import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {   dataDecrypt, FirstLetterCapitalize, formatDate, getCurrentDate } from "./../../../helper/commonHelpers";
//formatDate

function CompanyDetails() {
  const [submitting, setSubmitting] = useState(false);
  const { shareholdID }   = useParams();
  const [shareHolderDetail, setshareHolderDetail] = useState([]);
  const [bankList, setbankList] = useState([]);


  const [formErrors, setFormErrors] = useState({
    member_id: "",
    transfer_date: "",
    shares: "",
    transaction_date: "",
    amount: "",
  });


  const[transfer_date, settransfer_date] = useState('');  
  const[shares, setshares] = useState('10');
  const[face_value, setface_value] = useState('10.0');
  const[total_consideration, settotal_consideration] = useState('100');
  
  const[pay_mode, setpay_mode] = useState('cash');
  const[remark, setremark] = useState('');
  const[amount, setamount] = useState('');
  const[transaction_date, settransaction_date] = useState('');


const[bank_name, setbank_name] = useState('');
const[cheque_no, setcheque_no] = useState('');
const[cheque_date, setcheque_date] = useState('');
const[online_transfer_date, setonline_transfer_date] = useState('');

const[tranfer_no, settranfer_no] = useState('');
const[transfer_mode, settransfer_mode] = useState('');
const[saving_account, setsaving_account] = useState('');

  useEffect(() => {
  

      axios.get(API_BASE_URL + "api-member/get-shares-list?shares_id="+dataDecrypt(shareholdID), {
        headers: API_HEADERS,
      }).then((response) => {

        console.log(response.data);

        if (response.data["status"] > 0) {
            setshareHolderDetail(response.data["data"]);


            settransfer_date(response.data["data"].transfer_date);
            setshares(response.data["data"].shares);
            setface_value(response.data["data"].face_value);
            settotal_consideration(response.data["data"].total_consideration);
            setpay_mode(response.data["data"].pay_mode);
            setremark(response.data["data"].remark);
            setamount(response.data["data"].amount);
            settransaction_date(response.data["data"].transaction_date);
            setbank_name(response.data["data"].bank_name);
            setcheque_no(response.data["data"].cheque_no);
            setcheque_date(response.data["data"].cheque_date);
            setonline_transfer_date(response.data["data"].online_transfer_date);
            settranfer_no(response.data["data"].tranfer_no);
            settransfer_mode(response.data["data"].transfer_mode);
            setsaving_account(response.data["data"].saving_account);


        } else {
            toast.error(response.data["message"]);
        }

      }).catch((error) => {
        toast.error("Failed to fetch data!");
      });

    

      axios.get(API_BASE_URL + "api-common/get-bank-list", {
        headers: API_HEADERS,
      }).then((response) => {
        if (response.data["status"] > 0) {
          setbankList(response.data["data"]);
          }
      }).catch((error) => {
        toast.error("Failed to fetch data!");
      });


      settransfer_date(getCurrentDate());
      settransaction_date(getCurrentDate());
  }, [shareholdID]);




  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));

    const formData = {
        // table_id:dataDecrypt(memberID),

        // member_id: member_id,
        // business_type: business_type,
        transfer_date: transfer_date,
        shares: shares,
        face_value: face_value,
        total_consideration: total_consideration,
        paymode: pay_mode,
        remark: remark,
        amount: amount,
        transaction_date: transaction_date,
        bank_name: bank_name,
        cheque_no: cheque_no,
        cheque_date: cheque_date,
        online_transfer_date: online_transfer_date,
        tranfer_no: tranfer_no,
        transfer_mode: transfer_mode,
        saving_account: saving_account,
    };

    // validate form

      // if (!member_id) {
      //   errors.member_id = 'Member is required.';
      // }

      if (!transfer_date) {
        errors.transfer_date = 'Transfar date is required.';
      }

      if (!shares) {
        errors.shares = 'Shares is required.';
      }

      if (!transaction_date) {
        errors.transaction_date = 'Transaction date is required.';
      }

      if (!amount) {
        errors.amount = 'Amount is required.';
      }


      if (!pay_mode) {
        errors.pay_mode = 'Pay Mode is required.';
      }


      if(pay_mode==='cheque')
      {
        if (!formData.bank_name) {
          errors.bank_name = 'Bank Name is required.';
        }

        if (!formData.cheque_no) {
          errors.cheque_no = 'Cheque No. is required.';
        }

        if (!formData.cheque_date) {
          errors.cheque_date = 'Cheque Date. is required.';
        }
      }
  
      if(pay_mode==='online')
      {
        if (!formData.transfer_date) {
          errors.transfer_date = 'Transfer Date is required.';
        }

        if (!formData.online_transfer_date) {
          errors.online_transfer_date = 'UTR/ Transaction No is required.';
        }

        if (!formData.transfer_mode) {
          errors.transfer_mode = 'Transfer Mode is required.';
        }
      }

      if(pay_mode==='saving')
      {
        if (!formData.saving_account) {
          errors.saving_account = 'Saving Account is required.';
        }
      }



    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      axios
        .post(API_BASE_URL + "api-member/add-new-shares", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/member-share-holdings";
            //   window.location.reload();
            }, 2000);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 1000);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>
                          {shareHolderDetail && shareHolderDetail.member_name && (
                            <>{FirstLetterCapitalize(shareHolderDetail.member_name)}</>
                            )}

                            <small>&nbsp;Surrender Share</small> </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/member-share-holdings">Share Holdings </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> {shareHolderDetail && shareHolderDetail.member_name && (
                            <>{FirstLetterCapitalize(shareHolderDetail.member_name)}</>
                            )}</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> Surrender</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-7">
                    <div class="card">
                    

                      <div class="card-block">
                      <form onSubmit={updateDetails}>


                    


                        <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                            <div class="row">
                              
                              <div class="col-sm-12">
                              <h6 class="">
                                Transfer Date<span class="text-danger">*</span>
                                </h6>
                                <input
                                  class="form-control"
                                  type="date"
                                  value={transfer_date}
                                  onChange={(e) =>
                                    settransfer_date(e.target.value)
                                  }
                                  placeholder="Enter Transfer Date"
                                />
                                {formErrors.transfer_date && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.transfer_date}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                          <div class="col-sm-12 col-xl-6 m-b-30">
                            <div class="row">
                              
                              <div class="col-sm-12">
                              <h6 class="">
                                Shares<span class="text-danger">*</span>
                                </h6>
                                <input
                                  class="form-control"
                                  type="number"
                                  value={shares}
                                  onChange={(e) =>
                                    setshares(e.target.value)
                                  }
                                  placeholder="Enter Shares"
                                />
                                {formErrors.shares && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.shares}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                        </div>

                        <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                            <div class="row">
                              
                              <div class="col-sm-12">
                              <h6 class="">
                                Face Value
                                </h6>
                                <input
                                  class="form-control"
                                  type="number"
                                  value={face_value}
                                  onChange={(e) =>
                                    setface_value(e.target.value)
                                  }
                                  placeholder="Enter Face Value"
                                  readOnly
                                />
                                {formErrors.face_value && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.face_value}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                          <div class="col-sm-12 col-xl-6 m-b-30">
                            <div class="row">
                              
                              <div class="col-sm-12">
                              <h6 class="">
                                Total Consideration
                                </h6>
                                <input
                                  class="form-control"
                                  type="number"
                                  value={total_consideration}
                                  onChange={(e) =>
                                    settotal_consideration(e.target.value)
                                  }
                                  placeholder="Enter Total Consideration"
                                  readOnly
                                />
                                {formErrors.total_consideration && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.total_consideration}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                        </div>

                        <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <h5 class="sub-title text-center">Share Surrender Payment (Debit to Transferor Membership - Payment to Release) </h5>
                          </div>
                        </div>


                        <div class="row">
                          <div class="col-sm-12 col-xl-10 offset-xl-1 m-b-30">
                              <div class="row">
                                  <div class="col-sm-5">
                                  <h6 class="text-xl-right">Transaction Date<span class="text-danger">*</span></h6>
                                  </div>
                                  <div class="col-sm-7">
                                  <input
                                      class="form-control"
                                      type="date"
                                      value={transaction_date}
                                      onChange={(e) =>
                                      settransaction_date(e.target.value)
                                      }
                                      placeholder="Enter Transaction Date. "
                                  />
                                  {formErrors.transaction_date && (
                                      <span style={{ color: "red" }}>
                                      {formErrors.transaction_date}
                                      </span>
                                  )}
                                  </div>
                              </div>
                              </div>
                          </div>

                          <div class="row">
                        <div class="col-sm-12 col-xl-10 offset-xl-1 m-b-30">
                            <div class="row">
                              <div class="col-sm-5">
                                <h6 class="text-xl-right">
                                Amount <span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-7">
                                <input
                                  class="form-control"
                                  type="text"
                                  value={amount}
                                  onChange={(e) =>
                                    setamount(e.target.value)
                                  }
                                  placeholder="Enter Amount . " 
                                />
                                {formErrors.amount && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.amount}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                        <div class="col-sm-12 col-xl-10 offset-xl-1 m-b-30">
                            <div class="row">
                              <div class="col-sm-5">
                                <h6 class="text-xl-right">
                                Remarks (if any)
                                </h6>
                              </div>
                              <div class="col-sm-7">
                                <input
                                  class="form-control"
                                  type="text"
                                  value={remark}
                                  onChange={(e) =>
                                    setremark(e.target.value)
                                  }
                                  placeholder="Enter Remarks (if any). " 
                                />
                                {formErrors.remark && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.remark}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row">
                        <div class="col-sm-12 col-xl-10 offset-xl-1  m-b-30">
                            <div class="row">
                              <div class="col-sm-4">
                                <h6 class="text-xl-right">
                                  Pay Mode<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-8">
                                <div class="form-radio row ml-3">
                                  <div class="radio radio-inline col-xs-3 ">
                                    <label for="title_cash">
                                      <input
                                        type="radio"
                                        name="pay_mode"
                                        value={"cash"}
                                        id={"title_cash"}
                                        checked={pay_mode === 'cash' ? 'checked' : ''}
                                        onChange={(e) =>
                                          setpay_mode(e.target.value)
                                        }
                                      />
                                      <i class="helper"></i>Cash
                                    </label>
                                  </div>
                                  <div class="radio radio-inline col-xs-3 ">
                                    <label for="title_chque">
                                      <input
                                        type="radio"
                                        name="pay_mode"
                                        id={"title_chque"}
                                        value={"cheque"}
                                        checked={pay_mode === 'cheque' ? 'checked' : ''}
                                        onChange={(e) =>
                                          setpay_mode(e.target.value)
                                        }
                                      />
                                      <i class="helper"></i>Cheque
                                    </label>
                                  </div>
                                  <div class="radio radio-inline col-xs-3">
                                    <label for="title_online">
                                      <input
                                        type="radio"
                                        name="pay_mode"
                                        value={"online"}
                                        checked={pay_mode === 'online' ? 'checked' : ''}
                                        id={"title_online"}
                                        onChange={(e) =>
                                          setpay_mode(e.target.value)
                                        }
                                      />
                                      <i class="helper"></i>Online Tx
                                    </label>
                                  </div>

                                  <div class="radio radio-inline col-xs-3">
                                    <label for="title_saving">
                                      <input
                                        type="radio"
                                        name="pay_mode"
                                        value={"saving"}
                                        checked={pay_mode === 'saving' ? 'checked' : ''}
                                        id={"title_saving"}
                                        onChange={(e) =>
                                          setpay_mode(e.target.value)
                                        }
                                      />
                                      <i class="helper"></i>Saving Ac
                                    </label>
                                  </div>
                                  
                                </div>
                                {formErrors.pay_mode && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.pay_mode}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row" style={{ display: pay_mode === 'cheque' ? '' : 'none' }}>
                        <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                            <div class="row">
                              <div class="col-sm-4">
                                <h6 class="text-xl-right">
                                  Bank Name<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-8">
                              <select
                                  class="form-control m-b"
                                  name="bank_name"
                                  value={bank_name}
                                  onChange={(e) =>
                                    setbank_name(e.target.value)
                                  }
                                >
                                  <option value={''} disabled>Select bank_name</option>
                                  {bankList.map((bankdata) => (
                                    <option
                                      value={bankdata.id}
                                      selected={
                                        bankdata.id === bank_name
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {bankdata.name}

                                    </option>
                                  ))}
                                </select>
                                {formErrors.bank_name && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.bank_name}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row" style={{ display: pay_mode === 'cheque' ? '' : 'none' }}>
                        <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                            <div class="row">
                              <div class="col-sm-4">
                                <h6 class="text-xl-right">
                                  Cheque<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-8">
                                <input
                                  class="form-control"
                                  type="text"
                                  value={cheque_no}
                                  onChange={(e) =>
                                    setcheque_no(e.target.value)
                                  }
                                  placeholder="Enter Cheque"
                                />
                                {formErrors.cheque_no && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.cheque_no}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row" style={{ display: pay_mode === 'cheque' ? '' : 'none' }}>
                        <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                            <div class="row">
                              <div class="col-sm-4">
                                <h6 class="text-xl-right">
                                  Cheque Date<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-8">
                                <input
                                  class="form-control"
                                  type="date"
                                  value={cheque_date}
                                  onChange={(e) =>
                                    setcheque_date(e.target.value)
                                  }
                                  placeholder="Enter Cheque Date"
                                />
                                {formErrors.cheque_date && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.cheque_date}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row" style={{ display: pay_mode === 'online' ? '' : 'none' }}>
                        <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                            <div class="row">
                              <div class="col-sm-4">
                                <h6 class="text-xl-right">
                                  Transfar date<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-8">
                                <input
                                  class="form-control"
                                  type="date"
                                  value={online_transfer_date}
                                  onChange={(e) =>
                                    setonline_transfer_date(e.target.value)
                                  }
                                  placeholder="Enter Transfar date"
                                />
                                {formErrors.online_transfer_date && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.online_transfer_date}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                      

                        <div class="row" style={{ display: pay_mode === 'online' ? '' : 'none' }}>
                        <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                            <div class="row">
                              <div class="col-sm-4">
                                <h6 class="text-xl-right">
                                  UTR/ Transfar No.<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-8">
                                <input
                                  class="form-control"
                                  type="text"
                                  value={tranfer_no}
                                  onChange={(e) =>
                                    settranfer_no(e.target.value)
                                  }
                                  placeholder="Enter Transfar No."
                                />
                                {formErrors.tranfer_no && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.tranfer_no}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row" style={{ display: pay_mode === 'online' ? '' : 'none' }}>
                        <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                            <div class="row">
                              <div class="col-sm-4">
                                <h6 class="text-xl-right">
                                  Transfar Mode<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-8">
                                <div class="form-radio row ml-3">
                                  <div class="radio radio-inline col-xs-3 ">
                                    <label for="title_IMPS">
                                      <input
                                        type="radio"
                                        name="transfer_mode"
                                        value={"IMPS"}
                                        id={"title_IMPS"}
                                        checked={transfer_mode === 'IMPS' ? 'checked' : ''}
                                        onChange={(e) =>
                                          settransfer_mode(e.target.value)
                                        }
                                      />
                                      <i class="helper"></i>IMPS
                                    </label>
                                  </div>
                                  <div class="radio radio-inline col-xs-3 ">
                                    <label for="title_VPA">
                                      <input
                                        type="radio"
                                        name="transfer_mode"
                                        id={"title_VPA"}
                                        value={"VPA"}
                                        checked={transfer_mode === 'VPA' ? 'checked' : ''}
                                        onChange={(e) =>
                                          settransfer_mode(e.target.value)
                                        }
                                      />
                                      <i class="helper"></i>VPA
                                    </label>
                                  </div>
                                  <div class="radio radio-inline col-xs-3">
                                    <label for="title_NEFT">
                                      <input
                                        type="radio"
                                        name="transfer_mode"
                                        value={"NEFT/RTGS"}
                                        checked={transfer_mode === 'NEFT/RTGS' ? 'checked' : ''}
                                        id={"title_NEFT"}
                                        onChange={(e) =>
                                          settransfer_mode(e.target.value)
                                        }
                                      />
                                      <i class="helper"></i>NEFT/RTGS
                                    </label>
                                  </div>
                                  
                                </div>
                                {formErrors.transfer_mode && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.transfer_mode}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row" style={{ display: pay_mode === 'saving' ? '' : 'none' }}>
                        <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                            <div class="row">
                              <div class="col-sm-4">
                                <h6 class="text-xl-right">
                                Saving Account<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-8">
                                <input
                                  class="form-control"
                                  type="text"
                                  value={saving_account}
                                  onChange={(e) =>
                                    setsaving_account(e.target.value)
                                  }
                                  placeholder="Enter Saving Account No."
                                />
                                {formErrors.saving_account && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.saving_account}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                            <button
                              class="btn btn-success btn-square"
                              type="submit"
                              disabled={submitting}
                            >
                              Save
                            </button>{" "}
                            <a href="/share-holdings" class="btn btn-danger btn-square">Cancel</a>
                            &nbsp;
                            <button
                              class="btn btn-warning btn-square"
                              type="reset"
                            >
                              Reset
                            </button>
                          

                            <ToastContainer className="toast-container" />
                          </div>
                        </div>


                      </form>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-5">
                    <div class="card">

                      <div class="card-block">
                        <form onSubmit={updateDetails}>

                        <div class="table-responsive dt-responsive">
                            <table class="table table-striped table-bordered nowrap">
                            <tr>
                                <th>Member.</th>
                                <td> {shareHolderDetail && shareHolderDetail.member_id && (
                                    <>{shareHolderDetail.member_id}</>
                                    )} </td>
                            </tr>
                            <tr>
                                <th>Share Allotment Date</th>
                                <td> {shareHolderDetail && shareHolderDetail.created_at && (
                                    <>{formatDate(shareHolderDetail.created_at)}</>
                                    )}</td>
                            </tr>
                            <tr>
                                <th>Share Range</th>
                                <td> {shareHolderDetail && shareHolderDetail.shares && (
                                    <>{shareHolderDetail.shares}</>
                                    )} </td>
                            </tr>
                            <tr>
                                <th>Total Shares Held</th>
                                <td> {shareHolderDetail && shareHolderDetail.total_share_held && (
                                    <>{shareHolderDetail.total_share_held}</>
                                    )} </td>
                            </tr>
                            <tr>
                                <th>Nominal Value</th>
                                <td>
                                {shareHolderDetail && shareHolderDetail.share_nominal_value && (
                                    <>{shareHolderDetail.share_nominal_value}</>
                                    )} 
                                </td>
                            </tr>
                            <tr>
                                <th>Total Value</th>
                                <td>
                                {shareHolderDetail && shareHolderDetail.total_shares_value && (
                                    <>{shareHolderDetail.total_shares_value}</>
                                    )} 
                                </td>
                            </tr>

                            <tr>
                                <th>Allotment Date</th>
                                <td> {shareHolderDetail && shareHolderDetail.created_at && (
                                    <>{formatDate(shareHolderDetail.created_at)}</>
                                    )}</td>
                            </tr>

                            <tr>
                                <th>Date of Transfer</th>
                                <td> {shareHolderDetail && shareHolderDetail.transfer_date && (
                                    <>{formatDate(shareHolderDetail.transfer_date)}</>
                                    )}</td>
                            </tr>

                            <tr>
                                <th>Share Certificate No</th>
                                <td> {shareHolderDetail && shareHolderDetail.certificate_no && (
                                    <>{FirstLetterCapitalize(shareHolderDetail.certificate_no)}</>
                                    )}</td>
                            </tr>

                            <tr>
                                <th>Is Surrendered</th>
                                <td> 

                                {shareHolderDetail && shareHolderDetail.id==='yes' && (
                                      <label class="label label-success">Yes</label>
                                      )}
                                      {shareHolderDetail && shareHolderDetail.id==='no' && (
                                      <label class="label label-danger">No</label>
                                      )}

                                </td>
                            </tr>

                            </table>
                        </div>

                        </form>
                      </div>
                    </div>
                  </div>


                </div>


                

              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
          <ToastContainer className="toast-container" />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
