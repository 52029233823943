import React, { useState, useEffect } from 'react';
import DashboardLayout from '../dashboardLayout';

// import axios from "axios";
// import { API_BASE_URL, API_HEADERS } from "../../../config"; // Importing constants directly
// import $ from 'jquery';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



import {
      
      FirstLetterCapitalize,
      dataEncript,
      // formatDate,
      getCurrentDate,
      getCurrentMonthStartDate
      
    } from '../../helper/commonHelpers'; 


    
import {
  getListArr
} from '../../helper/listModal'; 

function AdminLayout() {
   const [accountList, setaccountList] = useState([]);
   const [submitting, setSubmitting] = useState(false);

   const[approval_date_from, setapproval_date_from] = useState('');
   const[approval_date_to, setapproval_date_to] = useState('');

    useEffect(() => {
     //let condition = `approved_status=initiated`; 
      getListArr('api-approvals/get-account-approve-history').then(data => {
        setaccountList(data);
      }).catch(error => {
          // console.error('Error fetching associate list:', error);
      });

      setapproval_date_from(getCurrentMonthStartDate());
      setapproval_date_to(getCurrentDate());

    }, []);



 


 



const filterData = (e) => {
      e.preventDefault();
      setSubmitting(true);
  
  const formData = {};

  if (approval_date_from) {
      formData['approval_date_from'] = approval_date_from;
    }
  
    // console.log(formData);
    if (Object.keys(formData).length === 0) {
      // Set formErrors state with validation errors
      let condition = `approval_date_from=${approval_date_from}&approval_date_to=${approval_date_to} `; 
      
        getListArr('api-approvals/get-account-approve-history', condition).then(data => {
          setaccountList(data);
          setSubmitting(false);
          toast.success('Data filtered successfully.');
        }).catch(error => {
            // console.error('Error fetching associate list:', error);
            toast.error(error);
        });
  
      }
      setSubmitting(false);
    };



    


    useEffect(() => {
      const timeout = setTimeout(() => {
        if (window.tableDomJquery) {
          window.tableDomJquery();
        }
      }, 2000); // Timeout of 3000 milliseconds (3 seconds)
  
      return () => clearTimeout(timeout); // Clear the timeout on component unmount

      // setapproval_date_to(getCurrentDate());
      // setapproval_date_to(getCurrentDate());

    }, [accountList]);

  return (
    <DashboardLayout>
<div class="pcoded-content">
      <div class="pcoded-inner-content">

            <div class="main-body">
                  <div class="page-wrapper">

                        <div class="page-header">
                    <div class="row align-items-end">
                        <div class="col-lg-6">
                            <div class="page-header-title">
                                <div class="d-inline">
                                    <h4>Accounts Approval - History
  
                                        
                                     {/* <small>
                                          &nbsp;
                                          <a class="btn btn-info btn-sm" title="Add New" href="/collection-centers-group-new">
                                         <b>+</b> </a> &nbsp; 
                                      </small>  */}
                                        
                                           </h4>
                                    {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="page-header-breadcrumb">
                                <ul class="breadcrumb-title">
                                    <li class="breadcrumb-item" style={{float:'left'}}>
                                        <a href="/dashboard"> <i class="feather icon-home"></i> </a>
                                    </li>
                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="approvals-accounts">Accounts Approval</a></li>
                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="#!">Accounts Approval - History</a></li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


            <div class="page-body">


            <div class="card" style={{borderTop:'3px solid green'}} >
                  <div class="card-header py-3 text-denger">
                        <h5>Search Box</h5>
                              <div class="card-header-right">
                                    <ul class="list-unstyled card-option">
                                        <li><i class="feather icon-minus minimize-card"></i></li>
                                    </ul>
                              </div>
                        </div>
                        <div class="card-block" >
                        <form onSubmit={filterData}>
                        <div class="row">
                          

                              <div class="col-sm-12 col-xl-3 m-b-30">
                                <div class="row">
                                  <div class="col-sm-12">
                                  <h6 class=""> Approval Date From : </h6>
                                    <input
                                      class="form-control"
                                      type="date"
                                      value={approval_date_from}
                                      onChange={(e) =>
                                        setapproval_date_from(e.target.value)
                                      }
                                      placeholder="Approval Date From"
                                    />
                                
                                  </div>
                                </div>
                              </div>

                              <div class="col-sm-12 col-xl-3 m-b-30">
                                <div class="row">
                                  <div class="col-sm-12">
                                  <h6 class=""> Member No : </h6>
                                    <input
                                      class="form-control"
                                      type="date"
                                      value={approval_date_to}
                                      onChange={(e) =>
                                        setapproval_date_to(e.target.value)
                                      }
                                      placeholder="Approval Date To"
                                    />
                                
                                  </div>
                                </div>
                              </div>

                          </div>

                          

                              <div class="row">
                                <div class="col-sm-12 col-xl-12 m-b-30 text-center">

                                <button class="btn btn-success btn-square" type="submit" disabled={submitting} >
                                <i class="fa fa-search"></i>   Search 
                                </button>{" "}
                                &nbsp;
                              
                                <button class="btn btn-danger btn-square" type="reset" > Clear Form </button>
                                <ToastContainer className="toast-container" />

                                </div>
                              </div>

                              </form>
                        </div>
                    </div>




                  <div class="card mt-2">
                         <div class="card-header">
                       </div> 
                        <div class="card-block">
                              <div class="table-responsive dt-responsive">
                                    <table class="table dtable table-striped table-bordered nowrap" style={{width:'100%'}}>
                                          <thead>
                                                <tr>
                                                      <th>SR.NO</th>
                                                      <th>BRANCH</th>
                                                      <th>MEMBER</th>
                                                      <th>A/C TYPE</th>
                                                      <th>A/C No.</th>
                                                      <th>AMT. DEPOSIT</th>
                                                      <th>PAY. MODE</th>
                                                      <th>PAY. RECIEVED</th>

                                                      <th>A/C&nbsp;STATUS</th>
                                                      <th>REMARKS</th>
                                                    
                                                      <th class="text-right">ACTIONS</th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                             
                                          {
                                                accountList.length > 0 ? (
                                                  accountList.map((branchData, index) => (
                                                      <tr class="gradeX" key={index}>
                                                      <td>{index + 1}</td>
                                                      <td>{branchData.branch_name?FirstLetterCapitalize(branchData.branch_name):''}</td>
                                                      <td> <a href={`/view-member/${dataEncript(branchData.member_id)}`}> {branchData.member_created_id?FirstLetterCapitalize(branchData.member_created_id):''} - {branchData.member_name?FirstLetterCapitalize(branchData.member_name):''} </a> </td>
                                                      <td>{branchData.account_for?FirstLetterCapitalize(branchData.account_for):''}</td>
                                                      <td>

                                                      { branchData && (
                                                              branchData.account_for === 'saving' ? (
                                                                <a href={`/view-member/${dataEncript(branchData.id)}`}>{branchData.account_no?(branchData.account_no):''}</a>
                                                              ) : branchData.account_for === 'rd' ? (
                                                                <a href={`/recurring-deposit-account-view/${dataEncript(branchData.id)}`}>{branchData.account_no?(branchData.account_no):''}</a>
                                                              ) : branchData.account_for === 'mis' ? (
                                                                <a href={`/fixed-deposit-mis-account-view/${dataEncript(branchData.id)}`}>{branchData.account_no?(branchData.account_no):''}</a>
                                                              ) : branchData.account_for === 'fd' ? (
                                                                <a href={`/fixed-deposit-account-view/${dataEncript(branchData.id)}`}>{branchData.account_no?(branchData.account_no):''}</a>
                                                              ) : branchData.account_for === 'dd' ? (
                                                                <a href={`/recurring-deposit-account-view/${dataEncript(branchData.id)}`}>{branchData.account_no?(branchData.account_no):''}</a>
                                                              ) : branchData.account_no
                                                            )
                                                          }
                                                      
                                                      </td>


                                                      <td>{branchData.paymode1_amt?(branchData.paymode1_amt):''}</td>
                                                      <td>{branchData.paymode1?FirstLetterCapitalize(branchData.paymode1):''}</td>
                                                    
                                                      <td></td>

                                                       <td>{branchData.approved_status?FirstLetterCapitalize(branchData.approved_status):''}</td>
                                                          
                                                       <td>{branchData.approved_by_name?FirstLetterCapitalize(branchData.approved_by_name):''}</td>

                                                          
                                                      <td class="center">
                                                            <a href="#!" class='btn btn-success btn-sm' disabled={submitting} >Done</a>
                                                      </td>
                                                </tr>
                                                  ))
                                                ) :null
                                              }

                                          
                                               
                                          </tbody>
                                        
                                    </table>
                              </div>
                        </div>
                  </div>






            </div>

      </div>
</div>
<ToastContainer className="toast-container" />
<div id="styleSelector">
</div>
</div>
</div>
    </DashboardLayout>
  );
}

export default AdminLayout;
