import React, { useState, useEffect } from "react";
import DashboardLayout from "../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { dataDecrypt,
    dataEncript,
    // FirstLetterCapitalize,
    // formatDate,
    deleteConfirmation
     } from "../../../helper/commonHelpers";

function CompanyDetails() {
  // const navigate = useNavigate();
  const { accountID }   = useParams();
  const [promoterhDetails, setpromoterhDetails] = useState([]);

  const [acttype, setacttype] = useState('FD');



  // PROMOTER INFO 

  useEffect(() => {

  // get bran ch details
 
axios.get(API_BASE_URL+'api-common/get-account-list?id='+dataDecrypt(accountID), {
    headers: API_HEADERS
}).then((response) => {

    if (response.data["status"] > 0) {
        setpromoterhDetails(response.data['data']);


        response.data['data'] && (
          response.data['data'].account_for === 'fd' ? (
            setacttype('FD')
          ) : response.data['data'].account_for === 'mis' ? (
            setacttype('MIS')
          ): response.data['data'].account_for === 'rd' ? (
            setacttype('RD')
          ) : <></>
        )

    }
}).catch((error) => {
  toast.error('Failed to fetch DETAILS list!');
});


 

    //    setenrollment_date(getCurrentDate());
  }, [accountID]);


  const deleteTransaction = async (tblid) => {
        const confirmed = await deleteConfirmation('Are you sure to proceed?');
        if(confirmed===true)
        {
            const formData = {
                tablename:'tbl_transaction',  // table name
                coloum_name:'id', // which collumn name you want to  
                value:tblid, // column value 
             };

             axios.post(API_BASE_URL + "api-common/detete", formData, {
                headers: {
                    ...API_HEADERS,
                },
                })
                .then((response) => {
                if (response.data["status"] > 0) {
                    toast.success(response.data["message"]);
                    setTimeout(() => {
                         window.location.reload();
                    }, 2700);
                } else {
                    toast.error(response.data["message"]);
                }
                })
                .catch((error) => {
                   toast.error("Failed to submition!");
                }); // close query hare
        }
  };



 
  


  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>
                          
                        {
                            promoterhDetails && (
                              promoterhDetails.account_for === 'fd' ? (
                                <> Fixed Deposit</>
                              ) : promoterhDetails.account_for === 'mis' ? (
                                <>MIS Deposits</>
                              ): promoterhDetails.account_for === 'rd' ? (
                                <>Recurring Deposits</>
                              ) : <></>
                            )
                          }
                          
                           - {promoterhDetails && promoterhDetails.account_no && (
                                    <>{promoterhDetails.account_no}</>
                                    )} <>Remove</> </h4>

                                    
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          

                          {
                            promoterhDetails && (
                              promoterhDetails.account_for === 'fd' ? (
                                <a href="/fixed-deposit-account">Fixed Deposit</a>
                              ) : promoterhDetails.account_for === 'mis' ? (
                                <a href="/fixed-deposit-mis-account">Fixed Deposit</a>
                              ) : promoterhDetails.account_for === 'rd' ? (
                                <a href="/fixed-deposit-mis-account">Recurring Deposit</a>
                              ) : <></>
                            )
                          }

                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                            {
                                promoterhDetails && (
                                  promoterhDetails.account_for === 'fd' ? (
                                    <a href={`/fixed-deposit-account-view/${dataEncript(promoterhDetails.id)}`}> {promoterhDetails && promoterhDetails.account_no && (
                                      <>{promoterhDetails.account_no}</>
                                      )} </a>
                                  ) : promoterhDetails.account_for === 'mis' ? (
                                    <a href={`/fixed-deposit-mis-account-view/${dataEncript(promoterhDetails.id)}`}> {promoterhDetails && promoterhDetails.account_no && (
                                      <>{promoterhDetails.account_no}</>
                                      )} </a>
                                  ) : promoterhDetails.account_for === 'rd' ? (
                                    <a href={`/recurring-deposit-account-view/${dataEncript(promoterhDetails.id)}`}> {promoterhDetails && promoterhDetails.account_no && (
                                      <>{promoterhDetails.account_no}</>
                                      )} </a>
                                  ) : <></>
                                )
                              }
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">Remove Account</a>
                        </li>
                      </ul>
                    </div>
                  </div>

                 

                </div>
              </div>

              <div class="page-body">
                <div class="row">
            

                  <div class="col-sm-12">

                 


                    <div class="card ">
                      <div class="card-header py-3">
                        <h5>Remove {acttype} and it's details and transactions.</h5>
                       <hr/>
                        {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                      </div>

                      <div class="card-block">


                          <h6>Remove {acttype} will delete following details:</h6>

                          <ul>
                              <li>Remove {acttype} and all its transactions.</li>
                              <li>Remove transactions from accounting module.</li>
                              <li>Remove all the tracking if any.</li>
                              <li>Sequence numbers will get unused in future.</li>
                              <li>May lead to data corruption if any inter link account transactions are present.</li>
                              <li>No data backup will be provided for this action.</li>
                            </ul>


                            <div class="col-md-12 mt-3 offset-xl-2 mt-10">
                                <a class="btn btn-danger mt-2 " href="#!"   onClick={() => deleteTransaction(promoterhDetails.id)}   >REMOVE ACCOUNT</a>
                                <a class="btn btn-warning mt-2  ml-1"  href={`/fixed-deposit-account-view/${accountID}`}>CANCEL</a>

                                {
                            promoterhDetails && (
                              promoterhDetails.account_for === 'fd' ? (
                                <a href="/fixed-deposit-account"  class="btn btn-warning mt-2  ml-1 btn-square">Cancel</a>
                              ) : promoterhDetails.account_for === 'mis' ? (
                                <a href="/fixed-deposit-mis-account"  class="btn btn-warning mt-2  ml-1 btn-square">Cancel</a>
                              ) : promoterhDetails.account_for === 'rd' ? (
                                <a href="/recurring-deposit-account"  class="btn btn-warning mt-2  ml-1 btn-square">Cancel</a>
                              ) : <></>
                            )
                          }

                            </div>

                        
                        
                      </div>
                    </div>
                  </div>


                  



                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
          <ToastContainer className="toast-container" />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
