import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {   dataDecrypt, FirstLetterCapitalize, formatDate, formattedAmount, dataEncript, deleteConfirmation } from "./../../../helper/commonHelpers";
//formatDate

function CompanyDetails() {
  const [submitting, setSubmitting] = useState(false);
  const { accountID }   = useParams();
  const [shareHolderDetail, setshareHolderDetail] = useState([]);
  const [memberList, setmemberList] = useState([]);

  const [formErrors, setFormErrors] = useState({
    member_id: "",
  });


 


  const[account_type, setaccount_type] = useState('single');
    const[joint_ac1_member_id, setjoint_ac1_member_id] = useState('');
    const[joint_ac2_member_id, setjoint_ac2_member_id] = useState('');
    const[mode_of_operation, setmode_of_operation] = useState('single');


  useEffect(() => {
  

      axios.get(API_BASE_URL + "api-common/get-account-list?id="+dataDecrypt(accountID), {
        headers: API_HEADERS,
      }).then((response) => {

        console.log(response.data);

        if (response.data["status"] > 0) {
            setshareHolderDetail(response.data["data"]);

            setaccount_type(response.data["data"].account_type);
            setjoint_ac1_member_id(response.data["data"].joint_member1);
            setjoint_ac2_member_id(response.data["data"].joint_member2);
            setmode_of_operation(response.data["data"].mode_of_operation);

        } else {
            toast.error(response.data["message"]);
        }

      });

    
      axios.get(API_BASE_URL + "api-member/get-member-list", {
        headers: API_HEADERS,
      }).then((response) => {
        if (response.data["status"] > 0) {
        setmemberList(response.data["data"]);
        }
      }).catch((error) => {
        toast.error("Failed to fetch data!");
      });
     


  }, [accountID]);




  const updateDetails = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));

    const formData = {
        id:dataDecrypt(accountID),

        account_type: account_type,
            joint_member1:joint_ac1_member_id, 
            joint_member2:joint_ac2_member_id, 
            mode_of_operation:mode_of_operation, 
    };

  console.log(formData);
     

      if(account_type==='joint')
      {
  
        if (!joint_ac1_member_id) {
            errors.joint_ac1_member_id = 'Joint A/c Member1 is required.';
          }

          if (!mode_of_operation) {
            errors.mode_of_operation = 'Mode of Operation is required.';
          }
  
      }



    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {

        const confirmed = await deleteConfirmation('to change the Account Type?');
        if(confirmed===true)
        {

      axios.post(API_BASE_URL + "api-saving-account/change-account-type", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
            //   window.location.href = "/account-transaction-view/"+accountID+'/'+response.data["data"].id;

              window.location.reload();
            }, 2000);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 1000);
        }); // close query hare

    }
    setSubmitting(false);
    } else {
      setSubmitting(false);
    } // hare close error
  };


  

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4> Account - {shareHolderDetail && shareHolderDetail.account_no && (
                                    <>{shareHolderDetail.account_no}</>
                                    )} 

                            <span>&nbsp;Change Account Type</span> </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/account-list">Accounts </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href={`/account-view/${dataEncript(shareHolderDetail.id)}`}> {shareHolderDetail && shareHolderDetail.account_no && (
                                    <>{shareHolderDetail.account_no}</>
                                    )} </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> Change Account Type</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-7">
                    <div class="card" style={{borderTop:'3px solid red'}} >
                    
                        <h4 class="m-4">CHANGE ACCOUNT TYPE</h4>
                       <hr class="p-0 m-0"/>

                     

                      <div class="card-block">
                      <form onSubmit={updateDetails}>

                      <h6 class="mb-2">Are you sure you want to change account type?</h6>

                      <div class="row" >
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                  Account Type<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_single">
                                        <input
                                          type="radio"
                                          name="account_type"
                                          value={"single"}
                                          id={"title_single"}
                                          checked={account_type === 'single' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setaccount_type(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Single
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_joint">
                                        <input
                                          type="radio"
                                          name="account_type"
                                          id={"title_joint"}
                                          value={"joint"}
                                          checked={account_type === 'joint' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setaccount_type(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Joint
                                      </label>
                                    </div>
                                  
                                    
                                  </div>
                                  {formErrors.account_type && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.account_type}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style={{ display: account_type === 'joint' ? '' : 'none' }}>
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Joint A/c Member <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                  <select
                                    class="form-control m-b"
                                    name="joint_ac1_member_id"
                                    value={joint_ac1_member_id}
                                    onChange={(e) =>
                                      setjoint_ac1_member_id(e.target.value)
                                    }
                                  >
                                    <option value={''} disabled>Select Member</option>
                                    {memberList.map((aBranchData) => (
                                      <option
                                        value={aBranchData.id}
                                        selected={
                                          aBranchData.id === joint_ac1_member_id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {aBranchData.fname} &nbsp;
                                        {aBranchData.mnane}

                                      </option>
                                    ))}
                                  </select>



                                  {formErrors.joint_ac1_member_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.joint_ac1_member_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Joint A/c Member 2
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                
                                  <select
                                    class="form-control m-b"
                                    name="joint_ac2_member_id"
                                    value={joint_ac2_member_id}
                                    onChange={(e) =>
                                      setjoint_ac2_member_id(e.target.value)
                                    }
                                  >
                                    <option value={''} disabled>Select Member</option>
                                    {memberList.map((aBranchData) => (
                                      <option
                                        value={aBranchData.id}
                                        selected={
                                          aBranchData.id === joint_ac2_member_id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {aBranchData.fname} &nbsp;
                                        {aBranchData.mnane}

                                      </option>
                                    ))}
                                  </select>



                                  {formErrors.joint_ac2_member_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.joint_ac2_member_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            
                          </div>


                          <div class="row" style={{ display: account_type === 'joint' ? '' : 'none' }} >
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                  Mode of Operation<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="mode_operation_single">
                                        <input
                                          type="radio"
                                          name="mode_of_operation"
                                          value={"single"}
                                          id={"mode_operation_single"}
                                          checked={mode_of_operation === 'single' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setmode_of_operation(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Single
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="mode_operation_joint">
                                        <input
                                          type="radio"
                                          name="mode_of_operation"
                                          id={"mode_operation_joint"}
                                          value={"joint"}
                                          checked={mode_of_operation === 'joint' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setmode_of_operation(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Jointly
                                      </label>
                                    </div>

                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="mode_operation_either">
                                        <input
                                          type="radio"
                                          name="mode_of_operation"
                                          id={"mode_operation_either"}
                                          value={"either"}
                                          checked={mode_of_operation === 'either' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setmode_of_operation(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i> Either or Survivor
                                      </label>
                                    </div>
                                  
                                    
                                  </div>
                                  {formErrors.mode_of_operation && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.mode_of_operation}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>





                      

                         


                        <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                            <button
                              class="btn btn-success btn-square"
                              type="submit"
                              disabled={submitting}
                            >
                              CHANGE ACCOUNT TYPE
                            </button>{" "}
                            <a href={`/account-view/${dataEncript(shareHolderDetail.id)}`} class="btn btn-danger btn-square">Cancel</a>
                            &nbsp;
                          

                            <ToastContainer className="toast-container" />
                          </div>
                        </div>


                      </form>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-5">
                    <div class="card" style={{borderTop:'3px solid green'}} >

                      <div class="card-block">
                        <form onSubmit={updateDetails}>

                        <div class="table-responsive dt-responsive">
                            <table class="table dtable table-striped table-bordered">
                            <tr>
                                <th>Member.</th>
                                <td> {shareHolderDetail && shareHolderDetail.member_name && (
                                    <a href={`/view-member/${dataEncript(shareHolderDetail.member_id)}`} >
                                        {FirstLetterCapitalize(shareHolderDetail.member_name)}
                                      </a>
                                    )} </td>
                            </tr>
                            <tr>
                                <th>PAN No.</th>
                                <td> {shareHolderDetail && shareHolderDetail.pan && (
                                    <>{shareHolderDetail.pan}</>
                                    )}</td>
                            </tr>
                           
                            <tr>
                                <th>Account No.</th>
                                <td> {shareHolderDetail && shareHolderDetail.account_no && (
                                    <>{shareHolderDetail.account_no}</>
                                    )} </td>
                            </tr>
                            <tr>
                                <th>Scheme</th>
                                <td>
                                {shareHolderDetail && shareHolderDetail.scheme_name && (
                                    <>{shareHolderDetail.scheme_name}</>
                                    )} 
                                </td>
                            </tr>
                            <tr>
                                <th>Open Date</th>
                                <td>
                                {shareHolderDetail && shareHolderDetail.open_date && (
                                    <>{formatDate(shareHolderDetail.open_date)}</>
                                    )} 
                                </td>
                            </tr>

                            <tr>
                                <th>Status</th>
                                <td>{
                                      shareHolderDetail && (
                                        shareHolderDetail.account_status === 'active' ? (
                                          <label className="label label-success">Active</label>
                                        ) : shareHolderDetail.account_status === 'inactive' ? (
                                          <label className="label label-danger">Inactive</label>
                                        ) : null
                                      )
                                    }</td>
                            </tr>

                   

                         

                            <tr>
                                <th>Available Balance (C)</th>
                                <td> {shareHolderDetail && shareHolderDetail.mis_amount && (
                                      <>{formattedAmount(shareHolderDetail.mis_amount)}</>
                                      )}</td>
                            </tr>


                            <tr>
                                <th>Joint Account</th>
                                <td>{
                                      shareHolderDetail && (
                                        shareHolderDetail.account_type === 'joint' ? (
                                          <label className="label label-success">Yes</label>
                                        ) : shareHolderDetail.account_type === 'single' ? (
                                          <label className="label label-danger">No</label>
                                        ) :null
                                      )
                                    }
                                   </td>
                            </tr>

                            <tr>
                                <th>Special Account</th>
                                <td>{
                                      shareHolderDetail && (
                                        shareHolderDetail.account_type === 'joint' ? (
                                          <label className="label label-success">Yes</label>
                                        ) : shareHolderDetail.account_type === 'single' ? (
                                          <label className="label label-danger">No</label>
                                        ) :null
                                      )
                                    }
                                   </td>
                            </tr>

                          

                            </table>
                        </div>

                        </form>
                      </div>
                    </div>
                  </div>


                </div>


                

              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
          <ToastContainer className="toast-container" />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
