import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly

import { useParams } from 'react-router-dom'; 

  import { toast, ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

  import {
    dataDecrypt,
    dataEncript,
    formatDate,
    FirstLetterCapitalize,
    deleteConfirmation
  } from './../../../helper/commonHelpers'; 


function CompanyDetails() {
    // const navigate = useNavigate();
    const { promoterID }   = useParams();
    const [submitting, setSubmitting] = useState(false);
    const [promoterhDetails, setpromoterhDetails] = useState([]);
    const [minorList, setminorList] = useState([]);

    const [nomineeList, setnomineeList] = useState([]);



    const [kyc_status, setkyc_status] = useState('');
    const [sms, setsms] = useState('no');
    const [account_locked, setaccount_locked] = useState('no');

    const [formErrors, setFormErrors] = useState({
        kyc_status: "",
      });

    useEffect(() => {

          // get bran ch details
          axios
          .get(API_BASE_URL + "api-company/get-promoter-by-id?promoter_id="+dataDecrypt(promoterID), {
            headers: API_HEADERS,
          })
          .then((response) => {
             setpromoterhDetails(response.data['data']);
             setkyc_status(response.data['data'].kyc_status);

             setsms(response.data['data'].sms);
             setaccount_locked(response.data['data'].account_locked);
          })
          .catch((error) => {
            toast.error('Failed to fetch  DETAILS list!');
          });


          axios.get(API_BASE_URL + "api-company/get-promoter-share-holdings-nominee?promoter_id="+dataDecrypt(promoterID), {
            headers: API_HEADERS,
          })
          .then((response) => {
            
            if (response.data["status"] > 0) {
                setnomineeList(response.data['data']);
            } else {
                toast.error(response.data["message"]);
            }

          })
          .catch((error) => {
            toast.error('Failed to fetch nominee !');
          });


          // GET MINOR LIST
        //   let minorCondition = "`member_type` = 'promoter' AND `member_id` ="+dataDecrypt(promoterID);

          let minorCondition = `member_type = 'promoter' AND member_id = ${dataDecrypt(promoterID)}`;
          axios.get(API_BASE_URL + "api-common/get-data?tablename=tbl_minor&condition="+minorCondition, {
            headers: API_HEADERS,
          })
          .then((response) => {
        
            if (response.data["status"] > 0) {
                setminorList(response.data["data"]);
            } else {
                toast.error(response.data["message"]);
            }

          })
          .catch((error) => {
            toast.error("Failed to fetch MINOR DATA!");
          });



      }, [promoterID]);



    //   console.log(promoterhDetails);


// update kyc status /updateSettingstatus //updateKYCstatus
const updateSettingstatus = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    // let errors = {};
    const formData = {
       tablename:'tbl_promoter',  // table name
        where_coloumn_name:'id', // which collumn name you want to  
        where_value:dataDecrypt(promoterID), // column value 
        set_coloumn_name:'sms', // which collumn name you want to update
        set_value:sms, // collumn value
    };


    const formData1 = {
        tablename:'tbl_promoter',  // table name
         where_coloumn_name:'id', // which collumn name you want to  
         where_value:dataDecrypt(promoterID), // column value 
         set_coloumn_name:'account_locked', // which collumn name you want to update
         set_value:account_locked, // collumn value
     };

    // if (!formData.set_value) {
    //     errors.kyc_status = 'Please Select status.';
    //   }

    // Set formErrors state with validation errors
    // setFormErrors(errors);
    // if (Object.keys(errors).length === 0) {
        const confirmed = await deleteConfirmation('Are you sure you want to change setting status?');
        if(confirmed===true)
        {

            axios
            .post(API_BASE_URL + "api-common/update-status", formData1, {
            headers: {
                ...API_HEADERS,
            },
            });


                        axios
                .post(API_BASE_URL + "api-common/update-status", formData, {
                headers: {
                    ...API_HEADERS,
                },
                })
                .then((response) => {
                if (response.data["status"] > 0) {
                    toast.success(response.data["message"]);
                    // setTimeout(() => {
                    // window.location.reload();
                    // }, 4000);
                } else {
                    toast.error(response.data["message"]);
                }
                })
                .catch((error) => {
                toast.error("Failed to submition!");
                })
                .finally(() => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
                }); // close query hare
        }
    // } else {
    //   setSubmitting(false);
    // } // hare close error
  };

// update kyc status
const updateKYCstatus = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    const formData = {
       tablename:'tbl_promoter',  // table name
        where_coloumn_name:'id', // which collumn name you want to  
        where_value:dataDecrypt(promoterID), // column value 
        set_coloumn_name:'kyc_status', // which collumn name you want to update
        set_value:kyc_status, // collumn value
    };

    if (!formData.set_value) {
        errors.kyc_status = 'Please Select status.';
      }

    // Set formErrors state with validation errors
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
        const confirmed = await deleteConfirmation('Are you sure you want to chnage status?');
        if(confirmed===true)
        {
                        axios
                .post(API_BASE_URL + "api-common/update-status", formData, {
                headers: {
                    ...API_HEADERS,
                },
                })
                .then((response) => {
                if (response.data["status"] > 0) {
                    toast.success(response.data["message"]);
                    setTimeout(() => {
                    window.location.reload();
                    }, 4000);
                } else {
                    toast.error(response.data["message"]);
                }
                })
                .catch((error) => {
                toast.error("Failed to submition!");
                })
                .finally(() => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
                }); // close query hare
        }
    } else {
      setSubmitting(false);
    } // hare close error
  };





// update kyc status /updateSettingstatus //updateKYCstatus
const sendUserCredential = async (e) => {
    e.preventDefault();
        let username = promoterhDetails.fname+' '+promoterhDetails.mname;
        const confirmed = await deleteConfirmation('Are You Sure to Reset Credentials and Resend SMS to '+username+' ?');
        if(confirmed===true)
        {
            toast.success('Your credentials has been sent on registered mobile number.');

            //   setTimeout(() => {
            //         window.location.reload();
            //         }, 2800);
        }
  };


  return (
    <DashboardLayout>




     <div class="pcoded-content">
    <div class="pcoded-inner-content">
        <div class="main-body">
            <div class="page-wrapper">
                <div class="page-header">
                    <div class="row align-items-end">
                        <div class="col-lg-8">
                            <div class="page-header-title">
                                <div class="d-inline">
                                    <h4>
                                    {promoterhDetails && promoterhDetails.fname && (
                                    <span>{promoterhDetails.fname}</span>
                                    )}

                                    {promoterhDetails && promoterhDetails.mname && (
                                    <span>{promoterhDetails.mname}</span>
                                    )}
                                    
                                    <small> Promoter</small></h4>
                                    {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}

                                   



                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="page-header-breadcrumb">
                                <ul class="breadcrumb-title">
                                    <li class="breadcrumb-item" style={{float:'left'}}>
                                        <a href="/dashboard"> <i class="feather icon-home"></i> </a>
                                    </li>
                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="/promoter-list">Promoter</a></li>

                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="#!"> 
                                    {promoterhDetails && promoterhDetails.fname && (
                                    <span>{promoterhDetails.fname}</span>
                                    )}

                                    {promoterhDetails && promoterhDetails.mname && (
                                    <span>{promoterhDetails.mname}</span>
                                    )} </a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="page-body">
                    <div class="row">

                    <div class="col-sm-12 mb-1">
                         <button class="btn btn-secondary mr-1">Download I5G/15H</button>
                         <button class="btn btn-warning mr-1">Upload I5G/15H</button>
                         <button class="btn btn-primary mr-1">View Transaction</button>
                     </div>


                        <div class="col-sm-6 ">
                          <div class="row">
                                <div class="col-md-12">
                                    <div class="card  border-success border-top ">
                                    <div class="card-header ">
                                        <h5>
                                            
                                        </h5>

                                                        <a
                                            style={{ float: "right" }}
                                            class="badge badge-info"
                                            href={`/edit-promoter/${dataEncript(promoterhDetails.id)}`}
                                            title="Edit"
                                            >
                                            <i class="feather icon-edit"></i>
                                            </a>
                                        {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                                    </div>

                                    <div class="card-block">
                                    <form > 
                                    <div class="table-responsive dt-responsive">
                                        <table class="table table-striped table-bordered nowrap">
                                        <tr>
                                            <th >Branch</th>
                                            <td>{promoterhDetails && promoterhDetails.branch_id && (
                                                <span>{promoterhDetails.branch_id}</span>
                                                )}</td>
                                        </tr>
                                        <tr>
                                            <th>Enrollment Date</th>
                                            <td>
                                                {promoterhDetails && promoterhDetails.enrollment_date && (
                                                <span>{ formatDate(promoterhDetails.enrollment_date)}</span>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Name</th>
                                            <td> {promoterhDetails && promoterhDetails.title && (
                                            <span>{promoterhDetails.title}</span>
                                            )}

                                            {promoterhDetails && promoterhDetails.fname && (
                                            <span>{promoterhDetails.fname}</span>
                                            )}

                                            {promoterhDetails && promoterhDetails.mname && (
                                            <span>{promoterhDetails.mname}</span>
                                            )}
                                                </td>
                                        </tr>
                                        <tr>
                                            <th>DOB</th>
                                            <td>{promoterhDetails && promoterhDetails.dob && (
                                                <span>{ formatDate(promoterhDetails.dob)}</span>
                                                )}</td>
                                        </tr>
                                        <tr>
                                            <th>Age</th>
                                            <td> {promoterhDetails && promoterhDetails.dob && (
                                            <span>{promoterhDetails.dob}</span>
                                            )}</td>
                                        </tr>
                                        <tr>
                                            <th>Senior Citizen</th>
                                            <td> {promoterhDetails && promoterhDetails.dob && (
                                            <span>{promoterhDetails.dob}</span>
                                            )}</td>
                                        </tr>
                                        <tr>
                                            <th>Gender</th>
                                            <td> {promoterhDetails && promoterhDetails.gender && (
                                            <span>{FirstLetterCapitalize(promoterhDetails.gender)}</span>
                                            )}</td>
                                        </tr>
                                        <tr>
                                            <th>Folio No.</th>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>Father Name</th>
                                            <td> {promoterhDetails && promoterhDetails.father_name && (
                                            <span>{FirstLetterCapitalize(promoterhDetails.father_name)}</span>
                                            )}</td>
                                        </tr>
                                        <tr>
                                            <th>Mother Name</th>
                                            <td> {promoterhDetails && promoterhDetails.mother_name && (
                                            <span>{FirstLetterCapitalize(promoterhDetails.mother_name)}</span>
                                            )}</td>
                                        </tr>
                                        <tr>
                                            <th>Marital Status</th>
                                            <td> {promoterhDetails && promoterhDetails.marital_status && (
                                            <span>{FirstLetterCapitalize(promoterhDetails.marital_status)}</span>
                                            )}</td>
                                        </tr>
                                        <tr>
                                            <th>Spouse Name</th>
                                            <td> {promoterhDetails && promoterhDetails.supouse_name && (
                                            <span>{FirstLetterCapitalize(promoterhDetails.supouse_name)}</span>
                                            )}</td>
                                        </tr>
                                        <tr>
                                            <th>Occupation</th>
                                            <td> {promoterhDetails && promoterhDetails.occuption && (
                                            <span>{FirstLetterCapitalize(promoterhDetails.occuption)}</span>
                                            )}</td>
                                        </tr>
                                        <tr>
                                            <th>Form 15G/ 15H Uploaded<br/> (FY 2023-2024)</th>
                                            <td> {promoterhDetails && promoterhDetails.fname && (
                                              <label class="label label-warning">No</label>
                                            )}</td>
                                        </tr>
                                        </table>
                                        </div>
                                        </form>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">

                                                <div class="card-block accordion-block">
                                                    <div id="accordion" role="tablist" aria-multiselectable="true">


                                                        <div class="card ">
                                                        <div class="accordion-panel">
                                                            <div class="accordion-heading text-white bg-success" role="tab" id="headingOne">
                                                                <h3 class="card-title accordion-title">
                                                                    <a class="accordion-msg " data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                    MEMBER KYC INFO <label class="pull-right"><i class="feather icon-plus"></i></label>
                                                                    </a>
                                                                </h3>
                                                            </div>
                                                            <div id="collapseOne" class="panel-collapse in collapse " role="tabpanel" aria-labelledby="headingOne">
                                                                <div class="accordion-content accordion-desc">
                                                                <div class="table-responsive dt-responsive">
                                                                    <table class="table table-striped table-bordered nowrap">
                                                                    <tr>
                                                                            <th>Aadhaar No</th>
                                                                            <td> {promoterhDetails && promoterhDetails.adhar_no && (
                                                                            <span>{FirstLetterCapitalize(promoterhDetails.adhar_no)}</span>
                                                                            )}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Voter ID No. </th>
                                                                            <td> {promoterhDetails && promoterhDetails.voter_id_no && (
                                                                            <span>{FirstLetterCapitalize(promoterhDetails.voter_id_no)}</span>
                                                                            )}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Pan No.      </th>
                                                                            <td> {promoterhDetails && promoterhDetails.pan_no && (
                                                                            <span>{FirstLetterCapitalize(promoterhDetails.pan_no)}</span>
                                                                            )}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Ration Card No.    </th>
                                                                            <td> {promoterhDetails && promoterhDetails.ration_card_no && (
                                                                            <span>{FirstLetterCapitalize(promoterhDetails.ration_card_no)}</span>
                                                                            )}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Meter No.    </th>
                                                                            <td> {promoterhDetails && promoterhDetails.meter_no && (
                                                                            <span>{FirstLetterCapitalize(promoterhDetails.meter_no)}</span>
                                                                            )}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>CI No. </th>
                                                                            <td> {promoterhDetails && promoterhDetails.ci_no && (
                                                                            <span>{FirstLetterCapitalize(promoterhDetails.ci_no)}</span>
                                                                            )}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>CI Relation  </th>
                                                                            <td> {promoterhDetails && promoterhDetails.ci_relation && (
                                                                            <span>{FirstLetterCapitalize(promoterhDetails.ci_relation)}</span>
                                                                            )}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>DL No</th>
                                                                            <td> {promoterhDetails && promoterhDetails.dl_no && (
                                                                            <span>{FirstLetterCapitalize(promoterhDetails.dl_no)}</span>
                                                                            )}</td>
                                                                        </tr>
                                                                    </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>


                                                        <div class="card ">
                                                        <div class="accordion-panel">
                                                            <div class="accordion-heading text-white bg-info" role="tab" id="headingTwo">
                                                                <h3 class="card-title accordion-title">
                                                                    <a class="accordion-msg " data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                                                    NOMINEE INFO <label class="pull-right"><i class="feather icon-plus"></i></label>
                                                                    </a>
                                                                </h3>
                                                            </div>
                                                            <div id="collapseTwo" class="panel-collapse in collapse " role="tabpanel" aria-labelledby="headingTwo">
                                                                <div class="accordion-content accordion-desc">
                                                                <div class="table-responsive dt-responsive">
                                                                    <table class="table table-striped table-bordered nowrap">
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <td> {promoterhDetails && promoterhDetails.nominee_name && (
                                                                            <span>{FirstLetterCapitalize(promoterhDetails.nominee_name)}</span>
                                                                            )}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>setnominee_relation</th>
                                                                        <td> {promoterhDetails && promoterhDetails.supouse_name && (
                                                                            <span>{FirstLetterCapitalize(promoterhDetails.supouse_name)}</span>
                                                                            )}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Mobile No.  </th>
                                                                        <td> {promoterhDetails && promoterhDetails.nominee_mob && (
                                                                            <span>{FirstLetterCapitalize(promoterhDetails.nominee_mob)}</span>
                                                                            )}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Aadhaar No. </th>
                                                                        <td> {promoterhDetails && promoterhDetails.nominee_aadhar && (
                                                                            <span>{FirstLetterCapitalize(promoterhDetails.nominee_aadhar)}</span>
                                                                            )}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Voter ID No.      </th>
                                                                        <td> {promoterhDetails && promoterhDetails.nominee_voter_id && (
                                                                            <span>{FirstLetterCapitalize(promoterhDetails.nominee_voter_id)}</span>
                                                                            )}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Pan No.     </th>
                                                                        <td> {promoterhDetails && promoterhDetails.nominee_pan && (
                                                                            <span>{FirstLetterCapitalize(promoterhDetails.nominee_pan)}</span>
                                                                            )}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Address</th>
                                                                        <td> {promoterhDetails && promoterhDetails.nominee_address && (
                                                                            <span>{FirstLetterCapitalize(promoterhDetails.nominee_address)}</span>
                                                                            )}</td>
                                                                    </tr>
                                                                    </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>


                                                        <div class="card ">
                                                        <div class="accordion-panel">
                                                            <div class="accordion-heading text-white bg-danger" role="tab" id="headingThree">
                                                                <h3 class="card-title accordion-title ">
                                                                    <a class="accordion-msg " data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                                                    DOCUMENTS <label class="pull-right"><i class="feather icon-plus"></i></label>
                                                                    </a>
                                                                </h3>
                                                            </div>
                                                            <div id="collapseThree" class="panel-collapse in collapse " role="tabpanel" aria-labelledby="headingThree">
                                                                <div class="accordion-content accordion-desc">
                                                                <div class="table-responsive dt-responsive">
                                                                    <table class="table table-striped table-bordered nowrap">
                                                                    <tr>
                                                                        <th>Photo (Photo)</th>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Id Proof (Aadhar Card)  </th>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Address Proof (Voter)     </th>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Pan Number (Pan) </th>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Signature (Signature)      </th>
                                                                        <td></td>
                                                                    </tr>
                                                                    </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>

                                                       

                                                        


                                                    </div>
                                                </div>

                                </div>
                            </div>

                        </div>


                        <div class="col-sm-6 ">
                            <div class="row">
                                    <div className="col-sm-6">
                                            <div class="card bg-c-yellow text-white">
                                                <div class="card-block">
                                                    <div class="row align-items-center">
                                                            <div class="col col-auto text-right">
                                                                <i class="feather icon-user f-50 text-c-yellow"></i>
                                                            </div>
                                                            <div class="col">
                                                                <p class="m-b-5">TOTAL DEPOSITS</p>
                                                                <h4 class="m-b-0">852</h4>
                                                            </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                            <div class="card bg-c-yellow text-white">
                                                <div class="card-block">
                                                    <div class="row align-items-center">
                                                            <div class="col col-auto text-right">
                                                                <i class="feather icon-user f-50 text-c-yellow"></i>
                                                            </div>
                                                            <div class="col p-0 ">
                                                                <p class="m-b-5">LOAN OUTSTANDING</p>
                                                                <h4 class="m-b-0">852</h4>
                                                            </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                            </div>


                            <div class="row">
                                <div class="col-md-12">
                                        <div class="card  border-success border-top ">
                                       

                                        <div class="card-block">
                                            <form onSubmit={updateKYCstatus}> 
                                            <div class="table-responsive dt-responsive">
                                                    <table class="table">
                                                        <tr class="border-0">
                                                            <th class="text-center" style={{ borderTop: '0px solid #dee2e6' }}>CURRENT KYC STATUS</th>
                                                            <td style={{ borderTop: '0px solid #dee2e6' }}>
                                                                
                                                                {promoterhDetails?.kyc_status === 'null' && (
                                                                  <label class="label label-warning">No</label>
                                                                )}

                                                                 {promoterhDetails?.kyc_status === 'PENDING' && (
                                                                  <label class="label label-warning">PENDING</label>
                                                                )}

                                                                   {promoterhDetails?.kyc_status === 'MIN_KYC' && (
                                                                  <label class="label label-info">MIN_KYC</label>
                                                                )}

                                                                {promoterhDetails?.kyc_status === 'FULL_KYC' && (
                                                                  <label class="label label-success">FULL_KYC</label>
                                                                )}
                                                                
                                                                </td>
                                                        </tr>
                                                        <tr>
                                                            <th class="text-center" style={{width:'0px;'}}>KYC STATUS</th>
                                                            <td>
                                                            <div class="input-group input-group-button">
                                                            <select
                                                                    class="form-control"
                                                                    name="kyc_status"
                                                                    value={kyc_status}
                                                                    onChange={(e) =>
                                                                        setkyc_status(e.target.value)
                                                                    }
                                                                >
                                                                    <option value="">Select Status</option>
                                                                    <option value="PENDING">PENDING</option>
                                                                    <option value="MIN_KYC">MIN_KYC</option>
                                                                    <option value="FULL_KYC">FULL_KYC</option>
                                                                </select>
                                                                <div class="input-group-prepend">
                                                                   <button class="btn btn-success btn-square" type="submit" disabled={submitting}  >Update</button>
                                                                    </div>
                                                                </div>

                                                                {formErrors.kyc_status && (
                                                                    <span style={{ color: "red" }}>
                                                                    {formErrors.kyc_status}
                                                                    </span>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-12">
                                        <div class="card  border-success border-top ">
                                       

                                        <div class="card-header " style={{ borderBottom: '1px solid #dee2e6' }}>
                                            <h5 class="title" >Settings</h5>
                                        </div>

                                        <div class="card-block">
                                        <form onSubmit={updateSettingstatus}> 
                                            <div class="table-responsive dt-responsive">
                                                    <table class="table">

                                                  

                                                        <tr class="border-0">
                                                            <th class="text-center" style={{ borderTop: '0px solid #dee2e6' }} >SMS</th>
                                                            <td style={{ borderTop: '0px solid #dee2e6' }} >

                                                                <input type="checkbox" class="js-large" name="sms" checked={sms === 'yes'} value="yes" onChange={(e) => {setsms(e.target.checked ? 'yes' : 'no'); updateSettingstatus(e);}} />

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th class="text-center" style={{width:'0px;'}}>Account Locked</th>
                                                            <td>
                                                                
                                                            <input type="checkbox" class="js-large" name="account_locked" value={"yes"}  checked={account_locked === 'yes' ? 'checked' : ''} onChange={(e) => {setaccount_locked(e.target.checked ? 'yes' : 'no'); updateSettingstatus(e);}} />

                                                                {formErrors.kyc_status && (
                                                                    <span style={{ color: "red" }}>
                                                                    {formErrors.kyc_status}
                                                                    </span>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                        <div class="card  border-primary border-top ">
                                        <div class="card-header " style={{ borderBottom: '1px solid #dee2e6' }}>
                                            <h5 class="title">INTERNET BANKING USERNAME</h5>
                                        </div>

                                        <div class="card-block">
                                            <form > 
                                                    <table class="table" width={'100%'} >
                                                        <tr>
                                                            <th style={{ borderTop: '0px solid #dee2e6' }}>USERNAME</th>
                                                            <td style={{ borderTop: '0px solid #dee2e6' }}>{promoterhDetails && promoterhDetails.internet_user_name && (
                                                                            <span>{FirstLetterCapitalize(promoterhDetails.internet_user_name)}</span>
                                                                            )}</td>
                                                            <td style={{ borderTop: '0px solid #dee2e6' }}>
                                                            <a href={`/change-password-promoter/${dataEncript(promoterhDetails.id)}`} class="label label-success mr-1"><i class="fa fa-refresh"></i></a>
                                                            <i class="fa fa-sign-in mr-1 btn label label-info" onClick={(e) => {sendUserCredential(e);}}></i></td>
                                                        </tr>
                                                    </table>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-12">
                                        <div class="card  border-primary border-top ">
                                        <div class="card-header " >
                                            <h5 class="title">Minors</h5>
                                            
                                            <a href={`/add-new-minor/${dataEncript(promoterhDetails.id)}`} style={{float:'inline-end'}} class="label label-info p-1"><i class="feather icon-plus"></i> MINOR</a>
                                            
                                        </div>

                                        <div class="card-block">
                                            
                                        <div class="table-responsive dt-responsive">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                            <th>Sr.</th>
                                                            <th>Name</th>
                                                            <th>DOB</th>
                                                            <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            minorList.length > 0 ? (
                                                            minorList.map((minorData, index) => (
                                                                <tr class="gradeX">
                                                                <td>{index + 1}</td>
                                                                <td >{minorData.title?minorData.title:''}
                                                                    {minorData.fname?minorData.fname:''} {minorData.lname?minorData.lname:''}
                                                                </td>
                                                                <td>{minorData.created_at?formatDate(minorData.created_at):''}</td>
                                                                <td class="center">
                                                                        <a href={`/view-minor/${dataEncript(promoterhDetails.id)}/${dataEncript(minorData.id)}`} class="label label-success"><i class="fa fa-eye"></i></a>
                                                                        <a href={`/edit-minor/${dataEncript(promoterhDetails.id)}/${dataEncript(minorData.id)}`} class="label label-info"><i class="fa fa-edit"></i></a> 
                                                                </td>
                                                            </tr>
                                                            ))
                                                            ) : (
                                                            <tr>
                                                                <td colSpan="3">No data available</td>
                                                            </tr>
                                                            )
                                                        }

                                                        </tbody>
                                                    </table>
                                                    </div>   

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                        <div class="card  border-primary border-top ">
                                        <div class="card-header " style={{ borderBottom: '1px solid #dee2e6' }}>
                                            <h5 class="title" >SHARE HOLDING DETAILS</h5>
                                            <a href={`/add-new-nominee/${dataEncript(promoterhDetails.id)}`} style={{float:'inline-end'}} class="label label-info p-1"><i class="feather icon-plus"></i> NOMINEE</a>
                                        </div>

                                        <div class="card-block">
                                        <div class="table-responsive dt-responsive">
                                                    <table class="table">
                                                    <tr>
                                                            <th style={{ borderTop: '0px solid #dee2e6' }}>NO OF SHARE</th>
                                                            <th style={{ borderTop: '0px solid #dee2e6' }}>2000</th>
                                                            </tr>
                                                        
                                                    </table>
                                                    </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
    <div class="col-md-12">

        <div class="card-block accordion-block">
            <div id="r_accordion" role="tablist" aria-multiselectable="true">


                <div class="card ">
                    <div class="accordion-panel">
                        <div class="accordion-heading text-white bg-success" role="tab" id="r_headingOne">
                            <h3 class="card-title accordion-title">
                                <a class="accordion-msg scale_active" data-toggle="collapse" data-parent="#r_accordion" href="#r_collapseOne" aria-expanded="true" aria-controls="r_collapseOne">
                                    SHARE HOLDING NOMINEE'S INFO <label class="pull-right"><i class="feather icon-plus"></i></label>
                                </a>
                            </h3>
                        </div>
                        <div id="r_collapseOne" class="panel-collapse in collapse show" role="tabpanel" aria-labelledby="r_headingOne">
                            <div class="accordion-content accordion-desc">
                                <div class="table-responsive dt-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Sr</th>
                                                <th>NAME</th>
                                                <th>RELATION</th>
                                                <th>ADDRESS</th>
                                                <th>SHARE PERCENTAGE(%)</th>
                                                <th>ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                                  {
                                                            nomineeList?.nominee?.length > 0 ? (
                                                            nomineeList.nominee.map((nomineeData, index) => (
                                                                <tr class="gradeX">
                                                                <td>{index + 1}</td>
                                                                <td >{nomineeData.name?nomineeData.name:''}</td>
                                                                <td >{nomineeData.relation?nomineeData.relation:''}</td>
                                                                <td >{nomineeData.address?nomineeData.address:''}</td>
                                                                <td >{nomineeData.share_holding?nomineeData.share_holding:''}</td>
                                                                <td class="center">
                                                                        <a href={`/add-new-nominee/${dataEncript(promoterhDetails.id)}`}  class="label label-info"><i class="fa fa-edit"></i></a> 
                                                                </td>
                                                            </tr>
                                                            ))
                                                            ) : (
                                                            <tr>
                                                                <td colSpan="6">No data available</td>
                                                            </tr>
                                                            )
                                                        }
                                        </tbody>
                                        
                                    </table>
                                    </div>  
                            </div>
                        </div>
                    </div>
                </div>


                <div class="card ">
                    <div class="accordion-panel">
                        <div class="accordion-heading text-white bg-info" role="tab" id="r_headingTwo">
                            <h5 class="card-title accordion-title p-2">

                            ADDRESS & CONTACT INFO 
                                <a class="accordion-msg " style={{display:'inline'}}  data-toggle="collapse" data-parent="#r_accordion" href="#r_collapseTwo" aria-expanded="true" aria-controls="r_collapseTwo">
                                     <label class="pull-right"><i class="feather icon-plus"></i></label>
                                </a>

                                <a  href={`/edit-address-details/${dataEncript(promoterhDetails.id)}`} style={{display:'inline', cursor:'pointer'}}  >
                                <label class="pull-right mr-2"><i class="feather icon-edit"></i></label>
                                </a>

                            </h5>
                        </div>
                        <div id="r_collapseTwo" class="panel-collapse in collapse " role="tabpanel" aria-labelledby="r_headingTwo">
                            <div class="accordion-content accordion-desc">
                                <div class="table-responsive dt-responsive">
                                        <table class="table">
                                           
                                            <tbody>
                                                     <tr>
                                                        <th>Address</th>
                                                       <td>
                                                        {promoterhDetails && promoterhDetails.address1 && (
                                                        <span>{ promoterhDetails.address1}</span>
                                                        )} &nbsp;
                                                          {promoterhDetails && promoterhDetails.address2 && (
                                                        <span>{ promoterhDetails.address2}</span>
                                                        )}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Para/ Word/ Panchyat/ Area </th>
                                                       <td>
                                                        {promoterhDetails && promoterhDetails.para && (
                                                        <span>{ promoterhDetails.para}</span>
                                                        )}
                                                             {promoterhDetails && promoterhDetails.ward && (
                                                        <span>/ { promoterhDetails.ward}</span>
                                                        )}
                                                          {promoterhDetails && promoterhDetails.panchayat && (
                                                        <span>/ { promoterhDetails.panchayat}</span>
                                                        )}

                                                        {promoterhDetails && promoterhDetails.area && (
                                                        <span>/ { promoterhDetails.area}</span>
                                                        )}

                                                        </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <th>Landmark</th>
                                                       <td>
                                                        {promoterhDetails && promoterhDetails.landmark && (
                                                        <span>{ FirstLetterCapitalize(promoterhDetails.landmark)}</span>
                                                        )}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>GPS Lat/Log </th>
                                                       <td>
                                                        {promoterhDetails && promoterhDetails.loc_lat && (
                                                        <span>{ promoterhDetails.loc_lat}</span>
                                                        )}
                                                        {promoterhDetails && promoterhDetails.loc_long && (
                                                        <span>/ { promoterhDetails.loc_long}</span>
                                                        )}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <th>Email</th>
                                                       <td>
                                                        {promoterhDetails && promoterhDetails.email && (
                                                        <span>{ promoterhDetails.email}</span>
                                                        )}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Mobile No. </th>
                                                       <td>
                                                        {promoterhDetails && promoterhDetails.mobile_no && (
                                                        <span>{ promoterhDetails.mobile_no}</span>
                                                        )}
                                                        </td>
                                                    </tr>

                                                    

                                            </tbody>
                                            
                                        </table>
                                        </div> 
                            </div>
                        </div>
                    </div>
                </div>


                <div class="card ">
                    <div class="accordion-panel">
                        <div class="accordion-heading text-white bg-warning" role="tab" id="r_headingThree">
                            <h5 class="card-title accordion-title p-2">
                            BANK DETAILS
                            
                                <a class="accordion-msg " style={{display:'inline'}} data-toggle="collapse" data-parent="#r_accordion" href="#r_collapseThree" aria-expanded="true" aria-controls="r_collapseThree">
                                 <label class="pull-right"><i class="feather icon-plus "></i></label>
                                </a>

                                <a  href={`/edit-bank-details/${dataEncript(promoterhDetails.id)}`} style={{display:'inline', cursor:'pointer'}}  >
                                <label class="pull-right mr-2"><i class="feather icon-edit"></i></label>
                                </a>

                               
                            </h5>
                        </div>
                        <div id="r_collapseThree" class="panel-collapse in collapse " role="tabpanel" aria-labelledby="r_headingThree">
                            <div class="accordion-content accordion-desc">
                            <div class="table-responsive dt-responsive">
                                        <table class="table">
                                           
                                            <tbody>
                                                     <tr>
                                                        <th>Bank Name</th>
                                                        <td>
                                                        {promoterhDetails && promoterhDetails.bank_name && (
                                                        <span>{ promoterhDetails.bank_name}</span>
                                                        )}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>IFSC Code </th>
                                                        <td>{promoterhDetails && promoterhDetails.ifsc_code && (
                                                            <span>{ promoterhDetails.ifsc_code}</span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <th>Account Type</th>
                                                        <td>{promoterhDetails && promoterhDetails.account_type && (
                                                            <span>{ promoterhDetails.account_type}</span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Account No. </th>
                                                        <td>{promoterhDetails && promoterhDetails.account_no && (
                                                            <span>{ promoterhDetails.account_no}</span>
                                                            )}
                                                        </td>
                                                    </tr>

                                                    

                                                    

                                            </tbody>
                                            
                                        </table>
                                        </div> 
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card ">
                    <div class="accordion-panel">
                        <div class="accordion-heading text-white bg-success" role="tab" id="r_headingFour">
                            <h5 class="card-title accordion-title p-2">

                            PROMOTER ACCOUNTS
                                
                                <a class="accordion-msg " style={{display:'inline'}}  data-toggle="collapse" data-parent="#r_accordion" href="#r_collapseFour" aria-expanded="true" aria-controls="r_collapseFour">
                                 <label class="pull-right"><i class="feather icon-plus"></i></label>
                                </a>


                                <a  href={`/edit-address-details/${dataEncript(promoterhDetails.id)}`} style={{display:'inline', cursor:'pointer'}}  >
                                <label class="pull-right mr-2"><i class="feather icon-edit"></i></label>
                                </a>

                            </h5>
                        </div>
                        <div id="r_collapseFour" class="panel-collapse in collapse " role="tabpanel" aria-labelledby="r_headingFour">
                            <div class="accordion-content accordion-desc">
                            <div class="table-responsive dt-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>ACCOUNT TYPE</th>
                                                <th>ACCOUNT NO</th>
                                                <th>OPEN DATE</th>
                                                <th>STATUS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                                <td>NAME</td>
                                                <td>RELATION</td>
                                                <td>ADDRESS</td>
                                                <td>SHARE PERCENTAGE(%)</td>
                                                
                                            </tr>
                                        </tbody>
                                        
                                    </table>
                                    </div>  
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card ">
                    <div class="accordion-panel">
                        <div class="accordion-heading text-white bg-info" role="tab" id="r_headingFive">
                            <h3 class="card-title accordion-title">
                                <a class="accordion-msg " data-toggle="collapse" data-parent="#r_accordion" href="#r_collapseFive" aria-expanded="true" aria-controls="r_collapseFive">
                                JOINT ACCOUNTS <label class="pull-right"><i class="feather icon-plus"></i></label>
                                </a>
                            </h3>
                        </div>
                        <div id="r_collapseFive" class="panel-collapse in collapse " role="tabpanel" aria-labelledby="r_headingFive">
                            <div class="accordion-content accordion-desc">
                            <div class="table-responsive dt-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>ACCOUNT TYPE</th>
                                                <th>ACCOUNT NO</th>
                                                <th>OPEN DATE</th>
                                                <th>STATUS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                                <td>NAME</td>
                                                <td>RELATION</td>
                                                <td>ADDRESS</td>
                                                <td>SHARE PERCENTAGE(%)</td>
                                                
                                            </tr>
                                        </tbody>
                                        
                                    </table>
                                    </div>  
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card ">
                    <div class="accordion-panel">
                        <div class="accordion-heading text-white bg-danger" role="tab" id="r_headingSix">
                            <h3 class="card-title accordion-title">
                                <a class="accordion-msg " data-toggle="collapse" data-parent="#r_accordion" href="#r_collapseSix" aria-expanded="true" aria-controls="r_collapseSix">
                                CO APPLICANT LOANS<label class="pull-right"><i class="feather icon-plus"></i></label>
                                </a>
                            </h3>
                        </div>
                        <div id="r_collapseSix" class="panel-collapse in collapse " role="tabpanel" aria-labelledby="r_headingSix">
                            <div class="accordion-content accordion-desc">
                            <div class="table-responsive dt-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>ACCOUNT TYPE</th>
                                                <th>ACCOUNT NO</th>
                                                <th>OPEN DATE</th>
                                                <th>STATUS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                                <td>NAME</td>
                                                <td>RELATION</td>
                                                <td>ADDRESS</td>
                                                <td>SHARE PERCENTAGE(%)</td>
                                                
                                            </tr>
                                        </tbody>
                                        
                                    </table>
                                    </div>  
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card ">
                    <div class="accordion-panel">
                        <div class="accordion-heading text-white bg-info" role="tab" id="r_headingSeven">
                            <h3 class="card-title accordion-title">
                                <a class="accordion-msg " data-toggle="collapse" data-parent="#r_accordion" href="#r_collapseSeven" aria-expanded="true" aria-controls="r_collapseSeven">
                                MY GUARANTOR SHIP <label class="pull-right"><i class="feather icon-plus"></i></label>
                                </a>
                            </h3>
                        </div>
                        <div id="r_collapseSeven" class="panel-collapse in collapse " role="tabpanel" aria-labelledby="r_headingSeven">
                            <div class="accordion-content accordion-desc">
                            <div class="table-responsive dt-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>ACCOUNT TYPE</th>
                                                <th>ACCOUNT NO</th>
                                                <th>OPEN DATE</th>
                                                <th>STATUS</th>
                                                <th>STATE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                                <td>NAME</td>
                                                <td>RELATION</td>
                                                <td>ADDRESS</td>
                                                <td>SHARE PERCENTAGE(%)</td>
                                                
                                            </tr>
                                        </tbody>
                                        
                                    </table>
                                    </div> 
                            </div>
                        </div>
                    </div>
                </div>




            </div>
        </div>

    </div>
</div>



                        </div>



                    </div>
                </div>
            </div>
        </div>

        <div id="styleSelector"></div>
        <ToastContainer className="toast-container" />

    </div>
</div>

    </DashboardLayout>
  );
}

export default CompanyDetails;
