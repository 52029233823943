import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly

// import { useNavigate } from 'react-router-dom';

import $ from 'jquery';
import 'dropify/dist/css/dropify.min.css';
import 'dropify';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { getCurrentDate } from "./../../../helper/commonHelpers";

function CompanyDetails() {
  // const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  // const [stateList, setStateList] = useState([]);
  const [pramoterList, setpramoterList] = useState([]);

  const [formErrors, setFormErrors] = useState({
    director_name: "",
    din_no: "",
    appointment_date: "",
    authorized_signatory: "",

  });

  const[designation, setdesignation] = useState('');
  const[member, setmember] = useState('');
  const[director_name, setdirector_name] = useState('');
  const[din_no, setdin_no] = useState('');
  const[appointment_date, setappointment_date] = useState('');
  const[resignation_date, setresignation_date] = useState('');
  const[signature, setsignature] = useState('');
  const[authorized_signatory, setauthorized_signatory] = useState('no');

  useEffect(() => {
  
    $('.dropify').dropify();
    // GET ACTIVE BRANCH LIST
    axios
      .get(API_BASE_URL + "api-company/get-active-promoter-list", {
        headers: API_HEADERS,
      })
      .then((response) => {
        setpramoterList(response.data["data"]);
      })
      .catch((error) => {
        toast.error("Failed to fetch data!");
      });

      setappointment_date(getCurrentDate());
      setresignation_date(getCurrentDate());



  }, []);


  const handleLogoImageChange = (e) => {
    const file = e.target.files[0]; 
    setsignature(file); 
  };


  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};

    const formData = new FormData();
    if (signature) {
      formData.append('signature', signature); 
    }

    formData.append('authorized_signatory', authorized_signatory); 
    formData.append('designation', designation); 
    formData.append('member', member); 
    formData.append('director_name', director_name); 
    formData.append('din_no', din_no); 
    formData.append('appointment_date', appointment_date); 
    formData.append('resignation_date', resignation_date); 




    // validate form
     if (!director_name) {
        errors.director_name = 'Director is required.';
      }

      if (!din_no) {
        errors.din_no = 'Din No. is required.';
      }

      if (!appointment_date) {
        errors.appointment_date = 'Appointment Date is required.';
      }

      if (!authorized_signatory) {
        errors.authorized_signatory = 'Authorized date No is required.';
      }



    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
        axios.post(API_BASE_URL + 'api-company/add-director', formData, {
          headers: {
            ...API_HEADERS,
            'Content-Type': 'multipart/form-data', // Set content type for FormData
          },
        }).then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/director";
            }, 3700);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 300);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>Add New Director</h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/director">Directors</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">Add New Director</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12"></div>

                  <div class="col-sm-12">
                    <div class="card">
                      <div class="card-header">
                        {/* <h5>Add New Promoter</h5> */}
                        {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                      </div>

                      <div class="card-block">
                        <form onSubmit={updateDetails}>
                          
                          <div class="row">

                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Designation
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={designation}
                                    onChange={(e) =>
                                      setdesignation(e.target.value)
                                    }
                                    placeholder="Enter Designation"
                                  />
                                  {formErrors.designation && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.designation}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Member
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <select
                                    class="form-control m-b"
                                    name="member"
                                    value={member}
                                    onChange={(e) =>
                                      setmember(e.target.value)
                                    }
                                  >
                                    <option value={''} disabled>Select Member</option>
                                    {pramoterList.map((aBranchData) => (
                                      <option
                                        value={aBranchData.id}
                                        selected={
                                          aBranchData.id === member
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {aBranchData.fname} &nbsp;
                                        {aBranchData.lname}

                                      </option>
                                    ))}
                                  </select>
                                  {formErrors.member && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.member}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            
                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Director Name<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={director_name}
                                    onChange={(e) =>
                                      setdirector_name(e.target.value)
                                    }
                                    placeholder="Enter Director Name"
                                  />
                                  {formErrors.director_name && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.director_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    DIN No.<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={din_no}
                                    onChange={(e) =>
                                      setdin_no(e.target.value)
                                    }
                                    placeholder="Enter DIN No."
                                  />
                                  {formErrors.din_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.din_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Appointment Date<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="date"
                                    value={appointment_date}
                                    onChange={(e) =>
                                      setappointment_date(e.target.value)
                                    }
                                    placeholder="Enter Appointment Date" 
                                  />
                                  {formErrors.appointment_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.appointment_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Resignation Date
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="date"
                                    value={resignation_date}
                                    onChange={(e) =>
                                      setresignation_date(e.target.value)
                                    }
                                    placeholder="Enter Resignation Date" 
                                  />
                                  {formErrors.resignation_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.resignation_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Signatory
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="file"
                                    onChange={handleLogoImageChange} className="dropify"
                                  />
                                  {formErrors.signatory && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.signatory}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Authorized Signatory<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                    <div class="form-radio row ml-3">
                                      <div class="radio radio-inline col-xs-3 ">
                                        <label for="title_yes">
                                          <input
                                            type="radio"
                                            name="authorized_signatory"
                                            value={"yes"}
                                            id={"title_yes"}
                                            checked={authorized_signatory === 'yes' ? 'checked' : ''}
                                            onChange={(e) =>
                                              setauthorized_signatory(e.target.value)
                                            }
                                          />
                                          <i class="helper"></i>Yes
                                        </label>
                                      </div>
                                      <div class="radio radio-inline col-xs-3 ">
                                        <label for="title_no">
                                          <input
                                            type="radio"
                                            name="authorized_signatory"
                                            id={"title_no"}
                                            value={"no"}
                                            checked={authorized_signatory === 'no' ? 'checked' : ''}
                                            onChange={(e) =>
                                              setauthorized_signatory(e.target.value)
                                            }
                                          />
                                          <i class="helper"></i>No
                                        </label>
                                      </div>
                                      
                                    </div>
                                    {formErrors.authorized_signatory && (
                                      <span style={{ color: "red" }}>
                                        {formErrors.authorized_signatory}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <h5 class="sub-title text-center">
                              
                              </h5>
                            </div>
                          </div>


                        


                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                              <button
                                class="btn btn-success btn-square"
                                type="submit"
                                disabled={submitting}
                              >
                                Add DIRECTOR
                              </button>{" "}
                              &nbsp;
                              <a href="/director" class="btn btn-danger btn-square">Cancel</a>
                              &nbsp;
                              <button
                                class="btn btn-warning btn-square"
                                type="reset"
                              >
                                Reset
                              </button>

                              <ToastContainer className="toast-container" />
                            </div>
                          </div>


                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
