import React, { useState, useEffect } from "react";
import DashboardLayout from "../../dashboardLayout";
// import axios from "axios";
// import { API_BASE_URL } from "../../../../config"; // Importing constants directly

import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

// import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";





import {  dataDecrypt, dataEncript, FirstLetterCapitalize, formatDate } from "../../../helper/commonHelpers";
import {  getCollectionCenterGroupListDetails } from '../../../helper/listModal';

//formatDate

function CompanyDetails() {
  const { centerGroupID }   = useParams();

  const [details, setdetails] = useState([]);
  const [commentsList, setcommentsList] = useState([]);
  

  useEffect(() => {
    let condition = `group_id=${dataDecrypt(centerGroupID)}`;
    getCollectionCenterGroupListDetails(condition).then(data => {
        console.log(data);
        setdetails(data);

        if (data.group_comment && (data.group_comment).length > 0) {
            setcommentsList(data.group_comment);
        }

      }).catch(error => {
          // console.error('Error fetching associate list:', error);
      });


  }, [centerGroupID]);





  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>  Group   -  

                        {details && details.group_name && (
                                    <> {FirstLetterCapitalize(details.group_name)}</>
                                    )}

                        </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                            <a href="/associate-advisor">Groups </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                            <a href={`/collection-centers-view/${dataEncript(details.id)}`}  > {details && details.group_name && (
                                    <>{FirstLetterCapitalize(details.group_name)}</>
                                    )}  </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> View</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-8">
                    

                    <div class="card" style={{borderTop:'3px solid green'}} >
                      <div class="card-header py-3">
                        <h5 class="" style={{width:'100%'}}>Group Information
                              
                          <a href={`/collection-centers-group-edit/${dataEncript(details.id)}`} style={{float:'right'}}  class="label label-info px-2" title="Edit" > <i class="feather icon-edit"></i> </a>
                      
                        </h5>
                      </div>
                          {/* <h4 class="m-4">CHANGE ACCOUNT INFO</h4> */}
                        <hr class="p-0 m-0"/>
                        <div class="card-block">

                        <div class="row">
                        <div class="col-sm-12">
                        <div class="table-responsive dt-responsive">
                          <table class="table dtable table-striped table-bordered nowrap">
                            <tbody>
                            <tr>
                                <th>Collection Center</th>
                                <td> {details && details.collcenter_id && (
                                    <> {FirstLetterCapitalize(details.collcenter_id)}</>
                                    )}</td>
                            </tr>

                            <tr>
                                <th>Group No</th>
                                <td> {details && details.group_no && (
                                    <> {(details.group_no)}</>
                                    )}</td>
                            </tr>

                            <tr>
                                <th>Name</th>
                                <td> {details && details.group_name && (
                                    <> {FirstLetterCapitalize(details.group_name)}</>
                                    )}</td>
                            </tr>

                            <tr>
                                <th>Head Name</th>
                                <td> {details && details.group_head && (
                                    <> {FirstLetterCapitalize(details.group_head)}</>
                                    )}</td>
                            </tr>

                            <tr>
                                <th>Cashier Name	</th>
                                <td> {details && details.group_cashier && (
                                    <> {FirstLetterCapitalize(details.group_cashier)}</>
                                    )}</td>
                            </tr>

                            <tr>
                                <th>Active</th>
                                <td> { details && (
                                        details.group_active === 'yes' ? (
                                          <label className="label label-success">Yes</label>
                                        ) : details.group_active === 'no' ? (
                                          <label className="label label-danger">No</label>
                                        ) : null
                                      )
                                    }
                                    </td>
                                  </tr>
                                  </tbody>
                              </table>
                            </div>
                            </div>
                        </div>

                        
                          <div class="row">
                            <div class="col-sm-12">
                          <div class="table-responsive dt-responsive">
                            <table class="table dtable table-striped table-bordered nowrap">
                                <tbody>
                                    <thead class="bg-info">
                                        <tr>
                                            <th>MEMBER NO.</th>
                                            <th>BRANCH</th>
                                            <th>ENROLLMENT DATE</th>
                                            <th>MOBILE NO</th>
                                            <th>STATUS</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                                <td>MEMBER NO.</td>
                                                <td>BRANCH</td>
                                                <td>ENROLLMENT DATE</td>
                                                <td>MOBILE NO</td>
                                                <td>STATUS</td>

                                            </tr>
                                    </tbody>
                                </tbody>
                              </table>
                            </div>
                            </div>
                          </div>

                            



                        </div>
                      </div>
                  </div>

                  <div class="col-sm-4">
                    <div class="card" style={{borderTop:'3px solid green'}} >
                    <div class="card-header py-3 text-denger">
                          <h5>COMMENTS</h5>
                                <div class="card-header-right">
                                      <ul class="list-unstyled card-option">
                                          <li><i class="feather icon-minus minimize-card"></i></li>
                                      </ul>
                                </div>
                          </div>
                          <div class="card-block p-0">
                                <form > 
                                  <div class="table-responsive dt-responsive">
                                          <table class="table dtable table-striped table-bordered">
                                          <thead>
                                            <tr>
                                                <th>Sr</th>
                                                <th>COMMENT</th>
                                                <th>COMMENT BY</th>
                                                <th>DATE</th>
                                            </tr>
                                        </thead>
                                        <tbody>


                                        {
                                        commentsList.length > 0 ? (
                                            commentsList.map((appointData, index) => (
                                            <tr class="gradeX">
                                            <td>{index + 1}</td>
                                            <td > {appointData && appointData.comment && (
                                                        <>{FirstLetterCapitalize(appointData.comment)}</>
                                                        )}</td>

                                                <td> {appointData && appointData.comment_by && (
                                                        <>{FirstLetterCapitalize(appointData.comment_by)}</>
                                                        )}</td>

                                            <td> {appointData && appointData.created_at && (
                                                        <>{ appointData.created_at?formatDate(appointData.created_at):''}</>
                                                        )}</td>

                                            
                                        </tr>
                                        ))
                                        ) : (
                                        <tr>
                                            <td colSpan="4">No data available</td>
                                        </tr>
                                        )
                                    }
                                          
                                        </tbody>
                                          </table>
                                          </div>

                                          <div class="row">
                                          <div class="col-md-12 mb-3 text-center">
                                              <a href={`/collection-centers-group-add-comment/${centerGroupID}`}  class="btn btn-info btn-square">ADD COMMENT</a>
                                          </div>
                                        </div>
                                        </form>
                          </div>
                    </div>
                  </div>
                </div>


                

              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
          {/* <ToastContainer className="toast-container" /> */}
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
