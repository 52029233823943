import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly

import { useParams } from 'react-router-dom'; 

  import { toast, ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

  import {
    dataDecrypt,
    dataEncript
  } from './../../../helper/commonHelpers'; 


function CompanyDetails() {
    // const navigate = useNavigate();
    const { branchID }   = useParams();
    const branch_id = branchID;

    const [branchDetails, setbranchDetails] = useState([]);


    useEffect(() => {

          // get bran ch details
          axios
          .get(API_BASE_URL + "api-company/get-branch-by-id?branch_id="+dataDecrypt(branch_id), {
            headers: API_HEADERS,
          })
          .then((response) => {
             setbranchDetails(response.data['data']);
          })
          .catch((error) => {
            toast.error('Failed to fetch EMPLYEE DETAILS list!');
          });

      }, [branch_id]);




  return (
    <DashboardLayout>




     <div class="pcoded-content">
    <div class="pcoded-inner-content">
        <div class="main-body">
            <div class="page-wrapper">
                <div class="page-header">
                    <div class="row align-items-end">
                        <div class="col-lg-8">
                            <div class="page-header-title">
                                <div class="d-inline">
                                    <h4>{branchDetails.branch_name} <small>Branch</small></h4>
                                    {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}

                                   



                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="page-header-breadcrumb">
                                <ul class="breadcrumb-title">
                                    <li class="breadcrumb-item" style={{float:'left'}}>
                                        <a href="/dashboard"> <i class="feather icon-home"></i> </a>
                                    </li>
                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="/branches-list">Branch List</a></li>

                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="#!">{branchDetails.branch_name}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="page-body">
                    <div class="row">

                    <div class="col-sm-12">
                    

                                    </div>


                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-header">
                                    <h5>

                        

                                    </h5>

                                    <a
                          style={{ float: "right" }}
                          class="badge badge-info"
                          href={`/edit-branch/${dataEncript(branchDetails.id)}`}
                          title="Edit"
                        >
                           <i class="feather icon-edit"></i>
                        </a>
                                    {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                                </div>

                                <div class="card-block">
                                <form > 
                                 <div class="row">
                                    <div class="col-sm-12 col-xl-12 m-b-30">
                                           
                                    <table class="table table-striped table-bordered nowrap">
                                        <tbody>
                                            <tr>
                                                <th>Branch Name</th>
                                                <td>{branchDetails.branch_name}</td>
                                            </tr>

                                            <tr>
                                                <th>Branch Code</th>
                                                <td>{branchDetails.branch_code}</td>
                                            </tr>

                                            <tr>
                                                <th>IFSC Code</th>
                                                <td>{branchDetails.ifsc_code}</td>
                                            </tr>

                                            <tr>
                                                <th>Date Of Opning</th>
                                                <td>{branchDetails.open_date}</td>
                                            </tr>

                                            <tr>
                                                <th>Address</th>
                                                <td>{branchDetails.address_line1}</td>
                                            </tr>

                                            <tr>
                                                <th>Contact Info</th>
                                                <td>{branchDetails.address_line1}</td>
                                            </tr>

                                            <tr>
                                                <th>Recharge/ Bill Payment Disable</th>
                                                <td>

                                                {branchDetails?.bill_pay_service === 'yes' && (
                                                <label class="label label-success">Yes</label>
                                                )}

                                                  {branchDetails?.bill_pay_service === 'no' && (
                                                    <label class="label label-warning">No</label>
                                                    )}
                                                
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>NEFT/ IMPS/ WITHIN Transfar Disable</th>
                                                <td>
                                                {branchDetails?.transfer_service === 'yes' && (
                                                <label class="label label-success">Yes</label>
                                                )}

                                                  {branchDetails?.transfer_service === 'no' && (
                                                    <label class="label label-warning">No</label>
                                                    )}

                                                </td>
                                            </tr>







                                        </tbody>
                                    </table>


                                        </div>
                                    </div>



                                        

                                    

                                    

                                        

                                    <div class="row">
                                        <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                                          

                                            <ToastContainer className="toast-container" />
                                        </div>
                                        </div>



                                    </form>
                                </div>


                            </div>




                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="styleSelector"></div>
    </div>
</div>

    </DashboardLayout>
  );
}

export default CompanyDetails;
