import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {   dataDecrypt, FirstLetterCapitalize, getCurrentDate, formattedAmount, dataEncript, infoAlert } from "./../../../helper/commonHelpers";
//formatDate

function CompanyDetails() {
  const [submitting, setSubmitting] = useState(false);
  const { accountID }   = useParams();
  const [shareHolderDetail, setshareHolderDetail] = useState([]);
  const [bankList, setbankList] = useState([]);
  const[transaction_report, settransaction_report] = useState('');
  const [schemeDetails, setschemeDetails] = useState([]);
  const [associateListArr, setassociateListArr] = useState([]);


  const[acttype, setacttype] = useState('');


  const [formErrors, setFormErrors] = useState({
    member_id: "",
    transaction_date: "",
    amount: "",
  });


  // const[transfer_date, settransfer_date] = useState('');  
  // const[shares, setshares] = useState('10');
  // const[face_value, setface_value] = useState('10.0');
  // const[total_consideration, settotal_consideration] = useState('100');
  

  const[remark, setremark] = useState('');
  const[amount, setamount] = useState('');
  const[transaction_date, settransaction_date] = useState('');

const[pay_mode, setpay_mode] = useState('cash');
const[bank_name, setbank_name] = useState('');
const[cheque_no, setcheque_no] = useState('');
const[cheque_date, setcheque_date] = useState('');
const[online_transfer_date, setonline_transfer_date] = useState('');

const[tranfer_no, settranfer_no] = useState('');
const[transfer_mode, settransfer_mode] = useState('');
const[saving_account, setsaving_account] = useState('');

const[coll_associate_id, setcoll_associate_id] = useState('');

  useEffect(() => {
  

    axios.get(API_BASE_URL + "api-associates/get-associate-list", {
      headers: API_HEADERS,
    }).then((response) => {
      if (response.data["status"] > 0) {
        setassociateListArr(response.data["data"]);
      }
    });

      axios.get(API_BASE_URL + "api-common/get-account-list?id="+dataDecrypt(accountID), {
        headers: API_HEADERS,
      }).then((response) => {

        // console.log(response.data);

        if (response.data["status"] > 0) {
            setshareHolderDetail(response.data["data"]);

            setcoll_associate_id(response.data["data"].coll_associate_id);


            axios.get(API_BASE_URL + "api-common/get-account-scheme-details?id="+(response.data["data"].scheme_id), {
              headers: API_HEADERS,
              }).then((response) => {
                  if (response.data["status"] > 0) {
                      setschemeDetails(response.data["data"]);
                  }
              });


              response.data['data'] && (
                response.data['data'].account_for === 'fd' ? (
                  setacttype('FD')
                ) : response.data['data'].account_for === 'mis' ? (
                  setacttype('MIS')
                ): response.data['data'].account_for === 'rd' ? (
                  setacttype('RD')
                ): response.data['data'].account_for === 'dd' ? (
                  setacttype('DD')
                ) : <></>
              )


        


        } else {
            toast.error(response.data["message"]);
        }

      }).catch((error) => {
        toast.error("Failed to fetch data!");
      });

    

      axios.get(API_BASE_URL + "api-common/get-bank-list", {
        headers: API_HEADERS,
      }).then((response) => {
        if (response.data["status"] > 0) {
          setbankList(response.data["data"]);
          }
      });



      


      settransaction_date(getCurrentDate());
  }, [accountID]);




  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));



    const formData = new FormData();
    formData.append('account_type', 'account'); 
    formData.append('account_id', dataDecrypt(accountID)); 
    formData.append('transaction_type', 'credit'); 
    formData.append('deposit_amt', amount); 


    formData.append('pay_mode', pay_mode); 
    formData.append('remark', remark); 
    formData.append('transaction_date', transaction_date); 
    formData.append('bank_name', bank_name); 
    formData.append('cheque_no', cheque_no); 
    formData.append('cheque_date', cheque_date); 

    formData.append('online_transfer_date', online_transfer_date); 
    formData.append('utr_no', tranfer_no); 
    formData.append('transfer_mode', transfer_mode); 
    formData.append('saving_account', saving_account); 

    formData.append('coll_associate_id', coll_associate_id); 



    if(transaction_report)
    {
      formData.append('t_receipt', transaction_report); 
    }
   
     

      if (!transaction_date) {
        errors.transaction_date = 'Transaction date is required.';
      }

      if (!amount) {
        errors.amount = 'Amount is required.';
      }


      if (!pay_mode) {
        errors.pay_mode = 'Pay Mode is required.';
      }


      if(pay_mode==='cheque')
      {
        if (!bank_name) {
          errors.bank_name = 'Bank Name is required.';
        }

        if (!cheque_no) {
          errors.cheque_no = 'Cheque No. is required.';
        }

        if (!cheque_date) {
          errors.cheque_date = 'Cheque Date. is required.';
        }
      }
  
      if(pay_mode==='online')
      {
        if (!online_transfer_date) {
          errors.online_transfer_date = 'Transfer Date is required.';
        }

        if (!online_transfer_date) {
          errors.online_transfer_date = 'UTR/ Transaction No is required.';
        }

        if (!transfer_mode) {
          errors.transfer_mode = 'Transfer Mode is required.';
        }
      }

      if(pay_mode==='saving')
      {
        if (!saving_account) {
          errors.saving_account = 'Saving Account is required.';
        }
      }



    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      axios
        .post(API_BASE_URL + "api-saving-account/new-deposit-money", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/account-transaction-view/"+accountID+'/'+response.data["data"].id;

            //   window.location.reload();
            }, 2000);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 1000);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };


  const handletransaction_report = (e) => {
    const file = e.target.files[0]; 
    settransaction_report(file); 
  };

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4> 
                          
                        {
                            shareHolderDetail && (
                              shareHolderDetail.account_for === 'fd' ? (
                                <> FD Account</>
                              ) : shareHolderDetail.account_for === 'mis' ? (
                                <>MIS Account</>
                              ) : shareHolderDetail.account_for === 'rd' ? (
                                <> RD Account</>
                              ): shareHolderDetail.account_for === 'dd' ? (
                                <> Daily Deposits</>
                              ) : <>Saving Account</>
                            )
                          }
                          
                           - {shareHolderDetail && shareHolderDetail.account_no && (
                                    <>{shareHolderDetail.account_no}</>
                                    )} 

                            <span>&nbsp;Deposit Money</span> </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                        {
                                shareHolderDetail && (
                                  shareHolderDetail.account_for === 'fd' ? (
                                    <a href="/fixed-deposit-account">Fd Accounts</a>
                                  ) : shareHolderDetail.account_for === 'mis' ? (
                                    <a href="/fixed-deposit-mis-account">MIS Deposits</a>
                                  ): shareHolderDetail.account_for === 'rd' ? (
                                    <a href="/recurring-deposit-account">RD Deposits</a>
                                  ) : shareHolderDetail.account_for === 'dd' ? (
                                    <a href="/recurring-deposit-account">Daily Deposits</a>
                                  ) :  <a href="/account-list">Accounts</a>
                                )
                              }
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                        {
                              shareHolderDetail && (
                                shareHolderDetail.account_for === 'fd' ? (
                                  <a href={`/fixed-deposit-account-view/${dataEncript(shareHolderDetail.id)}`} > {shareHolderDetail && shareHolderDetail.account_no && ( <span>{shareHolderDetail.account_no}</span> )}</a>
                                ) : shareHolderDetail.account_for === 'mis' ? (
                                   <a href={`/fixed-deposit-mis-account-view/${dataEncript(shareHolderDetail.id)}`} > {shareHolderDetail && shareHolderDetail.account_no && ( <span>{shareHolderDetail.account_no}</span> )}</a>
                                ): shareHolderDetail.account_for === 'rd' ? (
                                  <a href={`/recurring-deposit-account-view/${dataEncript(shareHolderDetail.id)}`} > {shareHolderDetail && shareHolderDetail.account_no && ( <span>{shareHolderDetail.account_no}</span> )}</a>
                               ) : shareHolderDetail.account_for === 'dd' ? (
                                <a href={`/recurring-deposit-account-view/${dataEncript(shareHolderDetail.id)}`} > {shareHolderDetail && shareHolderDetail.account_no && ( <span>{shareHolderDetail.account_no}</span> )}</a>
                             ) :  <a href={`/account-view/${dataEncript(shareHolderDetail.id)}`} > {shareHolderDetail && shareHolderDetail.account_no && ( <span>{shareHolderDetail.account_no}</span> )}</a>
                              )
                            }
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> Deposit Money</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-7">
                    <div class="card" style={{borderTop:'3px solid red'}} >

                      <div class="card-block">
                      <form onSubmit={updateDetails}>

                          <div class="row">
                          <div class="col-sm-12 col-xl-10 offset-xl-1 m-b-30">
                              <div class="row">
                                  <div class="col-sm-5">
                                  <h6 class="text-xl-right">Member's Sign</h6>
                                  </div>
                                  <div class="col-sm-7">
                                  No Signature Present
                                  (Upload in Member Documents)
                                  </div>
                              </div>
                              </div>
                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-10 offset-xl-1 m-b-30">
                              <div class="row">
                                  <div class="col-sm-5">
                                  <h6 class="text-xl-right">Member's Photo</h6>
                                  </div>
                                  <div class="col-sm-7">
                                    
                                  No Signature Present
                                  (Upload in Member Documents)


                                  </div>
                              </div>
                              </div>
                          </div>

                    

                          <div class="row">

                            <div class="col-sm-12 col-xl-10 offset-xl-1 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Collection Associate/ Advisor/ Staff
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                              

                                  <select
                                  class="form-control m-b"
                                  name="associate_id"
                                  value={coll_associate_id}
                                  onChange={(e) =>
                                    setcoll_associate_id(e.target.value)
                                  }
                                >
                                  <option value={''} disabled>Collection Associate/ Advisor/ Staff</option>
                                  {associateListArr.map((branchData) => (
                                    <option
                                      value={branchData.id}
                                      selected={
                                        branchData.id === coll_associate_id
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {branchData.first_name} 
                                      {branchData.last_name}

                                    </option>
                                  ))}
                                </select>


                                  {formErrors.coll_associate_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.coll_associate_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                       

                        <div class="row">
                          <div class="col-sm-12 col-xl-10 offset-xl-1 m-b-30">
                            <div class="row">
                              <div class="col-sm-5">
                                <h6 class="text-xl-right">
                                Amount to Deposit <span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-7">
                                <input
                                  class="form-control"
                                  type="number"
                                  value={amount}
                                  onChange={(e) =>
                                    setamount(e.target.value)
                                  }
                                  placeholder="Enter Amount . " 
                                />
                                {formErrors.amount && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.amount}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                        <div class="col-sm-12 col-xl-10 offset-xl-1 m-b-30">
                            <div class="row">
                              <div class="col-sm-5">
                                <h6 class="text-xl-right">
                                Remarks (if any)
                                </h6>
                              </div>
                              <div class="col-sm-7">
                                <input
                                  class="form-control"
                                  type="text"
                                  value={remark}
                                  onChange={(e) =>
                                    setremark(e.target.value)
                                  }
                                  placeholder="Enter Remarks (if any). " 
                                />
                                {formErrors.remark && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.remark}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-sm-12 col-xl-10 offset-xl-1 m-b-30">
                              <div class="row">
                                  <div class="col-sm-5">
                                  <h6 class="text-xl-right">Transaction Date<span class="text-danger">*</span></h6>
                                  </div>
                                  <div class="col-sm-7">
                                  <input
                                      class="form-control"
                                      type="date"
                                      value={transaction_date}
                                      onChange={(e) =>
                                      settransaction_date(e.target.value)
                                      }
                                      placeholder="Enter Transaction Date. "
                                  />
                                  {formErrors.transaction_date && (
                                      <span style={{ color: "red" }}>
                                      {formErrors.transaction_date}
                                      </span>
                                  )}
                                  </div>
                              </div>
                              </div>
                          </div>


                          <div class="row">
                          <div class="col-sm-12 col-xl-10 offset-xl-1 m-b-30">
                              <div class="row">
                                  <div class="col-sm-5">
                                  <h6 class="text-xl-right">T. Receipt<span class="text-danger">*</span></h6>
                                  </div>
                                  <div class="col-sm-7">
                                  
                                  
                                  <div class="input-group">
                                  <input
                                      class="form-control"
                                      type="file"
                                      onChange={handletransaction_report}
                                  />
                                  <div class="input-group-prepend" style={{width:'auto'}}>
                                      <span class="input-group-text"   onClick={() => infoAlert("This service is not active for you. Contact Support.")}  ><i class="icofont icofont-info"></i></span>
                                  </div>
                                </div>

                                  </div>
                              </div>
                              </div>
                          </div>

                         


                        <div class="row">
                        <div class="col-sm-12 col-xl-10 offset-xl-1  m-b-30">
                            <div class="row">
                              <div class="col-sm-4">
                                <h6 class="text-xl-right">
                                  Pay Mode<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-8">
                                <div class="form-radio row ml-3">
                                  <div class="radio radio-inline col-xs-3 ">
                                    <label for="title_cash">
                                      <input
                                        type="radio"
                                        name="pay_mode"
                                        value={"cash"}
                                        id={"title_cash"}
                                        checked={pay_mode === 'cash' ? 'checked' : ''}
                                        onChange={(e) =>
                                          setpay_mode(e.target.value)
                                        }
                                      />
                                      <i class="helper"></i>Cash
                                    </label>
                                  </div>
                                  <div class="radio radio-inline col-xs-3 ">
                                    <label for="title_chque">
                                      <input
                                        type="radio"
                                        name="pay_mode"
                                        id={"title_chque"}
                                        value={"cheque"}
                                        checked={pay_mode === 'cheque' ? 'checked' : ''}
                                        onChange={(e) =>
                                          setpay_mode(e.target.value)
                                        }
                                      />
                                      <i class="helper"></i>Cheque
                                    </label>
                                  </div>
                                  <div class="radio radio-inline col-xs-3">
                                    <label for="title_online">
                                      <input
                                        type="radio"
                                        name="pay_mode"
                                        value={"online"}
                                        checked={pay_mode === 'online' ? 'checked' : ''}
                                        id={"title_online"}
                                        onChange={(e) =>
                                          setpay_mode(e.target.value)
                                        }
                                      />
                                      <i class="helper"></i>Online Tx
                                    </label>
                                  </div>

                                  <div class="radio radio-inline col-xs-3">
                                    <label for="title_saving">
                                      <input
                                        type="radio"
                                        name="pay_mode"
                                        value={"saving"}
                                        checked={pay_mode === 'saving' ? 'checked' : ''}
                                        id={"title_saving"}
                                        onChange={(e) =>
                                          setpay_mode(e.target.value)
                                        }
                                      />
                                      <i class="helper"></i>Saving Ac
                                    </label>
                                  </div>
                                  
                                </div>
                                {formErrors.pay_mode && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.pay_mode}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row" style={{ display: pay_mode === 'cheque' ? '' : 'none' }}>
                        <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                            <div class="row">
                              <div class="col-sm-4">
                                <h6 class="text-xl-right">
                                  Bank Name<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-8">
                              <select
                                  class="form-control m-b"
                                  name="bank_name"
                                  value={bank_name}
                                  onChange={(e) =>
                                    setbank_name(e.target.value)
                                  }
                                >
                                  <option value={''} disabled>Select bank_name</option>
                                  {bankList.map((bankdata) => (
                                    <option
                                      value={bankdata.id}
                                      selected={
                                        bankdata.id === bank_name
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {bankdata.name}

                                    </option>
                                  ))}
                                </select>
                                {formErrors.bank_name && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.bank_name}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row" style={{ display: pay_mode === 'cheque' ? '' : 'none' }}>
                        <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                            <div class="row">
                              <div class="col-sm-4">
                                <h6 class="text-xl-right">
                                  Cheque<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-8">
                                <input
                                  class="form-control"
                                  type="text"
                                  value={cheque_no}
                                  onChange={(e) =>
                                    setcheque_no(e.target.value)
                                  }
                                  placeholder="Enter Cheque"
                                />
                                {formErrors.cheque_no && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.cheque_no}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row" style={{ display: pay_mode === 'cheque' ? '' : 'none' }}>
                        <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                            <div class="row">
                              <div class="col-sm-4">
                                <h6 class="text-xl-right">
                                  Cheque Date<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-8">
                                <input
                                  class="form-control"
                                  type="date"
                                  value={cheque_date}
                                  onChange={(e) =>
                                    setcheque_date(e.target.value)
                                  }
                                  placeholder="Enter Cheque Date"
                                />
                                {formErrors.cheque_date && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.cheque_date}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row" style={{ display: pay_mode === 'online' ? '' : 'none' }}>
                        <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                            <div class="row">
                              <div class="col-sm-4">
                                <h6 class="text-xl-right">
                                  Transfar date<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-8">
                                <input
                                  class="form-control"
                                  type="date"
                                  value={online_transfer_date}
                                  onChange={(e) =>
                                    setonline_transfer_date(e.target.value)
                                  }
                                  placeholder="Enter Transfar date"
                                />
                                {formErrors.online_transfer_date && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.online_transfer_date}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                      

                        <div class="row" style={{ display: pay_mode === 'online' ? '' : 'none' }}>
                        <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                            <div class="row">
                              <div class="col-sm-4">
                                <h6 class="text-xl-right">
                                  UTR/ Transfar No.<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-8">
                                <input
                                  class="form-control"
                                  type="text"
                                  value={tranfer_no}
                                  onChange={(e) =>
                                    settranfer_no(e.target.value)
                                  }
                                  placeholder="Enter Transfar No."
                                />
                                {formErrors.tranfer_no && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.tranfer_no}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row" style={{ display: pay_mode === 'online' ? '' : 'none' }}>
                        <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                            <div class="row">
                              <div class="col-sm-4">
                                <h6 class="text-xl-right">
                                  Transfar Mode<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-8">
                                <div class="form-radio row ml-3">
                                  <div class="radio radio-inline col-xs-3 ">
                                    <label for="title_IMPS">
                                      <input
                                        type="radio"
                                        name="transfer_mode"
                                        value={"IMPS"}
                                        id={"title_IMPS"}
                                        checked={transfer_mode === 'IMPS' ? 'checked' : ''}
                                        onChange={(e) =>
                                          settransfer_mode(e.target.value)
                                        }
                                      />
                                      <i class="helper"></i>IMPS
                                    </label>
                                  </div>
                                  <div class="radio radio-inline col-xs-3 ">
                                    <label for="title_VPA">
                                      <input
                                        type="radio"
                                        name="transfer_mode"
                                        id={"title_VPA"}
                                        value={"VPA"}
                                        checked={transfer_mode === 'VPA' ? 'checked' : ''}
                                        onChange={(e) =>
                                          settransfer_mode(e.target.value)
                                        }
                                      />
                                      <i class="helper"></i>VPA
                                    </label>
                                  </div>
                                  <div class="radio radio-inline col-xs-3">
                                    <label for="title_NEFT">
                                      <input
                                        type="radio"
                                        name="transfer_mode"
                                        value={"NEFT/RTGS"}
                                        checked={transfer_mode === 'NEFT/RTGS' ? 'checked' : ''}
                                        id={"title_NEFT"}
                                        onChange={(e) =>
                                          settransfer_mode(e.target.value)
                                        }
                                      />
                                      <i class="helper"></i>NEFT/RTGS
                                    </label>
                                  </div>
                                  
                                </div>
                                {formErrors.transfer_mode && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.transfer_mode}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row" style={{ display: pay_mode === 'saving' ? '' : 'none' }}>
                        <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                            <div class="row">
                              <div class="col-sm-4">
                                <h6 class="text-xl-right">
                                Saving Account<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-8">
                                <input
                                  class="form-control"
                                  type="text"
                                  value={saving_account}
                                  onChange={(e) =>
                                    setsaving_account(e.target.value)
                                  }
                                  placeholder="Enter Saving Account No."
                                />
                                {formErrors.saving_account && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.saving_account}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                            <button
                              class="btn btn-success btn-square"
                              type="submit"
                              disabled={submitting}
                            >
                              DEPOSIT
                            </button>{" "}
                           

                            {
                              shareHolderDetail && (
                                shareHolderDetail.account_for === 'fd' ? (
                                  <a href={`/fixed-deposit-account-view/${dataEncript(shareHolderDetail.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                                ) : shareHolderDetail.account_for === 'mis' ? (
                                     <a href={`/fixed-deposit-mis-account-view/${dataEncript(shareHolderDetail.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                                ): shareHolderDetail.account_for === 'rd' ? (
                                  <a href={`/recurring-deposit-account-view/${dataEncript(shareHolderDetail.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                               ) : shareHolderDetail.account_for === 'dd' ? (
                                <a href={`/recurring-deposit-account-view/${dataEncript(shareHolderDetail.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                             ) : <a href={`/account-view/${dataEncript(shareHolderDetail.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                              )
                            }
                          

                            <ToastContainer className="toast-container" />
                          </div>
                        </div>


                      </form>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-5">

                  <div class="card" style={{borderTop:'3px solid green'}} >
                  <div class="card-header py-3 text-denger">
                        <h5>{acttype} Info</h5>
                              <div class="card-header-right">
                                    <ul class="list-unstyled card-option">
                                        <li><i class="feather icon-minus minimize-card"></i></li>
                                    </ul>
                              </div>
                        </div>
                        <div class="card-block p-0">
                        <form onSubmit={updateDetails}>

                        <div class="table-responsive dt-responsive">
                          <table class="table dtable table-striped table-bordered">

                              <tr>
                                              <th>Member</th>
                                              <td> {shareHolderDetail && shareHolderDetail.member_name && (
                                                  <a href={`/view-member/${dataEncript(shareHolderDetail.member_id)}`} > {FirstLetterCapitalize(shareHolderDetail.member_created_id)} - {FirstLetterCapitalize(shareHolderDetail.member_name)}</a>
                                                  )}</td>
                                              </tr>

                                              <tr>
                                              <th>{acttype} No.</th>
                                              <td> {shareHolderDetail && shareHolderDetail.account_no && (
                                                  <>{(shareHolderDetail.account_no)}</>
                                                  )}</td>
                                              </tr>

                                              <tr>
                                              <th>Scheme	</th>
                                              <td> {shareHolderDetail && shareHolderDetail.scheme_name && (
                                                  <>{(shareHolderDetail.scheme_name)}</>
                                                  )}</td>
                                              </tr>

                                              <tr>
                                              <th>Tenure	</th>
                                              <td> {schemeDetails && schemeDetails.tenure_value && (
                                                <>{(schemeDetails.tenure_value)} {(schemeDetails.tenure_type)}</>
                                                  )}</td>
                                              </tr>

                                              <tr>
                                              <th>Frequency</th>
                                              <td> {schemeDetails && schemeDetails.frequency && (
                                                  <>{(schemeDetails.frequency)}</>
                                                  )}</td>
                                              </tr>

                                              <tr>
                                              <th>Principal Amt.</th>
                                              <td> {shareHolderDetail && shareHolderDetail.mis_amount && (
                                                  <>{formattedAmount(shareHolderDetail.mis_amount)}</>
                                                  )}</td>
                                              </tr>

                                              <tr>
                                              <th>Balance Available		</th>
                                              <td> {shareHolderDetail && shareHolderDetail.mis_amount && (
                                                    <>{formattedAmount(shareHolderDetail.mis_amount)}</>
                                                  )}</td>
                                              </tr>


                              </table>
                        </div>

                        </form>
                        </div>
                    </div>
                  
                  </div>


                </div>


                

              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
          <ToastContainer className="toast-container" />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
