import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import $ from 'jquery';
import 'dropify/dist/css/dropify.min.css';
import 'dropify';

import { dataDecrypt,
    dataEncript,
    
     } from "./../../../helper/commonHelpers";

function CompanyDetails() {
  // const navigate = useNavigate();
  const { memberID }   = useParams();
  const [submitting, setSubmitting] = useState(false);
  // const [stateList, setStateList] = useState([]);
  const [promoterhDetails, setpromoterhDetails] = useState([]);
  const [memberList, setmemberList] = useState([]);


  const [formErrors, setFormErrors] = useState({
    member_id: "",
  });


  // PROMOTER INFO 
  const[member_id, setmember_id] = useState('');
  const[business_type, setbusiness_type] = useState('');
  const[doc_photo, setdoc_photo] = useState('');



  useEffect(() => {
    $('.dropify').dropify();
  // get bran ch details
axios.get(API_BASE_URL+'api-member/get-member-list?member_id='+dataDecrypt(memberID), {
    headers: API_HEADERS
}).then((response) => {

    if (response.data["status"] > 0) {
        setpromoterhDetails(response.data['data']);
        setmember_id(response.data['data'].id);
    }
  }).catch((error) => {
    toast.error('Failed to fetch DETAILS list!');
  });
 


  axios.get(API_BASE_URL + "api-member/get-member-list", {
    headers: API_HEADERS,
  }).then((response) => {
    if (response.data["status"] > 0) {
    setmemberList(response.data["data"]);
    }
  }).catch((error) => {
    toast.error("Failed to fetch data!");
  });
    //    setenrollment_date(getCurrentDate());
  }, [memberID]);


  const handledoc_photo = (e) => {
    const file = e.target.files[0]; 
    setdoc_photo(file); 
  };



  const currentYear = new Date().getFullYear();
  const startYear = 2020;
  const yearsList = [];
  for (let year = currentYear; year >= startYear; year--) {
    yearsList.push(year);
  }


  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));


    const formData = new FormData();
    if (doc_photo) {
      formData.append('form_15gh', doc_photo); 
    }

    formData.append('financial_year', business_type); 
    formData.append('member_id', member_id); 


    // validate form
      if (!member_id) {
        errors.member_id = 'Member is required.';
      }

      if (!business_type) {
        errors.business_type = 'Financial Year is required.';
      }

      if (!doc_photo) {
        errors.doc_photo = 'UPLOAD FORM 15G/ 15H is required.';
      }
    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      axios
        .post(API_BASE_URL + "api-member/add-from-15gh", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/view-member/"+memberID;
            //   window.location.reload();
            }, 2000);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 1000);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };




  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>Upload Form 15G/ 15H - {promoterhDetails && promoterhDetails.fname && (
                                    <span>{promoterhDetails.fname}</span>
                                    )}

                                    {promoterhDetails && promoterhDetails.mname && (
                                    <span>{promoterhDetails.mname}</span>
                                    )} </h4>

                                    
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/members-management">Member List</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href={`/view-member/${dataEncript(promoterhDetails.id)}`} >{promoterhDetails && promoterhDetails.fname && (
                                    <span>{promoterhDetails.fname}</span>
                                    )}

                                    {promoterhDetails && promoterhDetails.mname && (
                                    <span>{promoterhDetails.mname}</span>
                                    )}</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">Upload Form 15G/ 15H</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12"></div>

                


                  <div class="col-sm-12">
                    <div class="card">
                    

                      <div class="card-block">
                        <form onSubmit={updateDetails}>


                        <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Member <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <select
                                    class="form-control m-b"
                                    name="member_id"
                                    value={member_id}
                                    onChange={(e) =>
                                      setmember_id(e.target.value)
                                    }
                                  >
                                    <option value={''} disabled>Select Member</option>
                                    {memberList.map((aBranchData) => (
                                      <option
                                        value={aBranchData.id}
                                        selected={
                                          aBranchData.id === member_id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {aBranchData.fname} &nbsp;
                                        {aBranchData.mnane}

                                      </option>
                                    ))}
                                  </select>
                                  {formErrors.member_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.member_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Financial Year<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <select
                                    class="form-control m-b"
                                    name="business_type"
                                    value={business_type}
                                    onChange={(e) =>
                                      setbusiness_type(e.target.value)
                                    }
                                  >

                                  <option value={' '}>Select Financial Year</option>

                                   {yearsList.map((year) => (
                                    <option key={year} value={`${year} - ${year + 1}`}>
                                        FY {`${year}-${year + 1}`}
                                    </option>
                                    ))}

                                  </select>
                                  {formErrors.business_type && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.business_type}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            
                          </div>


                          <div class="row">
                                  <div class="col-sm-12 col-xl-6 m-b-30">
                                    <div class="row">
                                      <div class="col-sm-5">
                                        <h6 class="text-xl-right">
                                        UPLOAD FORM 15G/ 15H <span class="text-danger">*</span>
                                        </h6>
                                      </div>
                                      <div class="col-sm-7">
                                        
                                      <input class="form-control"  type="file" onChange={handledoc_photo} className="dropify" />
                                        {formErrors.doc_photo && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.doc_photo}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  
                          </div>



                         


                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                              <button
                                class="btn btn-success btn-square"
                                type="submit"
                                disabled={submitting}
                              >
                                Upload Form 15G/ 15H
                              </button>{" "}
                              &nbsp;
                              <a href={`/view-member/${dataEncript(promoterhDetails.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                              &nbsp;
                             

                              <ToastContainer className="toast-container" />
                            </div>
                          </div>


                        </form>
                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
