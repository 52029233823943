import React, { useState, useEffect, useRef } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly

import $ from 'jquery';
import { useParams } from 'react-router-dom'; 

  import { toast, ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

  import {
    dataDecrypt,
    dataEncript,
    formatDate,
    FirstLetterCapitalize,
    deleteConfirmation
  } from './../../../helper/commonHelpers'; 


  import 'select2/dist/css/select2.min.css';
  import 'select2';

function CompanyDetails() {
    // const navigate = useNavigate();
    const { memberID }   = useParams();
    const [submitting, setSubmitting] = useState(false);
    const [memberDetails, setmemberDetails] = useState([]);


    const [promoterhDetails, setpromoterhDetails] = useState([]);
    const [minorList, setminorList] = useState([]);
    const [activeBranchList, setactiveBranchList] = useState([]);
    const [appointList, setappointList] = useState([]);
    const [commentsList, setcommentsList] = useState([]);

    


    // const [nomineeList, setnomineeList] = useState([]);

    const [kyc_status, setkyc_status] = useState('');
    const [sms, setsms]               = useState('no');
    const [account_locked, setaccount_locked] = useState('no');
    const [internet_banking_status, setinternet_banking_status] = useState('no');


    const[associate_id, setassociate_id] = useState('');
    const[subject, setsubject] = useState('');
    const[description, setdescription] = useState('');
    const[meating_date, setmeating_date] = useState('');
    const[meating_time, setmeating_time] = useState('');

    const [formErrors, setFormErrors] = useState({
        kyc_status: "",
      });

    useEffect(() => {

          // get bran ch details
          axios
          .get(API_BASE_URL + "api-company/get-promoter-by-id?promoter_id="+dataDecrypt(memberID), {
            headers: API_HEADERS,
          })
          .then((response) => {
             setpromoterhDetails(response.data['data']);
             setkyc_status(response.data['data'].kyc_status);

             setsms(response.data['data'].sms);
             setaccount_locked(response.data['data'].account_locked);
          })
          .catch((error) => {
            console.log('Failed to fetch  DETAILS list!');
          });


          axios.get(API_BASE_URL + "api-company/get-active-branch-list", {
            headers: API_HEADERS,
          }).then((response) => {
            if (response.data["status"] > 0) {
            setactiveBranchList(response.data["data"]);
            }
          }).catch((error) => {
            toast.error("Failed to fetch data!");
          });

          let minorCondition = `member_type = 'member' AND member_id = ${dataDecrypt(memberID)}`;
          axios.get(API_BASE_URL + "api-common/get-data?tablename=tbl_minor&condition="+minorCondition, {
            headers: API_HEADERS,
          }).then((response) => {

            if (response.data["status"] > 0) {
                setminorList(response.data["data"]);
            } 

          }).catch((error) => {
            console.log('Failed to fetch  MINOR list!');
          });


          axios.get(API_BASE_URL+'api-member/get-member-list?member_id='+dataDecrypt(memberID), { headers: API_HEADERS })
          .then(response => {
                if (response.data["status"] > 0) {
               setmemberDetails(response.data["data"]);
               setkyc_status(response.data['data'].kyc_status);

               setsms(response.data['data'].sms_status);
               setaccount_locked(response.data['data'].account_locked);
               setinternet_banking_status(response.data['data'].internet_banking_status);
             

                    console.log(response.data["data"]);

                if (response.data["data"].appointments && (response.data["data"].appointments).length > 0) {
                    setappointList(response.data["data"].appointments);
                 }

                 if (response.data["data"].comments && (response.data["data"].comments).length > 0) {
                    setcommentsList(response.data["data"].comments);
                 }

            } else {
                toast.error(response.data["message"]);
            }
          }).catch((error) => {
            console.log('Failed to fetch  details list!');
            
          });



      }, [memberID]);



    //   console.log(promoterhDetails);


// update kyc status /updateSettingstatus //updateKYCstatus
const updateSettingstatus = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    // let errors = {};
    const formData = {
        tablename:'tbl_member',  // table name
        where_coloumn_name:'id', // which collumn name you want to  
        where_value:dataDecrypt(memberID), // column value 
        set_coloumn_name:'sms_status', // which collumn name you want to update
        set_value:sms?sms:'no', // collumn value
    };


    const formData1 = {
        tablename:'tbl_member',  // table name
         where_coloumn_name:'id', // which collumn name you want to  
         where_value:dataDecrypt(memberID), // column value 
         set_coloumn_name:'account_locked', // which collumn name you want to update
         set_value:account_locked?account_locked:'no', // collumn value
     };

     const formData2 = {
      tablename:'tbl_member',  // table name
       where_coloumn_name:'id', // which collumn name you want to  
       where_value:dataDecrypt(memberID), // column value 
       set_coloumn_name:'internet_banking_status', // which collumn name you want to update
       set_value:internet_banking_status?internet_banking_status:'no', // collumn value
   };

    // if (!formData.set_value) {
    //     errors.kyc_status = 'Please Select status.';
    //   }

    // Set formErrors state with validation errors
    // setFormErrors(errors);
    // if (Object.keys(errors).length === 0) {
        const confirmed = await deleteConfirmation('Are you sure you want to change setting status?');
        if(confirmed===true)
        {

          

            axios.post(API_BASE_URL + "api-common/update-status", formData1, {
            headers: {
                ...API_HEADERS,
            },
            });

            axios.post(API_BASE_URL + "api-common/update-status", formData2, {
              headers: {
                  ...API_HEADERS,
              },
              });


                axios.post(API_BASE_URL + "api-common/update-status", formData, {
                headers: {
                    ...API_HEADERS,
                },
                })
                .then((response) => {
                if (response.data["status"] > 0) {
                    toast.success(response.data["message"]);
                    // setTimeout(() => {
                    // window.location.reload();
                    // }, 4000);
                } else {
                    toast.error(response.data["message"]);
                }
                })
                .catch((error) => {
                toast.error("Failed to submition!");
                })
                .finally(() => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
                }); // close query hare
        }
    // } else {
    //   setSubmitting(false);
    // } // hare close error
  };

// update kyc status
const updateKYCstatus = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    const formData = {
       tablename:'tbl_member',  // table name
        where_coloumn_name:'id', // which collumn name you want to  
        where_value:dataDecrypt(memberID), // column value 
        set_coloumn_name:'kyc_status', // which collumn name you want to update
        set_value:kyc_status, // collumn value
    };

    if (!formData.set_value) {
        errors.kyc_status = 'Please Select status.';
      }

    // Set formErrors state with validation errors
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
        const confirmed = await deleteConfirmation('Are you sure you want to chnage status?');
        if(confirmed===true)
        {
                        axios
                .post(API_BASE_URL + "api-common/update-status", formData, {
                headers: {
                    ...API_HEADERS,
                },
                })
                .then((response) => {
                if (response.data["status"] > 0) {
                    toast.success(response.data["message"]);
                    setTimeout(() => {
                    window.location.reload();
                    }, 4000);
                } else {
                    toast.error(response.data["message"]);
                }
                })
                .catch((error) => {
                toast.error("Failed to submition!");
                })
                .finally(() => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
                }); // close query hare
        }
    } else {
      setSubmitting(false);
    } // hare close error
  };





// update kyc status /updateSettingstatus //updateKYCstatus
const sendUserCredential = async (e) => {
    e.preventDefault();
        let username = memberDetails.fname+' '+memberDetails.mname;
        const confirmed = await deleteConfirmation('Are You Sure to Reset Credentials and Resend SMS to '+username+' ?');
        if(confirmed===true)
        {
            toast.success('Your credentials has been sent on registered mobile number.');

            //   setTimeout(() => {
            //         window.location.reload();
            //         }, 2800);
        }
  };


  const selectRefBranch = useRef();
  useEffect(() => {
    // Initialize Select2



    setTimeout(() => {
        const $selectBranch = $(selectRefBranch.current);
        // $selectBranch.select2();
        $('#exampleModal').on('shown.bs.modal', function () {
            $selectBranch.select2();
         });
    
        // Handle change event
        $selectBranch.on('change', function () {
          const selectedOptions1 = $selectBranch.val() || [];
          setassociate_id(selectedOptions1);
        });
        // Cleanup Select2 on component unmount
        return () => {
          $selectBranch.select2('destroy');
        };
    }, 1000);

    
  }, []);


  

  const addBookingAppointment = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    const formData = {
        member_id:dataDecrypt(memberID), 
        associate_id: associate_id, 
        subject: subject, 
        description: description, 
        meating_date: meating_date, 
        meating_time: meating_time, 
    };

    if (!formData.associate_id) {
        errors.associate_id = 'Associate/ Staff/ User  is required.';
      }

      if (!formData.subject) {
        errors.subject = 'Subject  is required.';
      }

      if (!formData.description) {
        errors.description = 'Description  is required.';
      }

      if (!formData.meating_date) {
        errors.meating_date = 'Date is required.';
      }

      if (!formData.meating_time) {
        errors.meating_time = 'Time is required.';
      }


    // Set formErrors state with validation errors
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
        // const confirmed = await deleteConfirmation('Are you sure you want to save Appointment?');
        // if(confirmed===true)
        // {
            axios.post(API_BASE_URL + "api-member/add-appointment-info", formData, {
                headers: {
                    ...API_HEADERS,
                },
                })
                .then((response) => {
                if (response.data["status"] > 0) {
                    toast.success(response.data["message"]);
                    setTimeout(() => {
                    window.location.reload();
                    }, 2000);
                } else {
                    toast.error(response.data["message"]);
                }
                })
                .catch((error) => {
                toast.error("Failed to submition!");
                })
                .finally(() => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
                }); // close query hare
        // }
    } else {
      setSubmitting(false);
    } // hare close error
  };


  const ViewAppointDetail = async (index) => {


   
    //  console.log(appointList[index]);
     let html = '';
     html += `<tr>
                <th>Created by</th>
                <td>${FirstLetterCapitalize(memberDetails.fname)} ${FirstLetterCapitalize(memberDetails.mname)}</td>
            </tr>`;

    html += `<tr>
            <th>Subject</th>
                <td>${FirstLetterCapitalize(appointList[index].subject)}</td>
            </tr>`;

        html += `<tr>
            <th>Account</th>
                <td></td>
            </tr>`;

        html += `<tr>
            <th>Agent/ Staff</th>
            <td>${appointList[index].associate_id}</td>
        </tr>`;

        html += `<tr>
            <th>Description</th>
            <td>${FirstLetterCapitalize(appointList[index].description)}</td>
        </tr>`;

        html += `<tr>
                    <th>Meeting Date & Time</th>
                    <td>${appointList[index].meating_date? formatDate(appointList[index].meating_date):''} ${appointList[index].meating_time}</td>
                </tr>`;

        
                if(appointList[index].status==='pending')
                {
                    html += `<tr>
                            <th>Status</th>
                            <td><label class="label label-warning">Pending</label> </td>
                        </tr>`;
                }
                else if(appointList[index].status==='success')
                {
                    html += `<tr>
                            <th>Status</th>
                            <td><label class="label label-success">Success</label> </td>
                        </tr>`;
                }

        
                const modalBody = document.getElementById('appointViewTablebody');
                if (modalBody) {
                  modalBody.innerHTML = html;
                }


  };

  return (
    <DashboardLayout>




     <div class="pcoded-content">
    <div class="pcoded-inner-content">
        <div class="main-body">
            <div class="page-wrapper">
                <div class="page-header">
                    <div class="row align-items-end">
                        <div class="col-lg-8">
                            <div class="page-header-title">
                                <div class="d-inline">
                                    <h4>
                                    {memberDetails && memberDetails.fname && (
                                    <span>{memberDetails.fname}</span>
                                    )}

                                    {memberDetails && memberDetails.mname && (
                                    <span>{memberDetails.mname}</span>
                                    )}
                                    
                                    <small> Member</small></h4>
                                    {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}

                                   



                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="page-header-breadcrumb">
                                <ul class="breadcrumb-title">
                                    <li class="breadcrumb-item" style={{float:'left'}}>
                                        <a href="/dashboard"> <i class="feather icon-home"></i> </a>
                                    </li>
                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="/members-management">Members</a></li>

                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="#!"> 
                                    {memberDetails && memberDetails.fname && (
                                    <span>{memberDetails.fname}</span>
                                    )}

                                    {memberDetails && memberDetails.mname && (
                                    <span>{memberDetails.mname}</span>
                                    )} </a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="page-body">
                    <div class="row">

                    <div class="col-sm-12 mb-3">
                      <a class="btn btn-success mr-1 mt-1" href={`/member-add-shares/${dataEncript(memberDetails.id)}`} >ALLOCATE SHARES</a>
                      <a class="btn btn-warning mr-1 mt-1" href={`/member-shares-amount/${dataEncript(memberDetails.id)}`} >ADD SHARE AMOUNT</a>
                      <a class="btn btn-info mr-1 mt-1"  href={`/member-transaction/${dataEncript(memberDetails.id)}`}  >VIEW TRANSACTIONS</a>
                      <a class="btn btn-warning mr-1 mt-1"  href={`/member-ship-charge/${dataEncript(memberDetails.id)}`} >ADD MEMBERSHIP CHARGES</a>
                      <a class="btn btn-default mr-1 mt-1" title="DOWNLOAD 15G/ 15H" target="_blank" href="#!">
                          <i class="fa fa-print"></i> DOWNLOAD 15G/ 15H
                      </a>
                      <a class="btn btn-warning mr-1 mt-1" title="UPLOAD 15G/ 15H" href={`/member-upload-15g15h/${dataEncript(memberDetails.id)}`}>
                          <i class="fa fa-plus" aria-hidden="true"></i> UPLOAD 15G/ 15H
                      </a>
                      <a onclick="block_ui()" href={`/member-status-inactive/${dataEncript(memberDetails.id)}`}>
                          <button type="button" class="btn btn-info mr-1 mt-1">CHANGE STATUS TO INACTIVE</button>
                      </a>
                      <a class="btn btn-danger mr-1 mt-1" href={`/member-remove/${dataEncript(memberDetails.id)}`}>REMOVE MEMBER</a>
                      <a class="btn btn-primary mr-1 mt-1" target="_blank" href="#!">
                        <i class="fa fa-print"></i> APPLICATION FORM
                      </a>    


                         
                     </div>


                        <div class="col-sm-6 ">
                          <div class="row">
                                <div class="col-md-12">
                                    <div class="card  border-success border-top ">
                                    <div class="card-header ">
                                        <h5>
                                            
                                        </h5>

                                                        <a
                                            style={{ float: "right" }}
                                            class="badge badge-info"
                                            href={`/edit-member/${dataEncript(memberDetails.id)}`}
                                            title="Edit"
                                            >
                                            <i class="feather icon-edit"></i>
                                            </a>
                                        {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                                    </div>

                                    <div class="card-block">
                                    <form > 
                                    <div class="table-responsive dt-responsive">
                                        <table class="table table-striped table-bordered nowrap">
                                        <tr>
                                              <th>Membership Type</th>
                                              <td>
                                                         {memberDetails && memberDetails.membership_type==='nominal' && (
                                                            <label class="label label-warning">Normal</label>
                                                            )}
                                                            {memberDetails && memberDetails.membership_type==='regular' && (
                                                            <label class="label label-success">Regular</label>
                                                            )}
                                              </td>
                                          </tr>
                                          <tr>
                                              <th>Create on</th>
                                              <td>{memberDetails && memberDetails.created_at && (
                                              <span>{ formatDate(memberDetails.created_at)}</span>
                                              )}</td>
                                          </tr>
                                          <tr>
                                              <th>Created by</th>
                                              <td>{memberDetails && memberDetails.enrollment_date && (
                                              <span></span>
                                              )}</td>
                                          </tr>
                                          <tr>
                                              <th>Status</th>
                                              <td>{memberDetails && memberDetails.member_status==='active' && (
                                                            <label class="label label-success">Active</label>
                                                            )}
                                                            {memberDetails && memberDetails.member_status==='inactive' && (
                                                            <label class="label label-success">In-active</label>
                                                            )}
                                                            </td>
                                          </tr>
                                          <tr>
                                              <th>Branch</th>
                                              <td>{memberDetails && memberDetails.branch_name && (
                                              <span>{ memberDetails.branch_name}</span>
                                              )}</td>
                                          </tr>
                                          <tr>
                                              <th>Associate Advisor/Staff</th>
                                              <td>{memberDetails && memberDetails.associate_id && (
                                              <span>{ memberDetails.associate_id}</span>
                                              )}</td>
                                          </tr>
                                          <tr>
                                              <th>Old Member No</th>
                                              <td>{memberDetails && memberDetails.old_member_no && (
                                              <span>{ memberDetails.old_member_no}</span>
                                              )}</td>
                                          </tr>
                                          <tr>
                                              <th>Enrollment Date</th>
                                              <td>{memberDetails && memberDetails.enroll_date && (
                                              <span>{ formatDate(memberDetails.enroll_date)}</span>
                                              )}</td>
                                          </tr>
                                          <tr>
                                              <th>Name</th>
                                              <td>{memberDetails.title?FirstLetterCapitalize(memberDetails.title):''} 
                                                    {memberDetails.fname?FirstLetterCapitalize(memberDetails.fname):''} 
                                                    {memberDetails.mname?FirstLetterCapitalize(memberDetails.mname):''}
                                                    {memberDetails.lname?FirstLetterCapitalize(memberDetails.lname):''}
                                                    </td>
                                          </tr>
                                          <tr>
                                              <th>DOB</th>
                                              <td>{memberDetails && memberDetails.dob && (
                                              <span>{ memberDetails.dob?formatDate(memberDetails.dob):''}</span>
                                              )}</td>
                                          </tr>
                                          <tr>
                                              <th>Age</th>
                                              <td>{memberDetails && memberDetails.dob && (
                                              <span>{ memberDetails.dob?formatDate(memberDetails.dob):''}</span>
                                              )}</td>
                                          </tr>
                                          <tr>
                                              <th>Senior Citizen</th>
                                              <td>{memberDetails && memberDetails.dob && (
                                              <span>{ memberDetails.dob?formatDate(memberDetails.dob):''}</span>
                                              )}</td>
                                          </tr>
                                          <tr>
                                              <th>Gender</th>
                                              <td>  {memberDetails && memberDetails.gender==='male' && (
                                                            <label >Male</label>
                                                            )}
                                                            {memberDetails && memberDetails.gender==='female' && (
                                                            <label >Female</label>
                                                            )}
                                                            {memberDetails && memberDetails.gender==='other' && (
                                                            <label >Other</label>
                                                            )}
                                                            </td>
                                          </tr>
                                          <tr>
                                              <th>Folio No.</th>
                                              <td>{memberDetails && memberDetails.folio_no && (
                                              <span>{ memberDetails.folio_no}</span>
                                              )}</td>
                                          </tr>
                                          <tr>
                                              <th>Father Name</th>
                                              <td>{memberDetails && memberDetails.father_name && (
                                              <span>{memberDetails.father_name?FirstLetterCapitalize(memberDetails.father_name):''}</span>
                                              )}</td>
                                          </tr>
                                          <tr>
                                              <th>Mother Name</th>
                                              <td>{memberDetails && memberDetails.mother_name && (
                                              <span>{memberDetails.mother_name?FirstLetterCapitalize(memberDetails.mother_name):''}</span>
                                              )}</td>
                                          </tr>
                                          <tr>
                                              <th>Marital Status</th>
                                              <td>{memberDetails && memberDetails.marital_status && (
                                              <span>{memberDetails.marital_status?FirstLetterCapitalize(memberDetails.marital_status):''}</span>
                                              )}</td>
                                          </tr>
                                          <tr>
                                              <th>Qualification</th>
                                              <td>{memberDetails && memberDetails.qualification && (
                                              <span>{memberDetails.qualification?FirstLetterCapitalize(memberDetails.qualification):''}</span>
                                              )}</td>
                                          </tr>
                                          <tr>
                                              <th>Husband/Wife Name</th>
                                              <td>{memberDetails && memberDetails.husband_wife_name && (
                                              <span>{memberDetails.husband_wife_name?FirstLetterCapitalize(memberDetails.husband_wife_name):''}</span>
                                              )}</td>
                                          </tr>
                                          <tr>
                                              <th>Husband/Wife D.O.B</th>
                                              <td>{memberDetails && memberDetails.husband_wife_dob && (
                                              <span>{ memberDetails.husband_wife_dob?formatDate(memberDetails.husband_wife_dob):''}</span>
                                              )}</td>
                                          </tr>
                                          <tr>
                                              <th>Occupation</th>
                                              <td>{memberDetails && memberDetails.occupation && (
                                              <span>{memberDetails.occupation?FirstLetterCapitalize(memberDetails.occupation):''}</span>
                                              )}</td>
                                          </tr>
                                          <tr>
                                              <th>Monthly Income</th>
                                              <td>{memberDetails && memberDetails.monthly_income && (
                                              <span>{ memberDetails.monthly_income}</span>
                                              )}</td>
                                          </tr>
                                          <tr>
                                              <th>Collection Time</th>
                                              <td>{memberDetails && memberDetails.collection_time && (
                                              <span>{ memberDetails.collection_time}</span>
                                              )}</td>
                                          </tr>
                                        
                                          <tr>
                                              <th>Form 156/15H Uploaded<br/>(FY 2023-2024)</th>
                                              <td></td>
                                          </tr>
                                        </table>
                                        </div>
                                        </form>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card  border-success border-top ">
                                    <h5 class="card-title accordion-title p-2 py-3">
                                    APPOINTMENT DETAILS 
                                        <a
                                            style={{ float: "right" }}
                                            class="badge badge-success py-1 waves-effect md-trigger" 
                                            data-toggle="modal" data-target="#exampleModal"
                                            href="#!"
                                            title="Edit"
                                            >
                                            <i class="feather icon-plus"></i> NEW APPOINTMENT
                                            </a>
                                    </h5>


 
                                    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title text-center" id="exampleModalLabel">APPOINTMENT INFO</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>

                                        <form onSubmit={addBookingAppointment}>
                                        <div class="modal-body">

                                        <div class="row">
                                        <div class="col-sm-12 col-xl-12 m-b-30">
                                            <div class="row">
                                                <div class="col-sm-4">
                                                <h6 class="text-xl-right">
                                                Associate/ Staff/ User<span class="text-danger">*</span>
                                                </h6>
                                                </div>
                                                <div class="col-sm-8">
                                                <select
                                                    class="form-control m-b "
                                                    data-placeholder="Select Branch"
                                                    name="associate_id"
                                                    value={associate_id}
                                                    ref={selectRefBranch}
                                                >
                                                    <option value={''} disabled>Select Associate</option>

                                                    {activeBranchList.map((branchdata) => (
                                                    <option
                                                        value={branchdata.id}
                                                        selected={
                                                        branchdata.id === associate_id
                                                            ? "selected"
                                                            : ""
                                                        }
                                                    >
                                                        {branchdata.branch_name}

                                                    </option>
                                                    ))}
                                                </select>
                                                {formErrors.associate_id && (
                                                    <span style={{ color: "red" }}>
                                                    {formErrors.associate_id}
                                                    </span>
                                                )}
                                                </div>
                                            </div>
                                            </div>
                                          </div>

                                            <div class="row">
                                                <div class="col-sm-12 col-xl-12 m-b-30">
                                                <div class="row">
                                                    <div class="col-sm-4">
                                                    <h6 class="text-xl-right">
                                                    Subject <span class="text-danger">*</span>
                                                    </h6>
                                                    </div>
                                                    <div class="col-sm-8">
                                                    <input
                                                        class="form-control"
                                                        type="text"
                                                        value={subject}
                                                        onChange={(e) =>
                                                        setsubject(e.target.value)
                                                        }
                                                        placeholder="Enter Subject "
                                                    />
                                                    {formErrors.subject && (
                                                        <span style={{ color: "red" }}>
                                                        {formErrors.subject}
                                                        </span>
                                                    )}
                                                    </div>
                                                </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-sm-12 col-xl-12 m-b-30">
                                                <div class="row">
                                                    <div class="col-sm-4">
                                                    <h6 class="text-xl-right">
                                                    Description <span class="text-danger">*</span>
                                                    </h6>
                                                    </div>
                                                    <div class="col-sm-8">

                                                    <textarea class="form-control" rows={'3'} value={description}
                                                        onChange={(e) =>
                                                        setdescription(e.target.value)
                                                        }
                                                        placeholder="Enter Description " ></textarea>


                                                    {formErrors.description && (
                                                        <span style={{ color: "red" }}>
                                                        {formErrors.description}
                                                        </span>
                                                    )}
                                                    </div>
                                                </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-sm-12 col-xl-12 m-b-30">
                                                <div class="row">
                                                    <div class="col-sm-4">
                                                    <h6 class="text-xl-right">
                                                        Meeting Date & Time <span class="text-danger">*</span>
                                                    </h6>
                                                    </div>
                                                    <div class="col-sm-8">

                                                    <div class="input-group">
                                                        <input class="form-control" type="date" value={meating_date} onChange={(e) => setmeating_date(e.target.value) } placeholder="Enter date " />
                                                        <div class="input-group-prepend" style={{width:'auto'}}>
                                                           <input class="form-control" type="time" value={meating_time} onChange={(e) => setmeating_time(e.target.value) } placeholder="Enter time " />
                                                        </div>
                                                    </div>


                                                    {formErrors.meating_date && (
                                                        <span style={{ color: "red" }}>
                                                        {formErrors.meating_date}
                                                        </span>
                                                    )}

                                                    {formErrors.meating_time && (
                                                        <span style={{ color: "red" }}>
                                                        {formErrors.meating_time}
                                                        </span>
                                                    )}
                                                    
                                                    </div>
                                                </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div class="modal-footer">
                                            <button type="submit" class="btn btn-primary">Save changes</button>
                                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                        </div>
                                       </form>
                                        </div>
                                    </div>
                                    </div>


                                    <div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModal1Label" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title text-center" id="exampleModal1Label">APPOINTMENT INFO</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>

                                        <div class="modal-body">
                                        <div class="table-responsive dt-responsive">
                                        <table class="table table-striped table-bordered nowrap">
                                            <tbody id="appointViewTablebody">


                                            </tbody>
                                        </table>
                                        </div>

                                        </div>
                                     
                                        </div>
                                    </div>
                                    </div>

                                    <div class="card-block p-0">
                                    <form > 
                                    <div class="table-responsive dt-responsive">
                                        <table class="table table-striped table-bordered nowrap">
                                            <thead>
                                                <tr>
                                                    <th>Sr</th>
                                                    <th>SUBJECT</th>
                                                    <th>MEETING DATE</th>
                                                    <th>STATUS</th>
                                                    <th>ACTIONS</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                           
                                            {
                                                appointList.length > 0 ? (
                                                    appointList.map((appointData, index) => (
                                                    <tr class="gradeX">
                                                    <td>{index + 1}</td>
                                                    <td> {appointData && appointData.subject && (
                                                                <span>{FirstLetterCapitalize(appointData.subject)}</span>
                                                                )}</td>

                                                    <td> {appointData && appointData.meating_date && (
                                                                <span>{ appointData.meating_date?formatDate(appointData.meating_date):''}</span>
                                                                )}</td>

                                                    <td>

                                                    {appointData?.status === 'pending' && (
                                                        <label class="label label-warning">Pending</label>
                                                    )}

                                                        {appointData?.status === 'success' && (
                                                        <label class="label label-success">Success</label>
                                                    )}
                                                    </td>
                                                    <td>
                                                        <a href="#!" onClick={() => ViewAppointDetail(index)} data-toggle="modal" data-target="#exampleModal1"  class="label label-success"><i class="fa fa-eye"></i></a>
                                                    </td>
                                                </tr>
                                                ))
                                                ) : (
                                                <tr>
                                                    <td colSpan="5">No data available</td>
                                                </tr>
                                                )
                                            }


                                            </tbody>
                                        </table>
                                        </div>

                                        </form>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">

                                                <div class="card-block accordion-block">
                                                    <div id="accordion" role="tablist" aria-multiselectable="true">


                                                        <div class="card ">
                                                        <div class="accordion-panel">
                                                            <div class="accordion-heading text-white bg-success" role="tab" id="headingOne">
                                                                <h3 class="card-title accordion-title">
                                                                    <a class="accordion-msg " data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                    MEMBER KYC INFO <label class="pull-right"><i class="feather icon-plus"></i></label>
                                                                    </a>
                                                                </h3>
                                                            </div>
                                                            <div id="collapseOne" class="panel-collapse in collapse " role="tabpanel" aria-labelledby="headingOne">
                                                                <div class="accordion-content accordion-desc">
                                                                <div class="table-responsive dt-responsive">
                                                                    <table class="table table-striped table-bordered nowrap">
                                                                    <tr>
                                                                            <th>Aadhaar No</th>
                                                                            <td> {memberDetails && memberDetails.aadhar_no && (
                                                                            <span>{FirstLetterCapitalize(memberDetails.aadhar_no)}</span>
                                                                            )}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Voter ID No. </th>
                                                                            <td> {memberDetails && memberDetails.voter_id_no && (
                                                                            <span>{FirstLetterCapitalize(memberDetails.voter_id_no)}</span>
                                                                            )}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Pan No.      </th>
                                                                            <td> {memberDetails && memberDetails.pan_no && (
                                                                            <span>{FirstLetterCapitalize(memberDetails.pan_no)}</span>
                                                                            )}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Ration Card No.    </th>
                                                                            <td> {memberDetails && memberDetails.ration_card_no && (
                                                                            <span>{FirstLetterCapitalize(memberDetails.ration_card_no)}</span>
                                                                            )}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Meter No.    </th>
                                                                            <td> {memberDetails && memberDetails.meter_no && (
                                                                            <span>{FirstLetterCapitalize(memberDetails.meter_no)}</span>
                                                                            )}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>CI No. </th>
                                                                            <td> {memberDetails && memberDetails.ci_no && (
                                                                            <span>{FirstLetterCapitalize(memberDetails.ci_no)}</span>
                                                                            )}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>CI Relation  </th>
                                                                            <td> {memberDetails && memberDetails.ci_relation && (
                                                                            <span>{FirstLetterCapitalize(memberDetails.ci_relation)}</span>
                                                                            )}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>DL No</th>
                                                                            <td> {memberDetails && memberDetails.dl_no && (
                                                                            <span>{FirstLetterCapitalize(memberDetails.dl_no)}</span>
                                                                            )}</td>
                                                                        </tr>
                                                                    </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>


                                                        <div class="card ">
                                                        <div class="accordion-panel">
                                                            <div class="accordion-heading text-white bg-info" role="tab" id="headingTwo">
                                                                <h3 class="card-title accordion-title">
                                                                    <a class="accordion-msg " data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                                                    NOMINEE INFO <label class="pull-right"><i class="feather icon-plus"></i></label>
                                                                    </a>
                                                                </h3>
                                                            </div>
                                                            <div id="collapseTwo" class="panel-collapse in collapse " role="tabpanel" aria-labelledby="headingTwo">
                                                                <div class="accordion-content accordion-desc">
                                                                <div class="table-responsive dt-responsive">
                                                                    <table class="table table-striped table-bordered nowrap">
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <td> {memberDetails && memberDetails.nominee_name && (
                                                                            <span>{FirstLetterCapitalize(memberDetails.nominee_name)}</span>
                                                                            )}</td>
                                                                        </tr>
                                                                        <tr>
                                                                        <th>DOB</th>
                                                                        <td> {memberDetails && memberDetails.nominee_dob && (
                                                                            <span>{ memberDetails.nominee_dob?formatDate(memberDetails.nominee_dob):''}</span>
                                                                            )}</td>
                                                                        </tr>
                                                                        <tr>
                                                                        <th>Relation</th>
                                                                        <td> {memberDetails && memberDetails.nominee_relation && (
                                                                            <span>{FirstLetterCapitalize(memberDetails.nominee_relation)}</span>
                                                                            )}</td>
                                                                        </tr>
                                                                        <tr>
                                                                        <th>Mobile No.</th>
                                                                        <td> {memberDetails && memberDetails.nominee_mobile && (
                                                                            <span>{memberDetails.nominee_mobile}</span>
                                                                            )}</td>
                                                                        </tr>
                                                                        <tr>
                                                                        <th>Aadhaar No.</th>
                                                                        <td> {memberDetails && memberDetails.nominee_aadhar_no && (
                                                                            <span>{memberDetails.nominee_aadhar_no}</span>
                                                                            )}</td>
                                                                        </tr>
                                                                        <tr>
                                                                        <th>Voter ID No.</th>
                                                                        <td> {memberDetails && memberDetails.nominee_voter_id && (
                                                                            <span>{memberDetails.nominee_voter_id}</span>
                                                                            )}</td>
                                                                        </tr>
                                                                        <tr>
                                                                        <th>Pan No.</th>
                                                                        <td> {memberDetails && memberDetails.nominee_pan_no && (
                                                                            <span>{memberDetails.nominee_pan_no}</span>
                                                                            )}</td>
                                                                        </tr>
                                                                        <tr>
                                                                        <th>Address</th>
                                                                        <td> {memberDetails && memberDetails.nominee_address && (
                                                                            <span>{FirstLetterCapitalize(memberDetails.nominee_address)}</span>
                                                                            )}</td>
                                                                        </tr>
                                                                        <tr>
                                                                        <th>Ration Card No</th>
                                                                        <td> {memberDetails && memberDetails.nominee_ration_card_no && (
                                                                            <span>{memberDetails.nominee_ration_card_no}</span>
                                                                            )}</td>
                                                                        </tr>
                                                                    </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>


                                                        <div class="card ">
                                                        <div class="accordion-panel">
                                                            <div class="accordion-heading text-white bg-danger" role="tab" id="headingThree">
                                                                <h5 class="card-title accordion-title p-2">
                                                                DOCUMENTS 
                                                                        <a class="accordion-msg " style={{display:'inline'}}  data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                                                            <label class="pull-right"><i class="feather icon-plus"></i></label>
                                                                        </a>

                                                                        <a  href={`/member-update-document/${dataEncript(memberDetails.id)}`} style={{display:'inline', cursor:'pointer'}}  >
                                                                        <label class="pull-right mr-2"><i class="feather icon-edit"></i></label>
                                                                        </a>

                                                                    </h5>
                                                            </div>
                                                            <div id="collapseThree" class="panel-collapse in collapse show" role="tabpanel" aria-labelledby="headingThree">
                                                                <div class="accordion-content accordion-desc p-0">
                                                                <div class="table-responsive dt-responsive">
                                                                    <table class="table table-striped table-bordered nowrap">
                                                                    <tr>
                                                                        <th>Photo (Photo)</th>
                                                                        <td>
                                                                           {memberDetails && memberDetails.doc_photo && (
                                                                            <span><a href={memberDetails?.doc_photo ? API_BASE_URL + memberDetails.doc_photo : '#!'} alt="Photo (Photo)" data-lightbox="1" data-title="My caption 1">View</a></span>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Id Proof (Passport)	  </th>
                                                                        <td>
                                                                           {memberDetails && memberDetails.doc_id_proof && (
                                                                            <span><a href={memberDetails?.doc_id_proof ? API_BASE_URL + memberDetails.doc_id_proof : '#!'} alt="Photo (Photo)" data-lightbox="1" data-title="My caption 1">View</a></span>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Address Proof (Passport)     </th>
                                                                        <td>
                                                                           {memberDetails && memberDetails.doc_address_proof && (
                                                                            <span><a href={memberDetails?.doc_address_proof ? API_BASE_URL + memberDetails.doc_address_proof : '#!'} alt="Photo (Photo)" data-lightbox="1" data-title="My caption 1">View</a></span>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Pan Number (Pan) </th>
                                                                        <td>
                                                                           {memberDetails && memberDetails.doc_pan && (
                                                                            <span><a href={memberDetails?.doc_pan ? API_BASE_URL + memberDetails.doc_pan : '#!'} alt="Photo (Photo)" data-lightbox="1" data-title="My caption 1">View</a></span>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Signature (Signature)      </th>
                                                                        <td>
                                                                           {memberDetails && memberDetails.doc_signature && (
                                                                            <span><a href={memberDetails?.doc_signature ? API_BASE_URL + memberDetails.doc_signature : '#!'} alt="Photo (Photo)" data-lightbox="1" data-title="My caption 1">View</a></span>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                    </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>


                                                        <div class="card ">
                                                        <div class="accordion-panel">
                                                            <div class="accordion-heading text-white bg-info" role="tab" id="headingThreejointac">
                                                                <h5 class="card-title accordion-title p-2">
                                                                JOINT ACCOUNTS 
                                                                        <a class="accordion-msg " style={{display:'inline'}}  data-toggle="collapse" data-parent="#accordion" href="#collapseThreejointac" aria-expanded="true" aria-controls="collapseThreejointac">
                                                                            <label class="pull-right"><i class="feather icon-plus"></i></label>
                                                                        </a>

                                                                    </h5>
                                                            </div>
                                                            <div id="collapseThreejointac" class="panel-collapse in collapse show" role="tabpanel" aria-labelledby="headingThreejointac">
                                                                <div class="accordion-content accordion-desc p-0">
                                                                <div class="table-responsive dt-responsive">
                                                                    <table class="table table-striped table-bordered nowrap">
                                                                        <thead>
                                                                           <tr>
                                                                                <th>ACCOUNT TYPE</th>
                                                                                <th>ACCOUNT NO.</th>
                                                                                <th>OPEN DATE</th>
                                                                                <th>STATUS</th>
                                                                           </tr>
                                                                        </thead>
                                                                    </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>

                                                        <div class="card ">
                                                        <div class="accordion-panel">
                                                            <div class="accordion-heading text-white bg-success" role="tab" id="headingcoloan">
                                                                <h5 class="card-title accordion-title p-2">
                                                                CO APPLICANT LOANS
                                                                        <a class="accordion-msg " style={{display:'inline'}}  data-toggle="collapse" data-parent="#accordion" href="#collapseThreecoloan" aria-expanded="true" aria-controls="collapseThreecoloan">
                                                                            <label class="pull-right"><i class="feather icon-plus"></i></label>
                                                                        </a>

                                                                    </h5>
                                                            </div>
                                                            <div id="collapseThreecoloan" class="panel-collapse in collapse show" role="tabpanel" aria-labelledby="headingcoloan">
                                                                <div class="accordion-content accordion-desc p-0">
                                                                <div class="table-responsive dt-responsive">
                                                                    <table class="table table-striped table-bordered nowrap">
                                                                        <thead>
                                                                           <tr>
                                                                                <th>ACCOUNT TYPE</th>
                                                                                <th>ACCOUNT NO.</th>
                                                                                <th>OPEN DATE</th>
                                                                                <th>STATUS</th>
                                                                           </tr>
                                                                        </thead>
                                                                    </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>


                                                        <div class="card ">
                                                        <div class="accordion-panel">
                                                            <div class="accordion-heading text-white bg-warning" role="tab" id="headingmyship">
                                                                <h5 class="card-title accordion-title p-2">
                                                                MY GUARANTOR SHIP
                                                                        <a class="accordion-msg " style={{display:'inline'}}  data-toggle="collapse" data-parent="#accordion" href="#collapsemyship" aria-expanded="true" aria-controls="collapsemyship">
                                                                            <label class="pull-right"><i class="feather icon-plus"></i></label>
                                                                        </a>

                                                                    </h5>
                                                            </div>
                                                            <div id="collapsemyship" class="panel-collapse in collapse show" role="tabpanel" aria-labelledby="headingmyship">
                                                                <div class="accordion-content accordion-desc p-0">
                                                                <div class="table-responsive dt-responsive">
                                                                    <table class="table table-striped table-bordered nowrap">
                                                                        <thead>
                                                                           <tr>
                                                                                <th>ACCOUNT TYPE</th>
                                                                                <th>ACCOUNT NO.</th>
                                                                                <th>OPEN DATE</th>
                                                                                <th>STATE</th>
                                                                           </tr>
                                                                        </thead>
                                                                    </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>

                                                        <div class="card ">
                                                        <div class="accordion-panel">
                                                            <div class="accordion-heading text-white bg-info" role="tab" id="headingmycomments">
                                                                <h5 class="card-title accordion-title p-2">
                                                                MY GUARANTOR SHIP
                                                                        <a class="accordion-msg " style={{display:'inline'}}  data-toggle="collapse" data-parent="#accordion" href="#collapsemycomments" aria-expanded="true" aria-controls="collapsemycomments">
                                                                            <label class="pull-right"><i class="feather icon-plus"></i></label>
                                                                        </a>

                                                                    </h5>
                                                            </div>
                                                            <div id="collapsemycomments" class="panel-collapse in collapse show" role="tabpanel" aria-labelledby="headingmycomments">
                                                                <div class="accordion-content accordion-desc p-0">
                                                                <div class="table-responsive dt-responsive">
                                                                    <table class="table table-striped table-bordered nowrap">
                                                                        <thead>
                                                                           <tr>
                                                                                <th>Sr</th>
                                                                                <th>COMMENT</th>
                                                                                <th>COMMENT BY</th>
                                                                                <th>DATE</th>
                                                                           </tr>
                                                                        </thead>
                                                                        <tbody>


                                                                        {
                                                                        commentsList.length > 0 ? (
                                                                            commentsList.map((appointData, index) => (
                                                                            <tr class="gradeX">
                                                                            <td>{index + 1}</td>
                                                                            <td> {appointData && appointData.comment && (
                                                                                        <span>{FirstLetterCapitalize(appointData.comment)}</span>
                                                                                        )}</td>

                                                                              <td> {appointData && appointData.comment_by && (
                                                                                        <span>{FirstLetterCapitalize(appointData.comment_by)}</span>
                                                                                        )}</td>

                                                                            <td> {appointData && appointData.created_at && (
                                                                                        <span>{ appointData.created_at?formatDate(appointData.created_at):''}</span>
                                                                                        )}</td>

                                                                           
                                                                        </tr>
                                                                        ))
                                                                        ) : (
                                                                        <tr>
                                                                            <td colSpan="5">No data available</td>
                                                                        </tr>
                                                                        )
                                                                    }


                                                                            <tr class="py-2 text-center">
                                                                                <td colSpan={"4"}>
                                                                               
                                                                                    <a  href={`/member-add-comment/${dataEncript(memberDetails.id)}`} class="btn btn-info" > View All </a> &nbsp;  &nbsp; 
                                                                                    <a  href={`/member-add-comment/${dataEncript(memberDetails.id)}`} class="btn btn-success" > Add Comment </a>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>

                                                       

                                                        


                                                    </div>
                                                </div>

                                </div>
                            </div>

                        </div>


                        <div class="col-sm-6 ">
                            <div class="row">
                                    <div className="col-sm-6">
                                            <div class="card bg-c-yellow text-white">
                                                <div class="card-block">
                                                    <div class="row align-items-center">
                                                            <div class="col col-auto text-right">
                                                                <i class="feather icon-user f-50 text-c-yellow"></i>
                                                            </div>
                                                            <div class="col">
                                                                <p class="m-b-5">TOTAL DEPOSITS</p>
                                                                <h4 class="m-b-0">852</h4>
                                                            </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                            <div class="card bg-c-yellow text-white">
                                                <div class="card-block">
                                                    <div class="row align-items-center">
                                                            <div class="col col-auto text-right">
                                                                <i class="feather icon-user f-50 text-c-yellow"></i>
                                                            </div>
                                                            <div class="col p-0 ">
                                                                <p class="m-b-5">LOAN OUTSTANDING</p>
                                                                <h4 class="m-b-0">852</h4>
                                                            </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                            </div>


                            <div class="row">
                                <div class="col-md-12">
                                        <div class="card  border-success border-top ">
                                       

                                        <div class="card-block">
                                            <form onSubmit={updateKYCstatus}> 
                                            <div class="table-responsive dt-responsive">
                                                    <table class="table">
                                                        <tr class="border-0">
                                                            <th class="text-center" style={{ borderTop: '0px solid #dee2e6' }}>CURRENT KYC STATUS</th>
                                                            <td style={{ borderTop: '0px solid #dee2e6' }}>
                                                                
                                                                {memberDetails?.kyc_status === 'null' && (
                                                                  <label class="label label-warning">No</label>
                                                                )}

                                                                 {memberDetails?.kyc_status === 'PENDING' && (
                                                                  <label class="label label-warning">PENDING</label>
                                                                )}

                                                                   {memberDetails?.kyc_status === 'MIN_KYC' && (
                                                                  <label class="label label-info">MIN_KYC</label>
                                                                )}

                                                                {memberDetails?.kyc_status === 'FULL_KYC' && (
                                                                  <label class="label label-success">FULL_KYC</label>
                                                                )}
                                                                
                                                                </td>
                                                        </tr>
                                                        <tr>
                                                            <th class="text-center" style={{width:'0px;'}}>KYC STATUS</th>
                                                            <td>
                                                            <div class="input-group input-group-button">
                                                            <select
                                                                    class="form-control"
                                                                    name="kyc_status"
                                                                    value={kyc_status}
                                                                    onChange={(e) =>
                                                                        setkyc_status(e.target.value)
                                                                    }
                                                                >
                                                                    <option value="">Select Status</option>
                                                                    <option value="PENDING">PENDING</option>
                                                                    <option value="MIN_KYC">MIN_KYC</option>
                                                                    <option value="FULL_KYC">FULL_KYC</option>
                                                                </select>
                                                                <div class="input-group-prepend">
                                                                   <button class="btn btn-success btn-square" type="submit" disabled={submitting}  >Update</button>
                                                                    </div>
                                                                </div>

                                                                {formErrors.kyc_status && (
                                                                    <span style={{ color: "red" }}>
                                                                    {formErrors.kyc_status}
                                                                    </span>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-12">
                                        <div class="card  border-success border-top ">
                                       

                                        <div class="card-header " style={{ borderBottom: '1px solid #dee2e6' }}>
                                            <h5 class="title" >Settings</h5>
                                        </div>

                                        <div class="card-block">
                                        <form onSubmit={updateSettingstatus}> 
                                            <div class="table-responsive dt-responsive">
                                                    <table class="table">

                                                  
                                                    <tr class="border-0">
                                                            <th class="text-center" style={{ borderTop: '0px solid #dee2e6' }} >Internet Banking / Mob App Enabled</th>
                                                            <td style={{ borderTop: '0px solid #dee2e6' }} >

                                                                <input type="checkbox" class="js-large" name="internet_banking_status" checked={internet_banking_status === 'yes'} value="yes" onChange={(e) => {setinternet_banking_status(e.target.checked ? 'yes' : 'no'); updateSettingstatus(e);}} />

                                                            </td>
                                                        </tr>
                                                       
                                                        <tr>
                                                            <th class="text-center" style={{width:'0px;'}}>Account Locked</th>
                                                            <td>
                                                                
                                                            <input type="checkbox" class="js-large" name="account_locked" value={"yes"}  checked={account_locked === 'yes' ? 'checked' : ''} onChange={(e) => {setaccount_locked(e.target.checked ? 'yes' : 'no'); updateSettingstatus(e);}} />

                                                                {formErrors.kyc_status && (
                                                                    <span style={{ color: "red" }}>
                                                                    {formErrors.kyc_status}
                                                                    </span>
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr class="border-0">
                                                            <th class="text-center" style={{ borderTop: '0px solid #dee2e6' }} >SMS {sms}</th>
                                                            <td style={{ borderTop: '0px solid #dee2e6' }} >

                                                                <input type="checkbox" class="js-large" name="sms" checked={sms === 'yes'} value="yes" onChange={(e) => {setsms(e.target.checked ? 'yes' : 'no'); updateSettingstatus(e);}} />

                                                            </td>
                                                        </tr>
                                                    </table>
                                                    </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                        <div class="card  border-primary border-top ">
                                        <div class="card-header " style={{ borderBottom: '1px solid #dee2e6' }}>
                                            <h5 class="title">INTERNET BANKING USERNAME</h5>
                                        </div>

                                        <div class="card-block">
                                            <form > 
                                                    <table class="table" width={'100%'} >
                                                        <tr>
                                                            <th style={{ borderTop: '0px solid #dee2e6' }}>USERNAME</th>
                                                            <td style={{ borderTop: '0px solid #dee2e6' }}>{promoterhDetails && promoterhDetails.internet_user_name && (
                                                                            <span>{FirstLetterCapitalize(promoterhDetails.internet_user_name)}</span>
                                                                            )}</td>
                                                            <td style={{ borderTop: '0px solid #dee2e6' }}>
                                                            <a href={`/member-change-password/${dataEncript(memberDetails.id)}`} title="Rest Password" class="label label-success mr-1"><i class="fa fa-refresh"></i></a>
                                                            <i class="fa fa-sign-in mr-1 btn label label-info" onClick={(e) => {sendUserCredential(e);}}></i></td>
                                                        </tr>
                                                    </table>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-12">
                                        <div class="card  border-success border-top ">
                                        <div class="card-header " style={{ borderBottom: '1px solid #dee2e6' }}>
                                            <h5 class="title">MOBILE & EMAIL DETAILS</h5>
                                            <a href={`/member-change-email-phone/${dataEncript(memberDetails.id)}`} style={{float:'inline-end'}} class=" p-1"><i class="feather icon-edit"></i></a>
                                        </div>

                                        <div class="card-block">
                                            <form > 
                                                    <table class="table" width={'100%'} >
                                                        <tr>
                                                            <th style={{ borderTop: '0px solid #dee2e6' }}>Mobile No	</th>
                                                            <td>{memberDetails && memberDetails.mobile && (
                                                                <span>{memberDetails.mobile}</span>
                                                                )}</td>
                                                        </tr>
                                                        
                                                        <tr>
                                                            <th>Email	</th>
                                                            <td>{memberDetails && memberDetails.email && (
                                                                <span>{ memberDetails.email}</span>
                                                                )}</td>
                                                        </tr>
                                                    </table>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-12">
                                        <div class="card  border-primary border-top ">
                                        <div class="card-header " >
                                            <h5 class="title">Minors</h5>
                                            
                                            <a href={`/member-add-new-minor/${dataEncript(memberDetails.id)}`} style={{float:'inline-end'}} class="label label-info p-1"><i class="feather icon-plus"></i> MINOR</a>
                                            
                                        </div>

                                        <div class="card-block">
                                            
                                        <div class="table-responsive dt-responsive">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                            <th>Sr.</th>
                                                            <th>Name</th>
                                                            <th>DOB</th>
                                                            <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            minorList.length > 0 ? (
                                                            minorList.map((minorData, index) => (
                                                                <tr class="gradeX">
                                                                <td>{index + 1}</td>
                                                                <td >{minorData.title?minorData.title:''}
                                                                    {minorData.fname?minorData.fname:''} {minorData.lname?minorData.lname:''}
                                                                </td>
                                                                <td>{minorData.created_at?formatDate(minorData.created_at):''}</td>
                                                                <td class="center">
                                                                        <a href={`/member-view-minor/${dataEncript(memberDetails.id)}/${dataEncript(minorData.id)}`} class="label label-success"><i class="fa fa-eye"></i></a>
                                                                        <a href={`/member-edit-minor/${dataEncript(memberDetails.id)}/${dataEncript(minorData.id)}`} class="label label-info"><i class="fa fa-edit"></i></a> 
                                                                </td>
                                                            </tr>
                                                            ))
                                                            ) : (
                                                            <tr>
                                                                <td colSpan="3">No data available</td>
                                                            </tr>
                                                            )
                                                        }

                                                        </tbody>
                                                    </table>
                                                    </div>   

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                        <div class="card  border-primary border-top ">
                                        <div class="card-header " style={{ borderBottom: '1px solid #dee2e6' }}>
                                            <h5 class="title" >SHARE HOLDING DETAILS</h5>
                                            {/* <a href={`/add-new-nominee/${dataEncript(memberDetails.id)}`} style={{float:'inline-end'}} class="label label-info p-1"><i class="feather icon-plus"></i> NOMINEE</a> */}
                                        </div>

                                        <div class="card-block">
                                        <div class="table-responsive dt-responsive">
                                                    <table class="table">
                                                    <tr>
                                                            <th style={{ borderTop: '0px solid #dee2e6' }}>NO OF SHARE</th>
                                                            <th style={{ borderTop: '0px solid #dee2e6' }}>0</th>
                                                            </tr>
                                                        
                                                    </table>
                                                    </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
    <div class="col-md-12">
        <div class="card-block accordion-block">
            <div id="r_accordion" role="tablist" aria-multiselectable="true">


                {/* <div class="card ">
                    <div class="accordion-panel">
                        <div class="accordion-heading text-white bg-success" role="tab" id="r_headingOne">
                            <h3 class="card-title accordion-title">
                                <a class="accordion-msg scale_active" data-toggle="collapse" data-parent="#r_accordion" href="#r_collapseOne" aria-expanded="true" aria-controls="r_collapseOne">
                                    SHARE HOLDING NOMINEE'S INFO <label class="pull-right"><i class="feather icon-plus"></i></label>
                                </a>
                            </h3>
                        </div>
                        <div id="r_collapseOne" class="panel-collapse in collapse show" role="tabpanel" aria-labelledby="r_headingOne">
                            <div class="accordion-content accordion-desc">
                                <div class="table-responsive dt-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Sr</th>
                                                <th>NAME</th>
                                                <th>RELATION</th>
                                                <th>ADDRESS</th>
                                                <th>SHARE PERCENTAGE(%)</th>
                                                <th>ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                                  {
                                                            nomineeList?.nominee?.length > 0 ? (
                                                            nomineeList.nominee.map((nomineeData, index) => (
                                                                <tr class="gradeX">
                                                                <td>{index + 1}</td>
                                                                <td >{nomineeData.name?nomineeData.name:''}</td>
                                                                <td >{nomineeData.relation?nomineeData.relation:''}</td>
                                                                <td >{nomineeData.address?nomineeData.address:''}</td>
                                                                <td >{nomineeData.share_holding?nomineeData.share_holding:''}</td>
                                                                <td class="center">
                                                                        <a href={`/add-new-nominee/${dataEncript(memberDetails.id)}`}  class="label label-info"><i class="fa fa-edit"></i></a> 
                                                                </td>
                                                            </tr>
                                                            ))
                                                            ) : (
                                                            <tr>
                                                                <td colSpan="6">No data available</td>
                                                            </tr>
                                                            )
                                                        }
                                        </tbody>
                                        
                                    </table>
                                    </div>  
                            </div>
                        </div>
                    </div>
                </div> */}


                <div class="card ">
                    <div class="accordion-panel">
                        <div class="accordion-heading text-white bg-info" role="tab" id="r_headingTwo">
                            <h5 class="card-title accordion-title p-2">

                            ADDRESS & CONTACT INFO 
                                <a class="accordion-msg " style={{display:'inline'}}  data-toggle="collapse" data-parent="#r_accordion" href="#r_collapseTwo" aria-expanded="true" aria-controls="r_collapseTwo">
                                     <label class="pull-right"><i class="feather icon-plus"></i></label>
                                </a>

                                <a  href={`/member-edit-address/${dataEncript(memberDetails.id)}`} style={{display:'inline', cursor:'pointer'}}  >
                                <label class="pull-right mr-2"><i class="feather icon-edit"></i></label>
                                </a>

                            </h5>
                        </div>
                        <div id="r_collapseTwo" class="panel-collapse in collapse " role="tabpanel" aria-labelledby="r_headingTwo">
                            <div class="accordion-content accordion-desc">
                                <div class="table-responsive dt-responsive">
                                        <table class="table">
                                           
                                            <tbody>
                                                    <tr>
                                                        <th colSpan={2} class="text-center">Correspondence Address</th>
                                                    </tr>
                                                     <tr>
                                                        <th style={{ borderTop: '0px solid #dee2e6' }}>Address</th>
                                                       <td style={{ borderTop: '0px solid #dee2e6' }}>
                                                        {memberDetails && memberDetails.address1 && (
                                                        <span>{ memberDetails.address1}</span>
                                                        )} &nbsp;
                                                          {memberDetails && memberDetails.address2 && (
                                                        <span>{ memberDetails.address2}</span>
                                                        )}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Para/ Word/ Panchyat/ Area </th>
                                                       <td>
                                                        {memberDetails && memberDetails.para && (
                                                        <span>{ memberDetails.para}</span>
                                                        )}
                                                             {memberDetails && memberDetails.ward && (
                                                        <span>/ { memberDetails.ward}</span>
                                                        )}
                                                          {memberDetails && memberDetails.panchayat && (
                                                        <span>/ { memberDetails.panchayat}</span>
                                                        )}

                                                        {memberDetails && memberDetails.area && (
                                                        <span>/ { memberDetails.area}</span>
                                                        )}

                                                        </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <th>Landmark</th>
                                                       <td>
                                                        {memberDetails && memberDetails.landmark && (
                                                        <span>{ FirstLetterCapitalize(memberDetails.landmark)}</span>
                                                        )}
                                                        </td>
                                                    </tr>
                                                   

                                                    

                                                    <tr>
                                                        <th colSpan={2} class="text-center" style={{ borderTop: '0px solid #dee2e6' }}>Permanent Address</th>
                                                    </tr>

                                                    <tr>
                                                        <th style={{ borderTop: '0px solid #dee2e6' }}>Address</th>
                                                       <td style={{ borderTop: '0px solid #dee2e6' }}>
                                                        {memberDetails && memberDetails.prmt_address && (
                                                        <span>{ FirstLetterCapitalize(memberDetails.prmt_address)}</span>
                                                        )}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <th>City / District	</th>
                                                       <td>
                                                        {memberDetails && memberDetails.prmt_city && (
                                                        <span>{ FirstLetterCapitalize(memberDetails.prmt_city)}</span>
                                                        )}

                                                       
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <th>State	</th>
                                                       <td>
                                                        {memberDetails && memberDetails.prmt_state && (
                                                        <span>{ FirstLetterCapitalize(memberDetails.prmt_state)}</span>
                                                        )}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <th>GPS Lat/Log </th>
                                                       <td>
                                                        {memberDetails && memberDetails.loc_latitude && (
                                                        <span>{ memberDetails.loc_latitude}</span>
                                                        )}
                                                        {memberDetails && memberDetails.loc_longitude && (
                                                        <span>/ { memberDetails.loc_longitude}</span>
                                                        )}
                                                        </td>
                                                    </tr>

                                                    

                                            </tbody>
                                            
                                        </table>
                                        </div> 
                            </div>
                        </div>
                    </div>
                </div>


                <div class="card ">
                    <div class="accordion-panel">
                        <div class="accordion-heading text-white bg-warning" role="tab" id="r_headingThree">
                            <h5 class="card-title accordion-title p-2">
                            BANK DETAILS
                            
                                <a class="accordion-msg " style={{display:'inline'}} data-toggle="collapse" data-parent="#r_accordion" href="#r_collapseThree" aria-expanded="true" aria-controls="r_collapseThree">
                                 <label class="pull-right"><i class="feather icon-plus "></i></label>
                                </a>

                                <a  href={`/member-edit-bankdetails/${dataEncript(memberDetails.id)}`} style={{display:'inline', cursor:'pointer'}}  >
                                <label class="pull-right mr-2"><i class="feather icon-edit"></i></label>
                                </a>

                               
                            </h5>
                        </div>
                        <div id="r_collapseThree" class="panel-collapse in collapse " role="tabpanel" aria-labelledby="r_headingThree">
                            <div class="accordion-content accordion-desc">
                            <div class="table-responsive dt-responsive">
                                        <table class="table">
                                           
                                            <tbody>
                                                     <tr>
                                                        <th>Bank Name</th>
                                                        <td>
                                                        {memberDetails && memberDetails.bank_name && (
                                                        <span>{ memberDetails.bank_name}</span>
                                                        )}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>IFSC Code </th>
                                                        <td>{memberDetails && memberDetails.ifsc_code && (
                                                            <span>{ memberDetails.ifsc_code}</span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <th>Account Type</th>
                                                        <td>{memberDetails && memberDetails.account_type && (
                                                            <span>{ memberDetails.account_type}</span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Account No. </th>
                                                        <td>{memberDetails && memberDetails.account_no && (
                                                            <span>{ memberDetails.account_no}</span>
                                                            )}
                                                        </td>
                                                    </tr>

                                                    

                                                    

                                            </tbody>
                                            
                                        </table>
                                        </div> 
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card ">
                    <div class="accordion-panel">
                        <div class="accordion-heading text-white bg-success" role="tab" id="r_headingFour">
                            <h5 class="card-title accordion-title p-2">

                            MEMBER ACCOUNTS

                            </h5>
                        </div>
                        <div id="r_collapseFour" class="panel-collapse in collapse show " role="tabpanel" aria-labelledby="r_headingFour">
                            <div class="accordion-content accordion-desc">
                                
                                <div class="row">
                                    <div class="col-lg-12">
                                    <ul class="nav nav-tabs  tabs" role="tablist">
                                            <li class="nav-item">
                                                    <a class="nav-link active" data-toggle="tab" href="#active_account" role="tab">Active Account</a>
                                            </li>
                                            <li class="nav-item">
                                                    <a class="nav-link" data-toggle="tab" href="#close_account" role="tab">Closed Account</a>
                                            </li>
                                        </ul>

                                        <div class="tab-content tabs card-block p-0">
                                            <div class="tab-pane active" id="active_account" role="tabpanel">
                                                <div class="table-responsive dt-responsive">
                                                    <table class="table">
                                                    <thead>
                                                        <tr>
                                                        <th style={{ borderTop: '0px solid #dee2e6' }}>ACCOUNT TYPE</th>
                                                        <th style={{ borderTop: '0px solid #dee2e6' }}>ACCOUNT NO</th>
                                                        <th style={{ borderTop: '0px solid #dee2e6' }}>OPEN DATE</th>
                                                        <th style={{ borderTop: '0px solid #dee2e6' }}>STATUS</th>
                                                    </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>NAME</td>
                                                    <td>RELATION</td>
                                                    <td>ADDRESS</td>
                                                    <td>SHARE PERCENTAGE(%)</td>
                                                    
                                            </tr>
                                        </tbody>

                                        </table>
                                        </div>  
                                        </div>
                                        <div class="tab-pane" id="close_account" role="tabpanel">
                                            <div class="table-responsive dt-responsive">
                                                    <table class="table">
                                                    <thead>
                                                        <tr>
                                                        <th style={{ borderTop: '0px solid #dee2e6' }}>ACCOUNT TYPE</th>
                                                        <th style={{ borderTop: '0px solid #dee2e6' }}>ACCOUNT NO</th>
                                                        <th style={{ borderTop: '0px solid #dee2e6' }}>OPEN DATE</th>
                                                        <th style={{ borderTop: '0px solid #dee2e6' }}>STATUS</th>
                                                    </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>NAME</td>
                                                    <td>RELATION</td>
                                                    <td>ADDRESS</td>
                                                    <td>SHARE PERCENTAGE(%)</td>
                                                    
                                            </tr>
                                        </tbody>

                                        </table>
                                        </div>  
                                        </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </div>

    </div>
</div>



                        </div>



                    </div>
                </div>
            </div>
        </div>

        <div id="styleSelector"></div>
        <ToastContainer className="toast-container" />

    </div>
</div>

    </DashboardLayout>
  );
}

export default CompanyDetails;
