import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { dataDecrypt,
    dataEncript,
    FirstLetterCapitalize,
    formatDate,
    deleteConfirmation
     } from "./../../../helper/commonHelpers";

function CompanyDetails() {
  // const navigate = useNavigate();
  const { accountID }   = useParams();
  const [promoterhDetails, setpromoterhDetails] = useState([]);
  const [commentsList, setcommentsList] = useState([]);



  // PROMOTER INFO 

  useEffect(() => {

  // get bran ch details
 
axios.get(API_BASE_URL+'api-common/get-account-list?id='+dataDecrypt(accountID), {
    headers: API_HEADERS
}).then((response) => {

    if (response.data["status"] > 0) {
        setpromoterhDetails(response.data['data']);
    }
}).catch((error) => {
  toast.error('Failed to fetch DETAILS list!');
});

axios.get(API_BASE_URL+'api-member/get-transaction-list?member_id='+dataDecrypt(accountID), {
    headers: API_HEADERS
}).then((response) => {
  // console.log(response.data["data"]);
    if (response.data["status"] > 0) {
        setcommentsList(response.data["data"]);
    }
}).catch((error) => {
  toast.error('Failed to fetch DETAILS list!');
});
 

    //    setenrollment_date(getCurrentDate());
  }, [accountID]);


  const deleteTransaction = async (tblid) => {
        const confirmed = await deleteConfirmation('Are you sure to delete transaction?');
        if(confirmed===true)
        {
            const formData = {
                tablename:'tbl_transaction',  // table name
                coloum_name:'id', // which collumn name you want to  
                value:tblid, // column value 
             };

             axios.post(API_BASE_URL + "api-common/detete", formData, {
                headers: {
                    ...API_HEADERS,
                },
                })
                .then((response) => {
                if (response.data["status"] > 0) {
                    toast.success(response.data["message"]);
                    setTimeout(() => {
                         window.location.reload();
                    }, 2700);
                } else {
                    toast.error(response.data["message"]);
                }
                })
                .catch((error) => {
                   toast.error("Failed to submition!");
                }); // close query hare
        }
  };



 
  


  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>
                        {
                            promoterhDetails && (
                              promoterhDetails.account_for === 'fd' ? (
                                <> FD Account </>
                              ) : promoterhDetails.account_for === 'mis' ? (
                                <>MIS Deposits </>
                              ) : promoterhDetails.account_for === 'rd' ? (
                                <> Recurring Deposits </>
                              ): promoterhDetails.account_for === 'dd' ? (
                                <> Daily Deposits </>
                              ) : <></>
                            )
                          }
                              - {promoterhDetails && promoterhDetails.account_no && (
                                    <>{promoterhDetails.account_no}</>
                                    )} <span>Other Charges</span> </h4>

                                    
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                        {
                            promoterhDetails && (
                              promoterhDetails.account_for === 'fd' ? (
                                <a href="/fixed-deposit-account">FD Accounts </a>
                              ) : promoterhDetails.account_for === 'mis' ? (
                                <a href="/fixed-deposit-mis-account">MIS Deposits </a>
                              ) : promoterhDetails.account_for === 'rd' ? (
                                <a href="/recurring-deposit-account">Recurring Deposits </a>
                              )  : promoterhDetails.account_for === 'dd' ? (
                                <a href="/recurring-deposit-account">Daily Deposits </a>
                              ) : <></>
                            )
                          }
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                        {
                            promoterhDetails && (
                              promoterhDetails.account_for === 'fd' ? (
                                <a href={`/fixed-deposit-account-view/${dataEncript(promoterhDetails.id)}`}> {promoterhDetails && promoterhDetails.account_no && (
                                  <>{promoterhDetails.account_no}</>
                                  )} </a>
                              ) : promoterhDetails.account_for === 'mis' ? (
                                <a href={`/fixed-deposit-mis-account-view/${dataEncript(promoterhDetails.id)}`}> {promoterhDetails && promoterhDetails.account_no && (
                                  <>{promoterhDetails.account_no}</>
                                  )} </a>
                              ) : promoterhDetails.account_for === 'rd' ? (
                                <a href={`/recurring-deposit-account-view/${dataEncript(promoterhDetails.id)}`}> {promoterhDetails && promoterhDetails.account_no && (
                                  <>{promoterhDetails.account_no}</>
                                  )} </a>
                              ) : promoterhDetails.account_for === 'dd' ? (
                                <a href={`/recurring-deposit-account-view/${dataEncript(promoterhDetails.id)}`}> {promoterhDetails && promoterhDetails.account_no && (
                                  <>{promoterhDetails.account_no}</>
                                  )} </a>
                              ) : <></>
                            )
                          }
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">Other Charges</a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="col-md-12 mt-3">
                    <a class="btn btn-danger mt-2 "  href={`/recurring-deposit-account-other-charges-pay/${accountID}`}>DEBIT OTHER CHARGES</a>
                    <a class="btn btn-success mt-2 ml-1 "  href={`/recurring-deposit-account-clear-due/${accountID}`}>CLEAR DUES</a>
                  </div>

                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12"></div>

                  <div class="col-sm-12">

                 


                    <div class="card ">
                      <div class="card-header">
                        {/* <h5>Edit Promoter</h5> */}
                        {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                      </div>

                      <div class="card-block">


                      <div class="table-responsive dt-responsive">
                        <table class="table table-striped table-bordered nowrap">
                            <thead>
                                <tr>
                                    <th>Sr</th>
                                    <th>T. DATE</th>
                                    <th>PAY MODE</th>
                                    <th>REMARKS</th>
                                    <th>STATUS</th>
                                    <th>DEBIT</th>
                                    <th>CREDIT</th>
                                    <th>BALANCE</th>
                                    <th>ACCOUNTED</th>
                                    <th>ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>


                            {
                            commentsList.length > 0 ? (
                                commentsList.map((appointData, index) => (
                                <tr class="gradeX">
                                <td>{index + 1}</td>
                                <td> {appointData && appointData.transaction_date && (
                                    <span>{ appointData.transaction_date?formatDate(appointData.transaction_date):''}</span>
                                    )}</td>

                                  <td> {appointData && appointData.pay_mode && (
                                            <span>{FirstLetterCapitalize(appointData.pay_mode)}</span>
                                            )}</td>

                                    <td> {appointData && appointData.remark && (
                                      <span>{FirstLetterCapitalize(appointData.remark)}</span>
                                      )}</td>

                                   <td>
                                            {
                                              appointData && (
                                                appointData.status === 'pending' ? (
                                                  <label className="label label-warning">Pending</label>
                                                ) : appointData.status === 'approve' ? (
                                                  <label className="label label-success">Approve</label>
                                                ) : null
                                              )
                                            }
                                      </td>



                                   <td> {appointData && appointData.amount_type && (
                                      <span>{FirstLetterCapitalize(appointData.amount_type)}</span>
                                      )}</td>

                                      <td> {appointData && appointData.amount_type && (
                                      <span>{FirstLetterCapitalize(appointData.amount_type)}</span>
                                      )}</td>

                                      <td> {appointData && appointData.amount_type && (
                                      <span>{FirstLetterCapitalize(appointData.amount_type)}</span>
                                      )}</td>

                                  


                                     

                                    <td>  {
                                              appointData && (
                                                appointData.credited_in_account === 'no' ? (
                                                  <label className="label label-danger">No</label>
                                                ) : appointData.credited_in_account === 'yes' ? (
                                                  <label className="label label-success">Yes</label>
                                                ) : null
                                              )
                                            }
                                     </td>

                                <td>

                                          <a href={`/member-transaction-view/${accountID}/${dataEncript(appointData.id)}`} class='font-weight-normal label label-success'><i class="fa fa-eye"></i></a>&nbsp;
                                          <a href={`/edit-member/${dataEncript(appointData.id)}`}  class='font-weight-normal label label-info'> <i class="fa fa-print"></i> </a>&nbsp;
                                          <a href="#!"   onClick={() => deleteTransaction(appointData.id)}   class='font-weight-normal label label-danger'> <i class="fa fa-trash"></i> </a>&nbsp;
                                </td>

                                
                            </tr>
                            ))
                            ) : (
                            <tr>
                                <td colSpan="10">No data available</td>
                            </tr>
                            )
                        }
                              
                            </tbody>
                        </table>
                        </div>
                        <ToastContainer className="toast-container" />
                      </div>
                    </div>
                  </div>


                  



                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
