import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { dataDecrypt,
    dataEncript,
     } from "./../../../helper/commonHelpers";

function CompanyDetails() {
  // const navigate = useNavigate();
  const { promoterID }   = useParams();
  const [submitting, setSubmitting] = useState(false);
  // const [stateList, setStateList] = useState([]);
  const [promoterhDetails, setpromoterhDetails] = useState([]);
  const [stateList, setstateList] = useState([]);

  const [formErrors, setFormErrors] = useState({
    state: "",
    country: "",
    mobile_no:""

  });


  // PROMOTER INFO 
  const[address1, setaddress1] = useState('');
  const[address2, setaddress2] = useState('');
  const[para, setpara] = useState('');
  const[ward, setward] = useState('');
  const[panchayat, setpanchayat] = useState('');
  const[area, setarea] = useState('');
  const[landmark, setlandmark] = useState('');
  const[city, setcity] = useState('');
  const[state, setstate] = useState('');
  const[pincode, setpincode] = useState('');
  const[country, setcountry] = useState('India');
  const[loc_lat, setloc_lat] = useState('');
  const[loc_long, setloc_long] = useState('');
  const[email, setemail] = useState('');
  const[mobile_no, setmobile_no] = useState('');

  useEffect(() => {

    axios.get(API_BASE_URL+'api-common/get-state-list', { headers: API_HEADERS })
    .then(response => {
        setstateList(response.data['data']);
    })
    .catch(error => {
      console.error('Failed to fetch role list list!', error);
    });

  axios.get(API_BASE_URL + "api-company/get-promoter-by-id?promoter_id="+dataDecrypt(promoterID), {
    headers: API_HEADERS,
  })
  .then((response) => {

    if (response.data["status"] > 0) {
        setpromoterhDetails(response.data['data']);

        setaddress1(response.data["data"].address1);
        setaddress2(response.data["data"].address2);
        setpara(response.data["data"].para);
        setward(response.data["data"].ward);
        setpanchayat(response.data["data"].panchayat);
        setarea(response.data["data"].area);
        setlandmark(response.data["data"].landmark);
        setcity(response.data["data"].city);
        setstate(response.data["data"].state);

        setpincode(response.data["data"].pincode);
        setcountry((response.data["data"].country?response.data["data"].country:country));

        setloc_lat(response.data["data"].loc_lat);
        setloc_long(response.data["data"].loc_long);

        setemail(response.data["data"].email);
        setmobile_no(response.data["data"].mobile_no);

    } else {
        toast.error(response.data["message"]);
    }


  })
  .catch((error) => {
    toast.error('Failed to fetch DETAILS list!');
  });
    //    setenrollment_date(getCurrentDate());
  }, [promoterID, country]);

  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};

    const formData = {
        address1:address1,
        address2:address2,
        para:para,
        ward:ward,
        panchayat:panchayat,
        area:area,
        landmark:landmark,
        city:city,
        state:state,
        pincode:pincode,
        country:country,
        loc_lat:loc_lat,
        loc_long:loc_long,
        email:email,
        mobile_no:mobile_no,
        promoter_id:dataDecrypt(promoterID)
    };


    // validate form
   

      if (!formData.state) {
        errors.state = 'State is required.';
      }

      if (!formData.country) {
        errors.country = 'Country is required.';
      }

      if (!formData.mobile_no) {
        errors.mobile_no = 'Mobile is required.';
      }
    

      // console.log(formData);

    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      axios
        .post(API_BASE_URL + "api-company/update-promoter-address", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/view-promoter/"+dataEncript(promoterhDetails.id);
            }, 2700);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 300);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>Edit  - {promoterhDetails && promoterhDetails.fname && (
                                    <>{promoterhDetails.fname}</>
                                    )}

                                    {promoterhDetails && promoterhDetails.mname && (
                                    <>{promoterhDetails.mname}</>
                                    )}
                                      <small>&nbsp;Edit Contact Info</small>
                                     </h4>

                                    
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/promoter-list">Promoter List</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href={`/view-promoter/${dataEncript(promoterhDetails.id)}`} >{promoterhDetails && promoterhDetails.fname && (
                                    <span>{promoterhDetails.fname}</span>
                                    )}

                                    {promoterhDetails && promoterhDetails.mname && (
                                    <span>{promoterhDetails.mname}</span>
                                    )}</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">Edit Bank info</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12"></div>

                  <div class="col-sm-12">
                    <div class="card">
                      <div class="card-header">
                        {/* <h5>Edit Bank</h5> */}
                        {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                      </div>

                      <div class="card-block">
                        <form onSubmit={updateDetails}>


                        <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30 ">
                                <div class="row">
                                <div class="col-sm-5">
                                    <h6 class="text-xl-right">Mobile No.<span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-sm-7">
                                    <input class="form-control" type="text" value={mobile_no} onChange={(e) => setmobile_no(e.target.value)} placeholder='Enter Mobile No.' />
                                    {formErrors.mobile_no && <span style={{ color: 'red' }}>{formErrors.mobile_no}</span>}
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30 ">
                                <div class="row">
                                <div class="col-sm-5">
                                    <h6 class="text-xl-right">Email</h6>
                                    </div>
                                    <div class="col-sm-7">
                                    <input class="form-control" type="email" value={email} onChange={(e) => setemail(e.target.value)} placeholder='Enter Email' />
                                    {formErrors.email && <span style={{ color: 'red' }}>{formErrors.email}</span>}
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30 ">
                                <div class="row">
                                <div class="col-sm-5">
                                    <h6 class="text-xl-right">Address Line1</h6>
                                    </div>
                                    <div class="col-sm-7">
                                    <input class="form-control" type="text" value={address1} onChange={(e) => setaddress1(e.target.value)} placeholder='Enter Address Line1' />
                                    {formErrors.address1 && <span style={{ color: 'red' }}>{formErrors.address1}</span>}
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30 ">
                                <div class="row">
                                <div class="col-sm-5">
                                    <h6 class="text-xl-right">Address Line2</h6>
                                    </div>
                                    <div class="col-sm-7">
                                    <input class="form-control" type="text" value={address2} onChange={(e) => setaddress2(e.target.value)} placeholder='Enter Address Line2' />
                                    {formErrors.address2 && <span style={{ color: 'red' }}>{formErrors.address2}</span>}
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30 ">
                                <div class="row">
                                <div class="col-sm-5">
                                    <h6 class="text-xl-right">Para</h6>
                                    </div>
                                    <div class="col-sm-7">
                                    <input class="form-control" type="text" value={para} onChange={(e) => setpara(e.target.value)} placeholder='Enter Para' />
                                    {formErrors.para && <span style={{ color: 'red' }}>{formErrors.para}</span>}
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30 ">
                                <div class="row">
                                <div class="col-sm-5">
                                    <h6 class="text-xl-right">Ward</h6>
                                    </div>
                                    <div class="col-sm-7">
                                    <input class="form-control" type="text" value={ward} onChange={(e) => setward(e.target.value)} placeholder='Enter Ward' />
                                    {formErrors.ward && <span style={{ color: 'red' }}>{formErrors.ward}</span>}
                                    </div>
                                </div>
                            </div>

                        </div>

                        
                        <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30 ">
                                <div class="row">
                                <div class="col-sm-5">
                                    <h6 class="text-xl-right">Panchayat</h6>
                                    </div>
                                    <div class="col-sm-7">
                                    <input class="form-control" type="text" value={panchayat} onChange={(e) => setpanchayat(e.target.value)} placeholder='Enter Panchayat' />
                                    {formErrors.panchayat && <span style={{ color: 'red' }}>{formErrors.panchayat}</span>}
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30 ">
                                <div class="row">
                                <div class="col-sm-5">
                                    <h6 class="text-xl-right">Area</h6>
                                    </div>
                                    <div class="col-sm-7">
                                    <input class="form-control" type="text" value={area} onChange={(e) => setarea(e.target.value)} placeholder='Enter Area' />
                                    {formErrors.area && <span style={{ color: 'red' }}>{formErrors.area}</span>}
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30 ">
                                <div class="row">
                                <div class="col-sm-5">
                                    <h6 class="text-xl-right">Landmark</h6>
                                    </div>
                                    <div class="col-sm-7">
                                    <input class="form-control" type="text" value={landmark} onChange={(e) => setlandmark(e.target.value)} placeholder='Enter Landmark' />
                                    {formErrors.landmark && <span style={{ color: 'red' }}>{formErrors.landmark}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">

                        <div class="col-sm-12 col-xl-6 m-b-30 ">
                                <div class="row">
                                <div class="col-sm-5">
                                    <h6 class="text-xl-right">City<span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-sm-7">
                                    <input class="form-control" type="text" value={city} onChange={(e) => setcity(e.target.value)} placeholder='Enter City' />
                                    {formErrors.city && <span style={{ color: 'red' }}>{formErrors.city}</span>}
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30 ">
                                <div class="row">
                                <div class="col-sm-5">
                                    <h6 class="text-xl-right">State<span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-sm-7">

                                    <select
                                          class="form-control"
                                          name="state"
                                          value={state}
                                          onChange={(e) =>
                                            setstate(e.target.value)
                                          }
                                        >
                                         <option value="">Select State</option>

                                         {
                                                stateList.length > 0 ? (
                                                  stateList.map((stateDats, index) => (
                                                    <option value={stateDats.full_name}>{stateDats.full_name}</option>
                                                  ))
                                                ) : (
                                                  <></>
                                                )
                                              }


                                        </select>
                                    {formErrors.state && <span style={{ color: 'red' }}>{formErrors.state}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="row">

                        <div class="col-sm-12 col-xl-6 m-b-30 ">
                                <div class="row">
                                <div class="col-sm-5">
                                    <h6 class="text-xl-right">Pincode<span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-sm-7">
                                    <input class="form-control" type="text" value={pincode} onChange={(e) => setpincode(e.target.value)} placeholder='Enter Pin code' />
                                    {formErrors.pincode && <span style={{ color: 'red' }}>{formErrors.pincode}</span>}
                                    </div>
                                </div>
                            </div>

                                            <div class="col-sm-12 col-xl-6 m-b-30 ">
                                                <div class="row">
                                                <div class="col-sm-5">
                                                    <h6 class="text-xl-right">Country<span class="text-danger">*</span></h6>
                                                    </div>
                                                    <div class="col-sm-7">

                                                    <input class="form-control" type="text" value={country} onChange={(e) => setcountry(e.target.value)} placeholder='Enter Country' readOnly />
                                                    {formErrors.country && <span style={{ color: 'red' }}>{formErrors.country}</span>}
                                                    </div>
                                                </div>
                                            </div>
                            </div>
                        
                        
                        <hr/>
                        <div class="row">

                        <div class="col-sm-12 col-xl-6 m-b-30 ">
                                <div class="row">
                                <div class="col-sm-5">
                                    <h6 class="text-xl-right">Location Latitude<span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-sm-7">
                                    <input class="form-control" type="text" value={loc_lat} onChange={(e) => setloc_lat(e.target.value)} placeholder='Enter Location Latitude code' />
                                    {formErrors.loc_lat && <span style={{ color: 'red' }}>{formErrors.loc_lat}</span>}
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30 ">
                                <div class="row">
                                <div class="col-sm-5">
                                    <h6 class="text-xl-right">Location Longitude<span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-sm-7">
                                    <input class="form-control" type="text" value={loc_long} onChange={(e) => setloc_long(e.target.value)} placeholder='Enter Location Longitude code' />
                                    {formErrors.loc_long && <span style={{ color: 'red' }}>{formErrors.loc_long}</span>}
                                    </div>
                                </div>
                            </div>
                            </div>

                            
                        



                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                              <button
                                class="btn btn-success btn-square"
                                type="submit"
                                disabled={submitting}
                              >
                                Update Details
                              </button>{" "}
                              &nbsp;
                              <a href={`/view-promoter/${dataEncript(promoterhDetails.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                              &nbsp;
                             

                              <ToastContainer className="toast-container" />
                            </div>
                          </div>


                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
