import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { dataDecrypt,
    dataEncript,
    getCurrentDate
     } from "./../../../helper/commonHelpers";

function CompanyDetails() {
  // const navigate = useNavigate();
  const { memberID }   = useParams();
  const [submitting, setSubmitting] = useState(false);
  // const [stateList, setStateList] = useState([]);
  const [promoterhDetails, setpromoterhDetails] = useState([]);
  const [bankList, setbankList] = useState([]);


  const [formErrors, setFormErrors] = useState({
    member_id: "",
    transfer_date: "",
    shares: "",
    transaction_date: "",
    amount: "",
  });


  // PROMOTER INFO 
  
  
  const[pay_mode, setpay_mode] = useState('cash');
  const[remark, setremark] = useState('');
  const[amount, setamount] = useState('84.75');

  const[mgst, setmgst] = useState('18');
  const[total_amount, settotal_amount] = useState('100');

  
  const[transaction_date, settransaction_date] = useState('');
  const[net_collect, setnet_collect] = useState('100');



const[bank_name, setbank_name] = useState('');
const[cheque_no, setcheque_no] = useState('');
const[cheque_date, setcheque_date] = useState('');
const[online_transfer_date, setonline_transfer_date] = useState('');

const[tranfer_no, settranfer_no] = useState('');
const[transfer_mode, settransfer_mode] = useState('');
const[saving_account, setsaving_account] = useState('');

  useEffect(() => {

  // get bran ch details
 
axios.get(API_BASE_URL+'api-member/get-member-list?member_id='+dataDecrypt(memberID), {
    headers: API_HEADERS
}).then((response) => {

    if (response.data["status"] > 0) {
        setpromoterhDetails(response.data['data']);
    }
  }).catch((error) => {
    toast.error('Failed to fetch DETAILS list!');
  });
 




  axios.get(API_BASE_URL + "api-common/get-bank-list", {
    headers: API_HEADERS,
  }).then((response) => {
    if (response.data["status"] > 0) {
      setbankList(response.data["data"]);
      }
  }).catch((error) => {
    toast.error("Failed to fetch data!");
  });


  settransaction_date(getCurrentDate());
    //    setenrollment_date(getCurrentDate());
  }, [memberID]);

  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));

    const formData = {
      member_id:dataDecrypt(memberID),
      account_type:'member',
      transaction_type: 'Credit', 
      amount_type: 'Membership Charges', 

      
      pay_mode: pay_mode,
        remark: remark,
        amount: amount,
        transaction_date: transaction_date,

        bank_name: bank_name,
        cheque_no: cheque_no,
        cheque_date: cheque_date,
        online_transfer_date: online_transfer_date,
        utr_no: tranfer_no,

        transfer_mode: transfer_mode,
        saving_account: saving_account,

        member_fee_amount: amount,

        mgst: mgst,
        tamount: total_amount

    };

    // validate form

      if (!transaction_date) {
        errors.transaction_date = 'Transaction date is required.';
      }

      if (!amount) {
        errors.amount = 'Amount is required.';
      }


      if (!pay_mode) {
        errors.pay_mode = 'Pay Mode is required.';
      }


      if(pay_mode==='cheque')
      {
        if (!formData.bank_name) {
          errors.bank_name = 'Bank Name is required.';
        }

        if (!formData.cheque_no) {
          errors.cheque_no = 'Cheque No. is required.';
        }

        if (!formData.cheque_date) {
          errors.cheque_date = 'Cheque Date. is required.';
        }
      }
  
      if(pay_mode==='online')
      {
        if (!formData.transfer_date) {
          errors.transfer_date = 'Transfer Date is required.';
        }

        if (!online_transfer_date) {
          errors.online_transfer_date = 'Transfar date No is required.';
        }

        if (!formData.transfer_mode) {
          errors.transfer_mode = 'Transfer Mode is required.';
        }
      }

      // if(pay_mode==='saving')
      // {
      //   if (!formData.saving_account) {
      //     errors.saving_account = 'Saving Account is required.';
      //   }
      // }


    //   console.log(formData);
    //   console.log(errors);


    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      axios
        .post(API_BASE_URL + "api-member/add-share-amount", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/view-member/"+memberID;
            //   window.location.reload();
            }, 2000);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 1000);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };




  

  const calculateamount = async (ttlamount) => {
    let module = 1+mgst/100;
    setamount((ttlamount/module).toFixed(2));

    setnet_collect(ttlamount);
};


  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>Member - {promoterhDetails && promoterhDetails.fname && (
                                    <>{promoterhDetails.fname}</>
                                    )}

                                    {promoterhDetails && promoterhDetails.mname && (
                                    <>{promoterhDetails.mname}</>
                                    )} </h4>

                                    
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/members-management">Member List</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href={`/view-member/${dataEncript(promoterhDetails.id)}`} >{promoterhDetails && promoterhDetails.fname && (
                                    <span>{promoterhDetails.fname}</span>
                                    )}

                                    {promoterhDetails && promoterhDetails.mname && (
                                    <span>{promoterhDetails.mname}</span>
                                    )}</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">Membership Charges</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12">
                  </div>

                


                  <div class="col-sm-12">
                    <div class="card">
                    

                      <div class="card-block">
                        <form onSubmit={updateDetails}>




                          <div class="row">
                           <div class="col-sm-12 py-3">
                                <h4 class="text-center">Membership Fee</h4>
                            </div>

                            <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                                <div class="row">
                                    <div class="col-sm-5">
                                    <h6 class="text-xl-right">Transaction Date<span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-sm-7">
                                    <input
                                        class="form-control"
                                        type="date"
                                        value={transaction_date}
                                        onChange={(e) =>
                                        settransaction_date(e.target.value)
                                        }
                                        placeholder="Enter Transaction Date. "
                                    />
                                    {formErrors.transaction_date && (
                                        <span style={{ color: "red" }}>
                                        {formErrors.transaction_date}
                                        </span>
                                    )}
                                    </div>
                                </div>
                                </div>
                            </div>



                          <div class="row">
                          <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Membership Fee
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                    <div class="row">
                                      <div class="col-xs-4 col-4 text-center"><h6 style={{width:'inherit'}}>Amount</h6></div>
                                      <div class="col-xs-4 col-4 text-center"><h6 style={{width:'inherit'}}>MGST</h6> </div>
                                      <div class="col-xs-4 col-4 text-center"><h6 style={{width:'inherit'}}>Total Amount</h6></div>
                                    </div>

                                <div class="input-group">
                                  <input class="form-control" type="text" value={amount} onChange={(e) => setamount(e.target.value) } placeholder="Enter Amount " readOnly />
                                  <div class="input-group-prepend">
                                    <input class="form-control" type="text" value={mgst} onChange={(e) => setmgst(e.target.value) } placeholder="Enter Amount " readOnly />
                                  </div>
                                  <div class="input-group-prepend"> 
                                    <input class="form-control" type="number" value={total_amount} onInput={(e) => {settotal_amount(e.target.value); calculateamount(e.target.value); } } placeholder="Enter Amount " />
                                  </div>
                                </div>

                                  
                                  {formErrors.amount && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.amount}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>





                          <div class="row">
                          <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Net Fee to Collect <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={net_collect}
                                    onChange={(e) =>
                                      setnet_collect(e.target.value)
                                    }
                                    placeholder="Enter Net Fee to Collect " 
                                    readOnly
                                  />
                                  {formErrors.net_collect && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.net_collect}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="row">
                          <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Remarks (if any)
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={remark}
                                    onChange={(e) =>
                                      setremark(e.target.value)
                                    }
                                    placeholder="Enter Remarks (if any). " 
                                  />
                                  {formErrors.remark && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.remark}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="row">
                          <div class="col-sm-12 col-xl-8 offset-xl-1  m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    Pay Mode<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_cash">
                                        <input
                                          type="radio"
                                          name="pay_mode"
                                          value={"cash"}
                                          id={"title_cash"}
                                          checked={pay_mode === 'cash' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setpay_mode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Cash
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_chque">
                                        <input
                                          type="radio"
                                          name="pay_mode"
                                          id={"title_chque"}
                                          value={"cheque"}
                                          checked={pay_mode === 'cheque' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setpay_mode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Cheque
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3">
                                      <label for="title_online">
                                        <input
                                          type="radio"
                                          name="pay_mode"
                                          value={"online"}
                                          checked={pay_mode === 'online' ? 'checked' : ''}
                                          id={"title_online"}
                                          onChange={(e) =>
                                            setpay_mode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Online Tx
                                      </label>
                                    </div>

                                    <div class="radio radio-inline col-xs-3" style={{display:'none'}}>
                                      <label for="title_saving">
                                        <input
                                          type="radio"
                                          name="pay_mode"
                                          value={"saving"}
                                          checked={pay_mode === 'saving' ? 'checked' : ''}
                                          id={"title_saving"}
                                          onChange={(e) =>
                                            setpay_mode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Saving Ac
                                      </label>
                                    </div>
                                    
                                  </div>
                                  {formErrors.pay_mode && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.pay_mode}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="row" style={{ display: pay_mode === 'cheque' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Bank Name<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <select
                                    class="form-control m-b"
                                    name="bank_name"
                                    value={bank_name}
                                    onChange={(e) =>
                                      setbank_name(e.target.value)
                                    }
                                  >
                                    <option value={''} disabled>Select bank_name</option>
                                    {bankList.map((bankdata) => (
                                      <option
                                        value={bankdata.id}
                                        selected={
                                          bankdata.id === bank_name
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {bankdata.name}

                                      </option>
                                    ))}
                                  </select>
                                  {formErrors.bank_name && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.bank_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style={{ display: pay_mode === 'cheque' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Cheque<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={cheque_no}
                                    onChange={(e) =>
                                      setcheque_no(e.target.value)
                                    }
                                    placeholder="Enter Cheque"
                                  />
                                  {formErrors.cheque_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.cheque_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style={{ display: pay_mode === 'cheque' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Cheque Date<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="date"
                                    value={cheque_date}
                                    onChange={(e) =>
                                      setcheque_date(e.target.value)
                                    }
                                    placeholder="Enter Cheque Date"
                                  />
                                  {formErrors.cheque_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.cheque_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style={{ display: pay_mode === 'online' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Transfar date<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="date"
                                    value={online_transfer_date}
                                    onChange={(e) =>
                                      setonline_transfer_date(e.target.value)
                                    }
                                    placeholder="Enter Transfar date"
                                  />
                                  {formErrors.online_transfer_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.online_transfer_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                         

                          <div class="row" style={{ display: pay_mode === 'online' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    UTR/ Transfar No.<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={tranfer_no}
                                    onChange={(e) =>
                                      settranfer_no(e.target.value)
                                    }
                                    placeholder="Enter Transfar No."
                                  />
                                  {formErrors.tranfer_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.tranfer_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style={{ display: pay_mode === 'online' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Transfar Mode<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_IMPS">
                                        <input
                                          type="radio"
                                          name="transfer_mode"
                                          value={"IMPS"}
                                          id={"title_IMPS"}
                                          checked={transfer_mode === 'IMPS' ? 'checked' : ''}
                                          onChange={(e) =>
                                            settransfer_mode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>IMPS
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_VPA">
                                        <input
                                          type="radio"
                                          name="transfer_mode"
                                          id={"title_VPA"}
                                          value={"VPA"}
                                          checked={transfer_mode === 'VPA' ? 'checked' : ''}
                                          onChange={(e) =>
                                            settransfer_mode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>VPA
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3">
                                      <label for="title_NEFT">
                                        <input
                                          type="radio"
                                          name="transfer_mode"
                                          value={"NEFT/RTGS"}
                                          checked={transfer_mode === 'NEFT/RTGS' ? 'checked' : ''}
                                          id={"title_NEFT"}
                                          onChange={(e) =>
                                            settransfer_mode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>NEFT/RTGS
                                      </label>
                                    </div>
                                    
                                  </div>
                                  {formErrors.transfer_mode && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.transfer_mode}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style={{ display: pay_mode === 'saving' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Saving Account<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={saving_account}
                                    onChange={(e) =>
                                      setsaving_account(e.target.value)
                                    }
                                    placeholder="Enter Saving Account No."
                                  />
                                  {formErrors.saving_account && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.saving_account}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                              <button
                                class="btn btn-success btn-square"
                                type="submit"
                                disabled={submitting}
                              >
                                Save
                              </button>{" "}
                              &nbsp;
                              <a href={`/view-member/${dataEncript(promoterhDetails.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                              &nbsp;
                             

                              <ToastContainer className="toast-container" />
                            </div>
                          </div>


                        </form>
                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
