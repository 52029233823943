import React, { useState, useEffect } from "react";
import DashboardLayout from "../../dashboardLayout";
// import axios from "axios";
// import { API_BASE_URL, API_HEADERS } from "../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { deleteConfirmation, FirstLetterCapitalize, infoAlert, dataDecrypt, dataEncript } from "../../../helper/commonHelpers";

import { formPost, getAssociateList, getActiveBranchList, getCollectionCenterList } from '../../../helper/listModal';

//formatDate

function CompanyDetails() {
  const [submitting, setSubmitting] = useState(false);
  const { centerID }   = useParams();
  const [details, setdetails] = useState([]);


  const [associateArrList, setassociateArrList] = useState([]);
  const [activeBranchArr, setactiveBranchArr] = useState([]);



  const [formErrors, setFormErrors] = useState({
    member_id: "",
  });


  const[branch_id, setbranch_id] = useState('');
  const[center_no, setcenter_no] = useState('');
  const[center_name, setcenter_name] = useState('');
  const[center_head, setcenter_head] = useState('');
  const[center_cashier, setcenter_cashier] = useState('');
  const[collection_day, setcollection_day] = useState('');
  const[collection_time, setcollection_time] = useState('');
  const[center_active, setcenter_active] = useState('yes');
  const[center_address, setcenter_address] = useState('');
  const[loc_latitude, setloc_latitude] = useState('');
  const[loc_longitude, setloc_longitude] = useState('');



  useEffect(() => {

    getAssociateList().then(data => {
    //   console.log(data);
      setassociateArrList(data);
    }).catch(error => {
        // console.error('Error fetching associate list:', error);
    });
   

    getActiveBranchList().then(data => {
      // console.log(data);
      setactiveBranchArr(data);
    }).catch(error => {
        // console.error('Error fetching associate list:', error);
    });


    

    let condition = `id=${dataDecrypt(centerID)}`;
    getCollectionCenterList(condition).then(data => {
        setdetails(data);

        setbranch_id(data.branch_id);
        setcenter_no(data.center_no);
        setcenter_name(data.center_name);
        setcenter_head(data.center_head);
        setcenter_cashier(data.center_cashier);
        setcollection_day(data.collection_day);
        setcollection_time(data.collection_time);
        setcenter_active(data.center_active);
        setcenter_address(data.center_address);
        setloc_latitude(data.loc_latitude);
        setloc_longitude(data.loc_longitude);

      }).catch(error => {
          // console.error('Error fetching associate list:', error);
      });



    // setenroll_date(getCurrentDate());


  }, [centerID]);




  const updateDetails = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));

    const formData = {

     
    id: details.id,
      branch_id: branch_id,
      center_no: center_no,
      center_name: center_name,
      center_head: center_head,
      center_cashier: center_cashier,
      collection_day: collection_day,
      collection_time: collection_time,
      center_active: center_active,
      center_address: center_address,
      loc_latitude: loc_latitude,
      loc_longitude: loc_longitude,



    };

  console.log(formData);
     


      if (!branch_id) {
        errors.branch_id = 'Branch is required.';
      }
      
      if (!center_no) {
        errors.center_no = 'Center No is required.';
      }

      if (!center_name) {
        errors.center_name = 'Center No is required.';
      }

      




    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {

        const confirmed = await deleteConfirmation('to Edit Collection Centers?');
        if(confirmed===true)
        {

          formPost('api-collcenter/add-collection-center', formData).then(data => {

            toast.success(data["message"]);
            setTimeout(() => {
              window.location.href = "/collection-centers";
            }, 2000);

              setSubmitting(false);
          }).catch(error => {
              setSubmitting(false);
              toast.error(error);
          });



    }
    setSubmitting(false);
    } else {
      setSubmitting(false);
    } 
  };


  

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>  Edit Collection Centers -  {details && details.center_no && (
                                    <>{details.center_no}</>
                                    )}  </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                            <a href="/collection-centers">Collection Centers </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                            <a href={`/collection-centers-view/${dataEncript(details.id)}`}  > {details && details.center_no && (
                                    <>{details.center_no}</>
                                    )}  </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> Edit </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="card" style={{borderTop:'3px solid red'}} >
                    
                        {/* <h4 class="m-4">CHANGE ACCOUNT INFO</h4> */}
                       <hr class="p-0 m-0"/>

                     

                      <div class="card-block">
                      <form onSubmit={updateDetails}>


                      <div class="row"  >
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Branch <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                  <select
                                    class="form-control m-b"
                                    name="branch_id"
                                    value={branch_id}
                                    onChange={(e) =>
                                      setbranch_id(e.target.value)
                                    }
                                  >

                                    <option value={' '}>Select - Branch</option>

                                     {activeBranchArr.map((assData) => (
                                      <option
                                        value={assData.id}
                                        selected={
                                          assData.id === branch_id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {FirstLetterCapitalize(assData.branch_name)}

                                      </option>
                                    ))}
                                 

                                  </select>

                                  {formErrors.branch_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.branch_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                        

                          <div class="row" >
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Center No.<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input class="form-control" type="text" value={center_no} onChange={(e) => setcenter_no(e.target.value) } placeholder="Enter Center No" />

                                  {formErrors.center_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.center_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Center Name<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input class="form-control" type="text" value={center_name} onChange={(e) => setcenter_name(e.target.value) } placeholder="Enter Center Name" />

                                  {formErrors.center_name && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.center_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            
                          </div>



                          <div class="row"  >
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Center Head <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                  <select
                                    class="form-control m-b"
                                    name="center_head"
                                    value={center_head}
                                    onChange={(e) =>
                                      setcenter_head(e.target.value)
                                    }
                                  >

                                    <option value={' '}>Select - Center Head</option>

                                     {associateArrList.map((assData) => (
                                      <option
                                        value={assData.id}
                                        selected={
                                          assData.id === center_head
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {FirstLetterCapitalize(assData.associate_code)} - 

                                        {FirstLetterCapitalize(assData.first_name)} &nbsp;
                                        {FirstLetterCapitalize(assData.first_name)}

                                      </option>
                                    ))}
                                 

                                  </select>

                                  {formErrors.center_head && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.center_head}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Center Cashier <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                  <select
                                    class="form-control m-b"
                                    name="center_cashier"
                                    value={center_cashier}
                                    onChange={(e) =>
                                      setcenter_cashier(e.target.value)
                                    }
                                  >

                                    <option value={' '}>Select - Center Cashier</option>

                                     {associateArrList.map((assData) => (
                                      <option
                                        value={assData.id}
                                        selected={
                                          assData.id === center_cashier
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {FirstLetterCapitalize(assData.associate_code)} - 

                                        {FirstLetterCapitalize(assData.first_name)} &nbsp;
                                        {FirstLetterCapitalize(assData.first_name)}

                                      </option>
                                    ))}
                                 

                                  </select>

                                  {formErrors.center_cashier && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.center_cashier}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>



                          <div class="row" >
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Collection Day
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                

                                <div class="input-group">
                                   <input class="form-control" type="text" value={collection_day} onChange={(e) => setcollection_day(e.target.value) } placeholder="Enter Collection Day " />
                                  <div class="input-group-prepend" style={{width:'auto'}}>
                                      <span class="input-group-text"   onClick={() => infoAlert("The day of month on which the collection is done at that center. Like on 5th of every month.")}  ><i class="icofont icofont-info"></i></span>
                                  </div>
                                </div>

                                  {formErrors.collection_day && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.collection_day}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Collection Time
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                
                                  
                                <div class="input-group">
                                <input class="form-control" type="time" value={collection_time} onChange={(e) => setcollection_time(e.target.value) } placeholder="Enter Collection Time" />
                                  <div class="input-group-prepend" style={{width:'auto'}}>
                                      <span class="input-group-text"   onClick={() => infoAlert("The time in between which the collection is done at center. Like 10 am - 1 pm")}  ><i class="icofont icofont-info"></i></span>
                                  </div>
                                </div>

                                  {formErrors.collection_time && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.collection_time}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            
                          </div>


                          

                          <div class="row" >
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Center Active <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="center_active_yes">
                                        <input
                                          type="radio"
                                          name="center_active"
                                          value={"yes"}
                                          id={"center_active_yes"}
                                          checked={center_active === 'yes' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setcenter_active(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Yes 
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="center_active_no">
                                        <input
                                          type="radio"
                                          name="center_active"
                                          id={"center_active_no"}
                                          value={"no"}
                                          checked={center_active === 'no' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setcenter_active(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>No
                                      </label>
                                    </div>
                                
                                  </div>
                                  {formErrors.center_active && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.center_active}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Address
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <textarea class="form-control" value={center_address} onChange={(e) => setcenter_address(e.target.value) } ></textarea>

                                  {formErrors.center_address && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.center_address}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            
                          </div>

                         





                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <h4 class="sub-title">
                                 </h4>
                              <h5 class="text-center">
                              Center's Address GPS Location -  &nbsp;
                                 <a href="#!"  class="btn btn-sm btn-warning btn-square">GET CURRENT LOCATION</a>
                                  </h5>
                            </div>
                          </div>



                       {/* ----------------8888888888888888888------------- */}
                    
                       <div class="row" >
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Location Latitude
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input class="form-control" type="text" value={loc_latitude} onChange={(e) => setloc_latitude(e.target.value) } placeholder="Enter Location Latitude" />

                                  {formErrors.loc_latitude && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.loc_latitude}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Location Longitude
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input class="form-control" type="text" value={loc_longitude} onChange={(e) => setloc_longitude(e.target.value) } placeholder="Enter Location Longitude" />

                                  {formErrors.loc_longitude && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.loc_longitude}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            
                          </div>

                        
                          <div class="row">
                              <div class="col-sm-12 col-xl-8 offset-xl-2 m-b-30 text-center">
                                    <h6 class="text-danger p-2">
                                        Note:  If you change collection center's branch, then system will automatically update branch in all groups which is associated with this collection center (JHHGFJHUJ).
                                        </h6>
                               </div>  
                           </div>  


                        <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                            <button
                              class="btn btn-success btn-square"
                              type="submit"
                              disabled={submitting}
                            >
                              UPDATE CENTER 
                            </button>{" "}
                       

                            <a href="/collection-centers"  class="btn btn-danger btn-square">Cancel</a>
                          

                            <ToastContainer className="toast-container" />
                          </div>
                        </div>


                      </form>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-5">
                  
                  </div>
                </div>


                

              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
          <ToastContainer className="toast-container" />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
