import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../dashboardLayout';

import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "../../../../config"; // Importing constants directly
// import $ from 'jquery';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



import {
      
      FirstLetterCapitalize,
      dataEncript,
      formatDate,
      formattedAmount
      
    } from '../../../helper/commonHelpers'; 

function AdminLayout() {
   const [promoterList, setpromoterList] = useState([]);


    useEffect(() => {

            let listCondition = `account_for = 'mis'`;
            axios.get(API_BASE_URL+'api-common/get-account-list?condition='+listCondition, { headers: API_HEADERS })
            .then(response => {
                  if (response.data["status"] > 0) {
                     setpromoterList(response.data['data']);
                  }
                  console.log(response.data['data']);
            })
            .catch(error => {
              toast.error("Failed to fetch role list list!");
            });
  
    }, []);






    useEffect(() => {
      const timeout = setTimeout(() => {
        if (window.tableDomJquery) {
          window.tableDomJquery();
        }
      }, 1000); // Timeout of 3000 milliseconds (3 seconds)
  
      return () => clearTimeout(timeout); // Clear the timeout on component unmount
    }, [promoterList]);




    

 
    const[member_no, setmember_no] = useState('');
    const[first_name, setfirst_name] = useState('');
    const[last_name, setlast_name] = useState('');
    const[share_no, setshare_no] = useState('');
    const[certificate_no, setcertificate_no] = useState('');
    const [submitting, setSubmitting] = useState(false);


    const filterData = (e) => {
      e.preventDefault();
      setSubmitting(true);
  
  const formData = {};
  
  if (member_no) {
      formData['member_no'] = member_no;
    }
  
    if (first_name) {
      formData['first_name'] = first_name;
    }
  
    if (last_name) {
      formData['last_name'] = last_name;
    }
  
    if (share_no) {
      formData['share_no'] = share_no;
    }
  
    if (certificate_no) {
      formData['certificate_no'] = certificate_no;
    }
  
    console.log(formData);
  
    if (Object.keys(formData).length === 0) {
      // Set formErrors state with validation errors
      
  
          axios.get(API_BASE_URL + 'api-member/get-shares-list', {
            headers: {
              ...API_HEADERS
            },
          }).then((response) => {
            if (response.data["status"] > 0) {
              setpromoterList(response.data['data']);
            } else {
              toast.error(response.data["message"]);
            }
  
          }).catch((error) => {
            toast.error("Failed to submition!");
          })
          .finally(() => {
            setTimeout(() => {
              setSubmitting(false);
            }, 300);
          }); // close query hare
  
      }
   
    };

  return (
    <DashboardLayout>
<div class="pcoded-content">
      <div class="pcoded-inner-content">

            <div class="main-body">
                  <div class="page-wrapper">

                        <div class="page-header">
                    <div class="row align-items-end">
                        <div class="col-lg-8">
                            <div class="page-header-title">
                                <div class="d-inline">
                                    <h4>MIS Deposits 
                                        
                                     <small>
                                          &nbsp;
                                          <a class="btn btn-info btn-sm" title="Add New" href="/fixed-deposit-mis-account-new">
                                         <b>+</b> </a> &nbsp; 
                                      </small> 
                                        
                                           </h4>
                                    {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="page-header-breadcrumb">
                                <ul class="breadcrumb-title">
                                    <li class="breadcrumb-item" style={{float:'left'}}>
                                        <a href="/dashboard"> <i class="feather icon-home"></i> </a>
                                    </li>
                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="#!">MIS Deposits</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


            <div class="page-body">


            <div class="card" style={{borderTop:'3px solid green'}} >
                  <div class="card-header py-3 text-denger">
                        <h5>Search Box</h5>
                              <div class="card-header-right">
                                    <ul class="list-unstyled card-option">
                                        <li><i class="feather icon-plus minimize-card"></i></li>
                                    </ul>
                              </div>
                        </div>
                        <div class="card-block" style={{display:'none'}}>
                        <form onSubmit={filterData}>
                        <div class="row">
                            <div class="col-sm-12 col-xl-4 m-b-30">
                                <div class="row">
                                  
                                  <div class="col-sm-12">
                                  <h6 class="">Tranx Id :</h6>
                                    <input
                                      class="form-control"
                                      type="text"
                                      value={member_no}
                                      onChange={(e) =>
                                        setmember_no(e.target.value)
                                      }
                                      placeholder="Search Tranx Id"
                                    />
                                  
                                  </div>
                                </div>
                              </div>
                              
                              <div class="col-sm-12 col-xl-4 m-b-30">
                                <div class="row">
                                
                                  <div class="col-sm-12">
                                  <h6 class="">Remarks :</h6>
                                    <input
                                      class="form-control"
                                      type="text"
                                      value={first_name}
                                      onChange={(e) =>
                                        setfirst_name(e.target.value)
                                      }
                                      placeholder="Search Remarks:"
                                    />
                                
                                  </div>
                                </div>
                              </div>

                              </div>

                          <div class="row">
                              <div class="col-sm-12 col-xl-3 m-b-30">
                                <div class="row">
                                
                                  <div class="col-sm-12">
                                  <h6 class=""> Transaction Date Range From: </h6>
                                    <input
                                      class="form-control"
                                      type="date"
                                      value={last_name}
                                      onChange={(e) =>
                                        setlast_name(e.target.value)
                                      }
                                      placeholder="Search Transaction Date Range from"
                                    />
                                
                                  </div>
                                </div>
                              </div>

                             
                            <div class="col-sm-12 col-xl-3 m-b-30">
                                <div class="row">
                                
                                  <div class="col-sm-12">
                                  <h6 class="">
                                  Transaction Date Range To:
                                    </h6>
                                    <input
                                      class="form-control"
                                      type="date"
                                      value={share_no}
                                      onChange={(e) =>
                                        setshare_no(e.target.value)
                                      }
                                      placeholder="Search Date Range To "
                                    />
                                
                                  </div>
                                </div>
                              </div>
                              
                              <div class="col-sm-12 col-xl-3 m-b-30">
                                <div class="row">
                              
                                  <div class="col-sm-12">
                                  <h6 class="">
                                  Amount Range From:
                                    </h6>
                                    <input
                                      class="form-control"
                                      type="text"
                                      value={certificate_no}
                                      onChange={(e) =>
                                        setcertificate_no(e.target.value)
                                      }
                                      placeholder="Search Amount Range From"
                                    />
                                  
                                  </div>
                                </div>
                              </div>

                              <div class="col-sm-12 col-xl-3 m-b-30">
                                <div class="row">
                              
                                  <div class="col-sm-12">
                                  <h6 class="">
                                  Amount Range To:
                                    </h6>
                                    <input
                                      class="form-control"
                                      type="text"
                                      value={certificate_no}
                                      onChange={(e) =>
                                        setcertificate_no(e.target.value)
                                      }
                                      placeholder="Search Amount Range To"
                                    />
                                  
                                  </div>
                                </div>
                              </div>

                              </div>

                              <div class="row">
                                <div class="col-sm-12 col-xl-12 m-b-30 text-center">

                                <button class="btn btn-success btn-square" type="submit" disabled={submitting} >
                                <i class="fa fa-search"></i>   Search 
                                </button>{" "}
                                &nbsp;
                              
                                <button class="btn btn-danger btn-square" type="reset" > Clear Form </button>
                                <ToastContainer className="toast-container" />

                                </div>
                              </div>

                              </form>
                        </div>
                    </div>




                  <div class="card mt-2">
                         <div class="card-header">
                       </div> 
                        <div class="card-block">
                              <div class="table-responsive dt-responsive">
                                    <table class="table dtable table-striped table-bordered nowrap" style={{width:'100%'}}>
                                          <thead>
                                                <tr>
                                                      <th>SR.NO</th>
                                                      <th>ASSOCIATE</th>
                                                      <th>GROUP</th>
                                                      <th class="no-wrap-space">FD NO</th>
                                                      <th class="no-wrap-space">MEMBER NO</th>
                                                      <th>MEMBER NAME</th>
                                                      <th>MINOR</th>
                                                      <th>BRANCH</th>
                                                      <th>SCHEME</th>
                                                      <th class="text-right">PRINCIPAL AMT.</th>
                                                      <th>OPEN&nbsp;DATE</th>
                                                      <th>INT. PAYOUT</th>
                                                      <th>MATURITY DATE</th>
                                                      <th>STATUS</th>
                                                      <th class="text-right">ACTIONS</th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                             
                                          


                                          {
                                                promoterList.length > 0 ? (
                                                  promoterList.map((branchData, index) => (
                                                      <tr class="gradeX">
                                                      <td>{index + 1}</td>
                                                      <td>{branchData.associate_name?FirstLetterCapitalize(branchData.associate_name):''}</td>
                                                      <td></td>

                                                      <td> <a href={`/fixed-deposit-mis-account-view/${dataEncript(branchData.id)}`} >  {branchData.account_no?FirstLetterCapitalize(branchData.account_no):''} </a> </td>
                                                      <td> <a href={`/view-member/${dataEncript(branchData.member_id)}`} > {branchData.member_created_id?branchData.member_created_id:''} </a> </td>
                                                      
                                                      <td>{branchData.member_name?FirstLetterCapitalize(branchData.member_name):''}</td>
                                                      <td></td>
                                                      <td>{branchData.branch_name?FirstLetterCapitalize(branchData.branch_name):''}</td>
                                                      <td>{branchData.scheme_name?FirstLetterCapitalize(branchData.scheme_name):''}</td>
                                                      <td>{branchData.mis_amount?formattedAmount(branchData.mis_amount):''}</td>
                                                      <td>{branchData.open_date?formatDate(branchData.open_date):''}</td>
                                                      <td>{branchData.ins_payout?FirstLetterCapitalize(branchData.ins_payout):''}</td>
                                                      <td></td>
                                                     

                                                      <td>
                                                      {
                                                            branchData && (
                                                              branchData.account_status === 'active' ? (
                                                                <label className="label label-success">Active</label>
                                                              ) : branchData.account_status === 'inactive' ? (
                                                                <label className="label label-danger">Inactive</label>
                                                              ) : null
                                                            )
                                                          }

                                                      </td>
                                                      
                                                      <td class="center">
                                                            <a href={`/fixed-deposit-mis-account-view/${dataEncript(branchData.id)}`} class='font-weight-normal label label-success'><i class="fa fa-eye"></i></a>
                                                      </td>
                                                </tr>
                                                  ))
                                                ) : (
                                                  <tr>
                                                    <td colSpan="15">No data available</td>
                                                  </tr>
                                                )
                                              }

                                          
                                               
                                          </tbody>
                                        
                                    </table>
                              </div>
                        </div>
                  </div>






            </div>

      </div>
</div>
<ToastContainer className="toast-container" />
<div id="styleSelector">
</div>
</div>
</div>
    </DashboardLayout>
  );
}

export default AdminLayout;
