import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly

import { useParams } from 'react-router-dom'; 

  import { toast, ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

  import {
    dataDecrypt,
    dataEncript,
    formatDate
  } from './../../../helper/commonHelpers'; 


function CompanyDetails() {
    // const navigate = useNavigate();
    const { unencumberedID }   = useParams();

    const [directorDetail, setdirectorDetail] = useState([]);

    useEffect(() => {

          // get bran ch details
          axios
          .get(API_BASE_URL + "api-company/get-unencumbered-deposite-by-id?unencumbered_id="+dataDecrypt(unencumberedID), {
            headers: API_HEADERS,
          })
          .then((response) => {
                if (response.data["status"] > 0) {
                setdirectorDetail(response.data['data']);
                }
          })
          .catch((error) => {
            toast.error('Failed to fetch Details list!');
          });

      }, [unencumberedID]);




  return (
    <DashboardLayout>




     <div class="pcoded-content">
    <div class="pcoded-inner-content">
        <div class="main-body">
            <div class="page-wrapper">
                <div class="page-header">
                    <div class="row align-items-end">
                        <div class="col-lg-6">
                            <div class="page-header-title">
                                <div class="d-inline">
                                    <h4>{directorDetail.bank_name} <small></small></h4>
                                    {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="page-header-breadcrumb">
                                <ul class="breadcrumb-title">
                                    <li class="breadcrumb-item" style={{float:'left'}}>
                                        <a href="/dashboard"> <i class="feather icon-home"></i> </a>
                                    </li>
                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="/unencumbered-deposits">Unencumbered Deposit</a></li>

                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="#!">{directorDetail.bank_name}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="page-body">
                    <div class="row">

                    <div class="col-sm-12">
                    

                                    </div>


                        <div class="col-sm-8">
                            <div class="card">
                                <div class="card-header">
                                    <h5>

                        

                                    </h5>

                                    <a
                          style={{ float: "right" }}
                          class="badge badge-info"
                          href={`/edit-unencumbered-deposits/${dataEncript(directorDetail.id)}`}
                          title="Edit"
                        >
                           <i class="feather icon-edit"></i>
                        </a>
                                    {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                                </div>

                                <div class="card-block">
                                <form > 
                                 <div class="row">
                                    <div class="col-sm-12 col-xl-12 m-b-30">
                                           
                                    <table class="table table-striped table-bordered nowrap">
                                        <tbody>
                                            <tr>
                                                <th>Bank Name</th>
                                                <td>{directorDetail && directorDetail.bank_name && (
                                                <>{directorDetail.bank_name}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                                <th>Fixed Deposit No.</th>
                                                <td>{directorDetail && directorDetail.fd_no && (
                                                <>{directorDetail.fd_no}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                                <th>FD Amount</th>
                                                <td>{directorDetail && directorDetail.fd_amount && (
                                                <>{directorDetail.fd_amount}</>
                                                )}</td>
                                            </tr>

                                           
                                            <tr>
                                                <th>Open Date</th>
                                                <td>{directorDetail && directorDetail.open_date && (
                                                <>{ formatDate(directorDetail.open_date) }</>
                                                )}</td>
                                            </tr>

                                            <tr>
                                                <th>Maturity Date</th>
                                                <td>{directorDetail && directorDetail.maturity_date && (
                                                <>{ formatDate(directorDetail.maturity_date) }</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                                <th>Rate interest (%)</th>
                                                <td>{directorDetail && directorDetail.annual_interest_rate && (
                                                <>{directorDetail.annual_interest_rate}</>
                                                )}</td>
                                            </tr>

                                            <tr>
                                                <th>FD From deposit money</th>
                                                <td>{directorDetail && directorDetail.is_fd_mode==='yes' && (
                                                     <label class="label label-success">Yes</label>
                                                )}

                                                {directorDetail && directorDetail.is_fd_mode==='no' && (
                                                     <label class="label label-danger">No</label>
                                                )}
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>Receipt Scan Copy</th>
                                                <td>{directorDetail && directorDetail.receipt_sacn_copy && (

                                                      <a href={directorDetail?.signature ? API_BASE_URL + directorDetail.signature : ''}  download={'true'}>Download</a>
                                                )}</td>
                                            </tr>



                                          







                                        </tbody>
                                    </table>


                                        </div>
                                    </div>



                                        

                                    

                                    

                                        

                                    <div class="row">
                                        <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                                          

                                            <ToastContainer className="toast-container" />
                                        </div>
                                        </div>



                                    </form>
                                </div>


                            </div>




                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="styleSelector"></div>
    </div>
</div>

    </DashboardLayout>
  );
}

export default CompanyDetails;
