import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// import Home from './home';
// import About from './about';

import ClientLogin from './components/admin/adminLogin';
import Dashboard from './components/admin/dashboard';
import Form from './components/admin/form';
import List from './components/admin/list';

import CompanyDetails from './components/admin/company/view_detail';
import EditCompanyDetails from './components/admin/company/view_detail1';

import BranchesList from './components/admin/company/branches/list';
import AddBranch from './components/admin/company/branches/add';
import EditBranch from './components/admin/company/branches/edit';
import ViewBranch from './components/admin/company/branches/view';


// promoter start
import PromoterList from './components/admin/company/promoter/list';
import AddPromoter from './components/admin/company/promoter/add';
import EditPromoter from './components/admin/company/promoter/edit';
import ViewPromoter from './components/admin/company/promoter/view';
import ChnagePromoterPassword from './components/admin/company/promoter/changepassword';
import AddNewMinor from './components/admin/company/promoter/addminor';
import EditMinor from './components/admin/company/promoter/editminor';
import ViewMinor from './components/admin/company/promoter/viewMinor';
import EditBankdetails from './components/admin/company/promoter/editbankdetails';
import EditAddress from './components/admin/company/promoter/editaddress';
import AddNewNominee from './components/admin/company/promoter/add_nominee';

// Company shareholder start
import ShareHolderList from './components/admin/company/shareholder/list';
import AddShareHolder from './components/admin/company/shareholder/add';
import EditShareHolder from './components/admin/company/shareholder/edit';
import ViewShareHolder from './components/admin/company/shareholder/view';


import DirectorList from './components/admin/company/directors/list';
import AddDirector from './components/admin/company/directors/add';
import EditDirector from './components/admin/company/directors/edit';
import ViewDirector from './components/admin/company/directors/view';

import UnencumberedList from './components/admin/company/unencumbered/list';
import Addunencumbered from './components/admin/company/unencumbered/add';
import Editunencumbered from './components/admin/company/unencumbered/edit';
import Viewunencumbered from './components/admin/company/unencumbered/view';


import BankaccountList from './components/admin/company/bankaccount/list';
import Addbankaccount from './components/admin/company/bankaccount/add';
import Editbankaccount from './components/admin/company/bankaccount/edit';
import Viewbankaccount from './components/admin/company/bankaccount/view';


import PermissionrolesList from './components/admin/usermanage/permission_roles/list';
import Addpermissionroles from './components/admin/usermanage/permission_roles/add';
import Editpermissionroles from './components/admin/usermanage/permission_roles/edit';
import Viewpermissionroles from './components/admin/usermanage/permission_roles/view';

import UsersList from './components/admin/usermanage/users/list';
import Addusers from './components/admin/usermanage/users/add';
import Editusers from './components/admin/usermanage/users/edit';
import Viewusers from './components/admin/usermanage/users/view';
import ResetPassword from './components/admin/usermanage/users/resetPassword';


/// member management------------------------------------------------------
import MemberList from './components/admin/membermanagement/member/list';
import Addmember from './components/admin/membermanagement/member/add';
import Editmember from './components/admin/membermanagement/member/edit';
import Viewmember from './components/admin/membermanagement/member/view';
import MemberChangePassword from './components/admin/membermanagement/member/changepassword';
import MemberChangeEmailPhone from './components/admin/membermanagement/member/editemailphone';
import MemberAddNewMinor from './components/admin/membermanagement/member/addminor';
import MemberEditMinor from './components/admin/membermanagement/member/editminor';
import MemberViewMinor from './components/admin/membermanagement/member/viewMinor';
import MemberEditBankdetails from './components/admin/membermanagement/member/editbankdetails';
import MemberEditaddress from './components/admin/membermanagement/member/editaddress';
import MemberupdateDocument from './components/admin/membermanagement/member/updatedocument';
import MemeberAddcomment from './components/admin/membermanagement/member/addComment';
import MemeberAddShare from './components/admin/membermanagement/member/addShares';
import MemeberAddShareAmount from './components/admin/membermanagement/member/addSharesAmount';
import MemeberTransaction from './components/admin/membermanagement/member/transaction';
import MemeberTransactionView from './components/admin/membermanagement/member/viewTransaction';

import MemeberMemberShipCharge from './components/admin/membermanagement/member/memberShipCharge';
import MemberUpload15G from './components/admin/membermanagement/member/upload15G';
import MemberStatusInactive from './components/admin/membermanagement/member/statusInactive';
import MemberRemove from './components/admin/membermanagement/member/removeMember';

import MemberMinorList from './components/admin/membermanagement/member/minorList';


import MemberShareHolderList from './components/admin/membermanagement/shareholder/list';
import MemberShareCertificate from './components/admin/membermanagement/shareholder/shareCertificatelist';
import MemberShareTransfarHistory from './components/admin/membermanagement/shareholder/shareTransfarHistory';
import Memberform15ghList from './components/admin/membermanagement/shareholder/form15ghList';
import MemberShareform15ghList from './components/admin/membermanagement/shareholder/upload15G';
import MemberShareViewform15ghList from './components/admin/membermanagement/shareholder/view15ghList.js';
import MemberAddShareHolder from './components/admin/membermanagement/shareholder/add';
import MemberEditShareHolder from './components/admin/membermanagement/shareholder/edit';
import MemberViewShareHolder from './components/admin/membermanagement/shareholder/view';
import MemberSurrender from './components/admin/membermanagement/shareholder/surrenderShare';


 

// SAVING ACCOUNTS -------------------
import AccountsScheemList from './components/admin/saccounts/schemes/list';
import AccountsScheemAdd from './components/admin/saccounts/schemes/add';
import AccountsScheemEdit from './components/admin/saccounts/schemes/edit';
import AccountsScheemView from './components/admin/saccounts/schemes/view';

import AccountsAccountList from './components/admin/saccounts/accounts/list';
import AccountsAccountAdd from './components/admin/saccounts/accounts/add';
import AccountsAccountEdit from './components/admin/saccounts/accounts/edit';
import AccountsAccountView from './components/admin/saccounts/accounts/view';
import AccountsAccountUploadDocument from './components/admin/saccounts/accounts/addDocument';
import AccountsAccountAddComment from './components/admin/saccounts/accounts/addComment';
import AccountsAccountTransaction from './components/admin/saccounts/accounts/transaction';
import AccountsAccountDepositeMony from './components/admin/saccounts/accounts/depositeMony';
import AccountsAccountDepositedebit from './components/admin/saccounts/accounts/deposite_withdrol';

import AccountsAccountOtCharge from './components/admin/saccounts/accounts/other_charge';
import AccountsAccountOtChargePay from './components/admin/saccounts/accounts/other_charge_pay';
import AccountsAccountOtChargeView from './components/admin/saccounts/accounts/view_transaction';
import AccountsAccountClearDue from './components/admin/saccounts/accounts/clear_due';

import AccountsAccountCreditIntrest from './components/admin/saccounts/accounts/credit_intrest';
import AccountsAccountChangeAccountType from './components/admin/saccounts/accounts/change_account_type';
import AccountsAccountChangeNominee from './components/admin/saccounts/accounts/change_nominee';
import AccountsAccountUpgrade from './components/admin/saccounts/accounts/upgrade';




import FdMisdAccountsScheem from './components/admin/fd_mds_account/schemes/list';
import FdMisdAccountsScheemAdd from './components/admin/fd_mds_account/schemes/add';
import FdMisdAccountsScheemView from './components/admin/fd_mds_account/schemes/view';
import FdMisdAccountsScheemEdit from './components/admin/fd_mds_account/schemes/edit';
import FdMisCalculator from './components/admin/fd_mds_account/schemes/fdcalculator';
import FdMisFdaccount from './components/admin/fd_mds_account/fd_accounts/list';
import FdMisFdaccountadd from './components/admin/fd_mds_account/fd_accounts/add';
import FdMisFdaccountview from './components/admin/fd_mds_account/fd_accounts/view';
import FdMisFdaccountPayouts from './components/admin/fd_mds_account/fd_accounts/payouts';
import FdMisFdaccountChangeInfo from './components/admin/fd_mds_account/fd_accounts/change_account_info';
import FdMisFdaccountChangeNominee from './components/admin/fd_mds_account/fd_accounts/change_nominee';
import FdMisFdaccountForeClose from './components/admin/fd_mds_account/fd_accounts/for_close';
import FdMisFdaccountRemoveAc from './components/admin/fd_mds_account/fd_accounts/remove_account';
import FdMisFdaccountLinkToSavingAc from './components/admin/fd_mds_account/fd_accounts/link_to_saving_account';
import FdMisFdaccountCreditIntrest from './components/admin/fd_mds_account/fd_accounts/credit_intrest';
import FdMisFdaccountDebitIntrest from './components/admin/fd_mds_account/fd_accounts/debit_intrest';

// mis---------------------
import FdMisMISaccount from './components/admin/fd_mds_account/mis_accounts/list';
import FdMisMISaccountAdd from './components/admin/fd_mds_account/mis_accounts/add';
import FdMisMISaccountView from './components/admin/fd_mds_account/mis_accounts/view';

// mds-----------------
import FdMisMdSaccount from './components/admin/fd_mds_account/mds_accounts/list';
import FdMisMdSaccountAdd from './components/admin/fd_mds_account/mds_accounts/add';
import FdMisMdSaccountView from './components/admin/fd_mds_account/mds_accounts/view';
import FdMisMdSaccountInstall from './components/admin/fd_mds_account/mds_accounts/installment_plan';
import FdMisMdSaccountMinor from './components/admin/fd_mds_account/mds_accounts/addminor';
import FdMisMdSaccountClose from './components/admin/fd_mds_account/mds_accounts/for_close';
import FdMisMdSaccountOtCharge from './components/admin/fd_mds_account/mds_accounts/other_charge';
import FdMisMdSaccountOtChargePay from './components/admin/fd_mds_account/mds_accounts/other_charge_pay';
import FdMisMdSaccountOtChargeDue from './components/admin/fd_mds_account/mds_accounts/clear_due';
import FdMisMdSaccountLinkSavingAC from './components/admin/fd_mds_account/mds_accounts/link_to_saving_account';
import FdMisMdSaccountCreditIntrest from './components/admin/fd_mds_account/mds_accounts/credit_intrest';
import FdMisMdSaccountDepositMony from './components/admin/fd_mds_account/mds_accounts/depositeMony';
import FdMisMdSaccountDepositMonyWithdrwal from './components/admin/fd_mds_account/mds_accounts/deposite_withdrol';



// dds-----------------------------------
import DDSSaccount from './components/admin/fd_mds_account/dds_accounts/list';
import DDSSaccountAdd from './components/admin/fd_mds_account/dds_accounts/add';
import DDSSaccountAddOCP from './components/admin/fd_mds_account/dds_accounts/other_charge_pay';







// ASSOCIATE ADVISOR----------------------------------
import AssociateAdvisorRank from './components/admin/associate_advisor/rank_structure/list';
import AssociateAdvisorRankAdd from './components/admin/associate_advisor/rank_structure/add';
import AssociateAdvisorRankEdit from './components/admin/associate_advisor/rank_structure/edit';
import AssociateAdvisorRankView from './components/admin/associate_advisor/rank_structure/view';

import AssociateAdvisorCommision from './components/admin/associate_advisor/commission_chart/list';
import AssociateAdvisorCommisionAdd from './components/admin/associate_advisor/commission_chart/add';
import AssociateAdvisorCommisionEdit from './components/admin/associate_advisor/commission_chart/edit';
import AssociateAdvisorCommisionView from './components/admin/associate_advisor/commission_chart/view';

import AssociateAdvisor from './components/admin/associate_advisor/advisor/list';
import AssociateAdvisorAdd from './components/admin/associate_advisor/advisor/add';
import AssociateAdvisorEdit from './components/admin/associate_advisor/advisor/edit.js';
import AssociateAdvisorView from './components/admin/associate_advisor/advisor/view';
import AssociateAdvisorChange from './components/admin/associate_advisor/advisor/changepassword';
import AssociateLinkToSavingAccount from './components/admin/associate_advisor/advisor/link_to_saving_account';



// COllS CENTER GROUPS----------------------------------
import CollectionCenters from './components/admin/coll_groups/collection_center/list';
import CollectionCentersAdd from './components/admin/coll_groups/collection_center/add';
import CollectionCentersEdit from './components/admin/coll_groups/collection_center/edit.js';
import CollectionCentersView from './components/admin/coll_groups/collection_center/view';


import CollectionCentersGroup from './components/admin/coll_groups/groups/list';
import CollectionCentersGroupAdd from './components/admin/coll_groups/groups/add';
import CollectionCentersGroupEdit from './components/admin/coll_groups/groups/edit.js';
import CollectionCentersGroupView from './components/admin/coll_groups/groups/view';
import CollectionCentersGroupAddComment from './components/admin/coll_groups/groups/addComment';







// APPROVALS--------------------
import ApprovalsAccount from './components/admin/approvals/accounts';
import ApprovalsAccounthistory from './components/admin/approvals/accounts_history';
import ApprovalsTransaction from './components/admin/approvals/transaction';
import ApprovalsTransactionHistory from './components/admin/approvals/transaction_history';
import ApprovalsReserveTransaction from './components/admin/approvals/reverse_transaction';
import ApprovalsNonComplains from './components/admin/approvals/non_compliances';
import ApprovalsLoanClosures from './components/admin/approvals/loan_closures';
import ApprovalsLoanClosuresHistory from './components/admin/approvals/loan_closures_history';
import ApprovalsDepositClosures from './components/admin/approvals/deposit_closures';
import ApprovalsShareTransfer from './components/admin/approvals/share_transfer';
import ApprovalsShareTransferHistory from './components/admin/approvals/share_transfer_history';

import ApprovalsShareSurrender from './components/admin/approvals/share_surrender';
import ApprovalsShareSurrenderHistory from './components/admin/approvals/share_surrender_history';

import ApprovalsWithinBankTransfer from './components/admin/approvals/bank_transfer';
import ApprovalsWithinBankTransferHistory from './components/admin/approvals/bank_transfer_history';

import ApprovalsAccountingVoucher from './components/admin/approvals/accounting_vouchers';
import ApprovalsAccountingVoucherHistory from './components/admin/approvals/accounting_vouchers_history';

import ApprovalsLoans from './components/admin/approvals/loans';
import ApprovalsLoansHistory from './components/admin/approvals/loans_history';





























// ---------------passbook
import PassbookList from './components/admin/passbook/passbook/list';
import PassbookAdd from './components/admin/passbook/passbook/add';
import PassbookView from './components/admin/passbook/passbook/view';
import PassbookEdit from './components/admin/passbook/passbook/edit';




















function App() {
  // Wrap the entire component in a Router component
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();

  useEffect(() => {
    // Extract the current pathname from the location
    const currentPath = location.pathname;
    let title = 'Home Page';

    if (currentPath === '/') {
      title = 'WEB -  ome Page';
    } else if (currentPath === '/about') {
      title = 'WEB -  bout Us';
    } else if (currentPath === '/service') {
      title = 'WEB -  ur Services';
    } else if (currentPath === '/project') {
      title = 'WEB -  ur Projects';
    } else if (currentPath === '/contact') {
      title = 'WEB -  ontact Us';
    }else if (currentPath === '/contacttest') {
      title = 'WEB -  ontact Us test';
    } else if (currentPath === '/dashboard') {
      title = 'Admin - Admin Dashboard';
    }

    document.title = title;
  }, [location.pathname]); // Run the effect when the pathname changes

  return (
    <>
      <Helmet>
        <title>{document.title}</title>
      </Helmet>

      <Routes>
        <Route path="/" element={<ClientLogin />} />
        {/* <Route path="/about" element={<About />} /> */}

        <Route path="/login" element={<ClientLogin />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/form" element={<Form />} />
        <Route path="/list" element={<List />} />

        <Route path="/company-details" element={<CompanyDetails />} />
        <Route path="/edit-company-details" element={<EditCompanyDetails />} />
        <Route path="/branches-list" element={<BranchesList />} />
        <Route path="/add-new-branch" element={<AddBranch />} />
        <Route path="/edit-branch/:branchID" element={<EditBranch />} />
        <Route path="/view-branch/:branchID" element={<ViewBranch />} />

        {/* PROMOTER LIST PromoterList */}
        <Route path="/promoter-list" element={<PromoterList />} />
        <Route path="/add-new-promoter" element={<AddPromoter />} />
        <Route path="/edit-promoter/:promoterID" element={<EditPromoter />} />
        <Route path="/view-promoter/:promoterID" element={<ViewPromoter />} />
        <Route path="/change-password-promoter/:promoterID" element={<ChnagePromoterPassword />} />
        <Route path="/add-new-minor/:promoterID" element={<AddNewMinor />} />
        <Route path="/edit-minor/:promoterID/:minorID" element={<EditMinor />} />
        <Route path="/view-minor/:promoterID/:minorID" element={<ViewMinor />} />
        <Route path="/edit-bank-details/:promoterID" element={<EditBankdetails />} />
        <Route path="/edit-address-details/:promoterID" element={<EditAddress />} />


        <Route path="/add-new-nominee/:promoterID" element={<AddNewNominee />} />



        <Route path="/share-holdings" element={<ShareHolderList />} />
        <Route path="/add-share-holdings" element={<AddShareHolder />} />
        <Route path="/edit-share-holdings/:shareholdID" element={<EditShareHolder />} />
        <Route path="/view-share-holdings/:shareholdID" element={<ViewShareHolder />} />

        <Route path="/director" element={<DirectorList />} />
        <Route path="/add-director" element={<AddDirector />} />
        <Route path="/edit-director/:directorID" element={<EditDirector />} />
        <Route path="/view-director/:directorID" element={<ViewDirector />} />

        <Route path="/unencumbered-deposits" element={<UnencumberedList />} />
        <Route path="/add-unencumbered-deposits" element={<Addunencumbered />} />
        <Route path="/edit-unencumbered-deposits/:unencumberedID" element={<Editunencumbered />} />
        <Route path="/view-unencumbered-deposits/:unencumberedID" element={<Viewunencumbered />} />

        <Route path="/bank-accounts" element={<BankaccountList />} />
        <Route path="/add-bank-account" element={<Addbankaccount />} />
        <Route path="/edit-bank-account/:bankaccountID" element={<Editbankaccount />} />
        <Route path="/view-bank-account/:bankaccountID" element={<Viewbankaccount />} />

        <Route path="/user-management-role" element={<PermissionrolesList />} />
         <Route path="/add-user-management-role" element={<Addpermissionroles />} />
        <Route path="/edit-user-management-role/:permission_rolesID" element={<Editpermissionroles />} />
        <Route path="/view-user-management-role/:permission_rolesID" element={<Viewpermissionroles />} /> 



        <Route path="/users-management" element={<UsersList />} />
        <Route path="/add-user" element={<Addusers />} />
        <Route path="/edit-user/:userID" element={<Editusers />} />
        <Route path="/view-user/:userID" element={<Viewusers />} /> 
        <Route path="/reset-user-password/:userID" element={<ResetPassword />} /> 


        <Route path="/members-management" element={<MemberList />} />
        <Route path="/add-member" element={<Addmember />} />
        <Route path="/edit-member/:memberID" element={<Editmember />} />
        <Route path="/view-member/:memberID" element={<Viewmember />} /> 
        <Route path="/member-change-password/:memberID" element={<MemberChangePassword />} /> 
        <Route path="/member-change-email-phone/:memberID" element={<MemberChangeEmailPhone />} /> 
        <Route path="/member-add-new-minor/:memberID" element={<MemberAddNewMinor />} /> 
        <Route path="/member-edit-minor/:memberID/:minorID" element={<MemberEditMinor />} /> 
        <Route path="/member-view-minor/:memberID/:minorID" element={<MemberViewMinor />} /> 
        <Route path="/member-edit-bankdetails/:memberID" element={<MemberEditBankdetails />} /> 
        <Route path="/member-edit-address/:memberID" element={<MemberEditaddress />} /> 
        <Route path="/member-update-document/:memberID" element={<MemberupdateDocument />} />
        <Route path="/member-add-comment/:memberID" element={<MemeberAddcomment />} /> 
        <Route path="/member-add-shares/:memberID" element={<MemeberAddShare />} /> 
        <Route path="/member-shares-amount/:memberID" element={<MemeberAddShareAmount />} /> 
        <Route path="/member-transaction/:memberID" element={<MemeberTransaction />} /> 
        <Route path="/member-transaction-view/:memberID/:transectionID" element={<MemeberTransactionView />} /> 

        <Route path="/member-ship-charge/:memberID" element={<MemeberMemberShipCharge />} /> 
        <Route path="/member-upload-15g15h/:memberID" element={<MemberUpload15G />} /> 
        <Route path="/member-status-inactive/:memberID" element={<MemberStatusInactive />} />
        <Route path="/member-remove/:memberID" element={<MemberRemove />} /> 

        <Route path="/member-minors" element={<MemberMinorList />} /> 
        
        <Route path="/member-share-holdings" element={<MemberShareHolderList />} /> 
        <Route path="/member-share-certificate" element={<MemberShareCertificate />} /> 
        <Route path="/member-share-transfer-history" element={<MemberShareTransfarHistory />} /> 
        <Route path="/member-form-15-gh" element={<Memberform15ghList />} /> 
        <Route path="/member-upload-15-gh" element={<MemberShareform15ghList />} /> 
        <Route path="/member-view-15-gh/:share15ghID" element={<MemberShareViewform15ghList />} /> 

        <Route path="/member-add-holdings" element={<MemberAddShareHolder />} /> 
        <Route path="/member-edit-holdings/:shareholdID" element={<MemberEditShareHolder />} /> 
        <Route path="/member-view-holdings/:shareholdID" element={<MemberViewShareHolder />} />
        <Route path="/member-surrender-share/:shareholdID" element={<MemberSurrender />} />


        <Route path="/account-scheem-list" element={<AccountsScheemList />} />
        <Route path="/account-scheem-add" element={<AccountsScheemAdd />} />
        <Route path="/account-scheem-edit/:scheemID" element={<AccountsScheemEdit />} />
        <Route path="/account-scheem-view/:scheemID" element={<AccountsScheemView />} />

        <Route path="/account-list" element={<AccountsAccountList />} />
        <Route path="/account-open-new" element={<AccountsAccountAdd />} />
        <Route path="/account-edit/:accountID" element={<AccountsAccountEdit />} />
        <Route path="/account-view/:accountID" element={<AccountsAccountView />} />
        <Route path="/account-upload-document/:accountID" element={<AccountsAccountUploadDocument />} />
        <Route path="/account-add-comment/:accountID" element={<AccountsAccountAddComment />} />
        <Route path="/account-transaction/:accountID" element={<AccountsAccountTransaction />} />
        <Route path="/account-deposite-mony/:accountID" element={<AccountsAccountDepositeMony />} />
        <Route path="/account-mony-debit/:accountID" element={<AccountsAccountDepositedebit />} />

        <Route path="/account-other-charge/:accountID" element={<AccountsAccountOtCharge />} />
        <Route path="/account-other-charge-pay/:accountID" element={<AccountsAccountOtChargePay />} />
        <Route path="/account-transaction-view/:accountID/:transectionID" element={<AccountsAccountOtChargeView />} />
        <Route path="/account-other-charge-clear-due/:accountID" element={<AccountsAccountClearDue />} />

        <Route path="/account-credit-interest/:accountID" element={<AccountsAccountCreditIntrest />} />
        <Route path="/account-change-account-type/:accountID" element={<AccountsAccountChangeAccountType />} />
        <Route path="/account-change-nominee/:accountID" element={<AccountsAccountChangeNominee />} />
        <Route path="/account-upgrade/:accountID" element={<AccountsAccountUpgrade />} />




{/* fd_misd accounts */}

<Route path="/fixed-deposit-schemes" element={<FdMisdAccountsScheem />} />
<Route path="/fixed-deposit-schemes-add" element={<FdMisdAccountsScheemAdd />} />
<Route path="/fixed-deposit-schemes-view/:scheemID" element={<FdMisdAccountsScheemView />} />
<Route path="/fixed-deposit-schemes-edit/:scheemID" element={<FdMisdAccountsScheemEdit />} />
<Route path="/fixed-deposit-calculator" element={<FdMisCalculator />} />

<Route path="/fixed-deposit-account" element={<FdMisFdaccount />} />
<Route path="/fixed-deposit-account-new" element={<FdMisFdaccountadd />} />
<Route path="/fixed-deposit-account-view/:accountID" element={<FdMisFdaccountview />} />
<Route path="/fixed-deposit-account-payouts/:accountID" element={<FdMisFdaccountPayouts />} />
<Route path="/fixed-deposit-change-account-info/:accountID" element={<FdMisFdaccountChangeInfo />} />
<Route path="/fixed-deposit-add-nominee/:accountID" element={<FdMisFdaccountChangeNominee />} />
<Route path="/fixed-deposit-fore-close/:accountID" element={<FdMisFdaccountForeClose />} />
<Route path="/fixed-deposit-account-remove/:accountID" element={<FdMisFdaccountRemoveAc />} />
<Route path="/fixed-deposit-link-saving-account/:accountID" element={<FdMisFdaccountLinkToSavingAc />} />
<Route path="/fixed-deposit-link-update-interest/:accountID" element={<FdMisFdaccountCreditIntrest />} />
<Route path="/fixed-deposit-link-debit-interest/:accountID" element={<FdMisFdaccountDebitIntrest />} />

{/* missssssssssssssssssssssssss */}
<Route path="/fixed-deposit-mis-account" element={<FdMisMISaccount />} />
<Route path="/fixed-deposit-mis-account-new" element={<FdMisMISaccountAdd />} />
<Route path="/fixed-deposit-mis-account-view/:accountID" element={<FdMisMISaccountView />} />


{/* mds--------------- */}
<Route path="/recurring-deposit-account" element={<FdMisMdSaccount />} />
<Route path="/recurring-deposit-account-new" element={<FdMisMdSaccountAdd />} />
<Route path="/recurring-deposit-account-view/:accountID" element={<FdMisMdSaccountView />} />
<Route path="/recurring-deposit-account-installments/:accountID" element={<FdMisMdSaccountInstall />} />
<Route path="/recurring-deposit-account-add-minor/:accountID" element={<FdMisMdSaccountMinor />} />
<Route path="/recurring-deposit-account-fore-close/:accountID" element={<FdMisMdSaccountClose />} />
<Route path="/recurring-deposit-account-other-charges/:accountID" element={<FdMisMdSaccountOtCharge />} />
<Route path="/recurring-deposit-account-other-charges-pay/:accountID" element={<FdMisMdSaccountOtChargePay />} />
<Route path="/recurring-deposit-account-clear-due/:accountID" element={<FdMisMdSaccountOtChargeDue />} />
<Route path="/recurring-deposit-account-link-saving-account/:accountID" element={<FdMisMdSaccountLinkSavingAC />} />
<Route path="/recurring-deposit-account-credit-interest/:accountID" element={<FdMisMdSaccountCreditIntrest />} />
<Route path="/recurring-deposit-account-mony/:accountID" element={<FdMisMdSaccountDepositMony />} />
<Route path="/recurring-deposit-account-debit/:accountID" element={<FdMisMdSaccountDepositMonyWithdrwal />} />




{/* dds-------------------- */}
<Route path="/daily-deposit-accounts/" element={<DDSSaccount />} />
<Route path="/daily-deposit-accounts-new/" element={<DDSSaccountAdd />} />
<Route path="/daily-deposit-accounts-other-charges-pay/:accountID" element={<DDSSaccountAddOCP />} />



{/* ASSOCIATE ADVISOR-------------------------------- */}
<Route path="/associate-advisor-agent-ranks" element={<AssociateAdvisorRank />} />
<Route path="/associate-advisor-agent-ranks-new" element={<AssociateAdvisorRankAdd />} />
<Route path="/associate-advisor-agent-ranks-edit/:rankID" element={<AssociateAdvisorRankEdit />} />
<Route path="/associate-advisor-agent-ranks-view/:rankID" element={<AssociateAdvisorRankView />} />

<Route path="/associate-advisor-commission-charts" element={<AssociateAdvisorCommision />} />
<Route path="/associate-advisor-commission-charts-new" element={<AssociateAdvisorCommisionAdd />} />
<Route path="/associate-advisor-commission-charts-edit/:chartID" element={<AssociateAdvisorCommisionEdit />} />
<Route path="/associate-advisor-commission-charts-view/:chartID" element={<AssociateAdvisorCommisionView />} />

<Route path="/associate-advisor" element={<AssociateAdvisor />} />
<Route path="/associate-advisor-new" element={<AssociateAdvisorAdd />} />
<Route path="/associate-advisor-edit/:advisorID" element={<AssociateAdvisorEdit />} />
<Route path="/associate-advisor-view/:advisorID" element={<AssociateAdvisorView />} />
<Route path="/associate-advisor-chnage-password/:advisorID" element={<AssociateAdvisorChange />} />
<Route path="/associate-advisor-link-saving-account/:advisorID" element={<AssociateLinkToSavingAccount />} />








{/*  collection center */}
<Route path="/collection-centers" element={<CollectionCenters />} />
<Route path="/collection-centers-new" element={<CollectionCentersAdd />} />
<Route path="/collection-centers-edit/:centerID" element={<CollectionCentersEdit />} />
<Route path="/collection-centers-view/:centerID" element={<CollectionCentersView />} />

<Route path="/collection-centers-group" element={<CollectionCentersGroup />} />
<Route path="/collection-centers-group-new" element={<CollectionCentersGroupAdd />} />
<Route path="/collection-centers-group-edit/:centerGroupID" element={<CollectionCentersGroupEdit />} />
<Route path="/collection-centers-group-view/:centerGroupID" element={<CollectionCentersGroupView />} />
<Route path="/collection-centers-group-add-comment/:centerGroupID" element={<CollectionCentersGroupAddComment />} />





{/* APPROVALS------------- */}
<Route path="/approvals-accounts" element={<ApprovalsAccount />} />
<Route path="/approvals-accounts-history" element={<ApprovalsAccounthistory />} />
<Route path="/approvals-transaction" element={<ApprovalsTransaction />} />
<Route path="/approvals-transaction/:branchID" element={<ApprovalsTransaction />} />

<Route path="/approvals-transaction-history" element={<ApprovalsTransactionHistory />} />
<Route path="/approvals-reverse-transactions" element={<ApprovalsReserveTransaction />} />
<Route path="/approvals-non-compliances" element={<ApprovalsNonComplains />} />
<Route path="/approvals-loan-closures" element={<ApprovalsLoanClosures />} />
<Route path="/approvals-loan-closures-history" element={<ApprovalsLoanClosuresHistory />} />
<Route path="/approvals-deposit-closures" element={<ApprovalsDepositClosures />} />
<Route path="/approvals-share-transfer" element={<ApprovalsShareTransfer />} />
<Route path="/approvals-share-transfer-history" element={<ApprovalsShareTransferHistory />} />

<Route path="/approvals-share-surrender" element={<ApprovalsShareSurrender />} />
<Route path="/approvals-share-surrender-history" element={<ApprovalsShareSurrenderHistory />} />

<Route path="/approvals-within-bank-transfer" element={<ApprovalsWithinBankTransfer />} />
<Route path="/approvals-within-bank-transfer-history" element={<ApprovalsWithinBankTransferHistory />} />

<Route path="/approvals-accounting-vouchers" element={<ApprovalsAccountingVoucher />} />
<Route path="/approvals-accounting-vouchers-history" element={<ApprovalsAccountingVoucherHistory />} />

<Route path="/approvals-loans" element={<ApprovalsLoans />} />
<Route path="/approvals-loans-history" element={<ApprovalsLoansHistory />} />













        {/* passbook ------------------------------ */}
        <Route path="/passbook-list" element={<PassbookList />} />
        <Route path="/passbook-add" element={<PassbookAdd />} />
        <Route path="/passbook-add/:accountID" element={<PassbookAdd />} />
        <Route path="/passbook-view/:passbookID" element={<PassbookView />} />
        <Route path="/passbook-edit/:passbookID" element={<PassbookEdit />} />














        
























        
      </Routes>
    </>
  );
}

export default App;