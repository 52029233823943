import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { dataDecrypt,
    dataEncript,
    FirstLetterCapitalize,
    formatDate,
    deleteConfirmation
     } from "./../../../helper/commonHelpers";

function CompanyDetails() {
  // const navigate = useNavigate();
  const { accountID, transectionID }   = useParams();
  // const [stateList, setStateList] = useState([]);
  const [promoterhDetails, setpromoterhDetails] = useState([]);
  const [transactionDetails, settransactionDetails] = useState([]);



  // PROMOTER INFO 

  useEffect(() => {

  // get bran ch details
 
axios.get(API_BASE_URL+'api-common/get-account-list?id='+dataDecrypt(accountID), {
    headers: API_HEADERS
}).then((response) => {

  // console.log(response.data['data']);

    if (response.data["status"] > 0) {
        setpromoterhDetails(response.data['data']);
    }
}).catch((error) => {
  toast.error('Failed to fetch DETAILS list!');
});



axios.get(API_BASE_URL+'api-common/get-account-transaction-list?id='+dataDecrypt(transectionID), {
    headers: API_HEADERS
}).then((response) => {
    console.log(response.data);

  // console.log(response.data['data']);


    if (response.data["status"] > 0) {
        settransactionDetails(response.data["data"]);
    }
}).catch((error) => {
  toast.error('Failed to fetch DETAILS list!');
});
 
    //    setenrollment_date(getCurrentDate());
  }, [accountID, transectionID]);



  // update kyc status /updateSettingstatus //updateKYCstatus
const deleteTransaction = async (e) => {
    e.preventDefault();
        const confirmed = await deleteConfirmation('Are you sure to delete transaction?');
        if(confirmed===true)
        {
            const formData = {
                tablename:'tbl_transaction',  // table name
                coloum_name:'id', // which collumn name you want to  
                value:dataDecrypt(transectionID), // column value 
             };


             axios.post(API_BASE_URL + "api-common/detete", formData, {
                headers: {
                    ...API_HEADERS,
                },
                })
                .then((response) => {
                if (response.data["status"] > 0) {
                    toast.success(response.data["message"]);
                    setTimeout(() => {
                         window.location.href = '/account-transaction/'+accountID;
                    }, 2700);
                } else {
                    toast.error(response.data["message"]);
                }
                })
                .catch((error) => {
                   toast.error("Failed to submition!");
                }); // close query hare


        }
  };


  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>Transaction  - {promoterhDetails && promoterhDetails.account_no && (
                                    <>{promoterhDetails.account_no}</>
                                    )}  </h4>

                                    
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>

                          {
                                    promoterhDetails && (
                                      promoterhDetails.account_for === 'fd' ? (
                                        <a href="/fixed-deposit-account">Fd Accounts</a>
                                      ) : promoterhDetails.account_for === 'mis' ? (
                                        <a href="/fixed-deposit-mis-account">MIS Deposits</a>
                                      ): promoterhDetails.account_for === 'rd' ? (
                                        <a href="/recurring-deposit-account">RD Accounts</a>
                                      ) :  <a href="/account-list">Accounts</a>
                                    )
                                  }

                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                        {
                                  promoterhDetails && (
                                    promoterhDetails.account_for === 'fd' ? (
                                      <a href={`/fixed-deposit-account-view/${dataEncript(promoterhDetails.id)}`} > {promoterhDetails && promoterhDetails.account_no && ( <span>{promoterhDetails.account_no}</span> )}</a>
                                    ) : promoterhDetails.account_for === 'mis' ? (
                                      <a href={`/fixed-deposit-mis-account-view/${dataEncript(promoterhDetails.id)}`} > {promoterhDetails && promoterhDetails.account_no && ( <span>{promoterhDetails.account_no}</span> )}</a>
                                    ): promoterhDetails.account_for === 'rd' ? (
                                      <a href={`/recurring-deposit-account-view/${dataEncript(promoterhDetails.id)}`} > {promoterhDetails && promoterhDetails.account_no && ( <span>{promoterhDetails.account_no}</span> )}</a>
                                    ) :  <a href={`/account-view/${dataEncript(promoterhDetails.id)}`} > {promoterhDetails && promoterhDetails.account_no && ( <span>{promoterhDetails.account_no}</span> )}</a>
                                  )
                                }
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">Transactions</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12"></div>


                  <div class="col-sm-7">
                    <div class="card">
                    <div class="card-header text-right">
                                        <a href={`/edit-member/${dataEncript(transactionDetails.id)}`}  class='font-weight-normal label label-info'> <i class="fa fa-print"></i> </a>&nbsp;
                                          <a href="#!"  onClick={(e) => {deleteTransaction(e);}}  class='font-weight-normal label label-danger'> <i class="fa fa-trash"></i> </a>&nbsp;
                      </div>

                      <div class="card-block">

                      <div class="table-responsive dt-responsive">
                        <table class="table table-striped table-bordered nowrap">
                            <tbody>
                                    

                            <tr>
                            <th> Member </th>
                            <td>{transactionDetails && transactionDetails.member_name && (
                            <span>{FirstLetterCapitalize(transactionDetails.member_name)}</span>
                            )}</td>
                        </tr>
                        <tr>
                            <th> Transaction Date </th>
                            <td>{transactionDetails && transactionDetails.transaction_date && (
                            <span>{formatDate(transactionDetails.transaction_date)}</span>
                            )}</td>
                        </tr>
                        <tr>
                            <th> Reference Id </th>
                            <td></td>
                        </tr>
                        <tr>
                            <th> Type </th>
                            <td>{transactionDetails && transactionDetails.amount_type && (
                            <span>{FirstLetterCapitalize(transactionDetails.amount_type)}</span>
                            )}</td>
                        </tr>
                        <tr>
                            <th> Transaction Type </th>
                            <td>{transactionDetails && transactionDetails.pay_mode && (
                            <span>{FirstLetterCapitalize(transactionDetails.pay_mode)}</span>
                            )}</td>
                        </tr>
                        <tr>
                            <th> Amount </th>
                            <td>{transactionDetails && transactionDetails.deposit_amt && (
                            <span>{transactionDetails.deposit_amt}</span>
                            )}</td>
                        </tr>
                        <tr>
                            <th> Transaction Status </th>
                            <td>{transactionDetails && transactionDetails.status && (
                            <span>{FirstLetterCapitalize(transactionDetails.status)}</span>
                            )}</td>
                        </tr>
                        <tr>
                            <th> Payment Mode </th>
                            <td>{transactionDetails && transactionDetails.pay_mode && (
                            <span>{FirstLetterCapitalize(transactionDetails.pay_mode)}</span>
                            )}</td>
                        </tr>
                        <tr>
                            <th> Remarks </th>
                            <td>{transactionDetails && transactionDetails.remark && (
                            <span>{FirstLetterCapitalize(transactionDetails.remark)}</span>
                            )}</td>
                        </tr>
                        <tr>
                            <th> Created at </th>
                            <td>{transactionDetails && transactionDetails.created_at && (
                            <span>{FirstLetterCapitalize(transactionDetails.created_at)}</span>
                            )}</td>
                        </tr>
                        <tr>
                            <th> Updated at </th>
                            <td>{transactionDetails && transactionDetails.updated_at && (
                            <span>{formatDate(transactionDetails.updated_at)}</span>
                            )}</td>
                        </tr>
                        <tr>
                            <th> Is Accounted </th>
                            <td> {
                                    transactionDetails && (
                                    transactionDetails.credited_in_account === 'no' ? (
                                        <label className="label label-danger">No</label>
                                    ) : transactionDetails.credited_in_account === 'yes' ? (
                                        <label className="label label-success">Yes</label>
                                    ) : null
                                    )
                                }</td>
                        </tr>
                        <tr>
                            <th> Branch </th>
                            <td>{transactionDetails && transactionDetails.branch_name && (
                            <span>{FirstLetterCapitalize(transactionDetails.branch_name)}</span>
                            )}</td>
                        </tr>

                              
                            </tbody>
                        </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12">
                    <div class="card">
                      <div class="card-header bg-info">
                        <h5 class="title">AUDIT TRAIL</h5>
                        {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                      </div>

                      <div class="card-block">


                      <div class="table-responsive dt-responsive">
                        <table class="table table-striped table-bordered nowrap">
                            <thead>
                                <tr>
                                    <th>Sr</th>
                                    <th>Creator</th>
                                    <th>Event</th>
                                    <th>Create On</th>
                                    <th>Change Logs</th>
                                </tr>
                            </thead>
                            <tbody>
                              
                            </tbody>
                        </table>
                        </div>
                        <ToastContainer className="toast-container" />
                      </div>
                    </div>
                  </div>


                  



                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
