import React from 'react';
import DashboardLayout from './dashboardLayout';



function AdminLayout() {

  return (
    <DashboardLayout>
<div class="pcoded-content">
      <div class="pcoded-inner-content">

            <div class="main-body">
                  <div class="page-wrapper">

                        <div class="page-header">
                    <div class="row align-items-end">
                        <div class="col-lg-8">
                            <div class="page-header-title">
                                <div class="d-inline">
                                    <h4>Advanced DataTable</h4>
                                    <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="page-header-breadcrumb">
                                <ul class="breadcrumb-title">
                                    <li class="breadcrumb-item" style={{float:'left'}}>
                                        <a href="../index.html"> <i class="feather icon-home"></i> </a>
                                    </li>
                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="#!">Form Picker</a></li>
                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="#!">Form Picker</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


            <div class="page-body">

                  <div class="card">
                        {/* <div class="card-header">
                              <h5>DOM/Jquery</h5>
                              <span>Events assigned to the table can be exceptionally useful for user
                                    interaction, however you must be aware that DataTables will add and
                                    remove rows from the DOM as they are needed (i.e. when paging only
                                    the visible elements are actually available in the DOM). As such,
                              this can lead to the odd hiccup when working with events.</span>
                        </div> */}
                        <div class="card-block">
                              <div class="table-responsive dt-responsive">
                                    <table id="dom-jqry" class="table table-striped table-bordered nowrap" style={{width:'100%'}}>
                                          <thead>
                                                <tr>
                                                      <th>Name</th>
                                                      <th>Position</th>
                                                      <th>Office</th>
                                                      <th>Age</th>
                                                      <th>Start date</th>
                                                      <th>Salary</th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                                <tr>
                                                      <td>Tiger Nixon</td>
                                                      <td>System Architect</td>
                                                      <td>Edinburgh</td>
                                                      <td>61</td>
                                                      <td>2011/04/25</td>
                                                      <td>$320,800</td>
                                                </tr>
                                                <tr>
                                                      <td>Garrett Winters</td>
                                                      <td>Accountant</td>
                                                      <td>Tokyo</td>
                                                      <td>63</td>
                                                      <td>2011/07/25</td>
                                                      <td>$170,750</td>
                                                </tr>
                                                <tr>
                                                      <td>Ashton Cox</td>
                                                      <td>Junior Technical Author</td>
                                                      <td>San Francisco</td>
                                                      <td>66</td>
                                                      <td>2009/01/12</td>
                                                      <td>$86,000</td>
                                                </tr>
                                                <tr>
                                                      <td>Cedric Kelly</td>
                                                      <td>Senior Javascript Developer</td>
                                                      <td>Edinburgh</td>
                                                      <td>22</td>
                                                      <td>2012/03/29</td>
                                                      <td>$433,060</td>
                                                </tr>
                                                <tr>
                                                      <td>Airi Satou</td>
                                                      <td>Accountant</td>
                                                      <td>Tokyo</td>
                                                      <td>33</td>
                                                      <td>2008/11/28</td>
                                                      <td>$162,700</td>
                                                </tr>
                                                <tr>
                                                      <td>Brielle Williamson</td>
                                                      <td>Integration Specialist</td>
                                                      <td>New York</td>
                                                      <td>61</td>
                                                      <td>2012/12/02</td>
                                                      <td>$372,000</td>
                                                </tr>
                                                <tr>
                                                      <td>Herrod Chandler</td>
                                                      <td>Sales Assistant</td>
                                                      <td>San Francisco</td>
                                                      <td>59</td>
                                                      <td>2012/08/06</td>
                                                      <td>$137,500</td>
                                                </tr>
                                                <tr>
                                                      <td>Rhona Davidson</td>
                                                      <td>Integration Specialist</td>
                                                      <td>Tokyo</td>
                                                      <td>55</td>
                                                      <td>2010/10/14</td>
                                                      <td>$327,900</td>
                                                </tr>
                                                <tr>
                                                      <td>Colleen Hurst</td>
                                                      <td>Javascript Developer</td>
                                                      <td>San Francisco</td>
                                                      <td>39</td>
                                                      <td>2009/09/15</td>
                                                      <td>$205,500</td>
                                                </tr>
                                                <tr>
                                                      <td>Sonya Frost</td>
                                                      <td>Software Engineer</td>
                                                      <td>Edinburgh</td>
                                                      <td>23</td>
                                                      <td>2008/12/13</td>
                                                      <td>$103,600</td>
                                                </tr>
                                                <tr>
                                                      <td>Jena Gaines</td>
                                                      <td>Office Manager</td>
                                                      <td>London</td>
                                                      <td>30</td>
                                                      <td>2008/12/19</td>
                                                      <td>$90,560</td>
                                                </tr>
                                                <tr>
                                                      <td>Quinn Flynn</td>
                                                      <td>Support Lead</td>
                                                      <td>Edinburgh</td>
                                                      <td>22</td>
                                                      <td>2013/03/03</td>
                                                      <td>$342,000</td>
                                                </tr>
                                                <tr>
                                                      <td>Charde Marshall</td>
                                                      <td>Regional Director</td>
                                                      <td>San Francisco</td>
                                                      <td>36</td>
                                                      <td>2008/10/16</td>
                                                      <td>$470,600</td>
                                                </tr>
                                                <tr>
                                                      <td>Haley Kennedy</td>
                                                      <td>Senior Marketing Designer</td>
                                                      <td>London</td>
                                                      <td>43</td>
                                                      <td>2012/12/18</td>
                                                      <td>$313,500</td>
                                                </tr>
                                                <tr>
                                                      <td>Tatyana Fitzpatrick</td>
                                                      <td>Regional Director</td>
                                                      <td>London</td>
                                                      <td>19</td>
                                                      <td>2010/03/17</td>
                                                      <td>$385,750</td>
                                                </tr>
                                                <tr>
                                                      <td>Michael Silva</td>
                                                      <td>Marketing Designer</td>
                                                      <td>London</td>
                                                      <td>66</td>
                                                      <td>2012/11/27</td>
                                                      <td>$198,500</td>
                                                </tr>
                                                <tr>
                                                      <td>Paul Byrd</td>
                                                      <td>Chief Financial Officer (CFO)</td>
                                                      <td>New York</td>
                                                      <td>64</td>
                                                      <td>2010/06/09</td>
                                                      <td>$725,000</td>
                                                </tr>
                                                <tr>
                                                      <td>Gloria Little</td>
                                                      <td>Systems Administrator</td>
                                                      <td>New York</td>
                                                      <td>59</td>
                                                      <td>2009/04/10</td>
                                                      <td>$237,500</td>
                                                </tr>
                                                <tr>
                                                      <td>Bradley Greer</td>
                                                      <td>Software Engineer</td>
                                                      <td>London</td>
                                                      <td>41</td>
                                                      <td>2012/10/13</td>
                                                      <td>$132,000</td>
                                                </tr>
                                                <tr>
                                                      <td>Dai Rios</td>
                                                      <td>Personnel Lead</td>
                                                      <td>Edinburgh</td>
                                                      <td>35</td>
                                                      <td>2012/09/26</td>
                                                      <td>$217,500</td>
                                                </tr>
                                          </tbody>
                                        
                                    </table>
                              </div>
                        </div>
                  </div>






            </div>

      </div>
</div>

<div id="styleSelector">
</div>
</div>
</div>
    </DashboardLayout>
  );
}

export default AdminLayout;
