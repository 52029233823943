import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { dataDecrypt,
    dataEncript,
    deleteConfirmation
     } from "./../../../helper/commonHelpers";

function CompanyDetails() {
  // const navigate = useNavigate();
  const { memberID }   = useParams();
  const [submitting, setSubmitting] = useState(false);
  // const [stateList, setStateList] = useState([]);
  const [promoterhDetails, setpromoterhDetails] = useState([]);


  


  // PROMOTER INFO 
  
  
  useEffect(() => {

  // get bran ch details
 
axios.get(API_BASE_URL+'api-member/get-member-list?member_id='+dataDecrypt(memberID), {
    headers: API_HEADERS
}).then((response) => {

    if (response.data["status"] > 0) {
        setpromoterhDetails(response.data['data']);

    }
  }).catch((error) => {
    toast.error('Failed to fetch DETAILS list!');
  });
 


    //    setenrollment_date(getCurrentDate());
  }, [memberID]);

  const updateDetails = async (e) => {
    e.preventDefault();


    const formData = {
        // table_id:dataDecrypt(memberID),

    };


 
    const confirmed = await deleteConfirmation('Are you sure to delete transaction?');
    if(confirmed===true)
    {
      axios
        .post(API_BASE_URL + "api-member/add-new-shares", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/view-member/"+memberID;
            //   window.location.reload();
            }, 2000);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 1000);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>{promoterhDetails && promoterhDetails.fname && (
                                    <>{promoterhDetails.fname}</>
                                    )}

                                    {promoterhDetails && promoterhDetails.mname && (
                                    <>{promoterhDetails.mname}</>
                                    )} 
                                    - Remove Member and details
                                        
                                    </h4>

                                    
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/members-management">Member List</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href={`/view-member/${dataEncript(promoterhDetails.id)}`} >{promoterhDetails && promoterhDetails.fname && (
                                    <span>{promoterhDetails.fname}</span>
                                    )}

                                    {promoterhDetails && promoterhDetails.mname && (
                                    <span>{promoterhDetails.mname}</span>
                                    )}</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">IN-ACTIVE MEMBER</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12">
                  </div>

                


                  <div class="col-sm-12">
                    <div class="card">
                    
                    
                    <div class="card-header">
                        <h4>Remove member and all his details and accounts.</h4>
                        <hr />
                    </div>

                      <div class="card-block">
                        <form onSubmit={updateDetails}>




                          <div class="row">
                          

                            <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">

                            <ul>
                                <li>Remove share holding if assigned. </li>
                                <li>Remove minors and accounts. </li>
                                <li>Remove saving accounts and all its transactions. </li>
                                <li>Remove FD accounts and all its transactions. </li>
                                <li>Remove RD accounts and all its transactions. </li>
                                <li>Remove Gold Loan applications/ accounts and all its transactions. </li>
                                <li>Remove Deposit Loan applications/ accounts and all its transactions. </li>
                                <li>Remove Property Loan applications/ accounts and all its transactions. </li>
                                <li>Remove Business/ Other Loan applications/ accounts and all its transactions. </li>
                                <li>Remove any recharge history present. </li>
                                <li>Remove transactions from accounting module. </li>
                                <li>Remove all the tracking if any. </li>
                                <li>Sequence numbers will get unused in future. </li>
                                <li>May lead to data corruption if any inter link account transactions are present. </li>
                                <li>No data backup will be provided for this action. </li>
                                </ul>


                              
                            </div>
                            </div>

                           

                          


                     


                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                              <button
                                class="btn btn-success btn-square"
                                type="submit"
                                disabled={submitting}
                              >
                                REMOVE MEMBER
                              </button>{" "}
                              &nbsp;
                              <a href={`/view-member/${dataEncript(promoterhDetails.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                              &nbsp;
                             

                              <ToastContainer className="toast-container" />
                            </div>
                          </div>


                        </form>
                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
