import React, { useState, useEffect } from 'react';
import DashboardLayout from '../dashboardLayout';

// import axios from "axios";
// import { API_BASE_URL, API_HEADERS } from "../../../config"; // Importing constants directly
// import $ from 'jquery';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



import {
      
      FirstLetterCapitalize,
      dataEncript,
      // formatDate,
      getCurrentDate,
      getCurrentMonthStartDate,
      
    } from '../../helper/commonHelpers'; 


    
import {
  getListArr
} from '../../helper/listModal'; 

function AdminLayout() {
   const [accountList, setaccountList] = useState([]);
   const [submitting, setSubmitting] = useState(false);

   const[approval_date_from, setapproval_date_from] = useState('');
   const[approval_date_to, setapproval_date_to] = useState('');
   const[account_for, setaccount_for] = useState('');
   const[approve_by_name, setapprove_by_name] = useState('');



    useEffect(() => {
     //let condition = `approved_status=initiated`; 
      getListArr('api-approvals/get-transactions-history').then(data => {
        setaccountList(data);
      }).catch(error => {
          // console.error('Error fetching associate list:', error);
      });

      setapproval_date_from(getCurrentMonthStartDate());
      setapproval_date_to(getCurrentDate());

    }, []);



 


 



const filterData = (e) => {
      e.preventDefault();
      setSubmitting(true);
  
  const formData = {
    // approval_date_from: approval_date_from,
    // approval_date_to: approval_date_to,
    // approve_by_name:  approve_by_name,
    // account_for: account_for,
  };

//   if (approval_date_from) {
//       formData['approval_date_from'] = approval_date_from;
//     }
  
    // console.log(formData);
    if (Object.keys(formData).length === 0) {
      // Set formErrors state with validation errors
      let condition = `approval_date_from=${approval_date_from}&approval_date_to=${approval_date_to}&account_type=${account_for}&approver_name=${approve_by_name} `; 
      
        getListArr('api-approvals/get-transactions-history', condition).then(data => {
            setaccountList(data);
          setSubmitting(false);
          toast.success('Data filtered successfully.');
        }).catch(error => {
            // console.error('Error fetching associate list:', error);
            toast.error(error);
        });
  
      }
      setSubmitting(false);
    };



    


    useEffect(() => {
      const timeout = setTimeout(() => {
        if (window.tableDomJquery) {
          window.tableDomJquery();
        }
      }, 2000); // Timeout of 3000 milliseconds (3 seconds)
  
      return () => clearTimeout(timeout); // Clear the timeout on component unmount

      // setapproval_date_to(getCurrentDate());
      // setapproval_date_to(getCurrentDate());

    }, [accountList]);

  return (
    <DashboardLayout>
<div class="pcoded-content">
      <div class="pcoded-inner-content">

            <div class="main-body">
                  <div class="page-wrapper">

                        <div class="page-header">
                    <div class="row align-items-end">
                        <div class="col-lg-6">
                            <div class="page-header-title">
                                <div class="d-inline">
                                    <h4>Share Surrender Approval - History</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="page-header-breadcrumb">
                                <ul class="breadcrumb-title">
                                    <li class="breadcrumb-item" style={{float:'left'}}>
                                        <a href="/dashboard"> <i class="feather icon-home"></i> </a>
                                    </li>
                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="approvals-share-surrender">Share Surrender Approvals </a></li>
                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="#!">Share Surrender Approval - History</a></li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


            <div class="page-body">


            <div class="card" style={{borderTop:'3px solid green'}} >
                  <div class="card-header py-3 text-denger">
                        <h5>Search Box</h5>
                              <div class="card-header-right">
                                    <ul class="list-unstyled card-option">
                                        <li><i class="feather icon-minus minimize-card"></i></li>
                                    </ul>
                              </div>
                        </div>
                        <div class="card-block" >
                        <form onSubmit={filterData}>
                        <div class="row">
                          

                              <div class="col-sm-12 col-xl-3 m-b-30">
                                <div class="row">
                                  <div class="col-sm-12">
                                  <h6 class=""> Approval Date From : </h6>
                                    <input
                                      class="form-control"
                                      type="date"
                                      value={approval_date_from}
                                      onChange={(e) =>
                                        setapproval_date_from(e.target.value)
                                      }
                                      placeholder="Approval Date From"
                                    />
                                
                                  </div>
                                </div>
                              </div>

                              <div class="col-sm-12 col-xl-3 m-b-30">
                                <div class="row">
                                  <div class="col-sm-12">
                                  <h6 class=""> Approval Date To : </h6>
                                    <input
                                      class="form-control"
                                      type="date"
                                      value={approval_date_to}
                                      onChange={(e) =>
                                        setapproval_date_to(e.target.value)
                                      }
                                      placeholder="Approval Date To"
                                    />
                                
                                  </div>
                                </div>
                              </div>

                              <div class="col-sm-12 col-xl-3 m-b-30">
                                <div class="row">
                                
                                  <div class="col-sm-12">
                                  <h6 class=""> Account Type : </h6>
                                  <select
                                    class="form-control m-b"
                                    name="account_for"
                                    value={account_for}
                                    onChange={(e) =>
                                      setaccount_for(e.target.value)
                                    }
                                  >
                                    <option value={''}>All</option>
                                    <option value={'saving'}>Saving</option>
                                    <option value={'rd'}>RD</option>
                                    <option value={'dd'}>DD</option>
                                    <option value={'fd'}>FD</option>
                                    <option value={'mis'}>MIS</option>
                                  </select>
                                
                                  </div>
                                </div>
                              </div>

                              <div class="col-sm-12 col-xl-3 m-b-30">
                                <div class="row">
                                  <div class="col-sm-12">
                                  <h6 class=""> Approved By Name : </h6>
                                    <input
                                      class="form-control"
                                      type="text"
                                      value={approve_by_name}
                                      onChange={(e) =>
                                        setapprove_by_name(e.target.value)
                                      }
                                      placeholder="Search Approved By Name"
                                    />
                                
                                  </div>
                                </div>
                              </div>




                          </div>

                          

                              <div class="row">
                                <div class="col-sm-12 col-xl-12 m-b-30 text-center">

                                <button class="btn btn-success btn-square" type="submit" disabled={submitting} >
                                <i class="fa fa-search"></i>   Search 
                                </button>
                                <ToastContainer className="toast-container" />

                                </div>
                              </div>

                              </form>
                        </div>
                    </div>




                  <div class="card mt-2">
                         <div class="card-header">
                       </div> 
                        <div class="card-block">
                              <div class="table-responsive dt-responsive">
                                    <table class="table dtable table-striped table-bordered nowrap" style={{width:'100%'}}>
                                          <thead>
                                                <tr>
                                                      <th>SR.NO</th>
                                                      <th>BRANCH</th>
                                                      <th>MEMBER</th>
                                                      <th>SHARE HOLDING</th>
                                                      <th>SURRENDER/ TRANSFER DATE</th>
                                                      <th>NO of SHARES</th>

                                                      <th>STATUS</th>
                                                      <th>APPROVED BY</th>
                                                      <th>REMARKS</th>
                                                    
                                                </tr>
                                          </thead>
                                          <tbody>
                                             
                                          {
                                                accountList.length > 0 ? (
                                                  accountList.map((branchData, index) => (
                                                      <tr class="gradeX" key={index}>
                                                      <td>{index + 1}</td>
                                                      <td>{branchData.branch_name?FirstLetterCapitalize(branchData.branch_name):''}</td>
                                                      <td> <a href={`/view-member/${dataEncript(branchData.member_id)}`}> {branchData.member_created_id?FirstLetterCapitalize(branchData.member_created_id):''} - {branchData.member_name?FirstLetterCapitalize(branchData.member_name):''} </a> </td>
                                                      <td></td>
                                                    
                                                        <td></td>
                                                        <td></td>

                                                       <td>{branchData.staus?FirstLetterCapitalize(branchData.status):''}</td>
                                                          
                                                       <td>{branchData.approver_name?FirstLetterCapitalize(branchData.approver_name):''}</td>
                                                       <td>{branchData.remark?FirstLetterCapitalize(branchData.remark):''}</td>

                                                          
                                                      
                                                </tr>
                                                  ))
                                                ) :null
                                              }

                                          
                                               
                                          </tbody>
                                        
                                    </table>
                              </div>
                        </div>
                  </div>






            </div>

      </div>
</div>
<ToastContainer className="toast-container" />
<div id="styleSelector">
</div>
</div>
</div>
    </DashboardLayout>
  );
}

export default AdminLayout;
