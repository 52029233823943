import React, { useState, useEffect } from "react";
import DashboardLayout from "../../dashboardLayout";
// import axios from "axios";
import { API_BASE_URL } from "../../../../config"; // Importing constants directly

import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import $ from 'jquery';
import 'dropify/dist/css/dropify.min.css';
import 'dropify';


import {  dataDecrypt, dataEncript, FirstLetterCapitalize, formatDate, formattedAmount, deleteConfirmation } from "../../../helper/commonHelpers";
import {  getAssociateList, formPost } from '../../../helper/listModal';

//formatDate

function CompanyDetails() {
  const { advisorID }   = useParams();
  const [submitting, setSubmitting] = useState(false);

  const [details, setdetails] = useState([]);
  const [userProfile, setuserProfile] = useState([]);

  useEffect(() => {
      getAssociateList(dataDecrypt(advisorID)).then(data => {
        // console.log(data);
        setdetails(data);
      }).catch(error => {
          // console.error('Error fetching associate list:', error);
      });


      setTimeout(() => {
        $('.dropify').dropify();
      }, 2000);

  }, [advisorID]);


  const handleLogoImageChange = (e) => {
    const file = e.target.files[0]; 
    setuserProfile(file); 
  };
  

  const [formErrors, setFormErrors] = useState({
    member_id: "",
  });


  const changePhoto = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    let errors = {};

    const formData = new FormData();
    formData.append('id', dataDecrypt(advisorID)); 
    if (userProfile) {
      formData.append('associate_profile_pic', userProfile); 
    }
    if (userProfile.length===0) {
      toast.error('Please choose Photo image!');
      errors.userProfile = 'Please choose Photo image is required.';
      setSubmitting(false);
    }

  

  // console.log(formData);
     
    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {

        const confirmed = await deleteConfirmation('to change photo?');
        if(confirmed===true)
        {

          formPost('api-associates/update-photo', formData).then(data => {

            toast.success(data["message"]);
            setTimeout(() => {
              // window.location.href = "/associate-advisor";
              window.location.reload();
            }, 2000);

              setSubmitting(false);
          }).catch(error => {
              setSubmitting(false);
              toast.error(error);
          });



    }
    setSubmitting(false);
    } else {
      setSubmitting(false);
    } 
  };





  const userAccountLockUnlock = async (e) => {
    e.preventDefault();
        let username = details.first_name+' '+details.last_name;

        const formData = {
            tablename:'tbl_associate',  // table name
             where_coloumn_name:'id', // which collumn name you want to  
             where_value:dataDecrypt(advisorID), // column value 
             set_coloumn_name:'account_login_locked', // which collumn name you want to update
             set_value:(details.account_login_locked==='no')?'yes':'no', // collumn value
         };

        let locked_status = (details.account_login_locked==='yes')?'Un-lock':'Lock';
        
        const confirmed = await deleteConfirmation('Are you sure to '+ locked_status +' Account '+username+' ?');
        if(confirmed===true)
        {
          formPost('api-common/update-status', formData).then(data => {

            toast.success(username + ' account '+locked_status+' successfully.');
            setTimeout(() => {
                setTimeout(() => {
                  window.location.reload();
                }, 1200);

            }, 2000);

              setSubmitting(false);
          }).catch(error => {
              setSubmitting(false);
              toast.error(error);
          });
        }
  };


  const sendUserCredential = async (e) => {
    e.preventDefault();
        let username = details.first_name+' '+details.last_name;
        const confirmed = await deleteConfirmation('Are You Sure to Reset Credentials and Resend SMS to '+username+' ?');
        if(confirmed===true)
        {
            toast.success('Your credentials has been sent on registered mobile number.');

            //   setTimeout(() => {
            //         window.location.reload();
            //         }, 2800);
        }
  };


  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>  Associate/ Advisor  -  

                        {details && details.first_name && (
                                    <>{details.first_name}</>
                                    )}  
                                    &nbsp;
                                    {details && details.last_name && (
                                    <>{details.last_name}</>
                                    )}  

                        </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                            <a href="/associate-advisor">Associate/ Advisor </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                            <a href="#!">
                              
                            {details && details.first_name && (
                                    <>{details.first_name}</>
                                    )}  
                                    &nbsp;
                                    {details && details.last_name && (
                                    <>{details.last_name}</>
                                    )}   </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> View</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-5">
                    <div class="card" style={{borderTop:'3px solid red'}} >

                    <div class="card-header py-3">
                       <h5 class="">Photo</h5>
                    </div>
                        {/* <h4 class="m-4">CHANGE ACCOUNT INFO</h4> */}
                       <hr class="p-0 m-0"/>
                      <div class="card-block">
                      
                      <form  onSubmit={changePhoto}> 
                                 <div class="row">
                                    <div class="col-sm-12 col-xl-12 m-b-30">
                                    <input
                                    class="form-control"
                                    type="file"
                                    className="dropify"
                                    onChange={handleLogoImageChange} 
                                    data-default-file={details?.associate_profile_pic ?API_BASE_URL + details.associate_profile_pic: ''}

                                  />

                                    {formErrors.userProfile && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.userProfile}
                                    </span>
                                  )}

                                        </div>
                                    </div>

                                    <div class="row">
                                <div class="col-sm-12 col-xl-12 m-b-10 m-t-10 text-center">
                                    <button class="btn btn-warning btn-square" type="submit" disabled={submitting} >
                                    <i class="feather icon-edit"></i>  Change Photo
                                    </button>
                                </div>
                                </div>

                                    </form>

                      </div>
                    </div>

                    <div class="card" style={{borderTop:'3px solid green'}} >
                      <div class="card-header py-3">
                        <h5 class="">Commission Amount</h5>
                      </div>
                          {/* <h4 class="m-4">CHANGE ACCOUNT INFO</h4> */}
                        <hr class="p-0 m-0"/>
                        <div class="card-block">
                        <div class="table-responsive dt-responsive">
                          <table class="table dtable table-striped table-bordered nowrap">
                            <tbody>


                            <tr>
                                <th>Total Earned</th>
                                <td> {details && details.rank_name && (
                                    <> {formattedAmount(details.rank_name)}</>
                                    )}</td>
                            </tr>

                            <tr>
                                <th>Total Withdrawn</th>
                                <td> {details && details.rank_name && (
                                    <> {formattedAmount(details.rank_name)}</>
                                    )}</td>
                            </tr>

                            <tr>
                                <th>Balance</th>
                                <td> {details && details.rank_name && (
                                    <> {formattedAmount(details.rank_name)}</>
                                    )}</td>
                            </tr>
                                  </tbody>
                              </table>
                            </div>
                        </div>
                      </div>


                      <div class="card" style={{borderTop:'3px solid green'}} >
                      <div class="card-header py-3">
                        <h5 class="">Transaction Info</h5>
                      </div>
                          {/* <h4 class="m-4">CHANGE ACCOUNT INFO</h4> */}
                        <hr class="p-0 m-0"/>
                        <div class="card-block">
                        <div class="table-responsive dt-responsive">
                          <table class="table dtable table-striped table-bordered nowrap">
                            <tbody>

                           
                                  
                           
                              </tbody>
                          </table>
                        </div>
                        </div>
                      </div>

                  </div>

                  <div class="col-sm-7">
                                

                  <div class="card" style={{borderTop:'3px solid red'}} >

                      <div class="card-header py-3">
                         
                  

                         <div class="card-header-right" style={{position: 'initial', padding:'0px 0!important;'}}>
                                    <ul class="list-unstyled card-option " >
                                        <li ><a href={`/associate-advisor-link-saving-account/${dataEncript(details.id)}`} class="label label-info px-0" title="Edit" > LINK SAVING ACCOUNT </a></li>
                                        <li ><a href={`/associate-advisor-edit/${dataEncript(details.id)}`} class="label label-warning px-0" title="Edit" > <i class="fa fa-print"></i> ID CARD </a></li>
                                        <li ><a href={`/associate-advisor-edit/${dataEncript(details.id)}`} class="label label-success px-0" title="Edit" > <i class="fa fa-print"></i> </a></li>
                                        <li ><a href={`/associate-advisor-edit/${dataEncript(details.id)}`} class="label label-default px-0" title="Edit" > <i class="feather icon-edit"></i> </a></li>
                                        <li ><a href={`/associate-advisor-chnage-password/${dataEncript(details.id)}`} class="label label-default px-0" title="Change Password" > <i class="fa fa-refresh"></i> </a></li>
                                        <li ><a href="#!"
                                                title="Lock/Unlock Account"
                                                onClick={(e) => {userAccountLockUnlock(e);}} class="label label-default px-0" > <i class={`fa ${details.account_login_locked === 'yes' ? 'fa-lock' : 'fa-unlock'}`}></i> </a></li>

                                        <li ><a href="#!"
                                                title="Resend Credential" onClick={(e) => {sendUserCredential(e);}} class="label label-default px-0" > <i class="fa fa-sign-in"></i> </a></li>
                                    </ul>
                              </div>      
                            
                                  
                      </div>

                        <hr class="p-0 m-0"/>

                  
                        <div class="card-block">
                        <div class="table-responsive dt-responsive">
                        <table class="table dtable table-striped table-bordered nowrap">
                          <tbody>

                          <tr>
                              <th>Employee Profile</th>
                              <td>{details && details.associate_emp_profile && (
                                <> {FirstLetterCapitalize(details.associate_emp_profile)}</>
                                )}</td>
                            </tr>
                            <tr>
                              <th>Supervisor</th>
                              <td>
                                 {details && details.associate_sup && (
                                <> {(details.associate_sup)}</>
                                )} 
                                </td>
                            </tr>
                            <tr>
                              <th>Rank</th>
                              <td>{details && details.associate_rank && (
                                <> {(details.associate_rank)}</>
                                )}</td>
                            </tr>
                            <tr>
                              <th>Code</th>
                              <td>{details && details.associate_code && (
                                <> {(details.associate_code)}</>
                                )}</td>
                            </tr>
                            <tr>
                              <th>User Name (LOGIN)</th>
                              <td>{details && details.login_user_name && (
                                <> {(details.login_user_name)}</>
                                )}</td>
                            </tr>
                            <tr>
                              <th>Name</th>
                              <td>{details && details.first_name && (
                                <> {(details.first_name)}</>
                                )} {details && details.last_name && (
                                  <>  {(details.last_name)}</>
                                  )} </td>
                            </tr>
                            <tr>
                              <th>Date Of Birth</th>
                              <td>{details && details.dob && (
                                <> {formatDate(details.dob)}</>
                                )}</td>
                            </tr>
                            <tr>
                              <th>Enrollment Date</th>
                              <td>{details && details.enroll_date && (
                                <> {formatDate(details.enroll_date)}</>
                                )}</td>
                            </tr>
                            <tr>
                              <th>Father Name</th>
                              <td>{details && details.father_name && (
                                <> {FirstLetterCapitalize(details.father_name)}</>
                                )}</td>
                            </tr>
                            <tr>
                              <th>Husband/Wife Name</th>
                              <td>{details && details.husband_wife_name && (
                                <> {FirstLetterCapitalize(details.husband_wife_name)}</>
                                )}</td>
                            </tr>
                            <tr>
                              <th>Email</th>
                              <td>{details && details.email && (
                                <> {(details.email)}</>
                                )}</td>
                            </tr>
                            <tr>
                              <th>Contact No.</th>
                              <td>{details && details.mobile_no && (
                                <> {(details.mobile_no)}</>
                                )}</td>
                            </tr>
                            <tr>
                              <th>Address</th>
                              <td>{details && details.address && (
                                <> {FirstLetterCapitalize(details.address)}</>
                                )}</td>
                            </tr>
                            <tr>
                              <th>Aadhaar No.</th>
                              <td>{details && details.aadhar_no && (
                                <> {(details.aadhar_no)}</>
                                )}</td>
                            </tr>
                            <tr>
                              <th>Pan No.</th>
                              <td>{details && details.pan_no && (
                                <> {(details.pan_no)}</>
                                )}</td>
                            </tr>
                            <tr>
                              <th>Branches</th>
                              <td>{details && details.branch_name && (
                                <> {FirstLetterCapitalize(details.branch_name)}</>
                                )}</td>
                            </tr>
                            <tr>
                              <th>Roles</th>
                              <td>{details && details.premission_roles && (
                                <> {FirstLetterCapitalize(details.premission_roles)}</>
                                )}</td>
                            </tr>
                            <tr>
                              <th>Active</th>
                              <td>
                              {
                                  details && (
                                      details.active === 'yes' ? (
                                      <label className="label label-success">yes</label>
                                      ) : details.active === 'no' ? (
                                      <label className="label label-danger">No</label>
                                      ) : null
                                  )
                                  }
                              </td>
                            </tr>
                            <tr>
                              <th>Account Login Locked</th>
                              <td> {
                                  details && (
                                      details.account_login_locked === 'yes' ? (
                                      <label className="label label-success">yes</label>
                                      ) : details.account_login_locked === 'no' ? (
                                      <label className="label label-danger">No</label>
                                      ) : null
                                  )
                                  }</td>
                            </tr>
                            <tr>
                              <th>Login on Holidays</th>
                              <td> {
                                  details && (
                                      details.login_on_holidays === 'yes' ? (
                                      <label className="label label-success">yes</label>
                                      ) : details.login_on_holidays === 'no' ? (
                                      <label className="label label-danger">No</label>
                                      ) : null
                                  )
                                  }</td>
                            </tr>
                            <tr>
                              <th>Search All Accounts (Yes) / Only Assigned (No)</th>
                              <td> {
                                  details && (
                                      details.searchable_acc === 'yes' ? (
                                      <label className="label label-success">yes</label>
                                      ) : details.searchable_acc === 'no' ? (
                                      <label className="label label-danger">No</label>
                                      ) : null
                                  )
                                  }</td>
                            </tr>
                            <tr>
                              <th>Access Type</th>
                              <td>{details && details.access_type && (
                                <> {FirstLetterCapitalize(details.access_type)}</>
                                )}</td>
                            </tr>
                            <tr>
                              <th>Collection Limit</th>
                              <td></td>
                            </tr>
                            <tr>
                              <th>Back Date Entry Allowed Days</th>
                              <td>{details && details.back_date_entry_days && (
                                <> {(details.back_date_entry_days)} {(details.back_date_entry_days>1)?'Days':'Day'}</>
                                )}</td>
                            </tr>
                            <tr>
                              <th>Nominee Name.</th>
                              <td>{details && details.nominee_name && (
                                <> {FirstLetterCapitalize(details.nominee_name)}</>
                                )}</td>
                            </tr>
                            <tr>
                              <th>Nominee Relation</th>
                              <td>{details && details.nominee_relation && (
                                <> {FirstLetterCapitalize(details.nominee_relation)}</>
                                )}</td>
                            </tr>

                            <tr>
                              <th>Nominee Address</th>
                              <td>{details && details.nominee_address && (
                                <> {FirstLetterCapitalize(details.nominee_address)}</>
                                )}</td>
                            </tr>

                          </tbody>
                      </table>
                      </div>

                        </div>

                      </div>

                  </div>
                </div>


                

              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
          <ToastContainer className="toast-container" />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
