import React, { useState, useEffect } from "react";
import DashboardLayout from "../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {   dataDecrypt, FirstLetterCapitalize, formatDate, formattedAmount, dataEncript, deleteConfirmation } from "../../../helper/commonHelpers";
//formatDate

function CompanyDetails() {
  const [submitting, setSubmitting] = useState(false);
  const { accountID }   = useParams();
  const [shareHolderDetail, setshareHolderDetail] = useState([]);
  const [memberList, setmemberList] = useState([]);
  const [schemeListArr, setschemeListArr] = useState([]);


  const [formErrors, setFormErrors] = useState({
    member_id: "",
  });


 


  const[account_type, setaccount_type] = useState('single');
  const[joint_ac1_member_id, setjoint_ac1_member_id] = useState('');
  const[member_id, setmember_id] = useState('');
  const[minor_id, setminor_id] = useState('');
  const[open_date, setopen_date] = useState('');
  const[scheme_id, setscheme_id] = useState('');
  const[amount, setamount] = useState('');

  const[acttype, setacttype] = useState('');


  useEffect(() => {
  

      axios.get(API_BASE_URL + "api-common/get-account-list?id="+dataDecrypt(accountID), {
        headers: API_HEADERS,
      }).then((response) => {

        console.log(response.data);

        if (response.data["status"] > 0) {

            console.log(response.data["data"]);

            setshareHolderDetail(response.data["data"]);
            setaccount_type(response.data["data"].account_type);
            setjoint_ac1_member_id(response.data["data"].joint_member1);

            setmember_id(response.data["data"].member_id);
            setminor_id(response.data["data"].minor_id);
            setscheme_id(response.data["data"].scheme_id);
            setopen_date((response.data["data"].open_date?formatDate(response.data["data"].open_date, 'YMD'):''));


            response.data['data'] && (
              response.data['data'].account_for === 'fd' ? (
                setacttype('FD')
              ) : response.data['data'].account_for === 'mis' ? (
                setacttype('MIS')
              ): response.data['data'].account_for === 'rd' ? (
                setacttype('RD')
              ): response.data['data'].account_for === 'dd' ? (
                setacttype('RD')
              ) : <></>
            )



        } else {
            toast.error(response.data["message"]);
        }

      });

    
      axios.get(API_BASE_URL + "api-member/get-member-list", {
        headers: API_HEADERS,
      }).then((response) => {
        if (response.data["status"] > 0) {
        setmemberList(response.data["data"]);
        }
      });
     
      let condition = ``;
      axios.get(API_BASE_URL+'api-common/get-account-scheme-list?condition='+condition, { headers: API_HEADERS })
      .then(response => {
            if (response.data["status"] > 0) {
              setschemeListArr(response.data['data']);
            }
      });

  }, [accountID]);




  const updateDetails = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));

    const formData = {
        id:dataDecrypt(accountID),

            account_type: account_type,
            joint_member1:joint_ac1_member_id, 
            minor_id:minor_id,

            member_id:member_id,
            open_date:open_date,
            scheme_id: scheme_id,
            amount: amount,


    };

  console.log(formData);
     

      if(account_type==='joint')
      {
  
        if (!joint_ac1_member_id) {
            errors.joint_ac1_member_id = 'Joint A/c Member1 is required.';
          }
    
      }


      if (!open_date) {
        errors.open_date = 'Open Date is required.';
      }


      shareHolderDetail && shareHolderDetail.account_for === 'rd' && !scheme_id && (
        errors.scheme_id = 'Scheme is required.'
      )

      shareHolderDetail && shareHolderDetail.account_for === 'rd' && !amount && (
        errors.amount = 'Amount is required.'
      )







    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {

        const confirmed = await deleteConfirmation('to change the Account Type?');
        if(confirmed===true)
        {

      axios.post(API_BASE_URL + "api-saving-account/change-account-type", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {


              shareHolderDetail && (
                shareHolderDetail.account_for === 'fd' ? (
                  window.location.href = "/fixed-deposit-account-view/"+accountID
                ) : shareHolderDetail.account_for === 'mis' ? (
                  window.location.href = "/fixed-deposit-mis-account-view/"+accountID
                ) : shareHolderDetail.account_for === 'rd' ? (
                  window.location.href = "/recurring-deposit-account-view/"+accountID
                ): shareHolderDetail.account_for === 'dd' ? (
                  window.location.href = "/recurring-deposit-account-view/"+accountID
                ) : <></>
              )

            //   window.location.href = "/account-transaction-view/"+accountID+'/'+response.data["data"].id;

              window.location.reload();
            }, 2000);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 1000);
        }); // close query hare

    }
    setSubmitting(false);
    } else {
      setSubmitting(false);
    } // hare close error
  };


  

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>  
                          
                        {
                            shareHolderDetail && (
                              shareHolderDetail.account_for === 'fd' ? (
                                <> FD Account</>
                              ) : shareHolderDetail.account_for === 'mis' ? (
                                <>MIS Deposits</>
                              ) : shareHolderDetail.account_for === 'rd' ? (
                                <>Recurring Deposits</>
                              ) : shareHolderDetail.account_for === 'dd' ? (
                                <>Daily Deposits</>
                              ) : <></>
                            )
                          }
                          
                          - {shareHolderDetail && shareHolderDetail.account_no && (
                                    <>{shareHolderDetail.account_no}</>
                                    )} 

                            <span>&nbsp;Change Account Info</span> </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          

                          {
                            shareHolderDetail && (
                              shareHolderDetail.account_for === 'fd' ? (
                                <a href="/fixed-deposit-account">FD Accounts </a>
                              ) : shareHolderDetail.account_for === 'mis' ? (
                                <a href="/fixed-deposit-mis-account">MIS Deposits </a>
                              ) : shareHolderDetail.account_for === 'rd' ? (
                                <a href="/recurring-deposit-account">Recurring Deposits </a>
                              ) : shareHolderDetail.account_for === 'dd' ? (
                                <a href="/recurring-deposit-account">Daily Deposits </a>
                              ) : <></>
                            )
                          }

                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          

                             {
                            shareHolderDetail && (
                              shareHolderDetail.account_for === 'fd' ? (
                                <a href={`/fixed-deposit-account-view/${dataEncript(shareHolderDetail.id)}`}> {shareHolderDetail && shareHolderDetail.account_no && (
                                  <>{shareHolderDetail.account_no}</>
                                  )} </a>
                              ) : shareHolderDetail.account_for === 'mis' ? (
                                <a href={`/fixed-deposit-mis-account-view/${dataEncript(shareHolderDetail.id)}`}> {shareHolderDetail && shareHolderDetail.account_no && (
                                  <>{shareHolderDetail.account_no}</>
                                  )} </a>
                              ) : shareHolderDetail.account_for === 'rd' ? (
                                <a href={`/recurring-deposit-account-view/${dataEncript(shareHolderDetail.id)}`}> {shareHolderDetail && shareHolderDetail.account_no && (
                                  <>{shareHolderDetail.account_no}</>
                                  )} </a>
                              ) : shareHolderDetail.account_for === 'dd' ? (
                                <a href={`/recurring-deposit-account-view/${dataEncript(shareHolderDetail.id)}`}> {shareHolderDetail && shareHolderDetail.account_no && (
                                  <>{shareHolderDetail.account_no}</>
                                  )} </a>
                              ) : <></>
                            )
                          }

                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> Change Account Info</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-7">
                    <div class="card" style={{borderTop:'3px solid red'}} >
                    
                        <h4 class="m-4">CHANGE ACCOUNT INFO</h4>
                       <hr class="p-0 m-0"/>

                     

                      <div class="card-block">
                      <form onSubmit={updateDetails}>

                      <h6 class="mb-2">Are you sure you want to change account type?</h6>


                      
                      <div class="row" style={{ display: (shareHolderDetail.account_for === 'rd' || shareHolderDetail.account_for === 'dd') ? '' : 'none' }} >
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Scheme <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                  <select
                                    class="form-control m-b"
                                    name="scheme_id"
                                    value={scheme_id}
                                    onChange={(e) =>
                                      setscheme_id(e.target.value)
                                    }
                                  >
                                    <option value={''} disabled>Select Scheme</option>
                                    {schemeListArr.map((schemeD, mindex) => (
                                      <option
                                        value={schemeD.id}
                                        data-index={mindex}
                                        selected={
                                          schemeD.id === scheme_id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {schemeD.scheme_code} - 
                                        {schemeD.scheme_name}

                                      </option>
                                    ))}
                                  </select>

                                  {formErrors.scheme_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.scheme_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                      <div class="row" style={{ display: shareHolderDetail.account_for === 'rd' ? 'none' : '' }} >
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Member <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                  <select
                                    class="form-control m-b"
                                    name="joint_ac1_member_id"
                                    value={member_id}
                                    onChange={(e) =>
                                      setmember_id(e.target.value)
                                    }
                                  >
                                    <option value={''} disabled>Select Member</option>
                                    {memberList.map((aBranchData) => (
                                      <option
                                        value={aBranchData.id}
                                        selected={
                                          aBranchData.id === member_id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {aBranchData.fname} &nbsp;
                                        {aBranchData.mnane}

                                      </option>
                                    ))}
                                  </select>

                                  {formErrors.member_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.member_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                      <div class="row" >
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Account Type<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_single">
                                        <input
                                          type="radio"
                                          name="account_type"
                                          value={"single"}
                                          id={"title_single"}
                                          checked={account_type === 'single' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setaccount_type(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Single
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_joint">
                                        <input
                                          type="radio"
                                          name="account_type"
                                          id={"title_joint"}
                                          value={"joint"}
                                          checked={account_type === 'joint' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setaccount_type(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Joint
                                      </label>
                                    </div>
                                  
                                    
                                  </div>
                                  {formErrors.account_type && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.account_type}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Joint Account Member
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                  <select
                                    class="form-control m-b"
                                    name="joint_ac1_member_id"
                                    value={joint_ac1_member_id}
                                    onChange={(e) =>
                                      setjoint_ac1_member_id(e.target.value)
                                    }
                                  >
                                    <option value={''} disabled>Select Member</option>
                                    {memberList.map((aBranchData) => (
                                      <option
                                        value={aBranchData.id}
                                        selected={
                                          aBranchData.id === joint_ac1_member_id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {aBranchData.fname} &nbsp;
                                        {aBranchData.mnane}

                                      </option>
                                    ))}
                                  </select>

                                  {formErrors.joint_ac1_member_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.joint_ac1_member_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Minor
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                  <select
                                    class="form-control m-b"
                                    name="minor_id"
                                    value={minor_id}
                                    onChange={(e) =>
                                      setminor_id(e.target.value)
                                    }
                                  >
                                    <option value={''} disabled>Select Member</option>
                                    {memberList.map((aBranchData) => (
                                      <option
                                        value={aBranchData.id}
                                        selected={
                                          aBranchData.id === minor_id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {aBranchData.fname} &nbsp;
                                        {aBranchData.mnane}

                                      </option>
                                    ))}
                                  </select>

                                  {formErrors.minor_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.minor_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>



                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Open Date <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                <input class="form-control" type="date" value={open_date} onChange={(e) => setopen_date(e.target.value) } placeholder="Enter Open Date" />

                                  {formErrors.open_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.open_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style={{ display: shareHolderDetail.account_for === 'rd' ? '' : 'none' }}>
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Amount<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                <input class="form-control" type="date" value={amount} onChange={(e) => setamount(e.target.value) } placeholder="Enter Open Date" />

                                  {formErrors.amount && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.amount}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>



                          





                      

                         


                        <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                            <button
                              class="btn btn-success btn-square"
                              type="submit"
                              disabled={submitting}
                            >
                              CHANGE ACCOUNT INFO
                            </button>{" "}
                       


                            {
                            shareHolderDetail && (
                              shareHolderDetail.account_for === 'fd' ? (
                                <a href={`/fixed-deposit-accountview/${dataEncript(shareHolderDetail.id)}`} class="btn btn-danger btn-square">Cancel</a>
                              ) : shareHolderDetail.account_for === 'mis' ? (
                                <a href={`/fixed-deposit-mis-accountview/${dataEncript(shareHolderDetail.id)}`} class="btn btn-danger btn-square">Cancel</a>
                              ) : shareHolderDetail.account_for === 'rd' ? (
                                <a href={`/recurring-deposit-accountview/${dataEncript(shareHolderDetail.id)}`} class="btn btn-danger btn-square">Cancel</a>
                              )  : shareHolderDetail.account_for === 'dd' ? (
                                <a href={`/recurring-deposit-accountview/${dataEncript(shareHolderDetail.id)}`} class="btn btn-danger btn-square">Cancel</a>
                              ) : <></>
                            )
                          }
                          

                            <ToastContainer className="toast-container" />
                          </div>
                        </div>


                      </form>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-5">
                  <div class="card" style={{borderTop:'3px solid green'}} >
                  <div class="card-header py-3 text-denger">
                        <h5>{acttype} Info</h5>
                              <div class="card-header-right">
                                    <ul class="list-unstyled card-option">
                                        <li><i class="feather icon-minus minimize-card"></i></li>
                                    </ul>
                              </div>
                        </div>
                        <div class="card-block p-0">
                        <form onSubmit={updateDetails}>

                        <div class="table-responsive dt-responsive">
                            <table class="table dtable table-striped table-bordered">
                            <tr>
                                <th>Member</th>
                                <td> {shareHolderDetail && shareHolderDetail.member_name && (
                                    <a href={`/view-member/${dataEncript(shareHolderDetail.member_id)}`} >
                                        {FirstLetterCapitalize(shareHolderDetail.member_created_id)} - 
                                        {FirstLetterCapitalize(shareHolderDetail.member_name)}
                                    </a>
                                    )} </td>
                            </tr>

                            <tr>
                                <th>{acttype} No.</th>
                                <td>
                                {shareHolderDetail && shareHolderDetail.account_no && (
                                    <>{(shareHolderDetail.account_no)}</>
                                    )} 
                                </td>
                            </tr>

                            <tr>
                                <th>Open Date</th>
                                <td>
                                {shareHolderDetail && shareHolderDetail.open_date && (
                                    <>{formatDate(shareHolderDetail.open_date)}</>
                                    )} 
                                </td>
                            </tr>

                            <tr>
                                <th>Status</th>
                                <td>{
                                      shareHolderDetail && (
                                        shareHolderDetail.account_status === 'active' ? (
                                          <label className="label label-success">Active</label>
                                        ) : shareHolderDetail.account_status === 'inactive' ? (
                                          <label className="label label-danger">Inactive</label>
                                        ) : null
                                      )
                                    }</td>
                            </tr>



                            <tr>
                                <th>Principal Amt</th>
                                <td>
                                {shareHolderDetail && shareHolderDetail.mis_amount && (
                                    <>{formattedAmount(shareHolderDetail.mis_amount)}</>
                                    )} 
                                </td>
                            </tr>

                            <tr>
                                <th>Balance Available</th>
                                <td>
                                {shareHolderDetail && shareHolderDetail.mis_amount && (
                                    <>{formattedAmount(shareHolderDetail.mis_amount)}</>
                                    )} 
                                </td>
                            </tr>


                            </table>
                        </div>

                        </form>
                        </div>
                    </div>
                  </div>
                </div>


                

              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
          <ToastContainer className="toast-container" />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
