// import React from 'react';
import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../config"; 

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function AdminLogin() {
    const [submitting, setSubmitting] = useState(false);
    const [formErrors, setFormErrors] = useState({
        email: "",
        password: "",
      });
    
      const[email, setemail] = useState('');
      const[password, setpassword] = useState('');

if(localStorage.getItem('userLogin'))
{
  window.location.href = "/dashboard";
}



    useEffect(() => {
        const canvas = document.getElementById('myCanvas');
        if (canvas) {
          const ctx = canvas.getContext('2d');
          console.log(ctx);
          // Do something with ctx...
        } else {
          console.error("Canvas element with ID 'myCanvas' not found");
        }
      }, []);


      const loginUser = (e) => {
        e.preventDefault();
        setSubmitting(true);
    
        let errors = {};
        const formData = {
          email:email,
          password:password
        };
    

          if (!formData.email) {
            errors.email = 'Email/ User name is required.';
          }
          if (!formData.password) {
            errors.password = 'Password is required.';
          }
    
    
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
          axios
            .post(API_BASE_URL + "api-user/user-login", formData, {
              headers: {
                ...API_HEADERS,
              },
            })
            .then((response) => {

                console.log(response);
                
              if (response.data["status"] > 0) {
                toast.success(response.data["message"]);
                localStorage.setItem('userLogin', JSON.stringify(response.data["data"]));

                setTimeout(() => {
                  window.location.href = "/dashboard";
                }, 2700);
              } else {
                toast.error(response.data["message"]);
              }
            })
            .catch((error) => {
              toast.error("Failed to submition!");
            })
            .finally(() => {
              setTimeout(() => {
                setSubmitting(false);
              }, 300);
            }); // close query hare
        } else {
          setSubmitting(false);
        } // hare close error
      };

      
  return (
    <>
    <div class="theme-loader">
    <div class="ball-scale">
        <div class="contain">
            <div class="ring">
                <div class="frame"></div>
            </div>
            <div class="ring">
                <div class="frame"></div>
            </div>
            <div class="ring">
                <div class="frame"></div>
            </div>
            <div class="ring">
                <div class="frame"></div>
            </div>
            <div class="ring">
                <div class="frame"></div>
            </div>
            <div class="ring">
                <div class="frame"></div>
            </div>
            <div class="ring">
                <div class="frame"></div>
            </div>
            <div class="ring">
                <div class="frame"></div>
            </div>
            <div class="ring">
                <div class="frame"></div>
            </div>
            <div class="ring">
                <div class="frame"></div>
            </div>
        </div>
    </div>
</div>

<section class="login-block">

    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <form onSubmit={loginUser} class="md-float-material form-material">
                    <div class="text-center">
                        <img src="../files/assets/images/logo.png" alt="logo.png" />
                    </div>
                    <div class="auth-box card">
                        <div class="card-block">
                            <div class="row m-b-20">
                                <div class="col-md-12">
                                    <h3 class="text-center">Sign In</h3>
                                </div>
                            </div>
                            <div class="form-group form-primary">
                                <input type="text" name="email"
                                 value={email}
                                    onChange={(e) =>
                                      setemail(e.target.value)
                                    }
                                     class="form-control"  placeholder="Your Email Address" />
                                <span class="form-bar"></span>
                                {formErrors.email && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.email}
                                    </span>
                                  )}
                            </div>
                            <div class="form-group form-primary">
                                <input type="password" name="password"
                                  onChange={(e) =>
                                    setpassword(e.target.value)
                                  }
                                class="form-control" required="" placeholder="Password" />
                                <span class="form-bar"></span>

                                {formErrors.password && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.password}
                                    </span>
                                  )}
                            </div>
                            <div class="row m-t-25 text-left">
                                <div class="col-12">
                                    <div class="checkbox-fade fade-in-primary d-">
                                        <label>
                                            <input type="checkbox" value="" />
                                            <span class="cr"><i class="cr-icon icofont icofont-ui-check txt-primary"></i></span>
                                            <span class="text-inverse">Remember me</span>
                                        </label>
                                    </div>
                                    <div class="forgot-phone text-right f-right">
                                        <a href="/dashboard" class="text-right f-w-600"> Forgot
                                        Password?</a>
                                    </div>
                                </div>
                            </div>
                            <div class="row m-t-30">
                                <div class="col-md-12">
                                    <button type="submit"
                                 disabled={submitting}
                                 class="btn btn-primary btn-md btn-block waves-effect waves-light text-center m-b-20">Sign
                                    in</button>
                                </div>
                            </div>
                            <hr />
                            <div class="row">
                                <div class="col-md-10">
                                    <p class="text-inverse text-left m-b-0">Thank you.</p>
                                    <p class="text-inverse text-left"><a href="../index.html"><b class="f-w-600">Back
                                    to website</b></a></p>
                                </div>
                                <div class="col-md-2">
                                    <img src="../files/assets/images/auth/Logo-small-bottom.png" alt="small-logo.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                  <canvas id="myCanvas"></canvas>
                  <ToastContainer className="toast-container" />

            </div>

        </div>

    </div>

</section> 
    </>
  );
}

export default AdminLogin;
