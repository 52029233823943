import React, { useState, useEffect } from "react";
import DashboardLayout from "../../dashboardLayout";
// import axios from "axios";
// import { API_BASE_URL, API_HEADERS } from "../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {   dataDecrypt, dataEncript, FirstLetterCapitalize, deleteConfirmation } from "../../../helper/commonHelpers";
//formatDate

import {
  getAccountList, getAssociateList, formPost
} from '../../../helper/listModal'; 


function CompanyDetails() {
  const [submitting, setSubmitting] = useState(false);
  const { advisorID }   = useParams();
  const [details, setdetails] = useState([]);
  const [saving_accountList, setsaving_accountList] = useState([]);

  const [formErrors, setFormErrors] = useState({
    account_id: "",
  });



  const[account_id, setaccount_id] = useState('');


  useEffect(() => {
  

    getAssociateList(dataDecrypt(advisorID)).then(data => {
      setdetails(data);
    }).catch(error => {
        // console.error('Error fetching associate list:', error);
    });


      getAccountList().then(data => {
        setsaving_accountList(data);
      }).catch(error => {
          // console.error('Error fetching associate list:', error);
      });



     


  }, [advisorID]);




  const updateDetails = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));



    const formData = {
      tablename:'tbl_associate',  // table name
       where_coloumn_name:'id', // which collumn name you want to  
       where_value:dataDecrypt(advisorID), // column value 
       set_coloumn_name:'saving_account', // which collumn name you want to update
       set_value:account_id, // collumn value
   };


    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {

        const confirmed = await deleteConfirmation(' to link FD account for auto credit interest to saving account??');
        if(confirmed===true)
        {


          formPost('api-common/update-status', formData).then(data => {

            toast.success(data["message"]);
            setTimeout(() => {
              window.location.href = "/associate-advisor-view/"+advisorID;

         }, 1200);

              setSubmitting(false);
          }).catch(error => {
              setSubmitting(false);
              toast.error(error);
          });


    }
    setSubmitting(false);
    } else {
      setSubmitting(false);
    } // hare close error
  };


  

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-12">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4> ASSOCIATE/ ADVISOR - {details && details.first_name && (
                                    <>{details.first_name}</>
                                    )} &nbsp; {details && details.last_name && (
                                      <>{details.last_name}</>
                                      )}  </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/fixed-deposit-account">Fixed Deposit </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href={`/fixed-deposit-account-view/${dataEncript(details.id)}`}> {details && details.first_name && (
                                    <>{details.first_name}</>
                                    )}  &nbsp; {details && details.last_name && (
                                      <>{details.last_name}</>
                                      )} </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> Link Saving Account</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="card" style={{borderTop:'3px solid red'}} >
                    
                        <h6 class="m-4">Link Associate saving account to Associate Profile to release incentive.</h6>
                       <hr class="p-0 m-0"/>

                     

                      <div class="card-block">
                      <form onSubmit={updateDetails}>

                      {/* <h6 class="mb-2">Are you sure you want to change account type?</h6> */}


                      <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                  Member <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                  <select
                                    class="form-control m-b"
                                    name="joint_ac1_account_id"
                                    value={account_id}
                                    onChange={(e) =>
                                      setaccount_id(e.target.value)
                                    }
                                  >
                                    <option value={''} disabled>Select Member</option>
                                    {saving_accountList.map((aBranchData) => (
                                      <option
                                        value={aBranchData.id}
                                        selected={
                                          aBranchData.id === account_id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {aBranchData.account_no} &nbsp;
                                       ({FirstLetterCapitalize(aBranchData.member_name)})

                                      </option>
                                    ))}
                                  </select>

                                  {formErrors.account_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.account_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>



                          





                      

                         


                        <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                            <button
                              class="btn btn-success btn-square"
                              type="submit"
                              disabled={submitting}
                            >
                            LINK ACCOUNT
                            </button>{" "}
                            <a href={`/fixed-deposit-account-view/${dataEncript(details.id)}`} class="btn btn-danger btn-square">Cancel</a>
                            &nbsp;
                          

                            <ToastContainer className="toast-container" />
                          </div>
                        </div>


                      </form>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6">


                  </div>
                </div>


                

              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
          <ToastContainer className="toast-container" />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
