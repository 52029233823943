import React, { useState, useEffect } from "react";
import DashboardLayout from "../../dashboardLayout";
// import axios from "axios";
// import { API_BASE_URL, API_HEADERS } from "../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { deleteConfirmation, dataDecrypt, dataEncript } from "../../../helper/commonHelpers";

import { formPost, getDataListBytable } from '../../../helper/listModal';

//formatDate

function CompanyDetails() {
  const [submitting, setSubmitting] = useState(false);
  const { rankID }   = useParams();
  const [details, setdetails] = useState([]);


  const [formErrors, setFormErrors] = useState({
    member_id: "",
  });


 




const[rank_name, setrank_name] = useState('');
const[rank_display_position, setrank_display_position] = useState('');
const[rank_working_position, setrank_working_position] = useState('');
const[collect_collection_charge, setcollect_collection_charge] = useState('yes');





  useEffect(() => {
  

   
    getDataListBytable('tbl_associate_rank', ``, dataDecrypt(rankID)).then(data => {
        // console.log(data);
        setdetails(data);
        setrank_name(data.rank_name);
        setrank_display_position(data.rank_display_position);
        setrank_working_position(data.rank_working_position);
        setcollect_collection_charge(data.collect_collection_charge);

      }).catch(error => {
          // console.error('Error fetching associate list:', error);
      });

  }, [rankID]);




  const updateDetails = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));

    const formData = {
            id: dataDecrypt(rankID),
            rank_name: rank_name,
            rank_display_position: rank_display_position,
            rank_working_position: rank_working_position,
            collect_collection_charge: collect_collection_charge,

    };

//   console.log(formData);
     


      if (!rank_name) {
        errors.rank_name = 'Rank Name is required.';
      }
      
      if (!rank_display_position) {
        errors.rank_display_position = 'Rank Display Position is required.';
      }

      if (!rank_working_position) {
        errors.rank_working_position = 'Rank Working Position is required.';
      }

      if (!collect_collection_charge) {
        errors.collect_collection_charge = 'Collection Charge Commission is required.';
      }



    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {

        const confirmed = await deleteConfirmation('to add UPDATE rank?');
        if(confirmed===true)
        {

          formPost('api-associates/add-rank', formData).then(data => {

            toast.success(data["message"]);
            setTimeout(() => {
              window.location.href = "/associate-advisor-agent-ranks";
            }, 2000);

              setSubmitting(false);
          }).catch(error => {
              setSubmitting(false);
              toast.error(error);
          });



    }
    setSubmitting(false);
    } else {
      setSubmitting(false);
    } 
  };


  

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>  Edit Rank - 

                        {details && details.rank_name && (
                                    <>{details.rank_name}</>
                                    )}  

                        </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                            <a href="/associate-advisor-agent-ranks">Associate/ Advisor Ranks </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                            <a href={`/associate-advisor-agent-ranks-view/${dataEncript(details.id)}`}>{details && details.rank_name && ( <>{details.rank_name}</> )} </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> Edit</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-7">
                    <div class="card" style={{borderTop:'3px solid red'}} >
                    
                        {/* <h4 class="m-4">CHANGE ACCOUNT INFO</h4> */}
                       <hr class="p-0 m-0"/>

                     

                      <div class="card-block">
                      <form onSubmit={updateDetails}>




                      <div class="row" >
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Rank Name<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                <input class="form-control" type="text" value={rank_name} onChange={(e) => setrank_name(e.target.value) } placeholder="Enter Rank Name" />

                                  {formErrors.rank_name && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.rank_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>




                      
                      <div class="row"  >
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Rank Display Position <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                  <select
                                    class="form-control m-b"
                                    name="rank_display_position"
                                    value={rank_display_position}
                                    onChange={(e) =>
                                      setrank_display_position(e.target.value)
                                    }
                                  >
                                    <option value={''} disabled>Select Rank</option>
                                    {Array.from({ length: 21 }, (_, index) => (
                                      <option key={index} value={index}>{index}</option>
                                    ))}

                                  </select>

                                  {formErrors.rank_display_position && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.rank_display_position}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                      <div class="row"  >
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Rank Working Position <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                  <select
                                    class="form-control m-b"
                                    name="rank_working_position"
                                    value={rank_working_position}
                                    onChange={(e) =>
                                      setrank_working_position(e.target.value)
                                    }
                                  >
                                    <option value={''} disabled>Select Position</option>
                                    {Array.from({ length: 21 }, (_, index) => (
                                      <option key={index} value={index}>{index}</option>
                                    ))}
                                   
                                  </select>

                                  <a href="#!" class="text-info">Top Level (1 - Manager) & Bottom (20 - Field Officer)</a>


                                  {formErrors.rank_working_position && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.rank_working_position}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                      <div class="row" >
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Collection Charge Commission<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_yes">
                                        <input
                                          type="radio"
                                          name="collect_collection_charge"
                                          value={"yes"}
                                          id={"title_yes"}
                                          checked={collect_collection_charge === 'yes' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setcollect_collection_charge(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Yes
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_no">
                                        <input
                                          type="radio"
                                          name="collect_collection_charge"
                                          id={"title_no"}
                                          value={"no"}
                                          checked={collect_collection_charge === 'no' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setcollect_collection_charge(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>NO
                                      </label>
                                    </div>
                                  
                                    
                                  </div>
                                  {formErrors.collect_collection_charge && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.collect_collection_charge}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                        
                      

                         


                        <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                            <button
                              class="btn btn-success btn-square"
                              type="submit"
                              disabled={submitting}
                            >
                              UPDATE RANK
                            </button>{" "}
                       

                            <a href="/associate-advisor-agent-ranks"  class="btn btn-danger btn-square">Cancel</a>
                          

                            <ToastContainer className="toast-container" />
                          </div>
                        </div>


                      </form>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-5">
                  
                  </div>
                </div>


                

              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
          <ToastContainer className="toast-container" />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
