import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly

// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { getCurrentDate } from "./../../../helper/commonHelpers";

function CompanyDetails() {
  // const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  // const [stateList, setStateList] = useState([]);
  const [pramoterList, setpramoterList] = useState([]);

  const [formErrors, setFormErrors] = useState({
    promoter_id: "",
    allotment_date: "",
    first_distinctive_no: "",
    last_distinctive_no: "",


    transaction_date: "",
    amount: "",
    paymode: "", 


    bank_name: "", 
    cheque_no: "", 
    cheque_date: "", 
    transfer_date: "", 
    tranfer_no: "", 
    transfer_mode: "", 
    saving_account: "", 

  });

  const[promoter_id, setpromoter_id] = useState('');
  const[allotment_date, setallotment_date] = useState('');
  const[first_distinctive_no, setfirst_distinctive_no] = useState('');
  const[last_distinctive_no, setlast_distinctive_no] = useState('');
  const[share_nominal_value, setshare_nominal_value] = useState('10.0');
  const[total_share_held, settotal_share_held] = useState('');
  const[total_shares_value, settotal_shares_value] = useState('');
  const[certificate_no, setcertificate_no] = useState('');

  const[paymode, setpaymode] = useState('cash');
  const[remark, setremark] = useState('');
  const[amount, setamount] = useState('');
  const[transaction_date, settransaction_date] = useState('');


const[bank_name, setbank_name] = useState('');
const[cheque_no, setcheque_no] = useState('');
const[cheque_date, setcheque_date] = useState('');
const[transfer_date, settransfer_date] = useState('');
const[tranfer_no, settranfer_no] = useState('');
const[transfer_mode, settransfer_mode] = useState('');
const[saving_account, setsaving_account] = useState('');

  useEffect(() => {
  

    // GET ACTIVE BRANCH LIST
    axios.get(API_BASE_URL + "api-company/get-active-promoter-list", {
        headers: API_HEADERS,
      })
      .then((response) => {
        setpramoterList(response.data["data"]);
      });

      setallotment_date(getCurrentDate());
      settransaction_date(getCurrentDate());
      setcheque_date(getCurrentDate());
      settransfer_date(getCurrentDate());



  }, []);

  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};

    const formData = {
      promoter_id:promoter_id,
      allotment_date:allotment_date,
      first_distinctive_no:first_distinctive_no,
      last_distinctive_no:last_distinctive_no,
      share_nominal_value:share_nominal_value,
      total_share_held:total_share_held,
      total_shares_value:total_shares_value,
      certificate_no:certificate_no,

      paymode: paymode,
      remark: remark,
      amount: amount,
      transaction_date: transaction_date,

    bank_name:bank_name,
    cheque_no:cheque_no,
    cheque_date:cheque_date,

    transfer_date:transfer_date,
    tranfer_no:tranfer_no,
    transfer_mode:transfer_mode,

    saving_account:saving_account,

    };


   

    // validate form
     if (!formData.first_distinctive_no) {
        errors.first_distinctive_no = 'First Distinctive No is required.';
      }

      if (!formData.last_distinctive_no) {
        errors.last_distinctive_no = 'Last Distinctive No is required.';
      }

      if (!formData.allotment_date) {
        errors.allotment_date = 'Allotment Date is required.';
      }

      if (!formData.first_distinctive_no) {
        errors.first_distinctive_no = 'First Distinctive No is required.';
      }

      if (!formData.transaction_date) {
        errors.transaction_date = 'Transaction Date is required.';
      }

      if (!formData.amount) {
        errors.amount = 'Amount is required.';
      }

      if (!formData.paymode) {
        errors.paymode = 'Pay Mode is required.';
      }


      if(formData.paymode==='cheque')
      {
        if (!formData.bank_name) {
          errors.bank_name = 'Bank Name is required.';
        }

        if (!formData.cheque_no) {
          errors.cheque_no = 'Cheque No. is required.';
        }

        if (!formData.cheque_date) {
          errors.cheque_date = 'Cheque Date. is required.';
        }
      }
  
      if(formData.paymode==='online')
      {
        if (!formData.transfer_date) {
          errors.transfer_date = 'Transfer Date is required.';
        }

        if (!formData.tranfer_no) {
          errors.tranfer_no = 'UTR/ Transaction No is required.';
        }

        if (!formData.transfer_mode) {
          errors.transfer_mode = 'Transfer Mode is required.';
        }
      }

      if(formData.paymode==='online')
      {
        if (!formData.saving_account) {
          errors.saving_account = 'Saving Account is required.';
        }
      }


    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      axios
        .post(API_BASE_URL + "api-company/add-promoter-share-holdings", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/share-holdings";
            }, 3700);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 300);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>Add Allocated Shares</h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/share-holdings">PROMOTERS SHARE HOLDINGS</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">Add Allocated Shares</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12"></div>

                  <div class="col-sm-12">
                    <div class="card">
                      <div class="card-header">
                        {/* <h5>Add New Promoter</h5> */}
                        {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                      </div>

                      <div class="card-block">
                        <form onSubmit={updateDetails}>
                          
                          <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Promoter<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <select
                                    class="form-control m-b"
                                    name="promoter_id"
                                    value={promoter_id}
                                    onChange={(e) =>
                                      setpromoter_id(e.target.value)
                                    }
                                  >
                                    <option value={''} disabled>Select Promoter</option>
                                    {pramoterList.map((aBranchData) => (
                                      <option
                                        value={aBranchData.id}
                                        selected={
                                          aBranchData.id === promoter_id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {aBranchData.fname} &nbsp;
                                        {aBranchData.lname}

                                      </option>
                                    ))}
                                  </select>
                                  {formErrors.promoter_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.promoter_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Allotment Date<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="date"
                                    value={allotment_date}
                                    onChange={(e) =>
                                      setallotment_date(e.target.value)
                                    }
                                    placeholder="Enter Allotment Date"
                                  />
                                  {formErrors.allotment_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.allotment_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    First Distinctive No.<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="number"
                                    value={first_distinctive_no}
                                    onChange={(e) =>
                                      setfirst_distinctive_no(e.target.value)
                                    }
                                    placeholder="Enter First Distinctive No."
                                  />
                                  {formErrors.first_distinctive_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.first_distinctive_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Last Distinctive No.
                                    <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="numberdate"
                                    value={last_distinctive_no}
                                    onChange={(e) =>
                                      setlast_distinctive_no(e.target.value)
                                    }
                                    placeholder="Enter Last Distinctive No."
                                  />
                                  {formErrors.last_distinctive_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.last_distinctive_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Share Nominal Value
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="number"
                                    value={share_nominal_value}
                                    onChange={(e) =>
                                      setshare_nominal_value(e.target.value)
                                    }
                                    placeholder="Enter Share Nominal Value" readOnly
                                  />
                                  {formErrors.share_nominal_value && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.share_nominal_value}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Total Shares Held
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={total_share_held}
                                    onChange={(e) =>
                                      settotal_share_held(e.target.value)
                                    }
                                    placeholder="Enter Total Shares Held" readOnly
                                  />
                                  {formErrors.total_share_held && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.total_share_held}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Total Shares
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="number"
                                    value={total_shares_value}
                                    onChange={(e) =>
                                      settotal_shares_value(e.target.value)
                                    }
                                    placeholder="Total Shares" readOnly
                                  />
                                  {formErrors.total_shares_value && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.total_shares_value}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Certificate No.
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={certificate_no}
                                    onChange={(e) =>
                                      setcertificate_no(e.target.value)
                                    }
                                    placeholder="Enter Certificate No."
                                  />
                                  {formErrors.certificate_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.certificate_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <h5 class="sub-title text-center">
                              
                              </h5>
                            </div>
                          </div>


                          <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    Transaction Date<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <input
                                    class="form-control"
                                    type="date"
                                    value={transaction_date}
                                    onChange={(e) =>
                                      settransaction_date(e.target.value)
                                    }
                                    placeholder="Enter Transaction Date"
                                  />
                                  {formErrors.transaction_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.transaction_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    Amount<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <input
                                    class="form-control"
                                    type="number"
                                    value={amount}
                                    onChange={(e) =>
                                      setamount(e.target.value)
                                    }
                                    placeholder="Enter Amount"
                                  />
                                  {formErrors.amount && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.amount}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    Remark's
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={remark}
                                    onChange={(e) =>
                                      setremark(e.target.value)
                                    }
                                    placeholder="Enter Remark's"
                                  />
                                  {formErrors.remark && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.remark}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    Pay Mode<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_cash">
                                        <input
                                          type="radio"
                                          name="paymode"
                                          value={"cash"}
                                          id={"title_cash"}
                                          checked={paymode === 'cash' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setpaymode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Cash
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_chque">
                                        <input
                                          type="radio"
                                          name="title"
                                          id={"title_chque"}
                                          value={"cheque"}
                                          checked={paymode === 'cheque' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setpaymode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Cheque
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3">
                                      <label for="title_online">
                                        <input
                                          type="radio"
                                          name="title"
                                          value={"online"}
                                          checked={paymode === 'online' ? 'checked' : ''}
                                          id={"title_online"}
                                          onChange={(e) =>
                                            setpaymode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Online
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3">
                                      <label for="title_saving">
                                        <input
                                          type="radio"
                                          name="title"
                                          value={"saving"}
                                          checked={paymode === 'saving' ? 'checked' : ''}
                                          id={"title_saving"}
                                          onChange={(e) =>
                                            setpaymode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Aaving Ac
                                      </label>
                                    </div>
                                  </div>
                                  {formErrors.title && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.title}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>



                       {/* CODE START FOR CHEQUE *************************************************************** */}
                         <div class="row" style={{ display: paymode === 'cheque' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    Bank Name<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={bank_name}
                                    onChange={(e) =>
                                      setbank_name(e.target.value)
                                    }
                                    placeholder="Enter Bank Name"
                                  />
                                  {formErrors.bank_name && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.bank_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style={{ display: paymode === 'cheque' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    Cheque<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={cheque_no}
                                    onChange={(e) =>
                                      setcheque_no(e.target.value)
                                    }
                                    placeholder="Enter Cheque"
                                  />
                                  {formErrors.cheque_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.cheque_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style={{ display: paymode === 'cheque' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    Cheque Date<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <input
                                    class="form-control"
                                    type="date"
                                    value={cheque_date}
                                    onChange={(e) =>
                                      setcheque_date(e.target.value)
                                    }
                                    placeholder="Enter Cheque Date"
                                  />
                                  {formErrors.cheque_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.cheque_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* ONLINE TRANSFAR START *********************************************** */}
                          <div class="row" style={{ display: paymode === 'online' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    Transfar date<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <input
                                    class="form-control"
                                    type="date"
                                    value={transfer_date}
                                    onChange={(e) =>
                                      settransfer_date(e.target.value)
                                    }
                                    placeholder="Enter Transfar date"
                                  />
                                  {formErrors.transfer_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.transfer_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                         

                          <div class="row" style={{ display: paymode === 'online' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    Transfar No.<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={tranfer_no}
                                    onChange={(e) =>
                                      settranfer_no(e.target.value)
                                    }
                                    placeholder="Enter Transfar No."
                                  />
                                  {formErrors.tranfer_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.tranfer_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style={{ display: paymode === 'online' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    Transfar Mode<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_IMPS">
                                        <input
                                          type="radio"
                                          name="transfer_mode"
                                          value={"IMPS"}
                                          id={"title_IMPS"}
                                          checked={transfer_mode === 'IMPS' ? 'checked' : ''}
                                          onChange={(e) =>
                                            settransfer_mode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>IMPS
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_VPA">
                                        <input
                                          type="radio"
                                          name="title"
                                          id={"title_VPA"}
                                          value={"VPA"}
                                          checked={transfer_mode === 'VPA' ? 'checked' : ''}
                                          onChange={(e) =>
                                            settransfer_mode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>VPA
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3">
                                      <label for="title_NEFT">
                                        <input
                                          type="radio"
                                          name="title"
                                          value={"NEFT/RTGS"}
                                          checked={transfer_mode === 'NEFT/RTGS' ? 'checked' : ''}
                                          id={"title_NEFT"}
                                          onChange={(e) =>
                                            settransfer_mode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>NEFT/RTGS
                                      </label>
                                    </div>
                                    
                                  </div>
                                  {formErrors.transfer_mode && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.transfer_mode}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                       {/*  ******************************* */}
                                
                       <div class="row" style={{ display: paymode === 'saving' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                  Select Saving Account<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                <select
                                    class="form-control m-b"
                                    name="saving_account"
                                    value={saving_account}
                                    onChange={(e) =>
                                      setsaving_account(e.target.value)
                                    }
                                  >
                                    <option value={' '} disabled>Select Saving Account</option>
                                    {pramoterList.map((aBranchData) => (
                                      <option
                                        value={aBranchData.id}
                                        selected={
                                          aBranchData.id === saving_account
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {aBranchData.fname} &nbsp;
                                        {aBranchData.lname}
                                      </option>
                                    ))}
                                  </select>
                                  {formErrors.saving_account && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.saving_account}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                              <button
                                class="btn btn-success btn-square"
                                type="submit"
                                disabled={submitting}
                              >
                                Allocate Share
                              </button>{" "}
                              &nbsp;
                              <a href="/share-holdings" class="btn btn-danger btn-square">Cancel</a>
                              &nbsp;
                              <button
                                class="btn btn-warning btn-square"
                                type="reset"
                              >
                                Reset
                              </button>

                              <ToastContainer className="toast-container" />
                            </div>
                          </div>


                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
