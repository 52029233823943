import React, { useState, useEffect } from 'react';
import DashboardLayout from '../dashboardLayout';

// import axios from "axios";
// import { API_BASE_URL, API_HEADERS } from "../../../config"; // Importing constants directly
// import $ from 'jquery';

import {  ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import {
      
      FirstLetterCapitalize,
      dataEncript
      
    } from '../../helper/commonHelpers'; 


    
import {
  getActiveBranchList, 
} from '../../helper/listModal'; 

function AdminLayout() {
   const [activeBranchArr, setactiveBranchArr] = useState([]);

 
  


    useEffect(() => {
      getActiveBranchList().then(data => {
        setactiveBranchArr(data);
      }).catch(error => {
          // console.error('Error fetching associate list:', error);
      });
    }, []);


 
    const[branch, setbranch] = useState('');


const filterData = (e) => {
      e.preventDefault();
  
   
        if(branch)
        {
            window.location.href = "/approvals-transaction/"+dataEncript(branch);
        }
   
    };


  return (
    <DashboardLayout>
<div class="pcoded-content">
      <div class="pcoded-inner-content">

            <div class="main-body">
                  <div class="page-wrapper">

                        <div class="page-header">
                    <div class="row align-items-end">
                        <div class="col-lg-8">
                            <div class="page-header-title">
                                <div class="d-inline">
                                    <h4>Approvals - Reverse Transactions  
                                        
                                     {/* <small>
                                          &nbsp;
                                          <a class="btn btn-info btn-sm" title="Add New" href="/collection-centers-group-new">
                                         <b>+</b> </a> &nbsp; 
                                      </small>  */}

                                        {/* <span>Saving/ FD/ RD/ Loans/ Salary / Incentive / Membership/ Share Transactions</span> */}
                                        
                                           </h4>
                                    {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="page-header-breadcrumb">
                                <ul class="breadcrumb-title">
                                    <li class="breadcrumb-item" style={{float:'left'}}>
                                        <a href="/dashboard"> <i class="feather icon-home"></i> </a>
                                    </li>
                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="#!">Approvals - Reverse Transactions  </a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


            <div class="page-body">


            <div class="card" style={{borderTop:'3px solid green'}} >
                  <div class="card-header py-3 text-denger">
                        <h5>Search Box</h5>
                              <div class="card-header-right">
                                    <ul class="list-unstyled card-option">
                                        <li><i class="feather icon-minus minimize-card"></i></li>
                                    </ul>
                              </div>
                        </div>
                        <div class="card-block" >
                        <form onSubmit={filterData}>
                        <div class="row">
                            <div class="col-sm-12 col-xl-3 m-b-30">
                                <div class="row">
                                  <div class="col-sm-12">
                                  <h6 class="">Branch :</h6>
                                  <select
                                    class="form-control m-b"
                                    name="branch"
                                    value={branch}
                                    onChange={(e) =>
                                      setbranch(e.target.value)
                                    }
                                  >
                                    <option value={' '}>Select - Branch</option>
                                     {activeBranchArr.map((assData) => (
                                      <option
                                        value={assData.id}
                                        selected={
                                          assData.id === branch
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {FirstLetterCapitalize(assData.branch_name)}
                                      </option>
                                    ))}
                                
                                  </select>
                                  </div>
                                </div>
                              </div>
                              
                             
                            

                              </div>

                              <div class="row">
                                <div class="col-sm-12 col-xl-12 m-b-30 text-center">

                                <button class="btn btn-success btn-square" type="submit"  >
                                <i class="fa fa-search"></i>   Search 
                                </button>
                                <ToastContainer className="toast-container" />

                                </div>
                              </div>

                              </form>
                        </div>
                    </div>



                  

                  






            </div>

      </div>
</div>
<ToastContainer className="toast-container" />
<div id="styleSelector">
</div>
</div>
</div>
    </DashboardLayout>
  );
}

export default AdminLayout;
