import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { dataDecrypt,
      dataEncript,
      formatDate
    //   getCurrentDate
     } from "./../../../helper/commonHelpers";

function CompanyDetails() {
  // const navigate = useNavigate();
  const { memberID, minorID }   = useParams();
  const [submitting, setSubmitting] = useState(false);
  // const [stateList, setStateList] = useState([]);
  const [promoterhDetails, setpromoterhDetails] = useState([]);

  const [formErrors, setFormErrors] = useState({
    enrollment_date: "",
    title: "",
    gender: "",
    fname: "",
    dob: "",
    father_name: "",
    addres: "",
  });


  const [enrollment_date, setenrollment_date] = useState('');

  // PROMOTER INFO 
  const [title, settitle] = useState('Md.');
  const [gender, setgender] = useState('male');
  const [fname, setfname] = useState('');
  const [lname, setlname] = useState('');
  const [dob, setdob] = useState('');
  const [father_name, setfather_name] = useState('');

  // PROMOTER KYC
  const [aadhar_no, setaadhar_no] = useState('');
  const [address, setaddress] = useState('');


  useEffect(() => {
    
    axios.get(API_BASE_URL+'api-member/get-member-list?member_id='+dataDecrypt(memberID), {
        headers: API_HEADERS
    }).then((response) => {
        if(response.data['status'] > 0)
        {
          setpromoterhDetails(response.data['data']);
        }
      })
      .catch((error) => {
        toast.error('Failed to fetch DETAILS list!');
      });


  // getminor details
  let minorCondition = `id = ${dataDecrypt(minorID)}`;
  axios.get(API_BASE_URL + "api-common/get-data?tablename=tbl_minor&condition="+minorCondition+"&id="+dataDecrypt(minorID), {
    headers: API_HEADERS,
  })
  .then((response) => {

    if (response.data["status"] > 0) {
        settitle(response.data["data"].title);
        setgender(response.data["data"].gender);
        setfname(response.data["data"].fname);
        setlname(response.data["data"].lname);

        setdob(formatDate(response.data["data"].dob, 'YMD'));
        setenrollment_date(formatDate(response.data["data"].enrollment_date, 'YMD'));


        setfather_name(response.data["data"].father_name);
        setaadhar_no(response.data["data"].aadhar_no);
        setaddress(response.data["data"].address);
    } else {
        toast.error(response.data["message"]);
    }

  })
  .catch((error) => {
    toast.error("Failed to fetch MINOR DATA!");
  });



      
  }, [memberID, minorID]);

  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};

    const formData = {
      minor_id:dataDecrypt(minorID),
      member_id:dataDecrypt(memberID),
      enrollment_date:enrollment_date,
      title: title,
      gender: gender,
      fname: fname,
      lname: lname,
      dob: dob,
      father_name: father_name,
      aadhar_no: aadhar_no,
      address: address,
      member_type:'member'
    };

    
    console.log(formData);
    // validate form
      if (!formData.enrollment_date) {
        errors.enrollment_date = 'Enrollment date is required.';
      }
      if (!formData.title) {
        errors.title = 'Title is required.';
      }
      if (!formData.gender) {
        errors.gender = 'Gender is required.';
      }

      if (!formData.fname) {
        errors.fname = 'First Name is required.';
      }

      if (!formData.dob) {
        errors.dob = 'Date of birth is required.';
      }

      if (!formData.father_name) {
        errors.father_name = 'Father name is required.';
      }

      if (!formData.address) {
        errors.addres = 'Address is required.';
      }



    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      axios
        .post(API_BASE_URL + "api-member/update-minor", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/view-member/"+dataEncript(promoterhDetails.id);
            }, 2700);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 2000);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>Edit Minor <small> Under {promoterhDetails && promoterhDetails.fname && (
                                    <span>{promoterhDetails.fname}</span>
                                    )}

                                    {promoterhDetails && promoterhDetails.mname && (
                                    <span>{promoterhDetails.mname}</span>
                                    )}</small> </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/members-management">Member List</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href={`/view-promoter/${dataEncript(promoterhDetails.id)}`} >{promoterhDetails && promoterhDetails.fname && (
                                    <span>{promoterhDetails.fname}</span>
                                    )}

                                    {promoterhDetails && promoterhDetails.mname && (
                                    <span>{promoterhDetails.mname}</span>
                                    )}</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">Minors</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">Edit</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12"></div>

                  <div class="col-sm-12">
                    <div class="card">
                      <div class="card-header">
                        {/* <h5>Edit Promoter</h5> */}
                        {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                      </div>

                      <div class="card-block">
                        <form onSubmit={updateDetails}>
                          

                        <div class="row">

                            <div class="col-sm-12 col-xl-6 m-b-30">
                            <div class="row">
                                <div class="col-sm-5">
                                <h6 class="text-xl-right">
                                    Enrollment Date
                                    <span class="text-danger">*</span>
                                </h6>
                                </div>
                                <div class="col-sm-7">
                                <input
                                    class="form-control"
                                    type="date"
                                    value={enrollment_date}
                                    onChange={(e) =>
                                    setenrollment_date(e.target.value)
                                    }
                                    placeholder="Enter Enrollment Date"
                                />
                                {formErrors.enrollment_date && (
                                    <span style={{ color: "red" }}>
                                    {formErrors.enrollment_date}
                                    </span>
                                )}
                                </div>
                            </div>
                            </div>
                            </div>

                          <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Title<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <div class="form-radio row">
                                    <div class="radio radio-inline col-xs-3 ml-3">
                                      <label for="title_md">
                                        <input
                                          type="radio"
                                          name="title"
                                          value={"Md."}
                                          id={"title_md"}
                                          checked={title === 'Md.' ? 'selected' : ''}
                                          onChange={(e) =>
                                            settitle(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Md.
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_mr">
                                        <input
                                          type="radio"
                                          name="title"
                                          id={"title_mr"}
                                          value={"Mr."}
                                          checked={title === 'Mr.' ? 'checked' : ''}
                                          onChange={(e) =>
                                            settitle(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Mr.
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3">
                                      <label for="title_ms">
                                        <input
                                          type="radio"
                                          name="title"
                                          value={"Ms."}
                                          checked={title === 'Ms.' ? 'checked' : ''}
                                          id={"title_ms"}
                                          onChange={(e) =>
                                            settitle(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Ms.
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3">
                                      <label for="title_mrs">
                                        <input
                                          type="radio"
                                          name="title"
                                          value={"Mrs."}
                                          checked={title === 'Mrs.' ? 'checked' : ''}
                                          id={"title_mrs"}
                                          onChange={(e) =>
                                            settitle(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Mrs.
                                      </label>
                                    </div>
                                  </div>
                                  
                                  {formErrors.title && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.title}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Gender<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <div class="form-radio row ml-1" >
                                    <div class="radio radio-inline col-xs-3">
                                      <label for="gender_male">
                                        <input
                                          type="radio"
                                          name="gender"
                                          value={"male"}
                                          id={"gender_male"}
                                          checked={gender === 'male' ? 'checked' : ''} 
                                          onChange={(e) =>
                                            setgender(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Male.
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3">
                                      <label for="gender_female">
                                        <input
                                          type="radio"
                                          name="gender"
                                          value={"female"}
                                          checked={gender === 'female' ? 'checked' : ''} 
                                          id={"gender_female"}
                                          onChange={(e) =>
                                            setgender(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Female.
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3">
                                      <label for="gender_other">
                                        <input
                                          type="radio"
                                          name="gender"
                                          value={"other"}
                                          checked={gender === 'other' ? 'checked' : ''}
                                          id={"gender_other"}
                                          onChange={(e) =>
                                            setgender(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Other.
                                      </label>
                                    </div>
                                  </div>
                                  {formErrors.gender && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.gender}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
           <h6 class="text-xl-right">First Name<span class="text-danger">*</span></h6>
        </div>
        <div class="col-sm-7">
        <input class="form-control" type="text" value={fname} onChange={(e) => setfname(e.target.value)} placeholder='Enter First Name' />
          {formErrors.fname && <span style={{ color: 'red' }}>{formErrors.fname}</span>}
        </div>
    </div>
  </div>

  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
          <h6 class="text-xl-right">
                                Last Name
                              </h6>
        </div>
        <div class="col-sm-7">
          <input
                                class="form-control"
                                type="text"
                                value={lname}
                                onChange={(e) => setlname(e.target.value)}
                                placeholder="Enter Last Name"
                              />
        </div>
    </div>
  </div>

  
</div>


<div class="row">
 


  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
          <h6 class="text-xl-right">Date of Birth<span class="text-danger">*</span></h6>
        </div>
        <div class="col-sm-7">
            <input
                                class="form-control"
                                type="date"
                                value={dob}
                                onChange={(e) => setdob(e.target.value)}
                                placeholder="Enter Date of Birth"
                              />
                              {formErrors.dob && (
                                    <span style={{ color: "red" }}>
                                    {formErrors.dob}
                                    </span>
                                )}
        </div>
    </div>
  </div>

  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
          <h6 class="text-xl-right">Father Name<span class="text-danger">*</span></h6>
        </div>
        <div class="col-sm-7">
          <input
                                class="form-control"
                                value={father_name}
                                onChange={(e) => setfather_name(e.target.value)}
                                type="text"
                                placeholder="Enter Father Name"
                              />
                              {formErrors.father_name && (
                                    <span style={{ color: "red" }}>
                                    {formErrors.father_name}
                                    </span>
                                )}
        </div>
    </div>
  </div>
</div>







                          <div class="row">
  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
           <h6 class="text-xl-right">Aadhaar No.</h6>
        </div>
        <div class="col-sm-7">
              <input
                                class="form-control"
                                type="text"
                                value={aadhar_no}
                                onChange={(e) => setaadhar_no(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.target.value.length === 12) {
                                    e.preventDefault();
                                  }
                                }}
                                placeholder="Enter Aadhaar No."
                              />
        </div>
    </div>
  </div>


  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
           <h6 class="text-xl-right">Address<span class="text-danger">*</span></h6>
        </div>
        <div class="col-sm-7">
            <textarea class="form-control" rows={'3'} onChange={(e) => setaddress(e.target.value)} value={address} ></textarea>
            {formErrors.addres && (
                                    <span style={{ color: "red" }}>
                                    {formErrors.addres}
                                    </span>
                                )}
        </div>
    </div>
  </div>
</div>






                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                              <button
                                class="btn btn-success btn-square"
                                type="submit"
                                disabled={submitting}
                              >
                                Update
                              </button>{" "}
                              &nbsp;
                              <a href={`/view-member/${dataEncript(promoterhDetails.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                              &nbsp;
                            

                              <ToastContainer className="toast-container" />
                            </div>
                          </div>


                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
