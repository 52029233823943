import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {   dataDecrypt, FirstLetterCapitalize, formatDate, getCurrentDate, formattedAmount, dataEncript } from "./../../../helper/commonHelpers";
//formatDate

function CompanyDetails() {
  const [submitting, setSubmitting] = useState(false);
  const { accountID }   = useParams();
  const [shareHolderDetail, setshareHolderDetail] = useState([]);


  const [formErrors, setFormErrors] = useState({
    member_id: "",
    transaction_date: "",
    amount: "",
  });


 
  
  const[remark, setremark] = useState('');
  const[amount, setamount] = useState('0');

  const[mgst, setmgst] = useState('18');
  const[total_amount, settotal_amount] = useState('');

  const[transaction_date, settransaction_date] = useState('');
  const[charge_type, setcharge_type] = useState('');



  useEffect(() => {
  

      axios.get(API_BASE_URL + "api-common/get-account-list?id="+dataDecrypt(accountID), {
        headers: API_HEADERS,
      }).then((response) => {

        console.log(response.data);

        if (response.data["status"] > 0) {
            setshareHolderDetail(response.data["data"]);


        } else {
            toast.error(response.data["message"]);
        }

      }).catch((error) => {
        toast.error("Failed to fetch data!");
      });

    

     


      settransaction_date(getCurrentDate());
  }, [accountID]);




  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));

    const formData = {
        account_id:dataDecrypt(accountID),

        remark: remark,
        amount: amount,
        gst: mgst,
        tamount: total_amount,
        charge_type:charge_type,
        transaction_type: 'debit',

        transaction_date: transaction_date,

        

    };

    // validate form

      // if (!member_id) {
      //   errors.member_id = 'Member is required.';
      // }

     

      if (!transaction_date) {
        errors.transaction_date = 'Transaction date is required.';
      }

      if (!amount) {
        errors.amount = 'Amount is required.';
      }

      if (!charge_type) {
        errors.charge_type = 'Charge Type is required.';
      }


    


    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      axios
        .post(API_BASE_URL + "api-saving-account/add-other-charges", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              // window.location.href = "/member-share-holdings";
              window.location.href = "/account-transaction-view/"+accountID+'/'+response.data["data"].id;

            //   window.location.reload();
            }, 2000);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 1000);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };


  

  const calculateamount = async (ttlamount) => {
    let module = 1+mgst/100;
    setamount((ttlamount/module).toFixed(2));

};

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4> Account - {shareHolderDetail && shareHolderDetail.account_no && (
                                    <>{shareHolderDetail.account_no}</>
                                    )} 

                            <span>&nbsp;Other Charges</span> </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/account-list">Accounts </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href={`/account-view/${dataEncript(shareHolderDetail.id)}`}> {shareHolderDetail && shareHolderDetail.account_no && (
                                    <>{shareHolderDetail.account_no}</>
                                    )} </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> Other Charges</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-7">
                    <div class="card" style={{borderTop:'3px solid red'}} >
                    

                      <div class="card-block">
                      <form onSubmit={updateDetails}>


                      
                      <div class="row">
                        <div class="col-sm-12 col-xl-12 m-b-30">
                            <div class="row">
                              <div class="col-sm-5">
                                <h6 class="text-xl-right">
                                Charge Type<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-7">
                                <select
                                    class="form-control m-b"
                                    name="charge_type"
                                    value={charge_type}
                                    onChange={(e) =>
                                      setcharge_type(e.target.value)
                                    }
                                  >
                                    <option value="">Select Type</option>
                                    <option value="SMS Charges">Sms Charges</option>
                                    <option value="Stamp Duty">Stamp Duty</option>
                                    <option value="Cheque Bounce Charges">Cheque Bounce Charges</option>
                                    <option value="Passbook Charges">Passbook Charges</option>
                                    <option value="Other Charges">Other Charges</option>
                                    <option value="Neft Rtgs Charges">Neft Rtgs Charges</option>
                                    <option value="Locker Charges">Locker Charges</option>
                                    <option value="Debit Card Charges">Debit Card Charges</option>
                                    <option value="Gst">Gst</option>
                                    <option value="Minimum Balance Charges">Minimum Balance Charges</option>
                                    <option value="Insurance Fee">Insurance Fee</option>
                                    <option value="Loan Application Fee">Loan Application Fee</option>
                                    <option value="Credit Report Fee">Credit Report Fee</option>
                                    
                                  </select>
                                {formErrors.charge_type && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.charge_type}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                  <div class="col-sm-5">
                                  <h6 class="text-xl-right">Transaction Date<span class="text-danger">*</span></h6>
                                  </div>
                                  <div class="col-sm-7">
                                  <input
                                      class="form-control"
                                      type="date"
                                      value={transaction_date}
                                      onChange={(e) =>
                                      settransaction_date(e.target.value)
                                      }
                                      placeholder="Enter Transaction Date. "
                                  />
                                  {formErrors.transaction_date && (
                                      <span style={{ color: "red" }}>
                                      {formErrors.transaction_date}
                                      </span>
                                  )}
                                  </div>
                              </div>
                              </div>
                          </div>
                       

                        


                        <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Charges<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                    <div class="row">
                                      <div class="col-xs-4 col-4 text-center"><h6 style={{width:'inherit'}}>Amount</h6></div>
                                      <div class="col-xs-4 col-4 text-center"><h6 style={{width:'inherit'}}>GST Rate (%)</h6> </div>
                                      <div class="col-xs-4 col-4 text-center"><h6 style={{width:'inherit'}}>Total Amount</h6></div>
                                    </div>

                                <div class="input-group">
                                  <input class="form-control" type="text" value={amount} onChange={(e) => setamount(e.target.value) } placeholder="0 " readOnly />
                                  <div class="input-group-prepend">
                                    <input class="form-control" type="text" value={mgst} onChange={(e) => setmgst(e.target.value) } placeholder="" readOnly />
                                  </div>
                                  <div class="input-group-prepend"> 
                                    <input class="form-control" type="number" value={total_amount} onInput={(e) => {settotal_amount(e.target.value); calculateamount(e.target.value); } } placeholder="Amount " />
                                  </div>
                                </div>

                                  
                                  {formErrors.amount && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.amount}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>




                        <div class="row">
                        <div class="col-sm-12 col-xl-12 m-b-30">
                            <div class="row">
                              <div class="col-sm-5">
                                <h6 class="text-xl-right">
                                Remarks (if any)<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-7">
                               

                                  <textarea class="form-control" value={remark} rows={'3'}
                                  onChange={(e) =>
                                    setremark(e.target.value)
                                  }
                                  placeholder="Enter Remarks (if any). " ></textarea>

                                {formErrors.remark && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.remark}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        


                        

                         


                        <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                            <button
                              class="btn btn-success btn-square"
                              type="submit"
                              disabled={submitting}
                            >
                              DEBIT
                            </button>{" "}
                            <a href={`/account-view/${dataEncript(shareHolderDetail.id)}`} class="btn btn-danger btn-square">Cancel</a>
                            &nbsp;
                          

                            <ToastContainer className="toast-container" />
                          </div>
                        </div>


                      </form>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-5">
                    <div class="card" style={{borderTop:'3px solid green'}} >

                      <div class="card-block">
                        <form onSubmit={updateDetails}>

                        <div class="table-responsive dt-responsive">
                            <table class="table dtable table-striped table-bordered">
                            <tr>
                                <th>Member.</th>
                                <td> {shareHolderDetail && shareHolderDetail.member_name && (
                                    <a href={`/account-view/${dataEncript(shareHolderDetail.member_id)}`} >
                                        {FirstLetterCapitalize(shareHolderDetail.member_name)}
                                      </a>
                                    )} </td>
                            </tr>
                            <tr>
                                <th>PAN No.</th>
                                <td> {shareHolderDetail && shareHolderDetail.pan && (
                                    <>{shareHolderDetail.pan}</>
                                    )}</td>
                            </tr>
                            <tr>
                                <th>Joint Account</th>
                                <td>{
                                      shareHolderDetail && (
                                        shareHolderDetail.account_type === 'joint' ? (
                                          <label className="label label-success">Yes</label>
                                        ) : shareHolderDetail.account_type === 'single' ? (
                                          <label className="label label-danger">No</label>
                                        ) :null
                                      )
                                    }
                                   </td>
                            </tr>
                            <tr>
                                <th>Account No.</th>
                                <td> {shareHolderDetail && shareHolderDetail.account_no && (
                                    <>{shareHolderDetail.account_no}</>
                                    )} </td>
                            </tr>
                            <tr>
                                <th>Scheme</th>
                                <td>
                                {shareHolderDetail && shareHolderDetail.scheme_name && (
                                    <>{shareHolderDetail.scheme_name}</>
                                    )} 
                                </td>
                            </tr>
                            <tr>
                                <th>Open Date</th>
                                <td>
                                {shareHolderDetail && shareHolderDetail.open_date && (
                                    <>{formatDate(shareHolderDetail.open_date)}</>
                                    )} 
                                </td>
                            </tr>

                            <tr>
                                <th>Status</th>
                                <td>{
                                      shareHolderDetail && (
                                        shareHolderDetail.account_status === 'active' ? (
                                          <label className="label label-success">Active</label>
                                        ) : shareHolderDetail.account_status === 'inactive' ? (
                                          <label className="label label-danger">Inactive</label>
                                        ) : null
                                      )
                                    }</td>
                            </tr>

                            <tr>
                                <th>Lock Balance (A)</th>
                                <td> {shareHolderDetail && shareHolderDetail.lock_amount && (
                                      <>{formattedAmount(shareHolderDetail.lock_amount)}</>
                                      )}</td>
                            </tr>

                            <tr>
                                <th>Hold Balance (B)</th>
                                <td> {shareHolderDetail && shareHolderDetail.lock_amt && (
                                      <>{formattedAmount(shareHolderDetail.lock_amt)}</>
                                      )}</td>
                            </tr>

                            <tr>
                                <th>Available Balance (C)</th>
                                <td> {shareHolderDetail && shareHolderDetail.mis_amount && (
                                      <>{formattedAmount(shareHolderDetail.mis_amount)}</>
                                      )}</td>
                            </tr>

                            <tr>
                                <th>Combined Balance (A + B + C + D - E)</th>
                                <td> {shareHolderDetail && shareHolderDetail.lock_amt && (
                                      <>{formattedAmount(shareHolderDetail.lock_amt)}</>
                                      )}</td>
                            </tr>

                            </table>
                        </div>

                        </form>
                      </div>
                    </div>
                  </div>


                </div>


                

              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
          <ToastContainer className="toast-container" />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
