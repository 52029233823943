import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {  dataEncript, dataDecrypt, FirstLetterCapitalize, formatDate } from "./../../../helper/commonHelpers";
//formatDate

function CompanyDetails() {

  const { shareholdID }   = useParams();
  const [shareHolderDetail, setshareHolderDetail] = useState([]);

  useEffect(() => {
  

      axios.get(API_BASE_URL + "api-company/get-promoter-share-holdings-by-id?share_holding_id="+dataDecrypt(shareholdID), {
        headers: API_HEADERS,
      })
      .then((response) => {

        if (response.data["status"] > 0) {
            setshareHolderDetail(response.data["data"]);
        } else {
            toast.error(response.data["message"]);
        }

      })
      .catch((error) => {
        toast.error("Failed to fetch data!");
      });

    

  }, [shareholdID]);



console.log(shareHolderDetail);


  const updateDetails = (e) => {
    e.preventDefault();
  };

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>
                          {shareHolderDetail && shareHolderDetail.promoter_name && (
                            <>{FirstLetterCapitalize(shareHolderDetail.promoter_name)}</>
                            )}

                            <small>&nbsp;Share holdings</small> </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/share-holdings">Promoter Share Holdings </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> {shareHolderDetail && shareHolderDetail.promoter_name && (
                            <>{FirstLetterCapitalize(shareHolderDetail.promoter_name)}</>
                            )}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-8">
                    <div class="card">
                      <div class="card-header">
                      <a
                          style={{ float: "right" }}
                          class="badge badge-info"
                          href={`/edit-share-holdings/${dataEncript(shareHolderDetail.id)}`}
                          title="Edit"
                        >
                           <i class="feather icon-edit"></i>
                        </a>
                      </div>

                      <div class="card-block">
                        <form onSubmit={updateDetails}>

                        <div class="table-responsive dt-responsive">
                            <table class="table table-striped table-bordered nowrap">
                            <tr>
                                <th>Membership No.</th>
                                <td> </td>
                            </tr>
                            <tr>
                                <th>Promoter Name</th>
                                <td> {shareHolderDetail && shareHolderDetail.promoter_name && (
                                    <>{FirstLetterCapitalize(shareHolderDetail.promoter_name)}</>
                                    )}</td>
                            </tr>
                            <tr>
                                <th>Share Allotment Date</th>
                                <td> {shareHolderDetail && shareHolderDetail.allotment_date && (
                                    <>{formatDate(shareHolderDetail.allotment_date)}</>
                                    )}</td>
                            </tr>
                            <tr>
                                <th>Share Range</th>
                                <td>  {shareHolderDetail && shareHolderDetail.first_distinctive_no && (
                                    <>{shareHolderDetail.first_distinctive_no}</>
                                    )} 
                                    {shareHolderDetail && shareHolderDetail.last_distinctive_no && (
                                    <> - {shareHolderDetail.last_distinctive_no}</>
                                    )}
                                    
                                    </td>
                            </tr>
                            <tr>
                                <th>Total Shares Held</th>
                                <td> {shareHolderDetail && shareHolderDetail.total_share_held && (
                                    <>{shareHolderDetail.total_share_held}</>
                                    )} </td>
                            </tr>
                            <tr>
                                <th>Nominal Value</th>
                                <td>
                                {shareHolderDetail && shareHolderDetail.share_nominal_value && (
                                    <>{shareHolderDetail.share_nominal_value}</>
                                    )} 
                                </td>
                            </tr>
                            <tr>
                                <th>Total Value</th>
                                <td>
                                {shareHolderDetail && shareHolderDetail.total_shares_value && (
                                    <>{shareHolderDetail.total_shares_value}</>
                                    )} 
                                </td>
                            </tr>
                            <tr>
                                <th>Share Certificate No</th>
                                <td> {shareHolderDetail && shareHolderDetail.certificate_no && (
                                    <>{FirstLetterCapitalize(shareHolderDetail.certificate_no)}</>
                                    )}</td>
                            </tr>
                            </table>
                        </div>

                        </form>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="row">
                  <div class="col-sm-12">
                    <div class="card">
                      <div class="card-header"></div>

                      <div class="card-block">

                      <div class="table-responsive dt-responsive">
                            <table class="table dtable table-striped table-bordered nowrap">
                                <thead class="bg-primary">
                                    <tr>
                                    <th>BUSINESS TYPE</th>
                                    <th>TRANSFEROR</th>
                                    <th>TRANSFEREE</th>
                                    <th>SHARE RANGE</th>
                                    <th>NOMINAL VAL.</th>
                                    <th>NO. OF SHARES</th>
                                    <th>TRANSFER DATE</th>
                                    <th>NEW SHARE</th>
                                    </tr>
                                </thead>
                                <tbody>

                                </tbody>
                            </table>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
          <ToastContainer className="toast-container" />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
