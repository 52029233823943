import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../dashboardLayout';

import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "../../../../config"; // Importing constants directly
// import $ from 'jquery';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



import {
      
      FirstLetterCapitalize,
      dataEncript
      
    } from '../../../helper/commonHelpers'; 

function AdminLayout() {
   const [promoterList, setpromoterList] = useState([]);


    useEffect(() => {
            axios.get(API_BASE_URL+'api-member/get-from-15gh', { headers: API_HEADERS })
            .then(response => {
                  if (response.data["status"] > 0) {
                     setpromoterList(response.data['data']);
                  }
                  console.log(response.data['data']);
            })
            .catch(error => {
              toast.error("Failed to fetch role list list!");
            });
  
    }, []);






    useEffect(() => {
      const timeout = setTimeout(() => {
        if (window.tableDomJquery) {
          window.tableDomJquery();
        }
      }, 1000); // Timeout of 3000 milliseconds (3 seconds)
  
      return () => clearTimeout(timeout); // Clear the timeout on component unmount
    }, [promoterList]);




    



  return (
    <DashboardLayout>
<div class="pcoded-content">
      <div class="pcoded-inner-content">

            <div class="main-body">
                  <div class="page-wrapper">

                        <div class="page-header">
                    <div class="row align-items-end">
                        <div class="col-lg-8">
                            <div class="page-header-title">
                                <div class="d-inline">
                                    <h4>Form 15G/ 15H  
                                        
                                 <small>
                                          &nbsp;
                                          <a class="btn btn-info btn-sm" href="/member-upload-15-gh">
                                         <b>+</b> </a>
                                      </small> 
                                        
                                           </h4>
                                    {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="page-header-breadcrumb">
                                <ul class="breadcrumb-title">
                                    <li class="breadcrumb-item" style={{float:'left'}}>
                                        <a href="/dashboard"> <i class="feather icon-home"></i> </a>
                                    </li>
                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="#!">Form 15G/ 15H</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


            <div class="page-body">

                  <div class="card">
                         <div class="card-header">
                      




                        </div> 
                        <div class="card-block">
                              <div class="table-responsive dt-responsive">
                                    <table class="table dtable table-striped table-bordered nowrap" style={{width:'100%'}}>
                                          <thead>
                                                <tr>
                                                      <th>SR.NO</th>
                                                      <th>MEMBER</th>
                                                      <th>FY</th>
                                                      <th>ACTIONS</th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                             
                                          


                                          {
                                                promoterList.length > 0 ? (
                                                  promoterList.map((branchData, index) => (
                                                      <tr class="gradeX">
                                                      <td>{index + 1}</td>
                                                      <td><a href={`/view-member/${dataEncript(branchData.member_id)}`} >
                                                        {branchData.member_name?FirstLetterCapitalize(branchData.member_name):''}</a></td>
                                                      <td>{branchData.financial_year?branchData.financial_year:''}</td>
                                                      
                                                      <td class="center">
                                                            <a href={`/member-view-15-gh/${dataEncript(branchData.id)}`}   class='font-weight-normal label label-info'> <i class="fa fa-eye"></i> </a>
                                                            
                                                      </td>
                                                </tr>
                                                  ))
                                                ) : (
                                                  <tr>
                                                    <td colSpan="10">No data available</td>
                                                  </tr>
                                                )
                                              }

                                          
                                               
                                          </tbody>
                                        
                                    </table>
                              </div>
                        </div>
                  </div>






            </div>

      </div>
</div>
<ToastContainer className="toast-container" />
<div id="styleSelector">
</div>
</div>
</div>
    </DashboardLayout>
  );
}

export default AdminLayout;
