import React, { useState, useEffect } from "react";
import DashboardLayout from "../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../config"; // Importing constants directly

// import { useNavigate } from 'react-router-dom'; 



  import { toast, ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';


function CompanyDetails() {
    // const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);
    const [stateList, setStateList] = useState([]);

    const [formErrors, setFormErrors] = useState({
        short_name:'', about:'', address:'', address2:'', city:'', pincode:'', country:'', class_name:'', cin:'', email:'', incorporation_date:'', incorporation_state:'', incorporation_country:'', authorized_capital:'', phone: '', paid_up_capital: ''
      });


    const [software_url, setsoftware_url] = useState('');
    const [website, setwebsite] = useState('');
    const [company_name, setcompany_name] = useState('');

    const [short_name, setshort_name] = useState('');
    const [about, setabout] = useState('');
    const [cin, setcin] = useState('');
    const [pan, setpan] = useState('');
    const [tan, settan] = useState('');
    const [gst_no, setgst_no] = useState('');
    const [category, setcategory] = useState('');
    const [class_name, setclass_name] = useState('');
    const [authorized_capital, setauthorized_capital] = useState('');
    const [paid_up_capital, setpaid_up_capital] = useState('');
    const [share_nominal_value, setshare_nominal_value] = useState('');
    // const [state_of_registration, setstate_of_registration] = useState('');

    const [incorporation_date, setincorporation_date] = useState('');
    const [incorporation_state, setincorporation_state] = useState('');
    const [incorporation_country, setincorporation_country] = useState('');

    const [address, setaddress] = useState('');
    const [address2, setaddress2] = useState('');
    const [city, setcity] = useState('');
    const [pincode, setpincode] = useState('');
    const [state, setstate] = useState('');
    const [country, setcountry] = useState('');
    const [email, setemail] = useState('');
    const [phone, setphone] = useState('');
    const [landline_no, setlandline_no] = useState('');

    
    useEffect(() => {
        
        axios
          .get(API_BASE_URL + "api-company/get-profile-Details", {
            headers: API_HEADERS,
          })
          .then((response) => {
                setsoftware_url(response.data['data'].software_url);
                    setwebsite(response.data['data'].website);
                    setcompany_name(response.data['data'].company_name);

                    setshort_name(response.data['data'].short_name);
                    setabout(response.data['data'].about);
                    setcin(response.data['data'].cin);
                    setpan(response.data['data'].pan);
                    settan(response.data['data'].tan);
                    setgst_no(response.data['data'].gst_no);
                    setcategory(response.data['data'].category);
                    setclass_name(response.data['data'].class_name);
                    setauthorized_capital(response.data['data'].authorized_capital);
                    setpaid_up_capital(response.data['data'].paid_up_capital);
                    setshare_nominal_value(response.data['data'].share_nominal_value);
                    // setstate_of_registration(response.data['data'].state_of_registration);



                    setincorporation_date(response.data['data'].incorporation_date);
                    setincorporation_state(response.data['data'].incorporation_state);
                    setincorporation_country(response.data['data'].incorporation_country);

                    setaddress(response.data['data'].address);
                    setaddress2(response.data['data'].address2);
                    setcity(response.data['data'].city);
                    setpincode(response.data['data'].pincode);
                    setstate(response.data['data'].state);
                    setcountry(response.data['data'].country);
                    setemail(response.data['data'].email);
                    setphone(response.data['data'].phone);
                    setlandline_no(response.data['data'].landline_no);

          })
          .catch((error) => {
            toast.error('Failed to fetch EMPLYEE DETAILS list!');
          });


        // get stateList
          axios.get(API_BASE_URL + "api-common/get-state-list", {
            headers: API_HEADERS,
          }).then((response) => {
            setStateList(response.data['data']);
          }).catch((error) => {
            toast.error('Failed to fetch date!');
          });
          
    
      }, []);


   

  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};


    const formData = {
        'software_url': software_url,
        'website': website,
        'company_name': company_name,

        'short_name': short_name,
        'about': about,
        'cin': cin,
        'pan': pan,
        'tan': tan,
        'gst_no': gst_no,
        'category': category,
        'class_name': class_name,
        'authorized_capital': authorized_capital,
        'paid_up_capital': paid_up_capital,
        'share_nominal_value': share_nominal_value,
        // 'state_of_registration': state_of_registration,


        'incorporation_date': incorporation_date,
        'incorporation_state': incorporation_state,
        'incorporation_country': incorporation_country,

        'address': address,
        'address2': address2,
        'city': city,
        'state': state,
        'email': email,
        'country': country,
        'pincode': pincode,

        'phone': phone,
        'landline_no': landline_no,

        

  };


// validate form
if (!formData.short_name) {
    errors.short_name = 'Short Name is required.';
  }
  if (!formData.about) {
    errors.about = 'About is required.';
  }
  if (!formData.address) {
    errors.address = 'Addreaa Line 1 is required.';
  }
  if (!formData.address2) {
    errors.address2 = 'Addreaa Line 2 is required.';
  }
  if (!formData.city) {
    errors.city = 'City is required.';
  }
  if (!formData.pincode) {
    errors.pincode = 'PIN Code is required.';
  }
  if (!formData.country) {
    errors.country = 'Country is required.';
  }
  if (!formData.class_name) {
    errors.class_name = 'Class Name is required.';
  }
  if (!formData.cin) {
    errors.cin = 'CIN is required.';
  }
  if (!formData.email) {
    errors.email = 'Email is required.';
  }
  if (!formData.incorporation_date) {
    errors.incorporation_date = 'Incorporation Date is required.';
  }
  if (!formData.incorporation_state) {
    errors.incorporation_state = 'Incorporation State is required.';
  }
  if (!formData.incorporation_country) {
    errors.incorporation_country = 'Incorporation Country is required.';
  }
  if (!formData.authorized_capital) {
    errors.authorized_capital = 'Authorized Capital is required.';
  }
  if (!formData.phone) {
    errors.phone = 'Mobile No is required.';
  }

  if (!formData.paid_up_capital) {
    errors.paid_up_capital = 'PAID UP CAPITAL is required';
  }
  // Set formErrors state with validation errors
  setFormErrors(errors);
  

  if (Object.keys(errors).length === 0) {
      axios.post(API_BASE_URL + 'api-company/update-profile', formData, {
        headers: {
          ...API_HEADERS,
        },
      })
      .then(response => {
        if (response.data['status'] > 0) {
             toast.success(response.data['message']);
             setTimeout(() => {
                window.location.href ='/company-details';
              }, 4000);
        } else {
            toast.success(response.data['message']);
        }
      })
      .catch(error => {
        toast.success('Failed to fetch details!');
      }).finally(() => {
        setTimeout(() => {
          setSubmitting(false);
        }, 300);
      });  // close query hare
    }else{
        setSubmitting(false); 
    } // hare close error 

  };


  return (
    <DashboardLayout>




     <div class="pcoded-content">
    <div class="pcoded-inner-content">
        <div class="main-body">
            <div class="page-wrapper">
                <div class="page-header">
                    <div class="row align-items-end">
                        <div class="col-lg-8">
                            <div class="page-header-title">
                                <div class="d-inline">
                                    <h4>Edit Company Profile</h4>
                                    {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}

                                   



                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="page-header-breadcrumb">
                                <ul class="breadcrumb-title">
                                    <li class="breadcrumb-item" style={{float:'left'}}>
                                        <a href="/dashboard"> <i class="feather icon-home"></i> </a>
                                    </li>
                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="/company-details">Compnay Profile</a></li>

                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="#!">Edit</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="page-body">
                    <div class="row">

                    <div class="col-sm-12">
                    

                                    </div>


                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-header">
                                    <h5>Company Profile Edit</h5>
                                    {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                                </div>

                                <div class="card-block">
                                <form onSubmit={updateDetails}> 
                                    <div class="row">
                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Company Website</h4>
                                            <input class="form-control" type="text" value={website} onChange={(e) => setwebsite(e.target.value)} placeholder='Enter company website URL' />
                                        </div>
                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Short Name<span class="text-danger">*</span></h4>
                                            <input class="form-control" type="text" value={short_name} onChange={(e) => setshort_name(e.target.value)} placeholder='Enter Short Name' />
                                            {formErrors.short_name && <span style={{ color: 'red' }}>{formErrors.short_name}</span>}
                                        </div>
                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">About Company<span class="text-danger">*</span></h4>
                                            <textarea class="form-control" onChange={(e) => setabout(e.target.value)} value={about} >{about}</textarea>
                                            {formErrors.about && <span style={{ color: 'red' }}>{formErrors.about}</span>}
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-12 col-xl-12 m-b-30">
                                            <h5 class="title">Reg. Office Address</h5>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-12 col-xl-6 m-b-30">
                                            <h4 class="sub-title">Address Line 1<span class="text-danger">*</span></h4>
                                            <input class="form-control" type="text" value={address} onChange={(e) => setaddress(e.target.value)}  placeholder='Enter Address Line 1' />
                                            {formErrors.address && <span style={{ color: 'red' }}>{formErrors.address}</span>}
                                        </div>
                                        <div class="col-sm-12 col-xl-6 m-b-30">
                                            <h4 class="sub-title">Address Line 2<span class="text-danger">*</span></h4>
                                            <input class="form-control" type="text" value={address2} onChange={(e) => setaddress2(e.target.value)}  placeholder='Enter Address Line 2' />
                                            {formErrors.address2 && <span style={{ color: 'red' }}>{formErrors.address2}</span>}
                                        </div>
                                    </div>


                                    <div class="row">
                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">City<span class="text-danger">*</span></h4>
                                            <input class="form-control" type="text" value={city} onChange={(e) => setcity(e.target.value)} placeholder='Enter City Name' />
                                            {formErrors.city && <span style={{ color: 'red' }}>{formErrors.city}</span>}
                                        </div>
                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">State<span class="text-danger">*</span></h4>
                                            <select class="form-control m-b" name="state" value={state} onChange={(e) => setstate(e.target.value)}>
                                                    <option disabled  >Select Status</option>
                                                    {stateList.map((stateData) => (
                                                      <option value={stateData.full_name} selected={stateData.state ===incorporation_state ? 'selected' : ''} >{stateData.full_name}</option>
                                                    ))}

                                                </select>
                                                {formErrors.state && <span style={{ color: 'red' }}>{formErrors.state}</span>}
                                        </div>
                                        
                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Pincode<span class="text-danger">*</span></h4>
                                            <input class="form-control" type="text" value={pincode} onChange={(e) => setpincode(e.target.value)} placeholder='Enter Pincode' />
                                            {formErrors.pincode && <span style={{ color: 'red' }}>{formErrors.pincode}</span>}
                                        </div>
                                        </div>

                                        <div class="row">
                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Country<span class="text-danger">*</span></h4>
                                            <input class="form-control" type="text" value={country} onChange={(e) => setcountry(e.target.value)}  placeholder='Enter Country' />
                                            {formErrors.country && <span style={{ color: 'red' }}>{formErrors.country}</span>}
                                        </div>

                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Mobile No.<span class="text-danger">*</span></h4>
                                            <input class="form-control" value={phone} onChange={(e) => setphone(e.target.value)} type="text" placeholder='Enter Mobile No.' />
                                            {formErrors.phone && <span style={{ color: 'red' }}>{formErrors.phone}</span>}
                                        </div>

                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Landline No</h4>
                                            <input class="form-control" type="text" value={landline_no} onChange={(e) => setlandline_no(e.target.value)}  placeholder='Enter Landline No.' />
                                        </div>
                                        </div>

                                        <div class="row">
                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Contact Email<span class="text-danger">*</span></h4>
                                            <input class="form-control" type="text" value={email} onChange={(e) => setemail(e.target.value)} placeholder='Enter Contact Email' />
                                            {formErrors.email && <span style={{ color: 'red' }}>{formErrors.email}</span>}
                                        </div>

                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">CIN No<span class="text-danger">*</span></h4>
                                            <input class="form-control" type="text" value={cin} onChange={(e) => setcin(e.target.value)}  placeholder='Enter CIN No.' />
                                            {formErrors.cin && <span style={{ color: 'red' }}>{formErrors.cin}</span>}
                                        </div>

                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">PAN No</h4>
                                            <input class="form-control" type="text" value={pan} onChange={(e) => setpan(e.target.value)}  placeholder='Enter PAN No.' />
                                        </div>
                                        </div>

                                        <div class="row">


                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">TAN No</h4>
                                            <input class="form-control" type="text" value={tan} onChange={(e) => settan(e.target.value)}  placeholder='Enter TAN No.' />
                                        </div>

                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">GST No.</h4>
                                            <input class="form-control" type="text" value={gst_no} onChange={(e) => setgst_no(e.target.value)}  placeholder='Enter GST No..' />
                                        </div>

                                        
                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Company Category<span class="text-danger">*</span></h4>
                                            <select class="col-sm-12 form-control" name="category" value={category} onChange={(e) => setcategory(e.target.value)}>
                                                <option value={''} disabled>select Category </option>  
                                                <option value="Limited By Share" selected={'Limited By Share' ===category ? 'selected' : ''}  >Limited By Share</option>
                                            </select>
                                        </div>

                                        </div>

                                        <div class="row">

                                          
                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Company Class<span class="text-danger">*</span></h4>
                                            <select class="col-sm-12 form-control" name="class_name" value={class_name} onChange={(e) => setclass_name(e.target.value)}>
                                                <option value={''} disabled>select Class </option>  
                                                <option value="Privet Limited">Privet Limited</option>
                                                <option value="Public Limited">Public Limited</option>
                                            </select>
                                            {formErrors.class_name && <span style={{ color: 'red' }}>{formErrors.class_name}</span>}
                                        </div>

                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Incorporation Date<span class="text-danger">*</span></h4>
                                            <input class="form-control" type="date" value={incorporation_date} onChange={(e) => setincorporation_date(e.target.value)} placeholder='Enter Incorporation Date' />
                                            {formErrors.incorporation_date && <span style={{ color: 'red' }}>{formErrors.incorporation_date}</span>}
                                            
                                        </div>

                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Incorporation State<span class="text-danger">*</span></h4>
                                            <select class="col-sm-12 form-control" name="incorporation_state" value={incorporation_state} onChange={(e) => setincorporation_state(e.target.value)}>
                                                <option value={''} disabled>select state </option>  
                                                {stateList.map((stateData) => (
                                                      <option value={stateData.full_name} selected={stateData.full_name ===incorporation_state ? 'selected' : ''} >{stateData.full_name}</option>
                                                    ))}
                                            </select>
                                            {formErrors.incorporation_state && <span style={{ color: 'red' }}>{formErrors.incorporation_state}</span>}
                                        </div>

                                        </div>

                                        <div class="row">
                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Incorporation Country<span class="text-danger">*</span></h4>
                                            <input class="form-control" type="text" value={incorporation_country} onChange={(e) => setincorporation_country(e.target.value)} placeholder='Enter Incorporation Country' />
                                            {formErrors.incorporation_country && <span style={{ color: 'red' }}>{formErrors.incorporation_country}</span>}
                                        </div>

                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Authorize Capital<span class="text-danger">*</span></h4>
                                            <input class="form-control" type="text" value={authorized_capital} onChange={(e) => setauthorized_capital(e.target.value)}  placeholder='Enter Authorize Capital Country' />
                                            {formErrors.authorized_capital && <span style={{ color: 'red' }}>{formErrors.authorized_capital}</span>}
                                        </div>

                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Paid Up Capital<span class="text-danger">*</span></h4>
                                            <input class="form-control" type="text" value={paid_up_capital} onChange={(e) => setpaid_up_capital(e.target.value)}  placeholder='Enter Paid Up Capital' />
                                            {formErrors.paid_up_capital && <span style={{ color: 'red' }}>{formErrors.paid_up_capital}</span>}
                                        </div>

                                    </div>

                                    <div class="row">
                                        <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                                            <button class="btn btn-success btn-square" type="submit" disabled={submitting}>Update</button> &nbsp;
                                            <button class="btn btn-danger btn-square" type="reset">Cancel</button>

                                            <ToastContainer className="toast-container" />
                                        </div>
                                        </div>



                                    </form>
                                </div>


                            </div>




                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="styleSelector"></div>
    </div>
</div>

    </DashboardLayout>
  );
}

export default CompanyDetails;
