import React, { useState, useEffect } from "react";
import DashboardLayout from "../../dashboardLayout";
// import axios from "axios";
// import { API_BASE_URL, API_HEADERS } from "../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { deleteConfirmation, dataDecrypt, dataEncript } from "../../../helper/commonHelpers";

import { formPost, getAssociateList } from '../../../helper/listModal';

//formatDate

function CompanyDetails() {
  const [submitting, setSubmitting] = useState(false);
  const { advisorID }   = useParams();
  const [details, setdetails] = useState([]);


  const [formErrors, setFormErrors] = useState({
    member_id: "",
  });


 




const[new_password, setnew_password] = useState('');
const[confirm_opassword, setconfirm_opassword] = useState('');






  useEffect(() => {
  

    getAssociateList(dataDecrypt(advisorID)).then(data => {
        // console.log(data);
        setdetails(data);

      }).catch(error => {
          // console.error('Error fetching associate list:', error);
      });

  }, [advisorID]);




  const updateDetails = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));

    const formData = {
            id: dataDecrypt(advisorID),
            confirm_password:confirm_opassword,
            new_password: new_password,

    };

  console.log(formData);
     


      if (!new_password) {
        errors.new_password = 'New Password is required.';
      }
      
      if (!confirm_opassword) {
        errors.confirm_opassword = 'Confirm Password is required.';
      }


      if (confirm_opassword!==new_password) {
        errors.confirm_opassword = 'Confirm Password is not match.';
      }



    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {

        const confirmed = await deleteConfirmation('to add NEW rank?');
        if(confirmed===true)
        {

          formPost('api-associates/reset-password', formData).then(data => {

            toast.success(data["message"]);
            setTimeout(() => {
              window.location.href = "/associate-advisor-view/"+advisorID;
            }, 2000);

              setSubmitting(false);
          }).catch(error => {
              setSubmitting(false);
              toast.error(error);
          });



    }
    setSubmitting(false);
    } else {
      setSubmitting(false);
    } 
  };


  

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>  Change Password  -  {details && details.first_name && (
                                    <>{details.first_name}</>
                                    )}  
                                    &nbsp;
                                    {details && details.last_name && (
                                    <>{details.last_name}</>
                                    )}   </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                            <a href="/associate-advisor-agent-ranks">Associate/ Advisor Ranks </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href={`/associate-advisor-view/${dataEncript(details.id)}`}  > {details && details.first_name && (
                                    <>{details.first_name}</>
                                    )}  
                                    &nbsp;
                                    {details && details.last_name && (
                                    <>{details.last_name}</>
                                    )}  </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> Change Password</a>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-7">
                    <div class="card" style={{borderTop:'3px solid red'}} >
                    
                        {/* <h4 class="m-4">CHANGE ACCOUNT INFO</h4> */}
                       <hr class="p-0 m-0"/>

                     

                      <div class="card-block">
                      <form onSubmit={updateDetails}>




                      <div class="row" >
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  New Password<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                <input class="form-control" type="password" value={new_password} onChange={(e) => setnew_password(e.target.value) } placeholder="Enter New Password" />

                                  {formErrors.new_password && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.new_password}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" >
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Confirm Password<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                <input class="form-control" type="text" value={confirm_opassword} onChange={(e) => setconfirm_opassword(e.target.value) } placeholder="Enter Confirm Password" />

                                  {formErrors.confirm_opassword && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.confirm_opassword}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>





                        
                      

                         


                        <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                            <button
                              class="btn btn-success btn-square"
                              type="submit"
                              disabled={submitting}
                            >
                              CHANGE PASSWORD
                            </button>{" "}
                       

                            <a href={`/associate-advisor-view/${dataEncript(details.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                          

                            <ToastContainer className="toast-container" />
                          </div>
                        </div>


                      </form>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-5">
                  
                  </div>
                </div>


                

              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
          <ToastContainer className="toast-container" />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
