import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../dashboardLayout';
import { useParams } from 'react-router-dom'; 

import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "../../../../config"; // Importing constants directly
// import $ from 'jquery';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



import {
      
      FirstLetterCapitalize,
      dataDecrypt,
      formatDate,
      // formattedAmount,
      dataEncript,
      deleteConfirmation
      
      
    } from '../../../helper/commonHelpers'; 

function AdminLayout() {
  const { passbookID }   = useParams();
  const [listDetails, setlistDetails] = useState([]);




    useEffect(() => {


            axios.get(API_BASE_URL + "api-passbooks/get-passbook-list?id="+dataDecrypt(passbookID), {
                headers: API_HEADERS,
              }).then((response) => {
          
          
                if (response.data["status"] > 0) {
                  setlistDetails(response.data["data"]);
          
                } else {
                    toast.error(response.data["message"]);
                }
          
              }).catch((error) => {
                toast.error("Failed to fetch data!");
              });


  
    }, [passbookID]);



    


    // useEffect(() => {
    //   const timeout = setTimeout(() => {
    //     if (window.tableDomJquery) {
    //       window.tableDomJquery();
    //     }
    //   }, 1000); // Timeout of 3000 milliseconds (3 seconds)
  
    //   return () => clearTimeout(timeout); // Clear the timeout on component unmount
    // }, [promoterList]);




    
  const deleteTransaction = async (tblid) => {

    const confirmed = await deleteConfirmation('Are you sure to delete?');
    if(confirmed===true)
    {
        const formData = {
            tablename:'tbl_passbook',  // table name
            coloum_name:'id', // which collumn name you want to  
            value:tblid, // column value 
         };


         axios.post(API_BASE_URL + "api-common/detete", formData, {
            headers: {
                ...API_HEADERS,
            },
            })
            .then((response) => {
            if (response.data["status"] > 0) {
                toast.success(response.data["message"]);
                setTimeout(() => {
                     window.location.href ='/passbook-list';
                }, 2700);
            } else {
                toast.error(response.data["message"]);
            }
            })
            .catch((error) => {
               toast.error("Failed to submition!");
            }); // close query hare


    }
};



  return (
    <DashboardLayout>
<div class="pcoded-content">
      <div class="pcoded-inner-content">

            <div class="main-body">
                  <div class="page-wrapper">

                  <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>
                          {listDetails && listDetails.passbook_no && (
                              <>{FirstLetterCapitalize(listDetails.passbook_no)}</>
                              )}

                            <small> Passbook</small>
                        </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/passbook-list">Passbook</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">  {listDetails && listDetails.passbook_no && (
                              <>{FirstLetterCapitalize(listDetails.passbook_no)}</>
                              )}</a>
                        </li>

                       
                      </ul>
                    </div>
                  </div>
                </div>
              </div>


            <div class="page-body">


            <div class="row">
                <div class="col-md-7">
                <div class="card" style={{borderTop:'3px solid green'}}>
                         <div class="card-header">
                         <small style={{float: 'right'}}>

                            <a class="btn btn-info btn-sm" title="" href={`/passbook-edit/${dataEncript(listDetails.id)}`}>
                            <i class="feather icon-edit"></i></a>&nbsp;

                            <a href="#!"   onClick={() => deleteTransaction(listDetails.id)}   class='btn btn-danger btn-sm'> <i class="fa fa-trash"></i> </a>&nbsp;

                            </small>

                        </div> 
                        <div class="card-block">
                              <div class="table-responsive dt-responsive">
                                    <table class="table dtable table-striped table-bordered nowrap" style={{width:'100%'}}>
                                        
                                          <tbody>
                                             
                                          <tr>
                                            <th>Account</th>
                                            <td> {listDetails && listDetails.account_no && (
                                                <a href={`/account-view/${dataEncript(listDetails.account_no)}`} class=' '>{listDetails.account_created_no?listDetails.account_created_no:''}</a>
                                                )}
                                                </td>
                                            </tr>
                                            <tr>
                                            <th>Passbook No.</th>
                                            <td> {listDetails && listDetails.passbook_no && (
                                                <>{listDetails.passbook_no}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Issue Date</th>
                                            <td> {listDetails && listDetails.issue_date && (
                                                <>{formatDate(listDetails.issue_date)}</>
                                                )}</td>
                                            </tr>
                                          </tbody>
                                        
                                    </table>
                              </div>
                        </div>
                  </div>
                </div>
                <div class="col-md-5">
                
                </div>

            </div>

              






            </div>

      </div>
</div>
<ToastContainer className="toast-container" />
<div id="styleSelector">
</div>
</div>
</div>
    </DashboardLayout>
  );
}

export default AdminLayout;
