import React, { useState, useEffect } from "react";
import DashboardLayout from "../../dashboardLayout";
// import axios from "axios";
// import { API_BASE_URL, API_HEADERS } from "../../../../config"; // Importing constants directly
// import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { deleteConfirmation, FirstLetterCapitalize } from "../../../helper/commonHelpers";

import { formPost, getDataListBytable } from '../../../helper/listModal';

//formatDate

function CompanyDetails() {
  const [submitting, setSubmitting] = useState(false);
  // const { accountID }   = useParams();
  const [rankList, setrankList] = useState([]);


  const [rank_id, setrank_id] = useState([]);
  const [rank_month, setrank_month] = useState([]);
  const [rank_total, setrank_total] = useState([]);



  const [formErrors, setFormErrors] = useState({
    member_id: "",
  });


 




  const[chart_type, setchart_type] = useState('');
  const[chart_name, setchart_name] = useState('');
  const[payout_type, setpayout_type] = useState('');
  const[commission_type, setcommission_type] = useState('INR');
  const[tenure, settenure] = useState(6);





  useEffect(() => {
  

    getDataListBytable('tbl_associate_rank', ``).then(data => {
      // console.log(data);
      setrankList(data);

        const updatedRank = data.map((branchData, index) => branchData.id);
        setrank_id(updatedRank);

    }).catch(error => {
        // console.error('Error fetching associate list:', error);
    });
     

    // const updatedTotal = [...Array(tenure)].map((_, index1) => 0 );
    // setrank_total(updatedTotal);
    

  }, [tenure]);




  const handleRankMonth = (index, value1, m_index) => {
    const updatedRankMonth = [...rank_month];
    updatedRankMonth[index] = value1;
    setrank_month(updatedRankMonth);



        //HARE CALCULATE TOTAL VALUE-------------------------------
        const new_total_val = rankList.reduce((total, ldata) => {
          const value = (ldata.id+''+m_index)===index?value1: rank_month[ldata.id+''+m_index];
          const numericValue =value? parseFloat(value):0;
          return total + numericValue;
        }, 0);


    const updated_m_total = [...rank_month];
    updated_m_total[m_index] = new_total_val;
    setrank_total(updated_m_total);

    // console.log(rank_total);



   };






  const updateDetails = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));

    const formData = {

      chart_type: chart_type,
      chart_name: chart_name,
      payout_type: payout_type,
      commission_type: commission_type,
      tenure: tenure,

    };

  console.log(formData);
     

      if (!chart_type) {
        errors.chart_type = 'Chart Type is required.';
      }
      
      if (!chart_name) {
        errors.chart_name = 'Chart Name Position is required.';
      }

      if (!payout_type) {
        errors.payout_type = 'Payout Type is required.';
      }

      if (!commission_type) {
        errors.commission_type = 'Commission Type is required.';
      }

      if (!tenure) {
        errors.tenure = 'Tenure (Months)  is required.';
      }


     



      const multiArray = rankList.map(ldata => {
        const row = {
          id: ldata.id,
          name: ldata.rank_name
        };
        
        // Add m values to the row
        for (let index = 0; index < tenure; index++) {
          row['m' + index] = rank_month[ldata.id+''+index];
        }
        return row;
      });

      formData['chart_model'] = JSON.stringify(multiArray);

    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {

        const confirmed = await deleteConfirmation('to add NEW Commission Chart?');
        if(confirmed===true)
        {

          formPost('api-associates/add-commission-chart', formData).then(data => {
            toast.success(data["message"]);
            setTimeout(() => {
              window.location.href = "/associate-advisor-commission-charts";
            }, 2000);

              setSubmitting(false);
          }).catch(error => {
              toast.error(error);
              setSubmitting(false);
            
          });



    }
    setSubmitting(false);
    } else {
      setSubmitting(false);
    } 
  };


  




  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>  New Commission Chart </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                            <a href="/associate-advisor-commission-charts">Commission Charts </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> New</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="card" style={{borderTop:'3px solid red'}} >
                    
                        {/* <h4 class="m-4">CHANGE ACCOUNT INFO</h4> */}
                       <hr class="p-0 m-0"/>

                     

                      <div class="card-block">
                      <form onSubmit={updateDetails}>



                      <div class="row"  >
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Chart Type <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                  <select
                                    class="form-control m-b"
                                    name="chart_type"
                                    value={chart_type}
                                    onChange={(e) =>
                                      {setchart_type(e.target.value);
                                        
                                        if(e.target.value==='Saving Account (Opening Incentive)'){
                                          settenure(1);
                                        }

                                      }
                                    }
                                  >
                                    <option value="" class="">Please Select</option>
                                    <option value="Recurring Deposit (RD) (Installment Based Incentive)" >Recurring Deposit (RD) (Installment Based Incentive)</option>
                                    <option value="Daily Deposit (DD) (Installment Based Incentive)" >Daily Deposit (DD) (Installment Based Incentive)</option>
                                    <option value="Fixed Deposit (FD) (One Time Incentive)" >Fixed Deposit (FD) (One Time Incentive)</option>
                                    <option value="Fixed Deposit (FD) (Payout Based Incentive)" >Fixed Deposit (FD) (Payout Based Incentive)</option>
                                    <option value="Monthly Income Scheme (MIS) (One Time Incentive)" >Monthly Income Scheme (MIS) (One Time Incentive)</option>
                                    <option value="Monthly Income Scheme (MIS) (Payout Based Incentive)" >Monthly Income Scheme (MIS) (Payout Based Incentive)</option>
                                    <option value="Saving Account (Opening Incentive)" >Saving Account (Opening Incentive)</option>


                                  </select>

                                  {formErrors.chart_type && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.chart_type}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                             <div class="col-sm-12 col-xl-6 m-b-30" style={{ display: chart_type === 'Fixed Deposit (FD) (One Time Incentive)' ? '' : 'none' }}>
                                <div class="row">
                                    <div class="col-sm-12">
                                      <h6 class="">One Time Incentive:</h6>
                                      <p>This is per account open or maturity based incentive payout according to commission chart.</p>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-sm-12 col-xl-6 m-b-30" style={{ display: chart_type === 'Fixed Deposit (FD) (Payout Based Incentive)' ? '' : 'none' }}>
                                <div class="row">
                                    <div class="col-sm-12">
                                      <h6 class="">Payout Based Incentive:</h6>
                                      <p>This is per interest payout based incentive payout according to commission chart.</p>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-sm-12 col-xl-6 m-b-30" style={{ display: chart_type === 'Monthly Income Scheme (MIS) (One Time Incentive)' ? '' : 'none' }}>
                                <div class="row">
                                    <div class="col-sm-12">
                                      <h6 class="">One Time Incentive:</h6>
                                      <p>This is per account open or maturity based incentive payout according to commission chart.</p>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-sm-12 col-xl-6 m-b-30" style={{ display: chart_type === 'Monthly Income Scheme (MIS) (Payout Based Incentive)' ? '' : 'none' }}>
                                <div class="row">
                                    <div class="col-sm-12">
                                      <h6 class="">Payout Based Incentive:</h6>
                                      <p>This is per interest payout based incentive payout according to commission chart.</p>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-sm-12 col-xl-6 m-b-30" style={{ display: chart_type === 'Saving Account (Opening Incentive)' ? '' : 'none' }}>
                                <div class="row">
                                    <div class="col-sm-12">
                                      <h6 class="">Account Opening Incentive:</h6>
                                      <p>This is one time incentive payout according to commission chart when Saving Account Open.</p>
                                    </div>
                                  </div>
                                </div>


                          </div>

                  <span style={{ display: (!chart_type) ? 'none' : '' }}>
                      <div class="row" >
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Chart Name<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                <input class="form-control" type="text" value={chart_name} onChange={(e) => setchart_name(e.target.value) } placeholder="Enter Chart Name" />

                                  {formErrors.chart_name && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.chart_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>




                      
                     


                      <div class="row"  >
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Payout Type <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                  <select
                                    class="form-control m-b"
                                    name="payout_type"
                                    value={payout_type}
                                    onChange={(e) =>
                                      setpayout_type(e.target.value)
                                    }
                                  >
                                    <option value="" class="" >Please Select</option>
                                    <option value="MLM" >MLM</option>
                                    <option value="FLAT" >FLAT</option>
                                    <option value="FLAT_NO_TEAM_COMM">FLAT_NO_TEAM_COMM</option>

                                   
                                  </select>
                                  {formErrors.payout_type && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.payout_type}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row"  >
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Commission Type <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                  <select
                                    class="form-control m-b"
                                    name="commission_type"
                                    value={commission_type}
                                    onChange={(e) =>
                                      setcommission_type(e.target.value)
                                    }
                                  >
                                    <option value="" class="">Please Select</option>
                                    <option value="(%)" >(%)</option>
                                    <option value="INR">INR</option>
                                   
                                  </select>
                                  {formErrors.commission_type && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.commission_type}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="row" style={{ display: chart_type === 'Saving Account (Opening Incentive)' ? 'none' : '' }} >
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Tenure (Months) <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                <input class="form-control" type="number" value={tenure} onChange={(e) => {
                                const value = e.target.value.trim();
                                const newValue = value === '' ? '' : parseInt(value);
                                settenure(newValue);
                              }}
                            placeholder="Enter Tenure (Months)" />
                                  
                                  {formErrors.tenure && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.tenure}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                                  
                          </span>



                          


                          <div class="row" style={{ display: (!chart_type) ? 'none' : '' }}>
                            <div class="col-sm-12 col-xl-12 m-b-30 ">
                              <div class="table-responsive dt-responsive text-center">
                                  <table class="table dtable table-striped table-bordered nowrap">
                                  <thead>

                                    <tr>
                                        <th>#</th>
                                        <th>RANK/&nbsp;MONTHS</th>

                                        { 
                                        chart_type && (
                                          chart_type !== 'Saving Account (Opening Incentive)' ? (
                                            
                                            <>
                                              {[...Array(tenure)].map((_, index) => (
                                                <th key={index}>{index + 1} M</th>
                                              ))}
                                            </>

                                            ):  <th>Account Opening Incentive on 1st Transaction</th>
                                          )
                                        }
                                  </tr>


                                  </thead>

                                    <tbody>

                                  

                                        {
                                          rankList.length > 0 ? (
                                            rankList.map((branchData, index) => (

                                              <>
                                            <tr class="gradeX">
                                                <td>{index + 1}</td>
                                                <td> {branchData.rank_name?FirstLetterCapitalize(branchData.rank_name):''} 
                                                   <input class="form-control" type="hidden" value={rank_id[index]}   />
                                                </td>

                                                {[...Array(tenure)].map((_, index1) => (
                                                       <td>
                                                      <input class="form-control" type="text" value={rank_month[(branchData.id+''+index1)]} onInput={(e) => {handleRankMonth((branchData.id+''+index1), e.target.value, index1);}} placeholder={` ${(commission_type==='INR')?'INR':'Percent'}` } />
                                                    </td>
                                              ))}
                                              
                                           </tr>

                                           </>

                                            ))
                                          ) : (
                                            <>
                                            </>
                                          )
                                        }


                                         <tr class="gradeX">
                                                <td>#</td>
                                                <td>Total</td>

                                                {[...Array(tenure)].map((_, index1) => (
                                                   <td>
                                                      <input class="form-control" type="text" value={rank_total[(index1)]} readOnly />
                                                    </td>
                                                 ))}
                                              
                                           </tr>


                                        
                                    


                                    </tbody>
                                </table>
                                </div>
                          </div>
                        </div>
                        
                      

                         


                        <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                            <button
                              class="btn btn-success btn-square"
                              type="submit"
                              disabled={submitting}
                            >
                              SAVE
                            </button>{" "}
                       

                            <a href="/associate-advisor-agent-ranks"  class="btn btn-danger btn-square">Cancel</a>
                          

                            <ToastContainer className="toast-container" />
                          </div>
                        </div>


                      </form>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-5">
                  
                  </div>
                </div>


                

              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
          <ToastContainer className="toast-container" />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
