import React, { useState, useEffect } from 'react';
import DashboardLayout from './../../dashboardLayout';

import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
// import $ from 'jquery';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



import {
      formatDate,
      dataEncript,
      FirstLetterCapitalize
      
    } from './../../../helper/commonHelpers'; 

function AdminLayout() {
   const [promoterList, setpromoterList] = useState([]);

const[member_no, setmember_no] = useState('');
const[first_name, setfirst_name] = useState('');
const[last_name, setlast_name] = useState('');
const[share_no, setshare_no] = useState('');
const[certificate_no, setcertificate_no] = useState('');
const [submitting, setSubmitting] = useState(false);



    useEffect(() => {
            axios.get(API_BASE_URL+'api-member/get-shares-list', { headers: API_HEADERS })
            .then(response => {
                  if (response.data["status"] > 0) {
                     setpromoterList(response.data['data']);
                  }
                  console.log(response.data['data']);
            })
            .catch(error => {
              console.error('Failed to fetch role list list!', error);
            });
  
    }, []);






    useEffect(() => {
      const timeout = setTimeout(() => {
        if (window.tableDomJquery) {
          window.tableDomJquery();
        }
      }, 1000); // Timeout of 3000 milliseconds (3 seconds)
  
      return () => clearTimeout(timeout); // Clear the timeout on component unmount
    }, [promoterList]);




    

  const filterData = (e) => {
      e.preventDefault();
      setSubmitting(true);

 const formData = {};

if (member_no) {
      formData['member_no'] = member_no;
    }

    if (first_name) {
      formData['first_name'] = first_name;
    }

    if (last_name) {
      formData['last_name'] = last_name;
    }

    if (share_no) {
      formData['share_no'] = share_no;
    }

    if (certificate_no) {
      formData['certificate_no'] = certificate_no;
    }

    console.log(formData);

    if (Object.keys(formData).length === 0) {
      // Set formErrors state with validation errors
      

          axios.get(API_BASE_URL + 'api-member/get-shares-list', {
            headers: {
              ...API_HEADERS
            },
          }).then((response) => {
            if (response.data["status"] > 0) {
                  setpromoterList(response.data['data']);
            } else {
              toast.error(response.data["message"]);
            }

          }).catch((error) => {
            toast.error("Failed to submition!");
          })
          .finally(() => {
            setTimeout(() => {
              setSubmitting(false);
            }, 300);
          }); // close query hare

      }
   
    };


  return (
    <DashboardLayout>
<div class="pcoded-content">
      <div class="pcoded-inner-content">

            <div class="main-body">
                  <div class="page-wrapper">

                        <div class="page-header">
                    <div class="row align-items-end">
                        <div class="col-lg-8">
                            <div class="page-header-title">
                                <div class="d-inline">
                                    <h4>Share Holdings  
                                        
                                    <small>
                                          &nbsp;
                                          <a class="btn btn-info btn-sm" title="UN-ALLOTTED SHARE LIST" href="/member-add-holdings">
                                         <b>+</b> </a> &nbsp; 

                                          <a class="btn btn-warning btn-sm" title="UN-ALLOTTED SHARE LIST" href="/share-holdings/unallotted">
                                          UN ALLOTTED SHARE LIST</a> &nbsp; 
                                          <a class="btn btn-primary btn-sm" title="UN-ALLOTTED SHARE LIST" href="/share-holdings/new-allotment">
                                          MANUAL NEW SHARE ALLOCATION</a>
                                      </small>
                                        
                                           </h4>
                                    {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="page-header-breadcrumb">
                                <ul class="breadcrumb-title">
                                    <li class="breadcrumb-item" style={{float:'left'}}>
                                        <a href="/dashboard"> <i class="feather icon-home"></i> </a>
                                    </li>
                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="#!">Share Holdings</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


            <div class="page-body">

                  <div class="card">
                         <div class="card-header">
                        <form onSubmit={filterData}>
                              <div class="row">
                          <div class="col-sm-12 col-xl-4 m-b-30">
                              <div class="row">
                                
                                <div class="col-sm-12">
                                <h6 class="">Member No :</h6>
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={member_no}
                                    onChange={(e) =>
                                      setmember_no(e.target.value)
                                    }
                                    placeholder="Enter Address Line 1 "
                                  />
                                 
                                </div>
                              </div>
                            </div>
                            
                            <div class="col-sm-12 col-xl-4 m-b-30">
                              <div class="row">
                              
                                <div class="col-sm-12">
                                <h6 class="">Member First Name :</h6>
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={first_name}
                                    onChange={(e) =>
                                      setfirst_name(e.target.value)
                                    }
                                    placeholder="Enter Member First Name :"
                                  />
                               
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-4 m-b-30">
                              <div class="row">
                              <h6 class="">
                                    Member Last Name :
                                  </h6>
                                <div class="col-sm-12">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={last_name}
                                    onChange={(e) =>
                                      setlast_name(e.target.value)
                                    }
                                    placeholder="Enter Member Last Name :"
                                  />
                               
                                </div>
                              </div>
                            </div>

                            </div>

                            <div class="row">
                          <div class="col-sm-12 col-xl-4 m-b-30">
                              <div class="row">
                              
                                <div class="col-sm-12">
                                <h6 class="">
                                  Share No :
                                  </h6>
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={share_no}
                                    onChange={(e) =>
                                      setshare_no(e.target.value)
                                    }
                                    placeholder="Enter Share No : "
                                  />
                               
                                </div>
                              </div>
                            </div>
                            
                            <div class="col-sm-12 col-xl-4 m-b-30">
                              <div class="row">
                            
                                <div class="col-sm-12">
                                <h6 class="">
                                    Certificate No :
                                  </h6>
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={certificate_no}
                                    onChange={(e) =>
                                      setcertificate_no(e.target.value)
                                    }
                                    placeholder="Enter Certificate No :"
                                  />
                                 
                                </div>
                              </div>
                            </div>

                            </div>

                            <div class="row">
                              <div class="col-sm-12 col-xl-12 m-b-30 text-center">

                              <button class="btn btn-success btn-square" type="submit" disabled={submitting} >
                              <i class="fa fa-search"></i>   Search 
                              </button>{" "}
                              &nbsp;
                            
                              <button class="btn btn-danger btn-square" type="reset" > Clear Form </button>
                              <ToastContainer className="toast-container" />

                              </div>
                            </div>

                            </form>




                        </div> 
                        <div class="card-block">
                              <div class="table-responsive dt-responsive">
                                    <table class="table dtable table-striped table-bordered nowrap" style={{width:'100%'}}>
                                          <thead>
                                                <tr>
                                                      <th>SR.NO</th>
                                                      <th>BRANCH</th>
                                                      <th>MEMBER</th>
                                                      <th>SHARE RANGE</th>
                                                      <th>TOTAL SHARES HELD</th>
                                                      <th>NOMINAL VAL.</th>
                                                      <th>TOTAL SHARE VAL..</th>
                                                      <th>ALLOTMENT DATE</th>
                                                      <th>TRANSFER DATE</th>
                                                      <th>CERT. NO</th>
                                                      <th>SURRENDERED</th>
                                                      <th>Actions</th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                             
                                          


                                          {
                                                promoterList.length > 0 ? (
                                                  promoterList.map((branchData, index) => (
                                                      <tr class="gradeX">
                                                      <td>{index + 1}</td>
                                                      <td>{branchData.branch_name?FirstLetterCapitalize(branchData.branch_name):''}</td>
                                                      <td>{branchData.member_name?FirstLetterCapitalize(branchData.member_name):''}</td>
                                                      <td>{branchData.shares?branchData.shares:''}</td>
                                                      <td>{branchData.total_share_held?branchData.total_share_held:''}</td>

                                                      <td>{branchData.share_nominal_value?branchData.share_nominal_value:''}</td>
                                                      <td>{branchData.total_shares_value?branchData.total_shares_value:''}</td>

                                                      
                                                      <td>{branchData.allotment_date?formatDate(branchData.allotment_date):''}</td>
                                                      <td>{branchData.transfer_date?formatDate(branchData.transfer_date):''}</td>
                                                      
                                                      <td>{branchData.certificate_no?branchData.certificate_no:''}</td>
                                                      

                                                      <td>{branchData.total_shares_value?branchData.total_shares_value:''}</td>
                                                   
                                                      <td class="center">
                                                            <a href={`/member-view-holdings/${dataEncript(branchData.id)}`} class='font-weight-normal label label-success'><i class="fa fa-eye"></i></a>&nbsp;
                                                            <a href="#!"  class='font-weight-normal label label-info'> <i class="fa fa-print"></i> SH-1 </a>
                                                            
                                                      </td>
                                                </tr>
                                                  ))
                                                ) : (
                                                  <tr>
                                                    <td colSpan="12">No data available</td>
                                                  </tr>
                                                )
                                              }

                                          
                                               
                                          </tbody>
                                        
                                    </table>
                              </div>
                        </div>
                  </div>






            </div>

      </div>
</div>

<div id="styleSelector">
</div>
</div>
</div>
    </DashboardLayout>
  );
}

export default AdminLayout;
