import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly

// import { useNavigate } from 'react-router-dom';

import $ from 'jquery';
import 'dropify/dist/css/dropify.min.css';
import 'dropify';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./TabCss.css";

// import { getCurrentDate } from "./../../../helper/commonHelpers";

import { getmenuSubMenuList } from '../../../helper/listModal';


function CompanyDetails() {
  // const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [roleMenue, setroleMenue] = useState(false);



  const [formErrors, setFormErrors] = useState({
    role_id: "",
    role_name: "",
    role_position: "",
    permission_type: "",
    active: "",
  });

  const[role_name, setrole_name] = useState('');
  const[role_position, setrole_position] = useState('');
  const[permission_type, setpermission_type] = useState('');
  const[active, setactive] = useState('');


  useEffect(() => {


    getmenuSubMenuList().then(data => {
      // setmemberlistArr(data);
      setroleMenue(data);
        // console.log(data);

    }).catch(error => {
        // console.error('Error fetching associate list:', error);
    });

  
    $('.dropify').dropify();
    // GET ACTIVE BRANCH LIST
    // axios.get(API_BASE_URL + "api-common/get-bank-list", {
    //     headers: API_HEADERS,
    //   }).then((response) => {
    //     if (response.data["status"] > 0) {
    //       setbankList(response.data["data"]);
    //       }
    //   }).catch((error) => {
    //     toast.error("Failed to fetch data!");
    //   });

     




  }, []);


 
  console.log(roleMenue);


  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};

 


    const formData = {
      role_name:role_name,
      role_position:role_position,
      permission_type:permission_type,
      active:active
    };



    // validate form
     if (!role_name) {
        errors.role_name = 'Role name is required.';
      }

      if (!role_position) {
        errors.role_position = 'Role Position is required.';
      }

      if (!permission_type) {
        errors.permission_type = 'Permission Type is required.';
      }

      if (!active) {
        errors.active = 'Active status is required.';
      }

    
   

    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
        axios.post(API_BASE_URL + 'api-company/add-bank-account', formData, {
          headers: {
            ...API_HEADERS
          },
        }).then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/bank-accounts";
            }, 3700);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 300);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>New Bank Account</h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/bank-accounts">Bank Accounts</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">New </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12"></div>

                  <div class="col-sm-12">
                    <div class="card">
                      <div class="card-header">
                        {/* <h5>Add New Promoter</h5> */}
                        {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                      </div>

                      <div class="card-block">
                        <form onSubmit={updateDetails}>
                          
                          <div class="row">


                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Role Name<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="date"
                                    value={role_name}
                                    onChange={(e) =>
                                      setrole_name(e.target.value)
                                    }
                                    placeholder="Enter Role Name"
                                  />
                                  {formErrors.role_name && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.role_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Role Position<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={role_position}
                                    onChange={(e) =>
                                      setrole_position(e.target.value)
                                    }
                                    placeholder="Enter Role Position"
                                  />
                                  {formErrors.role_position && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.role_position}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                          </div>

                        


                          <div class="row">

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Permission Type<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                    <div class="form-radio row ml-3">
                                      <div class="radio radio-inline col-xs-3 ">
                                        <label for="title_admin">
                                          <input
                                            type="radio"
                                            name="permission_type"
                                            value={"Admin"}
                                            id={"title_admin"}
                                            checked={permission_type === 'Admin' ? 'checked' : ''}
                                            onChange={(e) =>
                                              setpermission_type(e.target.value)
                                            }
                                          />
                                          <i class="helper"></i>Admin Type
                                        </label>
                                      </div>
                                      <div class="radio radio-inline col-xs-3 ">
                                        <label for="title_agent">
                                          <input
                                            type="radio"
                                            name="permission_type"
                                            id={"title_agent"}
                                            value={"Agent"}
                                            checked={permission_type === 'Agent' ? 'checked' : ''}
                                            onChange={(e) =>
                                              setpermission_type(e.target.value)
                                            }
                                          />
                                          <i class="helper"></i>Agent Type
                                        </label>
                                      </div>

                                      <div class="radio radio-inline col-xs-3 ">
                                        <label for="title_both">
                                          <input
                                            type="radio"
                                            name="permission_type"
                                            id={"title_both"}
                                            value={"Both"}
                                            checked={permission_type === 'Both' ? 'checked' : ''}
                                            onChange={(e) =>
                                              setpermission_type(e.target.value)
                                            }
                                          />
                                          <i class="helper"></i>Both Type
                                        </label>
                                      </div>
                                      
                                    </div>
                                    {formErrors.permission_type && (
                                      <span style={{ color: "red" }}>
                                        {formErrors.permission_type}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Active<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                    <div class="form-radio row ml-3">
                                      <div class="radio radio-inline col-xs-3 ">
                                        <label for="title_yes">
                                          <input
                                            type="radio"
                                            name="active"
                                            value={"yes"}
                                            id={"title_yes"}
                                            checked={active === 'yes' ? 'checked' : ''}
                                            onChange={(e) =>
                                              setactive(e.target.value)
                                            }
                                          />
                                          <i class="helper"></i>Yes
                                        </label>
                                      </div>
                                      <div class="radio radio-inline col-xs-3 ">
                                        <label for="title_no">
                                          <input
                                            type="radio"
                                            name="active"
                                            id={"title_no"}
                                            value={"no"}
                                            checked={active === 'no' ? 'checked' : ''}
                                            onChange={(e) =>
                                              setactive(e.target.value)
                                            }
                                          />
                                          <i class="helper"></i>No
                                        </label>
                                      </div>
                                      
                                    </div>
                                    {formErrors.active && (
                                      <span style={{ color: "red" }}>
                                        {formErrors.active}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>

                          </div>

                         
                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <h5 class="sub-title text-center">
                                      Link With Software
                              </h5>
                            </div>
                          </div>




                            <div class="row">
                              <div class="col-md-12">
                              <ul class="nav nav-tabs md-tabs" role="tablist">
                                          

                              {
                                roleMenue.length > 0 ? (
                                  roleMenue.map((menuData, indexOut) => (
                                    <li className="nav-item" key={indexOut}>
                                      <a 
                                        className={`nav-link ${indexOut === 0 ? 'active' : ''}`}  
                                        data-toggle="tab" 
                                        href={`#menutab${indexOut}`}  
                                        role="tab"
                                      >
                                        {menuData.menu_name} 
                                        <label className="label label-default labelclassn_no_use">0</label>
                                      </a>
                                    </li>
                                  ))
                                ) : null
                              }

                                   

                              {/* <li class="nav-item">
                                    <a class="nav-link active" data-toggle="tab" href="#DASHBOARD" role="tab">DASHBOARD <label class="label label-default labelclassn_no_use">0</label></a>
                              </li>


                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#COMPANY" role="tab">COMPANY <label class="label label-primary labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#USER_MANAGEMENT" role="tab">USER MANAGEMENT <label class="label label-success labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#COLLECTION_CENTERS" role="tab">COLLECTION CENTERS <label class="label label-info labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#MEMBER_MANAGEMENT" role="tab">MEMBER MANAGEMENT <label class="label label-warning labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#SAVING_ACCOUNTS" role="tab">SAVING ACCOUNTS <label class="label label-danger labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#FIXED_DEPOSITS" role="tab">FIXED DEPOSITS <label class="label label-default labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#RECURRING_DEPOSITS" role="tab">RECURRING DEPOSITS <label class="label label-primary labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#GOLD_LOAN" role="tab">GOLD LOAN <label class="label label-success labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#PROPERTY_LOAN" role="tab">PROPERTY LOAN <label class="label label-info labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#DEPOSIT_LOAN" role="tab">DEPOSIT LOAN <label class="label label-warning labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#OTHER_LOAN" role="tab">OTHER LOAN <label class="label label-danger labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#FIXED_LOAN" role="tab">FIXED LOAN <label class="label label-default labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#APPROVALS" role="tab">APPROVALS <label class="label label-primary labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#PAYMENT_COLLECTIONS" role="tab">PAYMENT COLLECTIONS <label class="label label-success labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#PAYMENT_PAYOUTS" role="tab">PAYMENT PAYOUTS <label class="label label-info labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#MACHINE_COLLECTION" role="tab">MACHINE COLLECTION <label class="label label-warning labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#PASSBOOKS" role="tab">PASSBOOKS <label class="label label-danger labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#PRINT_DOCUMENTS" role="tab">PRINT DOCUMENTS <label class="label label-default labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#ADVISORS" role="tab">ADVISORS <label class="label label-primary labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#EXTRA_SERVICES" role="tab">EXTRA SERVICES <label class="label label-success labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#TRANSFER_SETTING" role="tab">TRANSFER SETTING <label class="label label-info labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#CASHFREE" role="tab">CASHFREE <label class="label label-warning labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#WITHIN_BANK_TRANSFER" role="tab">WITHIN BANK TRANSFER <label class="label label-danger labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#REPORTS" role="tab">REPORTS <label class="label label-default labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#HR_MANAGEMENT" role="tab">HR MANAGEMENT <label class="label label-primary labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#SOFTWARE_SETTINGS" role="tab">SOFTWARE SETTINGS <label class="label label-success labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#WEBSITE" role="tab">WEBSITE <label class="label label-info labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#ACCOUNTING" role="tab">ACCOUNTING <label class="label label-warning labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#SMS_SCHEDULER" role="tab">SMS SCHEDULER <label class="label label-danger labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#BUSINESS_REPORTS" role="tab">BUSINESS REPORTS <label class="label label-default labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#DAILY_COLLECTION" role="tab">DAILY COLLECTION <label class="label label-primary labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#AGENT_APP" role="tab">AGENT APP <label class="label label-success labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#VERIFICATION_SUITE" role="tab">VERIFICATION SUITE <label class="label label-info labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#CIBIL_REPORT" role="tab">CIBIL REPORT <label class="label label-warning labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#VIEW_LEVEL_FIELDS_PERMISSIONS" role="tab">VIEW LEVEL FIELDS PERMISSIONS <label class="label label-danger labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#YESBANK" role="tab">YESBANK <label class="label label-default labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#NOTICE_BOARD" role="tab">NOTICE BOARD <label class="label label-primary labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#DOWNLOAD_REPORTS" role="tab">DOWNLOAD REPORTS <label class="label label-success labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#APPOINTMENTS" role="tab">APPOINTMENTS <label class="label label-info labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#INQUIRY" role="tab">INQUIRY <label class="label label-warning labelclassn_no_use">0</label></a>
                              </li>
                              <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#CC_LIMIT" role="tab">CC LIMIT <label class="label label-danger labelclassn_no_use">0</label></a>
                              </li> */}
                            </ul>

                            <div class="tab-content card-block p-0 pt-2">


                            {/* {roleMenue.length > 0 && (
                              roleMenue.map((menuData, indexOut) => 
                                  if (menuData.sub_menus && (menuData.sub_menus).length > 0) {
                                    setsub_menus(menuData.sub_menus);
                                }
                              (
                                <div className="tab-pane active" id={`menutab${indexOut}`} role="tabpanel">
                                    <div class="row">
                                  {(menuData.sub_menus).length > 0 && (
                                    (menuData.sub_menus).map((submenuData, subIndex) => (
                                      <>{submenuData}kjgjhvjvh</>
                                    ))
                                  )}
                                   </div>
                                </div>
                              ))
                            )} */}


                  {roleMenue.length > 0 && (
                    roleMenue.map((menuData, indexOut) => {
                      //  let sabmenuArray ={};

                      // if (menuData.sub_menus && menuData.sub_menus.length > 0) {
                      //   sabmenuArray = menuData.sub_menus;
                      // }

                      return (
                        <div className="tab-pane active" id={`menutab${indexOut}`} role="tabpanel" key={indexOut}>
                          <div className="row"> ddd
                            {menuData.sub_menus && menuData.sub_menus.length > 0 && (
                              menuData.sub_menus.map((submenuData, subIndex) => (
                               
                               <div key={subIndex}>
                                    {submenuData.menuname}dddd
                                </div>

                              ))
                            )}
                          </div>
                        </div>
                      );
                    })
                  )}





                                <div class="tab-pane active" id="DASHBOARD" role="tabpanel">
                                      <div class="row">
                                      <div class="col-xl-8 col-md-12">
                                        <div class="card table-card" style={{borderTop:'3px solid red'}}>
                                              <div class="card-header bg-default p-3">
                                                    <h5>Application Sales</h5>
                                                    <div class="card-header-right">
                                                          <ul class="list-unstyled card-option">
                                                                <li><i class="feather icon-maximize full-card"></i></li>
                                                                <li><i class="feather icon-minus minimize-card"></i>
                                                                </li>
                                                                <li><i class="feather icon-trash-2 close-card"></i></li>
                                                          </ul>
                                                    </div>
                                              </div>
                                              <div class="card-block">
                                                    <div class="table-responsive">
                                                          <table class="table table-hover  table-borderless">
                                                                <thead>
                                                                      <tr>
                                                                            <th>
                                                                                  <div class="chk-option">
                                                                                        <div class="checkbox-fade fade-in-primary">
                                                                                              <label class="check-task">
                                                                                                    <input type="checkbox" value="" />
                                                                                                    <span class="cr">
                                                                                                          <i class="cr-icon feather icon-check txt-default"></i>
                                                                                                    </span>
                                                                                              </label>
                                                                                        </div>
                                                                                  </div>
                                                                                  Application
                                                                            </th>
                                                                            <th>Sales</th>
                                                                            <th>Change</th>
                                                                            <th>Avg Price</th>
                                                                            <th>Total</th>
                                                                      </tr>
                                                                </thead>
                                                                <tbody>
                                                                      <tr>
                                                                            <td>
                                                                                  <div class="chk-option">
                                                                                        <div class="checkbox-fade fade-in-primary">
                                                                                              <label class="check-task">
                                                                                                    <input type="checkbox" value="" />
                                                                                                    <span class="cr">
                                                                                                          <i class="cr-icon feather icon-check txt-default"></i>
                                                                                                    </span>
                                                                                              </label>
                                                                                        </div>
                                                                                  </div>
                                                                                  <div class="d-inline-block align-middle">
                                                                                        <h6>Able Pro</h6>
                                                                                        <p class="text-muted m-b-0">Powerful
                                                                                        Admin Theme</p>
                                                                                  </div>
                                                                            </td>
                                                                            <td>16,300</td>
                                                                            <td></td>
                                                                            <td>$53</td>
                                                                            <td class="text-c-blue">$15,652</td>
                                                                      </tr>
                                                                      
                                                                </tbody>
                                                          </table>
                                                          
                                                    </div>
                                              </div>
                                        </div>
                                  </div>
                                      </div>
                                </div> 



                                <div class="tab-pane " id="COMPANY" role="tabpanel">
                                      <p class="m-0">1.COMPANY </p>
                                </div>
                                <div class="tab-pane " id="USER_MANAGEMENT" role="tabpanel">
                                      <p class="m-0">1.USER_MANAGEMENT </p>
                                </div>
                                <div class="tab-pane " id="COLLECTION_CENTERS" role="tabpanel">
                                      <p class="m-0">1.COLLECTION_CENTERS </p>
                                </div>
                                <div class="tab-pane " id="MEMBER_MANAGEMENT" role="tabpanel">
                                      <p class="m-0">1.MEMBER_MANAGEMENT </p>
                                </div>
                                <div class="tab-pane " id="SAVING_ACCOUNTS" role="tabpanel">
                                      <p class="m-0">1.SAVING_ACCOUNTS </p>
                                </div>
                                <div class="tab-pane " id="FIXED_DEPOSITS" role="tabpanel">
                                      <p class="m-0">1.FIXED_DEPOSITS </p>
                                </div>
                                <div class="tab-pane " id="RECURRING_DEPOSITS" role="tabpanel">
                                      <p class="m-0">1.RECURRING_DEPOSITS </p>
                                </div>
                                <div class="tab-pane " id="GOLD_LOAN" role="tabpanel">
                                      <p class="m-0">1.GOLD_LOAN </p>
                                </div>
                                <div class="tab-pane " id="PROPERTY_LOAN" role="tabpanel">
                                      <p class="m-0">1.PROPERTY_LOAN </p>
                                </div>
                                <div class="tab-pane " id="DEPOSIT_LOAN" role="tabpanel">
                                      <p class="m-0">1.DEPOSIT_LOAN </p>
                                </div>
                                <div class="tab-pane " id="OTHER_LOAN" role="tabpanel">
                                      <p class="m-0">1.OTHER_LOAN </p>
                                </div>
                                <div class="tab-pane " id="FIXED_LOAN" role="tabpanel">
                                      <p class="m-0">1.FIXED_LOAN </p>
                                </div>
                                <div class="tab-pane " id="APPROVALS" role="tabpanel">
                                      <p class="m-0">1.APPROVALS </p>
                                </div>
                                <div class="tab-pane " id="PAYMENT_COLLECTIONS" role="tabpanel">
                                      <p class="m-0">1.PAYMENT_COLLECTIONS </p>
                                </div>
                                <div class="tab-pane " id="PAYMENT_PAYOUTS" role="tabpanel">
                                      <p class="m-0">1.PAYMENT_PAYOUTS </p>
                                </div>
                                <div class="tab-pane " id="MACHINE_COLLECTION" role="tabpanel">
                                      <p class="m-0">1.MACHINE_COLLECTION </p>
                                </div>
                                <div class="tab-pane " id="PASSBOOKS" role="tabpanel">
                                      <p class="m-0">1.PASSBOOKS </p>
                                </div>
                                <div class="tab-pane " id="PRINT_DOCUMENTS" role="tabpanel">
                                      <p class="m-0">1.PRINT_DOCUMENTS </p>
                                </div>
                                <div class="tab-pane " id="ADVISORS" role="tabpanel">
                                      <p class="m-0">1.ADVISORS </p>
                                </div>
                                <div class="tab-pane " id="EXTRA_SERVICES" role="tabpanel">
                                      <p class="m-0">1.EXTRA_SERVICES </p>
                                </div>
                                <div class="tab-pane " id="TRANSFER_SETTING" role="tabpanel">
                                      <p class="m-0">1.TRANSFER_SETTING </p>
                                </div>
                                <div class="tab-pane " id="CASHFREE" role="tabpanel">
                                      <p class="m-0">1.CASHFREE </p>
                                </div>
                                <div class="tab-pane " id="WITHIN_BANK_TRANSFER" role="tabpanel">
                                      <p class="m-0">1.WITHIN_BANK_TRANSFER </p>
                                </div>
                                <div class="tab-pane " id="REPORTS" role="tabpanel">
                                      <p class="m-0">1.REPORTS </p>
                                </div>
                                <div class="tab-pane " id="HR_MANAGEMENT" role="tabpanel">
                                      <p class="m-0">1.HR_MANAGEMENT </p>
                                </div>
                                <div class="tab-pane " id="SOFTWARE_SETTINGS" role="tabpanel">
                                      <p class="m-0">1.SOFTWARE_SETTINGS </p>
                                </div>
                                <div class="tab-pane " id="WEBSITE" role="tabpanel">
                                      <p class="m-0">1.WEBSITE </p>
                                </div>
                                <div class="tab-pane " id="ACCOUNTING" role="tabpanel">
                                      <p class="m-0">1.ACCOUNTING </p>
                                </div>
                                <div class="tab-pane " id="SMS_SCHEDULER" role="tabpanel">
                                      <p class="m-0">1.SMS_SCHEDULER </p>
                                </div>
                                <div class="tab-pane " id="BUSINESS_REPORTS" role="tabpanel">
                                      <p class="m-0">1.BUSINESS_REPORTS </p>
                                </div>
                                <div class="tab-pane " id="DAILY_COLLECTION" role="tabpanel">
                                      <p class="m-0">1.DAILY_COLLECTION </p>
                                </div>
                                <div class="tab-pane " id="AGENT_APP" role="tabpanel">
                                      <p class="m-0">1.AGENT_APP </p>
                                </div>
                                <div class="tab-pane " id="VERIFICATION_SUITE" role="tabpanel">
                                      <p class="m-0">1.VERIFICATION_SUITE </p>
                                </div>
                                <div class="tab-pane " id="CIBIL_REPORT" role="tabpanel">
                                      <p class="m-0">1.CIBIL_REPORT </p>
                                </div>
                                <div class="tab-pane " id="VIEW_LEVEL_FIELDS_PERMISSIONS" role="tabpanel">
                                      <p class="m-0">1.VIEW_LEVEL_FIELDS_PERMISSIONS </p>
                                </div>
                                <div class="tab-pane " id="YESBANK" role="tabpanel">
                                      <p class="m-0">1.YESBANK </p>
                                </div>
                                <div class="tab-pane " id="NOTICE_BOARD" role="tabpanel">
                                      <p class="m-0">1.NOTICE_BOARD </p>
                                </div>
                                <div class="tab-pane " id="DOWNLOAD_REPORTS" role="tabpanel">
                                      <p class="m-0">1.DOWNLOAD_REPORTS </p>
                                </div>
                                <div class="tab-pane " id="APPOINTMENTS" role="tabpanel">
                                      <p class="m-0">1.APPOINTMENTS </p>
                                </div>
                                <div class="tab-pane " id="INQUIRY" role="tabpanel">
                                      <p class="m-0">1.INQUIRY </p>
                                </div>
                                <div class="tab-pane " id="CC_LIMIT" role="tabpanel">
                                      <p class="m-0">1.CC_LIMIT </p>
                                </div>
                            </div>
                              </div>
                            </div>







                        


                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                              <button
                                class="btn btn-success btn-square"
                                type="submit"
                                disabled={submitting}
                              >
                                SAVE
                              </button>{" "}
                              &nbsp;
                              <a href="/bank-accounts" class="btn btn-danger btn-square">Cancel</a>
                              &nbsp;
                              <button
                                class="btn btn-warning btn-square"
                                type="reset"
                              >
                                Reset
                              </button>

                              <ToastContainer className="toast-container" />
                            </div>
                          </div>


                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
