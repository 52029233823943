import React, { useState, useEffect, useRef } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly

// import { useNavigate } from 'react-router-dom';

import $ from 'jquery';
import 'dropify/dist/css/dropify.min.css';
import 'dropify';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import 'select2/dist/css/select2.min.css';
import 'select2';


// import { getCurrentDate } from "./../../../helper/commonHelpers";

function CompanyDetails() {
  // const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  // const [stateList, setStateList] = useState([]);
  const [bankList, setbankList] = useState([]);
  const [activeBranchList, setactiveBranchList] = useState([]);
  const [roleList, setroleList] = useState([]);


  const [formErrors, setFormErrors] = useState({
    login_user_name: "",
    fname: "",
    mobile: "",
    back_date_entry_days: "",
    permissions: "",
    branch: "",
    login_on_holidays: "",
    searchable_account: "",
    user_active: "",
  });

  const[emp_profile, setemp_profile] = useState('');
  const[designation, setdesignation] = useState('');
  const[login_user_name, setlogin_user_name] = useState('');
  const[fname, setfname] = useState('');
  const[lname, setlname] = useState('');
  const[email, setemail] = useState('');
  const[mobile, setmobile] = useState('');
  const[back_date_entry_days, setback_date_entry_days] = useState('');
  const[permissions, setpermissions] = useState([]);
  const[branch, setbranch] = useState([]);
  const[login_on_holidays, setlogin_on_holidays] = useState('yes');
  const[searchable_account, setsearchable_account] = useState('no');
  const[user_active, setuser_active] = useState('yes');

  useEffect(() => {
  
    $('.dropify').dropify();
    // GET ACTIVE BRANCH LIST
    axios.get(API_BASE_URL + "api-common/get-bank-list", {
        headers: API_HEADERS,
      }).then((response) => {
        if (response.data["status"] > 0) {
          setbankList(response.data["data"]);
          }
      }).catch((error) => {
        toast.error("Failed to fetch data!");
      });

      axios.get(API_BASE_URL + "api-company/get-active-branch-list", {
        headers: API_HEADERS,
      }).then((response) => {
        if (response.data["status"] > 0) {
        setactiveBranchList(response.data["data"]);
        }
      }).catch((error) => {
        toast.error("Failed to fetch data!");
      });

      axios.get(API_BASE_URL + "api-user/get-role-permission", {
        headers: API_HEADERS,
      }).then((response) => {
        if (response.data["status"] > 0) {
          setroleList(response.data["data"]);
        }
      }).catch((error) => {
        toast.error("Failed to fetch data!");
      });


      // setaccount_open_date(getCurrentDate());
  }, []);



 
  // const handleBranchChange = (e) => {
  //   setbranch(Array.from(e.target.selectedOptions, (option) => option.value));
  // };


 




  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);


    let errors = {};

    const formData = {
      emp_profile: emp_profile,
      designation: designation,
      login_user_name: login_user_name,
      fname: fname,
      lname: lname,
      email: email,
      mobile: mobile,
      back_date_entry_days: back_date_entry_days,
      permissions: permissions,
      branch: branch,
      login_on_holidays: login_on_holidays,
      searchable_account: searchable_account,
      user_active: user_active,


          
    };



    // validate form
     if (!login_user_name) {
        errors.login_user_name = 'Login User Name is required.';
      }

      if (!fname) {
        errors.fname = 'First Name is required.';
      }

      if (!mobile) {
        errors.mobile = 'Mobile No. is required.';
      }

      if (!back_date_entry_days) {
        errors.back_date_entry_days = 'Back Date Entry Days is required.';
      }

      if (!permissions) {
        errors.permissions = 'Permissions / Roles is required.';
      }

      if (!branch) {
        errors.branch = 'Branch name is required.';
      }

      if (!login_on_holidays) {
        errors.login_on_holidays = 'Login on Holidays is required.';
      }

      if (!searchable_account) {
        errors.searchable_account = 'Searchable Accounts is required.';
      }

      if (!user_active) {
        errors.user_active = 'User Active is required.';
      }


    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
        axios.post(API_BASE_URL + 'api-user/add-user', formData, {
          headers: {
            ...API_HEADERS
          },
        }).then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/users-management";
            }, 3700);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 300);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };


  const selectRefEmp = useRef();
  useEffect(() => {
    // Initialize Select2
    const $selectEmp = $(selectRefEmp.current);
    $selectEmp.select2();
    // Handle change event
    $selectEmp.on('change', function () {
      const selectedOptions = $selectEmp.val() || [];
      setemp_profile(selectedOptions);
    });
    // Cleanup Select2 on component unmount
    return () => {
      $selectEmp.select2('destroy');
    };
  }, []);



  const selectRefRole = useRef();
  useEffect(() => {
    // Initialize Select2
    const $selectRole = $(selectRefRole.current);
    $selectRole.select2();
    // Handle change event
    $selectRole.on('change', function () {
      const selectedOptions = $selectRole.val() || [];
      setpermissions(selectedOptions);
    });
    // Cleanup Select2 on component unmount
    return () => {
      $selectRole.select2('destroy');
    };
  }, []);

  const selectRefBranch = useRef();
  useEffect(() => {
    // Initialize Select2
    const $selectBranch = $(selectRefBranch.current);
    $selectBranch.select2();
    // Handle change event
    $selectBranch.on('change', function () {
      const selectedOptions1 = $selectBranch.val() || [];
      setbranch(selectedOptions1);
    });
    // Cleanup Select2 on component unmount
    return () => {
      $selectBranch.select2('destroy');
    };
  }, []);
  

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>New User</h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/users-management">Users</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">New </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12"></div>

                  <div class="col-sm-12">
                    <div class="card">
                      <div class="card-header">
                        {/* <h5>Add New Promoter</h5> */}
                        {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                      </div>

                      <div class="card-block">
                        <form onSubmit={updateDetails}>

                        
                        

                        <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Employee Profile<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <select
                                    class="form-control m-b"
                                    name="emp_profile"
                                    value={emp_profile}
                                    ref={selectRefEmp}
                                  >
                                    <option value={''} disabled>Select Profile</option>
                                    {bankList.map((bankdata) => (
                                      <option
                                        value={bankdata.id}
                                        selected={
                                          bankdata.id === emp_profile
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {bankdata.name}

                                      </option>
                                    ))}
                                  </select>
                                  {formErrors.emp_profile && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.emp_profile}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            </div>
                          
                          <div class="row">
                          
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Designation<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={designation}
                                    onChange={(e) =>
                                      setdesignation(e.target.value)
                                    }
                                    placeholder="Enter Designation"
                                  />
                                  {formErrors.designation && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.designation}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Login User Name <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={login_user_name}
                                    onChange={(e) =>
                                      setlogin_user_name(e.target.value)
                                    }
                                    placeholder="Enter Login User Name "
                                  />
                                  {formErrors.login_user_name && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.login_user_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    First Name <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={fname}
                                    onChange={(e) =>
                                      setfname(e.target.value)
                                    }
                                    placeholder="Enter First Name "
                                  />
                                  {formErrors.fname && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.fname}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Last Name
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={lname}
                                    onChange={(e) =>
                                      setlname(e.target.value)
                                    }
                                    placeholder="Enter Last Name"
                                  />
                                  {formErrors.lname && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.lname}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Email
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={email}
                                    onChange={(e) =>
                                      setemail(e.target.value)
                                    }
                                    placeholder="Enter Email "
                                  />
                                  {formErrors.email && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.email}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Mobile No.<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="number"
                                    value={mobile}
                                    onChange={(e) =>
                                      setmobile(e.target.value)
                                    }
                                    placeholder="Enter Mobile No."
                                  />
                                  {formErrors.mobile && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.mobile}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Back Date Entry Days<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                 <div class="input-group">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={back_date_entry_days}
                                    onChange={(e) =>
                                      setback_date_entry_days(e.target.value)
                                    }
                                    placeholder="Enter Back Date Entry Days "
                                  />

                                <div class="input-group-prepend">
                                 <span class="input-group-text" title="Enter the days user can put entry in back date. Like if 0, user can only enter current date entry or if 2, then user can put entiers dated 2 days back from current date." id="basic-addon5"><i class="icofont icofont-info"></i></span>
                                 </div>
                                </div>

                                  {formErrors.back_date_entry_days && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.back_date_entry_days}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                          </div>

                          <div class="row">
                           <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Permissions / Roles<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <select
                                    class="form-control m-b "
                                    data-placeholder="Select Permissions / Roles"
                                    multiple="multiple"
                                    name="permissions"
                                    value={permissions}
                                    ref={selectRefRole}
                                  >
                                    <option value={''} disabled>Select Profile</option>
                                    {roleList.map((roleData) => (
                                      <option
                                        value={roleData.id}
                                        selected={
                                          roleData.id === permissions
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {roleData.role_name}

                                      </option>
                                    ))}
                                  </select>
                                  {formErrors.permissions && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.permissions}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div> 

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Branch<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <select
                                     class="form-control m-b "
                                     data-placeholder="Select Branch"
                                     multiple="multiple"
                                    name="branch"
                                    value={branch}
                                    ref={selectRefBranch}
                                  >
                                    <option value={''} disabled>Select Profile</option>
                                    {activeBranchList.map((branchdata) => (
                                      <option
                                        value={branchdata.id}
                                        selected={
                                          branchdata.id === branch
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {branchdata.branch_name}

                                      </option>
                                    ))}
                                  </select>
                                  {formErrors.branch && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.branch}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                  

                          <div class="row">

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Login on Holidays<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                    <div class="form-radio row ml-3">
                                      <div class="radio radio-inline col-xs-3 ">
                                        <label for="title_yes">
                                          <input
                                            type="radio"
                                            name="login_on_holidays"
                                            value={"yes"}
                                            id={"title_yes"}
                                            checked={login_on_holidays === 'yes' ? 'checked' : ''}
                                            onChange={(e) =>
                                              setlogin_on_holidays(e.target.value)
                                            }
                                          />
                                          <i class="helper"></i>Yes
                                        </label>
                                      </div>
                                      <div class="radio radio-inline col-xs-3 ">
                                        <label for="title_no">
                                          <input
                                            type="radio"
                                            name="login_on_holidays"
                                            id={"title_no"}
                                            value={"no"}
                                            checked={login_on_holidays === 'no' ? 'checked' : ''}
                                            onChange={(e) =>
                                              setlogin_on_holidays(e.target.value)
                                            }
                                          />
                                          <i class="helper"></i>No
                                        </label>
                                      </div>
                                      
                                    </div>
                                    {formErrors.login_on_holidays && (
                                      <span style={{ color: "red" }}>
                                        {formErrors.login_on_holidays}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Searchable Accounts<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                    <div class="form-radio row ml-3">
                                      <div class="radio radio-inline col-xs-3 ">
                                        <label for="account_yes">
                                          <input
                                            type="radio"
                                            name="searchable_account"
                                            value={"yes"}
                                            id={"account_yes"}
                                            checked={searchable_account === 'yes' ? 'checked' : ''}
                                            onChange={(e) =>
                                              setsearchable_account(e.target.value)
                                            }
                                          />
                                          <i class="helper"></i>Yes All
                                        </label>
                                      </div>
                                      <div class="radio radio-inline col-xs-3 ">
                                        <label for="account_no">
                                          <input
                                            type="radio"
                                            name="searchable_account"
                                            id={"account_no"}
                                            value={"no"}
                                            checked={searchable_account === 'no' ? 'checked' : ''}
                                            onChange={(e) =>
                                              setsearchable_account(e.target.value)
                                            }
                                          />
                                          <i class="helper"></i>No - Only Assigned
                                        </label>
                                      </div>
                                      
                                    </div>
                                    {formErrors.searchable_account && (
                                      <span style={{ color: "red" }}>
                                        {formErrors.searchable_account}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>

                          </div>

                          <div class="row">

                          <div class="col-sm-12 col-xl-6 m-b-30">
                            <div class="row">
                              <div class="col-sm-5">
                                <h6 class="text-xl-right">
                                User Active<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-7">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="active_yes">
                                        <input
                                          type="radio"
                                          name="user_active"
                                          value={"yes"}
                                          id={"active_yes"}
                                          checked={user_active === 'yes' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setuser_active(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Yes
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="active_no">
                                        <input
                                          type="radio"
                                          name="user_active"
                                          id={"active_no"}
                                          value={"no"}
                                          checked={user_active === 'no' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setuser_active(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>No
                                      </label>
                                    </div>
                                    
                                  </div>
                                  {formErrors.user_active && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.user_active}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                          </div>

                         
                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <h5 class="sub-title text-center">
                                      {/* Link With Software */}
                              </h5>
                            </div>
                          </div>


                        
                        


                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                              <button
                                class="btn btn-success btn-square"
                                type="submit"
                                disabled={submitting}
                              >
                                SAVE
                              </button>{" "}
                              &nbsp;
                              <a href="/LINK WITH SOFTWARE" class="btn btn-danger btn-square">Cancel</a>
                              &nbsp;
                              <button
                                class="btn btn-warning btn-square"
                                type="reset"
                              >
                                Reset
                              </button>

                              <ToastContainer className="toast-container" />
                            </div>
                          </div>


                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;