import React, { useState, useEffect } from "react";
import DashboardLayout from "../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { 
  dataDecrypt,
    dataEncript,
    // getCurrentDate,
    // infoAlert,
    FirstLetterCapitalize,
    formatDate
     } from "../../../helper/commonHelpers";

function CompanyDetails() {
  const { passbookID }   = useParams();
  const [listDetails, setlistDetails] = useState([]);

  const [submitting, setSubmitting] = useState(false);
  const [accountList, setaccountList] = useState([]);


  const [formErrors, setFormErrors] = useState({
    account_type: '',
    account_no: '',
    issue_date: '',
    passbook_no: '',
  });


const[account_type, setaccount_type] = useState('');
const[account_no, setaccount_no] = useState('');
const[issue_date, setissue_date] = useState('');
const[passbook_no, setpassbook_no] = useState('');

  useEffect(() => {

    axios.get(API_BASE_URL + "api-passbooks/get-passbook-list?id="+dataDecrypt(passbookID), {
      headers: API_HEADERS,
    }).then((response) => {


      if (response.data["status"] > 0) {
        setlistDetails(response.data["data"]);

        setaccount_type(response.data["data"].account_type);
        setaccount_no(response.data["data"].account_no);
        setissue_date((response.data["data"].issue_date?formatDate(response.data["data"].issue_date, 'YMD'):''));
        setpassbook_no(response.data["data"].passbook_no);


        axios.get(API_BASE_URL+'api-common/get-account-list?account_for='+(response.data["data"].account_type), { headers: API_HEADERS })
        .then(response => {
    
              if (response.data["status"] > 0) {
                setaccountList(response.data['data']);
              }
              else{
                setaccountList([]);
              }
        });



      } else {
          toast.error(response.data["message"]);
      }

    }).catch((error) => {
      toast.error("Failed to fetch data!");
    });

    // setissue_date(getCurrentDate());
  }, [passbookID]);


  const getAccountList = (e) => {
    
    axios.get(API_BASE_URL+'api-common/get-account-list?account_for='+e.target.value, { headers: API_HEADERS })
    .then(response => {

          if (response.data["status"] > 0) {
            setaccountList(response.data['data']);
          }
          else{
            setaccountList([]);
          }
    })
    .catch(error => {
      toast.error("Failed to fetch role list list!");
    });
  }


  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));

    const formData = {
        account_type: account_type,
        account_no: account_no,
        issue_date: issue_date,
        passbook_no: passbook_no,
        id: ''+listDetails.id+'',
        
    };

    // validate form

    if (!account_type) {
      errors.account_type = 'Account Type is required.';
    }

      if(!account_no) {
        errors.account_no = 'Scheme Code is required.';
      }

      if (!issue_date) {
        errors.issue_date = 'Issue Date is required.';
      }

      if (!passbook_no) {
        errors.passbook_no = 'Passbook No is required.';
      }


    
      console.log(formData);
    //   console.log(errors);

    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      axios
        .post(API_BASE_URL + "api-passbooks/add-passbook", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/passbook-view/"+passbookID;
            }, 2000);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 1000);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };




  
  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
            <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>Edit Passbook - {listDetails && listDetails.passbook_no && (
                              <>{FirstLetterCapitalize(listDetails.passbook_no)}</>
                              )}</h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/passbook-list">Passbooks</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href={`/passbook-view/${dataEncript(listDetails.id)}`}>{listDetails && listDetails.passbook_no && (
                              <>{FirstLetterCapitalize(listDetails.passbook_no)}</>
                              )}</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> Edit</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12"></div>

                


                  <div class="col-sm-12">
                    <div class="card">
                    

                      <div class="card-block">
                        <form onSubmit={updateDetails}>

                        <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Account Type<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <select
                                    class="form-control m-b"
                                    name="promoter_id"
                                    value={account_type}

                                    onChange={(e) =>
                                      { setaccount_type(e.target.value); getAccountList(e)}
                                    }

                                    disabled

                                  >
                                  <option value=" ">Select Type</option>
                                  <option value="Saving">Saving</option>
                                  <option value="FD/MIS">FD/ MIS</option>
                                  <option value="RD">RD</option>
                                  <option value="DD">DD</option>
                                  <option value="Gold Loan">Gold Loan</option>
                                  <option value="Property Loan">Property Loan</option>
                                  <option  value="Deposit Loan">Deposit Loan</option>
                                  <option value="Other Loan">Other Loan</option>
                                  <option value="Fixed Loan">Fixed Loan</option>
                                  <option value="CC Limit">CC Limit</option>
                                   
                                  </select>
                                  {formErrors.account_type && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.account_type}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Account No<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                    <select
                                      class="form-control m-b"
                                      name="account_no"
                                      value={account_no}
                                      onChange={(e) =>
                                        setaccount_no(e.target.value)
                                      }
                                    >
                                      <option value={''} >Select Account</option>
                                      {accountList.map((accountData) => (
                                        <option
                                          value={accountData.id}
                                          selected={
                                            accountData.id === account_no
                                              ? "selected"
                                              : ""
                                          }
                                        >
                                          {accountData.account_no?accountData.account_no:''} 

                                          {accountData.member_name?' ('+FirstLetterCapitalize(accountData.member_name)+')':''}

                                        </option>
                                      ))}
                                    </select>
                                  {formErrors.account_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.account_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                          </div>


                          <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Passbook Issue Date<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="date"
                                    value={issue_date}
                                    onChange={(e) =>
                                      setissue_date(e.target.value)
                                    }
                                    placeholder="Enter Passbook Issue Date"
                                    
                                  />
                                  {formErrors.issue_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.issue_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Passbook No<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input class="form-control" type="number" value={passbook_no} onChange={(e) => setpassbook_no(e.target.value) } placeholder="Enter Passbook No"  />
                                  {formErrors.passbook_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.passbook_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                          </div>



                      


                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                              <button
                                class="btn btn-success btn-square"
                                type="submit"
                                disabled={submitting}
                              >
                                UPDATE PASSBOOK
                              </button>{" "}
                              <a href={`/passbook-view/${dataEncript(listDetails.id)}`} class="btn btn-danger btn-square">Cancel</a>
                             
                             

                              <ToastContainer className="toast-container" />
                            </div>
                          </div>


                        </form>
                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
