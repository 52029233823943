import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { 
  dataDecrypt,
    // dataEncript,
    // getCurrentDate,
    FirstLetterCapitalize,
    formatDate,
    infoAlert
     } from "./../../../helper/commonHelpers";

function CompanyDetails() {
    const { scheemID }   = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [listDetails, setlistDetails] = useState([]);

  const [formErrors, setFormErrors] = useState({
    scheme_name: '',
    scheme_code: '',
  });


  // PROMOTER INFO 
const[scheme_name, setscheme_name] = useState(''); 
const[scheme_code, setscheme_code] = useState(''); 

const[min_fd_amt, setmin_fd_amt] = useState(''); 
const[fd_lock_period, setfd_lock_period] = useState(''); 
// const[annual_ins_rate, setannual_ins_rate] = useState(''); 
// const[add_on_ins_rate, setadd_on_ins_rate] = useState(''); 

const[ins_lock_period, setins_lock_period] = useState(''); 

// const[fd_tenure_value, setfd_tenure_value] = useState(''); 
// const[fd_tenure_type, setfd_tenure_type] = useState(''); 
// const[ins_payout, setins_payout] = useState(''); 

const[bonous_rate_type, setbonous_rate_type] = useState('%'); 
const[bonous_rate_value, setbonous_rate_value] = useState('0.0'); 
const[cancel_charge_type, setcancel_charge_type] = useState('%'); 
const[cancellation_charge, setcancellation_charge] = useState(''); 
const[penal_charge, setpenal_charge] = useState(''); 
const[stationary_charge, setstationary_charge] = useState(''); 
const[scheme_active, setscheme_active] = useState(''); 
const[effective_date, seteffective_date] = useState(''); 

// const[//scheme_id, setscheme_id] = useState(''); 


const [fromdays, setfromdays] = useState(['']);
const [todays, settodays] = useState(['']);
const [ins_rate, setins_rate] = useState(['']);

const [sr_citizen_ins_rate, setsr_citizen_ins_rate] = useState(['']);
const [ins_payout_type, setins_payout_type] = useState(['']);


  useEffect(() => {
    axios.get(API_BASE_URL + "api-common/get-account-scheme-details?id="+dataDecrypt(scheemID), {
        headers: API_HEADERS,
      }).then((response) => {
  
        console.log(response.data);
        if (response.data["status"] > 0) {

            setlistDetails(response.data["data"]);
         
            setscheme_name(response.data["data"].scheme_name);
            setscheme_code(response.data["data"].scheme_code);
            setmin_fd_amt(response.data["data"].min_open_bal);
            setfd_lock_period(response.data["data"].lock_in_period);
            setins_lock_period(response.data["data"].ins_lock_period);
            setbonous_rate_type(response.data["data"].bonous_rate_type);
            setbonous_rate_value(response.data["data"].bonous_rate_value);
            setcancel_charge_type(response.data["data"].cancel_charge_type);
            setcancellation_charge(response.data["data"].cancel_charge_value);
            setpenal_charge(response.data["data"].penal_charge);
            setstationary_charge(response.data["data"].stationary_charge);
            setscheme_active(response.data["data"].scheme_active);
            seteffective_date((response.data["data"].effective_date? formatDate(response.data["data"].effective_date, 'YMD'):'' ));
            
        }
  
      });
  }, [scheemID]);

  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));

    const formData = {
        // table_id:dataDecrypt(memberID),
        scheme_name: scheme_name,
        scheme_code: scheme_code,

          min_fd_amt: min_fd_amt,
          fd_lock_period: fd_lock_period,
          // annual_ins_rate: annual_ins_rate,
          // add_on_ins_rate: add_on_ins_rate,
          ins_lock_period: ins_lock_period,
          // fd_tenure_value: fd_tenure_value,
          // fd_tenure_type: fd_tenure_type,
          // ins_payout: ins_payout,
          bonous_rate_type: bonous_rate_type,
          bonous_rate_value: bonous_rate_value,
          cancel_charge_type: cancel_charge_type,
          cancellation_charge: cancellation_charge,
          penal_charge: penal_charge,
          stationary_charge: stationary_charge,
          scheme_active: scheme_active,
          effective_date:effective_date,

          from_days:fromdays,
          to_days:todays,

          ins_rate:ins_rate,
          sr_citizen_ins_rate:sr_citizen_ins_rate,
          ins_payout_type:ins_payout_type


    };

    // validate form

    if (!scheme_name) {
      errors.scheme_name = 'Scheme Name is required.';
    }

    if (!scheme_code) {
          errors.scheme_code = 'Scheme Code is required.';
        }

        if (!min_fd_amt) {
          errors.min_fd_amt = 'Min. FD/ MIS Amount is required.';
        }

        if (!effective_date) {
          errors.effective_date = 'Effective Date is required.';
        }

        if (!scheme_active) {
          errors.scheme_active = 'Active status is required.';
        }


  
        console.log(formData);

    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      axios
        .post(API_BASE_URL + "api-fd-account/add-new-scheme", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/fixed-deposit-schemes";
            //   window.location.reload();
            }, 2000);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 1000);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };




  const handleFromDays = (index, value) => {
    const updatedFromDays = [...fromdays];
    updatedFromDays[index] = value;
    setfromdays(updatedFromDays);
   };

   
  const handleTodaysChange = (index, value) => {
    const updatedToDays = [...todays];
    updatedToDays[index] = value;
    settodays(updatedToDays);
   };


   const handleins_rateChange = (index, value) => {
    const updatedToDays = [...ins_rate];
    updatedToDays[index] = value;
    setins_rate(updatedToDays);
   };

   const handlesr_citizen_ins_rateChange = (index, value) => {
    const updatedToDays = [...sr_citizen_ins_rate];
    updatedToDays[index] = value;
    setsr_citizen_ins_rate(updatedToDays);
   };

   
   const handleins_payout_typeChange = (index, value) => {
    const updatedToDays = [...ins_payout_type];
    updatedToDays[index] = value;
    setins_payout_type(updatedToDays);
   };


  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
            <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>Edit Fixed Deposit Scheme -  {listDetails && listDetails.scheme_name && (
                              <>{FirstLetterCapitalize(listDetails.scheme_name)}</>
                              )}</h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/account-scheem-list">Saving Scheme</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> {listDetails && listDetails.scheme_name && (
                              <>{FirstLetterCapitalize(listDetails.scheme_name)}</>
                              )} </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> Edit </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12"></div>

                


                  <div class="col-sm-12">
                    <div class="card">
                    

                      <div class="card-block">
                        <form onSubmit={updateDetails}>

                        <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Scheme Name<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={scheme_name}
                                    onChange={(e) =>
                                      setscheme_name(e.target.value)
                                    }
                                    placeholder="Enter Scheme Name"
                                  />
                                  {formErrors.scheme_name && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.scheme_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Scheme Code<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <div class="input-group" >
                                   <input class="form-control" type="text" value={scheme_code} onChange={(e) => setscheme_code(e.target.value) } placeholder="Enter Scheme Code" />
                                  <div class="input-group-prepend" style={{width:'auto'}}>
                                      <span class="input-group-text"   onClick={() => infoAlert("You can give code to FD schemes Like 'FD001'")} ><i class="icofont icofont-info-circle"></i></span>
                                  </div>
                                </div>
                                  {formErrors.scheme_code && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.scheme_code}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                          </div>


                        
                        <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Min. FD/ MIS Amount<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={min_fd_amt}
                                    onChange={(e) =>
                                      setmin_fd_amt(e.target.value)
                                    }
                                    placeholder="Enter Min. FD/ MIS Amount"
                                  />
                                  {formErrors.min_fd_amt && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.min_fd_amt}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  FD/ MIS Lock In Period<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                    <select
                                        class="form-control m-b"
                                        name="promoter_id"
                                        value={fd_lock_period}
                                        onChange={(e) =>
                                          setfd_lock_period(e.target.value)
                                        }
                                      >
                                        <option value="0">0 month</option>
                                          <option value="1">1 month</option>
                                          <option selected="selected" value="3">3 months</option>
                                          <option value="6">6 months</option>
                                          <option value="9">9 months</option>
                                          <option value="12">12 Months</option>
                                          <option value="15">15 Months</option>
                                          <option value="16">16 Months</option>
                                          <option value="18">18 Months</option>
                                          <option value="21">21 Months</option>
                                          <option value="24">24 Months</option>
                                          <option value="25">25 Months</option>
                                          <option value="27">27 Months</option>
                                          <option value="30">30 Months</option>
                                          <option value="33">33 Months</option>
                                          <option value="36">36 Months</option>
                                          <option value="39">39 Months</option>
                                          <option value="42">42 Months</option>
                                          <option value="45">45 Months</option>
                                          <option value="48">48 Months</option>
                                          <option value="51">51 Months</option>
                                          <option value="54">54 Months</option>
                                          <option value="57">57 Months</option>
                                          <option value="60">60 Months</option>
                                      
                                      </select>
                                  {formErrors.fd_lock_period && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.fd_lock_period}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                          </div>




                          <div class="row">

                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Interest Lock In Period<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <div class="input-group">
                                <select
                                        class="form-control m-b"
                                        name="promoter_id"
                                        value={ins_lock_period}
                                        onChange={(e) =>
                                          setins_lock_period(e.target.value)
                                        }
                                      ><option value="0">0 month</option>
                                      <option value="3">3 months</option>
                                      <option selected="selected" value="6">6 months</option>
                                      <option value="9">9 months</option>
                                      <option value="12">12 Months</option>
                                      <option value="15">15 Months</option>
                                      <option value="16">16 Months</option>
                                      <option value="18">18 Months</option>
                                      <option value="21">21 Months</option>
                                      <option value="24">24 Months</option>
                                      <option value="25">25 Months</option>
                                      <option value="27">27 Months</option>
                                      <option value="30">30 Months</option>
                                      <option value="33">33 Months</option>
                                      <option value="36">36 Months</option>
                                      <option value="39">39 Months</option>
                                      <option value="42">42 Months</option>
                                      <option value="45">45 Months</option>
                                      <option value="48">48 Months</option>
                                      <option value="51">51 Months</option>
                                      <option value="54">54 Months</option>
                                      <option value="57">57 Months</option>
                                      <option value="60">60 Months</option>
                                      
                                      </select>
                                  <div class="input-group-prepend" style={{width:'auto'}}>
                                      <span class="input-group-text"   onClick={() => infoAlert("As per Nidhi Rules 2014, minimum interest locking period should be 6 months.")} ><i class="icofont icofont-info-circle"></i></span>
                                  </div>
                                </div>
                                  {formErrors.ins_lock_period && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.ins_lock_period}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>


                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5"> </div>
                                <div class="col-sm-7">
                                <h6 class="text-info">
                                  <starong>10.0 % TDS</starong> will be deducted, if the Interest exceeds ₹ 40000 per annually.
                                  </h6>
                                </div>
                              </div>
                            </div>

                          </div>


                          <div class="row">

                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Bonus Rate
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <div class="input-group">
                                  <div class="input-group-prepend" style={{width:'auto'}}>
                                  <select
                                          class="form-control m-b"
                                          name="promoter_id"
                                          value={bonous_rate_type}
                                          onChange={(e) =>
                                            setbonous_rate_type(e.target.value)
                                          }
                                        >
                                         <option value="%">(%)</option>
                                          <option value="fixed">FIXED</option>
                                        
                                        </select>
                                    </div>

                                      <input
                                      class="form-control"
                                      type="text"
                                      value={bonous_rate_value}
                                      onChange={(e) =>
                                        setbonous_rate_value(e.target.value)
                                      }
                                      placeholder="Enter Bonus Rate"
                                    />

                                  <div class="input-group-prepend" style={{width:'auto'}}>
                                      <span class="input-group-text"   onClick={() => infoAlert("Like if your FD/ MIS amount is 1 lakh & you are setting bonus rate as 1%, So 1,000 will be added to your FD/ MIS amount on maturity as bonus. If you are setting fixed bonus 1,000 then 1,000 will be added to 1 lakh on maturity as bonus. Bonus amount will be added only after when FD/ MIS will get matured.")} ><i class="icofont icofont-info-circle"></i></span>
                                  </div>
                                </div>
                                  {formErrors.ins_lock_period && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.ins_lock_period}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>


                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Cancellation Charges (if any)
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <div class="input-group">
                                  <div class="input-group-prepend" style={{width:'auto'}}>
                                  <select
                                          class="form-control m-b"
                                          name="promoter_id"
                                          value={cancel_charge_type}
                                          onChange={(e) =>
                                            setcancel_charge_type(e.target.value)
                                          }
                                        >
                                          <option value="%">(%)</option>
                                          <option value="fixed">FIXED</option>
                                        
                                        </select>
                                    </div>

                                      <input
                                      class="form-control"
                                      type="text"
                                      value={cancellation_charge}
                                      onChange={(e) =>
                                        setcancellation_charge(e.target.value)
                                      }
                                      placeholder="Enter Cancellation Charges"
                                    />

                                  <div class="input-group-prepend" style={{width:'auto'}}>
                                      <span class="input-group-text"   onClick={() => infoAlert("Charges if FD is closed before maturity. Leave blank if no charges.")} ><i class="icofont icofont-info-circle"></i></span>
                                  </div>
                                </div>
                                  {formErrors.ins_lock_period && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.ins_lock_period}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Penal Charges (%)
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <div class="input-group">
                                  <select
                                          class="form-control m-b"
                                          name="promoter_id"
                                          value={penal_charge}
                                          onChange={(e) =>
                                            setpenal_charge(e.target.value)
                                          }
                                        >
                                          <option value="0.0">0.0 %</option>
                                          <option value="0.5">0.5 %</option>
                                          <option value="1.0">1 %</option>
                                          <option value="1.5">1.5 %</option>
                                          <option value="2.0">2 %</option>
                                          <option value="3.0">3 %</option>
                                          <option value="4.0">4 %</option>
                                          <option value="5.0">5 %</option>
                                        
                                        </select>
                                    

                                  <div class="input-group-prepend" style={{width:'auto'}}>
                                      <span class="input-group-text"   onClick={() => infoAlert("Like if FD Pre Matures within 6 month, no interest would be given. After 6 month, Interest Rate would be calculated as (Original Rate - Penal Rate) might be 1% or 2%")} ><i class="icofont icofont-info-circle"></i></span>
                                  </div>
                                </div>
                                  {formErrors.penal_charge && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.penal_charge}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>


                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Effective Date <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input
                                      class="form-control"
                                      type="date"
                                      value={effective_date}
                                      onChange={(e) =>
                                        seteffective_date(e.target.value)
                                      }
                                      placeholder="Enter Min. FD/ MIS Amount"
                                    />
                                  {formErrors.effective_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.effective_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>



                          <div class="row">

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Stationary Fee
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input
                                      class="form-control"
                                      type="number"
                                      value={stationary_charge}
                                      onChange={(e) =>
                                        setstationary_charge(e.target.value)
                                      }
                                      placeholder="Enter Stationary Fee"
                                    />
                                  {formErrors.stationary_charge && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.stationary_charge}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="row" >
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Active<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_yes">
                                        <input
                                          type="radio"
                                          name="scheme_active"
                                          value={"yes"}
                                          id={"title_yes"}
                                          checked={scheme_active === 'yes' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setscheme_active(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Yes
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_no">
                                        <input
                                          type="radio"
                                          name="scheme_active"
                                          id={"title_no"}
                                          value={"no"}
                                          checked={scheme_active === 'no' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setscheme_active(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>No
                                      </label>
                                    </div>
                                  
                                    
                                  </div>
                                  {formErrors.scheme_active && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.scheme_active}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                          

                          <div class="row" style={{display:'none'}} >
                            <div class="col-sm-12 col-xl-10 offset-xl-1 m-b-30">
                                  
                            <div class="table-responsive dt-responsive">
                                    <table class="table dtable table-striped table-bordered nowrap" style={{width:'100%'}}>
                                          <thead>
                                                <tr>
                                                      <th colSpan={'2'} class="text-center">DAYS</th>
                                                      <th rowSpan={'2'}>ANNUAL INTEREST RATE (%)</th>
                                                      <th rowSpan={'2'}>SR CTZN INTEREST RATE (%)<i onClick={() => infoAlert("In the case of SR. Citizen, Total Interest will be (INTEREST RATE + SR CTZN INTEREST RATE)")} class="icofont icofont-info-circle"></i></th>
                                                      <th rowSpan={'2'}>INTEREST PAYOUT TYPE</th>
                                                </tr>
                                                <tr>
                                                      <th>From</th>
                                                      <th>To</th>
                                                </tr>
                                          </thead>
                                          <tbody>

                                          {[...Array(10)].map((_, index) => (
                                            <tr>
                                                <td> <input
                                                        class="form-control"
                                                        type="number"
                                                        value={fromdays[index]}
                                                        onChange={(e) =>
                                                          handleFromDays(index, e.target.value)
                                                        }
                                                      /></td>
                                                      
                                                <td> <input
                                                        class="form-control"
                                                        type="number"
                                                        value={todays[index]}
                                                        onChange={(e) =>
                                                          handleTodaysChange(index, e.target.value)
                                                        }
                                                      /></td>

                                                <td> <input
                                                        class="form-control"
                                                        type="number"
                                                        value={setins_rate[index]}
                                                        onChange={(e) =>
                                                          handleins_rateChange(index, e.target.value)
                                                        }
                                                      /></td>

                                                <td> <input
                                                        class="form-control"
                                                        type="number"
                                                        value={sr_citizen_ins_rate[index]}
                                                        onChange={(e) =>
                                                          handlesr_citizen_ins_rateChange(index, e.target.value)
                                                        }
                                                      /></td>
                                                      
                                                <td><select
                                                        class="form-control m-b"
                                                        name="promoter_id"
                                                        value={ins_payout_type[index]}
                                                        onChange={(e) =>
                                                          handleins_payout_typeChange(index, e.target.value)
                                                        }
                                                        data-placeholder="Select Interest payout"
                                                      >
                                                        <option value=" ">Select Interest payout</option>
                                                        
                                                        
                                                        <option value="CUMULATIVE_YEARLY">Cumulative Yearly</option>
                                                          <option value="CUMULATIVE_HALF_YEARLY">Cumulative Half Yearly</option>
                                                          <option value="CUMULATIVE_QUARTERLY">Cumulative Quarterly</option>
                                                          <option value="CUMULATIVE_MONTHLY">Cumulative Monthly</option>
                                                          <option value="MONTHLY">Monthly</option>
                                                          <option value="QUARTERLY">Quarterly</option>
                                                          <option value="HALF_YEARLY">Half Yearly</option>
                                                          <option value="YEARLY">Yearly</option>
                                                      
                                                      </select></td>
                                            </tr>
                                            ))}

                                          </tbody>
                                         </table>
                                     </div>

                            </div>
                          </div>



                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                              <button
                                class="btn btn-success btn-square"
                                type="submit"
                                disabled={submitting}
                              >
                                UPDATE SCHEME
                              </button>{" "}
                              <a href="/fixed-deposit-schemes" class="btn btn-danger btn-square">Cancel</a>
                              &nbsp;
                              
                             

                              <ToastContainer className="toast-container" />
                            </div>
                          </div>


                        </form>
                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
