import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
// import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { 
  // dataDecrypt,
    // dataEncript,
    getCurrentDate,
    // FirstLetterCapitalize
    infoAlert
     } from "./../../../helper/commonHelpers";

function CompanyDetails() {
  const [submitting, setSubmitting] = useState(false);
  const [bankList, setbankList] = useState([]);
  const [pramoterList, setpramoterList] = useState([]);
  const [associateListArr, setassociateListArr] = useState([]);



  const [formErrors, setFormErrors] = useState({
    member_id: '', 
    branch_id: '', 
    open_date: '', 
    joint_ac1_member_id: '',

    name: '',
    relation: '',
    address: '',


  });


  const [activeBranchList, setactiveBranchList] = useState([]);
  const [minorList, setminorList] = useState([]);
  const [memberList, setmemberList] = useState([]);
  // const [scheemList, setscheemList] = useState([]);




  // PROMOTER INFO 
  const[member_id, setmember_id] = useState('');
  const[member_name, setmember_name] = useState('');
  const[minor_id, setminor_id] = useState('');
  const[branch_id, setbranch_id] = useState('');
  const[associate_id, setassociate_id] = useState('');
  // const[scheme_id, setscheme_id] = useState('');
  const[open_date, setopen_date] = useState('');
  const[account_type, setaccount_type] = useState('single');
      const[joint_ac1_member_id, setjoint_ac1_member_id] = useState('');

  const[nominee, setnominee] = useState('no');
  const[transaction_date, settransaction_date] = useState('');





  const[years, setyears] = useState('');
  const[month, setmonth] = useState('');
  const[days, setdays] = useState('');
  const[fd_amount, setfd_amount] = useState('');
  const[intrest_payout_type, setintrest_payout_type] = useState('');
  const[tds_deducation, settds_deducation] = useState('no');
  const[senior_citizen, setsenior_citizen] = useState('');
  const[final_amount, setfinal_amount] = useState('');



  // 
  const[payment1, setpayment1] = useState('0');
  const[saving_account, setsaving_account] = useState('0');

  const[paymode, setpaymode] = useState('cash');
  const[creadited_nidhi_acc, setcreadited_nidhi_acc] = useState('');
  const[transfer_mode, settransfer_mode] = useState('');

  const[utr_no, setutr_no] = useState('');
  const[transfer_date, settransfer_date] = useState('');
  const[cheque_date, setcheque_date] = useState('');
  const[cheque_no, setcheque_no] = useState('');
  const[bank_name, setbank_name] = useState('');


  // 22222222222222222222
  const[payment2, setpayment2] = useState('0');
  const[saving_account2, setsaving_account2] = useState('0');

  const[paymode2, setpaymode2] = useState('cash');
  const[creadited_nidhi_acc2, setcreadited_nidhi_acc2] = useState('');
  const[transfer_mode2, settransfer_mode2] = useState('');

  const[utr_no2, setutr_no2] = useState('');
  const[transfer_date2, settransfer_date2] = useState('');
  const[cheque_date2, setcheque_date2] = useState('');
  const[cheque_no2, setcheque_no2] = useState('');
  const[bank_name2, setbank_name2] = useState('');


  useEffect(() => {


    axios.get(API_BASE_URL + "api-associates/get-associate-list", {
      headers: API_HEADERS,
    }).then((response) => {
      if (response.data["status"] > 0) {
        setassociateListArr(response.data["data"]);
      }
    });


    axios.get(API_BASE_URL + "api-common/get-bank-list", {
      headers: API_HEADERS,
    }).then((response) => {
      if (response.data["status"] > 0) {
        setbankList(response.data["data"]);
        }
    });

    axios.get(API_BASE_URL + "api-company/get-active-branch-list", {
      headers: API_HEADERS,
    }).then((response) => {
      if (response.data["status"] > 0) {
      setactiveBranchList(response.data["data"]);
      }
    });


    let minorCondition = `member_type = 'member'`;
    axios.get(API_BASE_URL + "api-common/get-data?tablename=tbl_minor&condition="+minorCondition, {
      headers: API_HEADERS,
    })
    .then((response) => {

      if (response.data["status"] > 0) {
        setminorList(response.data["data"]);
      } 

    })
    .catch((error) => {
    console.log('Failed to fetch MINOR DATA!');
    });



    axios.get(API_BASE_URL + "api-member/get-member-list", {
      headers: API_HEADERS,
    }).then((response) => {
      if (response.data["status"] > 0) {
      setmemberList(response.data["data"]);
      }
    }).catch((error) => {
      toast.error("Failed to fetch data!");
    });



    // axios.get(API_BASE_URL + "api-company/get-active-promoter-list", {
    //   headers: API_HEADERS,
    // })
    // .then((response) => {
    //   setpramoterList(response.data["data"]);
    // });


             let listCondition = `account_for = 'saving'`;
            axios.get(API_BASE_URL+'api-common/get-account-list?condition='+listCondition, { headers: API_HEADERS })
            .then(response => {
                  if (response.data["status"] > 0) {
                    setpramoterList(response.data['data']);
                  }
                  console.log(response.data['data']);
            });
 


  setopen_date(getCurrentDate());
  settransaction_date(getCurrentDate());
       settransfer_date(getCurrentDate());
  }, []);

  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));

    const formData = {
      account_for:'fd',

      member_id: member_id,
      member_name: member_name,
      minor_id: minor_id,
      branch_id: branch_id,
      associate_id: associate_id,
      scheme_id: '1',
      open_date: open_date,
      fd_amount:fd_amount,
      // Tenure Period
          years:years,
          month:month,
          days:days,
          intrest_payout_type:intrest_payout_type,
      tds_deducation:tds_deducation,
          senior_citizen:senior_citizen,
        

      account_type: account_type,
      joint_member1:joint_ac1_member_id, 
      nominee: nominee,
              // this is for nominee----------------------
              name: name,
              relation: relation,
              address: address,


      final_amount:final_amount,
      transaction_date: transaction_date,


      paymode1: paymode,
      paymode1_amt:payment1,
      credited_in_account1: creadited_nidhi_acc,
      transfer_mode1: transfer_mode,
      utr_no1: utr_no,
      transfer_date1: transfer_date,
      cheque_date1: cheque_date,
      cheque_no1: cheque_no,
      bank_name1: bank_name,
      saving_account1:saving_account,


        // 2222222222222222222
        paymode2_amt: payment2,
        paymode2: paymode2,
        saving_account2: saving_account2,
        credited_in_account2: creadited_nidhi_acc2,
        transfer_mode2: transfer_mode2,
        utr_no2: utr_no2,
        transfer_date2: transfer_date2,
        cheque_date2: cheque_date2,
        cheque_no2: cheque_no2,
        bank_name2: bank_name2,
    };

    // validate form

    if (!member_id) {
      errors.member_id = 'Member is required.';
    }

    if (!branch_id) {
      errors.branch_id = 'Branch is required.';
    }

   

    if (!open_date) {
      errors.open_date = 'Open Date is required.';
    }


    if (!years) {
      errors.years = 'Years is required.';
    }

    if (!month) {
      errors.month = 'Month is required.';
    }

    if (!days) {
      errors.days = 'Days is required.';
    }




    if(account_type==='joint')
    {

      if (!joint_ac1_member_id) {
          errors.joint_ac1_member_id = 'Joint A/c Member is required.';
        }

    }


    if(nominee==='yes')
    {

        if (!name) {
          errors.name = 'Name is required.';
        }

        if (!relation) {
          errors.relation = 'Relation is required.';
        }

        if (!address) {
          errors.address = 'Address is required.';
        }

    }

  

    if(paymode==='cheque')
    {
      if (!bank_name) {
        errors.bank_name = 'Bank Name is required.';
      }

      if (!cheque_no) {
        errors.cheque_no = 'Cheque No. is required.';
      }

      if (!cheque_date) {
        errors.cheque_date = 'Cheque Date. is required.';
      }
    }

    if(paymode==='online')
    {
      if (!transfer_date) {
        errors.transfer_date = 'Transfer Date is required.';
      }

      if (!utr_no) {
        errors.utr_no = 'UTR/ Transaction No is required.';
      }

      if (!transfer_mode) {
        errors.transfer_mode = 'Transfer Mode is required.';
      }

      if (!creadited_nidhi_acc) {
        errors.creadited_nidhi_acc = 'creadited_nidhi_acc is required.';
      }

    }



    // SECOND VALIDATION 
    if(paymode2==='cheque')
    {
      if (!bank_name2) {
        errors.bank_name2 = 'Bank Name is required.';
      }

      if (!cheque_no2) {
        errors.cheque_no2 = 'Cheque No. is required.';
      }

      if (!cheque_date2) {
        errors.cheque_date2 = 'Cheque Date. is required.';
      }
    }

    if(paymode2==='online')
    {
      if (!transfer_date2) {
        errors.transfer_date2 = 'Transfer Date is required.';
      }

      if (!utr_no2) {
        errors.utr_no2 = 'UTR/ Transaction No is required.';
      }

      if (!transfer_mode2) {
        errors.transfer_mode2 = 'Transfer Mode is required.';
      }

      if (!creadited_nidhi_acc2) {
        errors.creadited_nidhi_acc2 = 'creadited_nidhi_acc is required.';
      }

    }


    //   console.log(errors);

    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      axios
        .post(API_BASE_URL + "api-fd-account/open-fd-account", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/fixed-deposit-account";
            //   window.location.reload();
            }, 2000);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 1000);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };




  const [name, setname] = useState(['']);
  const [relation, setrelation] = useState(['']);
  const [address, setaddress] = useState(['']);

  const [nominees, setNominees] = useState(['']);

  

  const addNominee = () => {
    setNominees([...nominees, '']);
};

const removeNominee = (index) => {
 const updatedNominees = [...nominees];
 updatedNominees.splice(index, 1);
 setNominees(updatedNominees);
};

const handleNomineeChange = (index, value) => {
 const updatedNominees = [...nominees];
 updatedNominees[index] = value;
 setNominees(updatedNominees);
 setname(updatedNominees);
};

const handleRelation = (index, value) => {
 const updatedRelation = [...relation];
 updatedRelation[index] = value;
 setrelation(updatedRelation);
};

const handleAddress = (index, value) => {
 const updatedAddress = [...address];
 updatedAddress[index] = value;
 setaddress(updatedAddress);
};


const setFdAmounts = async (ttlamount) => {
  setfinal_amount(ttlamount);
  setpayment1(ttlamount);
};

const setPayment2 = async (ttlamount) => {
  
  if(final_amount>=ttlamount)
  {
    setpayment2((final_amount-ttlamount));
  }
  else{
    toast.error("Amount can't be negative!");
  }

};


  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
            <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>New Fixed Deposit</h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/fixed-deposit-account">Fixed Deposit</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">New</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12"></div>

                


                  <div class="col-sm-12">
                    <div class="card">
                    

                      <div class="card-block">
                        <form onSubmit={updateDetails}>

                       
                        


                          <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Member<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">

                                <select
                                    class="form-control m-b"
                                    name="member_id"
                                    value={member_id}
                                    onChange={(e) =>
                                      {setmember_id(e.target.value);
                                        const selectedIndex = e.target.options.selectedIndex;
                                        const dataIndex = e.target.options[selectedIndex].getAttribute('data-index');

                                        setmember_name((memberList[dataIndex].fname+' '+ memberList[dataIndex].lname));

                                      }
                                    }
                                  >
                                    <option value={''} disabled>Select Member</option>
                                    {memberList.map((aBranchData, mindex) => (
                                      <option
                                        value={aBranchData.id}
                                        data-index={mindex}
                                        selected={
                                          aBranchData.id === member_id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {aBranchData.fname} &nbsp;
                                        {aBranchData.lname}

                                      </option>
                                    ))}
                                  </select>


                                  {formErrors.member_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.member_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Member Name
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input class="form-control" type="text" value={member_name} onChange={(e) => setmember_name(e.target.value) } placeholder="Enter Member Name" readOnly />
                                  {formErrors.member_name && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.member_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Minor
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                  <select
                                  class="form-control m-b"
                                  name="minor_id"
                                  value={minor_id}
                                  onChange={(e) =>
                                    setminor_id(e.target.value)
                                  }
                                >
                                  <option value={''} disabled>Select Minor</option>
                                  {minorList.map((minorData) => (
                                    <option
                                      value={minorData.id}
                                      selected={
                                        minorData.id === minor_id
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {minorData.title?minorData.title:''}
                                      {minorData.fname?minorData.fname:''} {minorData.lname?minorData.lname:''}

                                    </option>
                                  ))}
                                </select>


                                  {formErrors.minor_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.minor_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Branch<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">

                                  <select
                                  class="form-control m-b"
                                  name="branch_id"
                                  value={branch_id}
                                  onChange={(e) =>
                                    setbranch_id(e.target.value)
                                  }
                                >
                                  <option value={''} disabled>Branch</option>
                                  {activeBranchList.map((branchData) => (
                                    <option
                                      value={branchData.id}
                                      selected={
                                        branchData.id === branch_id
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {branchData.branch_name}

                                    </option>
                                  ))}
                                </select>



                                  {formErrors.branch_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.branch_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Associate/ Advisor/ Staff 
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                              

                                  <select
                                  class="form-control m-b"
                                  name="associate_id"
                                  value={associate_id}
                                  onChange={(e) =>
                                    setassociate_id(e.target.value)
                                  }
                                >
                                  <option value={''} disabled>Associate/ Advisor/ Staff</option>
                                  {associateListArr.map((branchData) => (
                                    <option
                                      value={branchData.id}
                                      selected={
                                        branchData.id === associate_id
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {branchData.first_name} 
                                      {branchData.last_name}

                                    </option>
                                  ))}
                                </select>


                                  {formErrors.associate_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.associate_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <h4 class="sub-title text-center">
                              {/* SERVICE CHARGES 1*/}
                              </h4>
                            </div>
                          </div>

                          <div class="row">
                            

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Open Date<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input class="form-control" type="date" value={open_date} onChange={(e) => setopen_date(e.target.value) } placeholder="Enter Open Date" />
                                  {formErrors.open_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.open_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>


                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Tenure Period<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">


                                <div class="input-group">
                                <div class="input-group-prepend" style={{width:'33%'}}>
                                        <input
                                            class="form-control"
                                            type="text"
                                            value={years}
                                            onChange={(e) =>
                                            setyears(e.target.value)
                                            }
                                            placeholder="Years"
                                        />
                                        
                                  </div>
                                <input
                                    class="form-control"
                                    type="text"
                                    value={month}
                                    onChange={(e) =>
                                      setmonth(e.target.value)
                                    }
                                    placeholder="Month"
                                  />
                                    
                                   <div class="input-group-prepend" style={{width:'33%'}} >
                                        <input
                                            class="form-control"
                                            type="text"
                                            value={days}
                                            onChange={(e) =>
                                            setdays(e.target.value)
                                            }
                                            placeholder="Days"
                                        />
                                          
                                  </div>
                                  <div class="input-group-prepend" style={{width:'0.9%'}}>
                                            <span class="input-group-text" onClick={() => infoAlert("Choose Monthly - if you want to create MIS Scheme. Choose Cumulative Yearly - if you want to give the full amount (principal + interest) on maturity.")}   ><i class="icofont icofont-info"></i></span>
                                        </div>
                                </div>

                                <div class="row">
                                  <div class="col-sm-4">{formErrors.years && (
                                      <span style={{ color: "red" }}>
                                        {formErrors.years}
                                      </span>
                                    )}</div>
                                  <div class="col-sm-4">{formErrors.month && (
                                      <span style={{ color: "red" }}>
                                        {formErrors.month}
                                      </span>
                                    )}</div>
                                  <div class="col-sm-4">{formErrors.days && (
                                      <span style={{ color: "red" }}>
                                        {formErrors.days}
                                      </span>
                                    )}</div>
                                </div>


                                 
                                  {formErrors.intrest_rate && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.intrest_rate}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>


                          </div>


                          <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  FD Amount<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="number"
                                    value={fd_amount}
                                    

                                    onInput={(e) => {setfd_amount(e.target.value); setFdAmounts(e.target.value); } }

                                    placeholder="Enter fd_amount ( ₹ )"
                                  />
                                  {formErrors.fd_amount && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.fd_amount}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Interest Payout Type<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <div class="input-group">
                                        <select
                                            class="form-control m-b"
                                            value={intrest_payout_type}
                                            onChange={(e) =>
                                            setintrest_payout_type(e.target.value)
                                            }
                                        >
                                        <option value="">Select Interest Payout Cycle</option>
                                        <option value="CUMULATIVE_YEARLY">Cumulative Yearly</option>
                                        <option value="CUMULATIVE_HALF_YEARLY">Cumulative Half Yearly</option>
                                        <option value="CUMULATIVE_QUARTERLY">Cumulative Quarterly</option>
                                        <option value="CUMULATIVE_MONTHLY">Cumulative Monthly</option>
                                        <option value="MONTHLY">Monthly</option>
                                        <option value="QUARTERLY">Quarterly</option>
                                        <option selected="selected" value="HALF_YEARLY">Half Yearly</option>
                                        <option value="YEARLY">Yearly</option>
                                        
                                        </select>
                                        <div class="input-group-prepend" style={{width:'auto'}}>
                                            <span class="input-group-text" onClick={() => infoAlert("Choose Monthly - if you want to create MIS Scheme. Choose Cumulative Yearly - if you want to give the full amount (principal + interest) on maturity.")}   ><i class="icofont icofont-info"></i></span>
                                        </div>
                                        </div>
                                  {formErrors.intrest_payout_type && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.intrest_payout_type}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                        
                          <div class="row" >
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  TDS Deduction<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_yes">
                                        <input
                                          type="radio"
                                          name="tds_deducation"
                                          value={"yes"}
                                          id={"title_yes"}
                                          checked={tds_deducation === 'yes' ? 'checked' : ''}
                                          onChange={(e) =>
                                            settds_deducation(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Yes
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_no">
                                        <input
                                          type="radio"
                                          name="tds_deducation"
                                          id={"title_no"}
                                          value={"no"}
                                          checked={tds_deducation === 'no' ? 'checked' : ''}
                                          onChange={(e) =>
                                            settds_deducation(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>No
                                      </label>
                                    </div>
                                  
                                    
                                  </div>
                                  {formErrors.tds_deducation && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.tds_deducation}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                        <div class="col-sm-12 col-xl-6 m-b-30">
                            <div class="row">
                            <div class="col-sm-5">
                        <h6 class="text-xl-right">Senior Citizen <span class="text-danger">*</span></h6>
                                </div>
                                <div class="col-sm-7">
                                <input type="checkbox" name="senior_citizen" checked={senior_citizen === 'yes' ? 'checked' : ''} value={"yes"} onChange={(e) => setsenior_citizen(e.target.checked ? 'yes' : 'no')} />
                                </div>
                            </div>
                        </div>
                        

                          </div>



                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <h4 class="sub-title text-center">
                              {/* SERVICE CHARGES 2*/}
                              </h4>
                            </div>
                          </div>

                        
                          <div class="row" >
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Account Type<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_single">
                                        <input
                                          type="radio"
                                          name="account_type"
                                          value={"single"}
                                          id={"title_single"}
                                          checked={account_type === 'single' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setaccount_type(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Single
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_joint">
                                        <input
                                          type="radio"
                                          name="account_type"
                                          id={"title_joint"}
                                          value={"joint"}
                                          checked={account_type === 'joint' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setaccount_type(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Joint
                                      </label>
                                    </div>
                                  
                                    
                                  </div>
                                  {formErrors.account_type && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.account_type}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          
                            <div class="col-sm-12 col-xl-6 m-b-30" style={{ display: account_type === 'joint' ? '' : 'none' }} >
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Joint A/c Member <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                  <select
                                    class="form-control m-b"
                                    name="joint_ac1_member_id"
                                    value={joint_ac1_member_id}
                                    onChange={(e) =>
                                      setjoint_ac1_member_id(e.target.value)
                                    }
                                  >
                                    <option value={''} disabled>Select Member</option>
                                    {memberList.map((aBranchData) => (
                                      <option
                                        value={aBranchData.id}
                                        selected={
                                          aBranchData.id === joint_ac1_member_id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {aBranchData.fname} &nbsp;
                                        {aBranchData.mnane}

                                      </option>
                                    ))}
                                  </select>



                                  {formErrors.joint_ac1_member_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.joint_ac1_member_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Nominee <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="nominee_yes">
                                        <input
                                          type="radio"
                                          name="nominee"
                                          value={"yes"}
                                          id={"nominee_yes"}
                                          checked={nominee === 'yes' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setnominee(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Yes
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="nominee_no">
                                        <input
                                          type="radio"
                                          name="nominee"
                                          id={"nominee_no"}
                                          value={"no"}
                                          checked={nominee === 'no' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setnominee(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>No
                                      </label>
                                    </div>
                                  
                                    
                                  </div>
                                  {formErrors.nominee && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.nominee}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                          



                        
                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <h4 class="sub-title text-center">
                              {/* SERVICE CHARGES 2*/}
                              </h4>
                            </div>
                          </div>

                        
                         

                          <div class="row" style={{ display: nominee === 'yes' ? '' : 'none' }}  >
                          <div class="col-sm-12 col-xl-12 m-b-30">

                          {nominees.map((nomineval, index) => (
                          <div class="row" key={index} style={{ display: nominee === 'no' ? 'none' : '' }}>
                          <div class="col-sm-12"> <hr></hr></div>
                          <div class="col-sm-10">
                             <div class="row">
                                <div class="col-sm-12 col-xl-4 m-b-30">
                                    <div class="row">
                                    
                                        <div class="col-sm-12">
                                        <h6 class="">Name<span class="text-danger">*</span></h6>
                                        <input class="form-control" type="text" value={nominees[index]} onChange={(e) => {handleNomineeChange(index, e.target.value);}} 
                                            placeholder={`Nominee ${index + 1}`} />
                                        {formErrors.name && <span style={{ color: 'red' }}>{formErrors.name}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-xl-4 m-b-30">
                                    <div class="row">
                                    
                                        <div class="col-sm-12">
                                        <h6 class="text-xl-right">
                                                                Relation<span class="text-danger">*</span>
                                                            </h6>
                                        <select class="form-control"
                                                name="relation"
                                                value={relation[index]}
                                                onChange={(e) =>
                                                  handleRelation(index, e.target.value)
                                                }
                                            >
                                                <option value="">Select Relation</option>
                                                <option value="father">Father</option>
                                                <option value="mother">Mother</option>
                                                <option value="son">Son</option>
                                                <option value="daughter">Daughter</option>
                                                <option value="spouse">
                                                Spouse (Husband/ Wife)
                                                </option>
                                                <option value="husband">Husband</option>
                                                <option value="wife">Wife</option>
                                                <option value="brother">Brother</option>
                                                <option value="sister">Sister</option>
                                                <option value="daughter_in_law">
                                                Daughter in Law
                                                </option>
                                                <option value="brother_in_law">
                                                Brother in Law
                                                </option>
                                                <option value="grand_daughter">
                                                Grand Daughter
                                                </option>
                                                <option value="grand_son">Grand Son</option>
                                                <option value="nephew">Nephew</option>
                                                <option value="niece">Niece</option>
                                                <option value="other">Other</option>
                                            </select>

                                            {formErrors.relation && <span style={{ color: 'red' }}>{formErrors.relation}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-xl-4 m-b-30">
                                    <div class="row">
                                   
                                        <div class="col-sm-12">
                                        <h6 class="text-xl-right">Address<span class="text-danger">*</span> </h6>
                                          <textarea class="form-control" value={address[index]} onChange={(e) => handleAddress(index, e.target.value)}>{address[index]}</textarea>
                                          {formErrors.address && <span style={{ color: 'red' }}>{formErrors.address}</span>}
                                        </div>
                                    </div>
                                </div>
                                
                                </div>
                                </div>
                                <div class="col-sm-2"> 
                                        <a href="#a" class="text-info" onClick={() => removeNominee(index)}>Remove</a>
                                </div>
                                
                                </div>
                                  ))}


                              <div class="row">
                                <div class="col-sm-4">
                                 
                                      
                                  <h5 className="btn text-info" onClick={addNominee} style={{ display: nominee === 'no' ? 'none' : '' }}>
                                        + ADD MORE NOMINEE
                                   </h5>

                                </div>
                                <div class="col-sm-8">
                                
                                </div>
                              </div>
                            </div>
                          </div>




                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <h4 class="sub-title text-center">
                              {/* SERVICE CHARGES transaction-------------2*/}
                              </h4>
                            </div>
                          </div>

                          <div class="row">
                        

                            <div class="col-sm-12 col-xl-6 m-b-30">
                                    <div class="row">
                                      <div class="col-sm-5">
                                        <h6 class="text-xl-right">
                                        Final Amount
                                        </h6>
                                      </div>
                                      <div class="col-sm-7">
                                      <input class="form-control" type="text" value={final_amount} onChange={(e) => setfinal_amount(e.target.value) } placeholder="" readOnly  />
                                        {formErrors.final_amount && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.final_amount}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>


                            <div class="col-sm-12 col-xl-6 m-b-30">
                                    <div class="row">
                                      <div class="col-sm-5">
                                        <h6 class="text-xl-right">
                                        Transaction Date <span class="text-danger">*</span>
                                        </h6>
                                      </div>
                                      <div class="col-sm-7">
                                      <input class="form-control" type="date" value={transaction_date} onChange={(e) => settransaction_date(e.target.value) } placeholder="Enter Transaction Date"  />
                                        {formErrors.transaction_date && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.transaction_date}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                          </div>
                          

                          <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="card" style={{ borderRadius: '5px', backgroundColor: 'rgb(225, 246, 247)' }}>
                                <div class="card-block">

                                    <h5>Pay Mode 1</h5>

                                  <div class="row">
                                  <div class="col-sm-12 col-xl-12 m-b-30">
                                    <div class="row">
                                      <div class="col-sm-4">
                                        <h6 class="text-xl-right">
                                        Amount<span class="text-danger">*</span>
                                        </h6>
                                      </div>
                                      <div class="col-sm-8">
                                      <input class="form-control" type="text" value={payment1} 
                                      onInput={(e) => {setpayment1(e.target.value); setPayment2(e.target.value); } }
                                      placeholder=""   />
                                        {formErrors.payment1 && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.payment1}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  </div>
                               
                                <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    Pay Mode<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_cash">
                                        <input
                                          type="radio"
                                          name="paymode"
                                          value={"cash"}
                                          id={"title_cash"}
                                          checked={paymode === 'cash' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setpaymode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Cash
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_chque">
                                        <input
                                          type="radio"
                                          name="paymode"
                                          id={"title_chque"}
                                          value={"cheque"}
                                          checked={paymode === 'cheque' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setpaymode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Cheque
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3">
                                      <label for="title_online">
                                        <input
                                          type="radio"
                                          name="paymode"
                                          value={"online"}
                                          checked={paymode === 'online' ? 'checked' : ''}
                                          id={"title_online"}
                                          onChange={(e) =>
                                            setpaymode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Online Tr
                                      </label>
                                    </div>

                                    <div class="radio radio-inline col-xs-3">
                                      <label for="title_saving">
                                        <input
                                          type="radio"
                                          name="paymode"
                                          value={"saving"}
                                          checked={paymode === 'saving' ? 'checked' : ''}
                                          id={"title_saving"}
                                          onChange={(e) =>
                                            setpaymode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i> Saving Ac.
                                      </label>
                                    </div>
                                    
                                  </div>
                                  {formErrors.paymode && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.paymode}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div class="row" style={{ display: paymode === 'cheque' ? '' : 'none' }}>
                                <div class="col-sm-12 col-xl-12 m-b-30">
                                    <div class="row">
                                      <div class="col-sm-4">
                                        <h6 class="text-xl-right">
                                          Bank Name<span class="text-danger">*</span>
                                        </h6>
                                      </div>
                                      <div class="col-sm-8">
                                      <select
                                          class="form-control m-b"
                                          name="bank_name"
                                          value={bank_name}
                                          onChange={(e) =>
                                            setbank_name(e.target.value)
                                          }
                                        >
                                          <option value={''} disabled>Select bank_name</option>
                                          {bankList.map((bankdata) => (
                                            <option
                                              value={bankdata.id}
                                              selected={
                                                bankdata.id === bank_name
                                                  ? "selected"
                                                  : ""
                                              }
                                            >
                                              {bankdata.name}

                                            </option>
                                          ))}
                                        </select>
                                        {formErrors.bank_name && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.bank_name}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-sm-12 col-xl-12 m-b-30">
                                    <div class="row">
                                      <div class="col-sm-4">
                                        <h6 class="text-xl-right">
                                        Cheque No.<span class="text-danger">*</span>
                                        </h6>
                                      </div>
                                      <div class="col-sm-8">
                                        <input
                                          class="form-control"
                                          type="text"
                                          value={cheque_no}
                                          onChange={(e) =>
                                            setcheque_no(e.target.value)
                                          }
                                          placeholder="Enter Cheque"
                                        />
                                        {formErrors.cheque_no && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.cheque_no}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-sm-12 m-b-30">
                                    <div class="row">
                                      <div class="col-sm-4">
                                        <h6 class="text-xl-right">
                                          Cheque Date<span class="text-danger">*</span>
                                        </h6>
                                      </div>
                                      <div class="col-sm-8">
                                        <input
                                          class="form-control"
                                          type="date"
                                          value={cheque_date}
                                          onChange={(e) =>
                                            setcheque_date(e.target.value)
                                          }
                                          placeholder="Enter Cheque Date"
                                        />
                                        {formErrors.cheque_date && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.cheque_date}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row" style={{ display: paymode === 'online' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    Transfar date<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <input
                                    class="form-control"
                                    type="date"
                                    value={transfer_date}
                                    onChange={(e) =>
                                      settransfer_date(e.target.value)
                                    }
                                    placeholder="Enter Transfar date"
                                  />
                                  {formErrors.transfer_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.transfer_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    UTR/ Transfar No.<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={utr_no}
                                    onChange={(e) =>
                                      setutr_no(e.target.value)
                                    }
                                    placeholder="Enter Transfar No."
                                  />
                                  {formErrors.utr_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.utr_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                         

                          

                          <div class="row" style={{ display: paymode === 'online' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    Transfar Mode<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_IMPS">
                                        <input
                                          type="radio"
                                          name="transfer_mode"
                                          value={"IMPS"}
                                          id={"title_IMPS"}
                                          checked={transfer_mode === 'IMPS' ? 'checked' : ''}
                                          onChange={(e) =>
                                            settransfer_mode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>IMPS
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_VPA">
                                        <input
                                          type="radio"
                                          name="transfer_mode"
                                          id={"title_VPA"}
                                          value={"VPA"}
                                          checked={transfer_mode === 'VPA' ? 'checked' : ''}
                                          onChange={(e) =>
                                            settransfer_mode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>VPA
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3">
                                      <label for="title_NEFT">
                                        <input
                                          type="radio"
                                          name="transfer_mode"
                                          value={"NEFT/RTGS"}
                                          checked={transfer_mode === 'NEFT/RTGS' ? 'checked' : ''}
                                          id={"title_NEFT"}
                                          onChange={(e) =>
                                            settransfer_mode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>NEFT/RTGS
                                      </label>
                                    </div>
                                    
                                  </div>
                                  {formErrors.transfer_mode && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.transfer_mode}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl- m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                  Credited in Nidhi Account <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="nidhi_acc_yes">
                                        <input
                                          type="radio"
                                          name="creadited_nidhi_acc"
                                          value={"yes"}
                                          id={"nidhi_acc_yes"}
                                          checked={creadited_nidhi_acc === 'yes' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setcreadited_nidhi_acc(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Yes
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="nidhi_ac_no">
                                        <input
                                          type="radio"
                                          name="creadited_nidhi_acc"
                                          id={"nidhi_ac_no"}
                                          value={"no"}
                                          checked={creadited_nidhi_acc === 'no' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setcreadited_nidhi_acc(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>No
                                      </label>
                                    </div>
                                  
                                    
                                  </div>
                                  {formErrors.creadited_nidhi_acc && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.creadited_nidhi_acc}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                         

                          <div class="row" style={{ display: paymode === 'saving' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                  Select Saving Account<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                <select
                                    class="form-control m-b"
                                    name="saving_account"
                                    value={saving_account}
                                    onChange={(e) =>
                                      setsaving_account(e.target.value)
                                    }
                                  >
                                    <option value={' '} >Select Saving Account</option>
                                    {pramoterList.map((aBranchData) => (
                                      <option
                                        value={aBranchData.id}
                                        selected={
                                          aBranchData.id === saving_account
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {aBranchData.account_no}
                                      </option>
                                    ))}
                                  </select>
                                  {formErrors.saving_account && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.saving_account}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                                </div>
                              </div>
                            </div>
                            
                            {/* 2222222222222222222 */}
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="card" style={{ borderRadius: '5px', backgroundColor: 'rgb(225, 246, 247)' }}>
                                <div class="card-block">

                                    <h5>Pay Mode 2</h5>

                                  <div class="row">
                                  <div class="col-sm-12 col-xl-12 m-b-30">
                                    <div class="row">
                                      <div class="col-sm-4">
                                        <h6 class="text-xl-right">
                                        Amount<span class="text-danger">*</span>
                                        </h6>
                                      </div>
                                      <div class="col-sm-8">
                                      <input class="form-control" type="text" value={payment2} onChange={(e) => setpayment2(e.target.value) } placeholder=""   />
                                        {formErrors.payment2 && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.payment2}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  </div>
                               
                                <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    Pay Mode<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_cash2">
                                        <input
                                          type="radio"
                                          name="paymode2"
                                          value={"cash"}
                                          id={"title_cash2"}
                                          checked={paymode2 === 'cash' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setpaymode2(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Cash
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_chque2">
                                        <input
                                          type="radio"
                                          name="paymode2"
                                          id={"title_chque2"}
                                          value={"cheque"}
                                          checked={paymode2 === 'cheque' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setpaymode2(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Cheque
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3">
                                      <label for="title_online2">
                                        <input
                                          type="radio"
                                          name="paymode2"
                                          value={"online"}
                                          checked={paymode2 === 'online' ? 'checked' : ''}
                                          id={"title_online2"}
                                          onChange={(e) =>
                                            setpaymode2(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Online Tr
                                      </label>
                                    </div>

                                    <div class="radio radio-inline col-xs-3">
                                      <label for="title_saving2">
                                        <input
                                          type="radio"
                                          name="paymode2"
                                          value={"saving"}
                                          checked={paymode2 === 'saving' ? 'checked' : ''}
                                          id={"title_saving2"}
                                          onChange={(e) =>
                                            setpaymode2(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i> Saving Ac.
                                      </label>
                                    </div>
                                    
                                  </div>
                                  {formErrors.paymode2 && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.paymode2}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div class="row" style={{ display: paymode2 === 'cheque' ? '' : 'none' }}>
                                <div class="col-sm-12 col-xl-12 m-b-30">
                                    <div class="row">
                                      <div class="col-sm-4">
                                        <h6 class="text-xl-right">
                                          Bank Name<span class="text-danger">*</span>
                                        </h6>
                                      </div>
                                      <div class="col-sm-8">
                                      <select
                                          class="form-control m-b"
                                          name="bank_name2"
                                          value={bank_name2}
                                          onChange={(e) =>
                                            setbank_name2(e.target.value)
                                          }
                                        >
                                          <option value={''} disabled>Select bank_name2</option>
                                          {bankList.map((bankdata) => (
                                            <option
                                              value={bankdata.id}
                                              selected={
                                                bankdata.id === bank_name2
                                                  ? "selected"
                                                  : ""
                                              }
                                            >
                                              {bankdata.name}
                                              
                                            </option>
                                          ))}
                                        </select>
                                        {formErrors.bank_name2 && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.bank_name2}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-sm-12 col-xl-12 m-b-30">
                                    <div class="row">
                                      <div class="col-sm-4">
                                        <h6 class="text-xl-right">
                                        Cheque No.<span class="text-danger">*</span>
                                        </h6>
                                      </div>
                                      <div class="col-sm-8">
                                        <input
                                          class="form-control"
                                          type="text"
                                          value={cheque_no2}
                                          onChange={(e) =>
                                            setcheque_no2(e.target.value)
                                          }
                                          placeholder="Enter Cheque"
                                        />
                                        {formErrors.cheque_no2 && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.cheque_no2}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-sm-12 m-b-30">
                                    <div class="row">
                                      <div class="col-sm-4">
                                        <h6 class="text-xl-right">
                                          Cheque Date<span class="text-danger">*</span>
                                        </h6>
                                      </div>
                                      <div class="col-sm-8">
                                        <input
                                          class="form-control"
                                          type="date"
                                          value={cheque_date2}
                                          onChange={(e) =>
                                            setcheque_date2(e.target.value)
                                          }
                                          placeholder="Enter Cheque Date"
                                        />
                                        {formErrors.cheque_date2 && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.cheque_date2}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row" style={{ display: paymode2 === 'online' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    Transfar date<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <input
                                    class="form-control"
                                    type="date"
                                    value={transfer_date2}
                                    onChange={(e) =>
                                      settransfer_date2(e.target.value)
                                    }
                                    placeholder="Enter Transfar date"
                                  />
                                  {formErrors.transfer_date2 && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.transfer_date2}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    UTR/ Transfar No.<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={utr_no2}
                                    onChange={(e) =>
                                      setutr_no2(e.target.value)
                                    }
                                    placeholder="Enter Transfar No."
                                  />
                                  {formErrors.utr_no2 && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.utr_no2}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                         

                          

                          <div class="row" style={{ display: paymode2 === 'online' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    Transfar Mode<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_IMPS">
                                        <input
                                          type="radio"
                                          name="transfer_mode2"
                                          value={"IMPS"}
                                          id={"title_IMPS"}
                                          checked={transfer_mode2 === 'IMPS' ? 'checked' : ''}
                                          onChange={(e) =>
                                            settransfer_mode2(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>IMPS
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_VPA">
                                        <input
                                          type="radio"
                                          name="transfer_mode2"
                                          id={"title_VPA"}
                                          value={"VPA"}
                                          checked={transfer_mode2 === 'VPA' ? 'checked' : ''}
                                          onChange={(e) =>
                                            settransfer_mode2(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>VPA
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3">
                                      <label for="title_NEFT">
                                        <input
                                          type="radio"
                                          name="transfer_mode2"
                                          value={"NEFT/RTGS"}
                                          checked={transfer_mode2 === 'NEFT/RTGS' ? 'checked' : ''}
                                          id={"title_NEFT"}
                                          onChange={(e) =>
                                            settransfer_mode2(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>NEFT/RTGS
                                      </label>
                                    </div>
                                    
                                  </div>
                                  {formErrors.transfer_mode2 && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.transfer_mode2}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl- m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                  Credited in Nidhi Account <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="nidhi_acc_yes">
                                        <input
                                          type="radio"
                                          name="creadited_nidhi_acc2"
                                          value={"yes"}
                                          id={"nidhi_acc_yes"}
                                          checked={creadited_nidhi_acc2 === 'yes' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setcreadited_nidhi_acc2(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Yes
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="nidhi_ac_no">
                                        <input
                                          type="radio"
                                          name="creadited_nidhi_acc2"
                                          id={"nidhi_ac_no"}
                                          value={"no"}
                                          checked={creadited_nidhi_acc2 === 'no' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setcreadited_nidhi_acc2(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>No
                                      </label>
                                    </div>
                                  
                                    
                                  </div>
                                  {formErrors.creadited_nidhi_acc2 && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.creadited_nidhi_acc2}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                         

                          <div class="row" style={{ display: paymode2 === 'saving' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                  Select Saving Account<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                <select
                                    class="form-control m-b"
                                    name="saving_account2"
                                    value={saving_account2}
                                    onChange={(e) =>
                                      setsaving_account2(e.target.value)
                                    }
                                  >
                                    <option value={' '} >Select Saving Account</option>
                                    {pramoterList.map((aBranchData) => (
                                      <option
                                        value={aBranchData.id}
                                        selected={
                                          aBranchData.id === saving_account2
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {aBranchData.account_no}
                                      </option>
                                    ))}
                                  </select>
                                  {formErrors.saving_account2 && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.saving_account2}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                                </div>
                              </div>
                            </div>

                          </div>


                          

                         
                        
                          






                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                              <button
                                class="btn btn-success btn-square"
                                type="submit"
                                disabled={submitting}
                              >
                                OPEN FD ACCOUNT
                              </button>{" "}
                              <a href="/fixed-deposit-account" class="btn btn-danger btn-square">Cancel</a>
                              &nbsp;
                              <button
                                class="btn btn-warning btn-square"
                                type="reset"
                              >
                                Reset
                              </button>
                             

                              <ToastContainer className="toast-container" />
                            </div>
                          </div>


                        </form>
                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
