import React, { useState, useEffect } from "react";
import DashboardLayout from "../../dashboardLayout";
// import axios from "axios";
// import { API_BASE_URL, API_HEADERS } from "../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { deleteConfirmation, FirstLetterCapitalize, dataDecrypt, formatDate, dataEncript } from "../../../helper/commonHelpers";

import { formPost, getAssociateList, getDataListBytable, getActiveBranchList, getRolePermissionList } from '../../../helper/listModal';

//formatDate
function CompanyDetails() {
  const [submitting, setSubmitting] = useState(false);
  const { advisorID }   = useParams();
  const [details, setdetails] = useState([]);


  const [associateArrList, setassociateArrList] = useState([]);

  const [rankList, setrankList] = useState([]);
  const [activeBranchArr, setactiveBranchArr] = useState([]);
  const [rolePermissionArr, setrolePermissionArr] = useState([]);



  const [formErrors, setFormErrors] = useState({
    member_id: "",
  });

 

const[associate_emp_profile, setassociate_emp_profile] = useState('');
const[associate_rank, setassociate_rank] = useState('');
const[associate_sup, setassociate_sup] = useState('');
const[enroll_date, setenroll_date] = useState('');
const[first_name, setfirst_name] = useState('');
const[last_name, setlast_name] = useState('');
const[login_user_name, setlogin_user_name] = useState('');
const[email, setemail] = useState('');
const[mobile_no, setmobile_no] = useState('');
const[dob, setdob] = useState('');
const[father_name, setfather_name] = useState('');
const[husband_wife_name, sethusband_wife_name] = useState('');
const[pan_no, setpan_no] = useState('');
const[aadhar_no, setaadhar_no] = useState('');
const[address, setaddress] = useState('');
const[back_date_entry_days, setback_date_entry_days] = useState('');
const[premission_roles, setpremission_roles] = useState('');
const[branch, setbranch] = useState('');
const[access_type, setaccess_type] = useState('admin');
const[login_on_holidays, setlogin_on_holidays] = useState('yes');
const[searchable_acc, setsearchable_acc] = useState('no');
const[active, setactive] = useState('yes');
const[nominee_name, setnominee_name] = useState('');
const[nominee_relation, setnominee_relation] = useState('');
const[nominee_address, setnominee_address] = useState('');



  useEffect(() => {

    getAssociateList().then(data => {
    //   console.log(data);
      setassociateArrList(data);
    }).catch(error => {
        // console.error('Error fetching associate list:', error);
    });


    getDataListBytable('tbl_associate_rank', ``).then(data => {
      // console.log(data);
      setrankList(data);
    }).catch(error => {
        // console.error('Error fetching associate list:', error);
    });

    getActiveBranchList().then(data => {
      // console.log(data);
      setactiveBranchArr(data);
    }).catch(error => {
        // console.error('Error fetching associate list:', error);
    });


    getRolePermissionList().then(data => {
      // console.log(data);
      setrolePermissionArr(data);
    }).catch(error => {
        // console.error('Error fetching associate list:', error);
    });



    getAssociateList(dataDecrypt(advisorID)).then(data => {
        // console.log(data);
        setdetails(data);

        setassociate_emp_profile(data.associate_emp_profile);
        setassociate_rank(data.associate_rank);
        setassociate_sup(data.associate_sup);
        setenroll_date(data.enroll_date? formatDate(data.enroll_date, 'YMD'):'');

        setfirst_name(data.first_name);
        setlast_name(data.last_name);
        setlogin_user_name(data.login_user_name);
        setemail(data.email);
        setmobile_no(data.mobile_no);
        setdob(data.dob?formatDate(data.dob, 'YMD'):'');

        setfather_name(data.father_name);
        sethusband_wife_name(data.husband_wife_name);
        setpan_no(data.pan_no);
        setaadhar_no(data.aadhar_no);
        setaddress(data.address);
        setback_date_entry_days(data.back_date_entry_days);
        setpremission_roles(data.premission_roles);
        setbranch(data.branch);
        setaccess_type(data.access_type);
        setlogin_on_holidays(data.login_on_holidays);
        setsearchable_acc(data.searchable_acc);
        setactive(data.active);
        setnominee_name(data.nominee_name);
        setnominee_relation(data.nominee_relation);
        setnominee_address(data.nominee_address);

      }).catch(error => {
          // console.error('Error fetching associate list:', error);
      });



    // setenroll_date(getCurrentDate());


  }, [advisorID]);




  const updateDetails = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));

    const formData = {

     

            id: dataDecrypt(advisorID),

            associate_emp_profile: associate_emp_profile,
            associate_rank: associate_rank,
            associate_sup: associate_sup,
            enroll_date: enroll_date,
            first_name: first_name,
            last_name: last_name,
            login_user_name: login_user_name,
            email: email,
            mobile_no: mobile_no,
            dob: dob,
            father_name: father_name,
            husband_wife_name: husband_wife_name,
            pan_no: pan_no,
            aadhar_no: aadhar_no,
            address: address,
            back_date_entry_days: back_date_entry_days,
            premission_roles: premission_roles,
            branch: branch,
            access_type: access_type,
            login_on_holidays: login_on_holidays,
            searchable_acc: searchable_acc,
            active: active,
            nominee_name: nominee_name,
            nominee_relation: nominee_relation,
            nominee_address: nominee_address,


    };

  console.log(formData);
     


      if (!enroll_date) {
        errors.enroll_date = 'Enrollment Date is required.';
      }
      
      if (!first_name) {
        errors.first_name = 'First Name is required.';
      }

      if (!login_user_name) {
        errors.login_user_name = 'Login User Name is required.';
      }

      if (!mobile_no) {
        errors.mobile_no = 'Mobile No. is required.';
      }

      if (!back_date_entry_days) {
        errors.back_date_entry_days = 'Back Date Entry Days is required.';
      }

      if (!premission_roles) {
        errors.premission_roles = 'Permissions / Roles  is required.';
      }

      if (!branch) {
        errors.branch = 'Branch is required.';
      }




    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {

        const confirmed = await deleteConfirmation('to add NEW rank?');
        if(confirmed===true)
        {

          formPost('api-associates/add-associate', formData).then(data => {

            toast.success(data["message"]);
            setTimeout(() => {
              window.location.href = "/associate-advisor";
            }, 2000);

              setSubmitting(false);
          }).catch(error => {
              setSubmitting(false);
              toast.error(error);
          });



    }
    setSubmitting(false);
    } else {
      setSubmitting(false);
    } 
  };


  

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4> Edit ASSOCIATE/ ADVISOR -  {details && details.first_name && (
                                    <>{details.first_name}</>
                                    )}  
                                    &nbsp;
                                    {details && details.last_name && (
                                    <>{details.last_name}</>
                                    )}  
                                     </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                            <a href="/associate-advisor">Associate/ Advisor </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                            <a href={`/associate-advisor-view/${dataEncript(details.id)}`}  > {details && details.first_name && (
                                    <>{details.first_name}</>
                                    )}  
                                    &nbsp;
                                    {details && details.last_name && (
                                    <>{details.last_name}</>
                                    )} </a>
                        </li>

                        
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> Edit</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="card" style={{borderTop:'3px solid red'}} >
                    
                        {/* <h4 class="m-4">CHANGE ACCOUNT INFO</h4> */}
                       <hr class="p-0 m-0"/>

                     

                      <div class="card-block">
                      <form onSubmit={updateDetails}>


                      <div class="row"  >
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Associate Employee Profile (if any) <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                  <select
                                    class="form-control m-b"
                                    name="associate_emp_profile"
                                    value={associate_emp_profile}
                                    onChange={(e) =>
                                      setassociate_emp_profile(e.target.value)
                                    }
                                  >

                                    <option value={' '}>Select - Associate Employee Profile</option>

                                     {associateArrList.map((assData) => (
                                      <option
                                        value={assData.id}
                                        selected={
                                          assData.id === associate_emp_profile
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {FirstLetterCapitalize(assData.associate_code)} - 

                                        {FirstLetterCapitalize(assData.first_name)} &nbsp;
                                        {FirstLetterCapitalize(assData.first_name)}

                                      </option>
                                    ))}
                                 

                                  </select>

                                  {formErrors.associate_emp_profile && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.associate_emp_profile}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="row"  >
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Associate Rank <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                  <select
                                    class="form-control m-b"
                                    name="associate_rank"
                                    value={associate_rank}
                                    onChange={(e) =>
                                      setassociate_rank(e.target.value)
                                    }
                                  >
                                    <option value={' '}>Select - Rank </option>

                                     {rankList.map((rankD) => (
                                      <option
                                        value={rankD.id}
                                        selected={
                                          rankD.id === associate_rank
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {FirstLetterCapitalize(rankD.rank_display_position)} - 
                                        {FirstLetterCapitalize(rankD.rank_name)} 
                                      </option>
                                    ))}
                                  </select>

                                  {formErrors.associate_rank && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.associate_rank}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Associate Supervisor <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <select
                                    class="form-control m-b"
                                    name="associate_sup"
                                    value={associate_sup}
                                    onChange={(e) =>
                                      setassociate_sup(e.target.value)
                                    }
                                  >
                                    <option value={' '}>Select - Associate Supervisor </option>


                                     {associateArrList.map((assData) => (
                                      <option
                                        value={assData.id}
                                        selected={
                                          assData.id === associate_sup
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {FirstLetterCapitalize(assData.associate_code)} - 
                                        {FirstLetterCapitalize(assData.first_name)} &nbsp;
                                        {FirstLetterCapitalize(assData.first_name)}
                                      </option>
                                    ))}
                                  </select>

                                  {formErrors.associate_sup && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.associate_sup}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                          </div>




                          <div class="row" >
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Enrollment Date<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input class="form-control" type="date" value={enroll_date} onChange={(e) => setenroll_date(e.target.value) } placeholder="Enter Enrollment Date" />

                                  {formErrors.enroll_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.enroll_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" >
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  First Name<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input class="form-control" type="text" value={first_name} onChange={(e) => setfirst_name(e.target.value) } placeholder="Enter First Name" />

                                  {formErrors.first_name && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.first_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Last Name
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input class="form-control" type="text" value={last_name} onChange={(e) => setlast_name(e.target.value) } placeholder="Enter Last Name" />

                                  {formErrors.last_name && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.last_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            
                          </div>


                          <div class="row" >
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Login User Name <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input class="form-control" type="text" value={login_user_name} onChange={(e) => setlogin_user_name(e.target.value) } placeholder="Enter Login User Name " />

                                  {formErrors.login_user_name && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.login_user_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Email
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input class="form-control" type="text" value={email} onChange={(e) => setemail(e.target.value) } placeholder="Enter Email" />

                                  {formErrors.email && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.email}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            
                          </div>


                          <div class="row" >
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Mobile No.<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input class="form-control" type="text" value={mobile_no} onChange={(e) => setmobile_no(e.target.value) } placeholder="Enter Mobile No. " />

                                  {formErrors.mobile_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.mobile_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Date of Birth
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input class="form-control" type="date" value={dob} onChange={(e) => setdob(e.target.value) } placeholder="Enter Date of Birth" />

                                  {formErrors.dob && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.dob}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            
                          </div>


                          <div class="row" >
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Father Name
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input class="form-control" type="text" value={father_name} onChange={(e) => setfather_name(e.target.value) } placeholder="Enter Father Name " />

                                  {formErrors.father_name && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.father_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Husband/ Wife Name
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input class="form-control" type="text" value={husband_wife_name} onChange={(e) => sethusband_wife_name(e.target.value) } placeholder="Enter Spouse Name" />

                                  {formErrors.husband_wife_name && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.husband_wife_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            
                          </div>

                          <div class="row" >
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  PAN No.
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input class="form-control" type="text" value={pan_no} onChange={(e) => setpan_no(e.target.value) } placeholder="Enter PAN No. " />

                                  {formErrors.pan_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.pan_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Aadhaar No.
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input class="form-control" type="text" value={aadhar_no} onChange={(e) => setaadhar_no(e.target.value) } placeholder="Enter Aadhaar No." />

                                  {formErrors.aadhar_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.aadhar_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            
                          </div>

                          <div class="row" >
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Address
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <textarea class="form-control" value={address} onChange={(e) => setaddress(e.target.value) } ></textarea>

                                  {formErrors.address && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.address}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            
                          </div>

                          <div class="row" >
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Back Date Entry Days<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input class="form-control" type="text" value={back_date_entry_days} onChange={(e) => setback_date_entry_days(e.target.value) } placeholder="Enter Back Date Entry Days" />

                                  {formErrors.back_date_entry_days && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.back_date_entry_days}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            
                          </div>


                          <div class="row"  >
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Permissions / Roles <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                  <select
                                    class="form-control m-b"
                                    name="premission_roles"
                                    value={premission_roles}
                                    onChange={(e) =>
                                      setpremission_roles(e.target.value)
                                    }
                                  >

                                    <option value={' '}>Select - Permissions / Roles </option>


                                     {rolePermissionArr.map((permissionD) => (
                                      <option
                                        value={permissionD.id}
                                        selected={
                                          permissionD.id === premission_roles
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {FirstLetterCapitalize(permissionD.role_name)}
                                      </option>
                                    ))}
                                  </select>

                                  {formErrors.premission_roles && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.premission_roles}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Branch<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <select
                                    class="form-control m-b"
                                    name="branch"
                                    value={branch}
                                    onChange={(e) =>
                                      setbranch(e.target.value)
                                    }
                                  >

                                    <option value={' '}>Select - Branch  </option>

                                     {activeBranchArr.map((branchData) => (
                                      <option
                                        value={branchData.id}
                                        selected={
                                          branchData.id === branch
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {FirstLetterCapitalize(branchData.branch_name)} 
                                      </option>
                                    ))}
                                  </select>

                                  {formErrors.branch && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.branch}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                          </div>


                          <div class="row" >
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Access Type<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_admin">
                                        <input
                                          type="radio"
                                          name="access_type"
                                          value={"admin"}
                                          id={"title_admin"}
                                          checked={access_type === 'admin' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setaccess_type(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Admin app 
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_agent">
                                        <input
                                          type="radio"
                                          name="access_type"
                                          id={"title_agent"}
                                          value={"agent"}
                                          checked={access_type === 'agent' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setaccess_type(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Agent
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_both">
                                        <input
                                          type="radio"
                                          name="access_type"
                                          id={"title_both"}
                                          value={"both"}
                                          checked={access_type === 'both' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setaccess_type(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Both
                                      </label>
                                    </div>
                                  
                                    
                                  </div>
                                  {formErrors.access_type && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.access_type}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="row" >
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Login on Holidays<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="login_on_holidays_yes">
                                        <input
                                          type="radio"
                                          name="login_on_holidays"
                                          value={"yes"}
                                          id={"login_on_holidays_yes"}
                                          checked={login_on_holidays === 'yes' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setlogin_on_holidays(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Yes 
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="login_on_holidays_no">
                                        <input
                                          type="radio"
                                          name="login_on_holidays"
                                          id={"login_on_holidays_no"}
                                          value={"no"}
                                          checked={login_on_holidays === 'no' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setlogin_on_holidays(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>No
                                      </label>
                                    </div>
                                
                                  </div>
                                  {formErrors.login_on_holidays && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.login_on_holidays}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Searchable Accounts<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="searchable_acc_yes">
                                        <input
                                          type="radio"
                                          name="searchable_acc"
                                          value={"yes"}
                                          id={"searchable_acc_yes"}
                                          checked={searchable_acc === 'yes' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setsearchable_acc(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Yes  All
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="searchable_acc_no">
                                        <input
                                          type="radio"
                                          name="searchable_acc"
                                          id={"searchable_acc_no"}
                                          value={"no"}
                                          checked={searchable_acc === 'no' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setsearchable_acc(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>No - Only Assigned
                                      </label>
                                    </div>
                                
                                  </div>
                                  {formErrors.searchable_acc && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.searchable_acc}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                          </div>



                          <div class="row" >
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Active <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="active_yes">
                                        <input
                                          type="radio"
                                          name="active"
                                          value={"yes"}
                                          id={"active_yes"}
                                          checked={active === 'yes' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setactive(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Yes 
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="active_no">
                                        <input
                                          type="radio"
                                          name="active"
                                          id={"active_no"}
                                          value={"no"}
                                          checked={active === 'no' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setactive(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>No
                                      </label>
                                    </div>
                                
                                  </div>
                                  {formErrors.active && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.active}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                          </div>



                       {/* ----------------8888888888888888888------------- */}
                    
                       <div class="row" >
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Nominee Name
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input class="form-control" type="text" value={nominee_name} onChange={(e) => setnominee_name(e.target.value) } placeholder="Enter Nominee Name" />

                                  {formErrors.nominee_name && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.nominee_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Nominee Relation
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input class="form-control" type="text" value={nominee_relation} onChange={(e) => setnominee_relation(e.target.value) } placeholder="Enter Nominee Relation" />

                                  {formErrors.nominee_relation && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.nominee_relation}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            
                          </div>

                          <div class="row" >
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Nominee Address
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <textarea class="form-control" value={nominee_address} onChange={(e) => setnominee_address(e.target.value) } ></textarea>
                                  
                                  {formErrors.nominee_address && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.nominee_address}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            
                          </div>








                      
                        
                      

                         


                        <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                            <button
                              class="btn btn-success btn-square"
                              type="submit"
                              disabled={submitting}
                            >
                              UPDATE ASSOCIATE/ ADVISOR
                            </button>{" "}
                       

                            <a href={`/associate-advisor-view/${dataEncript(details.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                          

                            <ToastContainer className="toast-container" />
                          </div>
                        </div>


                      </form>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-5">
                  
                  </div>
                </div>


                

              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
          <ToastContainer className="toast-container" />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
