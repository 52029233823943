import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../dashboardLayout';
import { useParams } from 'react-router-dom'; 

import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "../../../../config"; // Importing constants directly
// import $ from 'jquery';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



import {
      
      FirstLetterCapitalize,
      dataDecrypt,
      formatDate,
      formattedAmount,
      dataEncript,
      deleteConfirmation,
      infoAlert
      
      
    } from '../../../helper/commonHelpers'; 

function AdminLayout() {
    const { accountID }   = useParams();
  const [listDetails, setlistDetails] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [commentsList, setcommentsList] = useState([]);
  const [passbooksList, setpassbooksList] = useState([]);
  const [documents_list, setdocuments_list] = useState([]);
  const [transaction_list, settransaction_list] = useState([]);






  const [is_sms, setis_sms]               = useState('no');
  const [deduct_charges, setdeduct_charges] = useState('no');
  const [account_hold, setaccount_hold] = useState('no');


  const [memberList, setmemberList] = useState([]);
  const [activeBranchList, setactiveBranchList] = useState([]);
  const [member_id, setmember_id] = useState('');
  const [old_account_no, setold_account_no] = useState('');
  const [branch_id, setbranch_id] = useState('');
  const [open_date, setopen_date] = useState('');
  const [associate_id, setassociate_id] = useState('');
  const [lock_amount, setlock_amount] = useState('');









  const [formErrors, setFormErrors] = useState({
    member_id: '',
  });

    useEffect(() => {


      axios.get(API_BASE_URL + "api-member/get-member-list", {
        headers: API_HEADERS,
      }).then((response) => {
        if (response.data["status"] > 0) {
        setmemberList(response.data["data"]);
        }
      });

      
      axios.get(API_BASE_URL + "api-company/get-active-branch-list", {
        headers: API_HEADERS,
      }).then((response) => {
        if (response.data["status"] > 0) {
        setactiveBranchList(response.data["data"]);
        }
      }).catch((error) => {
        toast.error("Failed to fetch data!");
      });


            axios.get(API_BASE_URL + "api-common/get-account-list?id="+dataDecrypt(accountID), {
                headers: API_HEADERS,
              }).then((response) => {
          
          
                if (response.data["status"] > 0) {

                  console.log(response.data["data"]);

                  setlistDetails(response.data["data"]);
                  setdeduct_charges(response.data["data"].deduct_charges);
                  setaccount_hold(response.data["data"].account_hold);
                  setis_sms(response.data["data"].is_sms);

                  setmember_id(response.data["data"].member_id);
                  setopen_date((response.data["data"].open_date? formatDate(response.data["data"].open_date, 'YMD'):''));
                  setold_account_no(response.data["data"].old_account_no);
                  setbranch_id(response.data["data"].branch_id);
                  setassociate_id(response.data["data"].associate_id);
                  setlock_amount(response.data["data"].lock_amount);

                  if (response.data["data"].comments && (response.data["data"].comments).length > 0) {
                      setcommentsList(response.data["data"].comments);
                  }

                  if (response.data["data"].passbooks && (response.data["data"].passbooks).length > 0) {
                    setpassbooksList(response.data["data"].passbooks);
                }

                if (response.data["data"].documents_list && (response.data["data"].documents_list).length > 0) {
                    setdocuments_list(response.data["data"].documents_list);
                }

                if (response.data["data"].transaction_list && (response.data["data"].transaction_list).length > 0) {
                    settransaction_list(response.data["data"].transaction_list);
                }



                }

                console.log(response.data["data"]);
          
              }).catch((error) => {
                toast.error("Failed to fetch data!");
              });


  
    }, [accountID]);



    



  
// update kyc status /updateSettingstatus //updateKYCstatus




const updateSetting = async (column) => {
  // e.preventDefault();
  setSubmitting(true);
  let column_val = '';
  if(column==='is_sms')
  {
    column_val = is_sms;
  }
   else if(column==='account_hold')
  {
    column_val = account_hold;
  }
  else if(column==='deduct_charges')
  {
    column_val = deduct_charges;
  }


  const confirmed = await deleteConfirmation('Are you sure you want to change setting status?');
  if(confirmed===true)
  {


      const formData = {
        tablename:'tbl_account',  // table name
        where_coloumn_name:'id', // which collumn name you want to  
        where_value:dataDecrypt(accountID), // column value 
        set_coloumn_name:column, // which collumn name you want to update
        set_value:((column_val==='no')?'yes':'no'), // collumn value
        // set_value:account_hold?account_hold:'no', // collumn value

      };



      console.log(formData);
      console.log(column_val);


          axios.post(API_BASE_URL + "api-common/update-status", formData, {
          headers: {
              ...API_HEADERS,
          },
          }).then((response) => {
            if (response.data["status"] > 0) {
                toast.success(response.data["message"]);
                setTimeout(() => {
                window.location.reload();
                }, 1000);
              } else {
                  toast.error(response.data["message"]);
              }
          }).catch((error) => {
              toast.error("Failed to submition!");
          }).finally(() => {
              setTimeout(() => {
                  setSubmitting(false);
              }, 300);
          }); // close query hare
  }


}


const updateDetails = async (column, msg) => {
  // e.preventDefault();
  setSubmitting(true);
let column_val = '';
  if(column==='member_id')
  {
    column_val = member_id;
  }
  else if(column==='old_account_no')
  {
    column_val = old_account_no;
  }
  else if(column==='branch_id')
  {
    column_val = branch_id;
  }
  else if(column==='open_date')
  {
    column_val = open_date;
  }
  else if(column==='associate_id')
  {
    column_val = associate_id;
  }
  else if(column==='lock_amount')
  {
    column_val = lock_amount;
  }
 


  let errors = {};
  const formData = {
      tablename:'tbl_account',  // table name
      where_coloumn_name:'id', // which collumn name you want to  
      where_value:dataDecrypt(accountID), // column value 
      set_coloumn_name:column, // which collumn name you want to update
      set_value:column_val, // collumn value
  };

  if (!column_val) {
      errors[column] = msg+' is required.';
    }

  setFormErrors(errors);
  if (Object.keys(errors).length === 0) {
      const confirmed = await deleteConfirmation('Are you sure you want to change?');
      if(confirmed===true)
      {
              axios.post(API_BASE_URL + "api-common/update-status", formData, {
              headers: {
                  ...API_HEADERS,
              },
              })
              .then((response) => {
              if (response.data["status"] > 0) {
                  toast.success(response.data["message"]);
                  setTimeout(() => {
                     window.location.reload();
                  }, 2300);
              } else {
                  toast.error(response.data["message"]);
              }
              })
              .catch((error) => {
                  toast.error("Failed to submition!");
              })
              .finally(() => {
              setTimeout(() => {
                  setSubmitting(false);
              }, 300);
              }); // close query hare
      }
      else {
        setSubmitting(false);
      }
  } else {
    setSubmitting(false);
  } // hare close error
};


    // useEffect(() => {
    //   const timeout = setTimeout(() => {
    //     if (window.tableDomJquery) {
    //       window.tableDomJquery();
    //     }
    //   }, 1000); // Timeout of 3000 milliseconds (3 seconds)
  
    //   return () => clearTimeout(timeout); // Clear the timeout on component unmount
    // }, [promoterList]);


    const alertInfo = async (msg1, msg2='') => {

      const confirmed = await infoAlert(msg1, msg2='', );
      if(confirmed===true)
      {
  
      }
  };

    



  return (
    <DashboardLayout>
<div class="pcoded-content">
      <div class="pcoded-inner-content">

            <div class="main-body">
                  <div class="page-wrapper">

                  <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>Saving Account - 
                        {listDetails && listDetails.account_no && (
                              <>{FirstLetterCapitalize(listDetails.account_no)}</>
                              )}
                        </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/account-list">Saving Account</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> {listDetails && listDetails.account_no && (
                              <>{FirstLetterCapitalize(listDetails.account_no)}</>
                              )}</a>
                        </li>

                       
                      </ul>
                    </div>
                  </div>

                  <div class="col-md-12 mt-3">
                    <a class="btn btn-info mt-10 "  href={`/account-transaction/${accountID}`}>VIEW TRANSACTIONS</a>
                    <a class="btn btn-success mt-10 ml-1"  href={`/account-deposite-mony/${accountID}`}>DEPOSIT MONEY</a>
                    <a class="btn btn-danger mt-10 ml-1" href={`/account-mony-debit/${accountID}`}>WITHDRAW MONEY</a>

                    <div class="dropdown-primary dropdown open mt-10 ml-1">
                      <button class="btn btn-info dropdown-toggle waves-effect waves-light " type="button" id="dropdown-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">DEBIT OTHER CHARGES</button>
                      <div class="dropdown-menu" aria-labelledby="dropdown-2" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                      <a class="dropdown-item waves-light waves-effect" href={`/account-other-charge/${accountID}`}>OTHER CHARGES LIST</a>
                      <a class="dropdown-item waves-light waves-effect" href={`/account-other-charge-pay/${accountID}`}>DEBIT OTHER CHARGES</a>
                      <a class="dropdown-item waves-light waves-effect" href={`/account-other-charge-clear-due/${accountID}`} >CLEAR DUES</a>
                      </div>
                      </div>

                      <div class="dropdown-success dropdown open mt-10 ml-1">
                        <button class="btn btn-success dropdown-toggle waves-effect waves-light " type="button" id="dropdown-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">ACCOUNT DETAILS</button>
                        <div class="dropdown-menu" aria-labelledby="dropdown-2" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                        <a class="dropdown-item waves-light waves-effect" href={`/account-credit-interest/${accountID}`} >CREDIT INTEREST</a>
                        <a class="dropdown-item waves-light waves-effect" href={`/account-change-account-type/${accountID}`} >CHANGE ACCOUNT TYPE</a>


                        <a class="dropdown-item waves-light waves-effect" href={`/account-change-nominee/${accountID}`} > {(listDetails.nominee==='yes')?'CHANGE':'ADD'}  NOMINEE</a>

                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item waves-light waves-effect"  href={`/account-upgrade/${accountID}`}  >UPGRADE ACCOUNT</a>
                        <a class="dropdown-item waves-light waves-effect" href="#!">CLOSE ACCOUNT</a>
                        <a class="dropdown-item waves-light waves-effect" href="#!">REMOVE ACCOUNT</a>
                        </div>
                      </div>


                      <div class="dropdown-danger dropdown open mt-10 ml-1">
                        <button class="btn btn-danger dropdown-toggle waves-effect waves-light " type="button" id="dropdown-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">PRINT DOCUMENTS</button>
                        <div class="dropdown-menu" aria-labelledby="dropdown-2" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                        <a class="dropdown-item waves-light waves-effect" href="#!">PASSBOOK</a>
                        <a class="dropdown-item waves-light waves-effect" href="#!">ACCOUNT OPENING FORM</a>
                        </div>
                      </div>

                  </div>

                </div>
              </div>


            <div class="page-body">


            <div class="row">
                <div class="col-md-6">
                <div class="card" style={{borderTop:'3px solid green'}}>
                         <div class="card-header">
                         <small style={{float: 'right'}}>

                            <a class="btn btn-info btn-sm" title="" href={`/account-scheem-edit/${dataEncript(listDetails.id)}`}>
                            <i class="feather icon-edit"></i></a>&nbsp;

                            </small>

                        </div> 
                        <div class="card-block">
                              <div class="table-responsive dt-responsive">
                                    <table class="table dtable table-striped table-bordered nowrap" style={{width:'100%'}}>
                                        
                                          <tbody>
                                             
                                          <tr>
                                            <th>Member</th>
                                            <td> {listDetails && listDetails.member_name && (
                                                <>{FirstLetterCapitalize(listDetails.member_name)}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Create on</th>
                                            <td> {listDetails && listDetails.scheme_name && (
                                                <>{FirstLetterCapitalize(listDetails.scheme_name)}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Account No.</th>
                                            <td> {listDetails && listDetails.account_no && (
                                                <>{(listDetails.account_no)}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Old Account No	</th>
                                            <td> {listDetails && listDetails.old_account_no && (
                                                <>{listDetails.old_account_no}</>
                                                )} </td>
                                            </tr>
                                            <tr>
                                            <th>Scheme Name	</th>
                                            <td> {listDetails && listDetails.scheme_id && (
                                                <>{formattedAmount(listDetails.scheme_id)}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Open Date</th>
                                            <td> {listDetails && listDetails.open_date && (
                                                <>{formatDate(listDetails.open_date)}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Status</th>
                                            <td>

                                              {
                                                listDetails && (
                                                  listDetails.account_status === 'active' ? (
                                                    <label className="label label-success">Active</label>
                                                  ) : listDetails.account_status === 'inactive' ? (
                                                    <label className="label label-danger">Inactive</label>
                                                  ) : null
                                                )
                                              }
                                            </td>
                                            </tr>
                                            <tr>
                                            <th>Lock Balance (A)</th>
                                            <td> {listDetails && listDetails.lock_amount && (
                                                <>{formattedAmount(listDetails.lock_amount)}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Hold Balance (B)</th>
                                            <td> {listDetails && listDetails.lock_amt && (
                                                <>{formattedAmount(listDetails.lock_amt)}</>
                                                )}</td>
                                            </tr>

                                            <tr>
                                            <th>Available Balance (C)</th>
                                            <td> {listDetails && listDetails.mis_amount && (
                                                <>{formattedAmount(listDetails.mis_amount)}</>
                                                )}</td>
                                            </tr>

                                            <tr>
                                            <th>Combined Balance (A + B + C + D)	</th>
                                            <td> {listDetails && listDetails.lock_amt && (
                                                <>{formattedAmount(listDetails.lock_amt)}</>
                                                )}</td>
                                            </tr>

                                            <tr>
                                            <th>Penalty Dues</th>
                                            <td> {listDetails && listDetails.lock_amt && (
                                                <>{formattedAmount(listDetails.lock_amt)}</>
                                                )}</td>
                                            </tr>

                                            <tr>
                                            <th>Special Account</th>
                                            <td> {
                                                            listDetails && (
                                                              listDetails.account_status === 'yes' ? (
                                                                <label className="label label-success">Yes</label>
                                                              ) : listDetails.account_status === 'no' ? (
                                                                <label className="label label-danger">No</label>
                                                              ) : null
                                                            )
                                                          }
                                                </td>
                                            </tr>
                                           
                                          </tbody>
                                        
                                    </table>
                              </div>
                        </div>
                  </div>


                  <div class="card  mt-3 " style={{borderTop:'3px solid red'}} >
                  <div class="card-header">
                        <h5>ALLOCATED PASSBOOK</h5>
                              <div class="card-header-right">
                                    <ul class="list-unstyled card-option">
                                          <li>
                                            <a class="badge badge-default" href={`/passbook-add/${accountID}`} > <i class="feather icon-plus "></i> PASSBOOK</a>
                                          </li>
                                    </ul>
                              </div>
                        </div>
                        <div class="card-block p-0">
                              <form > 
                                <div class="table-responsive dt-responsive">
                                        <table class="table dtable table-striped table-bordered">
                                          <thead>
                                                  <tr>
                                                    <th>Sr</th>
                                                    <th>NAME</th>
                                                    <th>DOB</th>
                                                    <th>ACTIONS</th>
                                                  </tr>
                                          </thead>
                                          
                                          <tbody>
                                                {
                                                passbooksList.length > 0 ? (
                                                    passbooksList.map((passbookData, index) => (
                                                    <tr class="gradeX">
                                                    <td>{index + 1}</td>
                                                    <td > {passbookData && passbookData.passbook_no && (
                                                                <>{FirstLetterCapitalize(passbookData.passbook_no)}</>
                                                                )}</td>

                                                      <td> {passbookData && passbookData.issue_date && (
                                                         <>{ passbookData.issue_date?formatDate(passbookData.issue_date):''}</>
                                                      )}</td>


                                                        <td> 
                                                            <a href={`/passbook-view/${dataEncript(passbookData.id)}`} class='font-weight-normal label label-success'><i class="fa fa-eye"></i></a>&nbsp;
                                                            <a href={`/passbook-edit/${dataEncript(passbookData.id)}`}  class='font-weight-normal label label-info'> <i class="fa fa-edit"></i> </a>
                                                        </td>
                                                    
                                                </tr>
                                                ))
                                                ) : (
                                                <tr>
                                                    <td colSpan="4">No data available</td>
                                                </tr>
                                                )
                                                }
                                                  
                                                </tbody>

                                        </table>
                                        </div>
                                       </form>
                        </div>
                  </div>


                  <div class="card  mt-3 " style={{borderTop:'3px solid red'}} >
                  <div class="card-header py-3 text-denger">
                        <h5>DOCUMENTS</h5>
                              <div class="card-header-right">
                                    <ul class="list-unstyled card-option">
                                        <li>
                                             <a  href={`/account-upload-document/${accountID}`} > <i class="feather icon-upload "></i></a>
                                        </li>
                                        <li><i class="feather icon-minus minimize-card"></i></li>
                                    </ul> 
                              </div>
                        </div>
                        <div class="card-block p-0">
                              <form > 
                                <div class="table-responsive dt-responsive">
                                        <table class="table dtable table-striped table-bordered">
                                          <thead>
                                                  <tr>
                                                    <th>Sr</th>
                                                    <th>NAME</th>
                                                    <th>Url</th>
                                                    <th>ACTIONS</th>
                                                  </tr>
                                          </thead>
                                          <tbody>
                                          {
                                              documents_list.length > 0 ? (
                                                  documents_list.map((documentData, index) => (
                                                  <tr class="gradeX">
                                                  <td>{index + 1}</td>
                                                  <td > {documentData && documentData.doc_name && (
                                                              <>{FirstLetterCapitalize(documentData.doc_name)}</>
                                                              )}</td>

                                                      <td> {documentData && documentData.document && (
                                                              <a href={documentData?.document ? API_BASE_URL + documentData.document : ''} > View  </a>
                                                           )}</td>

                                                  <td> 
                                                      <a href={`/passbook-edit/${dataEncript(documentData.id)}`}  class='font-weight-normal label label-danger'> <i class="fa fa-trash"></i> </a>
                                                  </td>

                                                  
                                              </tr>
                                              ))
                                              ) : (
                                              <tr>
                                                  <td colSpan="4">No data available</td>
                                              </tr>
                                              )
                                          }
                                          </tbody>
                                        </table>
                                        </div>
                                       </form>
                        </div>
                  </div>

                  <div class="card  mt-3 " style={{borderTop:'3px solid green'}} >
                  <div class="card-header py-3 text-denger">
                        <h5>COMMENTS</h5>
                              <div class="card-header-right">
                                    <ul class="list-unstyled card-option">
                                        <li><i class="feather icon-minus minimize-card"></i></li>
                                    </ul>
                              </div>
                        </div>
                        <div class="card-block p-0">
                              <form > 
                                <div class="table-responsive dt-responsive">
                                        <table class="table dtable table-striped table-bordered">
                                        <thead>
                                          <tr>
                                              <th>Sr</th>
                                              <th>COMMENT</th>
                                              <th>COMMENT BY</th>
                                              <th>DATE</th>
                                          </tr>
                                      </thead>
                                      <tbody>


                                      {
                                      commentsList.length > 0 ? (
                                          commentsList.map((appointData, index) => (
                                          <tr class="gradeX">
                                          <td>{index + 1}</td>
                                          <td > {appointData && appointData.comment && (
                                                      <>{FirstLetterCapitalize(appointData.comment)}</>
                                                      )}</td>

                                              <td> {appointData && appointData.comment_by && (
                                                      <>{FirstLetterCapitalize(appointData.comment_by)}</>
                                                      )}</td>

                                          <td> {appointData && appointData.created_at && (
                                                      <>{ appointData.created_at?formatDate(appointData.created_at):''}</>
                                                      )}</td>

                                          
                                      </tr>
                                      ))
                                      ) : (
                                      <tr>
                                          <td colSpan="4">No data available</td>
                                      </tr>
                                      )
                                  }
                                        
                                      </tbody>
                                        </table>
                                        </div>

                                        <div class="row">
                                        <div class="col-md-12 mb-3 text-center">
                                            <a href={`/account-add-comment/${accountID}`}  class="btn btn-info btn-square">ADD COMMENT</a>
                                        </div>
                                      </div>
                                       </form>
                        </div>
                  </div>

                  <div class="card  mt-3 " style={{borderTop:'3px solid yellow'}} >
                  <div class="card-header py-3 text-denger">
                        <h5>Transaction Info</h5>
                              <div class="card-header-right">
                                    <ul class="list-unstyled card-option">
                                        <li><i class="feather icon-minus minimize-card"></i></li>
                                    </ul>
                              </div>
                        </div>
                        <div class="card-block p-0">
                              <form > 
                                <div class="table-responsive dt-responsive">
                                        <table class="table">
                                          <thead>
                                                  <tr>
                                                    <th>DATE</th>
                                                    <th>Type</th>
                                                    <th>Payment Mode</th>
                                                    <th>Status</th>
                                                    <th>Amount</th>
                                                  </tr>
                                          </thead>
                                          <tbody>


                                          {
                                          transaction_list.length > 0 ? (
                                              transaction_list.map((appointData, index) => (
                                              <tr class="gradeX">


                                              <td> {appointData && appointData.transaction_date && (
                                                  <span>{ appointData.transaction_date?formatDate(appointData.transaction_date):''}</span>
                                                  )}</td>

                                                 <td> {appointData && appointData.transaction_type && (
                                                  <span>{FirstLetterCapitalize(appointData.transaction_type)}</span>
                                                  )}</td>

                                                <td> {appointData && appointData.pay_mode && (
                                                  <span>{FirstLetterCapitalize(appointData.pay_mode)}</span>
                                                  )}</td>

                                                <td>
                                                          {
                                                            appointData && (
                                                              appointData.status === 'pending' ? (
                                                                <label className="label label-warning">Pending</label>
                                                              ) : appointData.status === 'approve' ? (
                                                                <label className="label label-success">Approve</label>
                                                              ) : null
                                                            )
                                                          }
                                                    </td>

                                                    <td> {appointData && appointData.deposit_amt && (
                                                  <span>{formattedAmount(appointData.deposit_amt)}</span>
                                                  )}</td>

                                               

                                              
                                          </tr>
                                          ))
                                          ) : (
                                          <tr>
                                              <td colSpan="10">No data available</td>
                                          </tr>
                                          )
                                      }
                                            
                                          </tbody>
                                        </table>
                                        </div>

                                      <div class="row">
                                        <div class="col-md-12 mb-3 text-center">
                                            <a href={`/account-transaction/${accountID}`} class="btn btn-info btn-square">View All</a>
                                        </div>
                                      </div>

                                       </form>
                        </div>
                  </div>
                  

                </div>
                <div class="col-md-6">
                


                <div class="card" style={{borderTop:'3px solid red'}}>
                  <div class="card-header ">
                        <h5>SETTINGS</h5>
                              <div class="card-header-right">
                                    <ul class="list-unstyled card-option">
                                          <li><i class="feather icon-minus minimize-card"></i></li>
                                    </ul>
                              </div>
                        </div>
                        <div class="card-block">
                            
                              <form> 
                                <div class="table-responsive dt-responsive">
                                        <table class="table">
                                         <tr class="border-0">
                                                <th class="text-center" style={{ borderTop: '0px solid #dee2e6' }} >is_sms </th>
                                                <td style={{ borderTop: '0px solid #dee2e6' }} >

                                                    <input type="checkbox" class="js-large" name="is_sms" checked={is_sms === 'yes'} value="yes" onChange={(e) => { e.preventDefault(); setis_sms(e.target.checked ? 'yes' : 'no');  updateSetting('is_sms'); }}  />

                                                </td>
                                            </tr>

                                      
                                            <tr class="border-0">
                                                <th class="text-center" style={{width:'0px;'}} >ACCOUNT ON HOLD</th>
                                                <td  >
                                                    <input type="checkbox" class="js-large" name="account_hold" checked={account_hold === 'yes'} value="yes"  onChange={(e) => { e.preventDefault(); setaccount_hold(e.target.checked ? 'yes' : 'no');  updateSetting('account_hold'); }} />
                                                </td>
                                            </tr>
                                            
                                            <tr>
                                                <th class="text-center" style={{width:'0px;'}}>DEDUCT CHARGES &nbsp;
                                                <span class="" onClick={() => alertInfo("'YES' - Then charges (like - sms charges, card charges, service charges, min monhtly average balance) will automatically deducted as per setting in scheme. If you want to stop the automatic deduction for this account, you can set it to 'NO'")} title="'YES' - Then charges (like - sms charges, card charges, service charges, min monhtly average balance) will automatically deducted as per setting in scheme. If you want to stop the automatic deduction for this account, you can set it to 'NO'" ><i class="icofont icofont-info-circle"></i></span>
                                                </th>
                                                <td>
                                                <input type="checkbox" class="js-large" name="deduct_charges" value={"yes"}  checked={deduct_charges === 'yes' ? 'checked' : ''}  onChange={(e) => { e.preventDefault(); setdeduct_charges(e.target.checked ? 'yes' : 'no');  updateSetting('deduct_charges'); }} />
                                                    {formErrors.kyc_status && (
                                                        <span style={{ color: "red" }}>
                                                        {formErrors.kyc_status}
                                                        </span>
                                                    )}
                                                </td>
                                            </tr>
                                           
                                        </table>
                                        </div>
                                            </form>
                        </div>
                  </div>


                  <div class="card  mt-3" >
                  <div class="card-header bg-warning text-white">
                        <h5>Branch Info</h5>
                              <div class="card-header-right">
                                    <ul class="list-unstyled card-option">
                                          <li><i class="feather icon-minus minimize-card"></i></li>
                                    </ul>
                              </div>
                        </div>
                        <div class="card-block">
                            
                              <form > 
                                <div class="table-responsive dt-responsive">
                                        <table class="table">
                                         <tr class="border-0">
                                                <th class="" style={{ borderTop: '0px solid #dee2e6' }} >Branch</th>
                                                <td style={{ borderTop: '0px solid #dee2e6' }} >

                                                     {listDetails && listDetails.branch_id && (
                                                      <>{FirstLetterCapitalize(listDetails.branch_id)}</>
                                                      )}

                                                </td>
                                            </tr>

                                      
                                            <tr class="border-0">
                                                <th class="" style={{width:'0px;'}} >Associate/ Advisor/ Staff</th>
                                                <td  >
                                                     {listDetails && listDetails.associate_id && (
                                                      <>{FirstLetterCapitalize(listDetails.associate_id)}</>
                                                      )}
                                                </td>
                                            </tr>
                                            
                                            <tr>
                                                <th class="" style={{width:'0px;'}}>Joint Account</th>
                                                <td>
                                                  {
                                                    listDetails && (
                                                      listDetails.account_type === 'joint' ? (
                                                        <label className="label label-success">Yes</label>
                                                      ) : listDetails.account_type === 'single' ? (
                                                        <label className="label label-danger">No</label>
                                                      ) :null
                                                    )
                                                  }
                                                </td>
                                            </tr>
                                           
                                        </table>
                                        </div>
                                       </form>
                        </div>
                  </div>


                  <div class="card" style={{borderTop:'3px solid green'}}>
                         
                        <div class="card-block p-0">
                              <div class="table-responsive dt-responsive">
                             
                                    <table class="table " style={{width:'100%'}}>
                                        
                                          <tbody>
                                             
                                          <tr>
                                            <th>Member</th>
                                            <td> 
                                            <form  onSubmit={(e) => { e.preventDefault(); updateDetails('member_id', 'Member' ); }}>
                                            <div class="input-group">
                                                <select
                                                      class="form-control m-b"
                                                      name="member_id"
                                                      value={member_id}

                                                      onChange={(e) =>
                                                        setmember_id(e.target.value)
                                                        }
                                                    >
                                                      <option value={''} disabled>Select Member</option>
                                                      {memberList.map((aBranchData, mindex) => (
                                                        <option
                                                          value={aBranchData.id}
                                                          selected={
                                                            aBranchData.id === member_id
                                                              ? "selected"
                                                              : ""
                                                          }
                                                        >
                                                          {aBranchData.fname} &nbsp;
                                                          {aBranchData.lname}

                                                        </option>
                                                      ))}
                                                    </select>
                                                    <div class="input-group-prepend" style={{width:'auto'}}>
                                                        <input class="form-control btn btn-success" disabled={submitting} type="submit" value="Update" />
                                                    </div>
                                                </div>

                                                {formErrors.member_id && (
                                                    <span style={{ color: "red" }}>
                                                    {formErrors.member_id}
                                                    </span>
                                                )}

                                                   </form>
                                            </td>
                                            </tr>

                                            <tr>
                                              <th>Old Account No	</th>
                                              <td> 
                                              <form onSubmit={(e) => { e.preventDefault(); updateDetails('old_account_no', 'Old Account No' ); }}>
                                              <div class="input-group">
                                                    
                                                    <input type="text" class="form-control m-b" name="old_account_no" placeholder='Enter Old Account No'
                                                      value={old_account_no}
                                                      onChange={(e) =>
                                                          setold_account_no(e.target.value)
                                                      }
                                                />
                                                      <div class="input-group-prepend" style={{width:'auto'}}>
                                                          <input class="form-control btn btn-success" disabled={submitting} type="submit" value="Update" />
                                                      </div>
                                                  </div>

                                                  {formErrors.old_account_no && (
                                                      <span style={{ color: "red" }}>
                                                      {formErrors.old_account_no}
                                                      </span>
                                                  )}

                                                    </form>
                                              </td>
                                            </tr>


                                            <tr>
                                            <th>Branch</th>
                                            <td> 
                                            <form onSubmit={(e) => { e.preventDefault(); updateDetails('branch_id', 'Branch' ); }} >
                                            <div class="input-group">
                                                
                                                <select
                                                    class="form-control m-b"
                                                    name="branch_id"
                                                    value={branch_id}
                                                    onChange={(e) =>
                                                      setbranch_id(e.target.value)
                                                    }
                                                  >
                                                    <option value={''} disabled>Branch</option>
                                                    {activeBranchList.map((branchData) => (
                                                      <option
                                                        value={branchData.id}
                                                        selected={
                                                          branchData.id === branch_id
                                                            ? "selected"
                                                            : ""
                                                        }
                                                      >
                                                        {branchData.branch_name}

                                                      </option>
                                                    ))}
                                                  </select>

                                                    <div class="input-group-prepend" style={{width:'auto'}}>
                                                        <input class="form-control btn btn-success" disabled={submitting} type="submit" value="Update" />
                                                    </div>
                                                </div>

                                                {formErrors.branch_id && (
                                                    <span style={{ color: "red" }}>
                                                    {formErrors.branch_id}
                                                    </span>
                                                )}

                                                   </form>
                                            </td>
                                            </tr>

                                            <tr>
                                              <th>Open Date	</th>
                                              <td> 
                                              <form onSubmit={(e) => { e.preventDefault(); updateDetails('open_date', 'Open Date' ); }}>
                                              <div class="input-group">
                                                    
                                                    <input type="date" class="form-control m-b" name="open_date"
                                                      value={open_date}
                                                      onChange={(e) =>
                                                          setopen_date(e.target.value)
                                                      }
                                                />
                                                      <div class="input-group-prepend" style={{width:'auto'}}>
                                                          <input class="form-control btn btn-success" disabled={submitting} type="submit" value="Update" />
                                                      </div>
                                                  </div>

                                                  {formErrors.open_date && (
                                                      <span style={{ color: "red" }}>
                                                      {formErrors.open_date}
                                                      </span>
                                                  )}

                                                    </form>
                                              </td>
                                            </tr>


                                            <tr>
                                            <th>Associate/ Advisor/ Staff</th>
                                            <td> 
                                            <form onSubmit={(e) => { e.preventDefault(); updateDetails('associate_id', 'Associate/ Advisor' ); }}>
                                            <div class="input-group">
                                                  <select
                                                          class="form-control m-b"
                                                          name="associate_id"
                                                          value={associate_id}
                                                          onChange={(e) =>
                                                            setassociate_id(e.target.value)
                                                          }
                                                        >
                                                          <option value={''} disabled>Branch</option>
                                                          {activeBranchList.map((branchData) => (
                                                            <option
                                                              value={branchData.id}
                                                              selected={
                                                                branchData.id === associate_id
                                                                  ? "selected"
                                                                  : ""
                                                              }
                                                            >
                                                              {branchData.branch_name}

                                                            </option>
                                                          ))}
                                                        </select>
                                                    <div class="input-group-prepend" style={{width:'auto'}}>
                                                        <input class="form-control btn btn-success" disabled={submitting} type="submit" value="Update" />
                                                    </div>
                                                </div>

                                                {formErrors.associate_id && (
                                                    <span style={{ color: "red" }}>
                                                    {formErrors.associate_id}
                                                    </span>
                                                )}

                                                   </form>
                                            </td>
                                            </tr>

                                            <tr>
                                              <th>Lock Amount	</th>
                                              <td> 
                                              <form onSubmit={(e) => { e.preventDefault(); updateDetails('lock_amount', 'Lock Amount' ); }}>
                                              <div class="input-group">
                                                    
                                                    <input type="number" class="form-control m-b" name="lock_amount" placeholder='Lock Amount'
                                                      value={lock_amount}
                                                      onChange={(e) =>
                                                          setlock_amount(e.target.value)
                                                      }
                                                />
                                                      <div class="input-group-prepend" style={{width:'auto'}}>
                                                          <input class="form-control btn btn-success" disabled={submitting} type="submit" value="Update" />
                                                      </div>
                                                  </div>

                                                  {formErrors.lock_amount && (
                                                      <span style={{ color: "red" }}>
                                                      {formErrors.lock_amount}
                                                      </span>
                                                  )}

                                                    </form>
                                              </td>
                                            </tr>


                                            


                                          </tbody>
                                        
                                    </table>

                                 
                              </div>
                        </div>
                  </div>




                </div>

            </div>

              






            </div>

      </div>
</div>
<ToastContainer className="toast-container" />
<div id="styleSelector">
</div>
</div>
</div>
    </DashboardLayout>
  );
}

export default AdminLayout;
