import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {   dataDecrypt,   getCurrentDate,  dataEncript, FirstLetterCapitalize, formatDate, formattedAmount } from "./../../../helper/commonHelpers";
//formatDate

function CompanyDetails() {
  const [submitting, setSubmitting] = useState(false);
  const { accountID }   = useParams();
  const [shareHolderDetail, setshareHolderDetail] = useState([]);
  const [bankList, setbankList] = useState([]);
  const [transaction_list, settransaction_list] = useState([]);


  const[acttype, setacttype] = useState('');



  const [formErrors, setFormErrors] = useState({
    member_id: "",
    transaction_date: "",
    amount: "",
  });


 
  
  const[remark, setremark] = useState('');
  const[amount, setamount] = useState('0');

  const[mgst, setmgst] = useState('18');
  const[total_amount, settotal_amount] = useState('');
  const[transaction_date, settransaction_date] = useState('');

  const[penalty_due, setpenalty_due] = useState('0.00');
  const[waiv_amount, setwaiv_amount] = useState('0');

  const[net_amount, setnet_amount] = useState('0');
  const[rounding_off, setrounding_off] = useState('0');



  const[pay_mode, setpay_mode] = useState('cash');
const[bank_name, setbank_name] = useState('');
const[cheque_no, setcheque_no] = useState('');
const[cheque_date, setcheque_date] = useState('');
const[online_transfer_date, setonline_transfer_date] = useState('');

const[tranfer_no, settranfer_no] = useState('');
const[transfer_mode, settransfer_mode] = useState('');
const[saving_account, setsaving_account] = useState('');


  useEffect(() => {
  

      axios.get(API_BASE_URL + "api-common/get-account-list?id="+dataDecrypt(accountID), {
        headers: API_HEADERS,
      }).then((response) => {

        console.log(response.data);



        if (response.data["status"] > 0) {
            setshareHolderDetail(response.data["data"]);


            response.data['data'] && (
              response.data['data'].account_for === 'fd' ? (
                setacttype('FD')
              ) : response.data['data'].account_for === 'mis' ? (
                setacttype('MIS')
              ): response.data['data'].account_for === 'rd' ? (
                setacttype('RD')
              ): response.data['data'].account_for === 'dd' ? (
                setacttype('DD')
              ) : <></>
            )
    
    
    
            if (response.data["data"].transaction_list && (response.data["data"].transaction_list).length > 0) {
              settransaction_list(response.data["data"].transaction_list);
          }


        } else {
            toast.error(response.data["message"]);
        }

      }).catch((error) => {
        toast.error("Failed to fetch data!");
      });

    

      axios.get(API_BASE_URL + "api-common/get-bank-list", {
        headers: API_HEADERS,
      }).then((response) => {
        if (response.data["status"] > 0) {
          setbankList(response.data["data"]);
          }
      });
     


      settransaction_date(getCurrentDate());
  }, [accountID]);




  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));

    const formData = {
        account_id:dataDecrypt(accountID),

        remark: remark,
        amount: amount,
        gst: mgst,
        tamount: total_amount,
        transaction_type: 'debit',

        charge_type:'due',
        transaction_date: transaction_date,

        rounding_off:rounding_off,
        net_amount:net_amount,

        pay_mode:pay_mode,
        bank_name:bank_name,
        cheque_no:cheque_no, 
        cheque_date:cheque_date,
        online_transfer_date:online_transfer_date,
        tranfer_no: tranfer_no,
        transfer_mode: transfer_mode,
        saving_account: saving_account,

    };

    // validate form

      // if (!member_id) {
      //   errors.member_id = 'Member is required.';
      // }


      if (!transaction_date) {
        errors.transaction_date = 'Transaction date is required.';
      }

      if (!amount) {
        errors.amount = 'Amount is required.';
      }

    

      if (!pay_mode) {
        errors.pay_mode = 'Pay Mode is required.';
      }


      if(pay_mode==='cheque')
      {
        if (!formData.bank_name) {
          errors.bank_name = 'Bank Name is required.';
        }

        if (!formData.cheque_no) {
          errors.cheque_no = 'Cheque No. is required.';
        }

        if (!formData.cheque_date) {
          errors.cheque_date = 'Cheque Date. is required.';
        }
      }
  
      if(pay_mode==='online')
      {
        if (!formData.transfer_date) {
          errors.transfer_date = 'Transfer Date is required.';
        }

        if (!formData.online_transfer_date) {
          errors.online_transfer_date = 'UTR/ Transaction No is required.';
        }

        if (!formData.transfer_mode) {
          errors.transfer_mode = 'Transfer Mode is required.';
        }
      }

      if(pay_mode==='saving')
      {
        if (!formData.saving_account) {
          errors.saving_account = 'Saving Account is required.';
        }
      }
    


    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      axios
        .post(API_BASE_URL + "api-saving-account/add-other-charges", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              // window.location.href = "/member-share-holdings";
              window.location.href = "/account-transaction-view/"+accountID+'/'+response.data["data"].id;

            //   window.location.reload();
            }, 2000);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 1000);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };


  

  const calculateamount = async (ttlamount) => {
    let module = 1+mgst/100;
    setamount((ttlamount/module).toFixed(2));

};

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4> 
                          
                        {
                            shareHolderDetail && (
                              shareHolderDetail.account_for === 'fd' ? (
                                <> FD Account </>
                              ) : shareHolderDetail.account_for === 'mis' ? (
                                <>MIS Deposits </>
                              ) : shareHolderDetail.account_for === 'rd' ? (
                                <> Recurring Deposits </>
                              ): shareHolderDetail.account_for === 'dd' ? (
                                <> Daily Deposits </>
                              ) : <></>
                            )
                          }
                           - {shareHolderDetail && shareHolderDetail.account_no && (
                                    <>{shareHolderDetail.account_no}</>
                                    )} 

                            <span>&nbsp;Other Charges</span> </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                        {
                            shareHolderDetail && (
                              shareHolderDetail.account_for === 'fd' ? (
                                <a href="/fixed-deposit-account">FD Accounts </a>
                              ) : shareHolderDetail.account_for === 'mis' ? (
                                <a href="/fixed-deposit-mis-account">MIS Deposits </a>
                              ) : shareHolderDetail.account_for === 'rd' ? (
                                <a href="/recurring-deposit-account">Recurring Deposits </a>
                              ): shareHolderDetail.account_for === 'dd' ? (
                                <a href="/recurring-deposit-account">Daily Deposits </a>
                              ) : <></>
                            )
                          }
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                        {
                            shareHolderDetail && (
                              shareHolderDetail.account_for === 'fd' ? (
                                <a href={`/fixed-deposit-account-view/${dataEncript(shareHolderDetail.id)}`}> {shareHolderDetail && shareHolderDetail.account_no && (
                                  <>{shareHolderDetail.account_no}</>
                                  )} </a>
                              ) : shareHolderDetail.account_for === 'mis' ? (
                                <a href={`/fixed-deposit-mis-account-view/${dataEncript(shareHolderDetail.id)}`}> {shareHolderDetail && shareHolderDetail.account_no && (
                                  <>{shareHolderDetail.account_no}</>
                                  )} </a>
                              ) : shareHolderDetail.account_for === 'rd' ? (
                                <a href={`/recurring-deposit-account-view/${dataEncript(shareHolderDetail.id)}`}> {shareHolderDetail && shareHolderDetail.account_no && (
                                  <>{shareHolderDetail.account_no}</>
                                  )} </a>
                              ): shareHolderDetail.account_for === 'dd' ? (
                                <a href={`/recurring-deposit-account-view/${dataEncript(shareHolderDetail.id)}`}> {shareHolderDetail && shareHolderDetail.account_no && (
                                  <>{shareHolderDetail.account_no}</>
                                  )} </a>
                              ) : <></>
                            )
                          }
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">Clear Due</a>
                        </li>

                        

                        
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-7">
                    <div class="card" style={{borderTop:'3px solid red'}} >
                    

                      <div class="card-block">
                      <form onSubmit={updateDetails}>


                      
                    

                      <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                  <div class="col-sm-5">
                                  <h6 class="text-xl-right">Charges / Penalty Due<span class="text-danger">*</span></h6>
                                  </div>
                                  <div class="col-sm-7">
                                  <input
                                      class="form-control"
                                      type="number"
                                      value={penalty_due}
                                      onChange={(e) =>
                                      setpenalty_due(e.target.value)
                                      }
                                      placeholder="0.00 "
                                      readOnly
                                  />
                                  {formErrors.penalty_due && (
                                      <span style={{ color: "red" }}>
                                      {formErrors.penalty_due}
                                      </span>
                                  )}
                                  </div>
                              </div>
                              </div>
                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                  <div class="col-sm-5">
                                  <h6 class="text-xl-right">Waived Amount<span class="text-danger">*</span></h6>
                                  </div>
                                  <div class="col-sm-7">
                                  <input
                                      class="form-control"
                                      type="number"
                                      value={waiv_amount}
                                      onChange={(e) =>
                                      setwaiv_amount(e.target.value)
                                      }
                                      placeholder="Enter Waived Amount "
                                  />
                                  {formErrors.waiv_amount && (
                                      <span style={{ color: "red" }}>
                                      {formErrors.waiv_amount}
                                      </span>
                                  )}
                                  </div>
                              </div>
                              </div>
                          </div>
                      
                       

                        


                        <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Charges<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                    <div class="row">
                                      <div class="col-xs-4 col-4 text-center"><h6 style={{width:'inherit'}}>Amount</h6></div>
                                      <div class="col-xs-4 col-4 text-center"><h6 style={{width:'inherit'}}>GST Rate (%)</h6> </div>
                                      <div class="col-xs-4 col-4 text-center"><h6 style={{width:'inherit'}}>Total Amount</h6></div>
                                    </div>

                                <div class="input-group">
                                  <input class="form-control" type="text" value={amount} onChange={(e) => setamount(e.target.value) } placeholder="0 " readOnly />
                                  <div class="input-group-prepend">
                                    <input class="form-control" type="text" value={mgst} onChange={(e) => setmgst(e.target.value) } placeholder="" readOnly />
                                  </div>
                                  <div class="input-group-prepend"> 
                                    <input class="form-control" type="number" value={total_amount} onInput={(e) => {settotal_amount(e.target.value); calculateamount(e.target.value); } } placeholder="Amount " />
                                  </div>
                                </div>

                                  
                                  {formErrors.amount && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.amount}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                  <div class="col-sm-5">
                                  <h6 class="text-xl-right">Rounding Off<span class="text-danger">*</span></h6>
                                  </div>
                                  <div class="col-sm-7">
                                  <input
                                      class="form-control"
                                      type="number"
                                      value={rounding_off}
                                      onChange={(e) =>
                                      setrounding_off(e.target.value)
                                      }
                                      placeholder="Enter Rounding Off "
                                      readOnly
                                  />
                                  {formErrors.rounding_off && (
                                      <span style={{ color: "red" }}>
                                      {formErrors.rounding_off}
                                      </span>
                                  )}
                                  </div>
                              </div>
                              </div>
                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                  <div class="col-sm-5">
                                  <h6 class="text-xl-right">Net Amount<span class="text-danger">*</span></h6>
                                  </div>
                                  <div class="col-sm-7">
                                  <input
                                      class="form-control"
                                      type="number"
                                      value={net_amount}
                                      onChange={(e) =>
                                      setnet_amount(e.target.value)
                                      }
                                      placeholder="Enter Net Amount "
                                     readOnly
                                  />
                                  {formErrors.net_amount && (
                                      <span style={{ color: "red" }}>
                                      {formErrors.net_amount}
                                      </span>
                                  )}
                                  </div>
                              </div>
                              </div>
                          </div>

                          <div class="row">
                        <div class="col-sm-12 col-xl-12 m-b-30">
                            <div class="row">
                              <div class="col-sm-5">
                                <h6 class="text-xl-right">
                                Remarks (if any)<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-7">
                               

                                  <textarea class="form-control" value={remark} rows={'3'}
                                  onChange={(e) =>
                                    setremark(e.target.value)
                                  }
                                  placeholder="Enter Remarks (if any). " ></textarea>

                                {formErrors.remark && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.remark}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>



                          <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                  <div class="col-sm-5">
                                  <h6 class="text-xl-right">Transaction Date<span class="text-danger">*</span></h6>
                                  </div>
                                  <div class="col-sm-7">
                                  <input
                                      class="form-control"
                                      type="date"
                                      value={transaction_date}
                                      onChange={(e) =>
                                      settransaction_date(e.target.value)
                                      }
                                      placeholder="Enter Transaction Date. "
                                  />
                                  {formErrors.transaction_date && (
                                      <span style={{ color: "red" }}>
                                      {formErrors.transaction_date}
                                      </span>
                                  )}
                                  </div>
                              </div>
                              </div>
                          </div>


                          <div class="row">
                        <div class="col-sm-12 col-xl-12   m-b-30">
                            <div class="row">
                              <div class="col-sm-5">
                                <h6 class="text-xl-right">
                                  Pay Mode<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-7">
                                <div class="form-radio row ml-3">
                                  <div class="radio radio-inline col-xs-3 ">
                                    <label for="title_cash">
                                      <input
                                        type="radio"
                                        name="pay_mode"
                                        value={"cash"}
                                        id={"title_cash"}
                                        checked={pay_mode === 'cash' ? 'checked' : ''}
                                        onChange={(e) =>
                                          setpay_mode(e.target.value)
                                        }
                                      />
                                      <i class="helper"></i>Cash
                                    </label>
                                  </div>
                                  <div class="radio radio-inline col-xs-3 ">
                                    <label for="title_chque">
                                      <input
                                        type="radio"
                                        name="pay_mode"
                                        id={"title_chque"}
                                        value={"cheque"}
                                        checked={pay_mode === 'cheque' ? 'checked' : ''}
                                        onChange={(e) =>
                                          setpay_mode(e.target.value)
                                        }
                                      />
                                      <i class="helper"></i>Cheque
                                    </label>
                                  </div>
                                  <div class="radio radio-inline col-xs-3">
                                    <label for="title_online">
                                      <input
                                        type="radio"
                                        name="pay_mode"
                                        value={"online"}
                                        checked={pay_mode === 'online' ? 'checked' : ''}
                                        id={"title_online"}
                                        onChange={(e) =>
                                          setpay_mode(e.target.value)
                                        }
                                      />
                                      <i class="helper"></i>Online Tx
                                    </label>
                                  </div>

                                  <div class="radio radio-inline col-xs-3">
                                    <label for="title_saving">
                                      <input
                                        type="radio"
                                        name="pay_mode"
                                        value={"saving"}
                                        checked={pay_mode === 'saving' ? 'checked' : ''}
                                        id={"title_saving"}
                                        onChange={(e) =>
                                          setpay_mode(e.target.value)
                                        }
                                      />
                                      <i class="helper"></i>Saving Ac
                                    </label>
                                  </div>
                                  
                                </div>
                                {formErrors.pay_mode && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.pay_mode}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row" style={{ display: pay_mode === 'cheque' ? '' : 'none' }}>
                        <div class="col-sm-12 col-xl-12  m-b-30">
                            <div class="row">
                              <div class="col-sm-5">
                                <h6 class="text-xl-right">
                                  Bank Name<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-7">
                              <select
                                  class="form-control m-b"
                                  name="bank_name"
                                  value={bank_name}
                                  onChange={(e) =>
                                    setbank_name(e.target.value)
                                  }
                                >
                                  <option value={''} disabled>Select bank_name</option>
                                  {bankList.map((bankdata) => (
                                    <option
                                      value={bankdata.id}
                                      selected={
                                        bankdata.id === bank_name
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {bankdata.name}

                                    </option>
                                  ))}
                                </select>
                                {formErrors.bank_name && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.bank_name}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row" style={{ display: pay_mode === 'cheque' ? '' : 'none' }}>
                        <div class="col-sm-12 col-xl-12  m-b-30">
                            <div class="row">
                              <div class="col-sm-5">
                                <h6 class="text-xl-right">
                                  Cheque<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-7">
                                <input
                                  class="form-control"
                                  type="text"
                                  value={cheque_no}
                                  onChange={(e) =>
                                    setcheque_no(e.target.value)
                                  }
                                  placeholder="Enter Cheque"
                                />
                                {formErrors.cheque_no && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.cheque_no}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row" style={{ display: pay_mode === 'cheque' ? '' : 'none' }}>
                        <div class="col-sm-12 col-xl-12  m-b-30">
                            <div class="row">
                              <div class="col-sm-5">
                                <h6 class="text-xl-right">
                                  Cheque Date<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-7">
                                <input
                                  class="form-control"
                                  type="date"
                                  value={cheque_date}
                                  onChange={(e) =>
                                    setcheque_date(e.target.value)
                                  }
                                  placeholder="Enter Cheque Date"
                                />
                                {formErrors.cheque_date && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.cheque_date}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row" style={{ display: pay_mode === 'online' ? '' : 'none' }}>
                        <div class="col-sm-12 col-xl-12  m-b-30">
                            <div class="row">
                              <div class="col-sm-5">
                                <h6 class="text-xl-right">
                                  Transfar date<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-7">
                                <input
                                  class="form-control"
                                  type="date"
                                  value={online_transfer_date}
                                  onChange={(e) =>
                                    setonline_transfer_date(e.target.value)
                                  }
                                  placeholder="Enter Transfar date"
                                />
                                {formErrors.online_transfer_date && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.online_transfer_date}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                      

                        <div class="row" style={{ display: pay_mode === 'online' ? '' : 'none' }}>
                        <div class="col-sm-12 col-xl-12  m-b-30">
                            <div class="row">
                              <div class="col-sm-5">
                                <h6 class="text-xl-right">
                                  UTR/ Transfar No.<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-7">
                                <input
                                  class="form-control"
                                  type="text"
                                  value={tranfer_no}
                                  onChange={(e) =>
                                    settranfer_no(e.target.value)
                                  }
                                  placeholder="Enter Transfar No."
                                />
                                {formErrors.tranfer_no && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.tranfer_no}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row" style={{ display: pay_mode === 'online' ? '' : 'none' }}>
                        <div class="col-sm-12 col-xl-12  m-b-30">
                            <div class="row">
                              <div class="col-sm-5">
                                <h6 class="text-xl-right">
                                  Transfar Mode<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-7">
                                <div class="form-radio row ml-3">
                                  <div class="radio radio-inline col-xs-3 ">
                                    <label for="title_IMPS">
                                      <input
                                        type="radio"
                                        name="transfer_mode"
                                        value={"IMPS"}
                                        id={"title_IMPS"}
                                        checked={transfer_mode === 'IMPS' ? 'checked' : ''}
                                        onChange={(e) =>
                                          settransfer_mode(e.target.value)
                                        }
                                      />
                                      <i class="helper"></i>IMPS
                                    </label>
                                  </div>
                                  <div class="radio radio-inline col-xs-3 ">
                                    <label for="title_VPA">
                                      <input
                                        type="radio"
                                        name="transfer_mode"
                                        id={"title_VPA"}
                                        value={"VPA"}
                                        checked={transfer_mode === 'VPA' ? 'checked' : ''}
                                        onChange={(e) =>
                                          settransfer_mode(e.target.value)
                                        }
                                      />
                                      <i class="helper"></i>VPA
                                    </label>
                                  </div>
                                  <div class="radio radio-inline col-xs-3">
                                    <label for="title_NEFT">
                                      <input
                                        type="radio"
                                        name="transfer_mode"
                                        value={"NEFT/RTGS"}
                                        checked={transfer_mode === 'NEFT/RTGS' ? 'checked' : ''}
                                        id={"title_NEFT"}
                                        onChange={(e) =>
                                          settransfer_mode(e.target.value)
                                        }
                                      />
                                      <i class="helper"></i>NEFT/RTGS
                                    </label>
                                  </div>
                                  
                                </div>
                                {formErrors.transfer_mode && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.transfer_mode}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row" style={{ display: pay_mode === 'saving' ? '' : 'none' }}>
                        <div class="col-sm-12 col-xl-12  m-b-30">
                            <div class="row">
                              <div class="col-sm-5">
                                <h6 class="text-xl-right">
                                Saving Account<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-7">
                                <input
                                  class="form-control"
                                  type="text"
                                  value={saving_account}
                                  onChange={(e) =>
                                    setsaving_account(e.target.value)
                                  }
                                  placeholder="Enter Saving Account No."
                                />
                                {formErrors.saving_account && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.saving_account}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>


                        

                        


                        

                         


                        <div class="row">
                          <div class="col-sm-12 col-xl-12  m-b-30 text-center">
                            <button
                              class="btn btn-success btn-square"
                              type="submit"
                              disabled={submitting}
                            >
                              CLEAR DUE
                            </button>{" "}
                            {
                            shareHolderDetail && (
                              shareHolderDetail.account_for === 'fd' ? (
                                <a href={`/fixed-deposit-account-view/${dataEncript(shareHolderDetail.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                              ) : shareHolderDetail.account_for === 'mis' ? (
                                <a href={`/fixed-deposit-mis-account-view/${dataEncript(shareHolderDetail.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                              ) : shareHolderDetail.account_for === 'rd' ? (
                                <a href={`/recurring-deposit-account-view/${dataEncript(shareHolderDetail.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                              ) : shareHolderDetail.account_for === 'dd' ? (
                                <a href={`/recurring-deposit-account-view/${dataEncript(shareHolderDetail.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                              ) : <></>
                            )
                          }
                          

                            <ToastContainer className="toast-container" />
                          </div>
                        </div>


                      </form>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-5">

                  <div class="card" style={{borderTop:'3px solid green', display: acttype === 'DD' ? '' : 'none'}} >
                  <div class="card-header py-3 text-denger">
                        <h5>Transaction Info</h5>
                              <div class="card-header-right">
                                    <ul class="list-unstyled card-option">
                                        <li><i class="feather icon-minus minimize-card"></i></li>
                                    </ul>
                              </div>
                        </div>
                        <div class="card-block p-0">
                        <form onSubmit={updateDetails}>

                        <div class="table-responsive dt-responsive">
                            <table class="table dtable table-striped table-bordered">
                           
                            <thead>
                                                  <tr>
                                                    <th>DATE</th>
                                                    <th>CHARGE TYPE	</th>
                                                    <th>Amount</th>
                                                    <th>REMARKS</th>
                                                    <th>Status</th>
                                                  </tr>
                                          </thead>
                                          <tbody>


                                          {
                                          transaction_list.length > 0 ? (
                                              transaction_list.map((appointData, index) => (
                                              <tr class="gradeX">


                                              <td> {appointData && appointData.transaction_date && (
                                                  <span>{ appointData.transaction_date?formatDate(appointData.transaction_date):''}</span>
                                                  )}</td>

                                                 <td> {appointData && appointData.transaction_type && (
                                                  <span>{FirstLetterCapitalize(appointData.transaction_type)}</span>
                                                  )}</td>

                                              


                                                  <td> {appointData && appointData.deposit_amt && (
                                                         <span>{formattedAmount(appointData.deposit_amt)}</span>
                                                    )}</td>

                                                 <td> {appointData && appointData.payment_remark && (
                                                  <span>{FirstLetterCapitalize(appointData.payment_remark)}</span>
                                                  )}</td>

                                                <td>
                                                          {
                                                            appointData && (
                                                              appointData.status === 'pending' ? (
                                                                <label className="label label-warning">Pending</label>
                                                              ) : appointData.status === 'approve' ? (
                                                                <label className="label label-success">Approve</label>
                                                              ) : null
                                                            )
                                                          }
                                                    </td>

                                                    

                                               

                                              
                                          </tr>
                                          ))
                                          ) : (
                                          <tr>
                                              <td colSpan="5">No data available</td>
                                          </tr>
                                          )
                                      }
                                            
                                          </tbody>
                            </table>
                        </div>

                        </form>
                        </div>
                    </div>

                    
                  </div>


                </div>


                

              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
          <ToastContainer className="toast-container" />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
