import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../dashboardLayout';
import { useParams } from 'react-router-dom'; 

import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "../../../../config"; // Importing constants directly
// import $ from 'jquery';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



import {
      
      FirstLetterCapitalize,
      dataDecrypt,
      formatDate,
      formattedAmount,
      dataEncript,
      deleteConfirmation,
      infoAlert
      
      
    } from '../../../helper/commonHelpers'; 

    

function AdminLayout() {
    const { accountID }   = useParams();
  const [listDetails, setlistDetails] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [commentsList, setcommentsList] = useState([]);
  const [passbooksList, setpassbooksList] = useState([]);
  const [documents_list, setdocuments_list] = useState([]);
  const [transaction_list, settransaction_list] = useState([]);

  const [is_sms, setis_sms]               = useState('no');
  const [deduct_charges, setdeduct_charges] = useState('no');
  const [account_hold, setaccount_hold] = useState('no');
  const [auto_penalty, setauto_penalty] = useState('no');
  const [acttype, setacttype] = useState('');


  

  const [auto_renue, setauto_renue] = useState('no');
  const [auto_rennew_ins, setauto_rennew_ins] = useState('no');

  const [activeBranchList, setactiveBranchList] = useState([]);
  const [memberList, setmemberList] = useState([]);
  
  const [old_account_no, setold_account_no] = useState('');
  const [member_id, setmember_id] = useState('');
  const [branch_id, setbranch_id] = useState('');
  const [associate_id, setassociate_id] = useState('');
  const [coll_associate_id, setcoll_associate_id] = useState('');

  const [commision_chart, setcommision_chart] = useState('');


  const [formErrors, setFormErrors] = useState({
    branch_id: '',
  });

    useEffect(() => {
      
      axios.get(API_BASE_URL + "api-company/get-active-branch-list", {
        headers: API_HEADERS,
      }).then((response) => {
        if (response.data["status"] > 0) {
        setactiveBranchList(response.data["data"]);
        }
      }).catch((error) => {
        toast.error("Failed to fetch data!");
      });


            axios.get(API_BASE_URL + "api-common/get-account-list?id="+dataDecrypt(accountID), {
                headers: API_HEADERS,
              }).then((response) => {
          
          
                if (response.data["status"] > 0) {

                  console.log(response.data["data"]);

                  setlistDetails(response.data["data"]);
                  setdeduct_charges(response.data["data"].tds_deduction);

                  setaccount_hold(response.data["data"].account_hold);
                  setis_sms(response.data["data"].is_sms);
                  setauto_penalty(response.data["data"].auto_penalty);


                  setmember_id(response.data["data"].member_id);
                  setold_account_no(response.data["data"].old_account_no);
                  setbranch_id(response.data["data"].branch_id);
                  setassociate_id(response.data["data"].associate_id);
                  setcoll_associate_id(response.data["data"].coll_associate_id);

                  
                  setcommision_chart(response.data["data"].acc_commision_chart);
                  setauto_renue(response.data["data"].auto_renew);
                  setauto_rennew_ins(response.data["data"].auto_renew_ins);


                  if (response.data["data"].comments && (response.data["data"].comments).length > 0) {
                      setcommentsList(response.data["data"].comments);
                  }

                  if (response.data["data"].passbooks && (response.data["data"].passbooks).length > 0) {
                    setpassbooksList(response.data["data"].passbooks);
                }

                if (response.data["data"].documents_list && (response.data["data"].documents_list).length > 0) {
                    setdocuments_list(response.data["data"].documents_list);
                }

                if (response.data["data"].transaction_list && (response.data["data"].transaction_list).length > 0) {
                    settransaction_list(response.data["data"].transaction_list);
                }


                response.data['data'] && (
                  response.data['data'].account_for === 'fd' ? (
                    setacttype('FD')
                  ) : response.data['data'].account_for === 'mis' ? (
                    setacttype('MIS')
                  ) : response.data['data'].account_for === 'rd' ? (
                    setacttype('RD')
                  ) : response.data['data'].account_for === 'dd' ? (
                    setacttype('DD')
                  ) : <></>
                )


                }

                console.log(response.data["data"]);
          
              }).catch((error) => {
                toast.error("Failed to fetch data!");
              });



              axios.get(API_BASE_URL + "api-member/get-member-list", {
                headers: API_HEADERS,
              }).then((response) => {
                if (response.data["status"] > 0) {
                setmemberList(response.data["data"]);
                }
              });

  
    }, [accountID]);



    



  
// update kyc status /updateSettingstatus //updateKYCstatus




const updateSetting = async (column) => {
  // e.preventDefault();
  setSubmitting(true);
  let column_val = '';
  if(column==='is_sms')
  {
    column_val = is_sms;
  }
   else if(column==='account_hold')
  {
    column_val = account_hold;
  }
  else if(column==='auto_penalty')
  {
    column_val = auto_penalty;
  }
  else if(column==='deduct_charges')
  {
    column ='tds_deduction';
    column_val = deduct_charges;
  }


  const confirmed = await deleteConfirmation('Are you sure you want to change setting status?');
  if(confirmed===true)
  {

      const formData = {
        auto_renue:auto_renue,
        auto_rennew_ins:auto_rennew_ins, 

        tablename:'tbl_account',  // table name
        where_coloumn_name:'id', // which collumn name you want to  
        where_value:dataDecrypt(accountID), // column value 
        set_coloumn_name:column, // which collumn name you want to update
        set_value:((column_val==='no')?'yes':'no'), // collumn value
        // set_value:account_hold?account_hold:'no', // collumn value

      };
      // console.log(formData);
      // console.log(column_val);
          axios.post(API_BASE_URL + "api-common/update-status", formData, {
          headers: {
              ...API_HEADERS,
          },
          }).then((response) => {
            if (response.data["status"] > 0) {
                toast.success(response.data["message"]);
                setTimeout(() => {
                window.location.reload();
                }, 1000);
              } else {
                  toast.error(response.data["message"]);
              }
          }).catch((error) => {
              toast.error("Failed to submition!");
          }).finally(() => {
              setTimeout(() => {
                  setSubmitting(false);
              }, 300);
          }); // close query hare
  }


}



const updateAutoRenew = async (e) => {
  e.preventDefault();
  setSubmitting(true);
  let errors = {};

  if (!auto_renue) {
    errors.auto_renue = 'AUTO RENEW is required.';
  }
  else if (!auto_rennew_ins) {
    errors.auto_rennew_ins = 'AUTO RENEW INSTRUCTION is required.';
  }

  const confirmed = await deleteConfirmation('Are you sure you want to change setting status?');
  if(confirmed===true)
  {


      const formData = {

        tablename:'tbl_account',  // table name
        where_coloumn_name:'id', // which collumn name you want to  
        where_value:dataDecrypt(accountID), // column value 
        set_coloumn_name1:'auto_renew', // which collumn name you want to update
        set_value1: auto_renue,
        set_coloumn_name2:'auto_renew_ins',
        set_value2: auto_rennew_ins,

      };



          axios.post(API_BASE_URL + "api-common/update-two-data", formData, {
          headers: {
              ...API_HEADERS,
          },
          }).then((response) => {
            if (response.data["status"] > 0) {
                toast.success(response.data["message"]);
                setTimeout(() => {
                window.location.reload();
                }, 1000);
              } else {
                  toast.error(response.data["message"]);
              }
          }).catch((error) => {
              toast.error("Failed to submition!");
          }).finally(() => {
              setTimeout(() => {
                  setSubmitting(false);
              }, 300);
          }); // close query hare
  }


}



const updateDetails = async (column, msg) => {
  // e.preventDefault();
  setSubmitting(true);
let column_val = '';

if(column==='member_id')
{
  column_val = member_id;
}else if(column==='old_account_no')
  {
    column_val = old_account_no;
  }
  else if(column==='branch_id')
  {
    column_val = branch_id;
  }
  else if(column==='associate_id')
  {
    column_val = associate_id;
  }
  else if(column==='coll_associate_id')
  {
    column_val = coll_associate_id;
  }
  else if(column==='commision_chart')
  {
    column = 'acc_commision_chart';
    column_val = commision_chart;
  }
 


  let errors = {};
  const formData = {
      tablename:'tbl_account',  // table name
      where_coloumn_name:'id', // which collumn name you want to  
      where_value:dataDecrypt(accountID), // column value 
      set_coloumn_name:column, // which collumn name you want to update
      set_value:column_val, // collumn value
  };

  if (!column_val) {
      errors[column] = msg+' is required.';
    }

  setFormErrors(errors);
  if (Object.keys(errors).length === 0) {
      const confirmed = await deleteConfirmation('Are you sure you want to change?');
      if(confirmed===true)
      {
              axios.post(API_BASE_URL + "api-common/update-status", formData, {
              headers: {
                  ...API_HEADERS,
              },
              })
              .then((response) => {
              if (response.data["status"] > 0) {
                  toast.success(response.data["message"]);
                  setTimeout(() => {
                     window.location.reload();
                  }, 2300);
              } else {
                  toast.error(response.data["message"]);
              }
              })
              .catch((error) => {
                  toast.error("Failed to submition!");
              })
              .finally(() => {
              setTimeout(() => {
                  setSubmitting(false);
              }, 300);
              }); // close query hare
      }
      else {
        setSubmitting(false);
      }
  } else {
    setSubmitting(false);
  } // hare close error
};


    // useEffect(() => {
    //   const timeout = setTimeout(() => {
    //     if (window.tableDomJquery) {
    //       window.tableDomJquery();
    //     }
    //   }, 1000); // Timeout of 3000 milliseconds (3 seconds)
  
    //   return () => clearTimeout(timeout); // Clear the timeout on component unmount
    // }, [promoterList]);



    



  return (
    <DashboardLayout>
<div class="pcoded-content">
      <div class="pcoded-inner-content">

            <div class="main-body">
                  <div class="page-wrapper">

                  <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>


                        {
                            listDetails && (
                              listDetails.account_for === 'fd' ? (
                                <> FD Account</>
                              ) : listDetails.account_for === 'mis' ? (
                                <>MIS Deposits</>
                              ) : listDetails.account_for === 'rd' ? (
                                <>Recurring Deposits</>
                              ): listDetails.account_for === 'dd' ? (
                                <>Daily Deposits</>
                              ) : <></>
                            )
                          }
                          
                           - 
                        {listDetails && listDetails.account_no && (
                              <>{FirstLetterCapitalize(listDetails.account_no)}</>
                              )}
                        </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                        {
                            listDetails && (
                              listDetails.account_for === 'fd' ? (
                                <a href="/fixed-deposit-account">FD Accounts </a>
                              ) : listDetails.account_for === 'mis' ? (
                                <a href="/fixed-deposit-mis-account">MIS Deposits </a>
                              ) : listDetails.account_for === 'rd' ? (
                                <a href="/recurring-deposit-account">Recurring Deposits </a>
                              ) : listDetails.account_for === 'dd' ? (
                                <a href="/daily-deposit-accounts">Daily Deposits </a>
                              ) : <></>
                            )
                          }
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> {listDetails && listDetails.account_no && (
                              <>{FirstLetterCapitalize(listDetails.account_no)}</>
                              )}</a>
                        </li>

                       
                      </ul>
                    </div>
                  </div>

                  <div class="col-md-12 mt-3">
                   <a class="btn btn-warning mt-1 "  href={`/recurring-deposit-account-installments/${accountID}`}> INSTALLMENT PALAN</a>

                    {/* <a class="btn btn-warning mt-1 ml-1"  href={`/fixed-deposit-account-payouts/${accountID}`}>PAYOUTS</a> */}
                    <a class="btn btn-info mt-1 ml-1 "  href={`/account-transaction/${accountID}`}>VIEW TRANSACTIONS</a>

                    <a class="btn btn-success mt-10 ml-1"  href={`/recurring-deposit-account-mony/${accountID}`}>DEPOSIT MONEY</a>
                    


                             {
                                listDetails && (
                                  listDetails.account_for === 'rd' ? (
                                    <a class="btn btn-danger mt-10 ml-1" href={`/recurring-deposit-account-debit/${accountID}`}>WITHDRAW MONEY</a>
                                  )  :  <></>
                                )
                              }

                    

                      <div class="dropdown-success dropdown open mt-1 ml-1">
                        <button class="btn btn-success dropdown-toggle waves-effect waves-light " type="button" id="dropdown-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">ACCOUNT DETAILS</button>
                        <div class="dropdown-menu" aria-labelledby="dropdown-2" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                        
                          <a class="dropdown-item waves-light waves-effect" href={`/fixed-deposit-change-account-info/${accountID}`} >CHANGE ACCOUNT INFO</a>
                          <a class="dropdown-item waves-light waves-effect" href={`/fixed-deposit-add-nominee/${accountID}`} > {(listDetails.nominee==='yes')?'CHANGE':'ADD'}  NOMINEE</a>
                          <a class="dropdown-item waves-light waves-effect" href={`/recurring-deposit-account-add-minor/${accountID}`} > {(listDetails.nominee==='yes')?'CHANGE':'ADD'}  MINOR</a>
                     
                          {
                          listDetails && (
                            listDetails.account_for === 'rd' ? (
                              <>
                               <a class="dropdown-item waves-light waves-effect"  href={`/recurring-deposit-account-fore-close/${accountID}`}  >FORE CLOSE</a>
                                <a class="dropdown-item waves-light waves-effect" href={`/fixed-deposit-account-remove/${accountID}`}>REMOVE ACCOUNT</a>
                              </>
                            )  :  <></>
                          )
                        }


                        </div>
                      </div>



                      <div class="dropdown-danger dropdown open mt-1 ml-1">
                        <button class="btn btn-danger dropdown-toggle waves-effect waves-light " type="button" id="dropdown-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">PRINT DOCUMENTS</button>
                        <div class="dropdown-menu" aria-labelledby="dropdown-2" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                        <a class="dropdown-item waves-light waves-effect" href="#!">RD BOND</a>
                        <a class="dropdown-item waves-light waves-effect" href="#!">ACCOUNT OPENING FORM</a>
                        <a class="dropdown-item waves-light waves-effect" href="#!">CLOSING FORM</a>

                        </div>
                      </div>

                      

                      {
                          listDetails && (
                            listDetails.account_for === 'rd' ? (
                              <a class="btn btn-warning mt-1 ml-1 "  href={`/recurring-deposit-account-credit-interest/${accountID}`}>CREDIT/ REVERSE INTEREST</a>
                            )  :  <></>
                          )
                        }


                      <div class="dropdown-primary dropdown open mt-1 ml-1">
                      <button class="btn btn-info dropdown-toggle waves-effect waves-light " type="button" id="dropdown-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">DEBIT OTHER CHARGES</button>
                      <div class="dropdown-menu" aria-labelledby="dropdown-2" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                      <a class="dropdown-item waves-light waves-effect" href={`/recurring-deposit-account-other-charges/${accountID}`}>OTHER CHARGES LIST</a>
                      

                      
                      {
                          listDetails && (
                            listDetails.account_for === 'dd' ? (
                              <>
                               <a class="dropdown-item waves-light waves-effect" href={`/daily-deposit-accounts-other-charges-pay/${accountID}`}>DEBIT OTHER CHARGES</a>
                              </>
                            )  :  <a class="dropdown-item waves-light waves-effect" href={`/recurring-deposit-account-other-charges-pay/${accountID}`}>DEBIT OTHER CHARGES</a>
                          )
                        }


                      <a class="dropdown-item waves-light waves-effect" href={`/recurring-deposit-account-clear-due/${accountID}`} >CLEAR DUES</a>
                      </div>
                      </div>

                      

                      {
                          listDetails && (
                            (listDetails.account_for === 'rd' || listDetails.account_for === 'dd') ? (
                              <a class="btn btn-warning mt-1 ml-1 "  href={`/recurring-deposit-account-link-saving-account/${accountID}`}>LINK SAVING ACCOUNT(AUTO DEBIT)</a>
                            )  :  <></>
                          )
                        }

                    {/* <a class="btn btn-warning mt-1 ml-1 "  href={`/account-transaction/${accountID}`}>RE-OPEN ACCOUNT</a> */}


              

                     

                  </div>

                </div>
              </div>


            <div class="page-body">


            <div class="row">
                <div class="col-md-6">
                <div class="card" style={{borderTop:'3px solid green'}}>
                         <div class="card-header">
                         {/* <small style={{float: 'right'}}>
                            <a class="btn btn-info btn-sm" title="" href={`/account-scheem-edit/${dataEncript(listDetails.id)}`}>
                            <i class="feather icon-edit"></i></a>&nbsp;
                            </small> */}

                        </div> 
                        <div class="card-block">
                              <div class="table-responsive dt-responsive">
                                    <table class="table dtable table-striped table-bordered nowrap" style={{width:'100%'}}>
                                        
                                          <tbody>

                                          <tr>
                                            <th>Status</th>
                                            <td>

                                              {
                                                listDetails && (
                                                  listDetails.account_status === 'active' ? (
                                                    <label className="label label-success">Active</label>
                                                  ) : listDetails.account_status === 'inactive' ? (
                                                    <label className="label label-danger">Inactive</label>
                                                  ) : null
                                                )
                                              }
                                            </td>
                                            </tr>
                                             
                                          <tr>
                                            <th>Member</th>
                                            <td> {listDetails && listDetails.member_name && (
                                                <a href={`/view-member/${dataEncript(listDetails.member_id)}`} >{FirstLetterCapitalize(listDetails.member_created_id)} - {FirstLetterCapitalize(listDetails.member_name)}</a>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Create on</th>
                                            <td> {listDetails && listDetails.scheme_name && (
                                                <> </>
                                                )}</td>
                                            </tr>

                                            <tr>
                                            <th>Create By</th>
                                            <td> {listDetails && listDetails.scheme_name && (
                                                <> </>
                                                )}</td>
                                            </tr>

                                            {/* <tr>
                                            <th>Joint Account Member</th>
                                            <td> {listDetails && listDetails.joint_member1 && (
                                                  <>{FirstLetterCapitalize(listDetails.joint_member1)}</>
                                                )}</td>
                                            </tr> */}


                                            <tr>
                                            <th>{acttype} No.</th>
                                            <td> {listDetails && listDetails.account_no && (
                                                <>{(listDetails.account_no)}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Old {acttype} No.	</th>
                                            <td> {listDetails && listDetails.old_account_no && (
                                                <>{listDetails.old_account_no}</>
                                                )} </td>
                                            </tr>
                                            <tr>
                                            <th>Scheme	</th>
                                            <td> {listDetails && listDetails.scheme_name && (
                                                <>{(listDetails.scheme_name)}</>
                                                )}</td>
                                            </tr>

                                            {/* <tr>
                                            <th>Principal Amount	</th>
                                            <td> {listDetails && listDetails.mis_amount && (
                                                <>{formattedAmount(listDetails.mis_amount)}</>
                                                )}</td>
                                            </tr> */}


                                            <tr>
                                            <th>Open Date</th>
                                            <td> {listDetails && listDetails.open_date && (
                                                <>{formatDate(listDetails.open_date)}</>
                                                )}</td>
                                            </tr>

                                            <tr>
                                            <th>Deposit Frequency</th>
                                            <td> {listDetails && listDetails.open_date && (
                                                <></>
                                                )}</td>
                                            </tr>

                                            <tr>
                                            <th>Installment Amount</th>
                                            <td> {listDetails && listDetails.open_date && (
                                                <></>
                                                )}</td>
                                            </tr>

                                            <tr>
                                            <th>Installment Amount Received	</th>
                                            <td> {listDetails && listDetails.open_date && (
                                                <></>
                                                )}</td>
                                            </tr>

                                            <tr>
                                            <th>Interest Credited	</th>
                                            <td> {listDetails && listDetails.open_date && (
                                                <></>
                                                )}</td>
                                            </tr>

                                            <tr>
                                            <th>Balance Available		</th>
                                            <td> {listDetails && listDetails.mis_amount && (
                                                 <>{formattedAmount(listDetails.mis_amount)}</>
                                                )}</td>
                                            </tr>

                                            <tr>
                                            <th>Penalty / Other Charges Due (A)</th>
                                            <td> {listDetails && listDetails.mis_amount && (
                                                 <>{formattedAmount(0)}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Principal Amount Due (B)	</th>
                                            <td> {listDetails && listDetails.mis_amount && (
                                                 <>{formattedAmount(0)}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Total Amount Due (A + B)	</th>
                                            <td> {listDetails && listDetails.mis_amount && (
                                                 <>{formattedAmount(0)}</>
                                                )}</td>
                                            </tr>


                                            <tr>
                                            <th>Maturity Date</th>
                                            <td> {listDetails && listDetails.open_date && (
                                                <>{formatDate(listDetails.open_date)}</>
                                                )}</td>
                                            </tr>

                                            <tr>
                                            <th>Close Date</th>
                                            <td></td>
                                            </tr>


                                            <tr>
                                            <th>Maturity Bonus Amount		</th>
                                            <td> {listDetails && listDetails.mis_amount && (
                                                 <>{formattedAmount(0)}</>
                                                )}</td>
                                            </tr>
                                            
                                            <tr>
                                            <th>Annual Interest Rate (%)			</th>
                                            <td> {listDetails && listDetails.annual_ins_rate && (
                                                  <>{formattedAmount(listDetails.annual_ins_rate)}</>
                                                )}</td>
                                            </tr>
                                            
                                            <tr>
                                            <th>Interest Compounding Interval			</th>
                                            <td> {listDetails && listDetails.mis_amount && (
                                                 <></>
                                                )}</td>
                                            </tr>
                                            
                                         

                                            

                                            <tr>
                                            <th>TDS Deduction</th>
                                            <td>

                                              {
                                                listDetails && (
                                                  listDetails.tds_deduction === 'yes' ? (
                                                    <label className="label label-success">Yes</label>
                                                  ) : <label className="label label-danger">No</label>
                                                )
                                              }
                                            </td>
                                            </tr>

                                            <tr>
                                            <th>Special Account</th>
                                            <td>

                                              {
                                                listDetails && (
                                                  listDetails.account_status === 'yes' ? (
                                                    <label className="label label-success">Yes</label>
                                                  ) :<label className="label label-danger">No</label>
                                                )
                                              }
                                            </td>
                                            </tr>

                                           
                                           
                                          </tbody>
                                        
                                    </table>
                              </div>
                        </div>
                  </div>


                  <div class="card  mt-3 " style={{borderTop:'3px solid red'}} >
                  <div class="card-header">
                        <h5>ALLOCATED PASSBOOK</h5>
                              <div class="card-header-right">
                                    <ul class="list-unstyled card-option">
                                          <li>
                                            <a class="badge badge-default" href={`/passbook-add/${accountID}`} > <i class="feather icon-plus "></i> PASSBOOK</a>
                                          </li>
                                    </ul>
                              </div>
                        </div>
                        <div class="card-block p-0">
                              <form > 
                                <div class="table-responsive dt-responsive">
                                        <table class="table dtable table-striped table-bordered">
                                          <thead>
                                                  <tr>
                                                    <th>Sr</th>
                                                    <th>NAME</th>
                                                    <th>DOB</th>
                                                    <th>ACTIONS</th>
                                                  </tr>
                                          </thead>
                                          
                                          <tbody>
                                                {
                                                passbooksList.length > 0 ? (
                                                    passbooksList.map((passbookData, index) => (
                                                    <tr class="gradeX">
                                                    <td>{index + 1}</td>
                                                    <td > {passbookData && passbookData.passbook_no && (
                                                                <>{FirstLetterCapitalize(passbookData.passbook_no)}</>
                                                                )}</td>

                                                      <td> {passbookData && passbookData.issue_date && (
                                                         <>{ passbookData.issue_date?formatDate(passbookData.issue_date):''}</>
                                                      )}</td>


                                                        <td> 
                                                            <a href={`/passbook-view/${dataEncript(passbookData.id)}`} class='font-weight-normal label label-success'><i class="fa fa-eye"></i></a>&nbsp;
                                                            <a href={`/passbook-edit/${dataEncript(passbookData.id)}`}  class='font-weight-normal label label-info'> <i class="fa fa-edit"></i> </a>
                                                        </td>
                                                    
                                                </tr>
                                                ))
                                                ) : (
                                                <tr>
                                                    <td colSpan="4">No data available</td>
                                                </tr>
                                                )
                                                }
                                                  
                                                </tbody>

                                        </table>
                                        </div>
                                       </form>
                        </div>
                  </div>


                  <div class="card  mt-3 " style={{borderTop:'3px solid red'}} >
                  <div class="card-header py-3 text-denger">
                        <h5>DOCUMENTS</h5>
                              <div class="card-header-right">
                                    <ul class="list-unstyled card-option">
                                        <li>
                                             <a  href={`/account-upload-document/${accountID}`} > <i class="feather icon-upload "></i></a>
                                        </li>
                                        <li><i class="feather icon-minus minimize-card"></i></li>
                                    </ul> 
                              </div>
                        </div>
                        <div class="card-block p-0">
                              <form > 
                                <div class="table-responsive dt-responsive">
                                        <table class="table dtable table-striped table-bordered">
                                          <thead>
                                                  <tr>
                                                    <th>Sr</th>
                                                    <th>NAME</th>
                                                    <th>Url</th>
                                                    <th>ACTIONS</th>
                                                  </tr>
                                          </thead>
                                          <tbody>
                                          {
                                              documents_list.length > 0 ? (
                                                  documents_list.map((documentData, index) => (
                                                  <tr class="gradeX">
                                                  <td>{index + 1}</td>
                                                  <td > {documentData && documentData.doc_name && (
                                                              <>{FirstLetterCapitalize(documentData.doc_name)}</>
                                                              )}</td>

                                                      <td> {documentData && documentData.document && (
                                                              <a href={documentData?.document ? API_BASE_URL + documentData.document : ''} > View  </a>
                                                           )}</td>

                                                  <td> 
                                                      <a href={`/passbook-edit/${dataEncript(documentData.id)}`}  class='font-weight-normal label label-danger'> <i class="fa fa-trash"></i> </a>
                                                  </td>

                                                  
                                              </tr>
                                              ))
                                              ) : (
                                              <tr>
                                                  <td colSpan="4">No data available</td>
                                              </tr>
                                              )
                                          }
                                          </tbody>
                                        </table>
                                        </div>
                                       </form>
                        </div>
                  </div>

                  <div class="card  mt-3 " style={{borderTop:'3px solid green'}} >
                  <div class="card-header py-3 text-denger">
                        <h5>COMMENTS</h5>
                              <div class="card-header-right">
                                    <ul class="list-unstyled card-option">
                                        <li><i class="feather icon-minus minimize-card"></i></li>
                                    </ul>
                              </div>
                        </div>
                        <div class="card-block p-0">
                              <form > 
                                <div class="table-responsive dt-responsive">
                                        <table class="table dtable table-striped table-bordered">
                                        <thead>
                                          <tr>
                                              <th>Sr</th>
                                              <th>COMMENT</th>
                                              <th>COMMENT BY</th>
                                              <th>DATE</th>
                                          </tr>
                                      </thead>
                                      <tbody>


                                      {
                                      commentsList.length > 0 ? (
                                          commentsList.map((appointData, index) => (
                                          <tr class="gradeX">
                                          <td>{index + 1}</td>
                                          <td > {appointData && appointData.comment && (
                                                      <>{FirstLetterCapitalize(appointData.comment)}</>
                                                      )}</td>

                                              <td> {appointData && appointData.comment_by && (
                                                      <>{FirstLetterCapitalize(appointData.comment_by)}</>
                                                      )}</td>

                                          <td> {appointData && appointData.created_at && (
                                                      <>{ appointData.created_at?formatDate(appointData.created_at):''}</>
                                                      )}</td>

                                          
                                      </tr>
                                      ))
                                      ) : (
                                      <tr>
                                          <td colSpan="4">No data available</td>
                                      </tr>
                                      )
                                  }
                                        
                                      </tbody>
                                        </table>
                                        </div>

                                        <div class="row">
                                        <div class="col-md-12 mb-3 text-center">
                                            <a href={`/account-add-comment/${accountID}`}  class="btn btn-info btn-square">ADD COMMENT</a>
                                        </div>
                                      </div>
                                       </form>
                        </div>
                  </div>

                  <div class="card  mt-3 " style={{borderTop:'3px solid yellow'}} >
                  <div class="card-header py-3 text-denger">
                        <h5>Transaction Info</h5>
                              <div class="card-header-right">
                                    <ul class="list-unstyled card-option">
                                        <li><i class="feather icon-minus minimize-card"></i></li>
                                    </ul>
                              </div>
                        </div>
                        <div class="card-block p-0">
                              <form > 
                                <div class="table-responsive dt-responsive">
                                        <table class="table">
                                          <thead>
                                                  <tr>
                                                    <th>DATE</th>
                                                    <th>Type</th>
                                                    <th>Payment Mode</th>
                                                    <th>Amount</th>
                                                    <th>Status</th>
                                                  </tr>
                                          </thead>
                                          <tbody>


                                          {
                                          transaction_list.length > 0 ? (
                                              transaction_list.map((appointData, index) => (
                                              <tr class="gradeX">


                                              <td> {appointData && appointData.transaction_date && (
                                                  <span>{ appointData.transaction_date?formatDate(appointData.transaction_date):''}</span>
                                                  )}</td>

                                                 <td> {appointData && appointData.transaction_type && (
                                                  <span>{FirstLetterCapitalize(appointData.transaction_type)}</span>
                                                  )}</td>

                                                <td> {appointData && appointData.pay_mode && (
                                                  <span>{FirstLetterCapitalize(appointData.pay_mode)}</span>
                                                  )}</td>


                                                  <td> {appointData && appointData.deposit_amt && (
                                                         <span>{formattedAmount(appointData.deposit_amt)}</span>
                                                    )}</td>

                                                <td>
                                                          {
                                                            appointData && (
                                                              appointData.status === 'pending' ? (
                                                                <label className="label label-warning">Pending</label>
                                                              ) : appointData.status === 'approve' ? (
                                                                <label className="label label-success">Approve</label>
                                                              ) : null
                                                            )
                                                          }
                                                    </td>

                                                    

                                               

                                              
                                          </tr>
                                          ))
                                          ) : (
                                          <tr>
                                              <td colSpan="10">No data available</td>
                                          </tr>
                                          )
                                      }
                                            
                                          </tbody>
                                        </table>
                                        </div>

                                      <div class="row">
                                        <div class="col-md-12 mb-3 text-center">
                                            <a href={`/account-transaction/${accountID}`} class="btn btn-info btn-square">View All</a>
                                        </div>
                                      </div>

                                       </form>
                        </div>
                  </div>
                  

                </div>
                <div class="col-md-6">
                


                <div class="card" style={{borderTop:'3px solid red'}}>
                  <div class="card-header ">
                        <h5>SETTINGS</h5>
                              <div class="card-header-right">
                                    <ul class="list-unstyled card-option">
                                          <li><i class="feather icon-minus minimize-card"></i></li>
                                    </ul>
                              </div>
                        </div>
                        <div class="card-block">
                            
                              <form> 
                                <div class="table-responsive dt-responsive">
                                        <table class="table">
                                         <tr class="border-0">
                                                <th class="text-center" style={{ borderTop: '0px solid #dee2e6' }} >IS SMS </th>
                                                <td style={{ borderTop: '0px solid #dee2e6' }} >

                                                    <input type="checkbox" class="js-large" name="is_sms" checked={is_sms === 'yes'} value="yes" onChange={(e) => { e.preventDefault(); setis_sms(e.target.checked ? 'yes' : 'no');  updateSetting('is_sms'); }}  />

                                                </td>
                                            </tr>

                                      
                                            <tr class="border-0">
                                                <th class="text-center" style={{width:'0px;'}} >ACCOUNT ON HOLD</th>
                                                <td  >
                                                    <input type="checkbox" class="js-large" name="account_hold" checked={account_hold === 'yes'} value="yes"  onChange={(e) => { e.preventDefault(); setaccount_hold(e.target.checked ? 'yes' : 'no');  updateSetting('account_hold'); }} />
                                                </td>
                                            </tr>

                                            <tr class="border-0">
                                                <th class="text-center" style={{width:'0px;'}} >AUTO PENALTY <span class=""   onClick={() => infoAlert("if 'ON' - Penalty will debit automatically as per scheme or master settings., if you don't want auto penalty to debit for this account, you can set if to 'OFF'")} ><i class="icofont icofont-info-circle"></i></span></th>
                                                <td  >
                                                    <input type="checkbox" class="js-large" name="auto_penalty" checked={auto_penalty === 'yes'} value="yes"  onChange={(e) => { e.preventDefault(); setauto_penalty(e.target.checked ? 'yes' : 'no');  updateSetting('auto_penalty'); }} />
                                                </td>
                                            </tr>
                                            
                                            <tr>
                                                <th class="text-center" style={{width:'0px;'}}>DEDUCT TDS &nbsp;
                                                
                                                </th>
                                                <td>
                                                <input type="checkbox" class="js-large" name="deduct_charges" value={"yes"}  checked={deduct_charges === 'yes' ? 'checked' : ''}  onChange={(e) => { e.preventDefault(); setdeduct_charges(e.target.checked ? 'yes' : 'no');  updateSetting('deduct_charges'); }} />
                                                    {formErrors.kyc_status && (
                                                        <span style={{ color: "red" }}>
                                                        {formErrors.kyc_status}
                                                        </span>
                                                    )}
                                                </td>
                                            </tr>
                                           
                                        </table>
                                        </div>
                                            </form>
                        </div>
                  </div>



                  <div class="card" style={{borderTop:'3px solid red', display:'none' }}>
                  <div class="card-header ">
                        <h5>AUTO RENEW SETTINGS</h5>
                              <div class="card-header-right">
                                    <ul class="list-unstyled card-option">
                                          <li><i class="feather icon-minus minimize-card"></i></li>
                                    </ul>
                              </div>
                        </div>
                        <div class="card-block">
                            
                              <form onSubmit={updateAutoRenew}> 
                                <div class="table-responsive dt-responsive">
                                        <table class="table">
                                         <tr class="border-0">
                                                <th class="text-center" style={{ borderTop: '0px solid #dee2e6' }} >AUTO RENEW </th>
                                                <td style={{ borderTop: '0px solid #dee2e6' }} >

                                                <div class="form-radio row ml-3">
                                                    <div class="radio radio-inline col-xs-3 ">
                                                    <label for="title_yes">
                                                        <input
                                                        type="radio"
                                                        name="auto_renue"
                                                        value={"yes"}
                                                        id={"title_yes"}
                                                        checked={auto_renue === 'yes' ? 'checked' : ''}
                                                        onChange={(e) =>
                                                            setauto_renue(e.target.value)
                                                        }
                                                        />
                                                        <i class="helper"></i>Yes
                                                    </label>
                                                    </div>
                                                    <div class="radio radio-inline col-xs-3 ">
                                                    <label for="title_no">
                                                        <input
                                                        type="radio"
                                                        name="auto_renue"
                                                        id={"title_no"}
                                                        value={"no"}
                                                        checked={auto_renue === 'no' ? 'checked' : ''}
                                                        onChange={(e) =>
                                                            setauto_renue(e.target.value)
                                                        }
                                                        />
                                                        <i class="helper"></i>No
                                                    </label>
                                                    </div>
                                                
                                                    
                                                </div>

                                                </td>
                                            </tr>

                                      
                                            <tr class="border-0">
                                                <th class="text-center" style={{width:'0px;'}} >AUTO RENEW INSTRUCTION</th>
                                                <td  >
                                                <select
                                                class="form-control m-b"
                                                name="auto_rennew_ins"
                                                value={auto_rennew_ins}
                                                onChange={(e) =>
                                                    setauto_rennew_ins(e.target.value)
                                                }
                                                >
                                                <option value="">Select Instruction</option>
                                                <option value="REINVEST_PRINCIPAL">REINVEST_PRINCIPAL</option>
                                                <option selected="selected" value="REINVEST_PRINCIPAL_AND_INTEREST">REINVEST_PRINCIPAL_AND_INTEREST</option>
                                                </select>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td colSpan={'2'} class="text-center" style={{width:'0px;'}}>
                                                     <button class="btn btn-success btn-square PX-3" type="submit" disabled={submitting} > UPDATE </button>
                                                </td>
                                            </tr>
                                            
                                            
                                           
                                        </table>
                                        </div>
                                            </form>
                        </div>
                  </div>



                 


                  <div class="card" style={{borderTop:'3px solid green'}}>
                         
                        <div class="card-block p-0">
                              <div class="table-responsive dt-responsive">
                             
                                    <table class="table " style={{width:'100%'}}>
                                        
                                          <tbody>
                                             

                                            <tr>
                                              <th>Old {acttype} No.	</th>
                                              <td> 
                                              <form onSubmit={(e) => { e.preventDefault(); updateDetails('old_account_no', `Old ${acttype} No.` ); }}>
                                              <div class="input-group">
                                                    
                                                    <input type="text" class="form-control m-b" name="old_account_no" placeholder={`Enter Old ${acttype} No.`}
                                                      value={old_account_no}
                                                      onChange={(e) =>
                                                          setold_account_no(e.target.value)
                                                      }
                                                />
                                                      <div class="input-group-prepend" style={{width:'auto'}}>
                                                          <input class="form-control btn btn-success" disabled={submitting} type="submit" value="Update" />
                                                      </div>
                                                  </div>

                                                  {formErrors.old_account_no && (
                                                      <span style={{ color: "red" }}>
                                                      {formErrors.old_account_no}
                                                      </span>
                                                  )}

                                                    </form>
                                              </td>
                                            </tr>


                                            <tr>
                                            <th>Member</th>
                                            <td> 
                                            <form  onSubmit={(e) => { e.preventDefault(); updateDetails('member_id', 'Member' ); }}>
                                            <div class="input-group">
                                                <select
                                                      class="form-control m-b"
                                                      name="member_id"
                                                      value={member_id}

                                                      onChange={(e) =>
                                                        setmember_id(e.target.value)
                                                        }
                                                    >
                                                      <option value={''} disabled>Select Member</option>
                                                      {memberList.map((aBranchData, mindex) => (
                                                        <option
                                                          value={aBranchData.id}
                                                          selected={
                                                            aBranchData.id === member_id
                                                              ? "selected"
                                                              : ""
                                                          }
                                                        >
                                                          {aBranchData.fname} &nbsp;
                                                          {aBranchData.lname}

                                                        </option>
                                                      ))}
                                                    </select>
                                                    <div class="input-group-prepend" style={{width:'auto'}}>
                                                        <input class="form-control btn btn-success" disabled={submitting} type="submit" value="Update" />
                                                    </div>
                                                </div>

                                                {formErrors.member_id && (
                                                    <span style={{ color: "red" }}>
                                                    {formErrors.member_id}
                                                    </span>
                                                )}

                                                   </form>
                                            </td>
                                            </tr>


                                            <tr>
                                            <th>Branch</th>
                                            <td> 
                                            <form onSubmit={(e) => { e.preventDefault(); updateDetails('branch_id', 'Branch' ); }} >
                                            <div class="input-group">
                                                
                                                <select
                                                    class="form-control m-b"
                                                    name="branch_id"
                                                    value={branch_id}
                                                    onChange={(e) =>
                                                      setbranch_id(e.target.value)
                                                    }
                                                  >
                                                    <option value={''} disabled>Branch</option>
                                                    {activeBranchList.map((branchData) => (
                                                      <option
                                                        value={branchData.id}
                                                        selected={
                                                          branchData.id === branch_id
                                                            ? "selected"
                                                            : ""
                                                        }
                                                      >
                                                        {branchData.branch_name}

                                                      </option>
                                                    ))}
                                                  </select>

                                                    <div class="input-group-prepend" style={{width:'auto'}}>
                                                        <input class="form-control btn btn-success" disabled={submitting} type="submit" value="Update" />
                                                    </div>
                                                </div>

                                                {formErrors.branch_id && (
                                                    <span style={{ color: "red" }}>
                                                    {formErrors.branch_id}
                                                    </span>
                                                )}

                                                   </form>
                                            </td>
                                            </tr>

                                          


                                            <tr>
                                            <th>Associate/ Advisor/ Staff</th>
                                            <td> 
                                            <form onSubmit={(e) => { e.preventDefault(); updateDetails('associate_id', 'Associate/ Advisor' ); }}>
                                            <div class="input-group">
                                                  <select
                                                          class="form-control m-b"
                                                          name="associate_id"
                                                          value={associate_id}
                                                          onChange={(e) =>
                                                            setassociate_id(e.target.value)
                                                          }
                                                        >
                                                          <option value={''} disabled>Branch</option>
                                                          {activeBranchList.map((branchData) => (
                                                            <option
                                                              value={branchData.id}
                                                              selected={
                                                                branchData.id === associate_id
                                                                  ? "selected"
                                                                  : ""
                                                              }
                                                            >
                                                              {branchData.branch_name}

                                                            </option>
                                                          ))}
                                                        </select>
                                                    <div class="input-group-prepend" style={{width:'auto'}}>
                                                        <input class="form-control btn btn-success" disabled={submitting} type="submit" value="Update" />
                                                    </div>
                                                </div>

                                                {formErrors.associate_id && (
                                                    <span style={{ color: "red" }}>
                                                    {formErrors.associate_id}
                                                    </span>
                                                )}

                                                   </form>
                                            </td>
                                            </tr>


                                            <tr>
                                            <th>Collection Associate/ Advisor/ Staff</th>
                                            <td> 
                                            <form onSubmit={(e) => { e.preventDefault(); updateDetails('coll_associate_id', 'Collection Associate ' ); }}>
                                            <div class="input-group">
                                                  <select
                                                          class="form-control m-b"
                                                          name="coll_associate_id"
                                                          value={coll_associate_id}
                                                          onChange={(e) =>
                                                            setcoll_associate_id(e.target.value)
                                                          }
                                                        >
                                                          <option value={''} disabled>Branch</option>
                                                          {activeBranchList.map((branchData) => (
                                                            <option
                                                              value={branchData.id}
                                                              selected={
                                                                branchData.id === coll_associate_id
                                                                  ? "selected"
                                                                  : ""
                                                              }
                                                            >
                                                              {branchData.branch_name}

                                                            </option>
                                                          ))}
                                                        </select>
                                                    <div class="input-group-prepend" style={{width:'auto'}}>
                                                        <input class="form-control btn btn-success" disabled={submitting} type="submit" value="Update" />
                                                    </div>
                                                </div>

                                                {formErrors.coll_associate_id && (
                                                    <span style={{ color: "red" }}>
                                                    {formErrors.coll_associate_id}
                                                    </span>
                                                )}

                                                   </form>
                                            </td>
                                            </tr>



                                            <tr>
                                                <th>Current Chart</th>
                                                <td> {listDetails && listDetails.acc_commision_chart && (
                                                <a href={`/view-member/${dataEncript(listDetails.acc_commision_chart)}`} >{FirstLetterCapitalize(listDetails.acc_commision_chart)}</a>
                                                )}</td>
                                            </tr>

                                            <tr>
                                            <th>Commission Chart</th>
                                            <td> 
                                            <form onSubmit={(e) => { e.preventDefault(); updateDetails('commision_chart', 'Commission Chart' ); }}>
                                            <div class="input-group">
                                                  <select
                                                          class="form-control m-b"
                                                          name="commision_chart"
                                                          value={commision_chart}
                                                          onChange={(e) =>
                                                            setcommision_chart(e.target.value)
                                                          }
                                                        >
                                                          <option value="">Select Chart</option>
                                                            <option value="6 MONTH (Tenure - 1 M)">6 MONTH (Tenure - 1 M)</option>
                                                            <option value="AK (Tenure - 1 M)">AK (Tenure - 1 M)</option>
                                                            <option value="BUDH TESTING (Tenure - 1 M)">BUDH TESTING (Tenure - 1 M)</option>
                                                            <option value="Manikaran Commision Chart (Tenure - 6 M)">Manikaran Commision Chart (Tenure - 6 M)</option>
                                                            <option value="Ultima 10 (Tenure - 60 M)">Ultima 10 (Tenure - 60 M)</option>
                                                            <option value="FD 6 MONTH (Tenure - 6 M)">FD 6 MONTH (Tenure - 6 M)</option>
                                                        </select>
                                                    <div class="input-group-prepend" style={{width:'auto'}}>
                                                        <input class="form-control btn btn-success" disabled={submitting} type="submit" value="Update" />
                                                    </div>
                                                </div>

                                                {formErrors.commision_chart && (
                                                    <span style={{ color: "red" }}>
                                                    {formErrors.commision_chart}
                                                    </span>
                                                )}

                                                   </form>
                                            </td>
                                            </tr>

                                        

                                          </tbody>
                                    </table>
                              </div>
                        </div>
                  </div>


                  <div class="card  mt-3 " style={{borderTop:'3px solid red', display:'none'}} >
                  <div class="card-header py-3 text-denger">
                        <h5>Fore Closure Info</h5>
                        <div class="card-header-right">
                                    <ul class="list-unstyled card-option">
                                        <li><i class="feather icon-minus minimize-card"></i></li>
                                    </ul>
                              </div>
                        </div>
                        <div class="card-block p-0" style={{display:'none'}}>
                              <form > 
                                <div class="table-responsive dt-responsive">
                                        <table class="table">
                                          
                                          <tbody>

                                          <tr>
                                            <th>Fore Close Date</th>
                                            <td> {listDetails && listDetails.branch_name && (
                                                <></>
                                                )}</td>
                                        </tr>
                                        <tr>
                                            <th>Principal Amount</th>
                                            <td> {listDetails && listDetails.mis_amount && (
                                                <>{formattedAmount(listDetails.mis_amount)}</>
                                            )}</td>
                                        </tr>

                                        <tr>
                                            <th>Current Balance (A)</th>
                                            <td> {listDetails && listDetails.mis_amount && (
                                                <>{formattedAmount(listDetails.mis_amount)}</>
                                            )}</td>
                                        </tr>

                                        <tr>
                                            <th>Balance Interest to Credit (B)</th>
                                            <td> {listDetails && listDetails.mis_amount && (
                                                <>{formattedAmount(0)}</>
                                            )}</td>
                                        </tr>
                                        <tr>
                                            <th>TDS on Balance Interest to Credit (C)	</th>
                                            <td> {listDetails && listDetails.mis_amount && (
                                                <>{formattedAmount(0)}</>
                                            )}</td>
                                        </tr>

                                        <tr>
                                            <th>Penal Charges to Deduct (D)	</th>
                                            <td> {listDetails && listDetails.mis_amount && (
                                                <>{formattedAmount(0)}</>
                                            )}</td>
                                        </tr>

                                        <tr>
                                            <th>Fore Closure Charges (E)	</th>
                                            <td> {listDetails && listDetails.mis_amount && (
                                                <>{formattedAmount(0)}</>
                                            )}</td>
                                        </tr>

                                        <tr>
                                            <th>Final Payable Amount (A + B - C - D - E)	</th>
                                            <td> {listDetails && listDetails.mis_amount && (
                                                <>{formattedAmount(0)}</>
                                            )}</td>
                                        </tr>


                                        
                                       
                                        
                                            
                                          </tbody>
                                        </table>
                                        </div>

                                    </form>
                                </div>
                           </div>

                        

                  <div class="card  mt-3 " style={{borderTop:'3px solid green'}} >
                  <div class="card-header py-3 text-denger">
                        <h5>Scheme Info</h5>
                              <div class="card-header-right">
                                    <ul class="list-unstyled card-option">
                                        <li><i class="feather icon-plus minimize-card"></i></li>
                                    </ul>
                              </div>
                        </div>
                        <div class="card-block p-0" style={{display:'none'}}>
                              <form > 
                                <div class="table-responsive dt-responsive">
                                        <table class="table">
                                          
                                          <tbody>

                                          <tr>
                                            <th>Scheme Name</th>
                                            <td> {listDetails && listDetails.scheme_name && (
                                            <>{FirstLetterCapitalize(listDetails.scheme_name)}</>
                                            )}</td>
                                        </tr>
                                        <tr>
                                            <th>Scheme Code</th>
                                            <td> {listDetails && listDetails.scheme_code && (
                                                 <>{FirstLetterCapitalize(listDetails.scheme_code)}</>
                                            )}</td>
                                        </tr>
                                        <tr>
                                            <th>Minimum Locking Period</th>
                                            <td> {listDetails && listDetails.ins_lock_period && (
                                               <>{FirstLetterCapitalize(listDetails.ins_lock_period)}</>
                                            )}</td>
                                        </tr>
                                        <tr>
                                            <th>Interest Locking Period</th>
                                            <td> {listDetails && listDetails.ins_lock_period && (
                                            <>{FirstLetterCapitalize(listDetails.ins_lock_period)}</>
                                            )}</td>
                                        </tr>

                                        <tr>
                                            <th>Deposit Frequency	</th>
                                            <td> {listDetails && listDetails.bonous_rate_value && (
                                            <></>
                                            )}</td>
                                        </tr>

                                       

                                        <tr>
                                            <th>Annual Interest Rate (%)	</th>
                                            <td> {listDetails && listDetails.annual_ins_rate && (
                                              <>{(listDetails.annual_ins_rate)}</>
                                            )}</td>
                                        </tr>

                                        <tr>
                                            <th>Interest Compounding Interval		</th>
                                            <td> {listDetails && listDetails.bonous_rate_value && (
                                            <></>
                                            )}</td>
                                        </tr>

                                        <tr>
                                            <th>Tenure		</th>
                                            <td> {listDetails && listDetails.bonous_rate_value && (
                                            <></>
                                            )}</td>
                                        </tr>
                                        


                                  


                                        <tr>
                                            <th>Cancellation Charges</th>
                                            <td> {listDetails && listDetails.cancel_charge_value && (
                                            <>{formattedAmount(listDetails.cancel_charge_value)}</>
                                            )}</td>
                                        </tr>
                                        <tr>
                                            <th>Penal Charges (%)</th>
                                            <td> {listDetails && listDetails.penal_charge && (
                                            <>{formattedAmount(listDetails.penal_charge)}</>
                                            )}</td>
                                        </tr>

                                        <tr>
                                            <th>Bonus Rate</th>
                                            <td> {listDetails && listDetails.bonous_rate_value && (
                                            <>{FirstLetterCapitalize(listDetails.bonous_rate_value)} {FirstLetterCapitalize(listDetails.bonous_rate_type)}</>
                                            )}</td>
                                        </tr>

                                        <tr>
                                            <th>Minimum Amount	</th>
                                            <td> {listDetails && listDetails.mis_amount && (
                                            <>{formattedAmount(listDetails.mis_amount)}</>
                                            )}</td>
                                        </tr>

                                        <tr>
                                            <th>Skip Days (For DD Only)		</th>
                                            <td> {listDetails && listDetails.mis_amount && (
                                            <></>
                                            )}</td>
                                        </tr>

                                            
                                          </tbody>
                                        </table>
                                        </div>

                                    </form>
                                </div>
                    </div>


                    <div class="card  mt-3 " style={{borderTop:'3px solid green'}} >
                  <div class="card-header py-3 text-denger">
                        <h5>{acttype} Maturity Info</h5>
                              <div class="card-header-right">
                                    <ul class="list-unstyled card-option">
                                        <li><i class="feather icon-plus minimize-card"></i></li>
                                    </ul>
                              </div>
                        </div>
                        <div class="card-block p-0" style={{display:'none'}}>
                              <form > 
                                <div class="table-responsive dt-responsive">
                                        <table class="table">
                                          
                                          <tbody>

                                          <tr>
                                            <th>Maturity Date</th>
                                            <td> {listDetails && listDetails.scheme_name && (
                                            <></>
                                            )}</td>
                                        </tr>
                                        <tr>
                                            <th>Principal Amount (A)</th>
                                            <td> {listDetails && listDetails.mis_amount && (
                                                 <>{formattedAmount(listDetails.mis_amount)}</>
                                            )}</td>
                                        </tr>
                                        <tr>
                                            <th>Total Interest (B)</th>
                                            <td> {listDetails && listDetails.ins_lock_period && (
                                               <></>
                                            )}</td>
                                        </tr>
                                        <tr>
                                            <th>Bonus Amount (C)	</th>
                                            <td> {listDetails && listDetails.ins_lock_period && (
                                            <></>
                                            )}</td>
                                        </tr>

                                       
                                        <tr>
                                            <th>Net Maturity Amount (A + B + C)</th>
                                            <td> {listDetails && listDetails.penal_charge && (
                                            <></>
                                            )}</td>
                                        </tr>
                                        
                                            
                                          </tbody>
                                        </table>
                                        </div>

                                    </form>
                                </div>
                    </div>



                    <div class="card  mt-3 " style={{borderTop:'3px solid red', display:'none'}} >
                  <div class="card-header py-3 text-denger">
                        <h5>MIS Info</h5>
                              <div class="card-header-right">
                                    <ul class="list-unstyled card-option">
                                        <li><i class="feather icon-minus minimize-card"></i></li>
                                    </ul>
                              </div>
                        </div>
                        <div class="card-block p-0" style={{display:'none'}}>
                              <form > 
                                <div class="table-responsive dt-responsive">
                                        <table class="table">
                                          
                                          <tbody>

                                          <tr>
                                            <th>Interest Credited</th>
                                            <td> {listDetails && listDetails.scheme_name && (
                                               <>{formattedAmount(0)}</>
                                            )}</td>
                                        </tr>
                                        <tr>
                                            <th>Interest Released</th>
                                            <td> {listDetails && listDetails.mis_amount && (
                                                 <>{formattedAmount(0)}</>
                                            )}</td>
                                        </tr>
                                        <tr>
                                            <th>TDS Deducted</th>
                                            <td> {listDetails && listDetails.ins_lock_period && (
                                                 <>{formattedAmount(0)}</>
                                            )}</td>
                                        </tr>
                                       
                                        
                                            
                                          </tbody>
                                        </table>
                                        </div>

                                    </form>
                                </div>
                           </div>

                           <div class="card  mt-3 " style={{borderTop:'3px solid green'}} >
                  <div class="card-header py-3 text-denger">
                        <h5>Branch Info</h5>
                              <div class="card-header-right">
                                    <ul class="list-unstyled card-option">
                                        <li><i class="feather icon-minus minimize-card"></i></li>
                                    </ul>
                              </div>
                        </div>
                        <div class="card-block p-0" >
                              <form > 
                                <div class="table-responsive dt-responsive">
                                        <table class="table">
                                          
                                          <tbody>

                                          <tr>
                                            <th>Branch</th>
                                            <td> {listDetails && listDetails.branch_name && (
                                                <>{FirstLetterCapitalize(listDetails.branch_name)}</>
                                                )}</td>
                                        </tr>
                                        <tr>
                                            <th>Associate/ Advisor/ Staff</th>
                                            <td> {listDetails && listDetails.associate_name && (
                                                <>{FirstLetterCapitalize(listDetails.associate_name)}</>
                                            )}</td>
                                        </tr>
                                        <tr>
                                            <th>Joint Account</th>
                                            <td>

                                              {
                                                listDetails && (
                                                  listDetails.account_type === 'joint' ? (
                                                    <label className="label label-success">Yes</label>
                                                  ) : listDetails.account_type === 'no' ? (
                                                    <label className="label label-danger">No</label>
                                                  ) :  <label className="label label-danger">No</label>
                                                )
                                              }
                                            </td>

                                            
                                        </tr>

                                        {/* <tr>
                                            <th>Joint Account Member Name</th>
                                            <td> {listDetails && listDetails.joint_member1 && (
                                                 <>{FirstLetterCapitalize(listDetails.joint_member1)}</>
                                            )}</td>
                                        </tr> */}
                                       
                                        
                                            
                                          </tbody>
                                        </table>
                                        </div>

                                    </form>
                                </div>
                           </div>




                </div>
            </div>

              






            </div>

      </div>
</div>
<ToastContainer className="toast-container" />
<div id="styleSelector">
</div>
</div>
</div>
    </DashboardLayout>
  );
}

export default AdminLayout;
