import React, { useState, useEffect } from "react";
import DashboardLayout from "../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {   dataDecrypt, FirstLetterCapitalize, formatDate, dataEncript, deleteConfirmation, formattedAmount } from "../../../helper/commonHelpers";
//formatDate

function CompanyDetails() {
  const [submitting, setSubmitting] = useState(false);
  const { accountID }   = useParams();
  const [shareHolderDetail, setshareHolderDetail] = useState([]);
  const [saving_accountList, setsaving_accountList] = useState([]);
  const [memberDetails, setmemberDetails] = useState([]);
  const [schemeDetails, setschemeDetails] = useState([]);


  const [formErrors, setFormErrors] = useState({
    member_id: "",
  });



  const[member_id, setmember_id] = useState('');
  const[acttype, setacttype] = useState('');



  useEffect(() => {
  

      axios.get(API_BASE_URL + "api-common/get-account-list?id="+dataDecrypt(accountID), {
        headers: API_HEADERS,
      }).then((response) => {
        if (response.data["status"] > 0) {
            // console.log(response.data["data"]);
            setshareHolderDetail(response.data["data"]);

            response.data['data'] && (
                response.data['data'].account_for === 'fd' ? (
                  setacttype('FD')
                ) : response.data['data'].account_for === 'mis' ? (
                  setacttype('MIS')
                ): response.data['data'].account_for === 'rd' ? (
                  setacttype('RD')
                ): response.data['data'].account_for === 'dd' ? (
                  setacttype('DD')
                ) : <></>
              )


              axios.get(API_BASE_URL+'api-member/get-member-list?member_id='+(response.data["data"].member_id), { headers: API_HEADERS })
              .then(response => {
                    if (response.data["status"] > 0) {
                      setmemberDetails(response.data["data"]);
                      // console.log(response.data["data"]);
                      } 
               });

               axios.get(API_BASE_URL + "api-common/get-account-scheme-details?id="+(response.data["data"].scheme_id), {
                headers: API_HEADERS,
                }).then((response) => {
            
                    if (response.data["status"] > 0) {
                        setschemeDetails(response.data["data"]);
                    }
            
                });


        } else {
            toast.error(response.data["message"]);
        }
      });

    
  

      let listCondition = `account_for = 'saving'`;
      axios.get(API_BASE_URL+'api-common/get-account-list?condition='+listCondition, { headers: API_HEADERS })
      .then(response => {
            if (response.data["status"] > 0) {
                setsaving_accountList(response.data['data']);
            }
            // console.log(response.data['data']);
      })
      .catch(error => {
        toast.error("Failed to fetch role list list!");
      });
     


  }, [accountID]);




  const updateDetails = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));

    const formData = {
        id:dataDecrypt(accountID),
         member_id:member_id,

    };

//   console.log(formData);
     

    //   if(account_type==='joint')
    //   {
  
    //     if (!joint_ac1_member_id) {
    //         errors.joint_ac1_member_id = 'Joint A/c Member1 is required.';
    //       }
    
    //   }



    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {

        const confirmed = await deleteConfirmation(' to link FD account for auto credit interest to saving account??');
        if(confirmed===true)
        {

      axios.post(API_BASE_URL + "api-saving-account/change-account-type", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
            //   window.location.href = "/account-transaction-view/"+accountID+'/'+response.data["data"].id;

              window.location.reload();
            }, 2000);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 1000);
        }); // close query hare

    }
    setSubmitting(false);
    } else {
      setSubmitting(false);
    } // hare close error
  };


  

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-12">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4> 
                            
                        {
                            shareHolderDetail && (
                              shareHolderDetail.account_for === 'fd' ? (
                                <> Fixed Deposit </>
                              ) : shareHolderDetail.account_for === 'mis' ? (
                                <>MIS Deposits </>
                              ) : shareHolderDetail.account_for === 'rd' ? (
                                <> Recurring Deposits </>
                              ) : shareHolderDetail.account_for === 'dd' ? (
                                <> Daily Deposits </>
                              ) : <></>
                            )
                          }
                            
                             - {shareHolderDetail && shareHolderDetail.account_no && (
                                    <>{shareHolderDetail.account_no}</>
                                    )}  Link Saving Account For Auto Debit {acttype} Installment </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                        {
                            shareHolderDetail && (
                              shareHolderDetail.account_for === 'fd' ? (
                                <a href="/fixed-deposit-account">FD Accounts </a>
                              ) : shareHolderDetail.account_for === 'mis' ? (
                                <a href="/fixed-deposit-mis-account">MIS Deposits </a>
                              ) : shareHolderDetail.account_for === 'rd' ? (
                                <a href="/recurring-deposit-account">Recurring Deposits </a>
                              )  : shareHolderDetail.account_for === 'dd' ? (
                                <a href="/recurring-deposit-account">Daily Deposits </a>
                              ) : <></>
                            )
                          }
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                        {
                            shareHolderDetail && (
                              shareHolderDetail.account_for === 'fd' ? (
                                <a href={`/fixed-deposit-account-view/${dataEncript(shareHolderDetail.id)}`}> {shareHolderDetail && shareHolderDetail.account_no && (
                                  <>{shareHolderDetail.account_no}</>
                                  )} </a>
                              ) : shareHolderDetail.account_for === 'mis' ? (
                                <a href={`/fixed-deposit-mis-account-view/${dataEncript(shareHolderDetail.id)}`}> {shareHolderDetail && shareHolderDetail.account_no && (
                                  <>{shareHolderDetail.account_no}</>
                                  )} </a>
                              ) : shareHolderDetail.account_for === 'rd' ? (
                                <a href={`/recurring-deposit-account-view/${dataEncript(shareHolderDetail.id)}`}> {shareHolderDetail && shareHolderDetail.account_no && (
                                  <>{shareHolderDetail.account_no}</>
                                  )} </a>
                              ): shareHolderDetail.account_for === 'dd' ? (
                                <a href={`/recurring-deposit-account-view/${dataEncript(shareHolderDetail.id)}`}> {shareHolderDetail && shareHolderDetail.account_no && (
                                  <>{shareHolderDetail.account_no}</>
                                  )} </a>
                              ) : <></>
                            )
                          }
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> Link Saving Account</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="card" style={{borderTop:'3px solid red'}} >
                    
                        <h6 class="m-4">Link member's saving account to FD for auto credit interest to saving account</h6>
                       <hr class="p-0 m-0"/>

                     

                      <div class="card-block">
                      <form onSubmit={updateDetails}>

                      {/* <h6 class="mb-2">Are you sure you want to change account type?</h6> */}


                      <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                  Member <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                  <select
                                    class="form-control m-b"
                                    name="joint_ac1_member_id"
                                    value={member_id}
                                    onChange={(e) =>
                                      setmember_id(e.target.value)
                                    }
                                  >
                                    <option value={''} disabled>Select Member</option>
                                    {saving_accountList.map((aBranchData) => (
                                      <option
                                        value={aBranchData.id}
                                        selected={
                                          aBranchData.id === member_id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {aBranchData.account_no} &nbsp;
                                       ({FirstLetterCapitalize(aBranchData.member_name)})

                                      </option>
                                    ))}
                                  </select>

                                  {formErrors.member_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.member_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>



                          





                      

                         


                        <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                            <button
                              class="btn btn-success btn-square"
                              type="submit"
                              disabled={submitting}
                            >
                            LINK ACCOUNT
                            </button>{" "}
                            {
                            shareHolderDetail && (
                              shareHolderDetail.account_for === 'fd' ? (
                                <a href={`/fixed-deposit-account-view/${dataEncript(shareHolderDetail.id)}`} class="btn btn-danger btn-square">Cancel</a>
                              ) : shareHolderDetail.account_for === 'mis' ? (
                                <a href={`/fixed-deposit-mis-account-view/${dataEncript(shareHolderDetail.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                              ) : shareHolderDetail.account_for === 'rd' ? (
                                <a href={`/recurring-deposit-account-view/${dataEncript(shareHolderDetail.id)}`} class="btn btn-danger btn-square">Cancel</a>
                              ) : shareHolderDetail.account_for === 'dd' ? (
                                <a href={`/recurring-deposit-account-view/${dataEncript(shareHolderDetail.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                              ) : <></>
                            )
                          }
                          

                            <ToastContainer className="toast-container" />
                          </div>
                        </div>


                      </form>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6">


                  <div class="card" style={{borderTop:'3px solid green'}} >
                  <div class="card-header py-3 text-denger">
                        <h5>FD Info</h5>
                              <div class="card-header-right">
                                    <ul class="list-unstyled card-option">
                                        <li><i class="feather icon-minus minimize-card"></i></li>
                                    </ul>
                              </div>
                        </div>
                        <div class="card-block p-0">
                        <form onSubmit={updateDetails}>

                        <div class="table-responsive dt-responsive">
                            <table class="table dtable table-striped table-bordered">

                            <tr>
                                            <th>Member</th>
                                            <td> {shareHolderDetail && shareHolderDetail.member_name && (
                                                <a href={`/view-member/${dataEncript(shareHolderDetail.member_id)}`} > {FirstLetterCapitalize(shareHolderDetail.member_created_id)} - {FirstLetterCapitalize(shareHolderDetail.member_name)}</a>
                                                )}</td>
                                            </tr>

                                            <tr>
                                            <th>RD No.</th>
                                            <td> {shareHolderDetail && shareHolderDetail.account_no && (
                                                <>{(shareHolderDetail.account_no)}</>
                                                )}</td>
                                            </tr>

                                            <tr>
                                            <th>Scheme	</th>
                                            <td> {shareHolderDetail && shareHolderDetail.scheme_name && (
                                                <>{(shareHolderDetail.scheme_name)}</>
                                                )}</td>
                                            </tr>

                                            <tr>
                                            <th>Tenure	</th>
                                            <td> {schemeDetails && schemeDetails.tenure_value && (
                                              <>{(schemeDetails.tenure_value)} {(schemeDetails.tenure_type)}</>
                                                )}</td>
                                            </tr>

                                            <tr>
                                            <th>Frequency</th>
                                            <td> {schemeDetails && schemeDetails.frequency && (
                                                 <>{(schemeDetails.frequency)}</>
                                                )}</td>
                                            </tr>

                                            <tr>
                                            <th>Principal Amt.</th>
                                            <td> {shareHolderDetail && shareHolderDetail.mis_amount && (
                                                 <>{formattedAmount(shareHolderDetail.mis_amount)}</>
                                                )}</td>
                                            </tr>

                                            <tr>
                                            <th>Balance Available		</th>
                                            <td> {shareHolderDetail && shareHolderDetail.mis_amount && (
                                                  <>{formattedAmount(shareHolderDetail.mis_amount)}</>
                                                )}</td>
                                            </tr>


                            </table>
                        </div>

                        </form>
                        </div>
                    </div>


                    <div class="card mt-1" style={{borderTop:'3px solid red'}} >
                            <div class="card-header py-3 text-denger">
                                    <h5>Member Info</h5>
                                        <div class="card-header-right">
                                                <ul class="list-unstyled card-option">
                                                    <li><i class="feather icon-plus minimize-card"></i></li>
                                                </ul>
                                        </div>
                                    </div>
                                    <div class="card-block p-0" style={{display:'none'}}>
                                        <form > 
                                            <div class="table-responsive dt-responsive">
                                                    <table class="table">
                                                    
                                                    <tbody>

                                                    <tr>
                                                        <th>Member</th>
                                                        <td> {shareHolderDetail && shareHolderDetail.member_name && (
                                                            <a href={`/view-member/${dataEncript(shareHolderDetail.member_id)}`} >{FirstLetterCapitalize(shareHolderDetail.member_created_id)} - {FirstLetterCapitalize(shareHolderDetail.member_name)}</a>
                                                            )}</td>
                                                        </tr>

                                                        <tr>
                                                        <th>Branch</th>
                                                        <td> {memberDetails && memberDetails.branch_name && (
                                                            <>{FirstLetterCapitalize(memberDetails.branch_name)}</>
                                                            )}</td>
                                                        </tr>

                                                        <tr>
                                                        <th>DOB	</th>
                                                        <td> {memberDetails && memberDetails.dob && (
                                                            <>{formatDate(memberDetails.dob)}</>
                                                            )}</td>
                                                        </tr>

                                                        <tr>
                                                        <th>Gender	</th>
                                                        <td> {memberDetails && memberDetails.gender && (
                                                            <>{FirstLetterCapitalize(memberDetails.gender)}</>
                                                            )}</td>
                                                        </tr>

                                                        <tr>
                                                        <th>Father Name	</th>
                                                        <td> {memberDetails && memberDetails.father_name && (
                                                            <>{FirstLetterCapitalize(memberDetails.father_name)}</>
                                                            )}</td>
                                                        </tr>

                                                        <tr>
                                                        <th>Occupation</th>
                                                        <td> {memberDetails && memberDetails.occupation && (
                                                            <>{FirstLetterCapitalize(memberDetails.occupation)}</>
                                                            )}</td>
                                                        </tr>

                                                        
                                
                                                    
                                                        
                                                    </tbody>
                                                    </table>
                                                    </div>

                                                </form>
                                            </div>
                                    </div>


                    
                  </div>
                </div>


                

              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
          <ToastContainer className="toast-container" />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
