import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { dataDecrypt,
    dataEncript,
    FirstLetterCapitalize,
    formatDate
     } from "./../../../helper/commonHelpers";

function CompanyDetails() {
  // const navigate = useNavigate();
  const { accountID }   = useParams();
  const [submitting, setSubmitting] = useState(false);
  // const [stateList, setStateList] = useState([]);
  const [promoterhDetails, setpromoterhDetails] = useState([]);
  const [commentsList, setcommentsList] = useState([]);


  const [formErrors, setFormErrors] = useState({
    comment: ""

  });


  // PROMOTER INFO 
  const [comment, setcomment] = useState('');

  useEffect(() => {

  // get bran ch details



  
 
axios.get(API_BASE_URL+'api-common/get-account-list?id='+dataDecrypt(accountID), {
    headers: API_HEADERS
}).then((response) => {

    if (response.data["status"] > 0) {
        setpromoterhDetails(response.data['data']);
        if (response.data["data"].comments && (response.data["data"].comments).length > 0) {
            setcommentsList(response.data["data"].comments);
        }
    }


  })
  .catch((error) => {
    toast.error('Failed to fetch DETAILS list!');
  });
 

    //    setenrollment_date(getCurrentDate());
  }, [accountID]);

  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    let logindetail = JSON.parse(localStorage.getItem('userLogin'));

    const formData = {
        comment:comment,
        type:'account',
        table_id:dataDecrypt(accountID),
        comment_by: logindetail.id
    };

    // validate form

      if (!comment) {
        errors.comment = 'Comment is required.';
      }

    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      axios
        .post(API_BASE_URL + "api-common/add-comment", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
            //   window.location.href = "/account-view/"+accountID;
              window.location.reload();
            }, 2000);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 1000);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>Add Comment - 
                        {
                            promoterhDetails && (
                              promoterhDetails.account_for === 'fd' ? (
                                <> FD Account</>
                              ) : promoterhDetails.account_for === 'mis' ? (
                                <>MIS Account</>
                              ): promoterhDetails.account_for === 'rd' ? (
                                <> RD Account</>
                              ): promoterhDetails.account_for === 'dd' ? (
                                <> Daily Deposits</>
                              ) : <>Saving Account</>
                            )
                          }
                        </h4>

                                    
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          {
                                promoterhDetails && (
                                  promoterhDetails.account_for === 'fd' ? (
                                    <a href="/fixed-deposit-account">Fd Accounts</a>
                                  ) : promoterhDetails.account_for === 'mis' ? (
                                    <a href="/fixed-deposit-mis-account">MIS Deposits</a>
                                  ): promoterhDetails.account_for === 'rd' ? (
                                    <a href="/recurring-deposit-account">RD Deposits</a>
                                  ) : promoterhDetails.account_for === 'dd' ? (
                                    <a href="/recurring-deposit-account">Daily Deposits</a>
                                  ) :  <a href="/account-list">Accounts</a>
                                )
                              }
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                        {
                              promoterhDetails && (
                                promoterhDetails.account_for === 'fd' ? (
                                  <a href={`/fixed-deposit-account-view/${dataEncript(promoterhDetails.id)}`} > {promoterhDetails && promoterhDetails.account_no && ( <span>{promoterhDetails.account_no}</span> )}</a>
                                ) : promoterhDetails.account_for === 'mis' ? (
                                   <a href={`/fixed-deposit-mis-account-view/${dataEncript(promoterhDetails.id)}`} > {promoterhDetails && promoterhDetails.account_no && ( <span>{promoterhDetails.account_no}</span> )}</a>
                                ): promoterhDetails.account_for === 'rd' ? (
                                  <a href={`/recurring-deposit-account-view/${dataEncript(promoterhDetails.id)}`} > {promoterhDetails && promoterhDetails.account_no && ( <span>{promoterhDetails.account_no}</span> )}</a>
                               ): promoterhDetails.account_for === 'dd' ? (
                                <a href={`/recurring-deposit-account-view/${dataEncript(promoterhDetails.id)}`} > {promoterhDetails && promoterhDetails.account_no && ( <span>{promoterhDetails.account_no}</span> )}</a>
                               ) :  <a href={`/account-view/${dataEncript(promoterhDetails.id)}`} > {promoterhDetails && promoterhDetails.account_no && ( <span>{promoterhDetails.account_no}</span> )}</a>
                              )
                            }
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">Comments</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12"></div>

                  <div class="col-sm-8">
                    <div class="card">
                      <div class="card-header">
                        {/* <h5>Edit Promoter</h5> */}
                        {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                      </div>

                      <div class="card-block">


                      <div class="table-responsive dt-responsive">
                        <table class="table table-striped table-bordered nowrap">
                            <thead>
                                <tr>
                                    <th>Sr</th>
                                    <th>COMMENT</th>
                                    <th>COMMENT BY</th>
                                    <th>DATE</th>
                                </tr>
                            </thead>
                            <tbody>


                            {
                            commentsList.length > 0 ? (
                                commentsList.map((appointData, index) => (
                                <tr class="gradeX">
                                <td>{index + 1}</td>
                                <td> {appointData && appointData.comment && (
                                            <span>{FirstLetterCapitalize(appointData.comment)}</span>
                                            )}</td>

                                    <td> {appointData && appointData.comment_by && (
                                            <span>{FirstLetterCapitalize(appointData.comment_by)}</span>
                                            )}</td>

                                <td> {appointData && appointData.created_at && (
                                            <span>{ appointData.created_at?formatDate(appointData.created_at):''}</span>
                                            )}</td>

                                
                            </tr>
                            ))
                            ) : (
                            <tr>
                                <td colSpan="5">No data available</td>
                            </tr>
                            )
                        }
                              
                            </tbody>
                        </table>
                        </div>
                       
                      </div>
                    </div>
                  </div>


                  <div class="col-sm-4">
                    <div class="card">
                    

                      <div class="card-block">
                        <form onSubmit={updateDetails}>


                        <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30 ">
                            <h6 class="">Comment<span class="text-danger">*</span></h6>
                                    <textarea class="form-control" rows="4" value={comment} onChange={(e) => setcomment(e.target.value)} placeholder='Enter Comment' ></textarea>
                                    {formErrors.comment && <span style={{ color: 'red' }}>{formErrors.comment}</span>}
                            </div>
                        </div>

                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                              <button
                                class="btn btn-success btn-square"
                                type="submit"
                                disabled={submitting}
                              >
                                Save
                              </button>{" "}
                              &nbsp;
                              {
                              promoterhDetails && (
                                promoterhDetails.account_for === 'fd' ? (
                                  <a href={`/fixed-deposit-account-view/${dataEncript(promoterhDetails.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                                ) : promoterhDetails.account_for === 'mis' ? (
                                     <a href={`/fixed-deposit-mis-account-view/${dataEncript(promoterhDetails.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                                ): promoterhDetails.account_for === 'rd' ? (
                                  <a href={`/recurring-deposit-account-view/${dataEncript(promoterhDetails.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                               ): promoterhDetails.account_for === 'dd' ? (
                                <a href={`/recurring-deposit-account-view/${dataEncript(promoterhDetails.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                             ) : <a href={`/account-view/${dataEncript(promoterhDetails.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                              )
                            }
                             

                              <ToastContainer className="toast-container" />
                            </div>
                          </div>


                        </form>
                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
