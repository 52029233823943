import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly

// import { useNavigate } from 'react-router-dom'; 


  import { toast, ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

  import {
    getCurrentDate
  } from './../../../helper/commonHelpers'; 


function CompanyDetails() {
    // const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);
    const [stateList, setStateList] = useState([]);

    const [formErrors, setFormErrors] = useState({
            branch_name:'', branch_code:'', open_date:'', ifsc_code:'', address_line1:'',
             city:'', state:'', pincode:'', country:'', bill_pay_service:'', transfer_service:''

      });


      const [branch_name, setbranch_name] = useState('');
      const [branch_code, setbranch_code] = useState('');
      const [open_date, setopen_date] = useState('');
      const [ifsc_code, setifsc_code] = useState('');
      const [address_line1, setaddress_line1] = useState('');
      const [address_line2, setaddress_line2] = useState('');
      const [city, setcity] = useState('');
      const [state, setstate] = useState('');
      const [pincode, setpincode] = useState('');
      const [country, setcountry] = useState('');
      const [contact_email, setcontact_email] = useState('');
      const [contact_no, setcontact_no] = useState('');
      const [landline_no, setlandline_no] = useState('');
      const [bill_pay_service, setbill_pay_service] = useState('no');
      const [transfer_service, settransfer_service] = useState('no');

    useEffect(() => {

        // get stateList
          axios.get(API_BASE_URL + "api-common/get-state-list", {
            headers: API_HEADERS,
          }).then((response) => {
            setStateList(response.data['data']);
          }).catch((error) => {
            toast.error('Failed to fetch date!');
          });
          
          setopen_date(getCurrentDate());

      }, []);


   

  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};

    const formData = {
        'branch_name': branch_name,
        'branch_code': branch_code,
        'open_date': open_date,
        'ifsc_code': ifsc_code,
        'address_line1': address_line1,
        'address_line2': address_line2,
        'city': city,
        'state': state,
        'pincode': pincode,
        'country': country,
        'contact_email': contact_email,
        'contact_no': contact_no,
        'landline_no': landline_no,
        'bill_pay_service': bill_pay_service,
        'transfer_service': transfer_service,
  };


// validate form
if (!formData.branch_name) {
    errors.branch_name = 'Branch name is required.';
  }

  if (!formData.branch_code) {
    errors.branch_code = 'Branch code is required.';
  }

  if (!formData.open_date) {
    errors.open_date = 'Open date is required.';
  }

  if (!formData.ifsc_code) {
    errors.ifsc_code = 'IFSC Code is required.';
  }

  if (!formData.address_line1) {
    errors.address_line1 = 'Address line1 is required.';
  }

  if (!formData.city) {
    errors.city = 'City is required.';
  }

  if (!formData.state) {
    errors.state = 'State is required.';
  }

  if (!formData.pincode) {
    errors.pincode = 'PIN code is required.';
  }

  if (!formData.country) {
    errors.country = 'Country is required.';
  }

  if (!formData.bill_pay_service) {
    errors.bill_pay_service = 'Bill pay service is required.';
  }

  if (!formData.transfer_service) {
    errors.transfer_service = 'Transfer service is required.';
  }




  // Set formErrors state with validation errors
  setFormErrors(errors);
  

  if (Object.keys(errors).length === 0) {
      axios.post(API_BASE_URL + 'api-company/add-branch', formData, {
        headers: {
          ...API_HEADERS,
        },
      })
      .then(response => {
        if (response.data['status'] > 0) {
             toast.success(response.data['message']);
             setTimeout(() => {
                window.location.href ='/branches-list';
              }, 4000);
        } else {
            toast.error(response.data['message']);
        }
      })
      .catch(error => {
        toast.error('Failed to submition!');
      }).finally(() => {
        setTimeout(() => {
          setSubmitting(false);
        }, 300);
      });  // close query hare
    }else{
        setSubmitting(false); 
    } // hare close error 

  };


  return (
    <DashboardLayout>




     <div class="pcoded-content">
    <div class="pcoded-inner-content">
        <div class="main-body">
            <div class="page-wrapper">
                <div class="page-header">
                    <div class="row align-items-end">
                        <div class="col-lg-8">
                            <div class="page-header-title">
                                <div class="d-inline">
                                    <h4>Add New Branch</h4>
                                    {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}

                                   



                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="page-header-breadcrumb">
                                <ul class="breadcrumb-title">
                                    <li class="breadcrumb-item" style={{float:'left'}}>
                                        <a href="/dashboard"> <i class="feather icon-home"></i> </a>
                                    </li>
                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="/branches-list">Branch List</a></li>

                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="#!">Add New Branch</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="page-body">
                    <div class="row">

                    <div class="col-sm-12">
                    

                                    </div>


                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-header">
                                    <h5>Add New Branch</h5>
                                    {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                                </div>

                                <div class="card-block">
                                <form onSubmit={updateDetails}> 
                                 <div class="row">
                                    <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Branch Name<span class="text-danger">*</span></h4>
                                            <input class="form-control" type="text" value={branch_name} onChange={(e) => setbranch_name(e.target.value)}  placeholder='Enter Branch Name' />
                                            {formErrors.branch_name && <span style={{ color: 'red' }}>{formErrors.branch_name}</span>}
                                        </div>

                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Branch Code<span class="text-danger">*</span></h4>
                                            <input class="form-control" type="text" value={branch_code} onChange={(e) => setbranch_code(e.target.value)}  placeholder='Enter Branch Code' />
                                            {formErrors.branch_code && <span style={{ color: 'red' }}>{formErrors.branch_code}</span>}
                                        </div>

                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Open Date<span class="text-danger">*</span></h4>
                                            <input class="form-control" type="date" value={open_date} onChange={(e) => setopen_date(e.target.value)}  placeholder='Enter Open Date' />
                                            {formErrors.open_date && <span style={{ color: 'red' }}>{formErrors.open_date}</span>}
                                        </div>

                                    </div>



                                  

                                    <div class="row">
                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">IFSC Code<span class="text-danger">*</span></h4>
                                            <input class="form-control" type="text" value={ifsc_code} onChange={(e) => setifsc_code(e.target.value)}  placeholder='Enter IFSC Code' />
                                            {formErrors.ifsc_code && <span style={{ color: 'red' }}>{formErrors.ifsc_code}</span>}
                                        </div>
                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Address Line 1<span class="text-danger">*</span></h4>
                                            <input class="form-control" type="text" value={address_line1} onChange={(e) => setaddress_line1(e.target.value)}  placeholder='Enter Address Line 1' />
                                            {formErrors.address_line1 && <span style={{ color: 'red' }}>{formErrors.address_line1}</span>}
                                        </div>
                                        
                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Address Line 2</h4>
                                            <input class="form-control" type="text" value={address_line2} onChange={(e) => setaddress_line2(e.target.value)}  placeholder='Enter Address Line 2' />
                                            
                                        </div>

                                    </div>


                                    <div class="row">
                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">City<span class="text-danger">*</span></h4>
                                            <input class="form-control" type="text" value={city} onChange={(e) => setcity(e.target.value)} placeholder='Enter City Name' />
                                            {formErrors.city && <span style={{ color: 'red' }}>{formErrors.city}</span>}
                                        </div>
                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">State<span class="text-danger">*</span></h4>
                                            <select class="form-control m-b" name="state" value={state} onChange={(e) => setstate(e.target.value)}>
                                                    <option disabled  >Select Status</option>
                                                    {stateList.map((stateData) => (
                                                      <option value={stateData.full_name} selected={stateData.state ===state ? 'selected' : ''} >{stateData.full_name}</option>
                                                    ))}

                                                </select>
                                                {formErrors.state && <span style={{ color: 'red' }}>{formErrors.state}</span>}
                                        </div>
                                        
                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Pincode<span class="text-danger">*</span></h4>
                                            <input class="form-control" type="text" value={pincode} onChange={(e) => setpincode(e.target.value)} onKeyDown={(e) => { if (e.target.value.length === 6) { e.preventDefault(); } }} placeholder='Enter Pincode' />
                                            {formErrors.pincode && <span style={{ color: 'red' }}>{formErrors.pincode}</span>}
                                        </div>
                                        </div>

                                        <div class="row">
                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Country<span class="text-danger">*</span></h4>
                                            <input class="form-control" type="text" value={country} onChange={(e) => setcountry(e.target.value)}  placeholder='Enter Country' />
                                            {formErrors.country && <span style={{ color: 'red' }}>{formErrors.country}</span>}
                                        </div>

                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Contact Email</h4>
                                            <input class="form-control" value={contact_email} onChange={(e) => setcontact_email(e.target.value)} type="text" placeholder='Enter Contact Email' />
                                        </div>

                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Contact No</h4>
                                            <input class="form-control" type="text" value={contact_no} onChange={(e) => setcontact_no(e.target.value)}  onKeyDown={(e) => { if (e.target.value.length === 10) { e.preventDefault(); } }}  placeholder='Enter Contact No.' max={'10'} />
                                        </div>
                                        </div>

                                        <div class="row">
                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Landline No.</h4>
                                            <input class="form-control" type="text" value={landline_no} onChange={(e) => setlandline_no(e.target.value)} placeholder='Enter Landline No.' />
                                        </div>

                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Disable Recharge/ Bill Payment Service<span class="text-danger">*</span></h4>
                                            

                                            <div class="form-radio">
                                                <div class="radio radio-inline">
                                                    <label>
                                                            <input type="radio" name="bill_pay_service" value={'yes'}  onChange={(e) => setbill_pay_service(e.target.value)}  />
                                                            <i class="helper"></i>Yes
                                                    </label>
                                                </div>
                                                <div class="radio radio-inline">
                                                    <label>
                                                            <input type="radio" name="bill_pay_service" value={'no'}  checked="checked" onChange={(e) => setbill_pay_service(e.target.value)}   />
                                                            <i class="helper"></i>No
                                                    </label>
                                                </div>
                                               </div>

                                            {formErrors.bill_pay_service && <span style={{ color: 'red' }}>{formErrors.bill_pay_service}</span>}
                                        </div>

                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Disable NEFT/ IMPS/ Within Transfar Service<span class="text-danger">*</span></h4>


                                            <div class="form-radio">
                                                <div class="radio radio-inline">
                                                    <label>
                                                            <input type="radio" name="transfer_service" value={'yes'}  onChange={(e) => settransfer_service(e.target.value)}  />
                                                            <i class="helper"></i>Yes
                                                    </label>
                                                </div>
                                                <div class="radio radio-inline">
                                                    <label>
                                                            <input type="radio" name="transfer_service" value={'no'}  checked="checked" onChange={(e) => settransfer_service(e.target.value)}   />
                                                            <i class="helper"></i>No
                                                    </label>
                                                </div>
                                               </div>


                                            {formErrors.transfer_service && <span style={{ color: 'red' }}>{formErrors.transfer_service}</span>}
                                        </div>
                                        </div>

                                    

                                    

                                        

                                    <div class="row">
                                        <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                                            <button class="btn btn-success btn-square" type="submit" disabled={submitting}>Save</button> &nbsp;
                                            <button class="btn btn-danger btn-square" type="reset">Cancel</button>

                                            <ToastContainer className="toast-container" />
                                        </div>
                                        </div>



                                    </form>
                                </div>


                            </div>




                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="styleSelector"></div>
    </div>
</div>

    </DashboardLayout>
  );
}

export default CompanyDetails;
