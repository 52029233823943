import React, { useState, useEffect, useRef } from "react";
import DashboardLayout from "../../dashboardLayout";
// import axios from "axios";
// import { API_BASE_URL, API_HEADERS } from "../../../../config"; // Importing constants directly
// import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { deleteConfirmation, FirstLetterCapitalize, getCurrentDate } from "../../../helper/commonHelpers";
import { formPost, getActiveBranchList, getCollectionCenterList, getMemberList } from '../../../helper/listModal';

//formatDate
import $ from 'jquery';
import 'select2/dist/css/select2.min.css';
import 'select2';

function CompanyDetails() {
  const [submitting, setSubmitting] = useState(false);
  // const { accountID }   = useParams();
  const [activeBranchArr, setactiveBranchArr] = useState([]);
  const [colcenterList, setcolcenterList] = useState([]);
  const [memberlistArr, setmemberlistArr] = useState([]);



  const [formErrors, setFormErrors] = useState({
    member_id: "",
  });



// const[group_id, setgroup_id] = useState('');
const[collcenter_id, setcollcenter_id] = useState('');
const[branch_id, setbranch_id] = useState('');
const[open_date, setopen_date] = useState('');
const[group_name, setgroup_name] = useState('');
const[group_no, setgroup_no] = useState('');
const[group_head, setgroup_head] = useState('');
const[group_cashier, setgroup_cashier] = useState('');
const[group_member, setgroup_member] = useState('');
const[group_active, setgroup_active] = useState('yes');



  useEffect(() => {
   

    getMemberList().then(data => {
      setmemberlistArr(data);
    }).catch(error => {
        // console.error('Error fetching associate list:', error);
    });

    getCollectionCenterList().then(data => {
      // console.log(data);
      setcolcenterList(data);
    }).catch(error => {
        // console.error('Error fetching associate list:', error);
    });

    getActiveBranchList().then(data => {
      // console.log(data);
      setactiveBranchArr(data);
    }).catch(error => {
        // console.error('Error fetching associate list:', error);
    });


    setopen_date(getCurrentDate())

  }, []);


  const selectGroupMembers = useRef();
  useEffect(() => {
    // Initialize Select2
    const $selectRole = $(selectGroupMembers.current);
    $selectRole.select2();
    $selectRole.on('change', function () {
      const selectedOptions = $selectRole.val() || [];
      setgroup_member(selectedOptions);
    });
    // Cleanup Select2 on component unmount
    return () => {
      $selectRole.select2('destroy');
    };
  }, []);



  // const selectRefcollectioncenter = useRef();
  const select2Ref = useRef();

  useEffect(() => {
    const $select2Elements = $(select2Ref.current).find('select');
    $select2Elements.each(function() {
      const $select = $(this);

      $select.on('change', function() {
        
        let filename = $(this).attr('name');
        if(filename==='collcenter_id')
        {
          setcollcenter_id($(this).val());
        }
        else if(filename==='branch_id')
        {
          setbranch_id($(this).val());
        }
        else if(filename==='group_head')
        {
          setgroup_head($(this).val());
        }
        else if(filename==='group_cashier')
        {
          setgroup_cashier($(this).val());
        }

      });

      $select.select2();
      return () => {
        $select.select2('destroy');
      };
    });
  }, []);



  const updateDetails = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));

    const formData = {

      // group_id: group_id,
      collcenter_id: collcenter_id,
      branch_id: branch_id,
      open_date: open_date,
      group_name: group_name,
      group_no: group_no,
      group_head: group_head,
      group_cashier: group_cashier,
      group_member: JSON.stringify(group_member),
      member_count: (group_member.length),
      group_active: group_active,




    };

  console.log(formData);
     

      if (!open_date) {
        errors.open_date = 'Open Date is required.';
      }

      if (!group_name) {
        errors.group_name = 'Group Name is required.';
      }
      if (!group_no) {
        errors.group_no = 'Group No is required.';
      }
      if (!group_head) {
        errors.group_head = 'Group Head Member is required.';
      }
      if (!group_member) {
        errors.group_member = 'Group Members is required.';
      }
    
      // console.log(formData);


    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {

        const confirmed = await deleteConfirmation('to add New Group Centers?');
        if(confirmed===true)
        {

          formPost('api-collcenter/add-group', formData).then(data => {

            toast.success(data["message"]);
            setTimeout(() => {
              window.location.href = "/collection-centers-group";
            }, 2000);

              setSubmitting(false);
          }).catch(error => {
              setSubmitting(false);
              toast.error(error);
          });



    }
    setSubmitting(false);
    } else {
      setSubmitting(false);
    } 
  };


  

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>  Add New Group </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                            <a href="/collection-centers-group">Group </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> New</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="card" style={{borderTop:'3px solid red'}} >
                    
                        {/* <h4 class="m-4">CHANGE ACCOUNT INFO</h4> */}
                       <hr class="p-0 m-0"/>

                     

                      <div class="card-block">
                      <form onSubmit={updateDetails} ref={select2Ref}>

                      <div class="row"  >
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Collection Center
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                  <select
                                    class="form-control m-b"
                                    name="collcenter_id"
                                    value={collcenter_id}
                                    onChange={(e) =>
                                      setcollcenter_id(e.target.value)
                                    }
                                    
                                  >

                                    <option value={' '}>Select Collection Center</option>

                                     {colcenterList.map((assData) => (
                                      <option
                                        value={assData.id}
                                        selected={
                                          assData.id === collcenter_id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {FirstLetterCapitalize(assData.center_no)} - &nbsp; 
                                        {FirstLetterCapitalize(assData.center_name)}

                                      </option>
                                    ))}
                                 

                                  </select>
                                  {formErrors.collcenter_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.collcenter_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>



                      <div class="row"  >
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Branch 
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                  <select
                                    class="form-control m-b"
                                    name="branch_id"
                                    value={branch_id}
                                    onChange={(e) =>
                                      setbranch_id(e.target.value)
                                    }

                                    
                                  >

                                    <option value={' '}>Select - Branch</option>

                                     {activeBranchArr.map((assData) => (
                                      <option
                                        value={assData.id}
                                        selected={
                                          assData.id === branch_id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {FirstLetterCapitalize(assData.branch_name)}

                                      </option>
                                    ))}
                                 

                                  </select>

                                  {formErrors.branch_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.branch_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Open Date<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input class="form-control" type="date" value={open_date} onChange={(e) => setopen_date(e.target.value) } placeholder="Enter Open Date" />

                                  {formErrors.open_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.open_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                        

                          <div class="row" >
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Group Name<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input class="form-control" type="text" value={group_name} onChange={(e) => setgroup_name(e.target.value) } placeholder="Enter Group Name" />

                                  {formErrors.group_name && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.group_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Group No<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input class="form-control" type="text" value={group_no} onChange={(e) => setgroup_no(e.target.value) } placeholder="Enter Center Name" />

                                  {formErrors.group_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.group_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            
                          </div>



                          <div class="row"  >
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Group Head Member<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                  <select
                                    class="form-control m-b"
                                    name="group_head"
                                    value={group_head}
                                    onChange={(e) =>
                                      setgroup_head(e.target.value)
                                    }

                                    
                                  >

                                    <option value={' '}>Select - Center Head</option>

                                     {memberlistArr.map((assData) => (
                                      <option
                                        value={assData.id}
                                        selected={
                                          assData.id === group_head
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {FirstLetterCapitalize(assData.member_id)} - &nbsp;

                                        {FirstLetterCapitalize(assData.fname)} &nbsp;
                                        {FirstLetterCapitalize(assData.mname)} &nbsp;
                                        {FirstLetterCapitalize(assData.lname)}

                                      </option>
                                    ))}
                                 

                                  </select>

                                  {formErrors.group_head && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.group_head}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Group Cashier
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                  <select
                                    class="form-control m-b"
                                    name="group_cashier"
                                    value={group_cashier}
                                    onChange={(e) =>
                                      setgroup_cashier(e.target.value)
                                    }

                                    
                                  >

                                    <option value={' '}>Select - Group Cashier</option>

                                     {memberlistArr.map((assData) => (
                                      <option
                                        value={assData.id}
                                        selected={
                                          assData.id === group_cashier
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {FirstLetterCapitalize(assData.member_id)} - &nbsp;

                                        {FirstLetterCapitalize(assData.fname)} &nbsp;
                                        {FirstLetterCapitalize(assData.mname)} &nbsp;
                                        {FirstLetterCapitalize(assData.lname)}

                                      </option>
                                    ))}
                                 

                                  </select>

                                  {formErrors.group_cashier && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.group_cashier}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>



                          


                          

                          <div class="row" >

                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Group Members <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                  <select
                                    class="form-control m-b"
                                    name="group_member"
                                    value={group_member}
                                    onChange={(e) =>
                                      setgroup_member(e.target.value)
                                    }

                                    multiple="multiple"

                                    ref={selectGroupMembers}

                                  >

                                    <option value={' '}>Select - Group Cashier</option>

                                    

                                    {memberlistArr.map((assData) => (
                                      <option
                                        value={assData.id}
                                        selected={
                                          assData.id === group_member
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {FirstLetterCapitalize(assData.member_id)} - &nbsp;

                                        {FirstLetterCapitalize(assData.fname)} &nbsp;
                                        {FirstLetterCapitalize(assData.mname)} &nbsp;
                                        {FirstLetterCapitalize(assData.lname)}

                                      </option>
                                    ))}
                                 

                                  </select>

                                  {formErrors.group_member && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.group_member}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Center Active <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="group_active_yes">
                                        <input
                                          type="radio"
                                          name="group_active"
                                          value={"yes"}
                                          id={"group_active_yes"}
                                          checked={group_active === 'yes' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setgroup_active(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Yes 
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="group_active_no">
                                        <input
                                          type="radio"
                                          name="group_active"
                                          id={"group_active_no"}
                                          value={"no"}
                                          checked={group_active === 'no' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setgroup_active(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>No
                                      </label>
                                    </div>
                                
                                  </div>
                                  {formErrors.group_active && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.group_active}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            
                            
                          </div>

                         




                        <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                            <button
                              class="btn btn-success btn-square"
                              type="submit"
                              disabled={submitting}
                            >
                              ADD GROUP 
                            </button>{" "}
                       

                            <a href="/collection-centers"  class="btn btn-danger btn-square">Cancel</a>
                          

                            <ToastContainer className="toast-container" />
                          </div>
                        </div>


                      </form>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-5">
                  
                  </div>
                </div>


                

              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
          <ToastContainer className="toast-container" />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
