import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly

import { useParams } from 'react-router-dom'; 

  import { toast, ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

  import {
    dataDecrypt,
    dataEncript,
    formatDate
  } from './../../../helper/commonHelpers'; 


function CompanyDetails() {
    // const navigate = useNavigate();
    const { directorID }   = useParams();

    const [directorDetail, setdirectorDetail] = useState([]);

    useEffect(() => {

          // get bran ch details
          axios
          .get(API_BASE_URL + "api-company/get-director-by-id?director_id="+dataDecrypt(directorID), {
            headers: API_HEADERS,
          })
          .then((response) => {
             setdirectorDetail(response.data['data']);
          })
          .catch((error) => {
            toast.error('Failed to fetch Details list!');
          });

      }, [directorID]);




  return (
    <DashboardLayout>




     <div class="pcoded-content">
    <div class="pcoded-inner-content">
        <div class="main-body">
            <div class="page-wrapper">
                <div class="page-header">
                    <div class="row align-items-end">
                        <div class="col-lg-8">
                            <div class="page-header-title">
                                <div class="d-inline">
                                    <h4>{directorDetail.director_name} <small>Director</small></h4>
                                    {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="page-header-breadcrumb">
                                <ul class="breadcrumb-title">
                                    <li class="breadcrumb-item" style={{float:'left'}}>
                                        <a href="/dashboard"> <i class="feather icon-home"></i> </a>
                                    </li>
                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="/director">Directors</a></li>

                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="#!">{directorDetail.director_name}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="page-body">
                    <div class="row">

                    <div class="col-sm-12">
                    

                                    </div>


                        <div class="col-sm-8">
                            <div class="card">
                                <div class="card-header">
                                    <h5>

                        

                                    </h5>

                                    <a
                          style={{ float: "right" }}
                          class="badge badge-info"
                          href={`/edit-director/${dataEncript(directorDetail.id)}`}
                          title="Edit"
                        >
                           <i class="feather icon-edit"></i>
                        </a>
                                    {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                                </div>

                                <div class="card-block">
                                <form > 
                                 <div class="row">
                                    <div class="col-sm-12 col-xl-12 m-b-30">
                                           
                                    <table class="table table-striped table-bordered nowrap">
                                        <tbody>
                                            <tr>
                                                <th>Designation</th>
                                                <td>{directorDetail && directorDetail.designation && (
                                                <>{directorDetail.designation}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                                <th>Member</th>
                                                <td>{directorDetail && directorDetail.member && (
                                                <>{directorDetail.member}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                                <th>Name</th>
                                                <td>{directorDetail && directorDetail.director_name && (
                                                <>{directorDetail.director_name}</>
                                                )}</td>
                                            </tr>

                                            <tr>
                                                <th>DIN No.</th>
                                                <td>{directorDetail && directorDetail.din_no && (
                                                <>{directorDetail.din_no}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                                <th>Appointment Date</th>
                                                <td>{directorDetail && directorDetail.appointment_date && (
                                                <>{ formatDate(directorDetail.appointment_date) }</>
                                                )}</td>
                                            </tr>

                                            <tr>
                                                <th>Resignation Date</th>
                                                <td>{directorDetail && directorDetail.resignation_date && (
                                                <>{ formatDate(directorDetail.resignation_date) }</>
                                                )}</td>
                                            </tr>

                                            <tr>
                                                <th>Authorized Signatory</th>
                                                <td>{directorDetail && directorDetail.authorized_signatory==='yes' && (
                                                     <label class="label label-success">Yes</label>
                                                )}

                                                {directorDetail && directorDetail.authorized_signatory==='no' && (
                                                     <label class="label label-danger">No</label>
                                                )}
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>Signature</th>
                                                <td>{directorDetail && directorDetail.signature && (
                                                      <img src={directorDetail?.signature ? API_BASE_URL + directorDetail.signature : ''} alt="signature" style={{height:'100px;'}} />
                                                )}</td>
                                            </tr>



                                          







                                        </tbody>
                                    </table>


                                        </div>
                                    </div>



                                        

                                    

                                    

                                        

                                    <div class="row">
                                        <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                                          

                                            <ToastContainer className="toast-container" />
                                        </div>
                                        </div>



                                    </form>
                                </div>


                            </div>




                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="styleSelector"></div>
    </div>
</div>

    </DashboardLayout>
  );
}

export default CompanyDetails;
