import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { dataDecrypt,
    dataEncript,
    formatDate,
    getCurrentDate
     } from "./../../../helper/commonHelpers";

function CompanyDetails() {
  // const navigate = useNavigate();
  const { memberID }   = useParams();
  const [submitting, setSubmitting] = useState(false);
  // const [stateList, setStateList] = useState([]);
  const [promoterhDetails, setpromoterhDetails] = useState([]);


  const [formErrors, setFormErrors] = useState({
    member_id: "",
    enroll_date: "",
    status: "",
  });


  // PROMOTER INFO 
  
  
  const[close_date, setclose_date] = useState('');
  const[status, setstatus] = useState('Inactive');
  const[enroll_date, setenroll_date] = useState('');


  useEffect(() => {

  // get bran ch details
 
axios.get(API_BASE_URL+'api-member/get-member-list?member_id='+dataDecrypt(memberID), {
    headers: API_HEADERS
}).then((response) => {

    if (response.data["status"] > 0) {
        setpromoterhDetails(response.data['data']);


        setenroll_date(response.data['data'].enroll_date?formatDate(response.data['data'].enroll_date, 'YMD'):'');

    }
  }).catch((error) => {
    toast.error('Failed to fetch DETAILS list!');
  });
 


  setclose_date(getCurrentDate());

    //    setenrollment_date(getCurrentDate());
  }, [memberID]);

  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));

    const formData = {
        // table_id:dataDecrypt(memberID),

      
        close_date: close_date,
        status: status,
        enroll_date: enroll_date,

    };

    // validate form

    

      if (!enroll_date) {
        errors.enroll_date = 'Transaction date is required.';
      }

      if (!status) {
        errors.status = 'status is required.';
      }


     


   


    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      axios
        .post(API_BASE_URL + "api-member/add-new-shares", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/view-member/"+memberID;
            //   window.location.reload();
            }, 2000);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 1000);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>IN-ACTIVE MEMBER - {promoterhDetails && promoterhDetails.fname && (
                                    <>{promoterhDetails.fname}</>
                                    )}

                                    {promoterhDetails && promoterhDetails.mname && (
                                    <>{promoterhDetails.mname}</>
                                    )} </h4>

                                    
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/members-management">Member List</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href={`/view-member/${dataEncript(promoterhDetails.id)}`} >{promoterhDetails && promoterhDetails.fname && (
                                    <span>{promoterhDetails.fname}</span>
                                    )}

                                    {promoterhDetails && promoterhDetails.mname && (
                                    <span>{promoterhDetails.mname}</span>
                                    )}</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">IN-ACTIVE MEMBER</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12">
                  </div>

                


                  <div class="col-sm-12">
                    <div class="card">
                    

                      <div class="card-block">
                        <form onSubmit={updateDetails}>




                          <div class="row">
                            <div class="col-sm-12 py-3">
                                <h4 class="text-center">IN-ACTIVE MEMBER</h4>
                            </div>

                            <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Status <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={status}
                                    onChange={(e) =>
                                      setstatus(e.target.value)
                                    }
                                    placeholder="Enter Status " 
                                    readOnly
                                  />
                                  {formErrors.status && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.status}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            </div>

                            <div class="row">
                            <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                                <div class="row">
                                    <div class="col-sm-5">
                                    <h6 class="text-xl-right">Enrollment Date<span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-sm-7">
                                    <input
                                        class="form-control"
                                        type="date"
                                        value={enroll_date}
                                        onChange={(e) =>
                                        setenroll_date(e.target.value)
                                        }
                                        placeholder="Enter Transaction Date. "
                                    />
                                    {formErrors.enroll_date && (
                                        <span style={{ color: "red" }}>
                                        {formErrors.enroll_date}
                                        </span>
                                    )}
                                    </div>
                                </div>
                                </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                                <div class="row">
                                    <div class="col-sm-5">
                                    <h6 class="text-xl-right">Ceased Date<span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-sm-7">
                                    <input
                                        class="form-control"
                                        type="date"
                                        value={close_date}
                                        onChange={(e) =>
                                        setclose_date(e.target.value)
                                        }
                                        placeholder="Enter Transaction Date. "
                                    />
                                    {formErrors.close_date && (
                                        <span style={{ color: "red" }}>
                                        {formErrors.close_date}
                                        </span>
                                    )}
                                    </div>
                                </div>
                                </div>
                          </div>


                     


                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                              <button
                                class="btn btn-success btn-square"
                                type="submit"
                                disabled={submitting}
                              >
                                INACTIVE MEMBER
                              </button>{" "}
                              &nbsp;
                              <a href={`/view-member/${dataEncript(promoterhDetails.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                              &nbsp;
                             

                              <ToastContainer className="toast-container" />
                            </div>
                          </div>


                        </form>
                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
