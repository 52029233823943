import React, { useState, useEffect, useRef } from 'react';
import DashboardLayout from '../dashboardLayout';

// import axios from "axios";
// import { API_BASE_URL, API_HEADERS } from "../../../config"; // Importing constants directly
// import $ from 'jquery';
import { useParams } from 'react-router-dom'; 

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import {
      
      FirstLetterCapitalize,
      dataEncript,
      // formatDate,
      deleteConfirmation,
      formattedAmount,

      dataDecrypt,
      getCurrentDate
      
    } from '../../helper/commonHelpers'; 


    
import {
  getListArr, formPost, getActiveBranchList, 
} from '../../helper/listModal'; 

function AdminLayout() {
   const { branchID }   = useParams();
   const [transactionList, settransactionList] = useState([]);
   const [activeBranchArr, setactiveBranchArr] = useState([]);
   const [submitting, setSubmitting] = useState(false);

   const [acStatus, setacStatus] = useState([]);
   const statusRefs = useRef([]);
   const [payment_rel, setpayment_rel] = useState([]);
   const paymentRefs = useRef([]);
   const [remarks, setremarks] = useState([]);

  
   const [isChecked, setIsChecked] = useState(false);
   const [statusn, setStatusn] = useState(''); 
  


    useEffect(() => {
      let condition = `branch_id=${branchID ? dataDecrypt(branchID) : ''}`;

      getListArr('api-approvals/get-transactions-list', condition).then(data => {
        console.log(data);
        settransactionList(data);
      }).catch(error => {
          // console.error('Error fetching associate list:', error);
      });

      getActiveBranchList().then(data => {
        setactiveBranchArr(data);
      }).catch(error => {
          // console.error('Error fetching associate list:', error);
      });

    }, [branchID]);


 
    const[account_for, setaccount_for] = useState('');
    const[branch, setbranch] = useState((branchID?dataDecrypt(branchID):''));


const filterData = (e) => {
      e.preventDefault();
      setSubmitting(true);
  
  const formData = {};
  // if (member_no) {
  //     formData['member_no'] = member_no;
  //   }
  
    // console.log(formData);
    if (Object.keys(formData).length === 0) {
      // Set formErrors state with validation errors
      let condition = `account_type=${account_for}&branch_id=${branch} `; 
      
      getListArr('api-approvals/get-transactions-list',condition).then(data => {
          settransactionList(data);
          setSubmitting(false);
          toast.success('Data filtered successfully.');
        }).catch(error => {
            // console.error('Error fetching associate list:', error);
            toast.error(error);
        });
  
      }
      setSubmitting(false);
    };




     // Function to handle checkbox change
   const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    setStatusn(e.target.checked ? 'approve' : '');
  };

    // UPDATE PROCESS-------------
    const handleStatusChange = (event, index) => {
      const updatedStatusList = [...acStatus];
      updatedStatusList[index] = event.target.value;
      setacStatus(updatedStatusList);

      console.log(statusn);
    };
  
    const handleRemarkChange = (event, index) => {
      const updatedRemarkList = [...remarks];
      updatedRemarkList[index] = event.target.value;
      setremarks(updatedRemarkList);
    };

    const handlePaymentRelChange = (event, index) => {
      const updatedRemarkList = [...payment_rel];
      updatedRemarkList[index] = event.target.value;
      setpayment_rel(updatedRemarkList);
    };
  
    const handleDoneClick = async (index, acID, bankname) => {
      if (index >= 0 && index < transactionList.length) {
       
        if (typeof payment_rel[index] === 'undefined' || payment_rel[index] === '' ) {
          paymentRefs.current[index].focus();
        } else if (typeof acStatus[index] === 'undefined' || acStatus[index] === '' ) {
          statusRefs.current[index].focus();
        }
        else {
         
          const confirmed = await deleteConfirmation(' to change account status to '+acStatus[index]+' account?');
          if(confirmed===true)
          {

          let logindetail = JSON.parse(localStorage.getItem('userLogin'));
           const formData = {
                id:acID,
                approved_by: logindetail.id,
                payment_remark: remarks[index],
                status: acStatus[index],
                bank_name: bankname,
                payment_rel: payment_rel[index],

          };

              formPost('api-approvals/update-transaction-status', formData).then(data => {
              toast.success(data["message"]);

              // setTimeout(() => {
              //         window.location.reload();
              //   }, 1200);

              const updatedtransactionList = [...transactionList];
              updatedtransactionList.splice(index, 1);
              settransactionList(updatedtransactionList);

              const updatedStatusList = [...acStatus];
              updatedStatusList.splice(index, 1);
              setacStatus(updatedStatusList);

              const updatedRemarkList = [...remarks];
              updatedRemarkList.splice(index, 1);
              setremarks(updatedRemarkList); 


                setSubmitting(false);
              }).catch(error => {
                  setSubmitting(false);
                  toast.error(error);
              });
        }

        } 
        // else {
        //   statusRefs.current[index].focus();
        //   paymentRefs.current[index].focus();
        // }
      } else {
        console.error(`Invalid index: ${index}`);
      }
    };


    // useEffect(() => {
    //   const timeout = setTimeout(() => {
    //     if (window.tableDomJquery) {
    //       window.tableDomJquery();
    //     }
    //   }, 2000); // Timeout of 3000 milliseconds (3 seconds)
  
    //   return () => clearTimeout(timeout); // Clear the timeout on component unmount
    // }, [transactionList]);

  return (
    <DashboardLayout>
<div class="pcoded-content">
      <div class="pcoded-inner-content">

            <div class="main-body">
                  <div class="page-wrapper">

                        <div class="page-header">
                    <div class="row align-items-end">
                        <div class="col-lg-8">
                            <div class="page-header-title">
                                <div class="d-inline">
                                    <h4>Approvals - Loan Account Closures  
                                        
                                     {/* <small>
                                          &nbsp;
                                          <a class="btn btn-info btn-sm" title="Add New" href="/collection-centers-group-new">
                                         <b>+</b> </a> &nbsp; 
                                      </small>  */}
                                           </h4>
                                    {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="page-header-breadcrumb">
                                <ul class="breadcrumb-title">
                                    <li class="breadcrumb-item" style={{float:'left'}}>
                                        <a href="/dashboard"> <i class="feather icon-home"></i> </a>
                                    </li>
                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="#!">Approvals - Loan Account Closures  </a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


            <div class="page-body">


            <div class="card" style={{borderTop:'3px solid green'}} >
                  <div class="card-header py-3 text-denger">
                        <h5>Search Box</h5>
                              <div class="card-header-right">
                                    <ul class="list-unstyled card-option">
                                        <li><i class="feather icon-minus minimize-card"></i></li>
                                    </ul>
                              </div>
                        </div>
                        <div class="card-block" >
                        <form onSubmit={filterData}>
                        <div class="row">
                            <div class="col-sm-12 col-xl-3 m-b-30">
                                <div class="row">
                                  <div class="col-sm-12">
                                  <h6 class="">Branch :</h6>
                                  <select
                                    class="form-control m-b"
                                    name="branch"
                                    value={branch}
                                    onChange={(e) =>
                                      setbranch(e.target.value)
                                    }
                                  >
                                    <option value={' '}>Select - Branch</option>
                                     {activeBranchArr.map((assData) => (
                                      <option
                                        value={assData.id}
                                        selected={
                                          assData.id === branch
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {FirstLetterCapitalize(assData.branch_name)}
                                      </option>
                                    ))}
                                
                                  </select>
                                  </div>
                                </div>
                              </div>
                              
                             
                              <div class="col-sm-12 col-xl-3 m-b-30">
                                <div class="row">
                                
                                  <div class="col-sm-12">
                                  <h6 class=""> Account Type : </h6>
                                  <select
                                    class="form-control m-b"
                                    name="account_for"
                                    value={account_for}
                                    onChange={(e) =>
                                      setaccount_for(e.target.value)
                                    }
                                  >
                                    <option value={''}>All</option>
                                    <option value={'saving'}>Saving</option>
                                    <option value={'rd'}>RD</option>
                                    <option value={'dd'}>DD</option>
                                    <option value={'fd'}>FD</option>
                                    <option value={'mis'}>MIS</option>
                                  </select>
                                
                                  </div>
                                </div>
                              </div>

                              </div>

                              <div class="row">
                                <div class="col-sm-12 col-xl-12 m-b-30 text-center">

                                <button class="btn btn-success btn-square" type="submit" disabled={submitting} >
                                <i class="fa fa-search"></i>   Search 
                                </button>{" "}
                                &nbsp;
                              
                                <button class="btn btn-danger btn-square" type="reset" > Clear Form </button>
                                <ToastContainer className="toast-container" />

                                </div>
                              </div>

                              </form>
                        </div>
                    </div>



                  <div class="row">
                      <div class="col-sm-12">
                          <a href="/approvals-loan-closures-history" class="btn btn-warning btn-square" style={{float: 'inline-end'}} >  <i class="fa fa-history"></i>  APPROVAL HISTORY </a>
                      </div>
                  </div>

                  <div class="card mt-2">
                         <div class="card-header">
                       </div> 
                        <div class="card-block">
                              <div class="table-responsive dt-responsive">
                                    <table class="table dtable table-striped table-bordered nowrap" style={{width:'100%'}}>
                                          <thead>
                                                <tr>
                                                      <th>SR.NO</th>
                                                      <th>BRANCH</th>
                                                      <th>MEMBER</th>
                                                      <th>A/C TYPE</th>
                                                      <th>A/C No.</th>
                                                      <th>AMOUNT</th>
                                                      <th>PAY.MODE</th>
                                                      <th>PAYMENT RECIEVED</th>
                                                      <th>CLOSE DATE</th>

                                                      <th><p style={{width:'100px'}}>STATUS&nbsp;<input
                                                          class="form-control"
                                                          type="checkbox"
                                                          checked={isChecked}
                                                          onChange={handleCheckboxChange}
                                                          style={{fontSize:'6px', display:'inline-block', width:'auto'}}
                                                        /></p>
                                                         </th>
                                                      <th>REMARKS</th>
                                                    
                                                      <th class="text-right">ACTIONS</th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                             
                                          {
                                                transactionList.length > 0 ? (
                                                  transactionList.map((branchData, index) => (

                                                    
                                                      <tr class="gradeX" key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{branchData.branch_name?FirstLetterCapitalize(branchData.branch_name):''}</td>
                                                        <td><a href={`/view-member/${dataEncript(branchData.member_id)}`}> {branchData.member_created_id?FirstLetterCapitalize(branchData.member_created_id):''} - {branchData.member_name?FirstLetterCapitalize(branchData.member_name):''} </a> </td>
                                                        <td>{branchData.account_for?FirstLetterCapitalize(branchData.account_for):''}</td>
                                                        <td>

                                                          { branchData && (
                                                                  branchData.account_for === 'saving' ? (
                                                                    <a href={`/view-member/${dataEncript(branchData.id)}`}>{branchData.account_no?(branchData.account_no):''}</a>
                                                                  ) : branchData.account_for === 'rd' ? (
                                                                    <a href={`/recurring-deposit-account-view/${dataEncript(branchData.id)}`}>{branchData.account_no?(branchData.account_no):''}</a>
                                                                  ) : branchData.account_for === 'mis' ? (
                                                                    <a href={`/fixed-deposit-mis-account-view/${dataEncript(branchData.id)}`}>{branchData.account_no?(branchData.account_no):''}</a>
                                                                  ) : branchData.account_for === 'fd' ? (
                                                                    <a href={`/fixed-deposit-account-view/${dataEncript(branchData.id)}`}>{branchData.account_no?(branchData.account_no):''}</a>
                                                                  ) : branchData.account_for === 'dd' ? (
                                                                    <a href={`/recurring-deposit-account-view/${dataEncript(branchData.id)}`}>{branchData.account_no?(branchData.account_no):''}</a>
                                                                  ) : branchData.account_no
                                                                )
                                                              }
                                                          
                                                          </td>
                                                         
                                                        <td>
                                                          { branchData && (
                                                                branchData.transaction_type === 'debit' ? (
                                                                  <span>DR</span>
                                                                ) : <span>CR</span>
                                                              )
                                                            }

                                                            {formattedAmount(branchData.deposit_amt, 'no')}

                                                        </td>
                                                        <td>{branchData.pay_mode?FirstLetterCapitalize(branchData.pay_mode):''}</td>
                                                        <td></td>
                                                       
                                                       
                                                        <td>
                                                          <input type="date" class="form-control" value={getCurrentDate()} ref={(ref) => (paymentRefs.current[index] = ref)} onChange={(event) => handlePaymentRelChange(event, index)}   />
                                                        </td>


                                                      <td>
                                                     
                                                          <select class="form-control" ref={(ref) => (statusRefs.current[index] = ref)} onChange={(event) => handleStatusChange(event, index)} value={isChecked ? 'approve' : acStatus[index]}>
                                                            <option value="">Select Status</option>
                                                            <option value="approve">Approve</option>
                                                            <option value="rejected">Not Approve</option>
                                                            <option value="pending">Pending</option>
                                                          </select>
                                                      </td>
                                                          
                                                        <td> 
                                                           <textarea name="form-controle"  onChange={(event) => handleRemarkChange(event, index)} rows={'2'}></textarea>  
                                                        </td>
                                                          
                                                      <td class="center">
                                                            <a href="#!" onClick={() => handleDoneClick(index, branchData.id, branchData.bank_name)} class='btn btn-success btn-sm' disabled={submitting} >Done</a>
                                                      </td>
                                                </tr>
                                                  ))
                                                ) :null
                                              }

                                          
                                               
                                          </tbody>
                                        
                                    </table>
                              </div>
                        </div>
                  </div>






            </div>

      </div>
</div>
<ToastContainer className="toast-container" />
<div id="styleSelector">
</div>
</div>
</div>
    </DashboardLayout>
  );
}

export default AdminLayout;
