import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../dashboardLayout';

import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "../../../../config"; // Importing constants directly
// import $ from 'jquery';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



import {
      
      FirstLetterCapitalize
      
    } from '../../../helper/commonHelpers'; 

function AdminLayout() {
   const [promoterList, setpromoterList] = useState([]);


    useEffect(() => {
            axios.get(API_BASE_URL+'api-member/get-shares-list', { headers: API_HEADERS })
            .then(response => {
                  if (response.data["status"] > 0) {
                     setpromoterList(response.data['data']);
                  }
                //   console.log(response.data['data']);
            })
            .catch(error => {
              toast.error("Failed to fetch role list list!");
            });
  
    }, []);






    useEffect(() => {
      const timeout = setTimeout(() => {
        if (window.tableDomJquery) {
          window.tableDomJquery();
        }
      }, 1000); // Timeout of 3000 milliseconds (3 seconds)
  
      return () => clearTimeout(timeout); // Clear the timeout on component unmount
    }, [promoterList]);




    



  return (
    <DashboardLayout>
<div class="pcoded-content">
      <div class="pcoded-inner-content">

            <div class="main-body">
                  <div class="page-wrapper">

                        <div class="page-header">
                    <div class="row align-items-end">
                        <div class="col-lg-8">
                            <div class="page-header-title">
                                <div class="d-inline">
                                    <h4>Share Transfer History  
                                        
                                    {/* <small>
                                          &nbsp;
                                          <a class="btn btn-info btn-sm" title="UN-ALLOTTED SHARE LIST" href="/member-add-holdings">
                                         <b>+</b> </a> &nbsp; 

                                          <a class="btn btn-warning btn-sm" title="UN-ALLOTTED SHARE LIST" href="/share-holdings/unallotted">
                                          UN ALLOTTED SHARE LIST</a> &nbsp; 
                                          <a class="btn btn-primary btn-sm" title="UN-ALLOTTED SHARE LIST" href="/share-holdings/new-allotment">
                                          MANUAL NEW SHARE ALLOCATION</a>
                                      </small> */}
                                        
                                           </h4>
                                    {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="page-header-breadcrumb">
                                <ul class="breadcrumb-title">
                                    <li class="breadcrumb-item" style={{float:'left'}}>
                                        <a href="/dashboard"> <i class="feather icon-home"></i> </a>
                                    </li>
                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="#!">Share Transfer History</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


            <div class="page-body">

                  <div class="card">
                         <div class="card-header">
                      




                        </div> 
                        <div class="card-block">
                              <div class="table-responsive dt-responsive">
                                    <table class="table dtable table-striped table-bordered nowrap" style={{width:'100%'}}>
                                          <thead>
                                                <tr>
                                                      <th>SR.NO</th>
                                                      <th>BRANCH</th>
                                                      <th>BUSINESS TYPE</th>
                                                      <th>TRANSFEROR</th>
                                                      <th>TRANSFEREE</th>
                                                      <th>SHARE RANGE</th>
                                                      <th>NOMINAL VAL.</th>
                                                      <th>NO. OF SHARES</th>
                                                      <th>TRANSFER DATE</th>
                                                      <th>NEW SHARE</th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                             
                                          


                                          {
                                                promoterList.length > 0 ? (
                                                  promoterList.map((branchData, index) => (
                                                      <tr class="gradeX">
                                                      <td>{index + 1}</td>
                                                      <td>{branchData.branch_name?FirstLetterCapitalize(branchData.branch_name):''}</td>
                                                      <td>{branchData.member_name?FirstLetterCapitalize(branchData.member_name):''}</td>
                                                      <td>{branchData.shares?branchData.shares:''}</td>
                                                      
                                                      <td>{branchData.certificate_no?branchData.certificate_no:''}</td>
                                                      <td>{branchData.certificate_no?branchData.certificate_no:''}</td>
                                                      <td>{branchData.certificate_no?branchData.certificate_no:''}</td>
                                                      <td>{branchData.certificate_no?branchData.certificate_no:''}</td>
                                                      <td>{branchData.certificate_no?branchData.certificate_no:''}</td>
                                                      
                                                      <td class="center">
                                                            <a href="#!"  class='font-weight-normal label label-info'> <i class="fa fa-print"></i> SH-1 </a>
                                                            
                                                      </td>
                                                </tr>
                                                  ))
                                                ) : (
                                                  <tr>
                                                    <td colSpan="10">No data available</td>
                                                  </tr>
                                                )
                                              }

                                          
                                               
                                          </tbody>
                                        
                                    </table>
                              </div>
                        </div>
                  </div>






            </div>

      </div>
</div>
<ToastContainer className="toast-container" />
<div id="styleSelector">
</div>
</div>
</div>
    </DashboardLayout>
  );
}

export default AdminLayout;
