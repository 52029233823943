import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../dashboardLayout';

import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "../../../../config"; // Importing constants directly
// import $ from 'jquery';
import { useParams } from 'react-router-dom'; 

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



import {
      
      FirstLetterCapitalize,
      dataEncript,
      dataDecrypt,
      formatDate,
      deleteConfirmation
      
    } from '../../../helper/commonHelpers'; 

function AdminLayout() {
   const [listDetails, setlistDetails] = useState([]);
   const { share15ghID }   = useParams();

    useEffect(() => {
            axios.get(API_BASE_URL+'api-member/get-from-15gh?id='+dataDecrypt(share15ghID), { headers: API_HEADERS })
            .then(response => {
                  if (response.data["status"] > 0) {
                     setlistDetails(response.data['data']);
                  }
                //   console.log(response.data['data']);
            })
            .catch(error => {
              toast.error("Failed to fetch role list list!");
            });
  
    }, [share15ghID]);






    // useEffect(() => {
    //   const timeout = setTimeout(() => {
    //     if (window.tableDomJquery) {
    //       window.tableDomJquery();
    //     }
    //   }, 1000); // Timeout of 3000 milliseconds (3 seconds)
  
    //   return () => clearTimeout(timeout); // Clear the timeout on component unmount
    // }, [listDetails]);


    const deleteTransaction = async (tblid) => {

        const confirmed = await deleteConfirmation('Are you sure to delete transaction?');
        if(confirmed===true)
        {
            const formData = {
                tablename:'tbl_from_15gh',  // table name
                coloum_name:'id', // which collumn name you want to  
                value:tblid, // column value 
             };


             axios.post(API_BASE_URL + "api-common/detete", formData, {
                headers: {
                    ...API_HEADERS,
                },
                })
                .then((response) => {
                if (response.data["status"] > 0) {
                    toast.success(response.data["message"]);
                    setTimeout(() => {
                         window.location.href="/member-form-15-gh";
                    }, 2700);
                } else {
                    toast.error(response.data["message"]);
                }
                })
                .catch((error) => {
                   toast.error("Failed to submition!");
                }); // close query hare


        }
  };

    



  return (
    <DashboardLayout>
<div class="pcoded-content">
      <div class="pcoded-inner-content">

            <div class="main-body">
                  <div class="page-wrapper">

                  <div class="page-header">
                        <div class="row align-items-end">
                        <div class="col-lg-6">
                            <div class="page-header-title">
                            <div class="d-inline">
                                <h4>
                                {listDetails && listDetails.member_name && (
                                    <>{FirstLetterCapitalize(listDetails.member_name)}</>
                                    )}

                                      {listDetails && listDetails.financial_year && (
                                    <>&nbsp; {listDetails.financial_year}</>
                                    )}

                                    <small>&nbsp;Form 15G/ 15H</small> </h4>
                                {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                            </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="page-header-breadcrumb">
                            <ul class="breadcrumb-title">
                                <li class="breadcrumb-item" style={{ float: "left" }}>
                                <a href="/dashboard">
                                    {" "}
                                    <i class="feather icon-home"></i>{" "}
                                </a>
                                </li>
                                <li class="breadcrumb-item" style={{ float: "left" }}>
                                <a href="/member-form-15-gh">Form 15G/ 15H  </a>
                                </li>

                                <li class="breadcrumb-item" style={{ float: "left" }}>
                                 <a href="#!"> {listDetails && listDetails.financial_year && (
                                    <>{FirstLetterCapitalize(listDetails.financial_year)}</>
                                    )}</a>
                                </li>
                            </ul>
                            </div>
                        </div>
                        </div>
                    </div>


            <div class="page-body">

                <div class="row">
                     <div class="col-md-7">
                     <div class="card">
                         <div class="card-header">

                         <small style={{float: 'right'}}>

                            <a class="btn btn-info btn-sm" title="UN-ALLOTTED SHARE LIST" href={`/member-edit-holdings/${dataEncript(listDetails.id)}`}>
                            <i class="feather icon-edit"></i></a>&nbsp;

                            <a class="btn btn-danger btn-sm" title="UN-ALLOTTED SHARE LIST" onClick={() => deleteTransaction(listDetails.id)}  href="#!">
                            <i class="feather icon-trash"></i></a>

                        </small>

                        </div> 
                        <div class="card-block">
                              <div class="table-responsive dt-responsive">
                                    <table class="table dtable table-striped table-bordered nowrap" style={{width:'100%'}}>
                                     
                                          <tbody>

                                          <tr>
                                            <th>Member</th>
                                            <td>
                                            {listDetails && listDetails.member_name && (
                                                <>{FirstLetterCapitalize(listDetails.member_name)}</>
                                                )}</td>
                                        </tr>

                                        <tr>
                                            <th>Financial Year</th>
                                            <td>
                                            {listDetails && listDetails.financial_year && (
                                                <>&nbsp; {listDetails.financial_year}</>
                                                )}</td>
                                        </tr>

                                        <tr>
                                            <th>Created at</th>
                                            <td>
                                            {listDetails && listDetails.created_at && (
                                                <>{formatDate(listDetails.created_at)}</>
                                                )}</td>
                                        </tr>

                                        <tr>
                                            <th>Updated at</th>
                                            <td>
                                            {listDetails && listDetails.updated_at && (
                                                <>{formatDate(listDetails.updated_at)}</>
                                                )}</td>
                                        </tr>


                                        <tr>
                                            <th>Form 15G/ 15H</th>
                                            <td>
                                            {listDetails && listDetails.updated_at && (
                                                <>{formatDate(listDetails.updated_at)}</>
                                                )}</td>
                                        </tr>
                                          </tbody>
                                     </table>
                                    </div>
                                </div>
                            </div>
                     </div>
                </div>


                 






            </div>

      </div>
</div>
<ToastContainer className="toast-container" />
<div id="styleSelector">
</div>
</div>
</div>
    </DashboardLayout>
  );
}

export default AdminLayout;
