import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../dashboardLayout';
import { useParams } from 'react-router-dom'; 

import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "../../../../config"; // Importing constants directly
// import $ from 'jquery';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



import {
      
      FirstLetterCapitalize,
      dataDecrypt,
      formatDate,
      formattedAmount,
      dataEncript,
      deleteConfirmation
      
      
    } from '../../../helper/commonHelpers'; 

function AdminLayout() {
    const { scheemID }   = useParams();
  const [listDetails, setlistDetails] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [commission_chart, setcommission_chart] = useState(false);


  const [formErrors, setFormErrors] = useState({
    commission_chart: '',
  });

    useEffect(() => {


            axios.get(API_BASE_URL + "api-common/get-account-scheme-details?id="+dataDecrypt(scheemID), {
                headers: API_HEADERS,
              }).then((response) => {
          
          
                if (response.data["status"] > 0) {
                  setlistDetails(response.data["data"]);
          
                } else {
                    toast.error(response.data["message"]);
                }
          
              }).catch((error) => {
                toast.error("Failed to fetch data!");
              });


  
    }, [scheemID]);



    
  const updateDetails = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));

    const formData = {
        // table_id:dataDecrypt(memberID),
        commission_chart: commission_chart,
    };

    // validate form

    if (!commission_chart) {
      errors.commission_chart = 'Scheme Name is required.';
    }



    // Set formErrors state with validation errors
    setFormErrors(errors);
    const confirmed = await deleteConfirmation('to update commission chart.?');
    if(confirmed===true)
    {
    if (Object.keys(errors).length === 0) {

      axios.post(API_BASE_URL + "api-saving-account/add-new-scheme", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/account-scheem-list";
            //   window.location.reload();
            }, 2000);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 1000);
        }); // close query hare

        
    } else {
      setSubmitting(false);
    } // hare close error

}
  };


    // useEffect(() => {
    //   const timeout = setTimeout(() => {
    //     if (window.tableDomJquery) {
    //       window.tableDomJquery();
    //     }
    //   }, 1000); // Timeout of 3000 milliseconds (3 seconds)
  
    //   return () => clearTimeout(timeout); // Clear the timeout on component unmount
    // }, [promoterList]);




    



  return (
    <DashboardLayout>
<div class="pcoded-content">
      <div class="pcoded-inner-content">

            <div class="main-body">
                  <div class="page-wrapper">

                  <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>Edit Saving Scheme
                          {listDetails && listDetails.scheme_name && (
                              <>{FirstLetterCapitalize(listDetails.scheme_name)}</>
                              )}
                        </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/account-scheem-list">Saving Scheme</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">  {listDetails && listDetails.scheme_name && (
                              <>{FirstLetterCapitalize(listDetails.scheme_name)}</>
                              )}</a>
                        </li>

                       
                      </ul>
                    </div>
                  </div>
                </div>
              </div>


            <div class="page-body">


            <div class="row">
                <div class="col-md-7">
                <div class="card" style={{borderTop:'3px solid green'}}>
                         <div class="card-header">
                         <small style={{float: 'right'}}>

                            <a class="btn btn-info btn-sm" title="" href={`/account-scheem-edit/${dataEncript(listDetails.id)}`}>
                            <i class="feather icon-edit"></i></a>&nbsp;

                            </small>

                        </div> 
                        <div class="card-block">
                              <div class="table-responsive dt-responsive">
                                    <table class="table dtable table-striped table-bordered nowrap" style={{width:'100%'}}>
                                        
                                          <tbody>
                                             
                                          <tr>
                                            <th>Scheme Code</th>
                                            <td> {listDetails && listDetails.scheme_code && (
                                                <>{FirstLetterCapitalize(listDetails.scheme_code)}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Scheme Name</th>
                                            <td> {listDetails && listDetails.scheme_name && (
                                                <>{FirstLetterCapitalize(listDetails.scheme_name)}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Minimum account opening balance</th>
                                            <td> {listDetails && listDetails.min_open_bal && (
                                                <>{formattedAmount(listDetails.min_open_bal)}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Minimum avg. balance to maintain per month</th>
                                            <td> {listDetails && listDetails.min_month_avg_bal && (
                                                <>{formattedAmount(listDetails.min_month_avg_bal)}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Minimum avg. balance charge per month</th>
                                            <td> {listDetails && listDetails.min_month_avg_bal_charge && (
                                                <>{formattedAmount(listDetails.min_month_avg_bal_charge)}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Annual Rate of Interest</th>
                                            <td> {listDetails && listDetails.annual_ins_rate && (
                                                <>{listDetails.annual_ins_rate}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Senior Citizen Add-on Rate of Interest</th>
                                            <td> {listDetails && listDetails.add_on_ins_rate && (
                                                <>{(listDetails.add_on_ins_rate)}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Interest Payout</th>
                                            <td> {listDetails && listDetails.ins_payout && (
                                                <>{FirstLetterCapitalize(listDetails.ins_payout)}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Lock In Amount</th>
                                            <td> {listDetails && listDetails.lock_amt && (
                                                <>{formattedAmount(listDetails.lock_amt)}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Active</th>
                                            <td> {
                                                            listDetails && (
                                                              listDetails.scheme_active === 'yes' ? (
                                                                <label className="label label-success">Yes</label>
                                                              ) : listDetails.scheme_active === 'no' ? (
                                                                <label className="label label-danger">No</label>
                                                              ) : null
                                                            )
                                                          }
                                                </td>
                                            </tr>
                                            <tr>
                                            <th>Service Charges</th>
                                            <td> {listDetails && listDetails.service_charge && (
                                                <>{formattedAmount(listDetails.service_charge)}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>SMS Charges</th>
                                            <td> {listDetails && listDetails.sms_charge && (
                                                <>{formattedAmount(listDetails.sms_charge)}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Free IFSC Collection per Month</th>
                                            <td> {listDetails && listDetails.scheme_name && (
                                                <>{FirstLetterCapitalize(listDetails.scheme_name)}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Created at</th>
                                            <td> {listDetails && listDetails.created_at && (
                                                <>{formatDate(listDetails.created_at)}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Updated at</th>
                                            <td> {listDetails && listDetails.updated_at && (
                                                <>{formatDate(listDetails.updated_at)}</>
                                                )}</td>
                                            </tr>
                                          </tbody>
                                        
                                    </table>
                              </div>
                        </div>
                  </div>
                </div>
                <div class="col-md-5">
                <div class="card" style={{borderTop:'3px solid red'}}>
                         <div class="card-header">
                         <h5>Commission Chart</h5>
                        </div> 
                        <div class="card-block">
                              <div class="table-responsive dt-responsive">
                              <form onSubmit={updateDetails}>
                                    <table class="table " style={{width:'100%'}}>
                                        
                                          <tbody>
                                             
                                          <tr>
                                            <th>Commission Chart</th>
                                            <td> 

                                            <div class="input-group">
                                                <select
                                                    class="form-control m-b"
                                                    name="commission_chart"
                                                    value={commission_chart}
                                                    onChange={(e) =>
                                                    setcommission_chart(e.target.value)
                                                    }
                                                >
                                                <option value="">Select interest payout cycle</option>
                                                <option value="MONTHLY">Monthly</option>
                                                <option value="QAURTERLY">Qaurterly</option>
                                                <option value="HALF_YEARLY">Half Yearly</option>
                                                <option value="YEARLY">Yearly</option>
                                                
                                                </select>
                                                <div class="input-group-prepend" style={{width:'auto'}}>
                                                <input class="form-control btn btn-success" disabled={submitting} type="submit" value="Update" />
                                                </div>
                                                </div>

                                                {formErrors.commission_chart && (
                                                    <span style={{ color: "red" }}>
                                                    {formErrors.commission_chart}
                                                    </span>
                                                )}

                                            </td>
                                            </tr>
                                          </tbody>
                                        
                                    </table>

                                    </form>
                              </div>
                        </div>
                  </div>
                </div>

            </div>

              






            </div>

      </div>
</div>
<ToastContainer className="toast-container" />
<div id="styleSelector">
</div>
</div>
</div>
    </DashboardLayout>
  );
}

export default AdminLayout;
