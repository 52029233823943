import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { dataDecrypt,
    dataEncript,
     } from "./../../../helper/commonHelpers";

function CompanyDetails() {
  // const navigate = useNavigate();
  const { promoterID }   = useParams();
  const [submitting, setSubmitting] = useState(false);
  // const [stateList, setStateList] = useState([]);
  const [promoterhDetails, setpromoterhDetails] = useState([]);

  const [formErrors, setFormErrors] = useState({
    new_password: "",
    confirm_password: "",

  });


  // PROMOTER INFO 
  const [new_password, setnew_password] = useState('');
  const [confirm_password, setconfirm_password] = useState('');

  useEffect(() => {

  // get bran ch details
  axios
  .get(API_BASE_URL + "api-company/get-promoter-by-id?promoter_id="+dataDecrypt(promoterID), {
    headers: API_HEADERS,
  })
  .then((response) => {
    setpromoterhDetails(response.data['data']);
  })
  .catch((error) => {
    toast.error('Failed to fetch DETAILS list!');
  });
 

    //    setenrollment_date(getCurrentDate());
  }, [promoterID]);

  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};

    const formData = {
      new_password:new_password,
      con_password:confirm_password,
      promoter_id:dataDecrypt(promoterID)
    };


   

    // validate form
   

      if (!formData.new_password) {
        errors.new_password = 'New Password is required.';
      }

      if (!formData.con_password) {
        errors.confirm_password = 'Confirm Password is required.';
      }else if (formData.con_password !== formData.new_password) {
        errors.confirm_password = 'Confirm Password is not matched.';
      }

      // console.log(formData);

    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      axios
        .post(API_BASE_URL + "api-company/promoter-reset-password", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/view-promoter/"+dataEncript(promoterhDetails.id);
            }, 2700);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 300);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>Reset Password - {promoterhDetails && promoterhDetails.fname && (
                                    <span>{promoterhDetails.fname}</span>
                                    )}

                                    {promoterhDetails && promoterhDetails.mname && (
                                    <span>{promoterhDetails.mname}</span>
                                    )} </h4>

                                    
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/promoter-list">Promoter List</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href={`/view-promoter/${dataEncript(promoterhDetails.id)}`} >{promoterhDetails && promoterhDetails.fname && (
                                    <span>{promoterhDetails.fname}</span>
                                    )}

                                    {promoterhDetails && promoterhDetails.mname && (
                                    <span>{promoterhDetails.mname}</span>
                                    )}</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">Reset Password</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12"></div>

                  <div class="col-sm-12">
                    <div class="card">
                      <div class="card-header">
                        <h5>Edit Promoter</h5>
                        {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                      </div>

                      <div class="card-block">
                        <form onSubmit={updateDetails}>


                        <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30 offset-xl-2">
                                <div class="row">
                                <div class="col-sm-5">
                                    <h6 class="text-xl-right">New Password<span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-sm-7">
                                    <input class="form-control" type="password" value={new_password} onChange={(e) => setnew_password(e.target.value)} placeholder='Enter New Password' />
                                    {formErrors.new_password && <span style={{ color: 'red' }}>{formErrors.new_password}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30 offset-xl-2">
                                <div class="row">
                                <div class="col-sm-5">
                                    <h6 class="text-xl-right">Confirm Password<span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-sm-7">
                                    <input class="form-control" type="password" value={confirm_password} onChange={(e) => setconfirm_password(e.target.value)} placeholder='Enter Confirm Password' />
                                    {formErrors.confirm_password && <span style={{ color: 'red' }}>{formErrors.confirm_password}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                            
                        



                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                              <button
                                class="btn btn-success btn-square"
                                type="submit"
                                disabled={submitting}
                              >
                                Reset
                              </button>{" "}
                              &nbsp;
                              <a href={`/view-promoter/${dataEncript(promoterhDetails.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                              &nbsp;
                             

                              <ToastContainer className="toast-container" />
                            </div>
                          </div>


                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
