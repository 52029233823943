import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom';

import $ from 'jquery';
import 'dropify/dist/css/dropify.min.css';
import 'dropify';



import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "components/admin/membermanagement/member/MemberCss.css";

import './styles.css'; 
import 'select2/dist/css/select2.min.css';
import 'select2';


import {  dataEncript, dataDecrypt } from "./../../../helper/commonHelpers";
// /getCurrentDate,

function CompanyDetails() {
  const { memberID }   = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [memberDetails, setmemberDetails] = useState([]);


  const [formErrors, setFormErrors] = useState({
  });





  const[doc_type1, setdoc_type1] = useState('Photo');
  const[doc_photo, setdoc_photo] = useState('');
  const[doc_type2, setdoc_type2] = useState('driving_license');
  const[doc_id_proof, setdoc_id_proof] = useState('');
  const[doc_type3, setdoc_type3] = useState('driving_license');
  const[doc_address_proof, setdoc_address_proof] = useState('');
  const[doc_type4, setdoc_type4] = useState('pan');
  const[doc_pan, setdoc_pan] = useState('');
  const[doc_type5, setdoc_type5] = useState('Signature');
  const[doc_signature, setdoc_signature] = useState('');





  useEffect(() => {

    setTimeout(() => {
        $('.dropify').dropify();
      }, 1300);

   


      axios.get(API_BASE_URL+'api-member/get-member-list?member_id='+dataDecrypt(memberID), { headers: API_HEADERS })
            .then(response => {
         if (response.data["status"] > 0) {
               setmemberDetails(response.data["data"]);
        } else {
            toast.error(response.data["message"]);
        }
      }).catch((error) => {
        toast.error("Failed to fetch details DATA!");
      });



      // setaccount_open_date(getCurrentDate());
  }, [memberID]);





  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);


    let errors = {};

    const formData = new FormData();
  formData.append('member_id', dataDecrypt(memberID)); 

formData.append('doc_type1', doc_type1);
if (doc_photo) {
  formData.append('doc_photo', doc_photo); 
}

formData.append('doc_type2', doc_type2);
if (doc_id_proof) {
  formData.append('doc_id_proof', doc_id_proof); 
}

formData.append('doc_type3', doc_type3);
if (doc_address_proof) {
  formData.append('doc_address_proof', doc_address_proof); 
}


formData.append('doc_type4', doc_type4);
if (doc_pan) {
  formData.append('doc_pan', doc_pan); 
}

formData.append('doc_type5', doc_type5);
if (doc_signature) {
  formData.append('doc_signature', doc_signature); 
}





    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
        axios.post(API_BASE_URL + 'api-member/update-document', formData, {
          headers: {
            ...API_HEADERS
          },
        }).then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/view-member/"+memberID;
            }, 3700);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 300);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  }; 




  const handledoc_photo = (e) => {
    const file = e.target.files[0]; 
    setdoc_photo(file); 
  };
  const handledoc_id_proof = (e) => {
    const file = e.target.files[0]; 
    setdoc_id_proof(file); 
  };

  const handledoc_address_proof = (e) => {
    const file = e.target.files[0]; 
    setdoc_address_proof(file); 
  };

  const handledoc_pan = (e) => {
    const file = e.target.files[0]; 
    setdoc_pan(file); 
  };

  const handledoc_signature = (e) => {
    const file = e.target.files[0]; 
    setdoc_signature(file); 
  };

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                    <div class="d-inline">
                        <h4> {memberDetails && memberDetails.fname && (
                                    <>{memberDetails.fname}</>
                                    )}

                                    {memberDetails && memberDetails.mname && (
                                    <>{memberDetails.mname}</>
                                    )}  <small> Upload Documents</small> </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/members-management">Member List</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href={`/view-member/${dataEncript(memberDetails.id)}`} >{memberDetails && memberDetails.fname && (
                                    <span>{memberDetails.fname}</span>
                                    )}

                                    {memberDetails && memberDetails.mname && (
                                    <span>{memberDetails.mname}</span>
                                    )}</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">Upload Documents</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12"></div>

                  <div class="col-sm-12">
                    <div class="card">
                      <div class="card-header">
                        {/* <h5>Add New Promoter</h5> */}
                        {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                      </div>

                      <div class="card-block">
                        <form onSubmit={updateDetails}>

                      



                              <div class="row" >
                                  <div class="col-sm-12 col-xl-6 m-b-30">
                                    <div class="row">
                                      <div class="col-sm-5">
                                        <h6 class="text-xl-right">
                                        Photo
                                        </h6>
                                      </div>
                                      <div class="col-sm-7">
                                        
                                        <div class="input-group">

                                        <select class="form-control" name="marital_status"
                                          value={doc_type1}
                                          onChange={(e) =>
                                            setdoc_type1(e.target.value)
                                          }
                                        >
                                          <option value="Photo">Photo</option>
                                        </select>
                                          
                                        <div class="input-group-prepend">
                                            <input class="form-control" style={{width:'100px'}} type="file" accept="image/*" onChange={handledoc_photo} data-default-file={memberDetails?.doc_photo ? API_BASE_URL + memberDetails.doc_photo : ''} className="dropify" />
                                        </div>
                                        </div>
                                        {formErrors.doc_photo && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.doc_photo}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-sm-12 col-xl-6 m-b-30">
                                    <div class="row">
                                      <div class="col-sm-5">
                                        <h6 class="text-xl-right">
                                        Id Proof
                                        </h6>
                                      </div>
                                      <div class="col-sm-7">
                                        
                                        <div class="input-group">

                                        <select class="form-control" name="marital_status"
                                          value={doc_type2}
                                          onChange={(e) =>
                                            setdoc_type2(e.target.value)
                                          }
                                        >
                                         <option value="driving_license">Driving License</option>
                                        <option value="aadhar_card">Aadhaar Card</option>
                                        <option value="voter_id">Voter ID</option>
                                        <option value="ration_card">Ration Card</option>
                                        </select>
                                          
                                        <div class="input-group-prepend">
                                            <input class="form-control" type="file" accept="image/*" onChange={handledoc_id_proof} data-default-file={memberDetails?.doc_id_proof ? API_BASE_URL + memberDetails.doc_id_proof : ''} className="dropify" />
                                        </div>
                                        </div>
                                        {formErrors.doc_id_proof && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.doc_id_proof}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                          </div>

                          <div class="row" >
                                  <div class="col-sm-12 col-xl-6 m-b-30">
                                    <div class="row">
                                      <div class="col-sm-5">
                                        <h6 class="text-xl-right">
                                        Address Proof
                                        </h6>
                                      </div>
                                      <div class="col-sm-7">
                                        
                                        <div class="input-group">

                                        <select class="form-control" name="marital_status"
                                          value={doc_type3}
                                          onChange={(e) =>
                                            setdoc_type3(e.target.value)
                                          }
                                        >
                                         <option value="driving_license">Driving License</option>
                                        <option value="aadhar_card">Aadhaar Card</option>
                                        <option value="voter_id">Voter ID</option>
                                        <option value="ration_card">Ration Card</option>
                                        </select>
                                          
                                        <div class="input-group-prepend">
                                            <input class="form-control" type="file" accept="image/*" onChange={handledoc_address_proof} data-default-file={memberDetails?.doc_address_proof ? API_BASE_URL + memberDetails.doc_address_proof : ''} className="dropify" />
                                        </div>
                                        </div>
                                        {formErrors.doc_address_proof && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.doc_address_proof}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-sm-12 col-xl-6 m-b-30">
                                    <div class="row">
                                      <div class="col-sm-5">
                                        <h6 class="text-xl-right">
                                        Pan Number
                                        </h6>
                                      </div>
                                      <div class="col-sm-7">
                                        
                                        <div class="input-group">

                                        <select class="form-control" name="marital_status"
                                          value={doc_type4}
                                          onChange={(e) =>
                                            setdoc_type4(e.target.value)
                                          }
                                        >
                                         <option value="pan">PAN</option>
                                        <option value="form_60">Form 60</option>

                                        </select>
                                          
                                        <div class="input-group-prepend">
                                            <input class="form-control" type="file" accept="image/*" onChange={handledoc_pan} data-default-file={memberDetails?.doc_pan ? API_BASE_URL + memberDetails.doc_pan : ''}  className="dropify" />
                                        </div>
                                        </div>
                                        {formErrors.doc_pan && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.doc_pan}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                          </div>


                          <div class="row" >
                                  <div class="col-sm-12 col-xl-6 m-b-30">
                                    <div class="row">
                                      <div class="col-sm-5">
                                        <h6 class="text-xl-right">
                                        Signature
                                        </h6>
                                      </div>
                                      <div class="col-sm-7">
                                        
                                        <div class="input-group">

                                        <select class="form-control" name="marital_status"
                                          value={doc_type5}
                                          onChange={(e) =>
                                            setdoc_type5(e.target.value)
                                          }
                                        >
                                         <option value="Signature">Signature</option>
                                        </select>
                                          
                                        <div class="input-group-prepend">
                                            <input class="form-control" type="file" accept="image/*" onChange={handledoc_signature} data-default-file={memberDetails?.doc_signature ? API_BASE_URL + memberDetails.doc_signature : ''} className="dropify" />
                                        </div>
                                        </div>
                                        {formErrors.doc_signature && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.doc_signature}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                          </div>

                                      
                                      
                              
                        


                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                              <button
                                class="btn btn-success btn-square"
                                type="submit"
                                disabled={submitting}
                              >
                                UPDATE
                              </button>{" "}
                              &nbsp;
                              <a href={`/view-member/${memberID}`} class="btn btn-danger btn-square">Cancel</a>
                              &nbsp;
                             

                              <ToastContainer className="toast-container" />
                            </div>
                          </div>


                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;