// import React from 'react';
import React, { useState, useEffect } from 'react'; // Import useEffect from React
import {  useLocation } from 'react-router-dom'; 
import "./dashboardLayout.css";

function DashboardLayouts({ children }) {

    const location = useLocation(); 
    const [activeSideBar, setActiveSideBar] = useState('dashboard');
    const companyTab = ['form','company-details', 'list', 'edit-company-details', 'branches-list', 'add-new-branch', 'edit-branch', 'view-branch', 'promoter-list', 'add-new-promoter', 'edit-promoter', 'view-promoter', 'change-password-promoter', 'add-new-minor', 'edit-minor', 'view-minor', 'edit-bank-details',
                        'share-holdings', 'add-share-holdings', 'edit-share-holdings', 'view-share-holdings',
                        'director', 'add-director', 'edit-director', 'view-director',
                        'unencumbered-deposits','add-unencumbered-deposits','edit-unencumbered-deposits','view-unencumbered-deposits',
                        'bank-accounts', 'add-bank-account', 'edit-bank-account', 'view-bank-account'
                        ];

    const userTab = [
        'user-management-role','add-user-management-role','edit-user-management-role','view-user-management-role',
        'users-management', 'add-user', 'edit-user', 'view-user', 'reset-user-password',
         ];

         const memberTab = [
            'members-management', 'add-member', 'edit-member', 'view-member', 'member-change-password', 'member-change-email-phone', 'member-add-new-minor', 'member-edit-minor', 'member-view-minor', 'member-edit-bankdetails', 'member-edit-address', 'member-update-document', 'member-add-comment', 'member-add-shares', 'member-shares-amount', 'member-transaction', 'member-transaction-view', 'member-ship-charge', 'member-upload-15g15h', 'member-status-inactive', 'member-remove', 'member-minors', 'member-share-holdings', 'member-share-certificate', 'member-share-transfer-history', 'member-form-15-gh', 'member-upload-15-gh', 'member-view-15-gh',
            'member-share-holdings', 'member-add-holdings', 'member-edit-holdings', 'member-view-holdings',

             ];

          const accountTab = ['account-scheem-list', 'account-scheem-add', 'account-scheem-edit', 'account-scheem-view', 'account-list', 'account-open-new', 'account-edit', 'account-view', 'account-upload-document', 'account-add-comment', 'account-transaction', 'account-deposite-mony', 'account-mony-debit', 'account-other-charge', 'account-other-charge-pay', 'account-transaction-view', 'account-other-charge-clear-due', 'account-credit-interest', 'account-change-account-type', 'account-change-nominee', 'account-upgrade'];

          const fdmisTab = ['fixed-deposit-schemes', 'fixed-deposit-schemes-add', 'fixed-deposit-schemes-view', 'fixed-deposit-schemes-edit', 'fixed-deposit-calculator', 'fixed-deposit-account', 'fixed-deposit-account-new', 'fixed-deposit-account-view', 'fixed-deposit-account-payouts', 'fixed-deposit-change-account-info', 'fixed-deposit-add-nominee', 'fixed-deposit-fore-close', 'fixed-deposit-account-remove', 'fixed-deposit-link-saving-account', 'fixed-deposit-link-update-interest', 'fixed-deposit-link-debit-interest', 'fixed-deposit-mis-account', 'fixed-deposit-mis-account-new', 'fixed-deposit-mis-account-view', 'recurring-deposit-account', 'recurring-deposit-account-new', 'recurring-deposit-account-view', 'recurring-deposit-account-installments', 'recurring-deposit-account-add-minor', 'recurring-deposit-account-fore-close', 'recurring-deposit-account-other-charges', 'recurring-deposit-account-other-charges-pay', 'recurring-deposit-account-clear-due', 'recurring-deposit-account-link-saving-account', 'recurring-deposit-account-credit-interest', 'recurring-deposit-account-mony', 'recurring-deposit-account-debit', 'daily-deposit-accounts', 'daily-deposit-accounts-new', 'daily-deposit-accounts-other-charges-pay'];
          const associateTab  = ['associate-advisor-agent-ranks', 'associate-advisor-agent-ranks-new', 'associate-advisor-agent-ranks-edit', 'associate-advisor-agent-ranks-view', 'associate-advisor-commission-charts', 'associate-advisor-commission-charts-new', 'associate-advisor-commission-charts-edit', 'associate-advisor-commission-charts-view', 'associate-advisor', 'associate-advisor-new', 'associate-advisor-edit', 'associate-advisor-view', 'associate-advisor-chnage-password', 'associate-advisor-link-saving-account'];
          const collCenterTab = ['collection-centers', 'collection-centers-new', 'collection-centers-edit', 'collection-centers-view', 'collection-centers-group', 'collection-centers-group-new', 'collection-centers-group-edit', 'collection-centers-group-view', 'collection-centers-group-add-comment'];
          const approvalTab   = ['approvals-accounts', 'approvals-accounts-history', 'approvals-transaction', 'approvals-transaction', 'approvals-transaction-history', 'approvals-reverse-transactions', 'approvals-non-compliances', 'approvals-loan-closures', 'approvals-loan-closures-history', 'approvals-deposit-closures', 'approvals-share-transfer', 'approvals-share-transfer-history', 'approvals-share-surrender', 'approvals-share-surrender-history', 'approvals-within-bank-transfer', 'approvals-within-bank-transfer-history', 'approvals-accounting-vouchers', 'approvals-accounting-vouchers-history', 'approvals-loans', 'approvals-loans-history'];

    
  useEffect(() => {
    setActiveSideBar((location?.pathname.split('/')[1] || ''));
    if(!localStorage.getItem('userLogin'))
    {
      window.location.href = "/";
    }

  }, [location]);



  const logout = () => {
    // Perform logout logic, clear user and token if needed
    localStorage.removeItem('userLogin');
    window.location.href = "/";

  };

//   const storedUser = JSON.parse(localStorage.getItem('userLogin'));
//   console.log(storedUser); // Outputs the user object

    
  return (
    <>
<div class="theme-loader">
    <div class="ball-scale">
        <div class="contain">
            <div class="ring">
                <div class="frame"></div>
            </div>
            <div class="ring">
                <div class="frame"></div>
            </div>
            <div class="ring">
                <div class="frame"></div>
            </div>
            <div class="ring">
                <div class="frame"></div>
            </div>
            <div class="ring">
                <div class="frame"></div>
            </div>
            <div class="ring">
                <div class="frame"></div>
            </div>
            <div class="ring">
                <div class="frame"></div>
            </div>
            <div class="ring">
                <div class="frame"></div>
            </div>
            <div class="ring">
                <div class="frame"></div>
            </div>
            <div class="ring">
                <div class="frame"></div>
            </div>
        </div>
    </div>
</div>

<div id="pcoded" class="pcoded">
    <div class="pcoded-overlay-box"></div>
    <div class="pcoded-container navbar-wrapper">


        <nav class="navbar header-navbar pcoded-header">
            <div class="navbar-wrapper">
                <div class="navbar-logo">
                    <a class="mobile-menu" id="mobile-collapse" href="#blank">
                        <i class="feather icon-menu"></i>
                    </a>
                    <a href="../index.html">
                        <img class="img-fluid" src="../files/assets/images/logo.png" alt="Theme-Logo" />
                    </a>
                    <a class="mobile-options" href="#blank">
                        <i class="feather icon-more-horizontal"></i>
                    </a>
                </div>
                <div class="navbar-container">
                    <ul class="nav-left">
                        {/* <li class="header-search">
                            <div class="main-search morphsearch-search">
                                <div class="input-group">
                                    <span class="input-group-addon search-close"><i class="feather icon-x"></i></span>
                                    <input type="text" class="form-control" />
                                    <span class="input-group-addon search-btn"><i class="feather icon-search"></i></span>
                                </div>
                            </div>
                        </li> */}
                        {/* <li>
                            <a href="#blank onClick={toggleFullScreen}" >
                                <i class="feather icon-maximize full-screen"></i>
                            </a>
                        </li> */}
                    </ul>
                    <ul class="nav-right">

                    


                        <li class="user-profile header-notification">
                            <div class="dropdown-primary dropdown">
                                <div class="dropdown-toggle" data-toggle="dropdown">
                                    <span>Accounts</span>
                                    <i class="feather icon-chevron-down"></i>
                                </div>
                                <ul class="show-notification profile-notification dropdown-menu" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                                    <li>
                                        <a href="#blank">
                                            <i class="feather icon-settings"></i> Tree
                                        </a>
                                    </li>
                                    <li>
                                        <a href="user-profile.html">
                                            <i class="feather icon-user"></i> Vendor
                                        </a>
                                    </li>
                                    <li>
                                        <a href="email-inbox.html">
                                            <i class="feather icon-mail"></i> Ledger Groups
                                        </a>
                                    </li>
                                    <li>
                                        <a href="auth-lock-screen.html">
                                            <i class="feather icon-lock"></i> Ledgers
                                        </a>
                                    </li>

                                    <li>
                                        <a href="auth-lock-screen.html">
                                            <i class="feather icon-lock"></i> Entries
                                        </a>
                                    </li>

                                    <li>
                                        <a href="auth-lock-screen.html">
                                            <i class="feather icon-lock"></i> Trial Balance 
                                        </a>
                                    </li>
                                    <li>
                                    <a href="auth-lock-screen.html">
                                        <i class="feather icon-lock"></i> Profit and Loss (P & L)
                                    </a>
                                    </li>

                                    <li>
                                    <a href="auth-lock-screen.html">
                                        <i class="feather icon-lock"></i> Income Statement
                                    </a> </li>

                                    <li>
                                    <a href="auth-lock-screen.html">
                                        <i class="feather icon-lock"></i> Balance Sheet
                                    </a> </li>


                                    <li>
                                    <a href="auth-lock-screen.html">
                                        <i class="feather icon-lock"></i> FY REPORT
                                    </a> </li>
                                    
                                </ul>
                            </div>
                        </li>


                        <li class="header-notification">
                            <div class="dropdown-primary dropdown">
                                <div class="displayChatbox dropdown-toggle" data-toggle="dropdown">
                                    New Journal Entry
                                </div>
                            </div>
                        </li>

                        <li class="header-notification">
                            <div class="dropdown-primary dropdown">
                                <div class="displayChatbox dropdown-toggle" data-toggle="dropdown">
                                    Day Book
                                </div>
                            </div>
                        </li>

                        <li class="header-notification">
                            <div class="dropdown-primary dropdown">
                                <div class="displayChatbox dropdown-toggle" data-toggle="dropdown">
                                    Schedule SMS
                                </div>
                            </div>
                        </li>

                        <li class="user-profile header-notification">
                            <div class="dropdown-primary dropdown">
                                <div class="dropdown-toggle" data-toggle="dropdown">
                                    <span>Reports</span>
                                    <i class="feather icon-chevron-down"></i>
                                </div>
                                <ul class="show-notification profile-notification dropdown-menu" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                                    <li>
                                        <a href="#blank">
                                            <i class="feather icon-settings"></i> Associate Report
                                        </a>
                                    </li>
                                    <li>
                                        <a href="user-profile.html">
                                            <i class="feather icon-user"></i> Branch Report
                                        </a>
                                    </li>
                                    <li>
                                        <a href="email-inbox.html">
                                            <i class="feather icon-mail"></i> Maturity Report
                                        </a>
                                    </li>
                                    <li>
                                        <a href="auth-lock-screen.html">
                                            <i class="feather icon-lock"></i> Loan Report
                                        </a>
                                    </li>
                                    
                                </ul>
                            </div>
                        </li>


                        <li class="user-profile header-notification">
                            <div class="dropdown-primary dropdown">
                                <div class="dropdown-toggle" data-toggle="dropdown">
                                    <span>Daily Collection</span>
                                    <i class="feather icon-chevron-down"></i>
                                </div>
                                <ul class="show-notification profile-notification dropdown-menu" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                                    <li>
                                        <a href="#blank">
                                            <i class="feather icon-settings"></i> Dashboard
                                        </a>
                                    </li>
                                    <li>
                                        <a href="user-profile.html">
                                            <i class="feather icon-user"></i> Associate Collection Approval
                                        </a>
                                    </li>
                                    <li>
                                        <a href="email-inbox.html">
                                            <i class="feather icon-mail"></i> Associate Collection Report
                                        </a>
                                    </li>
                                    <li>
                                        <a href="auth-lock-screen.html">
                                            <i class="feather icon-lock"></i> Collection Report
                                        </a>
                                    </li>

                                    <li>
                                        <a href="auth-lock-screen.html">
                                            <i class="feather icon-lock"></i> Active Associate
                                        </a>
                                    </li>

                                    <li>
                                        <a href="auth-lock-screen.html">
                                            <i class="feather icon-lock"></i> Associate Collection Limit
                                        </a>
                                    </li>



                                    
                                </ul>
                            </div>
                        </li>

                        <li class="header-notification">
                            <div class="dropdown-primary dropdown">
                                <div class="displayChatbox dropdown-toggle" data-toggle="dropdown">
                                    Support ?
                                </div>
                            </div>
                        </li>







                        <li class="header-notification">
                            <div class="dropdown-primary dropdown">
                                <div class="dropdown-toggle" data-toggle="dropdown">
                                    <i class="feather icon-bell"></i>
                                    <span class="badge bg-c-pink">5</span>
                                </div>
                                <ul class="show-notification notification-view dropdown-menu" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                                    <li>
                                        <h6>Notifications</h6>
                                        <label class="label label-danger">New</label>
                                    </li>
                                    <li>
                                        <div class="media">
                                            <img class="d-flex align-self-center img-radius" src="../files/assets/images/avatar-4.jpg" alt="Generic placeholder" />
                                            <div class="media-body">
                                                <h5 class="notification-user">John Doe</h5>
                                                <p class="notification-msg">Lorem ipsum dolor sit amet, consectetuer
                                                elit.</p>
                                                <span class="notification-time">30 minutes ago</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="media">
                                            <img class="d-flex align-self-center img-radius" src="../files/assets/images/avatar-3.jpg" alt="Generic placeholder" />
                                            <div class="media-body">
                                                <h5 class="notification-user">Joseph William</h5>
                                                <p class="notification-msg">Lorem ipsum dolor sit amet, consectetuer
                                                elit.</p>
                                                <span class="notification-time">30 minutes ago</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="media">
                                            <img class="d-flex align-self-center img-radius" src="../files/assets/images/avatar-4.jpg" alt="Generic placeholder" />
                                            <div class="media-body">
                                                <h5 class="notification-user">Sara Soudein</h5>
                                                <p class="notification-msg">Lorem ipsum dolor sit amet, consectetuer
                                                elit.</p>
                                                <span class="notification-time">30 minutes ago</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="header-notification">
                            <div class="dropdown-primary dropdown">
                                <div class="displayChatbox dropdown-toggle" data-toggle="dropdown">
                                    <i class="feather icon-message-square"></i>
                                    <span class="badge bg-c-green">3</span>
                                </div>
                            </div>
                        </li>
                        <li class="user-profile header-notification">
                            <div class="dropdown-primary dropdown">
                                <div class="dropdown-toggle" data-toggle="dropdown">
                                    <img src="../files/assets/images/avatar-4.jpg" class="img-radius" alt="User-Profile" />
                                    <span>John Doe</span>
                                    <i class="feather icon-chevron-down"></i>
                                </div>
                                <ul class="show-notification profile-notification dropdown-menu" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                                    <li>
                                        <a href="#blank">
                                            <i class="feather icon-settings"></i> Settings
                                        </a>
                                    </li>
                                    <li>
                                        <a href="user-profile.html">
                                            <i class="feather icon-user"></i> Profile
                                        </a>
                                    </li>
                                    <li>
                                        <a href="email-inbox.html">
                                            <i class="feather icon-mail"></i> My Messages
                                        </a>
                                    </li>
                                    <li>
                                        <a href="auth-lock-screen.html">
                                            <i class="feather icon-lock"></i> Lock Screen
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#!" onClick={logout} >
                                            <i class="feather icon-log-out"></i> Logout
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

        <div id="sidebar" class="users p-chat-user showChat">
            <div class="had-container">
                <div class="card card_main p-fixed users-main">
                    <div class="user-box">
                        <div class="chat-inner-header">
                            <div class="back_chatBox">
                                <div class="right-icon-control">
                                    <input type="text" class="form-control  search-text" placeholder="Search Friend" id="search-friends" />
                                    <div class="form-icon">
                                        <i class="icofont icofont-search"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="main-friend-list">
                            <div class="media userlist-box" data-id="1" data-status="online" data-username="Josephin Doe" data-toggle="tooltip" data-placement="left" title="Josephin Doe">
                                <a class="media-left" href="#blank">
                                    <img class="media-object img-radius img-radius" src="../files/assets/images/avatar-3.jpg" alt="Generic placeholder " />
                                    <div class="live-status bg-success"></div>
                                </a>
                                <div class="media-body">
                                    <div class="f-13 chat-header">Josephin Doe</div>
                                </div>
                            </div>
                            <div class="media userlist-box" data-id="2" data-status="online" data-username="Lary Doe" data-toggle="tooltip" data-placement="left" title="Lary Doe">
                                <a class="media-left" href="#blank">
                                    <img class="media-object img-radius" src="../files/assets/images/avatar-2.jpg" alt="Generic placeholder" />
                                    <div class="live-status bg-success"></div>
                                </a>
                                <div class="media-body">
                                    <div class="f-13 chat-header">Lary Doe</div>
                                </div>
                            </div>
                            <div class="media userlist-box" data-id="3" data-status="online" data-username="Alice" data-toggle="tooltip" data-placement="left" title="Alice">
                                <a class="media-left" href="#blank">
                                    <img class="media-object img-radius" src="../files/assets/images/avatar-4.jpg" alt="Generic placeholder" />
                                    <div class="live-status bg-success"></div>
                                </a>
                                <div class="media-body">
                                    <div class="f-13 chat-header">Alice</div>
                                </div>
                            </div>
                            <div class="media userlist-box" data-id="4" data-status="online" data-username="Alia" data-toggle="tooltip" data-placement="left" title="Alia">
                                <a class="media-left" href="#blank">
                                    <img class="media-object img-radius" src="../files/assets/images/avatar-3.jpg" alt="Generic placeholder" />
                                    <div class="live-status bg-success"></div>
                                </a>
                                <div class="media-body">
                                    <div class="f-13 chat-header">Alia</div>
                                </div>
                            </div>
                            <div class="media userlist-box" data-id="5" data-status="online" data-username="Suzen" data-toggle="tooltip" data-placement="left" title="Suzen">
                                <a class="media-left" href="#blank">
                                    <img class="media-object img-radius" src="../files/assets/images/avatar-2.jpg" alt="Generic placeholder" />
                                    <div class="live-status bg-success"></div>
                                </a>
                                <div class="media-body">
                                    <div class="f-13 chat-header">Suzen</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="showChat_inner">
            <div class="media chat-inner-header">
                <a href="#blank" class="back_chatBox">
                    <i class="feather icon-chevron-left"></i> Josephin Doe
                </a>
            </div>
            <div class="media chat-messages">
                <a class="media-left photo-table" href="#blank">
                    <img class="media-object img-radius img-radius m-t-5" src="../files/assets/images/avatar-3.jpg" alt="Generic placeholder" />
                </a>
                <div class="media-body chat-menu-content">
                    <div class="">
                        <p class="chat-cont">I'm just looking around. Will you tell me something about yourself?</p>
                        <p class="chat-time">8:20 a.m.</p>
                    </div>
                </div>
            </div>
            <div class="media chat-messages">
                <div class="media-body chat-menu-reply">
                    <div class="">
                        <p class="chat-cont">I'm just looking around. Will you tell me something about yourself?</p>
                        <p class="chat-time">8:20 a.m.</p>
                    </div>
                </div>
                <div class="media-right photo-table">
                    <a href="#blank">
                        <img class="media-object img-radius img-radius m-t-5" src="../files/assets/images/avatar-4.jpg" alt="Generic placeholder" />
                    </a>
                </div>
            </div>
            <div class="chat-reply-box p-b-20">
                <div class="right-icon-control">
                    <input type="text" class="form-control search-text" placeholder="Share Your Thoughts" />
                    <div class="form-icon">
                        <i class="feather icon-navigation"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="pcoded-main-container">
            <div class="pcoded-wrapper">
                <nav class="pcoded-navbar">
                    <div class="pcoded-inner-navbar main-menu">
                        <div class="pcoded-navigatio-lavel">Navigation</div>
                        <ul class="pcoded-item pcoded-left-item">
                            
                            <li  class={activeSideBar === 'dashboard' ? 'active' : ''}>
                                <a href="/dashboard">
                                    <span class="pcoded-micon"><i class="feather icon-home"></i></span>
                                    <span class="pcoded-mtext">Dashboard</span>
                                </a>
                            </li>


                            {/* <li class={`pcoded-hasmenu ${formTab.includes(activeSideBar) ? 'pcoded-trigger' : ''}`} >
                                <a href="#blank">
                                    <span class="pcoded-micon"><i class="feather icon-sidebar"></i></span>
                                    <span class="pcoded-mtext">Page layouts</span>
                                </a>
                                <ul class="pcoded-submenu">
                                    
                                    <li class={activeSideBar === '/form' ? 'active' : ''}>
                                        <a href="/form">
                                            <span class="pcoded-mtext">Form</span>
                                        </a>
                                    </li>
                                    <li class={activeSideBar === '/list' ? 'active' : ''}>
                                        <a href="/list" >
                                            <span class="pcoded-mtext">List</span>
                                        </a>
                                    </li>
                                </ul>
                            </li> */}
                        

                            <li class={`pcoded-hasmenu ${companyTab.includes(activeSideBar) ? 'pcoded-trigger' : ''}`} >
                                <a href="#blank">
                                    <span class="pcoded-micon"><i class="feather icon-box"></i></span>
                                    <span class="pcoded-mtext">Company</span>
                                </a>
                                <ul class="pcoded-submenu">
                                    
                                <li class={(activeSideBar === 'company-details' || activeSideBar === 'edit-company-details') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">COMPANY</span>
                                    </a>
                                </li>

                                <li class={(activeSideBar === 'branches-list' || activeSideBar === 'add-new-branch' || activeSideBar === 'edit-branch'  || activeSideBar === 'view-branch') ? 'active' : ''}>
                                    <a href="/branches-list">
                                    <span class="pcoded-mtext">BRANCHES</span>
                                    </a>
                                </li>

                                <li class={(activeSideBar === 'promoter-list' || activeSideBar === 'add-new-promoter' || activeSideBar === 'edit-promoter' || activeSideBar === 'view-promoter' || activeSideBar === 'change-password-promoter' || activeSideBar === 'add-new-minor' || activeSideBar === 'edit-minor' || activeSideBar === 'view-minor' || activeSideBar === 'edit-bank-details') ? 'active' : ''}>
                                    <a href="/promoter-list">
                                    <span class="pcoded-mtext">PROMOTERS</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'share-holdings' || activeSideBar === 'add-share-holdings'  || activeSideBar === 'edit-share-holdings' || activeSideBar === 'view-share-holdings') ? 'active' : ''}>
                                    <a href="/share-holdings">
                                    <span class="pcoded-mtext">PROMOTERS SHARE HOLDINGS</span>
                                    </a>
                                </li>
                                
                                <li class={(activeSideBar === 'director' || activeSideBar === 'add-director' || activeSideBar === 'edit-director' || activeSideBar === 'view-director') ? 'active' : ''}>
                                    <a href="/director">
                                    <span class="pcoded-mtext">DIRECTORS</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'unencumbered-deposits' || activeSideBar === 'add-unencumbered-deposits' || activeSideBar === 'edit-unencumbered-deposits' || activeSideBar === 'view-unencumbered-deposits') ? 'active' : ''}>
                                    <a href="/unencumbered-deposits">
                                    <span class="pcoded-mtext">UNENCUMBERED DEPOSITS</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar ===  'bank-accounts' || activeSideBar ===  'add-bank-account' || activeSideBar ===  'edit-bank-account' || activeSideBar ===  'view-bank-account') ? 'active' : ''}>
                                    <a href="/bank-accounts">
                                    <span class="pcoded-mtext">BANK ACCOUNTS</span>
                                    </a>
                                </li>
                                </ul>
                            </li>

                     



                        <li class={`pcoded-hasmenu ${userTab.includes(activeSideBar) ? 'pcoded-trigger' : ''}`} >
                                <a href="#blank">
                                    <span class="pcoded-micon"><i class="feather icon-users"></i></span>
                                    <span class="pcoded-mtext">User Management</span>
                                </a>
                                <ul class="pcoded-submenu">

                                <li class={(activeSideBar === 'user-management-role' || activeSideBar === 'add-user-management-role' || activeSideBar === 'edit-user-management-role' || activeSideBar === 'view-user-management-role') ? 'active' : ''}>
                                    <a href="/user-management-role">
                                    <span class="pcoded-mtext">PERMISSIONS/ ROLES</span>
                                    </a>
                                </li>
                               

                                <li class={(activeSideBar ===  'users-management' || activeSideBar ===  'add-user' || activeSideBar ===  'edit-user' || activeSideBar ===  'view-user'  || activeSideBar ===  'reset-user-password') ? 'active' : ''}>
                                    <a href="/users-management">
                                    <span class="pcoded-mtext">USERS</span>
                                    </a>
                                </li>
                                </ul>
                            </li>

                     
                            <li class={`pcoded-hasmenu ${collCenterTab.includes(activeSideBar) ? 'pcoded-trigger' : ''}`} >
                                <a href="#blank">
                                    <span class="pcoded-micon"><i class="feather icon-align-left"></i></span>
                                    <span class="pcoded-mtext">Coll. Center / Groups</span>
                                </a>
                                <ul class="pcoded-submenu">

                                <li class={(activeSideBar === 'collection-centers' || activeSideBar === 'collection-centers-new' || activeSideBar === 'collection-centers-edit' || activeSideBar === 'collection-centers-view') ? 'active' : ''}>
                                    <a href="/collection-centers">
                                    <span class="pcoded-mtext">COLLECTION CENTERS</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'collection-centers-group' || activeSideBar === 'collection-centers-group-new' || activeSideBar === 'collection-centers-group-edit' || activeSideBar === 'collection-centers-group-view' || activeSideBar === 'collection-centers-group-add-comment') ? 'active' : ''}>
                                    <a href="/collection-centers-group">
                                    <span class="pcoded-mtext">GROUPS</span>
                                    </a>
                                </li>
                                </ul>
                            </li>

                            <li class={`pcoded-hasmenu ${memberTab.includes(activeSideBar) ? 'pcoded-trigger' : ''}`} >
                                <a href="#blank">
                                    <span class="pcoded-micon"><i class="feather icon-users"></i></span>
                                    <span class="pcoded-mtext">Member Management </span>
                                </a>
                                <ul class="pcoded-submenu">

                                <li class={(activeSideBar === 'members-management' || activeSideBar === 'add-member' || activeSideBar === 'edit-member' || activeSideBar === 'view-member' || activeSideBar === 'member-change-password' || activeSideBar === 'member-change-email-phone' || activeSideBar === 'member-add-new-minor' ||  activeSideBar === 'member-edit-bankdetails' || activeSideBar === 'member-edit-address' || activeSideBar === 'member-update-document' || activeSideBar === 'member-add-comment' || activeSideBar === 'member-add-shares' || activeSideBar === 'member-shares-amount' || activeSideBar === 'member-transaction' || activeSideBar === 'member-ship-charge' || activeSideBar === 'member-upload-15g15h' || activeSideBar === 'member-status-inactive') ? 'active' : ''}>
                                    <a href="/members-management">
                                    <span class="pcoded-mtext">MEMBERS</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'member-edit-minor' || activeSideBar === 'member-minors' || activeSideBar === 'member-view-minor') ? 'active' : ''}>
                                    <a href="/member-minors">
                                    <span class="pcoded-mtext">Minors</span>
                                    </a>
                                </li>
                                
                                <li class={(activeSideBar ===  'member-share-holdings' || activeSideBar ===  'member-add-holdings' || activeSideBar ===  'member-edit-holdings' || activeSideBar ===  'member-view-holdings') ? 'active' : ''}>
                                    <a href="/member-share-holdings">
                                    <span class="pcoded-mtext">SHARE Holding</span>
                                    </a>
                                </li>

                                <li class={(activeSideBar === 'member-share-certificate') ? 'active' : ''}>
                                    <a href="/member-share-certificate">
                                    <span class="pcoded-mtext">SHARE CERTIFICATES</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'member-share-transfer-history') ? 'active' : ''}>
                                    <a href="/member-share-transfer-history">
                                    <span class="pcoded-mtext">SHARE TRANSFER HISTORY</span>
                                    </a>
                                </li>

                                <li class={(activeSideBar === 'member-form-15-gh' || activeSideBar === 'member-view-15-gh') ? 'active' : ''}>
                                    <a href="/member-form-15-gh">
                                    <span class="pcoded-mtext">FORM 15G/ 15H</span>
                                    </a>
                                </li>


                                </ul>
                            </li>

                            <li class={`pcoded-hasmenu ${accountTab.includes(activeSideBar) ? 'pcoded-trigger' : ''}`} >
                                <a href="#blank">
                                    <span class="pcoded-micon"><i class="feather icon-clipboard"></i></span>
                                    <span class="pcoded-mtext">Saving Accounts</span>
                                </a>
                                <ul class="pcoded-submenu">

                                <li class={(activeSideBar === 'account-scheem-list' || activeSideBar === 'account-scheem-add' || activeSideBar === 'account-scheem-edit' || activeSideBar === 'account-scheem-view') ? 'active' : ''}>
                                    <a href="/account-scheem-list">
                                    <span class="pcoded-mtext">Schemes</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'account-list' || activeSideBar === 'account-open-new' || activeSideBar === 'account-edit' || activeSideBar === 'account-view' || activeSideBar === 'account-upload-document' || activeSideBar === 'account-add-comment' || activeSideBar === 'account-transaction' || activeSideBar === 'account-deposite-mony' || activeSideBar === 'account-mony-debit' || activeSideBar === 'account-other-charge' || activeSideBar === 'account-other-charge-pay' || activeSideBar === 'account-transaction-view' || activeSideBar === 'account-other-charge-clear-due' || activeSideBar === 'account-credit-interest' || activeSideBar === 'account-change-account-type' || activeSideBar === 'account-change-nominee' || activeSideBar === 'account-upgrade') ? 'active' : ''}>
                                    <a href="/account-list">
                                    <span class="pcoded-mtext">Accounts</span>
                                    </a>
                                </li>

                                </ul>
                            </li>

                            <li class={`pcoded-hasmenu ${fdmisTab.includes(activeSideBar) ? 'pcoded-trigger' : ''}`} >
                                <a href="#blank">
                                    <span class="pcoded-micon"><i class="feather icon-anchor"></i></span>
                                    <span class="pcoded-mtext">FD/ MIS, MDS/ RD/ DDS Accounts</span>
                                </a>
                                <ul class="pcoded-submenu">

                                <li class={(activeSideBar === 'fixed-deposit-schemes' || activeSideBar === 'fixed-deposit-schemes-add' || activeSideBar === 'fixed-deposit-schemes-view' || activeSideBar === 'fixed-deposit-schemes-edit') ? 'active' : ''}>
                                    <a href="/fixed-deposit-schemes">
                                    <span class="pcoded-mtext">Schemes</span>
                                    </a>
                                </li>

                                <li class={(activeSideBar === 'fixed-deposit-calculator') ? 'active' : ''}>
                                    <a href="/fixed-deposit-calculator">
                                    <span class="pcoded-mtext">Calculator</span>
                                    </a>
                                </li>

                                <li class={(activeSideBar === 'fixed-deposit-account' || activeSideBar === 'fixed-deposit-account-new' || activeSideBar === 'fixed-deposit-account-view' || activeSideBar === 'fixed-deposit-account-payouts' || activeSideBar === 'fixed-deposit-change-account-info' || activeSideBar === 'fixed-deposit-add-nominee' || activeSideBar === 'fixed-deposit-fore-close' || activeSideBar === 'fixed-deposit-account-remove' || activeSideBar === 'fixed-deposit-link-saving-account' || activeSideBar === 'fixed-deposit-link-update-interest' || activeSideBar === 'fixed-deposit-link-debit-interest') ? 'active' : ''}>
                                    <a href="/fixed-deposit-account">
                                    <span class="pcoded-mtext">FD Accounts</span>
                                    </a>
                                </li>

                                <li class={(activeSideBar === 'fixed-deposit-mis-account' || activeSideBar === 'fixed-deposit-mis-account-new' || activeSideBar === 'fixed-deposit-mis-account-view') ? 'active' : ''}>
                                    <a href="/fixed-deposit-mis-account">
                                    <span class="pcoded-mtext">MIS Accounts</span>
                                    </a>
                                </li>

                                <li class={(activeSideBar === 'recurring-deposit-account' || activeSideBar === 'recurring-deposit-account-new' || activeSideBar === 'recurring-deposit-account-view' || activeSideBar === 'recurring-deposit-account-installments' || activeSideBar === 'recurring-deposit-account-add-minor' || activeSideBar === 'recurring-deposit-account-fore-close' || activeSideBar === 'recurring-deposit-account-other-charges' || activeSideBar === 'recurring-deposit-account-other-charges-pay' || activeSideBar === 'recurring-deposit-account-clear-due' || activeSideBar === 'recurring-deposit-account-link-saving-account' || activeSideBar === 'recurring-deposit-account-credit-interest' || activeSideBar === 'recurring-deposit-account-mony' || activeSideBar === 'recurring-deposit-account-debit') ? 'active' : ''}>
                                    <a href="/recurring-deposit-account">
                                    <span class="pcoded-mtext">MDS / RD Accounts</span>
                                    </a>
                                </li>

                                <li class={(activeSideBar === 'daily-deposit-accounts' || activeSideBar === 'daily-deposit-accounts-new' || activeSideBar === 'daily-deposit-accounts-other-charges-pay') ? 'active' : ''}>
                                    <a href="/daily-deposit-accounts">
                                    <span class="pcoded-mtext">DDS Accounts</span>
                                    </a>
                                </li>

                                </ul>
                            </li>

                            <li class={`pcoded-hasmenu ${userTab.includes(activeSideBar) ? 'pcoded-trigger' : ''}`} >
                                <a href="#blank">
                                    <span class="pcoded-micon"><i class="feather icon-map"></i></span>
                                    <span class="pcoded-mtext">Loans</span>
                                </a>
                                <ul class="pcoded-submenu">

                                <li class={(activeSideBar === 'company-details11' || activeSideBar === 'edit-company-details11') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">Schemes</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'company-details11' || activeSideBar === 'edit-company-details11') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">Calculator</span>
                                    </a>
                                </li>

                                <li class={(activeSideBar === 'company-details11' || activeSideBar === 'edit-company-details11') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">Application</span>
                                    </a>
                                </li>

                                <li class={(activeSideBar === 'company-details11' || activeSideBar === 'edit-company-details11') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">DISBURSEMENTS</span>
                                    </a>
                                </li>

                                <li class={(activeSideBar === 'company-details11' || activeSideBar === 'edit-company-details11') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">Accounts</span>
                                    </a>
                                </li>

                                <li class={(activeSideBar === 'company-details11' || activeSideBar === 'edit-company-details11') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">Ornaments Inventory</span>
                                    </a>
                                </li>

                                </ul>
                            </li>

                            <li class={`pcoded-hasmenu ${approvalTab.includes(activeSideBar) ? 'pcoded-trigger' : ''}`} >
                                <a href="#blank">
                                    <span class="pcoded-micon"><i class="feather icon-users"></i></span>
                                    <span class="pcoded-mtext">Approvels</span>
                                </a>
                                <ul class="pcoded-submenu">

                                <li class={(activeSideBar === 'approvals-accounting-vouchers' || activeSideBar === 'approvals-accounting-vouchers-history') ? 'active' : ''}>
                                    <a href="/approvals-accounting-vouchers">
                                    <span class="pcoded-mtext">ACCOUNTING VOUCHERS</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'approvals-loans' || activeSideBar === 'approvals-loans-history') ? 'active' : ''}>
                                    <a href="/approvals-loans">
                                    <span class="pcoded-mtext">LOAN APPLICATIONS</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'approvals-accounts' || activeSideBar === 'approvals-accounts-history') ? 'active' : ''}>
                                    <a href="/approvals-accounts">
                                    <span class="pcoded-mtext">SAVING/FD/ RD ACCOUNTS</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'approvals-transaction' || activeSideBar === 'approvals-transaction-history') ? 'active' : ''}>
                                    <a href="/approvals-transaction">
                                    <span class="pcoded-mtext">PENDING TRANSACTIONS</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'approvals-reverse-transactions') ? 'active' : ''}>
                                    <a href="/approvals-reverse-transactions">
                                    <span class="pcoded-mtext">REVERSED TRANSACTIONS</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'approvals-non-compliances') ? 'active' : ''}>
                                    <a href="/approvals-non-compliances">
                                    <span class="pcoded-mtext">NON COMPLIANCES</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'approvals-loan-closures' || activeSideBar === 'approvals-loan-closures-history' ) ? 'active' : ''}>
                                    <a href="/approvals-loan-closures">
                                    <span class="pcoded-mtext">LOAN CLOSURE REQUESTS</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'approvals-deposit-closures') ? 'active' : ''}>
                                    <a href="/approvals-deposit-closures">
                                    <span class="pcoded-mtext">FD/RD CLOSURE REQUESTS</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'approvals-share-transfer' || activeSideBar === 'approvals-share-transfer-history') ? 'active' : ''}>
                                    <a href="/approvals-share-transfer">
                                    <span class="pcoded-mtext">SHARE TRANSFER/ALLOCATION</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'approvals-share-surrender' || activeSideBar === 'approvals-share-surrender-history') ? 'active' : ''}>
                                    <a href="/approvals-share-surrender">
                                    <span class="pcoded-mtext">SHARE SURRENDER</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'approvals-within-bank-transfer' || activeSideBar === 'approvals-within-bank-transfer-history') ? 'active' : ''}>
                                    <a href="/approvals-within-bank-transfer">
                                    <span class="pcoded-mtext">WITHIN BANK TRANSFER</span>
                                    </a>
                                </li>

                                </ul>
                            </li>



                            <li  class={activeSideBar === 'dashboard' ? 'active' : ''}>
                                <a href="/dashboard">
                                    <span class="pcoded-micon"><i class="feather icon-inbox"></i></span>
                                    <span class="pcoded-mtext">Payment To Collect</span>
                                </a>
                            </li>

                            <li  class={activeSideBar === 'dashboard' ? 'active' : ''}>
                                <a href="/dashboard">
                                    <span class="pcoded-micon"><i class="feather icon-inbox"></i></span>
                                    <span class="pcoded-mtext">Payment To Release</span>
                                </a>
                            </li>

                            <li  class={(activeSideBar === 'passbook-list' || activeSideBar === 'passbook-add' || activeSideBar === 'passbook-add' || activeSideBar === 'passbook-view' || activeSideBar === 'passbook-edit') ? 'active' : ''}>
                                <a href="/passbook-list">
                                    <span class="pcoded-micon"><i class="feather icon-file-text"></i></span>
                                    <span class="pcoded-mtext">PasbBooks</span>
                                </a>
                            </li>



                            <li class={`pcoded-hasmenu ${userTab.includes(activeSideBar) ? 'pcoded-trigger' : ''}`} >
                                <a href="#blank">
                                    <span class="pcoded-micon"><i class="feather icon-printer"></i></span>
                                    <span class="pcoded-mtext">Print Documents</span>
                                </a>
                                <ul class="pcoded-submenu">

                                <li class={(activeSideBar === 'member-share-certificate11') ? 'active' : ''}>
                                    <a href="/member-share-certificate11">
                                    <span class="pcoded-mtext">SHARE CERTIFICATE</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'company-details11' || activeSideBar === 'edit-company-details11') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">SAVING PASSBOOK</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'company-details11' || activeSideBar === 'edit-company-details11') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">SAVING PASSBOOK AUTO</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'company-details11' || activeSideBar === 'edit-company-details11') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">NEW</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'company-details11' || activeSideBar === 'edit-company-details11') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">FD/MIS BOND</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'company-details11' || activeSideBar === 'edit-company-details11') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">FD/MIS PASSBOOK</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'company-details11' || activeSideBar === 'edit-company-details11') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">FD/ MIS RECEIPT</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'company-details11' || activeSideBar === 'edit-company-details11') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">RD/DD BOND</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'company-details11' || activeSideBar === 'edit-company-details11') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">RD/ DD PASSBOOK</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'company-details11' || activeSideBar === 'edit-company-details11') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">RD/DD PASSBOOK AUTO</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'company-details11' || activeSideBar === 'edit-company-details11') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">NEW</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'company-details11' || activeSideBar === 'edit-company-details11') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">RD/DD RECEIPT</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'company-details11' || activeSideBar === 'edit-company-details11') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">DD RECEIPT</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'company-details11' || activeSideBar === 'edit-company-details11') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">LOAN PASSBOOK</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'company-details11' || activeSideBar === 'edit-company-details11') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">TRANSACTION RECEIPT</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'company-details11' || activeSideBar === 'edit-company-details11') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">SALARY SLIP</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'company-details11' || activeSideBar === 'edit-company-details11') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">NEW</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'company-details11' || activeSideBar === 'edit-company-details11') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">INTEREST CERTIFICATE</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'company-details11' || activeSideBar === 'edit-company-details11') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">LETTER HEAD</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'company-details11' || activeSideBar === 'edit-company-details11') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">COLLECTION REPORT</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'company-details11' || activeSideBar === 'edit-company-details11') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">PRINT REPORT</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'company-details11' || activeSideBar === 'edit-company-details11') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">PRINTER SETTINGS</span>
                                    </a>
                                </li>

                                </ul>
                            </li>



                            <li class={`pcoded-hasmenu ${associateTab.includes(activeSideBar) ? 'pcoded-trigger' : ''}`} >
                                <a href="#blank">
                                    <span class="pcoded-micon"><i class="feather icon-book"></i></span>
                                    <span class="pcoded-mtext">Associate/ Advisors</span>
                                </a>
                                <ul class="pcoded-submenu">

                                <li class={(activeSideBar === 'associate-advisor-agent-ranks' || activeSideBar === 'associate-advisor-agent-ranks-new' || activeSideBar === 'associate-advisor-agent-ranks-edit' || activeSideBar === 'associate-advisor-agent-ranks-view') ? 'active' : ''}>
                                    <a href="/associate-advisor-agent-ranks">
                                    <span class="pcoded-mtext">RANK STRUCTURE</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'associate-advisor-commission-charts' || activeSideBar === 'associate-advisor-commission-charts-new' || activeSideBar === 'associate-advisor-commission-charts-edit' || activeSideBar === 'associate-advisor-commission-charts-view') ? 'active' : ''}>
                                    <a href="/associate-advisor-commission-charts">
                                    <span class="pcoded-mtext">COMMISSION CHARTS</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'associate-advisor' || activeSideBar === 'associate-advisor-new' || activeSideBar === 'associate-advisor-edit' || activeSideBar === 'associate-advisor-view' || activeSideBar === 'associate-advisor-chnage-password' || activeSideBar === 'associate-advisor-link-saving-account') ? 'active' : ''}>
                                    <a href="/associate-advisor">
                                    <span class="pcoded-mtext">ASSOCIATES/ADVISORS</span>
                                    </a>
                                </li>
                                <li class={(activeSideBar === 'company-details11' || activeSideBar === 'edit-company-details11') ? 'active' : ''}>
                                    <a href="/company-details">
                                    <span class="pcoded-mtext">COMMISSION PAYOUTS</span>
                                    </a>
                                </li>

                                </ul>
                            </li>


                            </ul>
                        
                        

                        <div class="pcoded-navigatio-lavel">Support</div>
                        <ul class="pcoded-item pcoded-left-item">
                            <li class="">
                                <a href="http://html.codedthemes.com/Adminty/doc" >
                                    <span class="pcoded-micon"><i class="feather icon-monitor"></i></span>
                                    <span class="pcoded-mtext">Documentation</span>
                                </a>
                            </li>

                            <li class="">
                                <a href="#blnak" >
                                    <span class="pcoded-micon"><i class="feather icon-help-circle"></i></span>
                                    <span class="pcoded-mtext">Submit Issue</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>

           


        {/* page layout start */}
        { children }
        {/* page layout end*/}




                </div>
            </div>
        </div>
    </div>
    </>
  );
}

export default DashboardLayouts;
