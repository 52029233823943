import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { dataDecrypt,
    dataEncript,
    FirstLetterCapitalize,
    formatDate,
    deleteConfirmation
     } from "./../../../helper/commonHelpers";

function CompanyDetails() {
  // const navigate = useNavigate();
  const { accountID }   = useParams();
  const [promoterhDetails, setpromoterhDetails] = useState([]);
  const [transaction_list, settransaction_list] = useState([]);


  const[member_no, setmember_no] = useState('');
  const[first_name, setfirst_name] = useState('');
  const[last_name, setlast_name] = useState('');
  const[share_no, setshare_no] = useState('');
  const[certificate_no, setcertificate_no] = useState('');
  const [submitting, setSubmitting] = useState(false);

  // PROMOTER INFO 

  useEffect(() => {

  // get bran ch details
 
axios.get(API_BASE_URL+'api-common/get-account-list?id='+dataDecrypt(accountID), {
    headers: API_HEADERS
}).then((response) => {

    if (response.data["status"] > 0) {
        setpromoterhDetails(response.data['data']);
        console.log(response.data['data']);

        if (response.data["data"].transaction_list && (response.data["data"].transaction_list).length > 0) {
            settransaction_list(response.data["data"].transaction_list);
        }
    }




}).catch((error) => {
  toast.error('Failed to fetch DETAILS list!');
});



 

    //    setenrollment_date(getCurrentDate());
  }, [accountID]);


  const deleteTransaction = async (tblid) => {
        const confirmed = await deleteConfirmation('Are you sure to delete transaction?');
        if(confirmed===true)
        {
            const formData = {
                tablename:'tbl_transaction',  // table name
                coloum_name:'id', // which collumn name you want to  
                value:tblid, // column value 
             };

             axios.post(API_BASE_URL + "api-common/detete", formData, {
                headers: {
                    ...API_HEADERS,
                },
                })
                .then((response) => {
                if (response.data["status"] > 0) {
                    toast.success(response.data["message"]);
                    setTimeout(() => {
                         window.location.reload();
                    }, 2700);
                } else {
                    toast.error(response.data["message"]);
                }
                })
                .catch((error) => {
                   toast.error("Failed to submition!");
                }); // close query hare
        }
  };



  const reGenerateLedger = async (e) => {
    e.preventDefault();
        const confirmed = await deleteConfirmation('to re-generate the balance in following ledger');
        if(confirmed===true)
        {
            toast.success('Regenerate done successfully!');

              setTimeout(() => {
                    window.location.reload();
                    }, 2300);
        }
  };
  

  const filterData = (e) => {
    e.preventDefault();
    setSubmitting(true);

const formData = {};

if (member_no) {
    formData['member_no'] = member_no;
  }

  if (first_name) {
    formData['first_name'] = first_name;
  }

  if (last_name) {
    formData['last_name'] = last_name;
  }

  if (share_no) {
    formData['share_no'] = share_no;
  }

  if (certificate_no) {
    formData['certificate_no'] = certificate_no;
  }

  console.log(formData);

  if (Object.keys(formData).length === 0) {
    // Set formErrors state with validation errors
    

        axios.get(API_BASE_URL + 'api-member/get-shares-list', {
          headers: {
            ...API_HEADERS
          },
        }).then((response) => {
          if (response.data["status"] > 0) {
            settransaction_list(response.data['data']);
          } else {
            toast.error(response.data["message"]);
          }

        }).catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 300);
        }); // close query hare

    }
 
  };

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>{
                            promoterhDetails && (
                              promoterhDetails.account_for === 'fd' ? (
                                <> Fixed Deposit</>
                              ) : promoterhDetails.account_for === 'mis' ? (
                                < >MIS Account</>
                              ) : promoterhDetails.account_for === 'rd' ? (
                                <> RD Account</>
                              ) : promoterhDetails.account_for === 'dd' ? (
                                <> Daily Deposits</>
                              ) : <>Saving Account</>
                            )
                          } - {promoterhDetails && promoterhDetails.account_no && (
                                    <>{promoterhDetails.account_no}</>
                                    )} <span>Transactions</span> </h4>


                                    

                                    
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                            {
                                    promoterhDetails && (
                                      promoterhDetails.account_for === 'fd' ? (
                                        <a href="/fixed-deposit-account">Fd Accounts</a>
                                      ) : promoterhDetails.account_for === 'mis' ? (
                                        <a href="/fixed-deposit-mis-account">MIS Deposits</a>
                                      ): promoterhDetails.account_for === 'rd' ? (
                                        <a href="/recurring-deposit-account">RD Accounts</a>
                                      ): promoterhDetails.account_for === 'dd' ? (
                                        <a href="/recurring-deposit-account">Daily Deposits</a>
                                      ) :  <a href="/account-list">Accounts</a>
                                    )
                                  }
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                            {
                                  promoterhDetails && (
                                    promoterhDetails.account_for === 'fd' ? (
                                      <a href={`/fixed-deposit-account-view/${dataEncript(promoterhDetails.id)}`} > {promoterhDetails && promoterhDetails.account_no && ( <span>{promoterhDetails.account_no}</span> )}</a>
                                    ) : promoterhDetails.account_for === 'mis' ? (
                                      <a href={`/fixed-deposit-mis-account-view/${dataEncript(promoterhDetails.id)}`} > {promoterhDetails && promoterhDetails.account_no && ( <span>{promoterhDetails.account_no}</span> )}</a>
                                    ): promoterhDetails.account_for === 'rd' ? (
                                      <a href={`/recurring-deposit-account-view/${dataEncript(promoterhDetails.id)}`} > {promoterhDetails && promoterhDetails.account_no && ( <span>{promoterhDetails.account_no}</span> )}</a>
                                    ) : promoterhDetails.account_for === 'dd' ? (
                                      <a href={`/recurring-deposit-account-view/${dataEncript(promoterhDetails.id)}`} > {promoterhDetails && promoterhDetails.account_no && ( <span>{promoterhDetails.account_no}</span> )}</a>
                                    ) :  <a href={`/account-view/${dataEncript(promoterhDetails.id)}`} > {promoterhDetails && promoterhDetails.account_no && ( <span>{promoterhDetails.account_no}</span> )}</a>
                                  )
                                }
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">Transactions</a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="col-md-12 mt-3">

                    {
                      promoterhDetails && (
                        promoterhDetails.account_for === 'fd' ? (
                          <> </>
                        ) : promoterhDetails.account_for === 'mis' ? (
                          <> </>
                       ) : promoterhDetails.account_for === 'rd' ? (
                           <>   </>
                        )  : promoterhDetails.account_for === 'dd' ? (
                          <>   </>
                       ) :  <>
                        <a class="btn btn-success mt-10 "  href={`/account-deposite-mony/${accountID}`}>DEPOSITE MONY</a>
                         <a class="btn btn-danger mt-10 ml-1" href={`/account-mony-debit/${accountID}`} >WITHDRAW MONEY</a>
                       </>
                      )
                    }

                    
                    <a class="btn btn-warning mt-10 ml-1"  href="#!" onClick={(e) => {reGenerateLedger(e);}}>RE-GENERATE BALANCE LEDGER</a>
                  </div>

                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12"></div>

                  <div class="col-sm-12">

                  <div class="card">
                      <div class="card-block">
                      <form onSubmit={filterData}>
                        <div class="row">
                            <div class="col-sm-12 col-xl-4 m-b-30">
                                <div class="row">
                                  
                                  <div class="col-sm-12">
                                  <h6 class="">Tranx Id :</h6>
                                    <input
                                      class="form-control"
                                      type="text"
                                      value={member_no}
                                      onChange={(e) =>
                                        setmember_no(e.target.value)
                                      }
                                      placeholder="Search Tranx Id"
                                    />
                                  
                                  </div>
                                </div>
                              </div>
                              
                              <div class="col-sm-12 col-xl-4 m-b-30">
                                <div class="row">
                                
                                  <div class="col-sm-12">
                                  <h6 class="">Remarks :</h6>
                                    <input
                                      class="form-control"
                                      type="text"
                                      value={first_name}
                                      onChange={(e) =>
                                        setfirst_name(e.target.value)
                                      }
                                      placeholder="Search Remarks:"
                                    />
                                
                                  </div>
                                </div>
                              </div>

                              </div>

                          <div class="row">
                              <div class="col-sm-12 col-xl-3 m-b-30">
                                <div class="row">
                                <h6 class="">
                                Transaction Date Range From:
                                    </h6>
                                  <div class="col-sm-12">
                                    <input
                                      class="form-control"
                                      type="date"
                                      value={last_name}
                                      onChange={(e) =>
                                        setlast_name(e.target.value)
                                      }
                                      placeholder="Search Transaction Date Range from"
                                    />
                                
                                  </div>
                                </div>
                              </div>

                             
                            <div class="col-sm-12 col-xl-3 m-b-30">
                                <div class="row">
                                
                                  <div class="col-sm-12">
                                  <h6 class="">
                                  Transaction Date Range To:
                                    </h6>
                                    <input
                                      class="form-control"
                                      type="date"
                                      value={share_no}
                                      onChange={(e) =>
                                        setshare_no(e.target.value)
                                      }
                                      placeholder="Search Date Range To "
                                    />
                                
                                  </div>
                                </div>
                              </div>
                              
                              <div class="col-sm-12 col-xl-3 m-b-30">
                                <div class="row">
                              
                                  <div class="col-sm-12">
                                  <h6 class="">
                                  Amount Range From:
                                    </h6>
                                    <input
                                      class="form-control"
                                      type="text"
                                      value={certificate_no}
                                      onChange={(e) =>
                                        setcertificate_no(e.target.value)
                                      }
                                      placeholder="Search Amount Range From"
                                    />
                                  
                                  </div>
                                </div>
                              </div>

                              <div class="col-sm-12 col-xl-3 m-b-30">
                                <div class="row">
                              
                                  <div class="col-sm-12">
                                  <h6 class="">
                                  Amount Range To:
                                    </h6>
                                    <input
                                      class="form-control"
                                      type="text"
                                      value={certificate_no}
                                      onChange={(e) =>
                                        setcertificate_no(e.target.value)
                                      }
                                      placeholder="Search Amount Range To"
                                    />
                                  
                                  </div>
                                </div>
                              </div>

                              </div>

                              <div class="row">
                                <div class="col-sm-12 col-xl-12 m-b-30 text-center">

                                <button class="btn btn-success btn-square" type="submit" disabled={submitting} >
                                <i class="fa fa-search"></i>   Search 
                                </button>{" "}
                                &nbsp;
                              
                                <button class="btn btn-danger btn-square" type="reset" > Clear Form </button>
                                <ToastContainer className="toast-container" />

                                </div>
                              </div>

                              </form>
                      </div>
                    </div>


                    <div class="card mt-1">
                      <div class="card-header">
                        {/* <h5>Edit Promoter</h5> */}
                        {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                      </div>

                      <div class="card-block">


                      <div class="table-responsive dt-responsive">
                        <table class="table table-striped table-bordered nowrap">
                            <thead>
                                <tr>
                                    <th>Sr</th>
                                    <th>T. DATE</th>
                                    <th>PAY MODE</th>
                                    <th>REMARKS</th>
                                    <th>STATUS</th>
                                    <th>DEBIT</th>
                                    <th>CREDIT</th>
                                    <th>BALANCE</th>
                                    <th>ACCOUNTED</th>
                                    <th>ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>


                            {
                            transaction_list.length > 0 ? (
                                transaction_list.map((appointData, index) => (
                                <tr class="gradeX">
                                <td>{index + 1}</td>


                                <td> {appointData && appointData.transaction_date && (
                                    <span>{ appointData.transaction_date?formatDate(appointData.transaction_date):''}</span>
                                    )}</td>

                                  <td> {appointData && appointData.pay_mode && (
                                    <span>{FirstLetterCapitalize(appointData.pay_mode)}</span>
                                    )}</td>

                                    <td> {appointData && appointData.remark && (
                                      <span>{FirstLetterCapitalize(appointData.remark)}</span>
                                      )}</td>

                                   <td>
                                            {
                                              appointData && (
                                                appointData.status === 'pending' ? (
                                                  <label className="label label-warning">Pending</label>
                                                ) : appointData.status === 'approve' ? (
                                                  <label className="label label-success">Approve</label>
                                                ) : null
                                              )
                                            }
                                      </td>



                                   <td>  {
                                              appointData && (
                                                appointData.transaction_type === 'debit' ? (
                                                  <>{appointData.deposit_amt}</>
                                                ) : null
                                              )
                                            }</td>

                                     <td>  {
                                              appointData && (
                                                appointData.transaction_type === 'credit' ? (
                                                  <>{appointData.deposit_amt}</>
                                                ) : null
                                              )
                                            }</td>

                                       <td>  {
                                              appointData && (
                                                appointData.transaction_type === 'credit' ? (
                                                  <>{appointData.deposit_amt}</>
                                                ) : null
                                              )
                                            }</td>

                                  

                                    <td>  {
                                              appointData && (
                                                appointData.credited_in_account === 'no' ? (
                                                  <label className="label label-danger">No</label>
                                                ) : appointData.credited_in_account === 'yes' ? (
                                                  <label className="label label-success">Yes</label>
                                                ) : null
                                              )
                                            }
                                     </td>

                                <td>

                                          <a href={`/account-transaction-view/${accountID}/${dataEncript(appointData.id)}`} class='font-weight-normal label label-success'><i class="fa fa-eye"></i></a>&nbsp;
                                          <a href="#!"  class='font-weight-normal label label-info'> <i class="fa fa-print"></i> </a>&nbsp;
                                         
                                          {
                                              appointData && (
                                                appointData.transaction_type === 'credit' ? (
                                                  <a href="#!"   onClick={() => deleteTransaction(appointData.id)}   class='font-weight-normal label label-danger'> <i class="fa fa-trash"></i> </a>
                                                ) : null
                                              )
                                            }

                                          
                                </td>

                                
                            </tr>
                            ))
                            ) : (
                            <tr>
                                <td colSpan="10">No data available</td>
                            </tr>
                            )
                        }
                              
                            </tbody>
                        </table>
                        </div>
                        <ToastContainer className="toast-container" />
                      </div>
                    </div>
                  </div>


                  



                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
