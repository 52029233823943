import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly

import { useParams } from 'react-router-dom'; 

  import { toast, ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

  import {
    dataDecrypt,
    dataEncript,
    formatDate
  } from './../../../helper/commonHelpers'; 


function CompanyDetails() {
    // const navigate = useNavigate();
    const { promoterID, minorID }   = useParams();

    const [promoterhDetails, setpromoterhDetails] = useState([]);
    const [minorDetails, setminorDetails] = useState([]);


    useEffect(() => {
        axios
        .get(API_BASE_URL + "api-company/get-promoter-by-id?promoter_id="+dataDecrypt(promoterID), {
          headers: API_HEADERS,
        })
        .then((response) => {
          setpromoterhDetails(response.data['data']);
        })
        .catch((error) => {
          toast.error('Failed to fetch DETAILS list!');
        });


        let minorCondition = `id = ${dataDecrypt(minorID)}`;
        axios.get(API_BASE_URL + "api-common/get-data?tablename=tbl_minor&condition="+minorCondition+"&id="+dataDecrypt(minorID), {
          headers: API_HEADERS,
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            setminorDetails(response.data['data']);
          } else {
              toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to fetch MINOR DATA!");
        });

      }, [ promoterID, minorID]);




  return (
    <DashboardLayout>




     <div class="pcoded-content">
    <div class="pcoded-inner-content">
        <div class="main-body">
            <div class="page-wrapper">
                <div class="page-header">
                    <div class="row align-items-end">
                        <div class="col-lg-8">
                            <div class="page-header-title">
                                <div class="d-inline">
                                    <h4>Minor - {minorDetails && minorDetails.fname && (
                                    <>{minorDetails.fname}</>
                                    )}

                                    {minorDetails && minorDetails.lname && (
                                    <>{minorDetails.lname}</>
                                    )}

                                    &nbsp;

                                    
                                    <small>{promoterhDetails && promoterhDetails.fname && (
                                    <span>{promoterhDetails.fname}</span>
                                    )}

                                    {promoterhDetails && promoterhDetails.mname && (
                                    <span>{promoterhDetails.mname}</span>
                                    )}</small></h4>
                                    {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}

                                   



                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="page-header-breadcrumb">
                                <ul class="breadcrumb-title">
                                <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/promoter-list">Promoter List</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href={`/view-promoter/${dataEncript(promoterhDetails.id)}`} >{promoterhDetails && promoterhDetails.fname && (
                                    <span>{promoterhDetails.fname}</span>
                                    )}

                                    {promoterhDetails && promoterhDetails.mname && (
                                    <span>{promoterhDetails.mname}</span>
                                    )}</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">Minors</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> {minorDetails && minorDetails.fname && (
                                    <>{minorDetails.fname}</>
                                    )}

                                    {minorDetails && minorDetails.lname && (
                                    <>{minorDetails.lname}</>
                                    )}</a>
                        </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="page-body">
                    <div class="row">

                    <div class="col-sm-12">
                    

                                    </div>


                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-header">
                                    <h5>

                        

                                    </h5>

                                    <a
                          style={{ float: "right" }}
                          class="badge badge-info"
                          href={`/edit-minor/${dataEncript(promoterhDetails.id)}/${dataEncript(minorDetails.id)}`}
                          title="Edit"
                        >
                           <i class="feather icon-edit"></i>
                        </a>
                                    {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                                </div>

                                <div class="card-block">
                                <form > 
                                 <div class="row">
                                    <div class="col-sm-12 col-xl-12 m-b-30">
                                           
                                    <table class="table table-striped table-bordered nowrap">
                                        <tbody>
                                            <tr>
                                                <th>Enrollment Date</th>
                                                <td>{minorDetails && minorDetails.enrollment_date && (
                                                <span>{formatDate(minorDetails.enrollment_date)}</span>
                                                )}</td>
                                            </tr>

                                            <tr>
                                                <th>Father's Name</th>
                                                <td>{minorDetails && minorDetails.father_name && (
                                                <span>{minorDetails.father_name}</span>
                                                )}</td>
                                            </tr>

                                            <tr>
                                                <th>Name</th>
                                                <td>{minorDetails && minorDetails.fname && (
                                                <span>{minorDetails.fname}</span>
                                                )} &nbsp; 
                                                    {minorDetails && minorDetails.lname && (
                                                <span>{minorDetails.lname}</span>
                                                )}
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>Date Of Birth</th>
                                                <td>{minorDetails && minorDetails.dob && (
                                                <span>{formatDate(minorDetails.dob)}</span>
                                                )}</td>
                                            </tr>

                                            <tr>
                                                <th>Gender</th>
                                                <td>{minorDetails && minorDetails.gender && (
                                                <span>{minorDetails.gender}</span>
                                                )}</td>
                                            </tr>

                                            <tr>
                                                <th>Aadhar No</th>
                                                <td>{minorDetails && minorDetails.aadhar_no && (
                                                <span>{minorDetails.aadhar_no}</span>
                                                )}</td>
                                            </tr>

                                            <tr>
                                                <th>Address</th>
                                                <td>{minorDetails && minorDetails.address && (
                                                <span>{minorDetails.address}</span>
                                                )}</td>
                                            </tr>

                                           







                                        </tbody>
                                    </table>


                                        </div>
                                    </div>



                                        

                                    

                                    

                                        

                                    <div class="row">
                                        <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                                          

                                            <ToastContainer className="toast-container" />
                                        </div>
                                        </div>



                                    </form>
                                </div>


                            </div>




                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="styleSelector"></div>
    </div>
</div>

    </DashboardLayout>
  );
}

export default CompanyDetails;
