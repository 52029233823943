import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly

import { useParams } from 'react-router-dom';

import $ from 'jquery';
import 'dropify/dist/css/dropify.min.css';
import 'dropify';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { formatDate, dataDecrypt } from "./../../../helper/commonHelpers";

function CompanyDetails() {
  const { bankaccountID }   = useParams();
  const [dataDetails, setdataDetails] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [bankList, setbankList] = useState([]);
  const [activeBranchList, setactiveBranchList] = useState([]);


  const [formErrors, setFormErrors] = useState({
    bank_name: "",
    account_no: "",
    account_type: "",
    account_open_date: "",
    ifsc_no: "",
    accounting_bank: "",
  });

  const[bank_name, setbank_name] = useState('');
  const[account_open_date, setaccount_open_date] = useState('');
  const[account_no, setaccount_no] = useState('');
  const[ifsc_no, setifsc_no] = useState('');
  const[account_type, setaccount_type] = useState('');
  const[address, setaddress] = useState('');
  const[account_active, setaccount_active] = useState('yes');
  const[use_for_printing, setuse_for_printing] = useState('no');
  const[company_branch, setcompany_branch] = useState('');
  const[accounting_bank, setaccounting_bank] = useState('');


  useEffect(() => {
  
    $('.dropify').dropify();
    // GET ACTIVE BRANCH LIST
    axios.get(API_BASE_URL + "api-common/get-bank-list", {
        headers: API_HEADERS,
      }).then((response) => {
        if (response.data["status"] > 0) {
          setbankList(response.data["data"]);
          }
      }).catch((error) => {
        toast.error("Failed to fetch data!");
      });

      axios.get(API_BASE_URL + "api-company/get-active-branch-list", {
        headers: API_HEADERS,
      }).then((response) => {
        if (response.data["status"] > 0) {
        setactiveBranchList(response.data["data"]);
        }
      }).catch((error) => {
        toast.error("Failed to fetch data!");
      });


      axios.get(API_BASE_URL + "api-company/get-bank-account-by-id?id="+dataDecrypt(bankaccountID), {
        headers: API_HEADERS,
      }).then((response) => {
        if (response.data["status"] > 0) {
            
            setdataDetails(response.data["data"]);

            setbank_name(response.data["data"].bank_name);
            setaccount_open_date(response.data["data"].account_open_date?formatDate(response.data["data"].account_open_date, 'YMD'):'');

            setaccount_no(response.data["data"].account_no);
            setifsc_no(response.data["data"].ifsc_no);
            setaccount_type(response.data["data"].account_type);
            setaddress(response.data["data"].address);
            setaccount_active(response.data["data"].account_active);
            setuse_for_printing(response.data["data"].use_for_printing);
            setcompany_branch(response.data["data"].company_branch);
            setaccounting_bank(response.data["data"].accounting_bank);
           
        }
      }).catch((error) => {
        toast.error("Failed to director details data!");
      });


  }, [bankaccountID]);


  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};

    const formData = {
      bank_name: bank_name,
      account_open_date: account_open_date,
      account_no: account_no,
      ifsc_no: ifsc_no,
      account_type: account_type,
      address: address,
      account_active: account_active,
      use_for_printing: use_for_printing,
      company_branch: company_branch,
      accounting_bank: accounting_bank,
      id:dataDecrypt(bankaccountID),
    };



    // validate form
     if (!bank_name) {
        errors.bank_name = 'Bank name is required.';
      }

      if (!account_no) {
        errors.account_no = 'Account No is required.';
      }

      if (!account_type) {
        errors.account_type = 'Account Type is required.';
      }

      if (!account_open_date) {
        errors.account_open_date = 'Account Open date is required.';
      }

      if (!ifsc_no) {
        errors.ifsc_no = 'IFSC Code is required.';
      }

      if (!accounting_bank) {
        errors.accounting_bank = 'Accounting Bank is required.';
      }
   

    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
        axios.post(API_BASE_URL + 'api-company/update-bank-account', formData, {
          headers: {
            ...API_HEADERS
          },
        }).then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/bank-accounts";
            }, 3700);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 300);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>Edit Bank Account -  {dataDetails && dataDetails.account_no && (
                                        <>{dataDetails.account_no}</>
                                        )}</h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/bank-accounts">Bank Accounts</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">{dataDetails && dataDetails.account_no && (
                                        <>{dataDetails.account_no}</>
                                        )} </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">Edit </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12"></div>

                  <div class="col-sm-12">
                    <div class="card">
                      <div class="card-header">
                        {/* <h5>Add New Promoter</h5> */}
                        {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                      </div>

                      <div class="card-block">
                        <form onSubmit={updateDetails}>
                          
                          <div class="row">

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Bank Name<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <select
                                    class="form-control m-b"
                                    name="bank_name"
                                    value={bank_name}
                                    onChange={(e) =>
                                      setbank_name(e.target.value)
                                    }
                                  >
                                    <option value={''} disabled>Select bank_name</option>
                                    {bankList.map((bankdata) => (
                                      <option
                                        value={bankdata.id}
                                        selected={
                                          bankdata.id === bank_name
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {bankdata.name}

                                      </option>
                                    ))}
                                  </select>
                                  {formErrors.bank_name && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.bank_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Account Open Date<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="date"
                                    value={account_open_date}
                                    onChange={(e) =>
                                      setaccount_open_date(e.target.value)
                                    }
                                    placeholder="Enter Account Open Date"
                                  />
                                  {formErrors.account_open_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.account_open_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Account No.<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="number"
                                    value={account_no}
                                    onChange={(e) =>
                                      setaccount_no(e.target.value)
                                    }
                                    placeholder="Enter Account No."
                                  />
                                  {formErrors.account_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.account_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    IFSC Code<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={ifsc_no}
                                    onChange={(e) =>
                                      setifsc_no(e.target.value)
                                    }
                                    placeholder="Enter IFSC Code"
                                  />
                                  {formErrors.ifsc_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.ifsc_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Account Type<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <select
                                    class="form-control m-b"
                                    name="account_type"
                                    value={account_type}
                                    onChange={(e) =>
                                      setaccount_type(e.target.value)
                                    }
                                  >
                                    <option value={''} disabled>Select Account Type</option>
                                    <option value="Savings">Savings</option>
                                    <option value="Checking">Checking</option>
                                    <option value="Current">Current</option>
                                    <option value="Money Market">Money Market</option>
                                    <option value="Certificate of Deposit (CD)">Certificate of Deposit (CD)</option>
                                    <option value="Individual Retirement Account (IRA)">Individual Retirement Account (IRA)</option>
                                    <option value="Joint">Joint</option>
                                    <option value="Student">Student</option>
                                    <option value="Business">Business</option>
                                    <option value="Trust">Trust</option>
                                    
                                  </select>
                                  {formErrors.account_type && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.account_type}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Address
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                    <textarea value={address}
                                    class="form-control"
                                    onChange={(e) =>
                                      setaddress(e.target.value)
                                    }
                                    placeholder="Enter Address"></textarea>
                                  {formErrors.address && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.address}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Account Active
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                    <div class="form-radio row ml-3">
                                      <div class="radio radio-inline col-xs-3 ">
                                        <label for="title_yes">
                                          <input
                                            type="radio"
                                            name="account_active"
                                            value={"yes"}
                                            id={"title_yes"}
                                            checked={account_active === 'yes' ? 'checked' : ''}
                                            onChange={(e) =>
                                              setaccount_active(e.target.value)
                                            }
                                          />
                                          <i class="helper"></i>Yes
                                        </label>
                                      </div>
                                      <div class="radio radio-inline col-xs-3 ">
                                        <label for="title_no">
                                          <input
                                            type="radio"
                                            name="account_active"
                                            id={"title_no"}
                                            value={"no"}
                                            checked={account_active === 'no' ? 'checked' : ''}
                                            onChange={(e) =>
                                              setaccount_active(e.target.value)
                                            }
                                          />
                                          <i class="helper"></i>No
                                        </label>
                                      </div>
                                      
                                    </div>
                                    {formErrors.account_active && (
                                      <span style={{ color: "red" }}>
                                        {formErrors.account_active}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  User For Printing
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                    <div class="form-radio row ml-3">
                                      <div class="radio radio-inline col-xs-3 ">
                                        <label for="title_yes">
                                          <input
                                            type="radio"
                                            name="use_for_printing"
                                            value={"yes"}
                                            id={"title_yes"}
                                            checked={use_for_printing === 'yes' ? 'checked' : ''}
                                            onChange={(e) =>
                                              setuse_for_printing(e.target.value)
                                            }
                                          />
                                          <i class="helper"></i>Yes
                                        </label>
                                      </div>
                                      <div class="radio radio-inline col-xs-3 ">
                                        <label for="title_no">
                                          <input
                                            type="radio"
                                            name="use_for_printing"
                                            id={"title_no"}
                                            value={"no"}
                                            checked={use_for_printing === 'no' ? 'checked' : ''}
                                            onChange={(e) =>
                                              setuse_for_printing(e.target.value)
                                            }
                                          />
                                          <i class="helper"></i>No
                                        </label>
                                      </div>
                                      
                                    </div>
                                    {formErrors.use_for_printing && (
                                      <span style={{ color: "red" }}>
                                        {formErrors.use_for_printing}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>

                          </div>

                         
                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <h5 class="sub-title text-center">
                                      Link With Software
                              </h5>
                            </div>
                          </div>


                          <div class="row">

                          <div class="col-sm-12 col-xl-6 m-b-30">
                            <div class="row">
                              <div class="col-sm-5">
                                <h6 class="text-xl-right">
                                Company Branch
                                </h6>
                              </div>
                              <div class="col-sm-7">
                                <select
                                  class="form-control m-b"
                                  name="company_branch"
                                  value={company_branch}
                                  onChange={(e) =>
                                    setcompany_branch(e.target.value)
                                  }
                                >
                                  <option value={''} disabled>Select company_branch</option>
                                  {activeBranchList.map((branchData) => (
                                    <option
                                      value={branchData.id}
                                      selected={
                                        branchData.id === company_branch
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {branchData.branch_name}

                                    </option>
                                  ))}
                                </select>
                                {formErrors.company_branch && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.company_branch}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                          <div class="col-sm-12 col-xl-6 m-b-30">
                            <div class="row">
                              <div class="col-sm-5">
                                <h6 class="text-xl-right">
                                Accounting Bank<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-7">
                                <select
                                  class="form-control m-b"
                                  name="accounting_bank"
                                  value={accounting_bank}
                                  onChange={(e) =>
                                    setaccounting_bank(e.target.value)
                                  }
                                >
                                  <option value={''} disabled>Select Accounting Bank</option>
                                  {bankList.map((bankdata) => (
                                    <option
                                      value={bankdata.id}
                                      selected={
                                        bankdata.id === accounting_bank
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {bankdata.name}

                                    </option>
                                  ))}
                                </select>
                                {formErrors.accounting_bank && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.accounting_bank}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                          </div>

                        


                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                              <button
                                class="btn btn-success btn-square"
                                type="submit"
                                disabled={submitting}
                              >
                                UPDATE
                              </button>{" "}
                              &nbsp;
                              <a href="/bank-accounts" class="btn btn-danger btn-square">Cancel</a>
                            

                              <ToastContainer className="toast-container" />
                            </div>
                          </div>


                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
