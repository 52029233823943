import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../dashboardLayout';

import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "../../../../config"; // Importing constants directly
// import $ from 'jquery';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



import {
      
      FirstLetterCapitalize,
      dataEncript,
      formatDate,
      formattedAmount
      
    } from '../../../helper/commonHelpers'; 

function AdminLayout() {
   const [promoterList, setpromoterList] = useState([]);


    useEffect(() => {

            let listCondition = `account_for = 'saving'`;
            axios.get(API_BASE_URL+'api-common/get-account-list?condition='+listCondition, { headers: API_HEADERS })
            .then(response => {
                  if (response.data["status"] > 0) {
                     setpromoterList(response.data['data']);
                  }
                  console.log(response.data['data']);
            })
            .catch(error => {
              toast.error("Failed to fetch role list list!");
            });
  
    }, []);






    useEffect(() => {
      const timeout = setTimeout(() => {
        if (window.tableDomJquery) {
          window.tableDomJquery();
        }
      }, 1000); // Timeout of 3000 milliseconds (3 seconds)
  
      return () => clearTimeout(timeout); // Clear the timeout on component unmount
    }, [promoterList]);




    



  return (
    <DashboardLayout>
<div class="pcoded-content">
      <div class="pcoded-inner-content">

            <div class="main-body">
                  <div class="page-wrapper">

                        <div class="page-header">
                    <div class="row align-items-end">
                        <div class="col-lg-8">
                            <div class="page-header-title">
                                <div class="d-inline">
                                    <h4>Saving Accounts  
                                        
                                     <small>
                                          &nbsp;
                                          <a class="btn btn-info btn-sm" title="Add New Account" href="/account-open-new">
                                         <b>+</b> </a> &nbsp; 
                                      </small> 
                                        
                                           </h4>
                                    {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="page-header-breadcrumb">
                                <ul class="breadcrumb-title">
                                    <li class="breadcrumb-item" style={{float:'left'}}>
                                        <a href="/dashboard"> <i class="feather icon-home"></i> </a>
                                    </li>
                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="#!">Saving Accounts</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


            <div class="page-body">

                  <div class="card">
                         <div class="card-header">
                      




                        </div> 
                        <div class="card-block">
                              <div class="table-responsive dt-responsive">
                                    <table class="table dtable table-striped table-bordered nowrap" style={{width:'100%'}}>
                                          <thead>
                                                <tr>
                                                      <th>SR.NO</th>
                                                      <th>Associate</th>
                                                      <th>GROUP</th>
                                                      <th>A/C NO.</th>
                                                      <th>MEMBER NO</th>
                                                      <th>MEMBER NAME</th>
                                                      <th>MINOR</th>
                                                      <th>BRANCH</th>
                                                      <th>SCHEME</th>
                                                      <th>OPEN DATE	</th>
                                                      <th>BALANCE</th>
                                                      <th>JOINT A/C</th>
                                                      <th>ACTIVE</th>
                                                      <th>ACTIONS</th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                             
                                          


                                          {
                                                promoterList.length > 0 ? (
                                                  promoterList.map((branchData, index) => (
                                                      <tr class="gradeX">
                                                      <td>{index + 1}</td>
                                                      <td>{branchData.associate_id?FirstLetterCapitalize(branchData.associate_id):''}</td>
                                                      <td>{branchData.scheme_name?FirstLetterCapitalize(branchData.scheme_name):''}</td>
                                                      <td>{branchData.account_no?branchData.account_no:''}</td>
                                                      
                                                      <td>{branchData.member_name?branchData.member_name:''}</td>
                                                      <td>{branchData.member_name?FirstLetterCapitalize(branchData.member_name):''}</td>
                                                      <td></td>
                                                      <td>{branchData.branch_id?FirstLetterCapitalize(branchData.branch_id):''}</td>
                                                      <td>{branchData.scheme_id?FirstLetterCapitalize(branchData.scheme_id):''}</td>
                                                      <td>{branchData.open_date?formatDate(branchData.open_date):''}</td>
                                                      <td>

                                                            <a href="#!" class='font-weight-normal label label-warning' onClick={(e) =>
                                                                {
                                                                  const parent = e.target.parentNode;
                                                                  if (parent) {
                                                                    parent.removeChild(e.target);
                                                                    parent.textContent = formattedAmount(promoterList[index].mis_amount);
                                                                  }
                                                                }
                                                              }> VIEW&nbsp;BALANCE </a>


                                                      </td>

                                                      <td>
                                                      {
                                                            branchData && (
                                                              branchData.account_type === 'joint' ? (
                                                                <label className="label label-success">Yes</label>
                                                              ) : branchData.account_type === 'single' ? (
                                                                <label className="label label-danger">No</label>
                                                              ) :null
                                                            )
                                                          }

                                                      </td>

                                                      <td>
                                                      {
                                                            branchData && (
                                                              branchData.account_status === 'active' ? (
                                                                <label className="label label-success">Active</label>
                                                              ) : branchData.account_status === 'inactive' ? (
                                                                <label className="label label-danger">Inactive</label>
                                                              ) : null
                                                            )
                                                          }

                                                      </td>
                                                      
                                                      <td class="center">

                                                            <a href={`/account-view/${dataEncript(branchData.id)}`} class='font-weight-normal label label-success'><i class="fa fa-eye"></i></a>&nbsp;
                                                            <a href={`/account-edit/${dataEncript(branchData.id)}`}  class='font-weight-normal label label-info'><i class="fa fa-edit"></i> </a>


                                                      </td>
                                                </tr>
                                                  ))
                                                ) : (
                                                  <tr>
                                                    <td colSpan="14">No data available</td>
                                                  </tr>
                                                )
                                              }

                                          
                                               
                                          </tbody>
                                        
                                    </table>
                              </div>
                        </div>
                  </div>






            </div>

      </div>
</div>
<ToastContainer className="toast-container" />
<div id="styleSelector">
</div>
</div>
</div>
    </DashboardLayout>
  );
}

export default AdminLayout;
