import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { 
  dataDecrypt,
    // dataEncript,
    FirstLetterCapitalize,
    infoAlert
     } from "./../../../helper/commonHelpers";

function CompanyDetails() {
  const { scheemID }   = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [listDetails, setlistDetails] = useState([]);

  const [formErrors, setFormErrors] = useState({
    scheme_name: '',
    scheme_code: '',
    min_open_bal: '',
    min_month_avg_bal: '',
    annual_ins_rate: '',
    add_on_ins_rate: '',
    ins_payout: '',
    lock_amt: '',
    min_month_avg_bal_charge: '',
  });


  // PROMOTER INFO 
  const[scheme_name, setscheme_name] = useState('');
  const[scheme_code, setscheme_code] = useState('');
  const[min_open_bal, setmin_open_bal] = useState('');
  const[min_month_avg_bal, setmin_month_avg_bal] = useState('');
  const[annual_ins_rate, setannual_ins_rate] = useState('');
  const[add_on_ins_rate, setadd_on_ins_rate] = useState('');
  const[ins_payout, setins_payout] = useState('');
  const[lock_amt, setlock_amt] = useState('');
  const[min_month_avg_bal_charge, setmin_month_avg_bal_charge] = useState('');
  const[service_charge_frequency, setservice_charge_frequency] = useState('');
  const[service_charge, setservice_charge] = useState('');
  const[sms_charge_frequency, setsms_charge_frequency] = useState('');
  const[sms_charge, setsms_charge] = useState('');
  const[scheme_active, setscheme_active] = useState('no');
  const[scheme_id, setscheme_id] = useState('');

  useEffect(() => {

    axios.get(API_BASE_URL + "api-common/get-account-scheme-details?id="+dataDecrypt(scheemID), {
      headers: API_HEADERS,
    }).then((response) => {

      console.log(response.data);

      if (response.data["status"] > 0) {
        setlistDetails(response.data["data"]);
        
        setscheme_name(response.data["data"].scheme_name);
        setscheme_code(response.data["data"].scheme_code);
        setmin_open_bal(response.data["data"].min_open_bal);
        setmin_month_avg_bal(response.data["data"].min_month_avg_bal);
        setannual_ins_rate(response.data["data"].annual_ins_rate);
        setadd_on_ins_rate(response.data["data"].add_on_ins_rate);
        setins_payout(response.data["data"].ins_payout);
        setlock_amt(response.data["data"].lock_amt);
        setmin_month_avg_bal_charge(response.data["data"].min_month_avg_bal_charge);
        setservice_charge_frequency(response.data["data"].service_charge_frequency);
        setservice_charge(response.data["data"].service_charge);
        setsms_charge_frequency(response.data["data"].sms_charge_frequency);
        setsms_charge(response.data["data"].sms_charge);
        setscheme_active(response.data["data"].scheme_active);
        setscheme_id(response.data["data"].scheme_id);

      } else {
          toast.error(response.data["message"]);
      }

    })
    .catch((error) => {
      toast.error("Failed to fetch data!");
    });
  }, [scheemID]);

  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));

    const formData = {
        // table_id:dataDecrypt(memberID),
        scheme_name: scheme_name,
        scheme_code: scheme_code,
        min_open_bal: min_open_bal,
        min_month_avg_bal: min_month_avg_bal,
        annual_ins_rate: annual_ins_rate,
        add_on_ins_rate: add_on_ins_rate,
        ins_payout: ins_payout,
        lock_amt: lock_amt,
        min_month_avg_bal_charge: min_month_avg_bal_charge,
        service_charge_frequency: service_charge_frequency,
        service_charge: service_charge,
        sms_charge_frequency: sms_charge_frequency,
        sms_charge: sms_charge,
        scheme_active: scheme_active,
        scheme_id: listDetails.id,
    };

    // validate form

    if (!scheme_name) {
      errors.scheme_name = 'Scheme Name is required.';
    }

    if (!scheme_code) {
          errors.scheme_code = 'Scheme Code is required.';
        }

    if (!min_open_bal) {
          errors.min_open_bal = 'Min. Opening Balance is required.';
        }

    if (!min_month_avg_bal) {
          errors.min_month_avg_bal = 'Min. Monthly Avg. Balance is required.';
        }

    if (!annual_ins_rate) {
          errors.annual_ins_rate = 'Annual Interest Rate is required.';
        }

    if (!add_on_ins_rate) {
          errors.add_on_ins_rate = 'Sr. Citizen Add-on Interest is required.';
        }

    if (!ins_payout) {
          errors.ins_payout = 'Interest Payout is required.';
        }

    if (!lock_amt) {
          errors.lock_amt = 'Lock In Amount is required.';
        }

    if (!min_month_avg_bal_charge) {
          errors.min_month_avg_bal_charge = 'Min. Monthly Avg. Balance is required.';
        }


    //   console.log(formData);
    //   console.log(errors);

    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      axios
        .post(API_BASE_URL + "api-saving-account/add-new-scheme", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/account-scheem-list";
            //   window.location.reload();
            }, 2000);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 1000);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };




  const alertInfo = async (msg1, msg2='') => {

    const confirmed = await infoAlert(msg1, msg2='', );
    if(confirmed===true)
    {

    }
};
  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
            <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>Edit Saving Scheme
                          {listDetails && listDetails.scheme_name && (
                              <>{FirstLetterCapitalize(listDetails.scheme_name)}</>
                              )}
                        </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/account-scheem-list">Saving Scheme</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">  {listDetails && listDetails.scheme_name && (
                              <>{FirstLetterCapitalize(listDetails.scheme_name)}</>
                              )}</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">Edit </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12"></div>

                


                  <div class="col-sm-12">
                    <div class="card">
                    

                      <div class="card-block">
                        <form onSubmit={updateDetails}>

                        <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Scheme Name<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={scheme_name}
                                    onChange={(e) =>
                                      setscheme_name(e.target.value)
                                    }
                                    placeholder="Enter Scheme Name"
                                    readOnly
                                  />
                                  {formErrors.scheme_name && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.scheme_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Scheme Code<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <div class="input-group">
                                   <input class="form-control" type="number" value={scheme_code} onChange={(e) => setscheme_code(e.target.value) } placeholder="Enter Scheme Code" readOnly />
                                  <div class="input-group-prepend" style={{width:'auto'}}>
                                      <span class="input-group-text"   onClick={() => alertInfo("You can give code to saving schemes Like 'SAV001'")} title="You can give code to saving schemes Like 'SAV001'" ><i class="icofont icofont-info"></i></span>
                                  </div>
                                </div>
                                  {formErrors.scheme_code && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.scheme_code}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                          </div>


                          <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Min. Opening Balance<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="number"
                                    value={min_open_bal}
                                    onChange={(e) =>
                                      setmin_open_bal(e.target.value)
                                    }
                                    placeholder="Enter Min. Opening Balance"
                                  />
                                  {formErrors.min_open_bal && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.min_open_bal}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Min. Monthly Avg. Balance<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input class="form-control" type="number" value={min_month_avg_bal} onChange={(e) => setmin_month_avg_bal(e.target.value) } placeholder="Min. Monthly Avg. Balance" />
                                  {formErrors.min_month_avg_bal && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.min_month_avg_bal}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                          </div>


                          <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Annual Interest Rate (%)<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <div class="input-group">
                                   <input class="form-control" type="number" value={annual_ins_rate} onChange={(e) => setannual_ins_rate(e.target.value) } placeholder="Enter Annual Interest Rate (%)" />
                                  <div class="input-group-prepend" style={{width:'auto'}}>
                                      <span class="input-group-text" onClick={() => alertInfo("Maximum Interest on Saving Account can be 8 %.")}  title="Maximum Interest on Saving Account can be 8 %." ><i class="icofont icofont-info"></i></span>
                                  </div>
                                </div>
                                  {formErrors.annual_ins_rate && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.annual_ins_rate}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Sr. Citizen Add-on Interest Rate (%)<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <div class="input-group">
                                   <input class="form-control" type="number" value={add_on_ins_rate} onChange={(e) => setadd_on_ins_rate(e.target.value) } placeholder="Enter Sr. Citizen Add-on Interest Rate (%)" />
                                  <div class="input-group-prepend" style={{width:'auto'}}>
                                      <span class="input-group-text" onClick={() => alertInfo("Add the extra interest rate to be given to serior citizen on saving scheme.", "This interest rate will be added to interest rate. Like - 0.5 , 0.75 etc")}  title="Add the extra interest rate to be given to serior citizen on saving scheme. This interest rate will be added to interest rate. Like - 0.5 , 0.75 etc" ><i class="icofont icofont-info"></i></span>
                                  </div>
                                </div>
                                  {formErrors.add_on_ins_rate && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.add_on_ins_rate}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                          </div>


                          <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Interest Payout<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <div class="input-group">
                                <select
                                    class="form-control m-b"
                                    name="promoter_id"
                                    value={ins_payout}
                                    onChange={(e) =>
                                      setins_payout(e.target.value)
                                    }
                                  >
                                   <option value="">Select interest payout cycle</option>
                                  <option value="MONTHLY">Monthly</option>
                                  <option value="QAURTERLY">Qaurterly</option>
                                  <option value="HALF_YEARLY">Half Yearly</option>
                                  <option value="YEARLY">Yearly</option>
                                   
                                  </select>
                                   <div class="input-group-prepend" style={{width:'auto'}}>
                                      <span class="input-group-text" onClick={() => alertInfo("Interest to be credit to member")}  title="Interest to be credit to member" ><i class="icofont icofont-info"></i></span>
                                  </div>
                                </div>
                                  {formErrors.ins_payout && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.ins_payout}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                          

                          <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Lock In Amount<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <div class="input-group">
                                   <input class="form-control" type="number" value={lock_amt} onChange={(e) => setlock_amt(e.target.value) } placeholder="Enter Lock In Amount" />
                                  <div class="input-group-prepend" style={{width:'auto'}}>
                                      <span class="input-group-text" onClick={() => alertInfo("This amount will be locked in saving account and can't be withdrawn.")} title="This amount will be locked in saving account and can't be withdrawn." ><i class="icofont icofont-info"></i></span>
                                  </div>
                                </div>
                                  {formErrors.lock_amt && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.lock_amt}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Min. Monthly Avg. Balance Charge<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <div class="input-group">
                                   <input class="form-control" type="number" value={min_month_avg_bal_charge} onChange={(e) => setmin_month_avg_bal_charge(e.target.value) } placeholder="Enter Min. Monthly Avg. Balance Charge" />
                                  <div class="input-group-prepend" style={{width:'auto'}}>
                                      <span class="input-group-text" onClick={() => alertInfo("If monthly avg balance is not mainitned, than the mentioned charge will be deducted every month from the account.", )} title="If monthly avg balance is not mainitned, than the mentioned charge will be deducted every month from the account." ><i class="icofont icofont-info"></i></span>
                                  </div>
                                </div>
                                  {formErrors.min_month_avg_bal_charge && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.min_month_avg_bal_charge}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                                    
                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <h4 class="sub-title text-center">
                              SERVICE CHARGES
                              </h4>
                            </div>
                          </div>


                          <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Charge Frequency
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <div class="input-group">
                                <select
                                    class="form-control m-b"
                                    name="promoter_id"
                                    value={service_charge_frequency}
                                    onChange={(e) =>
                                      setservice_charge_frequency(e.target.value)
                                    }
                                  >
                                  <option value=" ">Select Frequency</option>
                                  <option value="beginning_of_month">Beginning of Month</option>
                                  <option value="end_of_month">End of Month</option>
                                  <option value="beginning_of_quarter">Beginning of Quarter</option>
                                  <option value="end_of_quarter">End of Quarter</option>
                                  <option value="beginning_of_half_year">Beginning of Half year</option>
                                  <option value="end_of_half_year">End of Half year</option>
                                  <option value="beginning_of_year">Beginning of Year</option>
                                  <option value="end_of_year">End of Year</option>
                                   
                                  </select>
                                   <div class="input-group-prepend" style={{width:'auto'}}>
                                      <span class="input-group-text" onClick={() => alertInfo("es will automatically deduct from saving accounts as per frequency selected like every month start, month end, quarter start, quarter end and so on.", )}  title="Charges will automatically deduct from saving accounts as per frequency selected like every month start, month end, quarter start, quarter end and so on." ><i class="icofont icofont-info"></i></span>
                                  </div>
                                </div>
                                  {formErrors.service_charge_frequency && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.service_charge_frequency}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Service Charges
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <div class="input-group">
                                   <input class="form-control" type="number" value={service_charge} onChange={(e) => setservice_charge(e.target.value) } placeholder="Enter Service Charges" />
                                  <div class="input-group-prepend" style={{width:'auto'}}>
                                      <span class="input-group-text" onClick={() => alertInfo("This amount will be deduct from saving account." )}  title="This amount will be deduct from saving account." ><i class="icofont icofont-info"></i></span>
                                  </div>
                                </div>
                                  {formErrors.service_charge && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.service_charge}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <h4 class="sub-title text-center">
                              SMS CHARGES
                              </h4>
                            </div>
                          </div>
                            

                          <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Charge Frequency
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <div class="input-group">
                                <select
                                    class="form-control m-b"
                                    name="promoter_id"
                                    value={sms_charge_frequency}
                                    onChange={(e) =>
                                      setsms_charge_frequency(e.target.value)
                                    }
                                  >
                                  <option value=" ">Select Frequency</option>
                                  <option value="beginning_of_month">Beginning of Month</option>
                                  <option value="end_of_month">End of Month</option>
                                  <option value="beginning_of_quarter">Beginning of Quarter</option>
                                  <option value="end_of_quarter">End of Quarter</option>
                                  <option value="beginning_of_half_year">Beginning of Half year</option>
                                  <option value="end_of_half_year">End of Half year</option>
                                  <option value="beginning_of_year">Beginning of Year</option>
                                  <option value="end_of_year">End of Year</option>
                                   
                                  </select>
                                   <div class="input-group-prepend" style={{width:'auto'}}>
                                      <span class="input-group-text" onClick={() => alertInfo("Charges will automatically deduct from saving accounts as per frequency selected like every month start, month end, quarter start, quarter end and so on.", "This charge will only be deducted if SMS are enabled on member & saving account." )} title="Charges will automatically deduct from saving accounts as per frequency selected like every month start, month end, quarter start, quarter end and so on." ><i class="icofont icofont-info"></i></span>
                                  </div>
                                </div>
                                  {formErrors.sms_charge_frequency && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.sms_charge_frequency}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  SMS Charges
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <div class="input-group">
                                   <input class="form-control" type="number" value={sms_charge} onChange={(e) => setsms_charge(e.target.value) } placeholder="Enter SMS Charges" />
                                  <div class="input-group-prepend" style={{width:'auto'}}>
                                      <span class="input-group-text"  onClick={() => alertInfo("This amount will be deduct from saving account.", )} title="This amount will be deduct from saving account." ><i class="icofont icofont-info"></i></span>
                                  </div>
                                </div>
                                  {formErrors.sms_charge && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.sms_charge}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <h4 class="sub-title text-center">
                              FREE IFSC COLLECTION PER MONTH
                              </h4>
                            </div>
                          </div>

                          
                          <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Charge Frequency
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <div class="input-group">
                                <select
                                    class="form-control m-b"
                                    name="promoter_id"
                                    value={scheme_id}
                                    onChange={(e) =>
                                      setscheme_id(e.target.value)
                                    }
                                  >
                                  <option value="0">0 per month</option>
                                  <option value="1">1 per month</option>
                                  <option value="2">2 per month</option>
                                  <option value="3">3 per month</option>
                                  <option value="4">4 per month</option>
                                  <option value="5">5 per month</option>
                                  <option value="6">6 per month</option>
                                  <option value="7">7 per month</option>
                                  <option value="8">8 per month</option>
                                  <option value="9">9 per month</option>
                                  <option value="10">10 per month</option>
                                  <option value="11">11 per month</option>
                                  <option value="12">12 per month</option>
                                  <option value="13">13 per month</option>
                                  <option value="14">14 per month</option>
                                  <option value="15">15 per month</option>
                                  <option value="16">16 per month</option>
                                  <option value="17">17 per month</option>
                                  <option value="18">18 per month</option>
                                  <option value="19">19 per month</option>
                                  <option value="20">20 per month</option>
                                  <option value="21">21 per month</option>
                                  <option value="22">22 per month</option>
                                  <option value="23">23 per month</option>
                                  <option value="24">24 per month</option>
                                  <option value="25">25 per month</option>
                                  <option value="unlimited">Unlimited</option>
                                   
                                  </select>
                                   <div class="input-group-prepend" style={{width:'auto'}}>
                                      <span class="input-group-text" onClick={() => alertInfo("Charges will be charged to bank as per slab & will not be deducted from customer saving account.", )} title="Charges will be charged to bank as per slab & will not be deducted from customer saving account." ><i class="icofont icofont-info"></i></span>
                                  </div>
                                </div>
                                  {formErrors.scheme_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.scheme_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                           
                          </div>



                          <div class="row" >
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                  Scheme Active<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_yes">
                                        <input
                                          type="radio"
                                          name="scheme_active"
                                          value={"yes"}
                                          id={"title_yes"}
                                          checked={scheme_active === 'yes' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setscheme_active(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Yes
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_no">
                                        <input
                                          type="radio"
                                          name="scheme_active"
                                          id={"title_no"}
                                          value={"no"}
                                          checked={scheme_active === 'no' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setscheme_active(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>No
                                      </label>
                                    </div>
                                  
                                    
                                  </div>
                                  {formErrors.scheme_active && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.scheme_active}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                      


                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                              <button
                                class="btn btn-success btn-square"
                                type="submit"
                                disabled={submitting}
                              >
                                Save Scheme
                              </button>{" "}
                              <a href="/account-scheem-list" class="btn btn-danger btn-square">Cancel</a>
                              &nbsp;
                              <button
                                class="btn btn-warning btn-square"
                                type="reset"
                              >
                                Reset
                              </button>
                             

                              <ToastContainer className="toast-container" />
                            </div>
                          </div>


                        </form>
                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
