import React from 'react';
import DashboardLayout from './dashboardLayout';



function AdminLayout() {

  return (
    <DashboardLayout>
     <div class="pcoded-content">
    <div class="pcoded-inner-content">
        <div class="main-body">
            <div class="page-wrapper">
                <div class="page-header">
                    <div class="row align-items-end">
                        <div class="col-lg-8">
                            <div class="page-header-title">
                                <div class="d-inline">
                                    <h4>Form Picker</h4>
                                    <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="page-header-breadcrumb">
                                <ul class="breadcrumb-title">
                                    <li class="breadcrumb-item" style={{float:'left'}}>
                                        <a href="../index.html"> <i class="feather icon-home"></i> </a>
                                    </li>
                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="#!">Form Picker</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="page-body">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-header">
                                    <h5>Default Date-Picker</h5>
                                    <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span>
                                </div>
                                <div class="card-block">
                                    <div class="row">
                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Date</h4>
                                            <p>Add type<code>&lt;input type="date"&gt;</code></p>
                                            <input class="form-control" type="date" />
                                        </div>
                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Month</h4>
                                            <p>Add type<code>&lt;input type="month"&gt;</code></p>
                                            <input class="form-control" type="month" />
                                        </div>
                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Week</h4>
                                            <p>Add type<code>&lt;input type="week"&gt;</code></p>
                                            <input class="form-control" type="week" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-xl-4 m-b-30">
                                            <h4 class="sub-title">Date-time-local</h4>
                                            <p>Add type<code>&lt;input type="datetime-local"&gt;</code></p>
                                            <input class="form-control" type="datetime-local" />
                                        </div>
                                        <div class="col-sm-12 col-xl-4">
                                            <h4 class="sub-title">Time</h4>
                                            <p>Add type<code>&lt;input type="time"&gt;</code></p>
                                            <input class="form-control" type="time" />
                                        </div>
                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="styleSelector"></div>
    </div>
</div>

    </DashboardLayout>
  );
}

export default AdminLayout;
