import React, { useState, useEffect } from "react";
import DashboardLayout from "../../dashboardLayout";
// import axios from "axios";
// import { API_BASE_URL, API_HEADERS } from "../../../../config"; // Importing constants directly

import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

// import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {  dataDecrypt, dataEncript, FirstLetterCapitalize } from "../../../helper/commonHelpers";
import {  getDataListBytable } from '../../../helper/listModal';

//formatDate

function CompanyDetails() {
  const { rankID }   = useParams();
  const [details, setdetails] = useState([]);



  useEffect(() => {
  

   
    getDataListBytable('tbl_associate_rank', ``, dataDecrypt(rankID)).then(data => {
        // console.log(data);
        setdetails(data);

      }).catch(error => {
          // console.error('Error fetching associate list:', error);
      });

  }, [rankID]);



  

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>  Edit Rank - 

                        {details && details.rank_name && (
                                    <>{details.rank_name}</>
                                    )}  

                        </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                            <a href="/associate-advisor-agent-ranks">Associate/ Advisor Ranks </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                            <a href={`/associate-advisor-agent-ranks-view/${dataEncript(details.id)}`}>{details && details.rank_name && ( <>{details.rank_name}</> )} </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> View</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="card" style={{borderTop:'3px solid red'}} >

                    <div class="card-header">
                    <a style={{ float: "right" }} class="badge badge-info" href={`/associate-advisor-agent-ranks-edit/${dataEncript(details.id)}`} title="Edit" > <i class="feather icon-edit"></i> Edit </a>
                    </div>
                    
                        {/* <h4 class="m-4">CHANGE ACCOUNT INFO</h4> */}
                       <hr class="p-0 m-0"/>

                     

                      <div class="card-block">
                      <div class="table-responsive dt-responsive">
                      <table class="table dtable table-striped table-bordered nowrap">
                        <tbody>


                        <tr>
                            <th>Name</th>
                            <td> {details && details.rank_name && (
                                <> {FirstLetterCapitalize(details.rank_name)}</>
                                )}</td>
                        </tr>

                        <tr>
                            <th>Position</th>
                            <td> {details && details.rank_working_position && (
                                <> {(details.rank_working_position)}</>
                                )}</td>
                        </tr>

                        
                        <tr>
                            <th>Display Position</th>
                            <td> {details && details.rank_display_position && (
                                <> {(details.rank_display_position)}</>
                                )}</td>
                        </tr>

                        <tr>
                            <th>Collection Commission Enabled	</th>
                            <td> 
                            {
                                details && (
                                    details.collect_collection_charge === 'yes' ? (
                                    <label className="label label-success">yes</label>
                                    ) : details.collect_collection_charge === 'no' ? (
                                    <label className="label label-danger">No</label>
                                    ) : null
                                )
                                }

                            </td>
                        </tr>


                        </tbody>
                    </table>
                    </div>

                      </div>



                    </div>
                  </div>

                  <div class="col-sm-5">
                  
                  </div>
                </div>


                

              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
          {/* <ToastContainer className="toast-container" /> */}
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
