import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {   dataDecrypt,   getCurrentDate,  dataEncript } from "./../../../helper/commonHelpers";
//formatDate

function CompanyDetails() {
  const [submitting, setSubmitting] = useState(false);
  const { accountID }   = useParams();
  const [shareHolderDetail, setshareHolderDetail] = useState([]);


  const [formErrors, setFormErrors] = useState({
    member_id: "",
    transaction_date: "",
    amount: "",
  });


 
  
  const[remark, setremark] = useState('');
  const[amount, setamount] = useState('');
  const[transaction_date, settransaction_date] = useState('');




  useEffect(() => {
  

      axios.get(API_BASE_URL + "api-common/get-account-list?id="+dataDecrypt(accountID), {
        headers: API_HEADERS,
      }).then((response) => {

        console.log(response.data);

        if (response.data["status"] > 0) {
            setshareHolderDetail(response.data["data"]);


        } else {
            toast.error(response.data["message"]);
        }

      });

    

     


      settransaction_date(getCurrentDate());
  }, [accountID]);




  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));

    const formData = {
        account_id:dataDecrypt(accountID),

        remark: remark,
        amount: amount,
        transaction_date: transaction_date,
        transaction_type: 'credit'


    };

    // validate form

      // if (!member_id) {
      //   errors.member_id = 'Member is required.';
      // }

     

      if (!transaction_date) {
        errors.transaction_date = 'Transaction date is required.';
      }

      if (!amount) {
           errors.amount = 'Amount is required.';
      }

    

    


    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      axios
        .post(API_BASE_URL + "api-saving-account/credit-intrest-amount", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              // window.location.href = "/member-share-holdings";
              window.location.href = "/account-transaction-view/"+accountID+'/'+response.data["data"].id;

            //   window.location.reload();
            }, 2000);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 1000);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };


  



  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4> Account - {shareHolderDetail && shareHolderDetail.account_no && (
                                    <>{shareHolderDetail.account_no}</>
                                    )} 

                            <span>&nbsp;Other Charges</span> </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/account-list">Accounts </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href={`/account-view/${dataEncript(shareHolderDetail.id)}`}> {shareHolderDetail && shareHolderDetail.account_no && (
                                    <>{shareHolderDetail.account_no}</>
                                    )} </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">Clear Due</a>
                        </li>

                        

                        
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-7">
                    <div class="card" style={{borderTop:'3px solid red'}} >
                    

                      <div class="card-block">
                      <form onSubmit={updateDetails}>


                      


                          <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                  <div class="col-sm-5">
                                  <h6 class="text-xl-right">Transaction Date<span class="text-danger">*</span></h6>
                                  </div>
                                  <div class="col-sm-7">
                                  <input
                                      class="form-control"
                                      type="date"
                                      value={transaction_date}
                                      onChange={(e) =>
                                      settransaction_date(e.target.value)
                                      }
                                      placeholder="Enter Transaction Date. "
                                  />
                                  {formErrors.transaction_date && (
                                      <span style={{ color: "red" }}>
                                      {formErrors.transaction_date}
                                      </span>
                                  )}
                                  </div>
                              </div>
                              </div>
                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                  <div class="col-sm-5">
                                  <h6 class="text-xl-right">Interest Amount<span class="text-danger">*</span></h6>
                                  </div>
                                  <div class="col-sm-7">
                                  <input
                                      class="form-control"
                                      type="number"
                                      value={amount}
                                      onChange={(e) =>
                                      setamount(e.target.value)
                                      }
                                      placeholder="0"
                                  />
                                  {formErrors.amount && (
                                      <span style={{ color: "red" }}>
                                      {formErrors.amount}
                                      </span>
                                  )}
                                  </div>
                              </div>
                              </div>
                          </div>
                      


                        <div class="row">
                        <div class="col-sm-12 col-xl-12 m-b-30">
                            <div class="row">
                              <div class="col-sm-5">
                                <h6 class="text-xl-right">
                                Remarks (if any)
                                </h6>
                              </div>
                              <div class="col-sm-7">
                               

                                  <textarea class="form-control" value={remark} rows={'3'}
                                  onChange={(e) =>
                                    setremark(e.target.value)
                                  }
                                  placeholder="Enter Remarks (if any). " ></textarea>

                                {formErrors.remark && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.remark}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        


                        

                         


                        <div class="row">
                          <div class="col-sm-12 col-xl-12 offset-xl-1 m-b-30 text-center">
                            <button
                              class="btn btn-success btn-square"
                              type="submit"
                              disabled={submitting}
                            >
                              SAVE
                            </button>{" "}
                            <a href={`/account-view/${dataEncript(shareHolderDetail.id)}`} class="btn btn-danger btn-square">Cancel</a>
                            &nbsp;
                          

                            <ToastContainer className="toast-container" />
                          </div>
                        </div>


                      </form>
                      </div>
                    </div>
                  </div>

                


                </div>


                

              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
          <ToastContainer className="toast-container" />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
