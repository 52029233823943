import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
// import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { 
  // dataDecrypt,
    // dataEncript,
    getCurrentDate,
    FirstLetterCapitalize
    // infoAlert
     } from "./../../../helper/commonHelpers";

function CompanyDetails() {
  const [submitting, setSubmitting] = useState(false);
  const [bankList, setbankList] = useState([]);

  const [formErrors, setFormErrors] = useState({
    member_id: '', 
    branch_id: '', 
    scheme_id: '', 
    open_date: '', 
    amount_deposite: '', 
    joint_ac1_member_id: '',

    name: '',
    relation: '',
    address: '',


  });


  const [activeBranchList, setactiveBranchList] = useState([]);
  const [minorList, setminorList] = useState([]);
  const [memberList, setmemberList] = useState([]);
  const [scheemList, setscheemList] = useState([]);
  const [associateListArr, setassociateListArr] = useState([]);



  // PROMOTER INFO 
  const[member_id, setmember_id] = useState('');
  const[member_name, setmember_name] = useState('');
  const[minor_id, setminor_id] = useState('');
  const[branch_id, setbranch_id] = useState('');
  const[associate_id, setassociate_id] = useState('');
  const[scheme_id, setscheme_id] = useState('');
  const[open_date, setopen_date] = useState('');
  const[amount_deposite, setamount_deposite] = useState('');
  const[account_type, setaccount_type] = useState('single');
      const[joint_ac1_member_id, setjoint_ac1_member_id] = useState('');
      const[joint_ac2_member_id, setjoint_ac2_member_id] = useState('');
      const[mode_of_operation, setmode_of_operation] = useState('single');

  const[nominee, setnominee] = useState('no');
  const[paymode, setpaymode] = useState('');
  const[transaction_date, settransaction_date] = useState('');
  const[creadited_nidhi_acc, setcreadited_nidhi_acc] = useState('');
  const[transfer_mode, settransfer_mode] = useState('');
  // const[saving_account, setsaving_account] = useState('');
  const[utr_no, setutr_no] = useState('');
  const[transfer_date, settransfer_date] = useState('');
  const[cheque_date, setcheque_date] = useState('');
  const[cheque_no, setcheque_no] = useState('');
  const[bank_name, setbank_name] = useState('');

  useEffect(() => {


    axios.get(API_BASE_URL + "api-associates/get-associate-list", {
      headers: API_HEADERS,
    }).then((response) => {
      if (response.data["status"] > 0) {
        setassociateListArr(response.data["data"]);
      }
    });


    axios.get(API_BASE_URL + "api-common/get-bank-list", {
      headers: API_HEADERS,
    }).then((response) => {
      if (response.data["status"] > 0) {
        setbankList(response.data["data"]);
        }
    }).catch((error) => {
      toast.error("Failed to fetch data!");
    });

    axios.get(API_BASE_URL + "api-company/get-active-branch-list", {
      headers: API_HEADERS,
    }).then((response) => {
      if (response.data["status"] > 0) {
      setactiveBranchList(response.data["data"]);
      }
    }).catch((error) => {
      toast.error("Failed to fetch data!");
    });


    let minorCondition = `member_type = 'member'`;
    axios.get(API_BASE_URL + "api-common/get-data?tablename=tbl_minor&condition="+minorCondition, {
      headers: API_HEADERS,
    })
    .then((response) => {

      if (response.data["status"] > 0) {
        setminorList(response.data["data"]);
      } 

    })
    .catch((error) => {
    console.log('Failed to fetch MINOR DATA!');
    });



    axios.get(API_BASE_URL + "api-member/get-member-list", {
      headers: API_HEADERS,
    }).then((response) => {
      if (response.data["status"] > 0) {
      setmemberList(response.data["data"]);
      }
    }).catch((error) => {
      toast.error("Failed to fetch data!");
    });


    axios.get(API_BASE_URL+'api-common/get-account-scheme-list', {
      headers: API_HEADERS
      }).then(response => {
         if (response.data["status"] > 0) {
          setscheemList(response.data['data']);
         }
       //   console.log(response.data['data']);
   });


  setopen_date(getCurrentDate());
  settransaction_date(getCurrentDate());
       settransfer_date(getCurrentDate());
  }, []);

  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));

    const formData = {
      member_id: member_id,
      member_name: member_name,
      minor_id: minor_id,
      branch_id: branch_id,
      associate_id: associate_id,
      scheme_id: scheme_id,
      open_date: open_date,
      amount_deposite: amount_deposite,
      account_type: account_type,
        joint_member1:joint_ac1_member_id, 
        joint_member2:joint_ac2_member_id, 
        mode_of_operation:mode_of_operation, 

      nominee: nominee,
      paymode: paymode,
      transaction_date: transaction_date,
      creadited_nidhi_acc: creadited_nidhi_acc,
      transfer_mode: transfer_mode,
      // saving_account: saving_account,
      utr_no: utr_no,
      transfer_date: transfer_date,
      cheque_date: cheque_date,
      cheque_no: cheque_no,
      bank_name: bank_name,

      // this is for nominee----------------------
      name: name,
      relation: relation,
      address: address,


    };

    // validate form

    if (!member_id) {
      errors.member_id = 'Member is required.';
    }

    if (!branch_id) {
      errors.branch_id = 'Branch is required.';
    }

    if (!scheme_id) {
      errors.scheme_id = 'Scheem is required.';
    }

    if (!open_date) {
      errors.open_date = 'Open Date is required.';
    }

    if (!amount_deposite) {
      errors.amount_deposite = 'Amount Deposite is required.';
    }


    if(account_type==='joint')
    {

      if (!joint_ac1_member_id) {
          errors.joint_ac1_member_id = 'Joint A/c Member is required.';
        }

    }


    if(nominee==='yes')
    {

        if (!name) {
          errors.name = 'Name is required.';
        }

        if (!relation) {
          errors.relation = 'Relation is required.';
        }

        if (!address) {
          errors.address = 'Address is required.';
        }

    }

  

    if(formData.paymode==='cheque')
    {
      if (!formData.bank_name) {
        errors.bank_name = 'Bank Name is required.';
      }

      if (!formData.cheque_no) {
        errors.cheque_no = 'Cheque No. is required.';
      }

      if (!formData.cheque_date) {
        errors.cheque_date = 'Cheque Date. is required.';
      }
    }

    if(formData.paymode==='online')
    {
      if (!formData.transfer_date) {
        errors.transfer_date = 'Transfer Date is required.';
      }

      if (!formData.utr_no) {
        errors.utr_no = 'UTR/ Transaction No is required.';
      }

      if (!formData.transfer_mode) {
        errors.transfer_mode = 'Transfer Mode is required.';
      }

      if (!creadited_nidhi_acc) {
        errors.creadited_nidhi_acc = 'creadited_nidhi_acc is required.';
      }

    }





    //   console.log(errors);

    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      axios
        .post(API_BASE_URL + "api-saving-account/open-saving-account", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/account-list";
            //   window.location.reload();
            }, 2000);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 1000);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };




  const [name, setname] = useState(['']);
  const [relation, setrelation] = useState(['']);
  const [address, setaddress] = useState(['']);

  const [nominees, setNominees] = useState(['']);

  

  const addNominee = () => {
    setNominees([...nominees, '']);
};

const removeNominee = (index) => {
 const updatedNominees = [...nominees];
 updatedNominees.splice(index, 1);
 setNominees(updatedNominees);
};

const handleNomineeChange = (index, value) => {
 const updatedNominees = [...nominees];
 updatedNominees[index] = value;
 setNominees(updatedNominees);
 setname(updatedNominees);
};

const handleRelation = (index, value) => {
 const updatedRelation = [...relation];
 updatedRelation[index] = value;
 setrelation(updatedRelation);
};

const handleAddress = (index, value) => {
 const updatedAddress = [...address];
 updatedAddress[index] = value;
 setaddress(updatedAddress);
};


//   const alertInfo = async (msg1, msg2='') => {

//     const confirmed = await infoAlert(msg1, msg2='', );
//     if(confirmed===true)
//     {

//     }
// };
  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
            <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>Open New Account</h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/account-list">Saving Accounts</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">Open New Account</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12"></div>

                


                  <div class="col-sm-12">
                    <div class="card">
                    

                      <div class="card-block">
                        <form onSubmit={updateDetails}>

                       
                        


                          <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Member<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">

                                <select
                                    class="form-control m-b"
                                    name="member_id"
                                    value={member_id}
                                    onChange={(e) =>
                                      {setmember_id(e.target.value);
                                        const selectedIndex = e.target.options.selectedIndex;
                                        const dataIndex = e.target.options[selectedIndex].getAttribute('data-index');

                                        setmember_name((memberList[dataIndex].fname+' '+ memberList[dataIndex].lname));

                                      }
                                    }
                                  >
                                    <option value={''} disabled>Select Member</option>
                                    {memberList.map((aBranchData, mindex) => (
                                      <option
                                        value={aBranchData.id}
                                        data-index={mindex}
                                        selected={
                                          aBranchData.id === member_id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {aBranchData.fname} &nbsp;
                                        {aBranchData.lname}

                                      </option>
                                    ))}
                                  </select>


                                  {formErrors.member_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.member_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Member Name
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input class="form-control" type="text" value={member_name} onChange={(e) => setmember_name(e.target.value) } placeholder="Enter Member Name" readOnly />
                                  {formErrors.member_name && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.member_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Minor
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                  <select
                                  class="form-control m-b"
                                  name="minor_id"
                                  value={minor_id}
                                  onChange={(e) =>
                                    setminor_id(e.target.value)
                                  }
                                >
                                  <option value={''} disabled>Select Minor</option>
                                  {minorList.map((minorData) => (
                                    <option
                                      value={minorData.id}
                                      selected={
                                        minorData.id === minor_id
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {minorData.title?minorData.title:''}
                                      {minorData.fname?minorData.fname:''} {minorData.lname?minorData.lname:''}

                                    </option>
                                  ))}
                                </select>


                                  {formErrors.minor_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.minor_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Branch<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">

                                  <select
                                  class="form-control m-b"
                                  name="branch_id"
                                  value={branch_id}
                                  onChange={(e) =>
                                    setbranch_id(e.target.value)
                                  }
                                >
                                  <option value={''} disabled>Branch</option>
                                  {activeBranchList.map((branchData) => (
                                    <option
                                      value={branchData.id}
                                      selected={
                                        branchData.id === branch_id
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {branchData.branch_name}

                                    </option>
                                  ))}
                                </select>



                                  {formErrors.branch_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.branch_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Associate/ Advisor/ Staff 
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                              

                                  <select
                                  class="form-control m-b"
                                  name="associate_id"
                                  value={associate_id}
                                  onChange={(e) =>
                                    setassociate_id(e.target.value)
                                  }
                                >
                                  <option value={''} disabled>Associate/ Advisor/ Staff</option>
                                  {associateListArr.map((branchData) => (
                                    <option
                                      value={branchData.id}
                                      selected={
                                        branchData.id === associate_id
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {branchData.first_name} 
                                      {branchData.last_name}

                                    </option>
                                  ))}
                                </select>


                                  {formErrors.associate_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.associate_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <h4 class="sub-title text-center">
                              {/* SERVICE CHARGES 1*/}
                              </h4>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Scheme <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               

                                  <select
                                  class="form-control m-b"
                                  name="scheme_id"
                                  value={scheme_id}
                                  onChange={(e) =>
                                    setscheme_id(e.target.value)
                                  }
                                >
                                  <option value={''} disabled>Select Scheme </option>
                                  {scheemList.map((scheemData) => (
                                    <option
                                      value={scheemData.id}
                                      selected={
                                        scheemData.id === scheme_id
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {scheemData.scheme_code?FirstLetterCapitalize(scheemData.scheme_code):''}
                                      {scheemData.scheme_name? ' - '+ FirstLetterCapitalize(scheemData.scheme_name):''}

                                    </option>
                                  ))}
                                </select>


                                  {formErrors.scheme_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.scheme_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Open Date<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input class="form-control" type="date" value={open_date} onChange={(e) => setopen_date(e.target.value) } placeholder="Enter Open Date" />
                                  {formErrors.open_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.open_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Amount Deposit<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input class="form-control" type="number" value={amount_deposite} onChange={(e) => setamount_deposite(e.target.value) } placeholder="Enter Amount Deposit" />
                                  {formErrors.amount_deposite && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.amount_deposite}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <h4 class="sub-title text-center">
                              {/* SERVICE CHARGES 2*/}
                              </h4>
                            </div>
                          </div>

                        
                          <div class="row" >
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                  Account Type<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_single">
                                        <input
                                          type="radio"
                                          name="account_type"
                                          value={"single"}
                                          id={"title_single"}
                                          checked={account_type === 'single' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setaccount_type(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Single
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_joint">
                                        <input
                                          type="radio"
                                          name="account_type"
                                          id={"title_joint"}
                                          value={"joint"}
                                          checked={account_type === 'joint' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setaccount_type(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Joint
                                      </label>
                                    </div>
                                  
                                    
                                  </div>
                                  {formErrors.account_type && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.account_type}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style={{ display: account_type === 'joint' ? '' : 'none' }}>
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Joint A/c Member <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                  <select
                                    class="form-control m-b"
                                    name="joint_ac1_member_id"
                                    value={joint_ac1_member_id}
                                    onChange={(e) =>
                                      setjoint_ac1_member_id(e.target.value)
                                    }
                                  >
                                    <option value={''} disabled>Select Member</option>
                                    {memberList.map((aBranchData) => (
                                      <option
                                        value={aBranchData.id}
                                        selected={
                                          aBranchData.id === joint_ac1_member_id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {aBranchData.fname} &nbsp;
                                        {aBranchData.mnane}

                                      </option>
                                    ))}
                                  </select>



                                  {formErrors.joint_ac1_member_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.joint_ac1_member_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Joint A/c Member 2
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                
                                  <select
                                    class="form-control m-b"
                                    name="joint_ac2_member_id"
                                    value={joint_ac2_member_id}
                                    onChange={(e) =>
                                      setjoint_ac2_member_id(e.target.value)
                                    }
                                  >
                                    <option value={''} disabled>Select Member</option>
                                    {memberList.map((aBranchData) => (
                                      <option
                                        value={aBranchData.id}
                                        selected={
                                          aBranchData.id === joint_ac2_member_id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {aBranchData.fname} &nbsp;
                                        {aBranchData.mnane}

                                      </option>
                                    ))}
                                  </select>



                                  {formErrors.joint_ac2_member_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.joint_ac2_member_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            
                          </div>


                          <div class="row" style={{ display: account_type === 'joint' ? '' : 'none' }} >
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                  Mode of Operation<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="mode_operation_single">
                                        <input
                                          type="radio"
                                          name="mode_of_operation"
                                          value={"single"}
                                          id={"mode_operation_single"}
                                          checked={mode_of_operation === 'single' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setmode_of_operation(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Single
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="mode_operation_joint">
                                        <input
                                          type="radio"
                                          name="mode_of_operation"
                                          id={"mode_operation_joint"}
                                          value={"joint"}
                                          checked={mode_of_operation === 'joint' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setmode_of_operation(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Jointly
                                      </label>
                                    </div>

                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="mode_operation_either">
                                        <input
                                          type="radio"
                                          name="mode_of_operation"
                                          id={"mode_operation_either"}
                                          value={"either"}
                                          checked={mode_of_operation === 'either' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setmode_of_operation(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i> Either or Survivor
                                      </label>
                                    </div>
                                  
                                    
                                  </div>
                                  {formErrors.mode_of_operation && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.mode_of_operation}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>



                        
                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <h4 class="sub-title text-center">
                              {/* SERVICE CHARGES 2*/}
                              </h4>
                            </div>
                          </div>

                        
                          <div class="row" >
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                  Nominee <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="nominee_yes">
                                        <input
                                          type="radio"
                                          name="nominee"
                                          value={"yes"}
                                          id={"nominee_yes"}
                                          checked={nominee === 'yes' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setnominee(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Yes
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="nominee_no">
                                        <input
                                          type="radio"
                                          name="nominee"
                                          id={"nominee_no"}
                                          value={"no"}
                                          checked={nominee === 'no' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setnominee(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>No
                                      </label>
                                    </div>
                                  
                                    
                                  </div>
                                  {formErrors.nominee && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.nominee}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style={{ display: nominee === 'yes' ? '' : 'none' }}  >
                          <div class="col-sm-12 col-xl-12 m-b-30">

                          {nominees.map((nomineval, index) => (
                          <div class="row" key={index} style={{ display: nominee === 'no' ? 'none' : '' }}>
                          <div class="col-sm-12"> <hr></hr></div>
                          <div class="col-sm-10">
                             <div class="row">
                                <div class="col-sm-12 col-xl-4 m-b-30">
                                    <div class="row">
                                    
                                        <div class="col-sm-12">
                                        <h6 class="">Name<span class="text-danger">*</span></h6>
                                        <input class="form-control" type="text" value={nominees[index]} onChange={(e) => {handleNomineeChange(index, e.target.value);}} 
                                            placeholder={`Nominee ${index + 1}`} />
                                        {formErrors.name && <span style={{ color: 'red' }}>{formErrors.name}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-xl-4 m-b-30">
                                    <div class="row">
                                    
                                        <div class="col-sm-12">
                                        <h6 class="text-xl-right">
                                                                Relation<span class="text-danger">*</span>
                                                            </h6>
                                        <select class="form-control"
                                                name="relation"
                                                value={relation[index]}
                                                onChange={(e) =>
                                                  handleRelation(index, e.target.value)
                                                }
                                            >
                                                <option value="">Select Relation</option>
                                                <option value="father">Father</option>
                                                <option value="mother">Mother</option>
                                                <option value="son">Son</option>
                                                <option value="daughter">Daughter</option>
                                                <option value="spouse">
                                                Spouse (Husband/ Wife)
                                                </option>
                                                <option value="husband">Husband</option>
                                                <option value="wife">Wife</option>
                                                <option value="brother">Brother</option>
                                                <option value="sister">Sister</option>
                                                <option value="daughter_in_law">
                                                Daughter in Law
                                                </option>
                                                <option value="brother_in_law">
                                                Brother in Law
                                                </option>
                                                <option value="grand_daughter">
                                                Grand Daughter
                                                </option>
                                                <option value="grand_son">Grand Son</option>
                                                <option value="nephew">Nephew</option>
                                                <option value="niece">Niece</option>
                                                <option value="other">Other</option>
                                            </select>

                                            {formErrors.relation && <span style={{ color: 'red' }}>{formErrors.relation}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-xl-4 m-b-30">
                                    <div class="row">
                                   
                                        <div class="col-sm-12">
                                        <h6 class="text-xl-right">Address<span class="text-danger">*</span> </h6>
                                          <textarea class="form-control" value={address[index]} onChange={(e) => handleAddress(index, e.target.value)}>{address[index]}</textarea>
                                          {formErrors.address && <span style={{ color: 'red' }}>{formErrors.address}</span>}
                                        </div>
                                    </div>
                                </div>
                                
                                </div>
                                </div>
                                <div class="col-sm-2"> 
                                        <a href="#a" class="text-info" onClick={() => removeNominee(index)}>Remove</a>
                                </div>
                                
                                </div>
                                  ))}


                              <div class="row">
                                <div class="col-sm-4">
                                 
                                      
                                  <h5 className="btn text-info" onClick={addNominee} style={{ display: nominee === 'no' ? 'none' : '' }}>
                                        + ADD MORE NOMINEE
                                   </h5>

                                </div>
                                <div class="col-sm-8">
                                
                                </div>
                              </div>
                            </div>
                          </div>




                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <h4 class="sub-title text-center">
                              {/* SERVICE CHARGES transaction-------------2*/}
                              </h4>
                            </div>
                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-6   m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    Pay Mode<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_cash">
                                        <input
                                          type="radio"
                                          name="paymode"
                                          value={"cash"}
                                          id={"title_cash"}
                                          checked={paymode === 'cash' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setpaymode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Cash
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_chque">
                                        <input
                                          type="radio"
                                          name="paymode"
                                          id={"title_chque"}
                                          value={"cheque"}
                                          checked={paymode === 'cheque' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setpaymode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Cheque
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3">
                                      <label for="title_online">
                                        <input
                                          type="radio"
                                          name="paymode"
                                          value={"online"}
                                          checked={paymode === 'online' ? 'checked' : ''}
                                          id={"title_online"}
                                          onChange={(e) =>
                                            setpaymode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Online Tr
                                      </label>
                                    </div>
                                    
                                  </div>
                                  {formErrors.paymode && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.paymode}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>


                            <div class="col-sm-12 col-xl-6 m-b-30">
                                    <div class="row">
                                      <div class="col-sm-5">
                                        <h6 class="text-xl-right">
                                        Transaction Date <span class="text-danger">*</span>
                                        </h6>
                                      </div>
                                      <div class="col-sm-7">
                                      <input class="form-control" type="date" value={transaction_date} onChange={(e) => settransaction_date(e.target.value) } placeholder="Enter Transaction Date"  />
                                        {formErrors.transaction_date && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.transaction_date}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                          </div>



                          <div class="row" style={{ display: paymode === 'cheque' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-4 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    Bank Name<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                <select
                                    class="form-control m-b"
                                    name="bank_name"
                                    value={bank_name}
                                    onChange={(e) =>
                                      setbank_name(e.target.value)
                                    }
                                  >
                                    <option value={''} disabled>Select bank_name</option>
                                    {bankList.map((bankdata) => (
                                      <option
                                        value={bankdata.id}
                                        selected={
                                          bankdata.id === bank_name
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {bankdata.name}

                                      </option>
                                    ))}
                                  </select>
                                  {formErrors.bank_name && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.bank_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-4 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                  Cheque No.<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={cheque_no}
                                    onChange={(e) =>
                                      setcheque_no(e.target.value)
                                    }
                                    placeholder="Enter Cheque"
                                  />
                                  {formErrors.cheque_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.cheque_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-4 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    Cheque Date<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <input
                                    class="form-control"
                                    type="date"
                                    value={cheque_date}
                                    onChange={(e) =>
                                      setcheque_date(e.target.value)
                                    }
                                    placeholder="Enter Cheque Date"
                                  />
                                  {formErrors.cheque_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.cheque_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>


                          </div>

                         
                        
                          <div class="row" style={{ display: paymode === 'online' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    Transfar date<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <input
                                    class="form-control"
                                    type="date"
                                    value={transfer_date}
                                    onChange={(e) =>
                                      settransfer_date(e.target.value)
                                    }
                                    placeholder="Enter Transfar date"
                                  />
                                  {formErrors.transfer_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.transfer_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    UTR/ Transfar No.<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={utr_no}
                                    onChange={(e) =>
                                      setutr_no(e.target.value)
                                    }
                                    placeholder="Enter Transfar No."
                                  />
                                  {formErrors.utr_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.utr_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                         

                          

                          <div class="row" style={{ display: paymode === 'online' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    Transfar Mode<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_IMPS">
                                        <input
                                          type="radio"
                                          name="transfer_mode"
                                          value={"IMPS"}
                                          id={"title_IMPS"}
                                          checked={transfer_mode === 'IMPS' ? 'checked' : ''}
                                          onChange={(e) =>
                                            settransfer_mode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>IMPS
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_VPA">
                                        <input
                                          type="radio"
                                          name="transfer_mode"
                                          id={"title_VPA"}
                                          value={"VPA"}
                                          checked={transfer_mode === 'VPA' ? 'checked' : ''}
                                          onChange={(e) =>
                                            settransfer_mode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>VPA
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3">
                                      <label for="title_NEFT">
                                        <input
                                          type="radio"
                                          name="transfer_mode"
                                          value={"NEFT/RTGS"}
                                          checked={transfer_mode === 'NEFT/RTGS' ? 'checked' : ''}
                                          id={"title_NEFT"}
                                          onChange={(e) =>
                                            settransfer_mode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>NEFT/RTGS
                                      </label>
                                    </div>
                                    
                                  </div>
                                  {formErrors.transfer_mode && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.transfer_mode}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                  Credited in Nidhi Account <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="nidhi_acc_yes">
                                        <input
                                          type="radio"
                                          name="creadited_nidhi_acc"
                                          value={"yes"}
                                          id={"nidhi_acc_yes"}
                                          checked={creadited_nidhi_acc === 'yes' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setcreadited_nidhi_acc(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Yes
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="nidhi_ac_no">
                                        <input
                                          type="radio"
                                          name="creadited_nidhi_acc"
                                          id={"nidhi_ac_no"}
                                          value={"no"}
                                          checked={creadited_nidhi_acc === 'no' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setcreadited_nidhi_acc(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>No
                                      </label>
                                    </div>
                                  
                                    
                                  </div>
                                  {formErrors.creadited_nidhi_acc && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.creadited_nidhi_acc}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                         






                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                              <button
                                class="btn btn-success btn-square"
                                type="submit"
                                disabled={submitting}
                              >
                                OPEN ACCOUNT
                              </button>{" "}
                              <a href="/account-list" class="btn btn-danger btn-square">Cancel</a>
                              &nbsp;
                              <button
                                class="btn btn-warning btn-square"
                                type="reset"
                              >
                                Reset
                              </button>
                             

                              <ToastContainer className="toast-container" />
                            </div>
                          </div>


                        </form>
                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
