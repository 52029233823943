import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "../../config";

export function getmenuSubMenuList() {
    return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + "api-user/get-menu-submenu", {
            headers: API_HEADERS,
        }).then((response) => {
            if (response.data["status"] > 0) {
                resolve(response.data["data"]);
            } else {
                reject(response.data["message"]);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

export function getAssociateList(id= ' ') {
    return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + "api-associates/get-associate-list?id="+id, {
            headers: API_HEADERS,
        }).then((response) => {
            if (response.data["status"] > 0) {
                resolve(response.data["data"]);
            } else {
                reject(response.data["message"]);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}


export function getMemberList(id= '') {
    return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + "api-member/get-member-list?member_id="+id, {
            headers: API_HEADERS,
        }).then((response) => {
            if (response.data["status"] > 0) {
                resolve(response.data["data"]);
            } else {
                reject(response.data["message"]);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

export function getCollectionCenterList(queryString = '') {
    // . condition = `member_type = 'member'`; 
    return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + "api-collcenter/get-collection-center-list?"+queryString, {
            headers: API_HEADERS,
        }).then((response) => {
            if (response.data["status"] > 0) {
                resolve(response.data["data"]);
            } else {
                reject(response.data["message"]);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

export function getCollectionCenterGroupList(queryString = '') {
    // . condition = `member_type = 'member'`; 
    return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + "api-collcenter/get-group-list?"+queryString, {
            headers: API_HEADERS,
        }).then((response) => {
            if (response.data["status"] > 0) {
                resolve(response.data["data"]);
            } else {
                reject(response.data["message"]);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

export function getCollectionCenterGroupListDetails(queryString = '') {
    // . condition = `member_type = 'member'`; 
    return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + "api-collcenter/get-group-details?"+queryString, {
            headers: API_HEADERS,
        }).then((response) => {
            if (response.data["status"] > 0) {
                resolve(response.data["data"]);
            } else {
                reject(response.data["message"]);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}


export function getActiveBranchList() {
    return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + "api-company/get-active-branch-list", {
            headers: API_HEADERS,
        }).then((response) => {
            if (response.data["status"] > 0) {
                resolve(response.data["data"]);
            } else {
                reject(response.data["message"]);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}


export function getRolePermissionList() {
    return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + "api-user/get-role-permission", {
            headers: API_HEADERS,
        }).then((response) => {
            if (response.data["status"] > 0) {
                resolve(response.data["data"]);
            } else {
                reject(response.data["message"]);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

export function getCommissionChart(id= '') {
    return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + "api-associates/get-commission-chart-list?id="+id, {
            headers: API_HEADERS,
        }).then((response) => {
            if (response.data["status"] > 0) {
                resolve(response.data["data"]);
            } else {
                reject(response.data["message"]);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}





export function getDataListBytable(table = '', condition = '',id = '') {
    // ex. condition = `member_type = 'member'`; 
    return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + "api-common/get-data?tablename="+table+"&condition="+condition+"&id="+id, {
            headers: API_HEADERS,
        }).then((response) => {
            if (response.data["status"] > 0) {
                resolve(response.data["data"]);
            } else {
                reject(new Error("No data available"));
            }
        }).catch((error) => {
            reject(error);
        });
    });
}


export function getAccountList(formData = '' ) {
    // ex. condition = `member_type = 'member'`; 
    return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + "api-common/get-account-list?"+formData, {
            headers: API_HEADERS,
        }).then((response) => {
            if (response.data["status"] > 0) {
                resolve(response.data["data"]);
            } else {
                reject(response.data["message"]);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}


export function getListArr(url, formData = '' ) {
    // ex. condition = `member_type = 'member'`; 
    return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL+url+"?"+formData, {
            headers: API_HEADERS,
        }).then((response) => {
            if (response.data["status"] > 0) {
                resolve(response.data["data"]);
            } else {
                reject(response.data["message"]);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

export function formPost(url = '', formData = '') {
    // ex. condition = `member_type = 'member'`; 
    return new Promise((resolve, reject) => {
        // axios.get(API_BASE_URL + "api-common/get-data?tablename="+table+"&condition="+condition, {
         axios.post(API_BASE_URL + url, formData, {
            headers: {
                ...API_HEADERS,
              },
        }).then((response) => {
            if (response.data["status"] > 0) {
                resolve(response.data);
            } else {
                reject(response.data["message"]);
            }
        }).catch((error) => {
            console.log(error);
            reject(error);
        });
    });
}




