import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { dataDecrypt,
    dataEncript,
     } from "./../../../helper/commonHelpers";

function CompanyDetails() {
  // const navigate = useNavigate();
  const { promoterID }   = useParams();
  const [submitting, setSubmitting] = useState(false);
  // const [stateList, setStateList] = useState([]);
  const [promoterhDetails, setpromoterhDetails] = useState([]);

  const [formErrors, setFormErrors] = useState({
    is_nomini: "",
    name: "",
    relation: "",
    shareholding: "",
    address: "",
  });



  // PROMOTER INFO 
  const [is_nomini, setis_nomini] = useState('');
  const [name, setname] = useState(['']);
  const [relation, setrelation] = useState(['']);
  const [shareholding, setshareholding] = useState(['']);
  const [address, setaddress] = useState(['']);

  const [nominees, setNominees] = useState(['']);




  useEffect(() => {
    
  axios
  .get(API_BASE_URL + "api-company/get-promoter-by-id?promoter_id="+dataDecrypt(promoterID), {
    headers: API_HEADERS,
  })
  .then((response) => {
    setpromoterhDetails(response.data['data']);

    

    if (response.data["status"] > 0) {

      setis_nomini(response.data['data'].is_share_nominee);

      if(response.data['data'].nominee.length > 0)
      {
        const nameArray = response.data['data'].nominee.map(nomineeData => nomineeData.name); 
        const relationArray = response.data['data'].nominee.map(nomineeData => nomineeData.relation); 
        const share_holdingArray = response.data['data'].nominee.map(nomineeData => nomineeData.share_holding); 
        const addressArray = response.data['data'].nominee.map(nomineeData => nomineeData.address); 

          setname(nameArray);
          setNominees(nameArray);
          setrelation(relationArray);
          setshareholding(share_holdingArray);
          setaddress(addressArray);

      }

    } else {
        toast.error(response.data["message"]);
    }


  })
  .catch((error) => {
    toast.error('Failed to fetch DETAILS list!');
  });

  }, [promoterID]);

  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};

    const formData = {
      share_holding_id:'',
      is_nominee: is_nomini,
      name: name,
      relation: relation,
      share_holding: shareholding,
      address: address,

      promoter_id:dataDecrypt(promoterID)
    };


   

   

    // validate form
   

      if (!formData.is_nominee) {
        errors.is_nomini = 'Nominee is required.';
      }
      if (!formData.name) {
        errors.name = 'Name is required.';
      }
      if (!formData.relation) {
        errors.relation = 'Relation is required.';
      }
      if (!formData.share_holding) {
        errors.shareholding = 'Share Holding is required.';
      }

      if (!formData.address) {
        errors.address = 'Share Holding is required.';
      }


      console.log(formData);

    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      axios.post(API_BASE_URL + "api-company/update-promoter-share-holdings-nominee", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/view-promoter/"+dataEncript(promoterhDetails.id);
            }, 2700);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 300);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };


  


  const addNominee = () => {
       setNominees([...nominees, '']);
  };

  const removeNominee = (index) => {
    const updatedNominees = [...nominees];
    updatedNominees.splice(index, 1);
    setNominees(updatedNominees);
  };

  const handleNomineeChange = (index, value) => {
    const updatedNominees = [...nominees];
    updatedNominees[index] = value;
    setNominees(updatedNominees);
    setname(updatedNominees);
  };

  const handleRelation = (index, value) => {
    const updatedRelation = [...relation];
    updatedRelation[index] = value;
    setrelation(updatedRelation);
  };


  //shareholding
  const handleshareholding = (index, value) => {
    const updatedShareholding = [...shareholding];
    updatedShareholding[index] = value;
    setshareholding(updatedShareholding);
  };

  const handleAddress = (index, value) => {
    const updatedAddress = [...address];
    updatedAddress[index] = value;
    setaddress(updatedAddress);
  };




  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>{promoterhDetails && promoterhDetails.fname && (
                                    <span>{promoterhDetails.fname}</span>
                                    )}

                                    {promoterhDetails && promoterhDetails.mname && (
                                    <span>{promoterhDetails.mname}</span>
                                    )}   <small>Promoter</small> </h4> 
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/promoter-list">Promoter List</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href={`/view-promoter/${dataEncript(promoterhDetails.id)}`} >{promoterhDetails && promoterhDetails.fname && (
                                    <span>{promoterhDetails.fname}</span>
                                    )}

                                    {promoterhDetails && promoterhDetails.mname && (
                                    <span>{promoterhDetails.mname}</span>
                                    )}</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">Share Holding Nominee's Info</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12"></div>

                  <div class="col-sm-12">
                    <div class="card">
                      <div class="card-header">
                        <h4>Update Share Holding Nominee's Info</h4>
                        <hr></hr>
                        {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                      </div>

                      <div class="card-block">
                        <form onSubmit={updateDetails}>

                        <div class="row">
                            <div class="col-sm-12 col-xl-6 ">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Nominee<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <div class="form-radio row ml-1" >
                                    <div class="radio radio-inline col-xs-3">
                                      <label for="is_nomini_yes">
                                        <input
                                          type="radio"
                                          name="is_nomini"
                                          value={'yes'}
                                          id={"is_nomini_yes"}
                                          checked={is_nomini === 'yes' ? 'checked' : ''} 
                                          onChange={(e) =>
                                            setis_nomini(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Yes
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3">
                                      <label for="gender_female">
                                        <input
                                          type="radio"
                                          name="gender"
                                          value={"no"}
                                          checked={is_nomini === 'no' ? 'checked' : ''} 
                                          id={"gender_female"}
                                          onChange={(e) =>
                                            setis_nomini(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>No.
                                      </label>
                                    </div>
                                   
                                  </div>
                                  {formErrors.is_nomini && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.is_nomini}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                           
                          </div>




                          {nominees.map((nominee, index) => (
                          <div class="row" key={index} style={{ display: is_nomini === 'no' ? 'none' : '' }}>
                          <div class="col-sm-12"> <hr></hr></div>
                          <div class="col-sm-10">
                             <div class="row">
                                <div class="col-sm-12 col-xl-6 m-b-30">
                                    <div class="row">
                                    <div class="col-sm-5">
                                        <h6 class="text-xl-right">Name<span class="text-danger">*</span></h6>
                                        </div>
                                        <div class="col-sm-7">
                                        <input class="form-control" type="text" value={nominees[index]} onChange={(e) => {handleNomineeChange(index, e.target.value);}} 
                                            placeholder={`Nominee ${index + 1}`} />
                                        {formErrors.name && <span style={{ color: 'red' }}>{formErrors.name}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-xl-6 m-b-30">
                                    <div class="row">
                                    <div class="col-sm-5">
                                        <h6 class="text-xl-right">
                                                                Relation<span class="text-danger">*</span>
                                                            </h6>
                                        </div>
                                        <div class="col-sm-7">
                                        <select class="form-control"
                                                name="relation"
                                                value={relation[index]}
                                                onChange={(e) =>
                                                  handleRelation(index, e.target.value)
                                                }
                                            >
                                                <option value="">Select Relation</option>
                                                <option value="father">Father</option>
                                                <option value="mother">Mother</option>
                                                <option value="son">Son</option>
                                                <option value="daughter">Daughter</option>
                                                <option value="spouse">
                                                Spouse (Husband/ Wife)
                                                </option>
                                                <option value="husband">Husband</option>
                                                <option value="wife">Wife</option>
                                                <option value="brother">Brother</option>
                                                <option value="sister">Sister</option>
                                                <option value="daughter_in_law">
                                                Daughter in Law
                                                </option>
                                                <option value="brother_in_law">
                                                Brother in Law
                                                </option>
                                                <option value="grand_daughter">
                                                Grand Daughter
                                                </option>
                                                <option value="grand_son">Grand Son</option>
                                                <option value="nephew">Nephew</option>
                                                <option value="niece">Niece</option>
                                                <option value="other">Other</option>
                                            </select>

                                            {formErrors.relation && <span style={{ color: 'red' }}>{formErrors.relation}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-xl-6 m-b-30">
                                    <div class="row">
                                    <div class="col-sm-5">
                                        <h6 class="text-xl-right">Address<span class="text-danger">*</span> </h6>
                                        </div>
                                        <div class="col-sm-7">
                                          <textarea class="form-control" value={address[index]} onChange={(e) => handleAddress(index, e.target.value)}>{address[index]}</textarea>
                                          {formErrors.address && <span style={{ color: 'red' }}>{formErrors.address}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-xl-6 m-b-30">
                                    <div class="row">
                                    <div class="col-sm-5">
                                        <h6 class="text-xl-right">
                                        Share Holding (%)<span class="text-danger">*</span>
                                                            </h6>
                                        </div>
                                        <div class="col-sm-7">
                                        <input
                                                                class="form-control"
                                                                type="number"
                                                                value={shareholding[index]}
                                                                onChange={(e) => handleshareholding(index, e.target.value) }
                                                                placeholder="Enter Share Holding (%)"
                                                            />
                                                            {formErrors.shareholding && <span style={{ color: 'red' }}>{formErrors.shareholding}</span>}
                                        </div>
                                    </div>
                                </div>
                                </div>
                                </div>
                                <div class="col-sm-2"> 
                                        <a href="#a" class="text-info" onClick={() => removeNominee(index)}>Remove</a>
                                </div>
                                
                                </div>
                                  ))}




                                <div class="row">
                                    

                                    <div>
                                    

                                    <h5 className="btn text-info" onClick={addNominee} style={{ display: is_nomini === 'no' ? 'none' : '' }}>
                                        + ADD MORE NOMINEE
                                    </h5>

                                    </div>


                                </div>


                          
                          




                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                              <button
                                class="btn btn-success btn-square"
                                type="submit"
                                disabled={submitting}
                              >
                                Update
                              </button>{" "}
                              &nbsp;
                              <a href={`/view-promoter/${dataEncript(promoterhDetails.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                             

                              <ToastContainer className="toast-container" />
                            </div>
                          </div>


                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
