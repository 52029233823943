// Function to capitalize a string
import Swal from 'sweetalert2';
import numeral from 'numeral';

export function capitalizeString(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  
  // Function to convert an array of strings to uppercase
  export function convertArrayToUpperCase(arr) {
    return arr.map(item => item.toUpperCase());
  }
  
  // Function to check if a number is even
  export function isEvenNumber(number) {
    return number % 2 === 0;
  }


  export function checkRolePermission(permissionType, navigateFunction) {
    let userData = JSON.parse(localStorage.getItem('adminLogin'));
    
    // console.log(userData);
    // console.log(userData.data[permissionType]);


    if (userData && userData.data && userData.data[permissionType] === 'no') {
      navigateFunction('/dashboard', {
        state: {
          successMessage: 'Sorry! you have not permission.',
          errType: 'Err'
        }
      });
    }
  }

 

    // Function to check if a number is even
    export function htmlAlert(type, message) {
        // console.log(location);

        if(message !==null && message.length > 0){

        if (type === 'S' || type === 'Success' || type === 'SUCCESS') {
          return (
            <div className="alert alert-success alert-dismissible">
              <button type="button" className="close" data-dismiss="alert">&times;</button>
              <strong>Success!</strong> {message}
            </div>
          );
        }
        else{
            return (
                <div className="alert alert-danger alert-dismissible">
                  <button type="button" className="close" data-dismiss="alert">&times;</button>
                  <strong>Warning!</strong> {message}
                </div>
              );
        }

      }
      
}

export function themLoader() {
  return (
    <div class="theme-loader">
        <div class="ball-scale">
            <div class="contain">
                <div class="ring">
                    <div class="frame"></div>
                </div>
                <div class="ring">
                    <div class="frame"></div>
                </div>
                <div class="ring">
                    <div class="frame"></div>
                </div>
                <div class="ring">
                    <div class="frame"></div>
                </div>
                <div class="ring">
                    <div class="frame"></div>
                </div>
                <div class="ring">
                    <div class="frame"></div>
                </div>
                <div class="ring">
                    <div class="frame"></div>
                </div>
                <div class="ring">
                    <div class="frame"></div>
                </div>
                <div class="ring">
                    <div class="frame"></div>
                </div>
                <div class="ring">
                    <div class="frame"></div>
                </div>
            </div>
        </div>
    </div>
  );

}


   // // Function to check if a number is even
    export function deleteConfirmation(message) {
      return new Promise((resolve) => {
        Swal.fire({
          title: 'Are you sure?',
          text: '' + message,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, Confirm!',
          cancelButtonText: 'Cancel'
        }).then((result) => {
          if (result.isConfirmed) {
            // Swal.fire('Deleted!', 'Employee has been deleted.', 'success');
            resolve(true);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            // Swal.fire('Cancelled', 'Employee deletion was cancelled.', 'info');
            resolve(false);
          }
        });
      });
    }

   

    export function getCurrentDate(message) {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Adding leading zero if needed
      const day = String(today.getDate()).padStart(2, '0'); // Adding leading zero if needed
      return `${year}-${month}-${day}`;
    }


    export function getCurrentMonthStartDate(){
      const today = new Date();
      return  formatDate((new Date(today.getFullYear(), today.getMonth(), 1)), 'YMD');
    // return startDate.toISOString().split('T')[0];
    }


    export function formatDate(inputDate, type = '') {
      const date = new Date(inputDate);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      if(type==='YMD')
      {
        return `${year}-${month}-${day}`;
      }
      else
      {
        return `${day}-${month}-${year}`;
      }
      
    }
    

    export function dataEncript(idToEncrypt) {
      // const encryptedId = CryptoJS.AES.encrypt(idToEncrypt.toString(), apiKey).toString();
      const encryptedId = btoa(idToEncrypt);
      return encryptedId;
    }

    export function dataDecrypt(encryptedId) {
      // const decryptedBytes = CryptoJS.AES.decrypt(encryptedId, apiKey);
      // const decryptedId = decryptedBytes.toString(CryptoJS.enc.Utf8);
      const decryptedId = atob(encryptedId);
      return decryptedId;
    }
      

    export function FirstLetterCapitalize(str) {
      // return (FirstLetterCapitalize)? str.charAt(0).toUpperCase() + str.slice(1):'';
      return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';

    }


    export function infoAlert(message, message1 = '') {
      return new Promise((resolve) => {
        Swal.fire({
          text: '' + message+' '+message1,
          confirmButtonText: 'Ok',
        }).then((result) => {
          if (result.isConfirmed) {
            // Swal.fire('Deleted!', 'Employee has been deleted.', 'success');
            resolve(true);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            // Swal.fire('Cancelled', 'Employee deletion was cancelled.', 'info');
            resolve(false);
          }
        });
      });
    }

    export function formattedAmount(amount, nosymbol = '') {
      // amount = amount?amount:0;

      if(nosymbol==='no')
      {
        return ' '+numeral(amount).format('₹ 0,0.00');
      }else
      {
        return '₹ '+numeral(amount).format('₹ 0,0.00');
      }

 
    }

  // Other helper functions...
  