import React, { useState, useEffect } from 'react';
import DashboardLayout from './../../dashboardLayout';

import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
// import $ from 'jquery';





import {
      formatDate,
      dataEncript
    } from './../../../helper/commonHelpers'; 

function AdminLayout() {
   const [promoterList, setpromoterList] = useState([]);

    useEffect(() => {
            axios.get(API_BASE_URL+'api-company/get-promoter-share-holdings-list', { headers: API_HEADERS })
            .then(response => {
                  if (response.data["status"] > 0) {
              setpromoterList(response.data['data']);
                  }
                  // console.log(response.data['data']);
            })
            .catch(error => {
              console.error('Failed to fetch role list list!', error);
            });
  
    }, []);






    useEffect(() => {
      const timeout = setTimeout(() => {
        if (window.tableDomJquery) {
          window.tableDomJquery();
        }
      }, 1000); // Timeout of 3000 milliseconds (3 seconds)
  
      return () => clearTimeout(timeout); // Clear the timeout on component unmount
    }, [promoterList]);




  return (
    <DashboardLayout>
<div class="pcoded-content">
      <div class="pcoded-inner-content">

            <div class="main-body">
                  <div class="page-wrapper">

                        <div class="page-header">
                    <div class="row align-items-end">
                        <div class="col-lg-8">
                            <div class="page-header-title">
                                <div class="d-inline">
                                    <h4>Promoters Share Holding Details  
                                    <a href="/add-share-holdings"  title='Add New Branch'> <i class="fa fa-plus btn btn-info float-right ml-4 btn-xs"></i> </a>
                                           </h4>
                                    {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="page-header-breadcrumb">
                                <ul class="breadcrumb-title">
                                    <li class="breadcrumb-item" style={{float:'left'}}>
                                        <a href="/dashboard"> <i class="feather icon-home"></i> </a>
                                    </li>
                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="#!">Promoters Share Holding Details</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


            <div class="page-body">

                  <div class="card">
                        {/* <div class="card-header">
                              <h5>DOM/Jquery</h5>
                              <span>Events assigned to the table can be exceptionally useful for user
                                    interaction, however you must be aware that DataTables will add and
                                    remove rows from the DOM as they are needed (i.e. when paging only
                                    the visible elements are actually available in the DOM). As such,
                              this can lead to the odd hiccup when working with events.</span>
                        </div> */}
                        <div class="card-block">
                              <div class="table-responsive dt-responsive">
                                    <table class="table dtable table-striped table-bordered nowrap" style={{width:'100%'}}>
                                          <thead>
                                                <tr>
                                                      <th>SR.NO</th>
                                                      <th>PROMOTER</th>
                                                      <th>FIRST DISTINCTIVE NO.</th>
                                                      <th>LAST DISTINCTIVE NO.</th>
                                                      <th>TOTAL SHARES HELD</th>
                                                      <th>SHARE NOMINAL VAL.</th>
                                                      <th>TOTAL VAL.</th>
                                                      <th>ALLOTMENT DATE</th>
                                                      <th>Actions</th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                             
                                          


                                          {
                                                promoterList.length > 0 ? (
                                                  promoterList.map((branchData, index) => (
                                                      <tr class="gradeX">
                                                      <td>{index + 1}</td>
                                                      <td>{branchData.promoter_id?branchData.promoter_name:''}</td>
                                                      <td>{branchData.first_distinctive_no?branchData.first_distinctive_no:''}</td>
                                                      <td>{branchData.last_distinctive_no?branchData.last_distinctive_no:''}</td>
                                                      <td>{branchData.total_share_held?branchData.total_share_held:''}</td>

                                                      <td>{branchData.share_nominal_value?branchData.share_nominal_value:''}</td>
                                                      <td>{branchData.total_shares_value?branchData.total_shares_value:''}</td>
                                                      <td>{branchData.allotment_date?formatDate(branchData.allotment_date):''}</td>
                                                   
                                                      <td class="center">
                                                            <a href={`/view-share-holdings/${dataEncript(branchData.id)}`} class='font-weight-normal'><i class="fa fa-eye"></i></a>&nbsp;
                                                            <a href={`/edit-share-holdings/${dataEncript(branchData.id)}`}  class='font-weight-normal'> <i class="fa fa-edit"></i> </a>
                                                            
                                                      </td>
                                                </tr>
                                                  ))
                                                ) : (
                                                  <tr>
                                                    <td colSpan="9">No data available</td>
                                                  </tr>
                                                )
                                              }

                                          
                                               
                                          </tbody>
                                        
                                    </table>
                              </div>
                        </div>
                  </div>






            </div>

      </div>
</div>

<div id="styleSelector">
</div>
</div>
</div>
    </DashboardLayout>
  );
}

export default AdminLayout;
