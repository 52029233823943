import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly

import { useParams } from 'react-router-dom'; 


import $ from 'jquery';
import 'dropify/dist/css/dropify.min.css';
import 'dropify';


  import { toast, ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

  import {
    dataDecrypt,
    dataEncript,
    deleteConfirmation
  } from './../../../helper/commonHelpers'; 


function CompanyDetails() {
    // const navigate = useNavigate();
    const { userID }   = useParams();
    const [submitting, setSubmitting] = useState(false);
    const [userDetails, setuserDetails] = useState([]);
    const [userProfile, setuserProfile] = useState([]);


    useEffect(() => {
        

          axios.get(API_BASE_URL+'api-user/get-user-details?userid='+dataDecrypt(userID), {
             headers: API_HEADERS 
            }).then(response => {
                if (response.data["status"] > 0) {
                    setuserDetails(response.data["data"]);
                }
                
          }).catch(error => {
            toast.error("Failed to fetch data!");
          });

         

          setTimeout(() => {
            $('.dropify').dropify();
          }, 2000);


      }, [userID]);



      const handleLogoImageChange = (e) => {
        const file = e.target.files[0]; 
        setuserProfile(file); 
      };


      const changePhoto = (e) => {
        e.preventDefault();
        setSubmitting(true);
        const formData = new FormData();
        formData.append('userid', dataDecrypt(userID)); 
        if (userProfile) {
          formData.append('user_photo', userProfile); 
        }
        if (userProfile.length===0) {
          toast.error('Please choose Photo image!');
          setSubmitting(false);
        }
        else{
          axios.post(API_BASE_URL + 'api-user/update-user-photo', formData, {
            headers: {
              ...API_HEADERS,
              'Content-Type': 'multipart/form-data', // Set content type for FormData
            },
          })
          .then(response => {
            if (response.data['status'] > 0) {
              toast.success(response.data['message']);
              setTimeout(() => {
                window.location.reload();
              }, 2300);
  
            } else {
              toast.error(response.data['message']);
            }
          })
          .catch(error => {
            toast.error('Failed to save data');
          }).finally(() => {
            setTimeout(() => {
              setSubmitting(false);
            }, 300);
          }); 
        }
      };

      const sendUserCredential = async (e) => {
        e.preventDefault();
            let username = userDetails.fname+' '+userDetails.lname;
            const confirmed = await deleteConfirmation('Are You Sure to Reset Credentials and Resend SMS to '+username+' ?');
            if(confirmed===true)
            {
                toast.success('Your credentials has been sent on registered mobile number.');
    
                //   setTimeout(() => {
                //         window.location.reload();
                //         }, 2800);
            }
      };


      const userAccountLockUnlock = async (e) => {
        e.preventDefault();
            let username = userDetails.fname+' '+userDetails.lname;

            const formData = {
                tablename:'tbl_user',  // table name
                 where_coloumn_name:'id', // which collumn name you want to  
                 where_value:dataDecrypt(userID), // column value 
                 set_coloumn_name:'account_login_locked', // which collumn name you want to update
                 set_value:(userDetails.account_login_locked==='no')?'yes':'no', // collumn value
             };

            let locked_status = (userDetails.account_login_locked==='yes')?'Un-lock':'Lock';
            
            const confirmed = await deleteConfirmation('Are you sure to '+ locked_status +' Account '+username+' ?');
            if(confirmed===true)
            {
                axios.post(API_BASE_URL + "api-common/update-status", formData, {
                headers: {
                    ...API_HEADERS,
                },
                }).then((response) => {
                if (response.data["status"] > 0) {
                    toast.success(username + ' account '+locked_status+' successfully.');
                    setTimeout(() => {
                      window.location.reload();
                    }, 1200);
                } else {
                    toast.error(response.data["message"]);
                }
                })
                .catch((error) => {
                toast.error("Failed to submition!");
                })
                .finally(() => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
                }); // close query hare
              
            }
      };


  



  return (
    <DashboardLayout>




     <div class="pcoded-content">
    <div class="pcoded-inner-content">
        <div class="main-body">
            <div class="page-wrapper">
                <div class="page-header">
                    <div class="row align-items-end">
                        <div class="col-lg-6">
                            <div class="page-header-title">
                                <div class="d-inline">
                                    <h4>
                                    {userDetails && userDetails.fname && (
                                    <>{userDetails.fname}</>
                                    )}
                                    
                                    {userDetails && userDetails.lname && (
                                    <>{userDetails.lname}</>
                                    )}
                                    <small> User</small></h4>
                                    {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="page-header-breadcrumb">
                                <ul class="breadcrumb-title">
                                    <li class="breadcrumb-item" style={{float:'left'}}>
                                        <a href="/dashboard"> <i class="feather icon-home"></i> </a>
                                    </li>
                                    <li class="breadcrumb-item" style={{ float: "left" }}>
                                        <a href="/users-management">Users</a>
                                        </li>

                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="#!">
                                    {userDetails && userDetails.fname && (
                                    <>{userDetails.fname}</>
                                    )}
                                    
                                    {userDetails && userDetails.lname && (
                                    <>{userDetails.lname}</>
                                    )}
                                        </a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="page-body">
                    <div class="row">

                    <div class="col-sm-12">
                    

                                    </div>




                      <div class="col-sm-4">
                            <div class="card" style={{borderTop:'3px solid red'}}>
                            <div class="card-header">
                                    <h5>User Profile</h5>
                                </div>
                                <div class="card-block">
                                <form  onSubmit={changePhoto}> 
                                 <div class="row">
                                    <div class="col-sm-12 col-xl-12 m-b-30">
                                    <input
                                    class="form-control"
                                    type="file"
                                    className="dropify"
                                    onChange={handleLogoImageChange} 
                                    data-default-file={userDetails?.user_photo ?API_BASE_URL + userDetails.user_photo: ''}

                                  />

                                        </div>
                                    </div>

                                    <div class="row">
                                <div class="col-sm-12 col-xl-12 m-b-10 m-t-10 text-center">
                                    <button class="btn btn-warning btn-square" type="submit" disabled={submitting} >
                                    Change Photo
                                    </button>
                                </div>
                                </div>

                                    </form>
                                </div>

                            </div>
                        </div>







                        <div class="col-sm-8">
                            <div class="card" style={{borderTop:'3px solid green'}}> 
                                <div class="card-header">
                                    <h5> </h5>


                           <span style={{ float: "right" }}>
                           

                        <a
                          class="badge badge-warning mr-1 "
                          href={`/edit-user/${dataEncript(userDetails.id)}`}
                          title="Edit"
                        >
                           <i class="fa fa-print"></i> ID CARD
                        </a>

                        <a
                          class="badge badge-success mr-1 "
                          href={`/edit-user/${dataEncript(userDetails.id)}`}
                          title="Edit"
                        >
                           <i class="fa fa-print"></i> 
                        </a>

                        <a
                            class="badge badge-info mr-1"
                            href={`/edit-user/${dataEncript(userDetails.id)}`}
                            title="Edit"
                            >
                           <i class="feather icon-edit"></i>
                        </a>  

                        <a
                          class="badge badge-default mr-1"
                          href={`/reset-user-password/${dataEncript(userDetails.id)}`}
                          title="Edit"
                        >
                           <i class="fa fa-refresh"></i>
                        </a>

                        <a
                          class="badge badge-default mr-1"
                          href="#!"
                          title="Lock/Unlock Account"
                          onClick={(e) => {userAccountLockUnlock(e);}}
                        >
                           <i class={`fa ${userDetails.account_login_locked === 'yes' ? 'fa-lock' : 'fa-unlock'}`}></i>

                        </a>

                        <a
                          class="badge badge-default "
                          href="#!"
                          title="Resend Credential"
                          onClick={(e) => {sendUserCredential(e);}}
                        >
                           <i class="fa fa-sign-out"></i>
                        </a>


                           </span>

                       

                                    {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                                </div>

                                <div class="card-block">
                                <form > 
                                 <div class="row">
                                    <div class="col-sm-12 col-xl-12 m-b-30">
                                           
                                    <table class="table table-striped table-bordered nowrap">
                                        <tbody>
                                        <tr>
                                            <th>Employee Profile</th>
                                            <td>{userDetails && userDetails.emp_profile && (
                                                <>{userDetails.emp_profile}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Designation</th>
                                            <td>{userDetails && userDetails.designation && (
                                                <>{userDetails.designation}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Login User Name</th>
                                            <td>{userDetails && userDetails.login_user_name && (
                                                <>{userDetails.login_user_name}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Name</th>
                                            <td>{userDetails && userDetails.fname && (
                                                <>{userDetails.fname}</>
                                                )} 
                                                
                                                {userDetails && userDetails.lname && (
                                                <>{userDetails.lname}</>
                                                )}
                                                </td>
                                            </tr>
                                            <tr>
                                            <th>Email</th>
                                            <td>{userDetails && userDetails.email && (
                                                <>{userDetails.email}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Roles</th>
                                            <td>{userDetails && userDetails.permissions && (
                                                <>{userDetails.permissions}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Branches</th>
                                            <td>{userDetails && userDetails.branch && (
                                                <>{userDetails.branch}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Contact No.</th>
                                            <td>{userDetails && userDetails.mobile && (
                                                <>{userDetails.mobile}</>
                                                )}</td>
                                            </tr>
                                            <tr>
                                            <th>Active</th>
                                            <td>
                                                      {userDetails && userDetails.user_active==='yes' && (
                                                            <label class="label label-success">Yes</label>
                                                            )}
                                                            {userDetails && userDetails.user_active==='no' && (
                                                            <label class="label label-danger">No</label>
                                                            )}
                                            </td>
                                            </tr>
                                            <tr>
                                            <th>Account Login Locked</th>
                                            <td>{userDetails && userDetails.account_login_locked==='yes' && (
                                                            <label class="label label-success">Yes</label>
                                                            )}
                                                            {userDetails && userDetails.account_login_locked==='no' && (
                                                            <label class="label label-danger">No</label>
                                                            )}</td>
                                            </tr>
                                            <tr>
                                            <th>Login on Holidays</th>
                                            <td>{userDetails && userDetails.login_on_holidays==='yes' && (
                                                            <label class="label label-success">Yes</label>
                                                            )}
                                                            {userDetails && userDetails.login_on_holidays==='no' && (
                                                            <label class="label label-danger">No</label>
                                                            )}</td>
                                            </tr>
                                            <tr>
                                            <th>Search All Accounts (Yes) / Only Assigned (No)</th>
                                            <td>{userDetails && userDetails.searchable_account==='yes' && (
                                                            <label class="label label-success">Yes</label>
                                                            )}
                                                            {userDetails && userDetails.searchable_account==='no' && (
                                                            <label class="label label-danger">No</label>
                                                            )}</td>
                                            </tr>
                                            <tr>
                                            <th>Back Date Entry Allowed Days</th>
                                            <td>{userDetails && userDetails.back_date_entry_days && (
                                                <>{userDetails.back_date_entry_days}</>
                                                )}</td>
                                            </tr>


                                        </tbody>
                                    </table>


                                        </div>
                                    </div>



                                        

                                    

                                    

                                        

                                    <div class="row">
                                        <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                                             <ToastContainer className="toast-container" />
                                        </div>
                                     </div>



                                    </form>
                                </div>


                            </div>




                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="styleSelector"></div>
    </div>
</div>

    </DashboardLayout>
  );
}

export default CompanyDetails;