import React, { useState, useEffect } from "react";
import DashboardLayout from "../../dashboardLayout";
// import axios from "axios";
// import { API_BASE_URL, API_HEADERS } from "../../../../config"; // Importing constants directly

import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

// import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {  dataDecrypt, dataEncript, FirstLetterCapitalize } from "../../../helper/commonHelpers";
import {  getCommissionChart } from '../../../helper/listModal';

//formatDate

function CompanyDetails() {
  const { chartID }   = useParams();
  const [details, setdetails] = useState([]);
  const[tenure, settenure] = useState(0);
  const[chart_type, setchart_type] = useState('');

  const [chart_model, setchart_model] = useState([]);
  const [rank_total, setrank_total] = useState([]);



  
  

  useEffect(() => {
    getCommissionChart(dataDecrypt(chartID))
      .then(data => {
        console.log(data);
        setdetails(data);
        settenure(data.tenure ? parseInt(data.tenure) : 0);
        setchart_type(data.chart_type);
        setchart_model(data.chart_model);
  
        // Calculate new_total_val and update rank_total
        const updated_m_total = [];
        for (let m_index = 0; m_index < (data.tenure ? parseInt(data.tenure) : 0); m_index++) {
          let new_total_val = data.chart_model.reduce((total, ldata) => {
            let value = ldata['m' + m_index] ? parseFloat(ldata['m' + m_index]) : 0;
            return total + value;
          }, 0);
          updated_m_total[m_index] = new_total_val;
        }
        setrank_total(updated_m_total);
      })
      .catch(error => {
        // Handle errors
        console.error('Error fetching commission chart:', error);
      });
  }, [chartID]); 
  



  

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>  Commission Chart -  

                        {details && details.chart_name && (
                                    <>{details.chart_name}</>
                                    )}  

                        </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                            <a href="/associate-advisor-commission-charts">Commission Chart </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                            <a href={`/associate-advisor-commission-charts-view/${dataEncript(details.id)}`}>{details && details.chart_name && ( <>{details.chart_name}</> )} </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> View</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                 
                  <div class="col-sm-12">
                    <div class="card" style={{borderTop:'3px solid red'}} >

                    <div class="card-header">
                    <a style={{ float: "right" }} class="badge badge-info" href={`/associate-advisor-commission-charts-edit/${dataEncript(details.id)}`} title="Edit" > <i class="feather icon-edit"></i> Edit </a>
                    </div>
                        {/* <h4 class="m-4">CHANGE ACCOUNT INFO</h4> */}
                       <hr class="p-0 m-0"/>

                     

                      <div class="card-block">
                      <div class="table-responsive dt-responsive">
                      <table class="table dtable table-striped table-bordered nowrap">
                        <tbody>


                        <tr>
                            <th>Name</th>
                            <td> {details && details.chart_name && (
                                <> {FirstLetterCapitalize(details.chart_name)}</>
                                )}</td>
                        </tr>

                        <tr>
                            <th>Chart Type	</th>
                            <td> {details && details.chart_type && (
                                <> {FirstLetterCapitalize(details.chart_type)}</>
                                )}</td>
                        </tr>

                        
                        <tr>
                            <th>Commission Type	</th>
                            <td> {
                                details && (
                                    details.commission_type === 'INR' ? (
                                    <>INR(₹)</>
                                    ) : details.commission_type === 'no' ? (
                                      <>PERCENT(%)</>
                                    ) : null
                                )
                                }</td>
                        </tr>

                        <tr>
                            <th>Payout Type	</th>
                            <td> {details && details.payout_type && (
                                <> {FirstLetterCapitalize(details.payout_type)}</>
                                )}</td>
                        </tr>

                        <tr>
                            <th>Tenure (Months)		</th>
                            <td> {details && details.tenure && (
                                <> {FirstLetterCapitalize(details.tenure)}</>
                                )}</td>
                        </tr>


                        </tbody>
                    </table>
                    </div>

                      </div>



                    </div>
                  </div>


                  {/* -------------------------------------- */}

                  <div class="col-sm-12">
                    <div class="card" style={{borderTop:'3px solid green'}} >

                       <hr class="p-0 m-0"/>

                      <div class="card-block">
                      <div class="table-responsive dt-responsive">
                      <table class="table dtable table-striped table-bordered nowrap">
                                  <thead>

                                    <tr>
                                        <th>#</th>
                                        <th>RANK/&nbsp;MONTHS</th>

                                        { 
                                        chart_type && (
                                          chart_type !== 'Saving Account (Opening Incentive)' ? (
                                            
                                            <>
                                              {[...Array(tenure)].map((_, index) => (
                                                <th key={index}>{index + 1} M</th>
                                              ))}
                                            </>

                                            ):  <th>Account Opening Incentive on 1st Transaction</th>
                                          )
                                        }
                                  </tr>


                                  </thead>

                                    <tbody>

                                    {
                                          chart_model.length > 0 ? (
                                            chart_model.map((branchData, index) => (

                                              <>
                                            <tr class="gradeX">
                                                <td>{index + 1}</td>
                                                <td> {branchData.name?FirstLetterCapitalize(branchData.name):''}  </td>

                                                {[...Array(tenure)].map((_, index1) => (
                                                       <td>
                                                        <input 
                                                          className="form-control" 
                                                          type="text" 
                                                          value={branchData && branchData['m' + index1] && (
                                                            branchData['m' + index1]
                                                          )}

                                                          style={{background: 'none',border: 'none'}}
                                                        />
                                                    </td>
                                              ))}
                                              
                                           </tr>

                                           </>

                                            ))
                                          ) : (
                                            <>
                                            </>
                                          )
                                        }

                                         <tr class="gradeX">
                                                <td>#</td>
                                                <td>Total</td>

                                                {[...Array(tenure)].map((_, index1) => (
                                                   <td>
                                                      <input class="form-control" type="text" value={rank_total[(index1)]} readOnly  style={{background: 'none',border: 'none'}} />
                                                    </td>
                                                 ))}
                                              
                                           </tr>

                                    </tbody>
                                </table>
                            </div>
                            </div>

                    </div>
                  </div>



                </div>


                

              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
          {/* <ToastContainer className="toast-container" /> */}
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
