import React, { useState, useEffect, useRef } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly


import $ from 'jquery';
import 'dropify/dist/css/dropify.min.css';
import 'dropify';



import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "components/admin/membermanagement/member/MemberCss.css";

import './styles.css'; 



import 'select2/dist/css/select2.min.css';
import 'select2';


// import { getCurrentDate } from "./../../../helper/commonHelpers";

function CompanyDetails() {
  // const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  // const [stateList, setStateList] = useState([]);
  const [activeBranchList, setactiveBranchList] = useState([]);
  const [roleList, setroleList] = useState([]);
  const [associateListArr, setassociateListArr] = useState([]);


  const [formErrors, setFormErrors] = useState({
    membership_type: "",
    branch_id: "",
    enroll_date: "",
    fname: "",
    dob: "",
    mobile: "",
    state: "",
    country: "",
    net_fee_collect: ""


  });

  const[membership_type, setmembership_type] = useState('regular');
  const[associate_id, setassociate_id] = useState('');
  const[group_id, setgroup_id] = useState('');
  const[branch_id, setbranch_id] = useState('');
  const[enroll_date, setenroll_date] = useState('');
  const[title, settitle] = useState('Md.');
  const[gender, setgender] = useState('male');
  const[fname, setfname] = useState('');
  const[mname, setmname] = useState('');
  const[lname, setlname] = useState('');
  const[dob, setdob] = useState('');
  const[qualification, setqualification] = useState('');
  const[occupation, setoccupation] = useState('');
  const[monthly_income, setmonthly_income] = useState('');
  const[father_name, setfather_name] = useState('');
  const[mother_name, setmother_name] = useState('');
  const[husband_wife_name, sethusband_wife_name] = useState('');
  const[husband_wife_dob, sethusband_wife_dob] = useState('');
  const[mobile, setmobile] = useState('');
  const[collection_time, setcollection_time] = useState('');
  const[marital_status, setmarital_status] = useState('');
  const[email, setemail] = useState('');
  const[address1, setaddress1] = useState('');
  const[address2, setaddress2] = useState('');
  const[para, setpara] = useState('');
  const[ward, setward] = useState('');
  const[panchayat, setpanchayat] = useState('');
  const[area, setarea] = useState('');
  const[landmark, setlandmark] = useState('');
  const[district, setdistrict] = useState('');
  const[state, setstate] = useState('');
  const[pincode, setpincode] = useState('');
  const[country, setcountry] = useState('');
  const[prmt_address, setprmt_address] = useState('');
  const[prmt_city, setprmt_city] = useState('');
  const[prmt_state, setprmt_state] = useState('');
  const[prmt_pincode, setprmt_pincode] = useState('');
  const[loc_latitude, setloc_latitude] = useState('');
  const[loc_longitude, setloc_longitude] = useState('');
  const[aadhar_no, setaadhar_no] = useState('');
  const[voter_id_no, setvoter_id_no] = useState('');
  const[pan_no, setpan_no] = useState('');
  const[ration_card_no, setration_card_no] = useState('');
  const[meter_no, setmeter_no] = useState('');
  const[ci_no, setci_no] = useState('');
  const[ci_relation, setci_relation] = useState('');
  const[dl_no, setdl_no] = useState('');
  const[passport_no, setpassport_no] = useState('');
  
  const[nominee_name, setnominee_name] = useState('');
  const[nominee_relation, setnominee_relation] = useState('');
  const[nominee_mobile, setnominee_mobile] = useState('');
  const[nominee_dob, setnominee_dob] = useState('');
  const[nominee_aadhar_no, setnominee_aadhar_no] = useState('');
  const[nominee_pan_no, setnominee_pan_no] = useState('');
  const[nominee_ration_card_no, setnominee_ration_card_no] = useState('');
  const[nominee_address, setnominee_address] = useState('');
  const[sms_status, setsms_status] = useState('');



  const[doc_type1, setdoc_type1] = useState('Photo');
  const[doc_photo, setdoc_photo] = useState('');
  const[doc_type2, setdoc_type2] = useState('driving_license');
  const[doc_id_proof, setdoc_id_proof] = useState('');
  const[doc_type3, setdoc_type3] = useState('driving_license');
  const[doc_address_proof, setdoc_address_proof] = useState('');
  const[doc_type4, setdoc_type4] = useState('pan');
  const[doc_pan, setdoc_pan] = useState('');
  const[doc_type5, setdoc_type5] = useState('Signature');
  const[doc_signature, setdoc_signature] = useState('');


const [transaction_date, settransaction_date] = useState('');
const [amount, setamount] = useState('1100');
const [mgst, setmgst] = useState('18');
const [total_amount, settotal_amount] = useState('');


const [net_fee_collect, setnet_fee_collect] = useState('');
const [remark, setremark] = useState('');
const [paymode, setpaymode] = useState('cash');
const [trnsfer_bank_name, settrnsfer_bank_name] = useState('');
const [cheque_no, setcheque_no] = useState('');
const [cheque_date, setcheque_date] = useState('');
const [transfer_date, settransfer_date] = useState('');
const [tranfer_no, settranfer_no] = useState('');
const [transfer_mode, settransfer_mode] = useState('');
const [nominee_voter_id, setnominee_voter_id] = useState('');
const [old_member_no, setold_member_no] = useState('');





  useEffect(() => {
  
    $('.dropify').dropify();


    axios.get(API_BASE_URL + "api-associates/get-associate-list", {
      headers: API_HEADERS,
    }).then((response) => {
      if (response.data["status"] > 0) {
        setassociateListArr(response.data["data"]);
      }
    });

    // GET ACTIVE BRANCH LIST


      axios.get(API_BASE_URL + "api-company/get-active-branch-list", {
        headers: API_HEADERS,
      }).then((response) => {
        if (response.data["status"] > 0) {
        setactiveBranchList(response.data["data"]);
        }
      }).catch((error) => {
        toast.error("Failed to fetch data!");
      });

      axios.get(API_BASE_URL + "api-user/get-role-permission", {
        headers: API_HEADERS,
      }).then((response) => {
        if (response.data["status"] > 0) {
          setroleList(response.data["data"]);
        }
      }).catch((error) => {
        toast.error("Failed to fetch data!");
      });


      // setaccount_open_date(getCurrentDate());
  }, []);






  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);


    let errors = {};

    const formData = new FormData();
formData.append('membership_type', membership_type);
formData.append('associate_id', associate_id);
formData.append('group_id', group_id);
formData.append('branch_id', branch_id);
formData.append('enroll_date', enroll_date);
formData.append('title', title);
formData.append('gender', gender);
formData.append('fname', fname);
formData.append('mname', mname);
formData.append('lname', lname);
formData.append('dob', dob);
formData.append('qualification', qualification);
formData.append('occupation', occupation);
formData.append('monthly_income', monthly_income);
formData.append('father_name', father_name);
formData.append('mother_name', mother_name);
formData.append('husband_wife_name', husband_wife_name);
formData.append('husband_wife_dob', husband_wife_dob);
formData.append('mobile', mobile);
formData.append('collection_time', collection_time);
formData.append('marital_status', marital_status);
formData.append('email', email);
formData.append('address1', address1);
formData.append('address2', address2);
formData.append('para', para);
formData.append('ward', ward);
formData.append('panchayat', panchayat);
formData.append('area', area);
formData.append('landmark', landmark);
formData.append('district', district);
formData.append('state', state);
formData.append('pincode', pincode);
formData.append('country', country);
formData.append('prmt_address', prmt_address);
formData.append('prmt_city', prmt_city);
formData.append('prmt_state', prmt_state);
formData.append('prmt_pincode', prmt_pincode);
formData.append('loc_latitude', loc_latitude);
formData.append('loc_longitude', loc_longitude);
formData.append('aadhar_no', aadhar_no);
formData.append('voter_id_no', voter_id_no);
formData.append('pan_no', pan_no);
formData.append('ration_card_no', ration_card_no);
formData.append('meter_no', meter_no);
formData.append('ci_no', ci_no);
formData.append('ci_relation', ci_relation);
formData.append('dl_no', dl_no);
formData.append('passport_no', passport_no);
formData.append('nominee_name', nominee_name);
formData.append('nominee_relation', nominee_relation);
formData.append('nominee_mobile', nominee_mobile);
formData.append('nominee_dob', nominee_dob);
formData.append('nominee_aadhar_no', nominee_aadhar_no);
formData.append('nominee_voter_id', nominee_voter_id);
formData.append('nominee_pan_no', nominee_pan_no);
formData.append('nominee_ration_card_no', nominee_ration_card_no);
formData.append('nominee_address', nominee_address);
formData.append('sms_status', sms_status);

formData.append('doc_type1', doc_type1);
if (doc_photo) {
  formData.append('doc_photo', doc_photo); 
}

formData.append('doc_type2', doc_type2);
if (doc_id_proof) {
  formData.append('doc_id_proof', doc_id_proof); 
}

formData.append('doc_type3', doc_type3);
if (doc_address_proof) {
  formData.append('doc_address_proof', doc_address_proof); 
}


formData.append('doc_type4', doc_type4);
if (doc_pan) {
  formData.append('doc_pan', doc_pan); 
}

formData.append('doc_type5', doc_type5);
if (doc_signature) {
  formData.append('doc_signature', doc_signature); 
}

formData.append('transaction_date', transaction_date);
formData.append('amount', amount);
formData.append('mgst', mgst);
formData.append('total_amount', total_amount);

formData.append('net_fee_collect', net_fee_collect);
formData.append('remark', remark);
formData.append('paymode', paymode);
formData.append('trnsfer_bank_name', trnsfer_bank_name);
formData.append('cheque_no', cheque_no);
formData.append('cheque_date', cheque_date);
formData.append('transfer_date', transfer_date);
formData.append('tranfer_no', tranfer_no);
formData.append('transfer_mode', transfer_mode);

console.log(formData);

    // validate form
     if (!membership_type) {
        errors.membership_type = 'Member type is required.';
      }

      if (!branch_id) {
        errors.branch_id = 'Branch Name is required.';
      }

      if (!enroll_date) {
        errors.enroll_date = 'Enrollment date is required.';
      }

      if (!fname) {
        errors.fname = 'First name is required.';
      }

      if (!dob) {
        errors.dob = 'DOB is required.';
      }

      if (!mobile) {
        errors.mobile = 'Mobile No. is required.';
      }

      
      if (!state) {
        errors.state = 'State is required.';
      }

      
      if (!country) {
        errors.country = 'Country is required.';
      }

      if (!net_fee_collect) {
        errors.net_fee_collect = 'Net Fee to Collect is required.';
      }


    

    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
        axios.post(API_BASE_URL + 'api-member/add-new-member', formData, {
          headers: {
            ...API_HEADERS
          },
        }).then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/members-management";
            }, 3700);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 300);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };


 



  const selectAssociate = useRef();
  useEffect(() => {
    // Initialize Select2
    const $selectEmp = $(selectAssociate.current);
    $selectEmp.select2();

    // $selectEmp.val(emp_profile).trigger('change');
    // Handle change event
    $selectEmp.on('change', function () {
      const selectedOptions = $selectEmp.val() || [];
      setassociate_id(selectedOptions);
    });
    // Cleanup Select2 on component unmount
    return () => {
      $selectEmp.select2('destroy');
    };
  }, []);

  const selectRefBranch = useRef();
  useEffect(() => {
    // Initialize Select2
    const $selectBranch = $(selectRefBranch.current);
    $selectBranch.select2();
    // Handle change event
    $selectBranch.on('change', function () {
      const selectedOptions1 = $selectBranch.val() || [];
      setbranch_id(selectedOptions1);
    });
    // Cleanup Select2 on component unmount
    return () => {
      $selectBranch.select2('destroy');
    };
  }, []);
  

  const selectRefGroupid = useRef();
  useEffect(() => {
    // Initialize Select2
    const $selectRole = $(selectRefGroupid.current);
    $selectRole.select2();
    // Handle change event
    $selectRole.on('change', function () {
      const selectedOptions = $selectRole.val() || [];
      setgroup_id(selectedOptions);
    });
    // Cleanup Select2 on component unmount
    return () => {
      $selectRole.select2('destroy');
    };
  }, []);

  const handledoc_photo = (e) => {
    const file = e.target.files[0]; 
    setdoc_photo(file); 
  };
  const handledoc_id_proof = (e) => {
    const file = e.target.files[0]; 
    setdoc_id_proof(file); 
  };

  const handledoc_address_proof = (e) => {
    const file = e.target.files[0]; 
    setdoc_address_proof(file); 
  };

  const handledoc_pan = (e) => {
    const file = e.target.files[0]; 
    setdoc_pan(file); 
  };

  const handledoc_signature = (e) => {
    const file = e.target.files[0]; 
    setdoc_signature(file); 
  };

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>New User</h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/users-management">Users</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">New </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12"></div>

                  <div class="col-sm-12">
                    <div class="card">
                      <div class="card-header">
                        {/* <h5>Add New Promoter</h5> */}
                        {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                      </div>

                      <div class="card-block">
                        <form onSubmit={updateDetails}>



                        <div class="row">
                      <div class="col-sm-12 col-xl-12 m-b-30">
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="text-xl-right">
                            Membership Type<span class="text-danger">*</span>
                            </h6>
                          </div>
                          <div class="col-sm-7">
                              <div class="form-radio row ml-3">
                                <div class="radio radio-inline col-xs-3 ">
                                  <label for="active_nominal">
                                    <input
                                      type="radio"
                                      name="membership_type"
                                      value={"nominal"}
                                      id={"active_nominal"}
                                      checked={membership_type === 'nominal' ? 'checked' : ''}
                                      onChange={(e) =>
                                        setmembership_type(e.target.value)
                                      }
                                    />
                                    <i class="helper"></i>Nominal Membership 
                                  </label>
                                </div>
                                <div class="radio radio-inline col-xs-3 ">
                                  <label for="active_regular">
                                    <input
                                      type="radio"
                                      name="membership_type"
                                      id={"active_regular"}
                                      value={"regular"}
                                      checked={membership_type === 'regular' ? 'checked' : ''}
                                      onChange={(e) =>
                                        setmembership_type(e.target.value)
                                      }
                                    />
                                    <i class="helper"></i>Regular Membership
                                  </label>
                                </div>
                                
                              </div>
                              {formErrors.membership_type && (
                                <span style={{ color: "red" }}>
                                  {formErrors.membership_type}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>


                      <div class="row">
                        <div class="col-sm-12 col-xl-12 m-b-30">
                          <h5 class="sub-title text-center">
                                  {/* Link With Software */}
                          </h5>
                        </div>
                      </div>

                        

                        <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Associate/ Advisor/ Staff
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <select
                                    class="form-control m-b"
                                    name="emp_profile"
                                    value={associate_id}
                                    ref={selectAssociate}
                                  >
                                    <option value={''} disabled>Associate/ Advisor/ Staff</option>
                                    {associateListArr.map((bankdata) => (
                                      <option
                                        value={bankdata.id}
                                        selected={
                                          bankdata.id === associate_id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {bankdata.first_name} 
                                      {bankdata.last_name}

                                      </option>
                                    ))}
                                  </select>
                                  {formErrors.associate_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.associate_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Groups
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <select
                                    class="form-control m-b "
                                    data-placeholder="Select Groups"
                                    multiple="multiple"
                                    name="group_id"
                                    value={group_id}
                                    ref={selectRefGroupid}
                                  >
                                    <option value={''} disabled>Select Profile</option>
                                    {roleList.map((roleData) => (
                                      <option
                                        value={roleData.id}
                                        selected={
                                          roleData.id === group_id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {roleData.role_name}

                                      </option>
                                    ))}
                                  </select>
                                  {formErrors.group_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.group_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div> 
                            </div>
                          
                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Branch<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <select
                                     class="form-control m-b "
                                     data-placeholder="Select Branch"
                                    name="branch_id"
                                    value={branch_id}
                                    ref={selectRefBranch}
                                  >
                                    <option value={''} disabled>Select Profile</option>
                                    {activeBranchList.map((branchdata) => (
                                      <option
                                        value={branchdata.id}
                                        selected={
                                          branchdata.id === branch_id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {branchdata.branch_name}

                                      </option>
                                    ))}
                                  </select>
                                  {formErrors.branch_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.branch_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Enrollment Date <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="date"
                                    value={enroll_date}
                                    onChange={(e) =>
                                      setenroll_date(e.target.value)
                                    }
                                    placeholder="Enter First Name "
                                  />
                                  {formErrors.enroll_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.enroll_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                          </div>

                          
                          <div class="row">
                        <div class="col-sm-12 col-xl-12 m-b-30">
                          <h5 class="sub-title text-center">
                          Member's Info

                          </h5>
                        </div>
                      </div>


                      <div class="row">
                      <div class="col-sm-12 col-xl-6 m-b-30">
                        <div class="row">
                          <div class="col-sm-5">
                            <h6 class="text-xl-right">
                            Title<span class="text-danger">*</span>
                            </h6>
                          </div>
                          <div class="col-sm-7">
                              <div class="form-radio row ml-3">
                                <div class="radio radio-inline col-xs-3 ">
                                  <label for="title_md">
                                    <input
                                      type="radio"
                                      name="title"
                                      value={"Md."}
                                      id={"title_md"}
                                      checked={title === 'Md.' ? 'checked' : ''}
                                      onChange={(e) =>
                                        settitle(e.target.value)
                                      }
                                    />
                                    <i class="helper"></i>Md. 
                                  </label>
                                </div>
                                <div class="radio radio-inline col-xs-3 ">
                                  <label for="title_mr">
                                    <input
                                      type="radio"
                                      name="title"
                                      id={"title_mr"}
                                      value={"Mr."}
                                      checked={title === 'Mr.' ? 'checked' : ''}
                                      onChange={(e) =>
                                        settitle(e.target.value)
                                      }
                                    />
                                    <i class="helper"></i>Mr.
                                  </label>
                                </div>
                                <div class="radio radio-inline col-xs-3 ">
                                  <label for="title_ms">
                                    <input
                                      type="radio"
                                      name="title"
                                      id={"title_ms"}
                                      value={"Ms."}
                                      checked={title === 'Ms.' ? 'checked' : ''}
                                      onChange={(e) =>
                                        settitle(e.target.value)
                                      }
                                    />
                                    <i class="helper"></i>Ms.
                                  </label>
                                </div>
                                <div class="radio radio-inline col-xs-3 ">
                                  <label for="title_mrs">
                                    <input
                                      type="radio"
                                      name="title"
                                      id={"title_mrs"}
                                      value={"Mrs."}
                                      checked={title === 'Mrs.' ? 'checked' : ''}
                                      onChange={(e) =>
                                        settitle(e.target.value)
                                      }
                                    />
                                    <i class="helper"></i>Mrs.
                                  </label>
                                </div>
                                
                              </div>
                              {formErrors.title && (
                                <span style={{ color: "red" }}>
                                  {formErrors.title}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-12 col-xl-6 m-b-30">
                        <div class="row">
                          <div class="col-sm-5">
                            <h6 class="text-xl-right">
                            Gender<span class="text-danger">*</span>
                            </h6>
                          </div>
                          <div class="col-sm-7">
                              <div class="form-radio row ml-3">
                                <div class="radio radio-inline col-xs-3 ">
                                  <label for="gender_male">
                                    <input
                                      type="radio"
                                      name="gender"
                                      value={"Male"}
                                      id={"gender_male"}
                                      checked={gender === 'Male' ? 'checked' : ''}
                                      onChange={(e) =>
                                        setgender(e.target.value)
                                      }
                                    />
                                    <i class="helper"></i>Male 
                                  </label>
                                </div>
                                <div class="radio radio-inline col-xs-3 ">
                                  <label for="gender_famela">
                                    <input
                                      type="radio"
                                      name="gender"
                                      id={"gender_famela"}
                                      value={"Female"}
                                      checked={gender === 'Female' ? 'checked' : ''}
                                      onChange={(e) =>
                                        setgender(e.target.value)
                                      }
                                    />
                                    <i class="helper"></i>Female
                                  </label>
                                </div>
                                <div class="radio radio-inline col-xs-3 ">
                                  <label for="gender_other">
                                    <input
                                      type="radio"
                                      name="gender"
                                      id={"gender_other"}
                                      value={"Other"}
                                      checked={gender === 'Other' ? 'checked' : ''}
                                      onChange={(e) =>
                                        setgender(e.target.value)
                                      }
                                    />
                                    <i class="helper"></i>Other
                                  </label>
                                </div>
                                
                                
                              </div>
                              {formErrors.gender && (
                                <span style={{ color: "red" }}>
                                  {formErrors.gender}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>



                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  First Name<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={fname}
                                    onChange={(e) =>
                                      setfname(e.target.value)
                                    }
                                    placeholder="Enter First Name "
                                  />
                                  {formErrors.fname && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.fname}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Middle Name
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={mname}
                                    onChange={(e) =>
                                      setmname(e.target.value)
                                    }
                                    placeholder="Enter Middle Name"
                                  />
                                  {formErrors.mname && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.mname}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            </div>



                  <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Last Name
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={lname}
                                    onChange={(e) =>
                                      setlname(e.target.value)
                                    }
                                    placeholder="Enter Last Name"
                                  />
                                  {formErrors.lname && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.lname}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Date of Birth<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="date"
                                    value={dob}
                                    onChange={(e) =>
                                      setdob(e.target.value)
                                    }
                                    placeholder="Enter Date of Birth"
                                  />
                                  {formErrors.dob && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.dob}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Qualification
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={qualification}
                                    onChange={(e) =>
                                      setqualification(e.target.value)
                                    }
                                    placeholder="Enter Qualification "
                                  />
                                  {formErrors.qualification && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.qualification}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Occupation
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={occupation}
                                    onChange={(e) =>
                                      setoccupation(e.target.value)
                                    }
                                    placeholder="Enter Occupation"
                                  />
                                  {formErrors.occupation && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.occupation}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Monthly Income
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={monthly_income}
                                    onChange={(e) =>
                                      setmonthly_income(e.target.value)
                                    }
                                    placeholder="Enter Monthly Income "
                                  />
                                  {formErrors.monthly_income && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.monthly_income}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Old Member No (if any)
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                 <div class="input-group">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={old_member_no}
                                    onChange={(e) =>
                                      setold_member_no(e.target.value)
                                    }
                                    placeholder="Enter Old Member No (if any) "
                                  />

                                <div class="input-group-prepend" style={{width:'auto'}}>
                                 <span class="input-group-text" title="Used for old member no if you migrating from other software." id="basic-addon5"><i class="icofont icofont-info"></i></span>
                                 </div>
                                </div>

                                  {formErrors.old_member_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.old_member_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Father Name
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={father_name}
                                    onChange={(e) =>
                                      setfather_name(e.target.value)
                                    }
                                    placeholder="Enter Father Name "
                                  />
                                  {formErrors.father_name && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.father_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Mother Name
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                 <input
                                    class="form-control"
                                    type="text"
                                    value={mother_name}
                                    onChange={(e) =>
                                      setmother_name(e.target.value)
                                    }
                                    placeholder="Enter Mother Name "
                                  />

                                  {formErrors.mother_name && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.mother_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Husband/ Wife Name
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={husband_wife_name}
                                    onChange={(e) =>
                                      sethusband_wife_name(e.target.value)
                                    }
                                    placeholder="Enter Husband/ Wife Name "
                                  />
                                  {formErrors.husband_wife_name && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.husband_wife_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Husband/ Wife DOB
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="date"
                                    value={husband_wife_dob}
                                    onChange={(e) =>
                                      sethusband_wife_dob(e.target.value)
                                    }
                                    placeholder="Enter Husband/ Wife DOB "
                                  />
                                  {formErrors.husband_wife_dob && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.husband_wife_dob}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Mobile No.<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <div class="input-group">
                                <div class="input-group-prepend" style={{width:'auto'}}>
                                 <span class="input-group-text" title="" id="basic-addon5">+91 </span>
                                 </div>
                                  <input
                                    class="form-control"
                                    type="number"
                                    value={mobile}
                                    onChange={(e) =>
                                      setmobile(e.target.value)
                                    }
                                    onKeyDown={(e) => { if (e.target.value.length === 10) { e.preventDefault(); } }}
                                    placeholder="Enter Mobile No "
                                  />
                                  </div>

                                  {formErrors.mobile && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.mobile}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Collection Time
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                 <div class="input-group">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={collection_time}
                                    onChange={(e) =>
                                      setcollection_time(e.target.value)
                                    }
                                    placeholder="Enter Collection Time "
                                  />

                                <div class="input-group-prepend" style={{width:'auto'}}>
                                 <span class="input-group-text" title="The time at which agent need to go to member to collect payment. Like 10 am - 11 am." id="basic-addon5"><i class="icofont icofont-info"></i></span>
                                 </div>
                                </div>

                                  {formErrors.collection_time && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.collection_time}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                           <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Marital Status
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <select
                                      class="form-control"
                                      name="marital_status"
                                      value={marital_status}
                                      onChange={(e) =>
                                        setmarital_status(e.target.value)
                                      }
                                    >
                                      <option value="">Select Marital Status</option>
                                      <option value="SINGLE">SINGLE</option>
                                      <option value="MARRIED">MARRIED</option>
                                      <option value="SEPARATED">SEPARATED</option>
                                      <option value="DIVORCED">DIVORCED</option>
                                      <option value="WIDOWED">WIDOWED</option>
                                      <option value="UNMARRIED">UNMARRIED</option>
                                      <option value="UNTAGGED">UNTAGGED</option>
                                    </select>

                                  {formErrors.marital_status && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.marital_status}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Email
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input
                                    class="form-control"
                                    type="email"
                                    value={email}
                                    onChange={(e) =>
                                      setemail(e.target.value)
                                    }
                                    placeholder="Enter Email "
                                  />

                                  {formErrors.email && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.email}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            
                          </div>


                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <h5 class="sub-title text-center">
                              Member's Correspondence Address
                              </h5>
                            </div>
                          </div>


                          
                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Address Line 1
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={address1}
                                    onChange={(e) =>
                                      setaddress1(e.target.value)
                                    }
                                    placeholder="Enter Address Line 1 "
                                  />
                                  {formErrors.address1 && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.address1}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Address Line 2
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={address2}
                                    onChange={(e) =>
                                      setaddress2(e.target.value)
                                    }
                                    placeholder="Enter Address Line 2"
                                  />
                                  {formErrors.address2 && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.address2}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            </div>

                            <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Para
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={para}
                                    onChange={(e) =>
                                      setpara(e.target.value)
                                    }
                                    placeholder="Enter Para "
                                  />
                                  {formErrors.para && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.para}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Ward
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={ward}
                                    onChange={(e) =>
                                      setward(e.target.value)
                                    }
                                    placeholder="Enter Ward"
                                  />
                                  {formErrors.ward && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.ward}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            </div>

                            <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Panchayat
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={panchayat}
                                    onChange={(e) =>
                                      setpanchayat(e.target.value)
                                    }
                                    placeholder="Enter Panchayat "
                                  />
                                  {formErrors.panchayat && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.panchayat}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Area
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={area}
                                    onChange={(e) =>
                                      setarea(e.target.value)
                                    }
                                    placeholder="Enter Area"
                                  />
                                  {formErrors.area && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.area}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            </div>

                            <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Landmark
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={landmark}
                                    onChange={(e) =>
                                      setlandmark(e.target.value)
                                    }
                                    placeholder="Enter Landmark "
                                  />
                                  {formErrors.landmark && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.landmark}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    City / District
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={district}
                                    onChange={(e) =>
                                      setdistrict(e.target.value)
                                    }
                                    placeholder="Enter City / District" />
                                  {formErrors.district && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.district}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            </div>


                             <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  State <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={state}
                                    onChange={(e) =>
                                      setstate(e.target.value)
                                    }
                                    placeholder="Enter state "
                                  />
                                  {formErrors.state && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.state}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            </div>

                             <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Pincode 
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={pincode}
                                    onChange={(e) =>
                                      setpincode(e.target.value)
                                    }
                                    onKeyDown={(e) => { if (e.target.value.length === 6) { e.preventDefault(); } }}
                                    placeholder="Enter pincode "
                                  />
                                  {formErrors.pincode && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.pincode}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                               <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Country<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={country}
                                    onChange={(e) =>
                                      setcountry(e.target.value)
                                    }
                                    placeholder="Enter country "
                                  />
                                  {formErrors.country && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.country}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            </div>


                               <div class="row">
                                <div class="col-sm-12 col-xl-12 m-b-30">
                                  <h5 class="sub-title text-center">
                                         Member's Permanent Address
                                  </h5>
                                </div>
                              </div>

                                 <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Address 
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={prmt_address}
                                    onChange={(e) =>
                                      setprmt_address(e.target.value)
                                    }
                                    placeholder="Enter Address "
                                  />
                                  {formErrors.prmt_address && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.prmt_address}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                               <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  City  
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={prmt_city}
                                    onChange={(e) =>
                                      setprmt_city(e.target.value)
                                    }
                                    placeholder="Enter City "
                                  />
                                  {formErrors.prmt_city && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.prmt_city}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            </div>

                               <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  State 
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={prmt_state}
                                    onChange={(e) =>
                                      setprmt_state(e.target.value)
                                    }
                                    placeholder="Enter State "
                                  />
                                  {formErrors.prmt_state && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.prmt_state}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                               <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Pincode  
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={prmt_pincode}
                                    onChange={(e) =>
                                      setprmt_pincode(e.target.value)
                                    }
                                    onKeyDown={(e) => { if (e.target.value.length === 6) { e.preventDefault(); } }}
                                    placeholder="Enter Pincode "
                                  />
                                  {formErrors.prmt_pincode && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.prmt_pincode}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            </div>




                            <div class="row">
                                <div class="col-sm-12 col-xl-12 m-b-30">
                                  <h5 class="sub-title text-center">
                                         Member's Address GPS Location -  <a href="/LINK WITH SOFTWARE" class="btn btn-warning btn-square">GET CURRENT LOCATION</a>
                                  </h5>
                                </div>
                              </div>



                                   <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Location Latitude 
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={loc_latitude}
                                    onChange={(e) =>
                                      setloc_latitude(e.target.value)
                                    }
                                    placeholder="Enter Location Latitude "
                                  />
                                  {formErrors.loc_latitude && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.loc_latitude}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                               <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Location Longitude  
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={loc_longitude}
                                    onChange={(e) =>
                                      setloc_longitude(e.target.value)
                                    }
                                    placeholder="Enter Location Longitude "
                                  />
                                  {formErrors.loc_longitude && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.loc_longitude}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            </div>


                            <div class="row">
                                <div class="col-sm-12 col-xl-12 m-b-30">
                                  <h5 class="sub-title text-center">
                                         Member's KYC
                                  </h5>
                                </div>
                              </div>

                                 <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Aadhaar No
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={aadhar_no}
                                    onChange={(e) =>
                                      setaadhar_no(e.target.value)
                                    }
                                    onKeyDown={(e) => { if (e.target.value.length === 12) { e.preventDefault(); } }}
                                    placeholder="Enter Aadhaar No "
                                  />
                                  {formErrors.aadhar_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.aadhar_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                               <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Voter ID No  
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={voter_id_no}
                                    onChange={(e) =>
                                      setvoter_id_no(e.target.value)
                                    }
                                    placeholder="Enter Voter ID No "
                                  />
                                  {formErrors.voter_id_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.voter_id_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            </div>


                               <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Pan No
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={pan_no}
                                    onChange={(e) =>
                                      setpan_no(e.target.value)
                                    }
                                    onKeyDown={(e) => { if (e.target.value.length === 10) { e.preventDefault(); } }}
                                    placeholder="Enter Pan No "
                                  />
                                  {formErrors.pan_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.pan_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                               <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Ration Card No 
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={ration_card_no}
                                    onChange={(e) =>
                                      setration_card_no(e.target.value)
                                    }
                                    placeholder="Enter Voter ID No "
                                  />
                                  {formErrors.ration_card_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.ration_card_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            </div>


                               <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Meter No
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={meter_no}
                                    onChange={(e) =>
                                      setmeter_no(e.target.value)
                                    }
                                    placeholder="Enter Meter No "
                                  />
                                  {formErrors.meter_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.meter_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                               <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  CI No  
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={ci_no}
                                    onChange={(e) =>
                                      setci_no(e.target.value)
                                    }
                                    placeholder="Enter CI No "
                                  />
                                  {formErrors.ci_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.ci_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            </div>


                             <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  CI Relation
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={ci_relation}
                                    onChange={(e) =>
                                      setci_relation(e.target.value)
                                    }
                                    placeholder="Enter CI Relation "
                                  />
                                  {formErrors.ci_relation && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.ci_relation}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                               <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  DL No  
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={dl_no}
                                    onChange={(e) =>
                                      setdl_no(e.target.value)
                                    }
                                    placeholder="Enter DL No "
                                  />
                                  {formErrors.dl_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.dl_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            </div>

                                <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Passport No
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={passport_no}
                                    onChange={(e) =>
                                      setpassport_no(e.target.value)
                                    }
                                    placeholder="Enter Passport No "
                                  />
                                  {formErrors.passport_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.passport_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                             
                            </div>

                                <div class="row">
                                <div class="col-sm-12 col-xl-12 m-b-30">
                                  <h5 class="sub-title text-center">
                                         Member's KYC Documents
                                  </h5>
                                </div>
                              </div>



                              <div class="row">
                                  <div class="col-sm-12 col-xl-6 m-b-30">
                                    <div class="row">
                                      <div class="col-sm-5">
                                        <h6 class="text-xl-right">
                                        Photo
                                        </h6>
                                      </div>
                                      <div class="col-sm-7">
                                        
                                        <div class="input-group">

                                        <select class="form-control" name="marital_status"
                                          value={doc_type1}
                                          onChange={(e) =>
                                            setdoc_type1(e.target.value)
                                          }
                                        >
                                          <option value="Photo">Photo</option>
                                        </select>
                                          
                                        <div class="input-group-prepend">
                                            <input class="form-control" style={{width:'100px'}} type="file" onChange={handledoc_photo} className="dropify" />
                                        </div>
                                        </div>
                                        {formErrors.doc_photo && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.doc_photo}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-sm-12 col-xl-6 m-b-30">
                                    <div class="row">
                                      <div class="col-sm-5">
                                        <h6 class="text-xl-right">
                                        Id Proof
                                        </h6>
                                      </div>
                                      <div class="col-sm-7">
                                        
                                        <div class="input-group">

                                        <select class="form-control" name="marital_status"
                                          value={doc_type2}
                                          onChange={(e) =>
                                            setdoc_type2(e.target.value)
                                          }
                                        >
                                         <option value="driving_license">Driving License</option>
                                        <option value="aadhar_card">Aadhaar Card</option>
                                        <option value="voter_id">Voter ID</option>
                                        <option value="ration_card">Ration Card</option>
                                        </select>
                                          
                                        <div class="input-group-prepend">
                                            <input class="form-control" type="file" onChange={handledoc_id_proof} className="dropify" />
                                        </div>
                                        </div>
                                        {formErrors.doc_id_proof && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.doc_id_proof}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                          </div>

                          <div class="row">
                                  <div class="col-sm-12 col-xl-6 m-b-30">
                                    <div class="row">
                                      <div class="col-sm-5">
                                        <h6 class="text-xl-right">
                                        Address Proof
                                        </h6>
                                      </div>
                                      <div class="col-sm-7">
                                        
                                        <div class="input-group">

                                        <select class="form-control" name="marital_status"
                                          value={doc_type3}
                                          onChange={(e) =>
                                            setdoc_type3(e.target.value)
                                          }
                                        >
                                         <option value="driving_license">Driving License</option>
                                        <option value="aadhar_card">Aadhaar Card</option>
                                        <option value="voter_id">Voter ID</option>
                                        <option value="ration_card">Ration Card</option>
                                        </select>
                                          
                                        <div class="input-group-prepend">
                                            <input class="form-control" type="file" onChange={handledoc_address_proof} className="dropify" />
                                        </div>
                                        </div>
                                        {formErrors.doc_address_proof && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.doc_address_proof}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-sm-12 col-xl-6 m-b-30">
                                    <div class="row">
                                      <div class="col-sm-5">
                                        <h6 class="text-xl-right">
                                        Pan Number
                                        </h6>
                                      </div>
                                      <div class="col-sm-7">
                                        
                                        <div class="input-group">

                                        <select class="form-control" name="marital_status"
                                          value={doc_type4}
                                          onChange={(e) =>
                                            setdoc_type4(e.target.value)
                                          }
                                        >
                                         <option value="pan">PAN</option>
                                        <option value="form_60">Form 60</option>

                                        </select>
                                          
                                        <div class="input-group-prepend">
                                            <input class="form-control" type="file" onChange={handledoc_pan} className="dropify" />
                                        </div>
                                        </div>
                                        {formErrors.doc_pan && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.doc_pan}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                          </div>


                          <div class="row">
                                  <div class="col-sm-12 col-xl-6 m-b-30">
                                    <div class="row">
                                      <div class="col-sm-5">
                                        <h6 class="text-xl-right">
                                        Signature
                                        </h6>
                                      </div>
                                      <div class="col-sm-7">
                                        
                                        <div class="input-group">

                                        <select class="form-control" name="marital_status"
                                          value={doc_type5}
                                          onChange={(e) =>
                                            setdoc_type5(e.target.value)
                                          }
                                        >
                                         <option value="Signature">Signature</option>
                                        </select>
                                          
                                        <div class="input-group-prepend">
                                            <input class="form-control" type="file" onChange={handledoc_signature} className="dropify" />
                                        </div>
                                        </div>
                                        {formErrors.doc_signature && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.doc_signature}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                          </div>

                                      
                                      
                                <div class="row">
                                <div class="col-sm-12 col-xl-12 m-b-30">
                                  <h5 class="sub-title text-center">
                                  Nominee Info
                                  </h5>
                                </div>
                              </div>   


                              <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Nominee Name
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={nominee_name}
                                    onChange={(e) =>
                                      setnominee_name(e.target.value)
                                    }
                                    placeholder="Enter Nominee Name "
                                  />
                                  {formErrors.nominee_name && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.nominee_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Nominee Relation
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  
                                   <select
                                class="form-control"
                                name="nominee_relation"
                                value={nominee_relation}
                                onChange={(e) =>
                                  setnominee_relation(e.target.value)
                                }
                              >
                                <option value="">Select Relation</option>
                                <option value="father">Father</option>
                                <option value="mother">Mother</option>
                                <option value="son">Son</option>
                                <option value="daughter">Daughter</option>
                                <option value="spouse">
                                  Spouse (Husband/ Wife)
                                </option>
                                <option value="husband">Husband</option>
                                <option value="wife">Wife</option>
                                <option value="brother">Brother</option>
                                <option value="sister">Sister</option>
                                <option value="daughter_in_law">
                                  Daughter in Law
                                </option>
                                <option value="brother_in_law">
                                  Brother in Law
                                </option>
                                <option value="grand_daughter">
                                  Grand Daughter
                                </option>
                                <option value="grand_son">Grand Son</option>
                                <option value="nephew">Nephew</option>
                                <option value="niece">Niece</option>
                                <option value="other">Other</option>
                              </select>
                                  {formErrors.nominee_relation && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.nominee_relation}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Nominee Mobile No.
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <div class="input-group">
                                <div class="input-group-prepend" style={{width:'auto'}}>
                                 <span class="input-group-text" title="" id="basic-addon5">+91 </span>
                                 </div>
                                  <input
                                    class="form-control"
                                    type="number"
                                    value={nominee_mobile}
                                    onChange={(e) =>
                                      setnominee_mobile(e.target.value)
                                    }
                                    onKeyDown={(e) => { if (e.target.value.length === 10) { e.preventDefault(); } }}
                                    placeholder="Enter Nominee Mobile No. "
                                  />
                                  </div>

                                  {formErrors.nominee_mobile && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.nominee_mobile}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Nominee DOB
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                <input
                                    class="form-control"
                                    type="date"
                                    value={nominee_dob}
                                    onChange={(e) =>
                                      setnominee_dob(e.target.value)
                                    }
                                    placeholder="Enter Nominee DOB"
                                  />

                                  {formErrors.nominee_dob && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.nominee_mobile}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Nominee Aadhaar No.
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={nominee_aadhar_no}
                                    onChange={(e) =>
                                      setnominee_aadhar_no(e.target.value)
                                    }
                                    placeholder="Enter Nominee Aadhaar No. "
                                  />
                                  {formErrors.nominee_aadhar_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.nominee_aadhar_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Nominee Voter ID No.
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={nominee_voter_id}
                                    onChange={(e) =>
                                      setnominee_voter_id(e.target.value)
                                    }
                                    placeholder="Enter Nominee Voter ID No. "
                                  />
                                  {formErrors.nominee_voter_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.nominee_voter_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Nominee Pan No.
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={nominee_pan_no}
                                    onChange={(e) =>
                                      setnominee_pan_no(e.target.value)
                                    }
                                    placeholder="Enter Nominee Pan No. "
                                  />
                                  {formErrors.nominee_pan_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.nominee_pan_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Nominee Ration Card No
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={nominee_ration_card_no}
                                    onChange={(e) =>
                                      setnominee_ration_card_no(e.target.value)
                                    }
                                    placeholder="Enter Nominee Voter ID No. "
                                  />
                                  {formErrors.nominee_ration_card_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.nominee_ration_card_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Nominee Address
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                              
                                  <textarea class="form-control" value={nominee_address}
                                    onChange={(e) =>
                                      setnominee_address(e.target.value)
                                    }></textarea>

                                  {formErrors.nominee_address && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.nominee_address}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                           
                          </div>


                          <div class="row">
                            <div class="col-sm-12 col-xl-8 m-b-30">
                              <h5 class="sub-title text-center">
                              Extra Settings
                              </h5>
                            </div>
                          </div>



                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30 offset-xl-2 ">
                              <div class="row">
                              <div class="col-sm-5">
                                    <h6 class="text-right">SMS</h6>
                                </div>
                                <div class="col-sm-7">
                                  <input type="checkbox" name="sms_status"   value={"yes"} onChange={(e) => setsms_status(e.target.value)} />
                                </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                              <div class="col-sm-5">
                                </div>
                                <div class="col-sm-7">
                            
                                </div>
                            </div>
                          </div>
                        </div>



                        <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <h5 class="sub-title text-center">
                              Membership Charges (if any) 
                              </h5>
                            </div>
                          </div>



                          <div class="row">
                          <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Transaction Date
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="date"
                                    value={transaction_date}
                                    onChange={(e) =>
                                      settransaction_date(e.target.value)
                                    }
                                    placeholder="Enter Transaction Date. "
                                  />
                                  {formErrors.transaction_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.transaction_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Membership Fee
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                    <div class="row">
                                      <div class="col-xs-4 col-4 text-center"><h6 style={{width:'inherit'}}>Amount</h6></div>
                                      <div class="col-xs-4 col-4 text-center"><h6 style={{width:'inherit'}}>MGST</h6> </div>
                                      <div class="col-xs-4 col-4 text-center"><h6 style={{width:'inherit'}}>Total Amount</h6></div>
                                    </div>

                                <div class="input-group">
                                  <input class="form-control" type="text" value={amount} onChange={(e) => setamount(e.target.value) } placeholder="Enter Amount " readOnly />
                                  <div class="input-group-prepend">
                                    <input class="form-control" type="text" value={mgst} onChange={(e) => setmgst(e.target.value) } placeholder="Enter Amount " readOnly />
                                  </div>
                                  <div class="input-group-prepend">
                                    <input class="form-control" type="text" value={total_amount} onChange={(e) => settotal_amount(e.target.value) } placeholder="Enter Amount " />
                                  </div>
                                </div>

                                  
                                  {formErrors.amount && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.amount}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Net Fee to Collect<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={net_fee_collect}
                                    onChange={(e) =>
                                      setnet_fee_collect(e.target.value)
                                    }
                                    placeholder="Enter Net Fee to Collect. " 
                                  />
                                  {formErrors.net_fee_collect && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.net_fee_collect}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                          <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Remarks (if any)
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={remark}
                                    onChange={(e) =>
                                      setremark(e.target.value)
                                    }
                                    placeholder="Enter Remarks (if any). " 
                                  />
                                  {formErrors.remark && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.remark}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          
                          <div class="row">
                          <div class="col-sm-12 col-xl-8 offset-xl-1  m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    Pay Mode<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_cash">
                                        <input
                                          type="radio"
                                          name="paymode"
                                          value={"cash"}
                                          id={"title_cash"}
                                          checked={paymode === 'cash' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setpaymode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Cash
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_chque">
                                        <input
                                          type="radio"
                                          name="paymode"
                                          id={"title_chque"}
                                          value={"cheque"}
                                          checked={paymode === 'cheque' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setpaymode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Cheque
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3">
                                      <label for="title_online">
                                        <input
                                          type="radio"
                                          name="paymode"
                                          value={"online"}
                                          checked={paymode === 'online' ? 'checked' : ''}
                                          id={"title_online"}
                                          onChange={(e) =>
                                            setpaymode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Online Tx
                                      </label>
                                    </div>
                                    
                                  </div>
                                  {formErrors.paymode && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.paymode}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>



                          <div class="row" style={{ display: paymode === 'cheque' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    Bank Name<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={trnsfer_bank_name}
                                    onChange={(e) =>
                                      settrnsfer_bank_name(e.target.value)
                                    }
                                    placeholder="Enter Bank Name"
                                  />
                                  {formErrors.trnsfer_bank_name && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.trnsfer_bank_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style={{ display: paymode === 'cheque' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    Cheque<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={cheque_no}
                                    onChange={(e) =>
                                      setcheque_no(e.target.value)
                                    }
                                    placeholder="Enter Cheque"
                                  />
                                  {formErrors.cheque_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.cheque_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style={{ display: paymode === 'cheque' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    Cheque Date<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <input
                                    class="form-control"
                                    type="date"
                                    value={cheque_date}
                                    onChange={(e) =>
                                      setcheque_date(e.target.value)
                                    }
                                    placeholder="Enter Cheque Date"
                                  />
                                  {formErrors.cheque_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.cheque_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>



                          <div class="row" style={{ display: paymode === 'online' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    Transfar date<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <input
                                    class="form-control"
                                    type="date"
                                    value={transfer_date}
                                    onChange={(e) =>
                                      settransfer_date(e.target.value)
                                    }
                                    placeholder="Enter Transfar date"
                                  />
                                  {formErrors.transfer_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.transfer_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                         

                          <div class="row" style={{ display: paymode === 'online' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    UTR/ Transfar No.<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={tranfer_no}
                                    onChange={(e) =>
                                      settranfer_no(e.target.value)
                                    }
                                    placeholder="Enter Transfar No."
                                  />
                                  {formErrors.tranfer_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.tranfer_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style={{ display: paymode === 'online' ? '' : 'none' }}>
                          <div class="col-sm-12 col-xl-8 offset-xl-1 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                    Transfar Mode<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_IMPS">
                                        <input
                                          type="radio"
                                          name="transfer_mode"
                                          value={"IMPS"}
                                          id={"title_IMPS"}
                                          checked={transfer_mode === 'IMPS' ? 'checked' : ''}
                                          onChange={(e) =>
                                            settransfer_mode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>IMPS
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_VPA">
                                        <input
                                          type="radio"
                                          name="transfer_mode"
                                          id={"title_VPA"}
                                          value={"VPA"}
                                          checked={transfer_mode === 'VPA' ? 'checked' : ''}
                                          onChange={(e) =>
                                            settransfer_mode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>VPA
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3">
                                      <label for="title_NEFT">
                                        <input
                                          type="radio"
                                          name="transfer_mode"
                                          value={"NEFT/RTGS"}
                                          checked={transfer_mode === 'NEFT/RTGS' ? 'checked' : ''}
                                          id={"title_NEFT"}
                                          onChange={(e) =>
                                            settransfer_mode(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>NEFT/RTGS
                                      </label>
                                    </div>
                                    
                                  </div>
                                  {formErrors.transfer_mode && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.transfer_mode}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                         
                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <h5 class="sub-title text-center">
                                      {/* Link With Software */}
                              </h5>
                            </div>
                          </div>


                        
                        


                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                              <button
                                class="btn btn-success btn-square"
                                type="submit"
                                disabled={submitting}
                              >
                                SAVE
                              </button>{" "}
                              &nbsp;
                              <a href="/LINK WITH SOFTWARE" class="btn btn-danger btn-square">Cancel</a>
                              &nbsp;
                              <button
                                class="btn btn-warning btn-square"
                                type="reset"
                              >
                                Reset
                              </button>

                              <ToastContainer className="toast-container" />
                            </div>
                          </div>


                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;