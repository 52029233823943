import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { dataDecrypt,
    dataEncript,
    // FirstLetterCapitalize,
    // formatDate
     } from "./../../../helper/commonHelpers";


import $ from 'jquery';
import 'dropify/dist/css/dropify.min.css';
import 'dropify';

function CompanyDetails() {
  // const navigate = useNavigate();
  const { accountID }   = useParams();
  const [submitting, setSubmitting] = useState(false);
  // const [stateList, setStateList] = useState([]);
  const [promoterhDetails, setpromoterhDetails] = useState([]);
  const [document_name, setdocument_name] = useState(['']);
  const [document_file, setdocument_file] = useState(['']);





  const [formErrors, setFormErrors] = useState({
    document_name: "",
    document_file: "",

  });



  useEffect(() => {
    setTimeout(() => {
      $('.dropify').dropify();
    }, 1300);
  // get bran ch details
 
axios.get(API_BASE_URL+'api-common/get-account-list?id='+dataDecrypt(accountID), {
    headers: API_HEADERS
}).then((response) => {

console.log(response.data['data']);

    if (response.data["status"] > 0) {
        setpromoterhDetails(response.data['data']);
    }


  })
  .catch((error) => {
    toast.error('Failed to fetch DETAILS list!');
  });
 

    //    setenrollment_date(getCurrentDate());
  }, [accountID]);

  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    let logindetail = JSON.parse(localStorage.getItem('userLogin'));


    const formData = new FormData();
    formData.append('type', 'account'); 
    formData.append('loan_id', promoterhDetails.id); 
    formData.append('upload_by', logindetail.id); 

  


    // validate form

      if (!document_name) {
        errors.document_name = 'Comment is required.';
      }

    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
     
      document_file.forEach(file => {
        formData.append('document', file); 
      });
  
  
      document_name.forEach(file => {
        formData.append('doc_name', file); 
      });  

        axios.post(API_BASE_URL + 'api-common/update-document', formData, {
          headers: {
            ...API_HEADERS
          },
        }).then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              


              promoterhDetails && (
                promoterhDetails.account_for === 'fd' ? (
                  window.location.href = "/fixed-deposit-account-view/"+accountID
                ) : promoterhDetails.account_for === 'mis' ? (
                  window.location.href = "/fixed-deposit-mis-account-view/"+accountID
                ): promoterhDetails.account_for === 'rd' ? (
                  window.location.href = "/recurring-deposit-account-view/"+accountID
                ): promoterhDetails.account_for === 'dd' ? (
                  window.location.href = "/recurring-deposit-account-view/"+accountID
                ) : window.location.href = "/account-view/"+accountID
              )

              // window.location.reload();
            }, 2000);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 1000);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };


  const [comments, setComments] = useState([{}]);

  const handelDocumentName = (index, value) => {
    const updateDocumentName = [...document_name];
    updateDocumentName[index] = value;
    setdocument_name(updateDocumentName);
  };

  
  
  const handelDocumentFile = (index, value) => {
    const documentFile = [...document_file];
    documentFile[index] = value;
    setdocument_file(documentFile);
  };

 
  const handleRemove = (index) => {
    const updatedNominees = [...comments];
    updatedNominees.splice(index);
    setComments(updatedNominees);
  };


  const handleAddComment = () => {
    setComments([...comments, '']);
    $('.dropify').dropify();
  };




  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4> 
                          
                        {
                            promoterhDetails && (
                              promoterhDetails.account_for === 'fd' ? (
                                <>Upload FD Account Documents</>
                              ) : promoterhDetails.account_for === 'mis' ? (
                                 <>Upload MIS Account Documents</>
                              ): promoterhDetails.account_for === 'rd' ? (
                                <>Upload RD Account Documents</>
                             ) : promoterhDetails.account_for === 'dd' ? (
                              <>Upload DD Account Documents</>
                            ) : <>Upload Saving Account Documents</>
                            )
                          }
                          
                          - {promoterhDetails && promoterhDetails.account_no && (
                                    <span>{promoterhDetails.account_no}</span>
                                    )}</h4>

                                    
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                         
                            {
                              promoterhDetails && (
                                promoterhDetails.account_for === 'fd' ? (
                                  <a href="/fixed-deposit-account">Fd Accounts</a>
                                ) : promoterhDetails.account_for === 'mis' ? (
                                   <a href="/fixed-deposit-mis-account">MIS Deposits</a>
                                ) : promoterhDetails.account_for === 'rd' ? (
                                  <a href="/recurring-deposit-account">RD Accounts</a>
                                ) : promoterhDetails.account_for === 'dd' ? (
                                 <a href="/recurring-deposit-account">Daily Deposits</a>
                                ) :  <a href="/account-list">Accounts</a>
                              )
                            }
                        </li>


                        <li class="breadcrumb-item" style={{ float: "left" }}>

                           {
                              promoterhDetails && (
                                promoterhDetails.account_for === 'fd' ? (
                                  <a href={`/fixed-deposit-account-view/${dataEncript(promoterhDetails.id)}`} > {promoterhDetails && promoterhDetails.account_no && ( <span>{promoterhDetails.account_no}</span> )}</a>
                                ) : promoterhDetails.account_for === 'mis' ? (
                                     <a href={`/fixed-deposit-mis-account-view/${dataEncript(promoterhDetails.id)}`} > {promoterhDetails && promoterhDetails.account_no && ( <span>{promoterhDetails.account_no}</span> )}</a>
                                ) : promoterhDetails.account_for === 'rd' ? (
                                  <a href={`/recurring-deposit-account-view/${dataEncript(promoterhDetails.id)}`} > {promoterhDetails && promoterhDetails.account_no && ( <span>{promoterhDetails.account_no}</span> )}</a>
                                ) : promoterhDetails.account_for === 'dd' ? (
                                <a href={`/recurring-deposit-account-view/${dataEncript(promoterhDetails.id)}`} > {promoterhDetails && promoterhDetails.account_no && ( <span>{promoterhDetails.account_no}</span> )}</a>
                                ) :  <a href={`/account-view/${dataEncript(promoterhDetails.id)}`} > {promoterhDetails && promoterhDetails.account_no && ( <span>{promoterhDetails.account_no}</span> )}</a>
                              )
                            }

                          
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> Documents</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12"></div>

                 


                  <div class="col-sm-12">
                    <div class="card">
                    

                      <div class="card-block">
                        <form onSubmit={updateDetails} encType="multipart/form-data">


                           {comments.map((comment, index) => (
                              <div className="row" key={index}>
                                <div className="col-sm-12 col-xl-4 m-b-30">
                                  <h6>Document Name</h6>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={document_name[index]}
                                    onChange={(e) => handelDocumentName(index, e.target.value)}
                                    placeholder='Enter Document Name'
                                  />
                                  
                                </div>

                                <div className="col-sm-12 col-xl-4 m-b-30">
                                  <h6>File</h6>
                                  <input
                                    type="file"
                                    className="form-control dropify"
                                    onChange={(e) => handelDocumentFile(index, e.target.files[0])}
                                    placeholder='Enter Comment'
                                    
                                    
                                  />
                                </div>

                                <div className="col-sm-12 col-xl-4 m-b-30">

                                      <a href="#a" class="text-danger mt-3 " onClick={() => handleRemove(index)}>Remove</a>
                                </div>
                              </div>
                            ))}

                           


                            <div class="row">
                            <div class="col-sm-12 col-xl-12 my-2">
                           

                            {formErrors.document_name && <span style={{ color: 'red' }}>{formErrors.document_name}</span>}
                            {formErrors.document_file && <span style={{ color: 'red' }}>{formErrors.document_file}</span>}


                                 <h5 className="btn text-info" onClick={handleAddComment}>
                                        + ADD MORE DOCUMENT
                                    </h5>


                            </div>
                          </div>



                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                              <button
                                class="btn btn-success btn-square"
                                type="submit"
                                disabled={submitting}
                              >
                                UPLOAD 
                              </button>{" "}
                              &nbsp;
                              
                      

                              {
                              promoterhDetails && (
                                promoterhDetails.account_for === 'fd' ? (
                                  <a href={`/fixed-deposit-account-view/${dataEncript(promoterhDetails.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                                ) : promoterhDetails.account_for === 'mis' ? (
                                     <a href={`/fixed-deposit-mis-account-view/${dataEncript(promoterhDetails.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                                ): promoterhDetails.account_for === 'rd' ? (
                                  <a href={`/recurring-deposit-account-view/${dataEncript(promoterhDetails.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                                ) : promoterhDetails.account_for === 'dd' ? (
                                  <a href={`/recurring-deposit-account-view/${dataEncript(promoterhDetails.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                                ) : <a href={`/account-view/${dataEncript(promoterhDetails.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                              )
                            }

                              <ToastContainer className="toast-container" />
                            </div>
                          </div>


                        </form>
                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
