import React, { useState, useEffect } from "react";
import DashboardLayout from "../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {   dataDecrypt, FirstLetterCapitalize, formatDate, formattedAmount, dataEncript, deleteConfirmation, infoAlert, getCurrentDate } from "../../../helper/commonHelpers";
//formatDate

function CompanyDetails() {
  const [submitting, setSubmitting] = useState(false);
  const { accountID }   = useParams();
  const [shareHolderDetail, setshareHolderDetail] = useState([]);
//   const [memberList, setmemberList] = useState([]);

  const [formErrors, setFormErrors] = useState({
    member_id: "",
  });


 


  const[closer_date, setcloser_date] = useState('');
  const[current_valance, setcurrent_valance] = useState('0');
  const[intrest_left_to, setintrest_left_to] = useState('0');
  const[tds_to_be_deduct, settds_to_be_deduct] = useState('0.00');
  const[intrest_rate, setintrest_rate] = useState('0');
  const[intrest_day, setintrest_day] = useState('0');
  const[intrest_amount, setintrest_amount] = useState('0');
  const[permature_rate, setpermature_rate] = useState('0.00');
  const[permature_day, setpermature_day] = useState('0');
  const[permature_amount, setpermature_amount] = useState('0.00');
  const[reserve_interest, setreserve_interest] = useState('0');
  const[penal_intrest_rate, setpenal_intrest_rate] = useState('0.0');

    const[penal_charges_amount, setpenal_charges_amount] = useState('0.0');
    const[penal_charges_gst_rate, setpenal_charges_gst_rate] = useState('18.0');
    const[penal_charges_total_amount, setpenal_charges_total_amount] = useState('0.00');


  const[cancel_charge_amount, setcancel_charge_amount] = useState('0');
  const[cancel_charge_gst_rate, setcancel_charge_gst_rate] = useState('18.0');
  const[cancel_charge_t_amount, setcancel_charge_t_amount] = useState('0');
  const[total_account, settotal_account] = useState('0');
  const[rounding_off, setrounding_off] = useState('0');
  const[final_amount_release, setfinal_amount_release] = useState('0');
  


  useEffect(() => {
  

      axios.get(API_BASE_URL + "api-common/get-account-list?id="+dataDecrypt(accountID), {
        headers: API_HEADERS,
      }).then((response) => {

        console.log(response.data);

        if (response.data["status"] > 0) {

            console.log(response.data["data"]);
            setshareHolderDetail(response.data["data"]);

        } else {
            toast.error(response.data["message"]);
        }

      });

    
  
     

      setcloser_date(getCurrentDate());
  }, [accountID]);




  const updateDetails = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));

    const formData = {
        id:dataDecrypt(accountID),

    

            closer_date: closer_date, 
            current_valance: current_valance, 
            intrest_left_to: intrest_left_to, 
            tds_to_be_deduct: tds_to_be_deduct, 
            intrest_rate: intrest_rate, 
            intrest_day: intrest_day, 
            intrest_amount: intrest_amount, 
            permature_rate: permature_rate, 
            permature_day: permature_day, 
            permature_amount: permature_amount, 
            reserve_interest: reserve_interest, 
            penal_intrest_rate: penal_intrest_rate, 
            

            penal_charges_amount: penal_charges_amount,
            penal_charges_gst_rate: penal_charges_gst_rate,
            penal_charges_total_amount: penal_charges_total_amount,


            cancel_charge_amount: cancel_charge_amount, 
            cancel_charge_gst_rate: cancel_charge_gst_rate, 
            cancel_charge_t_amount: cancel_charge_t_amount, 
            total_account: total_account, 
            rounding_off: rounding_off, 
            final_amount_release: final_amount_release, 



    };

  console.log(formData);
     

    //   if(account_type==='joint')
    //   {
  
    //     if (!joint_ac1_member_id) {
    //         errors.joint_ac1_member_id = 'Joint A/c Member1 is required.';
    //       }
    
    //   }



    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {

        const confirmed = await deleteConfirmation('to change the Account Type?');
        if(confirmed===true)
        {

      axios.post(API_BASE_URL + "api-saving-account/change-account-type", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
            //   window.location.href = "/account-transaction-view/"+accountID+'/'+response.data["data"].id;

              window.location.reload();
            }, 2000);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 1000);
        }); // close query hare

    }
    setSubmitting(false);
    } else {
      setSubmitting(false);
    } // hare close error
  };


  

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4> fd Account - {shareHolderDetail && shareHolderDetail.account_no && (
                                    <>{shareHolderDetail.account_no}</>
                                    )} 

                            <span>&nbsp;Fore Close</span> </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/fixed-deposit-account">FD Accounts </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href={`/fixed-deposit-account-view/${dataEncript(shareHolderDetail.id)}`}> {shareHolderDetail && shareHolderDetail.account_no && (
                                    <>{shareHolderDetail.account_no}</>
                                    )} </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> Fore Close</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-7">
                    <div class="card" style={{borderTop:'3px solid red'}} >
                    
                        <h4 class="m-4">CHANGE ACCOUNT TYPE</h4>
                       <hr class="p-0 m-0"/>

                     

                      <div class="card-block">
                      <form onSubmit={updateDetails}>

                      <h6 class="mb-2">Are you sure you want to change account type?</h6>

                        

                      <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Closure Date<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                <input class="form-control" type="date" value={closer_date} onChange={(e) => setcloser_date(e.target.value) } placeholder="Enter Closure Date" />

                                  {formErrors.closer_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.closer_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Current Balance (A)<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                <input class="form-control" type="text" value={current_valance} onChange={(e) => setcurrent_valance(e.target.value) } placeholder="" readOnly />

                                  {formErrors.current_valance && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.current_valance}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Interest Left to Paid (B)<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                <input class="form-control" type="text" value={intrest_left_to} onChange={(e) => setintrest_left_to(e.target.value) } placeholder=""  />

                                  {formErrors.intrest_left_to && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.intrest_left_to}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  TDS to be Deducted (C)<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                <input class="form-control" type="text" value={tds_to_be_deduct} onChange={(e) => settds_to_be_deduct(e.target.value) } placeholder=""  />

                                  {formErrors.tds_to_be_deduct && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.tds_to_be_deduct}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Interest <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                    <div class="row">
                                            <div class="col-4">Rate (%)</div>
                                            <div class="col-4">Days</div>
                                            <div class="col-4">Amount (D)</div>
                                    </div>

                                    <div class="input-group">
                                        <div class="input-group-prepend" style={{width:'33.3%'}}>
                                                <input
                                                    class="form-control"
                                                    type="text"
                                                    value={intrest_rate}
                                                    onChange={(e) =>
                                                    setintrest_rate(e.target.value)
                                                    }

                                                    readOnly
                                                />
                                                
                                        </div>
                                        <input
                                            class="form-control"
                                            type="text"
                                            value={intrest_day}
                                            onChange={(e) =>
                                            setintrest_day(e.target.value)
                                            }
                                            readOnly
                                        />
                                            
                                        <div class="input-group-prepend" style={{width:'33.3%'}} >
                                                <input
                                                    class="form-control"
                                                    type="text"
                                                    value={intrest_amount}
                                                    onChange={(e) =>
                                                    setintrest_amount(e.target.value)
                                                    }
                                                    readOnly
                                                />
                                                
                                        </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-4">{formErrors.intrest_rate && (
                                                <span style={{ color: "red" }}>
                                                    {formErrors.intrest_rate}
                                                </span>
                                                )}</div>
                                            <div class="col-sm-4">{formErrors.intrest_day && (
                                                <span style={{ color: "red" }}>
                                                    {formErrors.intrest_day}
                                                </span>
                                                )}</div>
                                            <div class="col-sm-4">{formErrors.intrest_amount && (
                                                <span style={{ color: "red" }}>
                                                    {formErrors.intrest_amount}
                                                </span>
                                                )}</div>
                                            </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Premature Interest <span onClick={() => infoAlert("If you want to change the premature interest rate then change the premature interest rate as per your FD closure period.")}   ><i class="icofont icofont-info-circle"></i></span><span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                    <div class="row">
                                            <div class="col-4">Rate (%)</div>
                                            <div class="col-4">Days</div>
                                            <div class="col-4">Amount (E)</div>
                                    </div>

                                    <div class="input-group">
                                        <div class="input-group-prepend" style={{width:'33.3%'}}>
                                                <input
                                                    class="form-control"
                                                    type="text"
                                                    value={permature_rate}
                                                    onChange={(e) =>
                                                    setpermature_rate(e.target.value)
                                                    }
                                                />
                                                
                                        </div>
                                        <input
                                            class="form-control"
                                            type="text"
                                            value={permature_day}
                                            onChange={(e) =>
                                            setpermature_day(e.target.value)
                                            }

                                            readOnly
                                        />
                                            
                                        <div class="input-group-prepend" style={{width:'33.3%'}} >
                                                <input
                                                    class="form-control"
                                                    type="text"
                                                    value={permature_amount}
                                                    onChange={(e) =>
                                                    setpermature_amount(e.target.value)
                                                    }

                                                    readOnly
                                                />
                                                
                                        </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-4">{formErrors.permature_rate && (
                                                <span style={{ color: "red" }}>
                                                    {formErrors.permature_rate}
                                                </span>
                                                )}</div>
                                            <div class="col-sm-4">{formErrors.permature_day && (
                                                <span style={{ color: "red" }}>
                                                    {formErrors.permature_day}
                                                </span>
                                                )}</div>
                                            <div class="col-sm-4">{formErrors.permature_amount && (
                                                <span style={{ color: "red" }}>
                                                    {formErrors.permature_amount}
                                                </span>
                                                )}</div>
                                            </div>
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Reverse Interest Amount (F = D - E)<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                    <div class="input-group">
                                        <input
                                            class="form-control"
                                            type="text"
                                            value={reserve_interest}
                                            onChange={(e) =>
                                            setreserve_interest(e.target.value)
                                            }
                                        />
                                        <div class="input-group-prepend" style={{width:'auto'}} >
                                           <span class="input-group-text" onClick={() => infoAlert("This value is automatically calculated by the system based on the premature interest rate, and you also have the option to change it manually.")}   ><i class="icofont icofont-info-circle"></i></span>
                                        </div>
                                        </div>
                                        {formErrors.reserve_interest && (
                                            <span style={{ color: "red" }}>
                                            {formErrors.reserve_interest}
                                            </span>
                                        )}
                                        </div>
                                    </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-12 col-xl-12 m-b-30">
                                    <div class="row">
                                        <div class="col-sm-5">
                                        <h6 class="text-xl-right">
                                        Penal Interest Rate (%)<span class="text-danger">*</span>
                                        </h6>
                                        </div>
                                        <div class="col-sm-7">
                                                <input
                                                    class="form-control"
                                                    type="text"
                                                    value={penal_intrest_rate}
                                                    onChange={(e) =>
                                                    setpenal_intrest_rate(e.target.value)
                                                    }

                                                    readOnly
                                                />
                                                {formErrors.penal_intrest_rate && (
                                                    <span style={{ color: "red" }}>
                                                    {formErrors.penal_intrest_rate}
                                                    </span>
                                                )}
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    
                                        <div class="row">
                                            <div class="col-sm-12 col-xl-12 m-b-30">
                                            <div class="row">
                                                <div class="col-sm-5">
                                                <h6 class="text-xl-right">
                                                Penal Charges (G) <span onClick={() => infoAlert("Interest Rate : 7.7% Premature Interest Rate(a) : 7.7% Penal charges(b) : 0.0% Net Penal Charges is(a - b) : 7.7% Total Penal Interest Amount : 2373.0 (7.7% of 1250000.0 for 8 days) Penal charges : 0.0 (2373.0 - 2373.0)")}   ><i class="icofont icofont-info-circle"></i></span><span class="text-danger">*</span>
                                                </h6>
                                                </div>
                                                <div class="col-sm-7">
                                            
                                                    <div class="row">
                                                            <div class="col-4">Amount</div>
                                                            <div class="col-4">GST Rate (%)</div>
                                                            <div class="col-4">T. Amount</div>
                                                    </div>

                                                    <div class="input-group">
                                                        <div class="input-group-prepend" style={{width:'33.3%'}}>
                                                                <input
                                                                    class="form-control"
                                                                    type="text"
                                                                    value={penal_charges_amount}
                                                                    onChange={(e) =>
                                                                    setpenal_charges_amount(e.target.value)
                                                                    }
                                                                />
                                                                
                                                        </div>
                                                        <input
                                                            class="form-control"
                                                            type="text"
                                                            value={penal_charges_gst_rate}
                                                            onChange={(e) =>
                                                            setpenal_charges_gst_rate(e.target.value)
                                                            }
                                                            readOnly
                                                        />
                                                            
                                                        <div class="input-group-prepend" style={{width:'33.3%'}} >
                                                                <input
                                                                    class="form-control"
                                                                    type="text"
                                                                    value={penal_charges_total_amount}
                                                                    onChange={(e) =>
                                                                    setpenal_charges_total_amount(e.target.value)
                                                                    }
                                                                    readOnly
                                                                />
                                                                
                                                        </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-sm-4">{formErrors.penal_charges_amount && (
                                                                <span style={{ color: "red" }}>
                                                                    {formErrors.penal_charges_amount}
                                                                </span>
                                                                )}</div>
                                                            <div class="col-sm-4">{formErrors.penal_charges_gst_rate && (
                                                                <span style={{ color: "red" }}>
                                                                    {formErrors.penal_charges_gst_rate}
                                                                </span>
                                                                )}</div>
                                                            <div class="col-sm-4">{formErrors.penal_charges_total_amount && (
                                                                <span style={{ color: "red" }}>
                                                                    {formErrors.penal_charges_total_amount}
                                                                </span>
                                                                )}</div>
                                                            </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12 col-xl-12 m-b-30">
                                            <div class="row">
                                                <div class="col-sm-5">
                                                <h6 class="text-xl-right">
                                                Cancellation Charges (H)
                                                </h6>
                                                </div>
                                                <div class="col-sm-7">
                                            
                                                    <div class="row">
                                                            <div class="col-4">Amount</div>
                                                            <div class="col-4">GST Rate (%)</div>
                                                            <div class="col-4">T. Amount</div>
                                                    </div>

                                                    <div class="input-group">
                                                        <div class="input-group-prepend" style={{width:'33.3%'}}>
                                                                <input
                                                                    class="form-control"
                                                                    type="text"
                                                                    value={cancel_charge_amount}
                                                                    onChange={(e) =>
                                                                    setcancel_charge_amount(e.target.value)
                                                                    }
                                                                />
                                                                
                                                        </div>
                                                        <input
                                                            class="form-control"
                                                            type="text"
                                                            value={cancel_charge_gst_rate}
                                                            onChange={(e) =>
                                                            setcancel_charge_gst_rate(e.target.value)
                                                            }
                                                            readOnly
                                                        />
                                                            
                                                        <div class="input-group-prepend" style={{width:'33.3%'}} >
                                                                <input
                                                                    class="form-control"
                                                                    type="text"
                                                                    value={cancel_charge_t_amount}
                                                                    onChange={(e) =>
                                                                    setcancel_charge_t_amount(e.target.value)
                                                                    }
                                                                    readOnly
                                                                />
                                                                
                                                        </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-sm-4">{formErrors.cancel_charge_amount && (
                                                                <span style={{ color: "red" }}>
                                                                    {formErrors.cancel_charge_amount}
                                                                </span>
                                                                )}</div>
                                                            <div class="col-sm-4">{formErrors.cancel_charge_gst_rate && (
                                                                <span style={{ color: "red" }}>
                                                                    {formErrors.cancel_charge_gst_rate}
                                                                </span>
                                                                )}</div>
                                                            <div class="col-sm-4">{formErrors.cancel_charge_t_amount && (
                                                                <span style={{ color: "red" }}>
                                                                    {formErrors.cancel_charge_t_amount}
                                                                </span>
                                                                )}</div>
                                                            </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>


                                        <div class="row">
                                            <div class="col-sm-12 col-xl-12 m-b-30">
                                            <div class="row">
                                                <div class="col-sm-5">
                                                <h6 class="text-xl-right">
                                                Total Account (I = A + B - C - F - G - H)
                                                </h6>
                                                </div>
                                                <div class="col-sm-7">
                                            
                                                <input class="form-control" type="text" value={total_account} onChange={(e) => settotal_account(e.target.value) } placeholder=""  readOnly />

                                                {formErrors.total_account && (
                                                    <span style={{ color: "red" }}>
                                                    {formErrors.total_account}
                                                    </span>
                                                )}
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12 col-xl-12 m-b-30">
                                            <div class="row">
                                                <div class="col-sm-5">
                                                <h6 class="text-xl-right">
                                                   Rounding Off (J)<span class="text-danger">*</span>
                                                </h6>
                                                </div>
                                                <div class="col-sm-7">
                                            
                                                <input class="form-control" type="text" value={rounding_off} onChange={(e) => setrounding_off(e.target.value) } placeholder=""  readOnly />

                                                {formErrors.rounding_off && (
                                                    <span style={{ color: "red" }}>
                                                    {formErrors.rounding_off}
                                                    </span>
                                                )}
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12 col-xl-12 m-b-30">
                                            <div class="row">
                                                <div class="col-sm-5">
                                                <h6 class="text-xl-right">
                                                Final Amount To Release (I - J) (if any)<span class="text-danger">*</span>
                                                </h6>
                                                </div>
                                                <div class="col-sm-7">
                                            
                                                <input class="form-control" type="text" value={final_amount_release} onChange={(e) => setfinal_amount_release(e.target.value) } placeholder=""  readOnly />

                                                {formErrors.final_amount_release && (
                                                    <span style={{ color: "red" }}>
                                                    {formErrors.final_amount_release}
                                                    </span>
                                                )}
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                    

                        <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                            <button
                              class="btn btn-success btn-square"
                              type="submit"
                              disabled={submitting}
                            >
                              RAISE REQUEST
                            </button>{" "}
                            <a href={`/fixed-deposit-account-view/${dataEncript(shareHolderDetail.id)}`} class="btn btn-danger btn-square">Cancel</a>
                            &nbsp;
                          

                            <ToastContainer className="toast-container" />
                          </div>
                        </div>


                      </form>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-5">
                  


                    <div class="card  " style={{borderTop:'3px solid #fe9365'}} >
                    <div class="card-header py-3 text-denger">
                            <h5>FD Info</h5>
                                <div class="card-header-right">
                                        <ul class="list-unstyled card-option">
                                            <li><i class="feather icon-minus minimize-card"></i></li>
                                        </ul>
                                </div>
                            </div>
                            <div class="card-block p-0" style={{display:'none'}}>
                                <form > 
                                    <div class="table-responsive dt-responsive">
                                            <table class="table dtable table-striped table-bordered">
                                          
                                         <tbody>
                                            
                                         <tr>
                                                <th> <>Member</> </th>
                                                <td>{shareHolderDetail && shareHolderDetail.member_name && (
                                                    <a href={`/view-member/${dataEncript(shareHolderDetail.member_id)}`} >
                                                        {FirstLetterCapitalize(shareHolderDetail.member_created_id)}
                                                        {FirstLetterCapitalize(shareHolderDetail.member_name)}
                                                    </a>
                                                    )} </td>
                                            </tr>

                                            <tr>
                                                <th> <>Open Date</> </th>
                                                <td>{shareHolderDetail && shareHolderDetail.open_date && (
                                                    <>{formatDate(shareHolderDetail.open_date)}</>
                                                    )} </td>
                                            </tr>

                                            <tr>
                                                <th> <>Maturity Date</> </th>
                                                <td>{shareHolderDetail && shareHolderDetail.open_date && (
                                                    <>{formatDate(shareHolderDetail.open_date)}</>
                                                    )} </td>
                                            </tr>

                                            <tr>
                                                <th> <>Status</> </th>
                                                <td>{
                                                    shareHolderDetail && (
                                                        shareHolderDetail.account_status === 'active' ? (
                                                        <label className="label label-success">Active</label>
                                                        ) : shareHolderDetail.account_status === 'inactive' ? (
                                                        <label className="label label-danger">Inactive</label>
                                                        ) : null
                                                    )
                                                    }</td>
                                            </tr>
                                            
                                         </tbody>
                                      </table>
                                     </div>
                                 </form>
                            </div>
                          </div>

                          <div class="card  mt-3 " style={{borderTop:'3px solid green'}} >
                    <div class="card-header py-3 text-denger">
                            <h5>Scheme Info</h5>
                                <div class="card-header-right">
                                        <ul class="list-unstyled card-option">
                                            <li><i class="feather icon-minus minimize-card"></i></li>
                                        </ul>
                                </div>
                            </div>
                            <div class="card-block p-0" style={{display:'none'}}>
                                <form > 
                                    <div class="table-responsive dt-responsive">
                                            <table class="table dtable table-striped table-bordered">
                                          
                                         <tbody>
                                            
                                         <tr>
                                                <th> Scheme </th>
                                                <td>{shareHolderDetail && shareHolderDetail.scheme_name && (
                                                    <>{(shareHolderDetail.scheme_name)}</>
                                                    )} </td>
                                            </tr>

                                            <tr>
                                                <th> Tenure</th>
                                                <td> {shareHolderDetail && shareHolderDetail.years && (
                                                <>{(shareHolderDetail.years)}Y</>
                                                )}

                                                 {shareHolderDetail && shareHolderDetail.month && (
                                                <>, {(shareHolderDetail.month)}M</>
                                                )}

                                                  {shareHolderDetail && shareHolderDetail.days && (
                                                <>, {(shareHolderDetail.days)}D</>
                                                )}
                                                
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>FD Lock In Period </th>
                                                <td>{shareHolderDetail && shareHolderDetail.lock_in_period && (
                                                    <>{(shareHolderDetail.lock_in_period)}</>
                                                    )} Month</td>
                                            </tr>

                                            <tr>
                                                <th>Annual Interest Rate </th>
                                                <td></td>
                                            </tr>

                                            <tr>
                                                <th>Interest Lock In Period </th>
                                                <td>{shareHolderDetail && shareHolderDetail.ins_lock_period && (
                                                    <>{(shareHolderDetail.ins_lock_period)}</>
                                                    )} Month</td>
                                            </tr>

                                            <tr>
                                                <th>Penal Charges </th>
                                                <td>{shareHolderDetail && shareHolderDetail.penal_charge && (
                                                    <>{(shareHolderDetail.penal_charge)}</>
                                                    )} </td>
                                            </tr>

                                            <tr>
                                                <th>Fore Closure Charges </th>
                                                <td>{shareHolderDetail && shareHolderDetail.penal_charge && (
                                                    <>{formattedAmount(shareHolderDetail.penal_charge)}</>
                                                    )} </td>
                                            </tr>


                                          
                                            
                                         </tbody>
                                      </table>
                                     </div>
                                 </form>
                            </div>
                          </div>


                          <div class="card  mt-3 " style={{borderTop:'3px solid #fe5d70'}} >
                    <div class="card-header py-3 text-denger">
                            <h5>Interest info till date :</h5>
                                <div class="card-header-right">
                                        <ul class="list-unstyled card-option">
                                            <li><i class="feather icon-minus minimize-card"></i></li>
                                        </ul>
                                </div>
                            </div>
                            <div class="card-block p-0">
                                <form > 
                                    <div class="table-responsive dt-responsive">
                                            <table class="table dtable table-striped table-bordered">
                                          
                                         <tbody>
                                            
                                         <tr>
                                                <th> Interest Credited </th>
                                                <td> {shareHolderDetail && shareHolderDetail.scheme_name && (
                                                    <>{formattedAmount(0)}</>
                                                     )}</td>
                                            </tr>

                                            <tr>
                                                <th> Interest Released</th>
                                                <td> {shareHolderDetail && shareHolderDetail.scheme_name && (
                                                    <>{formattedAmount(0)}</>
                                                     )}</td>
                                            </tr>

                                            <tr>
                                                <th>TDS Deducted </th>
                                                <td> {shareHolderDetail && shareHolderDetail.scheme_name && (
                                                    <>{formattedAmount(0)}</>
                                                     )}</td>
                                            </tr>

                                            <tr>
                                                <th>Interest Available to Release </th>
                                                <td> {shareHolderDetail && shareHolderDetail.scheme_name && (
                                                    <>{formattedAmount(0)}</>
                                                     )}</td>
                                            </tr>

                                          

                                          
                                            
                                         </tbody>
                                      </table>
                                     </div>
                                 </form>
                            </div>
                          </div>



                  </div>
                </div>


                

              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
          <ToastContainer className="toast-container" />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
