import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {   dataDecrypt, FirstLetterCapitalize, formatDate, formattedAmount, dataEncript } from "./../../../helper/commonHelpers";
//formatDate

function CompanyDetails() {
  const [submitting, setSubmitting] = useState(false);
  const { accountID }   = useParams();
  const [shareHolderDetail, setshareHolderDetail] = useState([]);
  const [scheemList, setscheemList] = useState([]);



  const [formErrors, setFormErrors] = useState({
    member_id: "",
    transaction_date: "",
    amount: "",
  });


  // const[transfer_date, settransfer_date] = useState('');  
  // const[shares, setshares] = useState('10');
  // const[face_value, setface_value] = useState('10.0');
  // const[total_consideration, settotal_consideration] = useState('100');
  
const[pay_mode, setpay_mode] = useState('no');
const[scheme_id, setscheme_id] = useState('');
const[avl_val, setavl_val] = useState('');

  useEffect(() => {
  

      axios.get(API_BASE_URL + "api-common/get-account-list?id="+dataDecrypt(accountID), {
        headers: API_HEADERS,
      }).then((response) => {

        console.log(response.data);

        if (response.data["status"] > 0) {
            setshareHolderDetail(response.data["data"]);
            setavl_val(response.data["data"].mis_amount);

        }

      });

    

      axios.get(API_BASE_URL+'api-common/get-account-scheme-list', {
        headers: API_HEADERS
        }).then(response => {
           if (response.data["status"] > 0) {
            setscheemList(response.data['data']);
           }
         //   console.log(response.data['data']);
     });


  }, [accountID]);




  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));

    const formData = {
        id:dataDecrypt(accountID),

        pay_mode: pay_mode,
        scheme_id: scheme_id

    };




      if(pay_mode==='yes')
      {
        if (!formData.scheme_id) {
          errors.scheme_id = 'New Scheme is required.';
        }
      }






    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      axios
        .post(API_BASE_URL + "api-saving-account/upgrade-account", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/account-view/"+accountID;
            //   window.location.reload();
            }, 2000);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 1000);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };




  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4> Account - {shareHolderDetail && shareHolderDetail.account_no && (
                                    <>{shareHolderDetail.account_no}</>
                                    )} 

                            <span>&nbsp;Upgrade</span> </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/account-list">Accounts </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href={`/account-view/${dataEncript(shareHolderDetail.id)}`}> {shareHolderDetail && shareHolderDetail.account_no && (
                                    <>{shareHolderDetail.account_no}</>
                                    )} </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> Upgrade</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-7">
                    <div class="card" style={{borderTop:'3px solid red'}} >
                    

                      <div class="card-block">
                      <form onSubmit={updateDetails}>


                   

                         


                        <div class="row">
                        <div class="col-sm-12 col-xl-10 offset-xl-1  m-b-30">
                            <div class="row">
                              <div class="col-sm-4">
                                <h6 class="text-xl-right">
                                Upgrade Account<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-radio row ml-3">

                                <div class="radio radio-inline col-xs-3 ">
                                    <label for="title_chque">
                                      <input
                                        type="radio"
                                        name="pay_mode"
                                        id={"title_chque"}
                                        value={"yes"}
                                        checked={pay_mode === 'yes' ? 'checked' : ''}
                                        onChange={(e) =>
                                          setpay_mode(e.target.value)
                                        }
                                      />
                                      <i class="helper"></i>Yes
                                    </label>
                                  </div>

                                  <div class="radio radio-inline col-xs-3 ">
                                    <label for="title_cash">
                                      <input
                                        type="radio"
                                        name="pay_mode"
                                        value={"no"}
                                        id={"title_cash"}
                                        checked={pay_mode === 'no' ? 'checked' : ''}
                                        onChange={(e) =>
                                          setpay_mode(e.target.value)
                                        }
                                      />
                                      <i class="helper"></i>No
                                    </label>
                                  </div>
                                  
                                  
                                  
                                </div>
                                {formErrors.pay_mode && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.pay_mode}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row" style={{ display: pay_mode === 'yes' ? '' : 'none' }}>
                        <div class="col-sm-12 col-xl-12  m-b-30">
                            <div class="row">
                              <div class="col-sm-4">
                                <h6 class="text-xl-right">
                                New Scheme<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-sm-6">
                              <select
                                  class="form-control m-b"
                                  name="scheme_id"
                                  value={scheme_id}
                                  onChange={(e) =>
                                    setscheme_id(e.target.value)
                                  }
                                >
                                  <option value={''} disabled>Select Scheme </option>
                                  {scheemList.map((scheemData) => (
                                    <option
                                      value={scheemData.id}
                                      selected={
                                        scheemData.id === scheme_id
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {scheemData.scheme_code?FirstLetterCapitalize(scheemData.scheme_code):''}
                                      {scheemData.scheme_name? ' - '+ FirstLetterCapitalize(scheemData.scheme_name):''}

                                    </option>
                                  ))}
                                </select>
                                {formErrors.scheme_id && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.scheme_id}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>



                        <div class="row" style={{ display: pay_mode === 'yes' ? '' : 'none' }}>
                        <div class="col-sm-12 col-xl-12  m-b-30">
                            <div class="row">
                              <div class="col-sm-4">
                                <h6 class="text-xl-right">
                                Balance Available
                                </h6>
                              </div>
                              <div class="col-sm-6">
                              <input
                                      class="form-control"
                                      type="text"
                                      value={avl_val}
                                      onChange={(e) =>
                                      setavl_val(e.target.value)
                                      }
                                      placeholder="0 "
                                      readOnly
                                  />
                                {formErrors.avl_val && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.avl_val}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                       


                        <div class="row">
                          <div class="col-sm-12 col-xl-10 offset-xl-1 m-b-30 text-center">
                            <button
                              class="btn btn-success btn-square"
                              type="submit"
                              disabled={submitting}
                            >
                              UPGRADE ACCOUNT
                            </button>{" "}
                            <a href={`/account-view/${dataEncript(shareHolderDetail.id)}`} class="btn btn-danger btn-square">Cancel</a>
                            &nbsp;
                          

                            <ToastContainer className="toast-container" />
                          </div>
                        </div>


                      </form>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-5">
                    <div class="card" style={{borderTop:'3px solid green'}} >

                      <div class="card-block">
                        <form onSubmit={updateDetails}>

                        <div class="table-responsive dt-responsive">
                            <table class="table dtable table-striped table-bordered">
                            <tr>
                                <th>Member.</th>
                                <td> {shareHolderDetail && shareHolderDetail.member_name && (
                                    <a href={`/account-view/${dataEncript(shareHolderDetail.member_id)}`} >
                                        {FirstLetterCapitalize(shareHolderDetail.member_name)}
                                      </a>
                                    )} </td>
                            </tr>
                            <tr>
                                <th>PAN No.</th>
                                <td> {shareHolderDetail && shareHolderDetail.pan && (
                                    <>{shareHolderDetail.pan}</>
                                    )}</td>
                            </tr>
                            <tr>
                                <th>Joint Account</th>
                                <td>{
                                      shareHolderDetail && (
                                        shareHolderDetail.account_type === 'joint' ? (
                                          <label className="label label-success">Yes</label>
                                        ) : shareHolderDetail.account_type === 'single' ? (
                                          <label className="label label-danger">No</label>
                                        ) :null
                                      )
                                    }
                                   </td>
                            </tr>
                            <tr>
                                <th>Account No.</th>
                                <td> {shareHolderDetail && shareHolderDetail.account_no && (
                                    <>{shareHolderDetail.account_no}</>
                                    )} </td>
                            </tr>
                            <tr>
                                <th>Scheme</th>
                                <td>
                                {shareHolderDetail && shareHolderDetail.scheme_name && (
                                    <>{shareHolderDetail.scheme_name}</>
                                    )} 
                                </td>
                            </tr>
                            <tr>
                                <th>Open Date</th>
                                <td>
                                {shareHolderDetail && shareHolderDetail.open_date && (
                                    <>{formatDate(shareHolderDetail.open_date)}</>
                                    )} 
                                </td>
                            </tr>

                            <tr>
                                <th>Status</th>
                                <td>{
                                      shareHolderDetail && (
                                        shareHolderDetail.account_status === 'active' ? (
                                          <label className="label label-success">Active</label>
                                        ) : shareHolderDetail.account_status === 'inactive' ? (
                                          <label className="label label-danger">Inactive</label>
                                        ) : null
                                      )
                                    }</td>
                            </tr>

                            <tr>
                                <th>Lock Balance (A)</th>
                                <td> {shareHolderDetail && shareHolderDetail.lock_amount && (
                                      <>{formattedAmount(shareHolderDetail.lock_amount)}</>
                                      )}</td>
                            </tr>

                            <tr>
                                <th>Hold Balance (B)</th>
                                <td> {shareHolderDetail && shareHolderDetail.lock_amt && (
                                      <>{formattedAmount(shareHolderDetail.lock_amt)}</>
                                      )}</td>
                            </tr>

                            <tr>
                                <th>Available Balance (C)</th>
                                <td> {shareHolderDetail && shareHolderDetail.mis_amount && (
                                      <>{formattedAmount(shareHolderDetail.mis_amount)}</>
                                      )}</td>
                            </tr>

                            <tr>
                                <th>Combined Balance (A + B + C + D - E)</th>
                                <td> {shareHolderDetail && shareHolderDetail.lock_amt && (
                                      <>{formattedAmount(shareHolderDetail.lock_amt)}</>
                                      )}</td>
                            </tr>

                            </table>
                        </div>

                        </form>
                      </div>
                    </div>
                  </div>


                </div>


                

              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
          <ToastContainer className="toast-container" />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
