import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {   dataDecrypt, dataEncript, deleteConfirmation } from "./../../../helper/commonHelpers";
//formatDate

function CompanyDetails() {
  const [submitting, setSubmitting] = useState(false);
  const { accountID }   = useParams();
  const [shareHolderDetail, setshareHolderDetail] = useState([]);

  const [formErrors, setFormErrors] = useState({
    member_id: "",
  });


  const[nominee, setnominee] = useState('no');
  const [name, setname] = useState(['']);
  const [relation, setrelation] = useState(['']);
  const [address, setaddress] = useState(['']);
  const [nominees, setNominees] = useState(['']);
  const [nomineesID, setNomineesID] = useState(['']);




  useEffect(() => {
  

      axios.get(API_BASE_URL + "api-common/get-account-list?id="+dataDecrypt(accountID), {
        headers: API_HEADERS,
      }).then((response) => {

        console.log(response.data);

        if (response.data["status"] > 0) {
            setshareHolderDetail(response.data["data"]);
            setnominee(response.data['data'].nominee);



            if (response.data["data"].nominee_list && (response.data["data"].nominee_list).length > 0) {
                const idArray = response.data['data'].nominee_list.map(nomineeData => nomineeData.name); 
                const nameArray = response.data['data'].nominee_list.map(nomineeData => nomineeData.name); 
                const relationArray = response.data['data'].nominee_list.map(nomineeData => nomineeData.relation); 
                const addressArray = response.data['data'].nominee_list.map(nomineeData => nomineeData.address); 

                setNomineesID(idArray);
                setNominees(nameArray);
                setname(nameArray);
                setrelation(relationArray);
                setaddress(addressArray);

            }




        } else {
            toast.error(response.data["message"]);
        }

      });

   
     


  }, [accountID]);




  const updateDetails = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));

    const formData = {
            table_id:dataDecrypt(accountID),

            type:'account',
            is_nominee: nominee,
            name: name,
            relation: relation,
            address: address,
            nominee_id: nomineesID

    };

     

    if(nominee==='yes')
    {

        if (!name) {
          errors.name = 'Name is required.';
        }

        if (!relation) {
          errors.relation = 'Relation is required.';
        }

        if (!address) {
          errors.address = 'Address is required.';
        }

    }



    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {

        const confirmed = await deleteConfirmation('to change the Account Type?');
        if(confirmed===true)
        {

      axios.post(API_BASE_URL + "api-saving-account/update-saving-account-nominee", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/account-view/"+accountID;

            //   window.location.reload();
            }, 2000);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 1000);
        }); // close query hare

    }
    setSubmitting(false);
    } else {
      setSubmitting(false);
    } // hare close error
  };




  

  const addNominee = () => {
    setNominees([...nominees, '']);
};

const removeNominee = (index) => {
 const updatedNominees = [...nominees];
 updatedNominees.splice(index, 1);
 setNominees(updatedNominees);
};

const handleNomineeChange = (index, value) => {
 const updatedNominees = [...nominees];
 updatedNominees[index] = value;
 setNominees(updatedNominees);
 setname(updatedNominees);
};

const handleRelation = (index, value) => {
 const updatedRelation = [...relation];
 updatedRelation[index] = value;
 setrelation(updatedRelation);
};

const handleAddress = (index, value) => {
 const updatedAddress = [...address];
 updatedAddress[index] = value;
 setaddress(updatedAddress);
};


  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4> Account - {shareHolderDetail && shareHolderDetail.account_no && (
                                    <>{shareHolderDetail.account_no}</>
                                    )} 

                            <span>&nbsp;Nominee</span> </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/account-list">Accounts </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href={`/account-view/${dataEncript(shareHolderDetail.id)}`}> {shareHolderDetail && shareHolderDetail.account_no && (
                                    <>{shareHolderDetail.account_no}</>
                                    )} </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> Nominee</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="card" style={{borderTop:'3px solid red'}} >
                    
                        <h4 class="m-4">Update Nominee Details</h4>
                       <hr class="p-0 m-0"/>

                     

                      <div class="card-block">
                      <form onSubmit={updateDetails}>



                      <div class="row" >
                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="">
                                  Nominee <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-8">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="nominee_yes">
                                        <input
                                          type="radio"
                                          name="nominee"
                                          value={"yes"}
                                          id={"nominee_yes"}
                                          checked={nominee === 'yes' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setnominee(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Yes
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="nominee_no">
                                        <input
                                          type="radio"
                                          name="nominee"
                                          id={"nominee_no"}
                                          value={"no"}
                                          checked={nominee === 'no' ? 'checked' : ''}
                                          onChange={(e) =>
                                            setnominee(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>No
                                      </label>
                                    </div>
                                  
                                    
                                  </div>
                                  {formErrors.nominee && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.nominee}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style={{ display: nominee === 'yes' ? '' : 'none' }}  >
                          <div class="col-sm-12 col-xl-12 m-b-30">

                          {nominees.map((nomineval, index) => (
                          <div class="row" key={index} style={{ display: nominee === 'no' ? 'none' : '' }}>
                          <div class="col-sm-12"> <hr></hr></div>
                          <div class="col-sm-10">
                             <div class="row">

                             <div class="col-sm-12 col-xl-4 m-b-30">
                                    <div class="row">
                                    
                                        <div class="col-sm-12">
                                        <h6 class="">
                                                                Relation<span class="text-danger">*</span>
                                                            </h6>
                                        <select class="form-control"
                                                name="relation"
                                                value={relation[index]}
                                                onChange={(e) =>
                                                  handleRelation(index, e.target.value)
                                                }
                                            >
                                                <option value="">Select Relation</option>
                                                <option value="father">Father</option>
                                                <option value="mother">Mother</option>
                                                <option value="son">Son</option>
                                                <option value="daughter">Daughter</option>
                                                <option value="spouse">
                                                Spouse (Husband/ Wife)
                                                </option>
                                                <option value="husband">Husband</option>
                                                <option value="wife">Wife</option>
                                                <option value="brother">Brother</option>
                                                <option value="sister">Sister</option>
                                                <option value="daughter_in_law">
                                                Daughter in Law
                                                </option>
                                                <option value="brother_in_law">
                                                Brother in Law
                                                </option>
                                                <option value="grand_daughter">
                                                Grand Daughter
                                                </option>
                                                <option value="grand_son">Grand Son</option>
                                                <option value="nephew">Nephew</option>
                                                <option value="niece">Niece</option>
                                                <option value="other">Other</option>
                                            </select>

                                            {formErrors.relation && <span style={{ color: 'red' }}>{formErrors.relation}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-xl-4 m-b-30">
                                    <div class="row">
                                    
                                        <div class="col-sm-12">
                                        <h6 class="">Name<span class="text-danger">*</span></h6>
                                        <input class="form-control" type="text" value={nominees[index]} onChange={(e) => {handleNomineeChange(index, e.target.value);}} 
                                            placeholder={`Nominee ${index + 1}`} />
                                        {formErrors.name && <span style={{ color: 'red' }}>{formErrors.name}</span>}
                                        </div>
                                    </div>
                                </div>

                               

                                <div class="col-sm-12 col-xl-4 m-b-30">
                                    <div class="row">
                                   
                                        <div class="col-sm-12">
                                        <h6 class="">Address<span class="text-danger">*</span> </h6>
                                          <textarea class="form-control" value={address[index]} onChange={(e) => handleAddress(index, e.target.value)}>{address[index]}</textarea>
                                          {formErrors.address && <span style={{ color: 'red' }}>{formErrors.address}</span>}
                                        </div>
                                    </div>
                                </div>
                                
                                </div>
                                </div>
                                <div class="col-sm-2"> 
                                        <a href="#a" class="text-info" onClick={() => removeNominee(index)}>Remove</a>
                                </div>
                                
                                </div>
                                  ))}


                              <div class="row">
                                <div class="col-sm-4">
                                 
                                      
                                  <h5 className="btn text-info" onClick={addNominee} style={{ display: nominee === 'no' ? 'none' : '' }}>
                                        + ADD MORE NOMINEE
                                   </h5>

                                </div>
                                <div class="col-sm-8">
                                
                                </div>
                              </div>
                            </div>
                          </div>







                      

                         


                        <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                            <button
                              class="btn btn-success btn-square"
                              type="submit"
                              disabled={submitting}
                            >
                              UPDATE
                            </button>{" "}
                            <a href={`/account-view/${dataEncript(shareHolderDetail.id)}`} class="btn btn-danger btn-square">Cancel</a>
                            &nbsp;
                          

                            <ToastContainer className="toast-container" />
                          </div>
                        </div>


                      </form>
                      </div>
                    </div>
                  </div>

                


                </div>


                

              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
          <ToastContainer className="toast-container" />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
