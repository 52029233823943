import React, { useState, useEffect } from 'react';
import DashboardLayout from './../../dashboardLayout';

import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
// import $ from 'jquery';





import {
      formatDate,
      dataEncript
    } from './../../../helper/commonHelpers'; 

function AdminLayout() {
   const [promoterList, setpromoterList] = useState([]);

    useEffect(() => {
            axios.get(API_BASE_URL+'api-company/get-unencumbered-deposite-list', { headers: API_HEADERS })
            .then(response => {
                  if (response.data["status"] > 0) {
                         setpromoterList(response.data['data']);
                  }
            })
            .catch(error => {
              console.error('Failed to fetch role list list!', error);
            });
    }, []);






    useEffect(() => {
      const timeout = setTimeout(() => {
        if (window.tableDomJquery) {
          window.tableDomJquery();
        }
      }, 1000); // Timeout of 3000 milliseconds (3 seconds)
  
      return () => clearTimeout(timeout); // Clear the timeout on component unmount
    }, [promoterList]);




  return (
    <DashboardLayout>
<div class="pcoded-content">
      <div class="pcoded-inner-content">

            <div class="main-body">
                  <div class="page-wrapper">

                        <div class="page-header">
                    <div class="row align-items-end">
                        <div class="col-lg-8">
                            <div class="page-header-title">
                                <div class="d-inline">
                                    <h4>Unencumbered Deposit  
                                    <a href="/add-unencumbered-deposits"  title='Add New Branch'> <i class="fa fa-plus btn btn-info float-right ml-4 btn-xs"></i> </a>
                                           </h4>
                                    {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="page-header-breadcrumb">
                                <ul class="breadcrumb-title">
                                    <li class="breadcrumb-item" style={{float:'left'}}>
                                        <a href="/dashboard"> <i class="feather icon-home"></i> </a>
                                    </li>
                                    <li class="breadcrumb-item" style={{float:'left'}}><a href="#!">Unencumbered Deposit</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


            <div class="page-body">

                  <div class="card">
                        {/* <div class="card-header">
                              <h5>DOM/Jquery</h5>
                              <span>Events assigned to the table can be exceptionally useful for user
                                    interaction, however you must be aware that DataTables will add and
                                    remove rows from the DOM as they are needed (i.e. when paging only
                                    the visible elements are actually available in the DOM). As such,
                              this can lead to the odd hiccup when working with events.</span>
                        </div> */}
                        <div class="card-block">
                              <div class="table-responsive dt-responsive">
                                    <table class="table dtable table-striped table-bordered nowrap" style={{width:'100%'}}>
                                          <thead>
                                                <tr>
                                                      <th>SR.NO</th>
                                                      <th>BANK NAME</th>
                                                      <th>FD NO. </th>
                                                      <th>AMOUNT</th>
                                                      <th>OPEN DATE</th>
                                                      <th>MATURITY DATE</th>
                                                      <th>INTEREST RATE(%)</th>
                                                      <th>FROM DEPOSIT MONEY</th>
                                                      <th>ACTIONS</th>
                                                </tr>

                                          </thead>
                                          <tbody>
                                          {
                                                promoterList.length > 0 ? (
                                                  promoterList.map((branchData, index) => (
                                                      <tr class="gradeX">
                                                      <td>{index + 1}</td>
                                                      <td>{branchData.bank_name?branchData.bank_name:''}</td>
                                                      <td>{branchData.fd_no?branchData.fd_no:''}</td>
                                                      <td>{branchData.fd_amount?branchData.fd_amount:''}</td>
                                                      <td>{branchData.open_date?formatDate(branchData.open_date):''}</td>
                                                      <td>{branchData.maturity_date?formatDate(branchData.maturity_date):''}</td>
                                                      <td>{branchData.annual_interest_rate?branchData.annual_interest_rate:''}</td>
                                                      <td>
                                                              {branchData && branchData.is_fd_mode==='yes' && (
                                                            <label class="label label-success">Yes</label>
                                                            )}
                                                            {branchData && branchData.is_fd_mode==='no' && (
                                                            <label class="label label-danger">No</label>
                                                            )}
                                                      </td>
                                                   
                                                      <td class="center">
                                                            <a href={`/view-unencumbered-deposits/${dataEncript(branchData.id)}`} class='font-weight-normal label label-success'><i class="fa fa-eye"></i></a>&nbsp;
                                                            <a href={`/edit-unencumbered-deposits/${dataEncript(branchData.id)}`}  class='font-weight-normal label label-info'> <i class="fa fa-edit"></i> </a>
                                                            
                                                      </td>
                                                </tr>
                                                  ))
                                                ) : (
                                                  <tr>
                                                    <td colSpan="9">No data available</td>
                                                  </tr>
                                                )
                                              }

                                          
                                               
                                          </tbody>
                                        
                                    </table>
                              </div>
                        </div>
                  </div>






            </div>

      </div>
</div>

<div id="styleSelector">
</div>
</div>
</div>
    </DashboardLayout>
  );
}

export default AdminLayout;
