import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom';

import $ from 'jquery';
import 'dropify/dist/css/dropify.min.css';
import 'dropify';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { formatDate, dataDecrypt } from "./../../../helper/commonHelpers";

function CompanyDetails() {
    const { unencumberedID }   = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [dataDetails, setdataDetails] = useState([]);
  const [bankList, setbankList] = useState([]);

  const [formErrors, setFormErrors] = useState({
    bank_name: "",
    fd_no: "",
    fd_amount: "",
    annual_interest_rate: "",
    open_date: "",
    maturity_date: "",
    receipt_sacn_copy: "",
  });

  const[bank_name, setbank_name] = useState('');
  const[fd_no, setfd_no] = useState('');
  const[fd_amount, setfd_amount] = useState('');
  const[annual_interest_rate, setannual_interest_rate] = useState('');
  const[open_date, setopen_date] = useState('');
  const[maturity_date, setmaturity_date] = useState('');
  const[is_fd_mode, setis_fd_mode] = useState('no');
  const[receipt_sacn_copy, setreceipt_sacn_copy] = useState('');


  useEffect(() => {
    $('.dropify').dropify();
    // GET ACTIVE BRANCH LIST
    axios
      .get(API_BASE_URL + "api-common/get-bank-list", {
        headers: API_HEADERS,
      }).then((response) => {

        if (response.data["status"] > 0) {
          setbankList(response.data["data"]);
          }

      }).catch((error) => {
        toast.error("Failed to fetch data!");
      });


      axios.get(API_BASE_URL + "api-company/get-unencumbered-deposite-by-id?unencumbered_id="+dataDecrypt(unencumberedID), {
        headers: API_HEADERS,
      }).then((response) => {
        if (response.data["status"] > 0) {
        
            
            setdataDetails(response.data["data"]);

            setbank_name(response.data["data"].bank_name);
            setfd_no(response.data["data"].fd_no);
            setfd_amount(response.data["data"].fd_amount);
            setannual_interest_rate(response.data["data"].annual_interest_rate);

            setopen_date(response.data["data"].open_date?formatDate(response.data["data"].open_date, 'YMD'):'');
            setmaturity_date(response.data["data"].maturity_date?formatDate(response.data["data"].maturity_date, 'YMD'):'');

            setis_fd_mode(response.data["data"].is_fd_mode);
            setreceipt_sacn_copy(response.data["data"].receipt_sacn_copy);

            console.log(response.data["data"]); 
           
        }
      }).catch((error) => {
        toast.error("Failed to director details data!");
      });



  }, [unencumberedID]);


  const handleLogoImageChange = (e) => {
    const file = e.target.files[0]; 
    setreceipt_sacn_copy(file); 
  };


  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};

    const formData = new FormData();
    if (receipt_sacn_copy) {
      formData.append('receipt_sacn_copy', receipt_sacn_copy); 
    }

    formData.append('bank_name', bank_name); 
    formData.append('fd_no', fd_no); 
    formData.append('fd_amount', fd_amount); 
    formData.append('annual_interest_rate', annual_interest_rate); 
    formData.append('open_date', open_date); 
    formData.append('maturity_date', maturity_date); 
    formData.append('is_fd_mode', is_fd_mode); 
    formData.append('unencumbered_id', dataDecrypt(unencumberedID)); 


    // validate form
     if (!bank_name) {
        errors.bank_name = 'Bank name is required.';
      }

      if (!fd_no) {
        errors.fd_no = 'FD No. is required.';
      }

      if (!fd_amount) {
        errors.fd_amount = 'FD Amount is required.';
      }

      if (!annual_interest_rate) {
        errors.annual_interest_rate = 'Annual interest Rate is required.';
      }

      if (!open_date) {
        errors.open_date = 'Open Date is required.';
      }

      if (!maturity_date) {
        errors.maturity_date = 'Maturity Date is required.';
      }
   

    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
        axios.post(API_BASE_URL + 'api-company/update-unencumbered-deposite', formData, {
          headers: {
            ...API_HEADERS,
            'Content-Type': 'multipart/form-data', // Set content type for FormData
          },
        }).then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/unencumbered-deposits";
            }, 3700);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 300);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>Edit -  {dataDetails && dataDetails.bank_name && (
                                        <>{dataDetails.bank_name}</>
                                        )}
                        </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/unencumbered-deposits">Unencumbered Deposit</a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">{dataDetails && dataDetails.bank_name && (
                                        <>{dataDetails.bank_name}</>
                                        )}</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">edit </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12"></div>

                  <div class="col-sm-12">
                    <div class="card">
                      <div class="card-header">
                        {/* <h5>Add New Promoter</h5> */}
                        {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                      </div>

                      <div class="card-block">
                        <form onSubmit={updateDetails}>
                          
                          <div class="row">

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Bank Name<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <select
                                    class="form-control m-b"
                                    name="bank_name"
                                    value={bank_name}
                                    onChange={(e) =>
                                      setbank_name(e.target.value)
                                    }
                                  >
                                    <option value={''} disabled>Select bank_name</option>
                                    {bankList.map((bankdata) => (
                                      <option
                                        value={bankdata.id}
                                        selected={
                                          bankdata.id === bank_name
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {bankdata.name}

                                      </option>
                                    ))}
                                  </select>
                                  {formErrors.bank_name && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.bank_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  FD No.<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={fd_no}
                                    onChange={(e) =>
                                      setfd_no(e.target.value)
                                    }
                                    placeholder="Enter FD No."
                                  />
                                  {formErrors.fd_no && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.fd_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    FD Amount<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="number"
                                    value={fd_amount}
                                    onChange={(e) =>
                                      setfd_amount(e.target.value)
                                    }
                                    placeholder="Enter FD Amount"
                                  />
                                  {formErrors.fd_amount && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.fd_amount}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Annual interest Rate(%)<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={annual_interest_rate}
                                    onChange={(e) =>
                                      setannual_interest_rate(e.target.value)
                                    }
                                    placeholder="Enter Annual interest Rate(%)"
                                  />
                                  {formErrors.annual_interest_rate && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.annual_interest_rate}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Open Date<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="date"
                                    value={open_date}
                                    onChange={(e) =>
                                      setopen_date(e.target.value)
                                    }
                                    placeholder="Enter Open Date" 
                                  />
                                  {formErrors.open_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.open_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Maturity Date
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="date"
                                    value={maturity_date}
                                    onChange={(e) =>
                                      setmaturity_date(e.target.value)
                                    }
                                    placeholder="Enter Maturity Date" 
                                  />
                                  {formErrors.maturity_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.maturity_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Receipt Scan Copy<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="file"
                                    onChange={handleLogoImageChange} className="dropify"
                                    data-default-file={dataDetails?.receipt_sacn_copy ? API_BASE_URL + dataDetails.receipt_sacn_copy : ''}
                                  />
                                  {formErrors.receipt_sacn_copy && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.receipt_sacn_copy}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  Is FD From Deposit Money<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                    <div class="form-radio row ml-3">
                                      <div class="radio radio-inline col-xs-3 ">
                                        <label for="title_yes">
                                          <input
                                            type="radio"
                                            name="is_fd_mode"
                                            value={"yes"}
                                            id={"title_yes"}
                                            checked={is_fd_mode === 'yes' ? 'checked' : ''}
                                            onChange={(e) =>
                                              setis_fd_mode(e.target.value)
                                            }
                                          />
                                          <i class="helper"></i>Yes
                                        </label>
                                      </div>
                                      <div class="radio radio-inline col-xs-3 ">
                                        <label for="title_no">
                                          <input
                                            type="radio"
                                            name="is_fd_mode"
                                            id={"title_no"}
                                            value={"no"}
                                            checked={is_fd_mode === 'no' ? 'checked' : ''}
                                            onChange={(e) =>
                                              setis_fd_mode(e.target.value)
                                            }
                                          />
                                          <i class="helper"></i>No
                                        </label>
                                      </div>
                                      
                                    </div>
                                    {formErrors.authorized_signatory && (
                                      <span style={{ color: "red" }}>
                                        {formErrors.authorized_signatory}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>

                          </div>

                         
                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <h5 class="sub-title text-center">
                              
                              </h5>
                            </div>
                          </div>


                        


                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                              <button
                                class="btn btn-success btn-square"
                                type="submit"
                                disabled={submitting}
                              >
                                Update
                              </button>{" "}
                              &nbsp;
                              <a href="/unencumbered-deposits" class="btn btn-danger btn-square">Cancel</a>
                              &nbsp;
                              <button
                                class="btn btn-warning btn-square"
                                type="reset"
                              >
                                Reset
                              </button>

                              <ToastContainer className="toast-container" />
                            </div>
                          </div>


                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
