import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { dataDecrypt,
    dataEncript,
     } from "./../../../helper/commonHelpers";

function CompanyDetails() {
  // const navigate = useNavigate();
  const { promoterID }   = useParams();
  const [submitting, setSubmitting] = useState(false);
  // const [stateList, setStateList] = useState([]);
  const [promoterhDetails, setpromoterhDetails] = useState([]);
  const [bankList, setbankList] = useState([]);

  const [formErrors, setFormErrors] = useState({
    bank_name: "",
    ifsc_code: "",
    account_type: "",
    account_no: "",

  });


  // PROMOTER INFO 
  const[bank_name, setbank_name] = useState('');
  const[ifsc_code, setifsc_code] = useState('');
  const[account_type, setaccount_type] = useState('');
  const[account_no, setaccount_no] = useState('');

  useEffect(() => {

    axios.get(API_BASE_URL+'api-common/get-bank-list', { headers: API_HEADERS })
    .then(response => {
      setbankList(response.data['data']);
    })
    .catch(error => {
      console.error('Failed to fetch role list list!', error);
    });


  axios
  .get(API_BASE_URL + "api-company/get-promoter-by-id?promoter_id="+dataDecrypt(promoterID), {
    headers: API_HEADERS,
  })
  .then((response) => {
    setpromoterhDetails(response.data['data']);

    setbank_name(response.data["data"].bank_name);
    setifsc_code(response.data["data"].ifsc_code);
    setaccount_type(response.data["data"].account_type);
    setaccount_no(response.data["data"].account_no);


  })
  .catch((error) => {
    toast.error('Failed to fetch DETAILS list!');
  });
    //    setenrollment_date(getCurrentDate());
  }, [promoterID]);

  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};

    const formData = {
      bank_name:bank_name,
      ifsc_code:ifsc_code,
      account_type:account_type,
      account_no:account_no,
      promoter_id:dataDecrypt(promoterID)
    };


   

    // validate form
   

      if (!formData.bank_name) {
        errors.bank_name = 'Bank name is required.';
      }

      if (!formData.ifsc_code) {
        errors.ifsc_code = 'IFSC Code is required.';
      }
      if (!formData.account_type) {
        errors.account_type = 'Account Type is required.';
      }

      if (!formData.account_no) {
        errors.account_no = 'Account No. is required.';
      }

      // console.log(formData);

    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      axios
        .post(API_BASE_URL + "api-company/update-promoter-bank-details", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/view-promoter/"+dataEncript(promoterhDetails.id);
            }, 2700);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 300);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>Edit  - {promoterhDetails && promoterhDetails.fname && (
                                    <>{promoterhDetails.fname}</>
                                    )}

                                    {promoterhDetails && promoterhDetails.mname && (
                                    <>{promoterhDetails.mname}</>
                                    )}
                                      <small>&nbsp;Bank Information</small>
                                     </h4>

                                    
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/promoter-list">Promoter List</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href={`/view-promoter/${dataEncript(promoterhDetails.id)}`} >{promoterhDetails && promoterhDetails.fname && (
                                    <span>{promoterhDetails.fname}</span>
                                    )}

                                    {promoterhDetails && promoterhDetails.mname && (
                                    <span>{promoterhDetails.mname}</span>
                                    )}</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">Edit Bank info</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12"></div>

                  <div class="col-sm-12">
                    <div class="card">
                      <div class="card-header">
                        <h5>Edit Bank</h5>
                        {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                      </div>

                      <div class="card-block">
                        <form onSubmit={updateDetails}>


                        <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30 ">
                                <div class="row">
                                <div class="col-sm-5">
                                    <h6 class="text-xl-right">Bank Name<span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-sm-7">
                                        <select
                                          class="form-control"
                                          name="bank_name"
                                          value={bank_name}
                                          onChange={(e) =>
                                            setbank_name(e.target.value)
                                          }
                                        >
                                         <option value="">Select Bank</option>

                                         {
                                                bankList.length > 0 ? (
                                                  bankList.map((bankData, index) => (
                                                    <option value={bankData.name}>{bankData.name}</option>
                                                  ))
                                                ) : (
                                                  <></>
                                                )
                                              }


                                        </select>
                                    {formErrors.bank_name && <span style={{ color: 'red' }}>{formErrors.bank_name}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30 ">
                                <div class="row">
                                <div class="col-sm-5">
                                    <h6 class="text-xl-right">IFSC Code<span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-sm-7">
                                    <input class="form-control" type="text" value={ifsc_code} onChange={(e) => setifsc_code(e.target.value)} placeholder='Enter IFSC Code' />
                                    {formErrors.ifsc_code && <span style={{ color: 'red' }}>{formErrors.ifsc_code}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30 ">
                                <div class="row">
                                <div class="col-sm-5">
                                    <h6 class="text-xl-right">Account Type<span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-sm-7">
                                        <select
                                          class="form-control"
                                          name="account_type"
                                          value={account_type}
                                          onChange={(e) =>
                                            setaccount_type(e.target.value)
                                          }
                                        >
                                          <option value="Saving">Saving</option>
                                          <option value="Current">Current</option>
                                        </select>
                                    {formErrors.account_type && <span style={{ color: 'red' }}>{formErrors.account_type}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30 ">
                                <div class="row">
                                <div class="col-sm-5">
                                    <h6 class="text-xl-right">Account No.<span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-sm-7">
                                    <input class="form-control" type="text" value={account_no} onChange={(e) => setaccount_no(e.target.value)} placeholder='Account No.' />
                                    {formErrors.account_no && <span style={{ color: 'red' }}>{formErrors.account_no}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>


                            
                        



                          <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30 text-center">
                              <button
                                class="btn btn-success btn-square"
                                type="submit"
                                disabled={submitting}
                              >
                                Update Details
                              </button>{" "}
                              &nbsp;
                              <a href={`/view-promoter/${dataEncript(promoterhDetails.id)}`}  class="btn btn-danger btn-square">Cancel</a>
                              &nbsp;
                             

                              <ToastContainer className="toast-container" />
                            </div>
                          </div>


                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
