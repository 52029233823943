// config.js
// const API_BASE_URL = 'http://localhost:8082/';
// const API_BASE_URL = 'http://192.168.1.9:8082/';
const API_BASE_URL = 'https://nidhibackend.wisdomlife.in/';


const apiKey = 'api@parthshopping.com';
const CUST_DOMAIN = '192.168.1.6:8082';
const API_HEADERS = {
  'x-api-key': apiKey,
  'domain': CUST_DOMAIN,
};

export { API_BASE_URL, API_HEADERS, apiKey };

