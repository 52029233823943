import React, { useState, useEffect } from "react";
import DashboardLayout from "../../dashboardLayout";
// import axios from "axios";
// import { API_BASE_URL } from "../../../../config"; // Importing constants directly

import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

// import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";





import {  dataDecrypt, dataEncript, FirstLetterCapitalize } from "../../../helper/commonHelpers";
import {  getCollectionCenterList } from '../../../helper/listModal';

//formatDate

function CompanyDetails() {
  const { centerID }   = useParams();

  const [details, setdetails] = useState([]);

  useEffect(() => {
    let condition = `id=${dataDecrypt(centerID)}`;
    getCollectionCenterList(condition).then(data => {
        // console.log(data);
        setdetails(data);
      }).catch(error => {
          // console.error('Error fetching associate list:', error);
      });


  }, [centerID]);





  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>  Collection Center  -  

                        {details && details.center_no && (
                                    <> {details.center_no}</>
                                    )}

                        </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                            <a href="/associate-advisor">Associate/ Advisor </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                            <a href={`/collection-centers-view/${dataEncript(details.id)}`}  > {details && details.center_no && (
                                    <>{details.center_no}</>
                                    )}  </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> View</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12">
                    

                    <div class="card" style={{borderTop:'3px solid green'}} >
                      <div class="card-header py-3">
                        <h5 class="" style={{width:'100%'}}>Collection Center
                              
                          <a href={`/collection-centers-edit/${dataEncript(details.id)}`} style={{float:'right'}}  class="label label-info px-2" title="Edit" > <i class="feather icon-edit"></i> </a>
                      
                        </h5>
                      </div>
                          {/* <h4 class="m-4">CHANGE ACCOUNT INFO</h4> */}
                        <hr class="p-0 m-0"/>
                        <div class="card-block">
                        <div class="table-responsive dt-responsive">
                          <table class="table dtable table-striped table-bordered nowrap">
                            <tbody>


                            <tr>
                                <th>Branch</th>
                                <td> {details && details.branch_name && (
                                    <> {FirstLetterCapitalize(details.branch_name)}</>
                                    )}</td>
                            </tr>

                            <tr>
                                <th>Center No</th>
                                <td> {details && details.center_no && (
                                    <> {(details.center_no)}</>
                                    )}</td>
                            </tr>

                            <tr>
                                <th>Center Name</th>
                                <td> {details && details.center_name && (
                                    <> {FirstLetterCapitalize(details.center_name)}</>
                                    )}</td>
                            </tr>

                            <tr>
                                <th>Head Name	</th>
                                <td> {details && details.center_head_name && (
                                    <> {FirstLetterCapitalize(details.center_head_name)}</>
                                    )}</td>
                            </tr>

                            <tr>
                                <th>Cashier Name	</th>
                                <td> {details && details.center_cashier_name && (
                                    <> {FirstLetterCapitalize(details.center_cashier_name)}</>
                                    )}</td>
                            </tr>

                            <tr>
                                <th>Collection Day	</th>
                                <td> {details && details.collection_day && (
                                    <> {FirstLetterCapitalize(details.collection_day)}</>
                                    )}</td>
                            </tr>

                            
                            <tr>
                                <th>Collection Time	</th>
                                <td> {details && details.collection_time && (
                                    <> {FirstLetterCapitalize(details.collection_time)}</>
                                    )}</td>
                            </tr>

                            <tr>
                                <th>Address	</th>
                                <td> {details && details.center_address && (
                                    <> {FirstLetterCapitalize(details.center_address)}</>
                                    )}</td>
                            </tr>

                            <tr>
                                <th>GPS Lat/ Log		</th>
                                <td> {details && details.loc_latitude && (
                                    <> {FirstLetterCapitalize(details.loc_latitude)}</>
                                    )}
                                    {details && details.loc_longitude && (
                                    <> /{FirstLetterCapitalize(details.loc_longitude)}</>
                                    )}
                                    </td>
                            </tr>


                            <tr>
                                <th>Active</th>
                                <td> { details && (
                                        details.center_active === 'yes' ? (
                                          <label className="label label-success">Yes</label>
                                        ) : details.center_active === 'no' ? (
                                          <label className="label label-danger">No</label>
                                        ) : null
                                      )
                                    }
                                    </td>
                            </tr>




                                  </tbody>
                              </table>
                            </div>
                        </div>
                      </div>



                  </div>

                  <div class="col-sm-6">

                  </div>
                </div>


                

              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
          {/* <ToastContainer className="toast-container" /> */}
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
