import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { dataDecrypt,
    formatDate } from "./../../../helper/commonHelpers";

function CompanyDetails() {
  // const navigate = useNavigate();
  const { promoterID }   = useParams();
  const [submitting, setSubmitting] = useState(false);
  // const [stateList, setStateList] = useState([]);
  const [activeBranchList, setactiveBranchList] = useState([]);

  const [formErrors, setFormErrors] = useState({
    branch_id: "",
    enrollment_date: "",
    title: "",
    gender: "",
    fname: "",
  });

  const [branch_id, setbranch_id] = useState('');
  const [enrollment_date, setenrollment_date] = useState('');

  // PROMOTER INFO 
  const [title, settitle] = useState('Md.');
  const [gender, setgender] = useState('male');
  const [fname, setfname] = useState('');
  const [mname, setmname] = useState('');
  const [lname, setlname] = useState('');
  const [dob, setdob] = useState('');
  const [occuption, setoccuption] = useState('');
  const [father_name, setfather_name] = useState('');
  const [mother_name, setmother_name] = useState('');
  const [marital_status, setmarital_status] = useState('');
  const [supouse_name, setsupouse_name] = useState('');
  const [email, setemail] = useState('');
  const [mobile_no, setmobile_no] = useState('');

  // PROMOTER KYC
  const [adhar_no, setadhar_no] = useState('');
  const [voter_id_no, setvoter_id_no] = useState('');
  const [pan_no, setpan_no] = useState('');
  const [ration_card_no, setration_card_no] = useState('');
  const [meter_no, setmeter_no] = useState('');
  const [ci_no, setci_no] = useState('');
  const [ci_relation, setci_relation] = useState('');
  const [dl_no, setdl_no] = useState('');

  //NOMINEE INFO
  const [nominee_name, setnominee_name] = useState('');
  const [nominee_mob, setnominee_mob] = useState('');
  const [nominee_aadhar, setnominee_aadhar] = useState('');
  const [nominee_voter_id, setnominee_voter_id] = useState('');
  const [nominee_pan, setnominee_pan] = useState('');
  const [nominee_address, setnominee_address] = useState('');
  const [nominee_relation, setnominee_relation] = useState('');
  const [sms, setsms] = useState('no');

  useEffect(() => {
    // get stateList
    // axios.get(API_BASE_URL + "api-common/get-state-list", {
    //   headers: API_HEADERS,
    // }).then((response) => {
    //   setStateList(response.data['data']);
    // }).catch((error) => {
    //   toast.error('Failed to fetch data!');
    // });


  // get bran ch details
  axios
  .get(API_BASE_URL + "api-company/get-promoter-by-id?promoter_id="+dataDecrypt(promoterID), {
    headers: API_HEADERS,
  })
  .then((response) => {
    

    setbranch_id(response.data['data'].branch_id);
    setenrollment_date(formatDate(response.data['data'].enrollment_date, 'YMD'));
    settitle(response.data['data'].title);
    setgender(response.data['data'].gender);
    setfname(response.data['data'].fname);
    setmname(response.data['data'].mname);
    setlname(response.data['data'].lname);
    setdob(formatDate(response.data['data'].dob, 'YMD'));
    setoccuption(response.data['data'].occuption);
    setfather_name(response.data['data'].father_name);
    setmother_name(response.data['data'].mother_name);
    setmarital_status(response.data['data'].marital_status);
    setsupouse_name(response.data['data'].supouse_name);
    setemail(response.data['data'].email);
    setmobile_no(response.data['data'].mobile_no);
 
    setadhar_no(response.data['data'].adhar_no);
    setvoter_id_no(response.data['data'].voter_id_no);
    setpan_no(response.data['data'].pan_no);
    setration_card_no(response.data['data'].ration_card_no);
    setmeter_no(response.data['data'].meter_no);
    setci_no(response.data['data'].ci_no);
    setci_relation(response.data['data'].ci_relation);
    setdl_no(response.data['data'].dl_no);
 
    setnominee_name(response.data['data'].nominee_name);
    setnominee_mob(response.data['data'].nominee_mob);
    setnominee_aadhar(response.data['data'].nominee_aadhar);
    setnominee_voter_id(response.data['data'].nominee_voter_id);
    setnominee_pan(response.data['data'].nominee_pan);
    setnominee_address(response.data['data'].nominee_address);
    setnominee_relation(response.data['data'].nominee_relation);
    setsms(response.data['data'].sms);


  })
  .catch((error) => {
    toast.error('Failed to fetch DETAILS list!');
  });



    // GET ACTIVE BRANCH LIST
    axios
      .get(API_BASE_URL + "api-company/get-active-branch-list", {
        headers: API_HEADERS,
      })
      .then((response) => {
        setactiveBranchList(response.data["data"]);
      })
      .catch((error) => {
        toast.error("Failed to fetch data!");
      });

    //    setenrollment_date(getCurrentDate());
  }, [promoterID]);

  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};

    const formData = {
      branch_id: branch_id,
      enrollment_date: enrollment_date,
      title: title,
      gender: gender,
      fname: fname,
      mname: mname,
      lname: lname,
      dob: dob,
      occuption: occuption,
      father_name: father_name,
      mother_name: mother_name,
      marital_status: marital_status,
      supouse_name: supouse_name,
      email: email,
      mobile_no: mobile_no,

      adhar_no: adhar_no,
      voter_id_no: voter_id_no,
      pan_no: pan_no,
      ration_card_no: ration_card_no,
      meter_no: meter_no,
      ci_no: ci_no,
      ci_relation: ci_relation,
      dl_no: dl_no,

      nominee_name: nominee_name,
      nominee_mob: nominee_mob,
      nominee_aadhar: nominee_aadhar,
      nominee_voter_id: nominee_voter_id,
      nominee_pan: nominee_pan,
      nominee_address: nominee_address,
      nominee_relation: nominee_relation,
      sms: sms,
      promoter_id:dataDecrypt(promoterID)
    };


    console.log(formData);
   

    // validate form
    if (!formData.branch_id) {
        errors.branch_id = 'Branch name is required.';
      }

      if (!formData.enrollment_date) {
        errors.enrollment_date = 'Enrollment date is required.';
      }
      if (!formData.title) {
        errors.title = 'Title is required.';
      }
      if (!formData.gender) {
        errors.gender = 'Gender is required.';
      }

      if (!formData.fname) {
        errors.fname = 'First Name is required.';
      }


    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      axios
        .post(API_BASE_URL + "api-company/update-promoter", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/promoter-list";
            }, 4000);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 300);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-8">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>Edit Promoter</h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/promoter-list">Promoter List</a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">Edit Promoter</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12"></div>

                  <div class="col-sm-12">
                    <div class="card">
                      <div class="card-header">
                        <h5>Edit Promoter</h5>
                        {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                      </div>

                      <div class="card-block">
                        <form onSubmit={updateDetails}>
                          <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Select Branch
                                    <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <select
                                    class="form-control m-b"
                                    name="branch_id"
                                    value={branch_id}
                                    onChange={(e) =>
                                      setbranch_id(e.target.value)
                                    }
                                  >
                                    <option value={''} disabled>Select Branch</option>
                                    {activeBranchList.map((aBranchData) => (
                                      <option
                                        value={aBranchData.id}
                                        selected={
                                          aBranchData.id === branch_id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {aBranchData.branch_name}
                                      </option>
                                    ))}
                                  </select>
                                  {formErrors.branch_id && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.branch_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Enrollment Date
                                    <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <input
                                    class="form-control"
                                    type="date"
                                    value={enrollment_date}
                                    onChange={(e) =>
                                      setenrollment_date(e.target.value)
                                    }
                                    placeholder="Enter Enrollment Date"
                                  />
                                  {formErrors.enrollment_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.enrollment_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <h5 class="sub-title text-center">
                                Promoter's Info
                              </h5>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Title<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <div class="form-radio row">
                                    <div class="radio radio-inline col-xs-3 ml-3">
                                      <label for="title_md">
                                        <input
                                          type="radio"
                                          name="title"
                                          value={"Md."}
                                          id={"title_md"}
                                          checked={title === 'Md.' ? 'selected' : ''}
                                          onChange={(e) =>
                                            settitle(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Md.
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_mr">
                                        <input
                                          type="radio"
                                          name="title"
                                          id={"title_mr"}
                                          value={"Mr."}
                                          checked={title === 'Mr.' ? 'checked' : ''}
                                          onChange={(e) =>
                                            settitle(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Mr.
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3">
                                      <label for="title_ms">
                                        <input
                                          type="radio"
                                          name="title"
                                          value={"Ms."}
                                          checked={title === 'Ms.' ? 'checked' : ''}
                                          id={"title_ms"}
                                          onChange={(e) =>
                                            settitle(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Ms.
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3">
                                      <label for="title_mrs">
                                        <input
                                          type="radio"
                                          name="title"
                                          value={"Mrs."}
                                          checked={title === 'Mrs.' ? 'checked' : ''}
                                          id={"title_mrs"}
                                          onChange={(e) =>
                                            settitle(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Mrs.
                                      </label>
                                    </div>
                                  </div>
                                  
                                  {formErrors.title && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.title}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                    Gender<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <div class="form-radio row ml-1" >
                                    <div class="radio radio-inline col-xs-3">
                                      <label for="gender_male">
                                        <input
                                          type="radio"
                                          name="gender"
                                          value={"male"}
                                          id={"gender_male"}
                                          checked={gender === 'male' ? 'checked' : ''} 
                                          onChange={(e) =>
                                            setgender(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Male.
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3">
                                      <label for="gender_female">
                                        <input
                                          type="radio"
                                          name="gender"
                                          value={"female"}
                                          checked={gender === 'female' ? 'checked' : ''} 
                                          id={"gender_female"}
                                          onChange={(e) =>
                                            setgender(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Female.
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3">
                                      <label for="gender_other">
                                        <input
                                          type="radio"
                                          name="gender"
                                          value={"other"}
                                          checked={gender === 'other' ? 'checked' : ''}
                                          id={"gender_other"}
                                          onChange={(e) =>
                                            setgender(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Other.
                                      </label>
                                    </div>
                                  </div>
                                  {formErrors.gender && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.gender}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
           <h6 class="text-xl-right">First Name<span class="text-danger">*</span></h6>
        </div>
        <div class="col-sm-7">
        <input class="form-control" type="text" value={fname} onChange={(e) => setfname(e.target.value)} placeholder='Enter First Name' />
          {formErrors.fname && <span style={{ color: 'red' }}>{formErrors.fname}</span>}
        </div>
    </div>
  </div>


  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
          <h6 class="text-xl-right">
                                Middle Name
                              </h6>
        </div>
        <div class="col-sm-7">
            <input
                                class="form-control"
                                type="text"
                                value={mname}
                                onChange={(e) => setmname(e.target.value)}
                                placeholder="Enter Middle Name"
                              />
        </div>
    </div>
  </div>
</div>


<div class="row">
  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
          <h6 class="text-xl-right">
                                Last Name
                              </h6>
        </div>
        <div class="col-sm-7">
          <input
                                class="form-control"
                                type="text"
                                value={lname}
                                onChange={(e) => setlname(e.target.value)}
                                placeholder="Enter Last Name"
                              />
        </div>
    </div>
  </div>


  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
          <h6 class="text-xl-right">Date of Birth</h6>
        </div>
        <div class="col-sm-7">
            <input
                                class="form-control"
                                type="date"
                                value={dob}
                                onChange={(e) => setdob(e.target.value)}
                                placeholder="Enter Date of Birth"
                              />
        </div>
    </div>
  </div>
</div>




<div class="row">
<div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
          <h6 class="text-xl-right">
                                Occupation
                              </h6>
        </div>
        <div class="col-sm-7">
           <input
                                class="form-control"
                                type="text"
                                value={occuption}
                                onChange={(e) => setoccuption(e.target.value)}
                                placeholder="Enter Occupation"
                              />
        </div>
    </div>
  </div>

  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
        
        </div>
        <div class="col-sm-7">
        
        </div>
    </div>
  </div>
</div>


<div class="row">
  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
          <h6 class="text-xl-right">Father Name</h6>
        </div>
        <div class="col-sm-7">
          <input
                                class="form-control"
                                value={father_name}
                                onChange={(e) => setfather_name(e.target.value)}
                                type="text"
                                placeholder="Enter Father Name"
                              />
        </div>
    </div>
  </div>
  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
           <h6 class="text-xl-right">Mother Name</h6>
        </div>
        <div class="col-sm-7">
              <input
                                class="form-control"
                                type="text"
                                value={mother_name}
                                onChange={(e) => setmother_name(e.target.value)}
                                placeholder="Enter Mother Name."
                              />
        </div>
    </div>
  </div>

</div>
                        
                          

                           
<div class="row">
  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
          <h6 class="text-xl-right">Marital Status</h6>
        </div>
        <div class="col-sm-7">
           <select
                                class="form-control"
                                name="marital_status"
                                value={marital_status}
                                onChange={(e) =>
                                  setmarital_status(e.target.value)
                                }
                              >
                                <option value="">Select Marital Status</option>
                                <option value="SINGLE">SINGLE</option>
                                <option value="MARRIED">MARRIED</option>
                                <option value="SEPARATED">SEPARATED</option>
                                <option value="DIVORCED">DIVORCED</option>
                                <option value="WIDOWED">WIDOWED</option>
                                <option value="UNMARRIED">UNMARRIED</option>
                                <option value="UNTAGGED">UNTAGGED</option>
                              </select>
        </div>
    </div>
  </div>
  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
           <h6 class="text-xl-right">Husband/ Wife Name</h6>
        </div>
        <div class="col-sm-7">
            <input
                                class="form-control"
                                type="text"
                                value={supouse_name}
                                onChange={(e) =>
                                  setsupouse_name(e.target.value)
                                }
                                placeholder="Enter Husband/ Wife Name"
                              />
        </div>
    </div>
  </div>

</div>
                          


<div class="row">
  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
          <h6 class="text-xl-right">Email</h6>
        </div>
        <div class="col-sm-7">
           <input
                                class="form-control"
                                type="text"
                                value={email}
                                onChange={(e) => setemail(e.target.value)}
                                placeholder="Enter Email"
                              />
        </div>
    </div>
  </div>
  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
          <h6 class="text-xl-right">Mobile No.</h6>
        </div>
        <div class="col-sm-7">
           <input
                                class="form-control"
                                type="text"
                                value={mobile_no}
                                onChange={(e) => setmobile_no(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.target.value.length === 10) {
                                    e.preventDefault();
                                  }
                                }}
                                placeholder="Enter Mobile No."
                              />
        </div>
    </div>
  </div>

</div>



                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <h5 class="sub-title text-center">Promoter's KYC</h5>
                            </div>
                          </div>



                          <div class="row">
  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
           <h6 class="text-xl-right">Aadhaar No.</h6>
        </div>
        <div class="col-sm-7">
              <input
                                class="form-control"
                                type="text"
                                value={adhar_no}
                                onChange={(e) => setadhar_no(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.target.value.length === 12) {
                                    e.preventDefault();
                                  }
                                }}
                                placeholder="Enter Aadhaar No."
                              />
        </div>
    </div>
  </div>


  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
           <h6 class="text-xl-right">Voter ID No.</h6>
        </div>
        <div class="col-sm-7">
           <input
                                class="form-control"
                                type="text"
                                value={voter_id_no}
                                onChange={(e) => setvoter_id_no(e.target.value)}
                                placeholder="Enter Voter ID No."
                              />
        </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
             <h6 class="text-xl-right">Pan No.</h6>
        </div>
        <div class="col-sm-7">
          <input
                                class="form-control"
                                type="text"
                                value={pan_no}
                                onChange={(e) => setpan_no(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.target.value.length === 10) {
                                    e.preventDefault();
                                  }
                                }}
                                placeholder="Enter Pan No."
                              />
        </div>
    </div>
  </div>


  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
            <h6 class="text-xl-right">Ration Card No.</h6>
        </div>
        <div class="col-sm-7">
           <input
                                class="form-control"
                                type="text"
                                value={ration_card_no}
                                onChange={(e) =>
                                  setration_card_no(e.target.value)
                                }
                                onKeyDown={(e) => {
                                  if (e.target.value.length === 12) {
                                    e.preventDefault();
                                  }
                                }}
                                placeholder="Enter Ration Card No."
                              />
        </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
           <h6 class="text-xl-right">Meter No.</h6>
        </div>
        <div class="col-sm-7">
          <input
                                class="form-control"
                                type="text"
                                value={meter_no}
                                onChange={(e) => setmeter_no(e.target.value)}
                                placeholder="Enter Meter No."
                              />
        </div>
    </div>
  </div>

  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
             <h6 class="text-xl-right">CI No.</h6>
        </div>
        <div class="col-sm-7">
                              <input
                                class="form-control"
                                type="text"
                                value={ci_no}
                                onChange={(e) => setci_no(e.target.value)}
                                placeholder="Enter CI No."
                              />
        </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
           <h6 class="text-xl-right">CI Relation</h6>
        </div>
        <div class="col-sm-7">
          <input
                                class="form-control"
                                type="text"
                                value={ci_relation}
                                onChange={(e) => setci_relation(e.target.value)}
                                placeholder="Enter CI Relation"
                              />
        </div>
    </div>
  </div>
  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
           <h6 class="text-xl-right">DL No.</h6>
        </div>
        <div class="col-sm-7">
        <input
                                class="form-control"
                                type="text"
                                value={dl_no}
                                onChange={(e) => setdl_no(e.target.value)}
                                placeholder="Enter DL No."
                              />
        </div>
    </div>
  </div>
</div>

                        

                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <h5 class="sub-title text-center">Nominee Info</h5>
                            </div>
                          </div>


                          <div class="row">
  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
              <h6 class="text-xl-right">Nominee Name.</h6>
        </div>
        <div class="col-sm-7">
            <input
                                class="form-control"
                                type="text"
                                value={nominee_name}
                                onChange={(e) =>
                                  setnominee_name(e.target.value)
                                }
                                placeholder="Enter Nominee Name"
                              />
        </div>
    </div>
  </div>


  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
         <h6 class="text-xl-right">Nominee Relation</h6>
        </div>
        <div class="col-sm-7">
          <select
                                class="form-control"
                                name="nominee_relation"
                                value={nominee_relation}
                                onChange={(e) =>
                                  setnominee_relation(e.target.value)
                                }
                              >
                                <option value="">Select Relation</option>
                                <option value="father">Father</option>
                                <option value="mother">Mother</option>
                                <option value="son">Son</option>
                                <option value="daughter">Daughter</option>
                                <option value="spouse">
                                  Spouse (Husband/ Wife)
                                </option>
                                <option value="husband">Husband</option>
                                <option value="wife">Wife</option>
                                <option value="brother">Brother</option>
                                <option value="sister">Sister</option>
                                <option value="daughter_in_law">
                                  Daughter in Law
                                </option>
                                <option value="brother_in_law">
                                  Brother in Law
                                </option>
                                <option value="grand_daughter">
                                  Grand Daughter
                                </option>
                                <option value="grand_son">Grand Son</option>
                                <option value="nephew">Nephew</option>
                                <option value="niece">Niece</option>
                                <option value="other">Other</option>
                              </select>
        </div>
    </div>
  </div>
</div>


<div class="row">
  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
            <h6 class="text-xl-right">Nominee Mobile No.</h6>
        </div>
        <div class="col-sm-7">
          <input
                                class="form-control"
                                type="text"
                                value={nominee_mob}
                                onChange={(e) => setnominee_mob(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.target.value.length === 10) {
                                    e.preventDefault();
                                  }
                                }}
                                placeholder="Enter Nominee Mobile No."
                              />
        </div>
    </div>
  </div>


  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
            <h6 class="text-xl-right">Nominee Aadhaar No</h6>
        </div>
        <div class="col-sm-7">
           <input
                                class="form-control"
                                type="text"
                                value={nominee_aadhar}
                                onChange={(e) =>
                                  setnominee_aadhar(e.target.value)
                                }
                                onKeyDown={(e) => {
                                  if (e.target.value.length === 12) {
                                    e.preventDefault();
                                  }
                                }}
                                placeholder="Enter Nominee Aadhaar No"
                              />
        </div>
    </div>
  </div>
</div>


<div class="row">
  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
            <h6 class="text-xl-right">Nominee Voter ID No.</h6>
        </div>
        <div class="col-sm-7">
          <input
                                class="form-control"
                                type="text"
                                value={nominee_voter_id}
                                onChange={(e) =>
                                  setnominee_voter_id(e.target.value)
                                }
                                placeholder="Enter Nominee Voter ID No."
                              />
        </div>
    </div>
  </div>


  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
            <h6 class="text-xl-right">Nominee Pan No.</h6>
        </div>
        <div class="col-sm-7">
           <input
                                class="form-control"
                                type="text"
                                value={nominee_pan}
                                onChange={(e) => setnominee_pan(e.target.value)}
                                placeholder="Enter Nominee Pan No."
                              />
        </div>
    </div>
  </div>
</div>

                        

<div class="row">
  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
            <h6 class="text-xl-right">Nominee Address</h6>
        </div>
        <div class="col-sm-7">
          <textarea
                                class="form-control"
                                name="nominee_address"
                                onChange={(e) =>
                                  setnominee_address(e.target.value)
                                }
                              >
                                {nominee_address}
                              </textarea>
        </div>
    </div>
  </div>


  <div class="col-sm-12 col-xl-6 m-b-30">
      <div class="row">
      <div class="col-sm-5">
        </div>
        <div class="col-sm-7">
    
        </div>
    </div>
  </div>
</div>



<div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <h5 class="sub-title text-center">Extra Settings</h5>
                            </div>
                          </div>


                          <div class="row">
                      <div class="col-sm-12 col-xl-6 m-b-30">
                          <div class="row">
                          <div class="col-sm-5">
                                <h6 class="">SMS</h6>
                            </div>
                            <div class="col-sm-7">
                               <input type="checkbox" name="sms" checked={sms === 'yes' ? 'checked' : ''} value={"yes"} onChange={(e) => setsms(e.target.checked ? 'yes' : 'no')} />
                            </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-xl-6 m-b-30">
                          <div class="row">
                          <div class="col-sm-5">
                            </div>
                            <div class="col-sm-7">
                        
                            </div>
                        </div>
                      </div>
                    </div>



                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                              <button
                                class="btn btn-success btn-square"
                                type="submit"
                                disabled={submitting}
                              >
                                Update Promoter
                              </button>{" "}
                              &nbsp;
                              <a href="/promoter-list" class="btn btn-danger btn-square">Cancel</a>
                              &nbsp;
                              <button
                                class="btn btn-warning btn-square"
                                type="reset"
                              >
                                Reset
                              </button>

                              <ToastContainer className="toast-container" />
                            </div>
                          </div>


                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
