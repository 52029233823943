import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../../config"; // Importing constants directly
// import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { 
  // dataDecrypt,
    // dataEncript,
    // getCurrentDate
    infoAlert
     } from "./../../../helper/commonHelpers";

function CompanyDetails() {
  const [submitting, setSubmitting] = useState(false);


  const [formErrors, setFormErrors] = useState({
    open_date: '',
  });


  // PROMOTER INFO 
 const[open_date, setopen_date] = useState(''); 
 const[amount, setamount] = useState('');
 const[intrest_type, setintrest_type] = useState('');
 const[intrest_rate, setintrest_rate] = useState('');
 const[years, setyears] = useState('');
 const[month, setmonth] = useState('');
 const[days, setdays] = useState('');
 const[bonous_rate_type, setbonous_rate_type] = useState('');
 const[bonous_rate_value, setbonous_rate_value] = useState('');
 const[tds_deducation, settds_deducation] = useState('');
 const[senior_citizen, setsenior_citizen] = useState('');



  useEffect(() => {

  }, []);

  const updateDetails = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));

    const formData = {
        // table_id:dataDecrypt(memberID),
        open_date: open_date,

        amount:amount,
        intrest_type:intrest_type,
        intrest_rate:intrest_rate,
        years:years,
        month:month,
        days:days,
        bonous_rate_type:bonous_rate_type,
        bonous_rate_value:bonous_rate_value,
        tds_deducation:tds_deducation,
        senior_citizen:senior_citizen,


    };

    // validate form

    if (!open_date) {
      errors.open_date = 'Scheme Name is required.';
    }

    

     
        console.log(formData);

    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      axios
        .post(API_BASE_URL + "api-fd-account/add-new-scheme", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/fixed-deposit-schemes";
            //   window.location.reload();
            }, 2000);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 1000);
        }); // close query hare
    } else {
      setSubmitting(false);
    } // hare close error
  };




  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
            <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>FD/ MIS Calculator</h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">FD/ MIS Calculator </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-12"></div>

                


                  <div class="col-sm-8">
                    <div class="card">
                
                      <div class="card-block">
                        <form onSubmit={updateDetails}>

                        <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                  Open Date<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-6">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={open_date}
                                    onChange={(e) =>
                                      setopen_date(e.target.value)
                                    }
                                    placeholder="Enter Open Date"
                                  />
                                  {formErrors.open_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.open_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                  Amount ( ₹ )<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-6">
                                  <input
                                    class="form-control"
                                    type="number"
                                    value={amount}
                                    onChange={(e) =>
                                      setamount(e.target.value)
                                    }
                                    placeholder="Enter Amount ( ₹ )"
                                  />
                                  {formErrors.amount && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.amount}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                  Interest Payout Type<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-6">
                                <div class="input-group">
                                        <select
                                            class="form-control m-b"
                                            value={intrest_type}
                                            onChange={(e) =>
                                            setintrest_type(e.target.value)
                                            }
                                        >
                                        <option value="">Select Interest Payout Cycle</option>
                                        <option value="CUMULATIVE_YEARLY">Cumulative Yearly</option>
                                        <option value="CUMULATIVE_HALF_YEARLY">Cumulative Half Yearly</option>
                                        <option value="CUMULATIVE_QUARTERLY">Cumulative Quarterly</option>
                                        <option value="CUMULATIVE_MONTHLY">Cumulative Monthly</option>
                                        <option value="MONTHLY">Monthly</option>
                                        <option value="QUARTERLY">Quarterly</option>
                                        <option selected="selected" value="HALF_YEARLY">Half Yearly</option>
                                        <option value="YEARLY">Yearly</option>
                                        
                                        </select>
                                        <div class="input-group-prepend" style={{width:'auto'}}>
                                            <span class="input-group-text" onClick={() => infoAlert("Choose Monthly - if you want to create MIS Scheme. Choose Cumulative Yearly - if you want to give the full amount (principal + interest) on maturity.")}   ><i class="icofont icofont-info"></i></span>
                                        </div>
                                        </div>
                                  {formErrors.intrest_type && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.intrest_type}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                  Annual Interest Rate (%)<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-6">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={intrest_rate}
                                    onChange={(e) =>
                                      setintrest_rate(e.target.value)
                                    }
                                    placeholder="Enter Annual Interest Rate (%)"
                                  />
                                  {formErrors.intrest_rate && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.intrest_rate}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                  Tenure Period<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-6">


                                <div class="input-group">
                                <div class="input-group-prepend" >
                                        <input
                                            class="form-control"
                                            type="text"
                                            value={years}
                                            onChange={(e) =>
                                            setyears(e.target.value)
                                            }
                                            placeholder="Years"
                                        />
                                  </div>
                                <input
                                    class="form-control"
                                    type="text"
                                    value={month}
                                    onChange={(e) =>
                                      setmonth(e.target.value)
                                    }
                                    placeholder="Month"
                                  />
                                   <div class="input-group-prepend" >
                                        <input
                                            class="form-control"
                                            type="text"
                                            value={days}
                                            onChange={(e) =>
                                            setdays(e.target.value)
                                            }
                                            placeholder="Days"
                                        />
                                  </div>
                                </div>


                                 
                                  {formErrors.intrest_rate && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.intrest_rate}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                  Bonus Rate
                                  </h6>
                                </div>
                                <div class="col-sm-6">
                                <div class="input-group">
                                  <div class="input-group-prepend" style={{width:'auto'}}>
                                  <select
                                          class="form-control m-b"
                                          name="promoter_id"
                                          value={bonous_rate_type}
                                          onChange={(e) =>
                                            setbonous_rate_type(e.target.value)
                                          }
                                        >
                                         <option value="%">(%)</option>
                                          <option value="fixed">FIXED</option>
                                        
                                        </select>
                                    </div>

                                      <input
                                      class="form-control"
                                      type="text"
                                      value={bonous_rate_value}
                                      onChange={(e) =>
                                        setbonous_rate_value(e.target.value)
                                      }
                                      placeholder="Enter Bonus Rate"
                                    />

                                  <div class="input-group-prepend" style={{width:'auto'}}>
                                      <span class="input-group-text"   onClick={() => infoAlert("Like if your RD/ DD total deposit is 1 lakh & you are setting bonus rate as 1%, So 1,000 will be added to your RD / DD on maturity as bonus. If you are setting fixed bonus 1,000 then 1,000 will be added to total deposits as bonus. Bonus amount will be added only after when RD/ DD will get matured.")} ><i class="icofont icofont-info-circle"></i></span>
                                  </div>
                                </div>
                                  {formErrors.ins_lock_period && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.ins_lock_period}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="row" >
                          <div class="col-sm-12 col-xl-6 m-b-30">
                              <div class="row">
                                <div class="col-sm-5">
                                  <h6 class="text-xl-right">
                                  TDS Deduction<span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                                  <div class="form-radio row ml-3">
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_yes">
                                        <input
                                          type="radio"
                                          name="tds_deducation"
                                          value={"yes"}
                                          id={"title_yes"}
                                          checked={tds_deducation === 'yes' ? 'checked' : ''}
                                          onChange={(e) =>
                                            settds_deducation(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>Yes
                                      </label>
                                    </div>
                                    <div class="radio radio-inline col-xs-3 ">
                                      <label for="title_no">
                                        <input
                                          type="radio"
                                          name="tds_deducation"
                                          id={"title_no"}
                                          value={"no"}
                                          checked={tds_deducation === 'no' ? 'checked' : ''}
                                          onChange={(e) =>
                                            settds_deducation(e.target.value)
                                          }
                                        />
                                        <i class="helper"></i>No
                                      </label>
                                    </div>
                                  
                                    
                                  </div>
                                  {formErrors.tds_deducation && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.tds_deducation}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                        <div class="col-sm-12 col-xl-6 m-b-30">
                            <div class="row">
                            <div class="col-sm-5">
                        <h6 class="">Senior Citizen</h6>
                                </div>
                                <div class="col-sm-7">
                                <input type="checkbox" name="senior_citizen" checked={senior_citizen === 'yes' ? 'checked' : ''} value={"yes"} onChange={(e) => setsenior_citizen(e.target.checked ? 'yes' : 'no')} />
                                </div>
                            </div>
                        </div>
                        

                          </div>




                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                              <button
                                class="btn btn-success btn-square"
                                type="submit"
                                disabled={submitting}
                              >
                                CALCULATE
                              </button>
                             

                              <ToastContainer className="toast-container" />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>


                  <div class="col-sm-12">
                    <div class="card">
                
                      <div class="card-block">
                      <ul class="nav nav-tabs md-tabs" role="tablist">

                            <li class="nav-item">
                                    <a class="nav-link active" data-toggle="tab" href="#DASHBOARD" role="tab">FINAL PAYMENT </a>
                                </li>


                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#year_1" role="tab">1 YEAR </a>
                                </li>

                      </ul>

                       <div class="tab-content card-block p-0 pt-2">
                              <div class="tab-pane active" id="final_payment" role="tabpanel">
                                        a 
                               </div> 

                               <div class="tab-pane" id="year_1" role="tabpanel">
                               1 YEAR
                               </div> 
                       </div>

                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
