import React, { useState, useEffect } from "react";
import DashboardLayout from "../../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "../../../../config"; // Importing constants directly
import { useParams } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {   dataDecrypt, FirstLetterCapitalize, dataEncript, deleteConfirmation, getCurrentDate, formattedAmount } from "../../../helper/commonHelpers";
//formatDate

function CompanyDetails() {
  const [submitting, setSubmitting] = useState(false);
  const { accountID }   = useParams();
  const [shareHolderDetail, setshareHolderDetail] = useState([]);

  const [formErrors, setFormErrors] = useState({
    member_id: "",
  });



  const[transaction_date, settransaction_date] = useState('');
  const[transaction_type, settransaction_type] = useState('debit');
  const[interest_amount, setinterest_amount] = useState('');
  const[remark, setremark] = useState('');
  


  useEffect(() => {
  

      axios.get(API_BASE_URL + "api-common/get-account-list?id="+dataDecrypt(accountID), {
        headers: API_HEADERS,
      }).then((response) => {
        if (response.data["status"] > 0) {
            console.log(response.data["data"]);
            setshareHolderDetail(response.data["data"]);
        } else {
            toast.error(response.data["message"]);
        }
      });

    
  

  
     

      settransaction_date(getCurrentDate());
  }, [accountID]);




  const updateDetails = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    let errors = {};
    // let logindetail = JSON.parse(localStorage.getItem('userLogin'));

    const formData = {
       account_id:dataDecrypt(accountID),
        transaction_date: transaction_date,
        transaction_type: transaction_type,
        amount: interest_amount,
        remark: remark,
    };

//   console.log(formData);
     

        if (!interest_amount) {
            errors.interest_amount = 'Interest Amount is required.';
          }

          if (!transaction_type) {
            errors.transaction_type = 'Transaction Type is required.';
          }
    
          if (!transaction_date) {
            errors.transaction_date = 'Transaction Date is required.';
          }
    
    



    // Set formErrors state with validation errors
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {

        const confirmed = await deleteConfirmation(' proceed? Note: This operation is irreversible.');
        if(confirmed===true)
        {

      axios.post(API_BASE_URL + "api-saving-account/credit-intrest-amount", formData, {
          headers: {
            ...API_HEADERS,
          },
        })
        .then((response) => {
          if (response.data["status"] > 0) {
            toast.success(response.data["message"]);
            setTimeout(() => {
              window.location.href = "/account-transaction-view/"+accountID+'/'+response.data["data"].id;
            }, 2000);
          } else {
            toast.error(response.data["message"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to submition!");
        })
        .finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 1000);
        }); // close query hare

    }
    setSubmitting(false);
    } else {
      setSubmitting(false);
    } // hare close error
  };


  

  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-12">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4> Fixed Deposit - {shareHolderDetail && shareHolderDetail.account_no && (
                                    <>{shareHolderDetail.account_no}</>
                                    )}  <span>Update TDS</span> </h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/fixed-deposit-account">Fixed Deposit </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href={`/fixed-deposit-account-view/${dataEncript(shareHolderDetail.id)}`}> {shareHolderDetail && shareHolderDetail.account_no && (
                                    <>{shareHolderDetail.account_no}</>
                                    )} </a>
                        </li>

                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!"> Update TDS</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="card" style={{borderTop:'3px solid red'}} >
                    
                        <h5 class="m-4">DEDUCT/ REVERSE TDS</h5>
                       <hr class="p-0 m-0"/>

                     

                      <div class="card-block">
                      <form onSubmit={updateDetails}>

                      {/* <h6 class="mb-2">Are you sure you want to change account type?</h6> */}

                
                      <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                  Transaction Date <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                <input class="form-control" type="date" value={transaction_date} onChange={(e) => settransaction_date(e.target.value) } placeholder="Enter Transaction Date" />

                                  {formErrors.transaction_date && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.transaction_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>


                      <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                  Transaction Type <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                  <select
                                    class="form-control m-b"
                                    name="transaction_type"
                                    value={transaction_type}
                                    onChange={(e) =>
                                      settransaction_type(e.target.value)
                                    }
                                  >
                                  <option value="credit">Reverse</option>
                                    <option  value="debit">Deduct</option>
                                   
                                  </select>

                                  {formErrors.transaction_type && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.transaction_type}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                  Interest Amount <span class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                <input class="form-control" type="number" value={interest_amount} onChange={(e) => setinterest_amount(e.target.value) } placeholder="Enter Interest Amount" />

                                  {formErrors.interest_amount && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.interest_amount}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-12 col-xl-12 m-b-30">
                              <div class="row">
                                <div class="col-sm-4">
                                  <h6 class="text-xl-right">
                                  Remarks (if any)
                                  </h6>
                                </div>
                                <div class="col-sm-7">
                               
                                <textarea class="form-control" rows={'3'} value={remark} onChange={(e) => setremark(e.target.value) }></textarea>

                                  {formErrors.remark && (
                                    <span style={{ color: "red" }}>
                                      {formErrors.remark}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>




                          





                      

                         


                        <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                            <button
                              class="btn btn-success btn-square"
                              type="submit"
                              disabled={submitting}
                            >
                            UPDATE TDS
                            </button>{" "}
                            <a href={`/fixed-deposit-account-view/${dataEncript(shareHolderDetail.id)}`} class="btn btn-danger btn-square">Cancel</a>
                            &nbsp;
                          

                            <ToastContainer className="toast-container" />
                          </div>
                        </div>


                      </form>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6">


                  <div class="card" style={{borderTop:'3px solid green'}} >
                  <div class="card-header py-3 text-denger">
                        <h5>FD Info</h5>
                              <div class="card-header-right">
                                    <ul class="list-unstyled card-option">
                                        <li><i class="feather icon-minus minimize-card"></i></li>
                                    </ul>
                              </div>
                        </div>
                        <div class="card-block p-0">
                        <form onSubmit={updateDetails}>

                        <div class="table-responsive dt-responsive">
                            <table class="table dtable table-striped table-bordered">
                            <tr>
                                <th>Select Saving Account.</th>
                                <td> {shareHolderDetail && shareHolderDetail.member_name && (
                                    <a href={`/view-member/${dataEncript(shareHolderDetail.member_id)}`} >
                                        {FirstLetterCapitalize(shareHolderDetail.member_created_id)} - 
                                        {FirstLetterCapitalize(shareHolderDetail.member_name)}
                                    </a>
                                    )} </td>
                            </tr>

                            <tr>
                                <th>FD No.</th>
                                <td>
                                {shareHolderDetail && shareHolderDetail.account_no && (
                                    <>{(shareHolderDetail.account_no)}</>
                                    )} 
                                </td>
                            </tr>

                            <tr>
                                <th>Scheme</th>
                                <td>
                                {shareHolderDetail && shareHolderDetail.scheme_name && (
                                    <>{(shareHolderDetail.scheme_name)}</>
                                    )} 
                                </td>
                            </tr>

                            <tr>
                                <th>Principal Amt</th>
                                <td>
                                {shareHolderDetail && shareHolderDetail.mis_amount && (
                                    <>{formattedAmount(shareHolderDetail.mis_amount)}</>
                                    )} 
                                </td>
                            </tr>

                            <tr>
                                <th>Balance Available</th>
                                <td>
                                {shareHolderDetail && shareHolderDetail.mis_amount && (
                                    <>{formattedAmount(shareHolderDetail.mis_amount)}</>
                                    )} 
                                </td>
                            </tr>


                            </table>
                        </div>

                        </form>
                        </div>
                    </div>


                    
                  </div>
                </div>


                

              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
          <ToastContainer className="toast-container" />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
