import React, { useState, useEffect } from "react";
import DashboardLayout from "../dashboardLayout";
import axios from "axios";
import { API_BASE_URL, API_HEADERS } from "./../../../config"; // Importing constants directly

import $ from 'jquery';
import 'dropify/dist/css/dropify.min.css';
import 'dropify';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import {
  deleteConfirmation
} from './../../helper/commonHelpers'; 




function CompanyDetails() {
  const [submitting, setSubmitting] = useState(false);
  const [companyDetails, setCompanyDetails] = useState([]);
  //GET COMPANY DETAILS
  useEffect(() => {
    axios
      .get(API_BASE_URL + "api-company/get-profile-Details", {
        headers: API_HEADERS,
      })
      .then((response) => {
        setCompanyDetails(response.data);
      })
      .catch((error) => {
        console.error("Failed to fetch EMPLYEE DETAILS list!", error);
      });
  }, []);


  useEffect(() => {
    // Initialize Dropify after component is rendered and 'companyDetails' state is updated
    if (companyDetails?.data) {
      // Wait for the image to load
      const interval = setInterval(() => {
        if (document.querySelector('.dropify')) {
          $('.dropify').dropify();
          $('.dropify').on('dropify.beforeClear',  (event, element) => {
           
               const confirmed =  deleteConfirmation('Are you sure you want to remove the logo?');
              // window.alert(confirmed);
              if (confirmed.isConfirmed) {
                return true;
              }
              return false;
          });
          clearInterval(interval);
        }

        if (document.querySelector('.dropify1')) {
          $('.dropify1').dropify();
          $('.dropify1').on('dropify.beforeClear', (event, element) => {
            // Show a confirmation dialog before removing the file
            if (!window.confirm('Are you sure you want to remove this file?')) {
              return false; // Prevent file removal if cancel is clicked
            }
            return true; // Continue with file removal if confirm is clicked
            
          });
          clearInterval(interval);
        }


      }, 100);
    }




  }, [companyDetails]);



  // change logo hare 
  const [logo, setLogo] = useState('');
  const handleLogoImageChange = (e) => {
    const file = e.target.files[0]; 
    setLogo(file); 
  };

  const changeLogo = (e) => {
    e.preventDefault();
    setSubmitting(true);
    const formData = new FormData();
    if (logo) {
      formData.append('logo', logo); 
    }
    if (logo.length===0) {
      toast.error('Please choose logo!');
      setSubmitting(false);
    }
    else{
      axios.post(API_BASE_URL + 'api-company/update-logo', formData, {
        headers: {
          ...API_HEADERS,
          'Content-Type': 'multipart/form-data', // Set content type for FormData
        },
      })
      .then(response => {
        if (response.data['status'] > 0) {
          toast.success(response.data['message']);
          setTimeout(() => {
            window.location.reload();
          }, 2300);
        } else {
          toast.error(response.data['message']);
        }
      })
      .catch(error => {
        toast.error('Failed to save data');
      }).finally(() => {
        setTimeout(() => {
          setSubmitting(false);
        }, 300);
      }); 
    }
  };


    // change Fevicon Icon 
    const [fevicon, setFevicon] = useState('');
    const handleFeviconImageChange = (e) => {
      const file = e.target.files[0]; 
      setFevicon(file); 
    };
    const changeFevicon = (e) => {
      e.preventDefault();
      setSubmitting(true);
      const formData = new FormData();
      if (fevicon) {
        formData.append('favicon', fevicon); 
      }
      if (fevicon.length===0) {
        toast.error('Please choose Fevicon Icon!');
        setSubmitting(false);
      }
      else{
        axios.post(API_BASE_URL + 'api-company/update-favicon', formData, {
          headers: {
            ...API_HEADERS,
            'Content-Type': 'multipart/form-data', // Set content type for FormData
          },
        })
        .then(response => {
          if (response.data['status'] > 0) {
            toast.success(response.data['message']);
            setTimeout(() => {
              window.location.reload();
            }, 2300);

          } else {
            toast.error(response.data['message']);
          }
        })
        .catch(error => {
          toast.error('Failed to save data');
        }).finally(() => {
          setTimeout(() => {
            setSubmitting(false);
          }, 300);
        }); 
      }
    };


    // update theam
  const [theamData, setTheamData] = useState({
    color: '',
    layout: '',
  });

  
  useEffect(() => {
    if (companyDetails?.data?.logo) {
      setTheamData({
        color: companyDetails.data?.color || '', 
        layout: companyDetails.data?.layout || '', 
      });

    }
  }, [companyDetails]);

  const handleTheam = (e) => {
    const { name, value } = e.target;
    setTheamData({ ...theamData, [name]: value });
  };


  const updateTheam = (e) => {
    e.preventDefault();
      axios.post(API_BASE_URL + 'api-company/update-theme', theamData, {
        headers: {
          ...API_HEADERS,
        },
      })
      .then(response => {
        if (response.data['status'] > 0) {
          // navigate('/manage-users', { state: { successMessage: response.data['message'], errType: 'SUCCESS' } });
          // window.location.reload();
          toast.success(response.data['message']);
          setTimeout(() => {
            window.location.reload();
          }, 2300);
        } else {
          toast.error(response.data['message']);
        }
      })
      .catch(error => {
        toast.success('Failed to save data');
      });
  };




  return (
    <DashboardLayout>
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <div class="page-header">
                <div class="row align-items-end">
                  <div class="col-lg-8">
                    <div class="page-header-title">
                      <div class="d-inline">
                        <h4>Company Details</h4>
                        {/* <span>Lorem ipsum dolor sit <code>amet</code>, consectetur adipisicing elit</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="page-header-breadcrumb">
                      <ul class="breadcrumb-title">
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="/dashboard">
                            {" "}
                            <i class="feather icon-home"></i>{" "}
                          </a>
                        </li>
                        <li class="breadcrumb-item" style={{ float: "left" }}>
                          <a href="#!">Details</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-body  ">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="card">
                      <div class="card-header ">
                        <h5>Company Details</h5>
                        <a
                          style={{ float: "right" }}
                          class="badge badge-info"
                          href="/edit-company-details"
                          title="Edit"
                        >
                          Edit <i class="feather icon-edit"></i>
                        </a>
                        {/* <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span> */}
                      </div>
                      <div class="card-block">
                        <div class="row">
                          <div class="col-sm-6">
                            <table>
                              <tr>
                                <th>Software URL</th>
                                <td>
                                  :
                                  {companyDetails.data?.software_url.length >
                                    0 && (
                                    <a href={companyDetails.data.software_url}>
                                      {companyDetails.data.software_url}
                                    </a>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <th>Website</th>
                                <td>
                                  :{" "}
                                  {companyDetails.data?.website.length > 0 && (
                                    <a href={companyDetails.data.website}>
                                      {companyDetails.data.website}
                                    </a>
                                  )}{" "}
                                </td>
                              </tr>
                              <tr>
                                <th>Company Name</th>
                                <td>
                                  :{" "}
                                  {companyDetails.data?.company_name.length >
                                    0 && (
                                    <a href={companyDetails.data.company_name}>
                                      {companyDetails.data.company_name}
                                    </a>
                                  )}{" "}
                                </td>
                              </tr>
                              <tr>
                                <th>Short Name</th>
                                <td>
                                  :{" "}
                                  {companyDetails.data?.short_name.length >
                                    0 && (
                                    <a href={companyDetails.data.short_name}>
                                      {companyDetails.data.short_name}
                                    </a>
                                  )}{" "}
                                </td>
                              </tr>
                              <tr>
                                <th>About</th>
                                <td>
                                  :{" "}
                                  {companyDetails.data?.about.length > 0 && (
                                    <a href={companyDetails.data.about}>
                                      {companyDetails.data.about}
                                    </a>
                                  )}{" "}
                                </td>
                              </tr>
                              <tr>
                                <th>CIN</th>
                                <td>
                                  :{" "}
                                  {companyDetails.data?.cin.length > 0 && (
                                    <a href={companyDetails.data.cin}>
                                      {companyDetails.data.cin}
                                    </a>
                                  )}{" "}
                                </td>
                              </tr>
                              <tr>
                                <th>PAN</th>
                                <td>
                                  :{" "}
                                  {companyDetails.data?.pan.length > 0 && (
                                    <a href={companyDetails.data.pan}>
                                      {companyDetails.data.pan}
                                    </a>
                                  )}{" "}
                                </td>
                              </tr>
                              <tr>
                                <th>TAN</th>
                                <td>
                                  :{" "}
                                  {companyDetails.data?.tan.length > 0 && (
                                    <a href={companyDetails.data.tan}>
                                      {companyDetails.data.tan}
                                    </a>
                                  )}{" "}
                                </td>
                              </tr>
                              <tr>
                                <th>GST NO</th>
                                <td>
                                  :{" "}
                                  {companyDetails.data?.gst_no.length > 0 && (
                                    <a href={companyDetails.data.gst_no}>
                                      {companyDetails.data.gst_no}
                                    </a>
                                  )}{" "}
                                </td>
                              </tr>
                              <tr>
                                <th>Category</th>
                                <td>
                                  :{" "}
                                  {companyDetails.data?.category.length > 0 && (
                                    <a href={companyDetails.data.category}>
                                      {companyDetails.data.category}
                                    </a>
                                  )}{" "}
                                </td>
                              </tr>
                              <tr>
                                <th>Class</th>
                                <td>
                                  :{" "}
                                  {companyDetails.data?.class_name.length >
                                    0 && (
                                    <a href={companyDetails.data.class_name}>
                                      {companyDetails.data.class_name}
                                    </a>
                                  )}{" "}
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div class="col-sm-6">
                            <table>
                              <tr>
                                <th>Authorized Capital</th>
                                <td>
                                  :{" "}
                                  {companyDetails.data?.authorized_capital
                                    .length > 0 && (
                                    <a
                                      href={
                                        companyDetails.data.authorized_capital
                                      }
                                    >
                                      {companyDetails.data.authorized_capital}
                                    </a>
                                  )}{" "}
                                </td>
                              </tr>
                              <tr>
                                <th>Paid Up Capital</th>
                                <td>
                                  :{" "}
                                  {companyDetails.data?.paid_up_capital.length >
                                    0 && (
                                    <a
                                      href={companyDetails.data.paid_up_capital}
                                    >
                                      {companyDetails.data.paid_up_capital}
                                    </a>
                                  )}{" "}
                                </td>
                              </tr>
                              <tr>
                                <th>Share Nominal Value</th>
                                <td>
                                  :{" "}
                                  {companyDetails.data?.share_nominal_value
                                    .length > 0 && (
                                    <a
                                      href={
                                        companyDetails.data.share_nominal_value
                                      }
                                    >
                                      {companyDetails.data.share_nominal_value}
                                    </a>
                                  )}{" "}
                                </td>
                              </tr>
                              <tr>
                                <th>State of Registration</th>
                                <td>
                                  :{" "}
                                  {companyDetails.data?.state_of_registration
                                    .length > 0 && (
                                    <a
                                      href={
                                        companyDetails.data
                                          .state_of_registration
                                      }
                                    >
                                      {
                                        companyDetails.data
                                          .state_of_registration
                                      }
                                    </a>
                                  )}{" "}
                                </td>
                              </tr>
                              <tr>
                                <th>Incorporation Date</th>
                                <td>
                                  :{" "}
                                  {companyDetails.data?.incorporation_date
                                    .length > 0 && (
                                    <a
                                      href={
                                        companyDetails.data.incorporation_date
                                      }
                                    >
                                      {companyDetails.data.incorporation_date}
                                    </a>
                                  )}{" "}
                                </td>
                              </tr>
                              <tr>
                                <th>Address</th>
                                <td>
                                  :{" "}
                                  {companyDetails.data?.address.length > 0 && (
                                    <a href={companyDetails.data.address}>
                                      {companyDetails.data.address}
                                    </a>
                                  )}{" "}
                                </td>
                              </tr>
                              <tr>
                                <th>Email</th>
                                <td>
                                  :{" "}
                                  {companyDetails.data?.email.length > 0 && (
                                    <a href={companyDetails.data.email}>
                                      {companyDetails.data.email}
                                    </a>
                                  )}{" "}
                                </td>
                              </tr>
                              <tr>
                                <th>Phone</th>
                                <td>
                                  :{" "}
                                  {companyDetails.data?.phone.length > 0 && (
                                    <a href={companyDetails.data.phone}>
                                      {companyDetails.data.phone}
                                    </a>
                                  )}{" "}
                                </td>
                              </tr>
                              <tr>
                                <th>Landline No.</th>
                                <td>
                                  :{" "}
                                  {companyDetails.data?.landline_no.length >
                                    0 && (
                                    <a href={companyDetails.data.landline_no}>
                                      {companyDetails.data.landline_no}
                                    </a>
                                  )}{" "}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ddddddddddddddddddddddddddd */}
                  <ToastContainer className="toast-container" />

                  <div class="col-sm-6">
                    <div class="card">
                      <div class="card-header ">
                        <h5>Logo</h5>
                      </div>
                      <div class="card-block">
                      <form  onSubmit={changeLogo}>
                        <div class="row">
                          <div class="col-sm-12 text-center">
                          <input type="file" name="logo" onChange={handleLogoImageChange} className="dropify" data-default-file={companyDetails.data?.logo ? API_BASE_URL + companyDetails.data.logo : ''} />

                          </div>
                        </div>

                        <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-10 m-t-10  text-center">
                            <button class="btn btn-warning btn-square" type="submit" disabled={submitting} >
                              Change Logo
                            </button>
                          </div>
                        </div>
                        </form>

                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="card">
                      <div class="card-header ">
                        <h5>Fevicon Icon</h5>
                      </div>
                      <div class="card-block">
                      <form  onSubmit={changeFevicon}>
                        <div class="row">
                          <div class="col-sm-12 text-center">
                            <input type="file" name="logo" className="dropify1" onChange={handleFeviconImageChange} data-default-file={companyDetails?.data?.favicon ? API_BASE_URL + companyDetails.data.favicon : ''} />
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-sm-12 col-xl-12 m-b-10 m-t-10 text-center">
                            <button class="btn btn-warning btn-square" type="submit" disabled={submitting} >
                              Change Fevicon
                            </button>
                          </div>
                        </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12">
                    <div class="card">
                      <div class="card-header ">
                        <h5>Theam Setting</h5>
                      </div>
                      <div class="card-block">
                      <form onSubmit={updateTheam}>
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="row">
                              <div class="col-sm-12 col-xl-6 m-b-30">
                                <h4 class="sub-title">Color</h4>

                                    {/* /js-example-basic-single */}
                                <select class=" col-sm-12 form-control" name="color" value={theamData.color} onChange={handleTheam}>
                                  <option value={''} disabled>select color</option>  
                                    <option value="red">Red</option>
                                    <option value="green">Green</option>
                                    <option value="yellow">Yellow</option>
                                </select>

                              </div>
                              <div class="col-sm-12 col-xl-6 m-b-30">
                                <h4 class="sub-title">Layout</h4>
                                
                                <select class="col-sm-12 form-control" name="layout" value={theamData.layout} onChange={handleTheam}>
                                    <option value={''} disabled>select layout</option>  
                                    <option value="AL">Alabama</option>
                                    <option value="WY">Wyoming</option>
                                </select>

                              </div>
                            </div>
                            <div class="row">
                              <div class="col-sm-12 col-xl-12 m-b-30 text-center">
                                <button class="btn btn-success btn-square">
                                  Update Theam
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        </form>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="styleSelector"></div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CompanyDetails;
